import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import FinanceApproveTable from "../../DataTable/FinanceApproveTable";
import Spinner from "../../Spinner";
import { GET_API, POST_API } from "../../../Services/api";
import Search from "../../../Assets/Icons/Search.svg";
import More from "../../../Assets/Icons/More.svg";
import "alertifyjs/build/css/alertify.css";

function FinanceApproval() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [financeList, setfinanceList] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    getFinanceList();
  }, []);

  const navigateDetailsPage = (row) => {
    navigate(`/approve/finance/${row?.id}`);
  };

  const headCells = [ 
    { field: "employee_code", headerName: "Employee Code", minWidth: 150 },
    { field: "name", headerName: "Employee Name", minWidth: 250 },
    { field: "payment_type", headerName: "Payment Type", minWidth: 150, renderCell: (row) => (row?.value ? row?.value : "-") },
    { field: "type", headerName: "Type", minWidth: 150 },
    { field: "amount", headerName: "Amount", minWidth: 150 },
    {
      field: "status", headerName: "Status", minWidth: 150,
      renderCell: (row) => (
        (row?.value === 1 || row?.value === "1") ? (
          <span
            className="bg-[#3EAB82] p-1"
            style={{
              color: "#FFF",
              borderRadius: "4px",
            }}
          >
            Approved
          </span>
        ) : (row?.value === 2 || row?.value === "2") ? (
          <span
            className="bg-[#EA8E37] p-1"
            style={{
              color: "#FFF",
              borderRadius: "4px",
            }}
          >
            Pending
          </span>
        ) : (
          <span
            className=" bg-[#FF5C42] p-1"
            style={{
              color: "#FFF",
              borderRadius: "4px",
            }}
          >
            Rejected
          </span>
        )
      ),
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (row) => (
        <img
          src={More}
          alt=""
          onClick={() => navigateDetailsPage(row)}
          className="log-icon"
        />
      ),
    },

  ];

  const getFinanceList = async () => {
    setLoading(true);
    try {
      const list = await GET_API("/financeApproval");
      if (list?.status === 200) {
        setfinanceList(list?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };


  const handleApprove = async (type) => {
    const user_id = localStorage.getItem("user_id");
    const data = {
      selectedRows,
      appType: type,
      approved_by: user_id,
    };
    if (selectedRows.length <= 0) {
      alertify
        .alert("Please select Row to Approve/Reject")
        .setHeader("<b> Alert </b> ");
      return;
    }
    try {
      const approveFinance = await POST_API("/updateApprovalFinance", data);
      if (approveFinance?.status === 200) {
        alertify.success(`Finance ${data?.type}d successfully...!`);
        setSelectedRows([]);
        getFinanceList();
        return;
      }
    } catch (error) {
      alertify.error(`Failed to ${data?.type}d Finance`);
      setSelectedRows([]);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">Finance</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Finance Logs</h4>
          <div className="search-block">
            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                style={{ outline: "none", border: "none" }}
              />

              <img src={Search} alt="" />
            </div>
            <div className="flex gap-3">
              <div
                className="add-new-button-block"
                onClick={() => {
                  handleApprove("reject");
                }}
              >
                <button
                  to=""
                  className="add-new-button"
                  disabled={selectedRows?.length >= 1 ? false : true}
                >
                  <span>Reject</span>
                </button>
              </div>
              <div
                className="add-new-button-block"
                onClick={() => {
                  handleApprove("approve");
                }}
              >
                <button
                  to=""
                  className="add-new-button"
                  disabled={selectedRows?.length >= 1 ? false : true}
                >
                  <span>Approve</span>
                </button>
              </div>
            </div>

          </div>
          {/**************** * Table ********************/}
          <FinanceApproveTable
            columns={headCells}
            list={financeList}
            setSelectedRows={setSelectedRows}
          />
        </div>
      </div>
    </div>
  );
}

export default FinanceApproval;




// import React, { useState, useEffect } from "react";
// import alertify from "alertifyjs";
// import FinanceApproveTable from "../../DataTable/FinanceApproveTable";
// import Search from "../../../Assets/Icons/Search.svg";
// import Spinner from "../../Spinner";
// import { GET_API, POST_API } from "../../../Services/api";
// import "alertifyjs/build/css/alertify.css";

// function FinanceApproval() {
//   const [loading, setLoading] = useState(false);
//   const [financeList, setFinanceList] = useState([]);
//   const [selectedRows, setSelectedRows] = useState([]);

//   useEffect(() => {
//     getFinanceApproveList();
//   }, []);

//   const headCells = [
//     { field: "EmployeeCode", headerName: "Employee Code", minWidth: 150 },
//     { field: "employee_name", headerName: "Employee Name", minWidth: 250 },
//     { field: "department_name", headerName: "Department", minWidth: 150 },
//     { field: "designation_name", headerName: "Designation", minWidth: 150 },
//     { field: "payment_type", headerName: "Payment Type", minWidth: 150 },
//     { field: "type", headerName: "Type", minWidth: 150 },
//     { field: "amount", headerName: "Amount", minWidth: 150 },
//   ];

//   const getFinanceApproveList = async () => {
//     setLoading(true);
//     try {
//       const financeList = await GET_API("/getFinanceDetailsApproval");
//       if (financeList?.status === 200) {
//         setFinanceList(financeList?.data?.data);
//         setLoading(false);
//       }
//     } catch (error) {
//       setLoading(false);
//     }
//   };

//   if (loading) {
//     return <Spinner />;
//   }

//   const handleApprove = async (type) => {
//     const data = {
//       selectedRows,
//       appType: type,
//     };
//     if (selectedRows.length <= 0) {
//       alertify
//         .alert("Please select Row to Approve/Reject")
//         .setHeader("<b> Alert </b> ");
//       return;
//     }

//     try {
//       const approveFinance = await POST_API(
//         "/updateFinanceDetailsApprovalstatus",
//         data
//       );
//       if (approveFinance?.status === 200) {
//         alertify.success(`Finance ${data?.type}d successfully...!`);
//         getFinanceApproveList();
//         return;
//       }
//     } catch (error) {
//       alertify.error(`Failed to ${data?.type}d Finance`);
//     }
//   };

//   return (
//     <div className=" bg-[#F7F6F4] grow">
//       <div className="sm-container">
//         <h1 className="heading-text">Finance</h1>
//         <div className="card-wrapper">
//           <h4 className="card-header">Finance Logs</h4>
//           <div className="search-block">
//             <div
//               className="add-new-button-block"
//               onClick={() => {
//                 handleApprove("reject");
//               }}
//             >
//               <button
//                 to=""
//                 className="add-new-button"
//                 // disabled={selectedRows?.length >= 1 ? false : true}
//               >
//                 <span>Reject</span>
//               </button>
//             </div>
//             <div
//               className="add-new-button-block"
//               onClick={() => {
//                 handleApprove("approve");
//               }}
//             >
//               <button
//                 to=""
//                 className="add-new-button"
//                 // disabled={selectedRows?.length >= 1 ? false : true}
//               >
//                 <span>Approve</span>
//               </button>
//             </div>
//             <div className="search">
//               <input
//                 type="text"
//                 className="search-input-box"
//                 placeholder="Search..."
//                 autoComplete="off"
//                 style={{ outline: "none", border: "none" }}
//               />

//               <img src={Search} alt="" />
//             </div>
//           </div>
//           {/**************** * Table ********************/}
//           <FinanceApproveTable
//             columns={headCells}
//             list={financeList}
//             setSelectedRows={setSelectedRows}
//           />
//         </div>
//       </div>
//     </div>
//   );
// }

// export default FinanceApproval;
