import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import alertify from "alertifyjs";
import { useReactToPrint } from "react-to-print";
import dayjs from "dayjs";
import { GET_API } from "../../../Services/api";
import Spinner from "../../Spinner";
import Print from "../../../Assets/Icons/Print.svg";
import Logo from "../../../Assets/Logo/Logo.png";
import Location from "../../../Assets/Icons/Location.svg";
import "alertifyjs/build/css/alertify.css";
import "./DownloadPayslip.css";

function PayslipsList() {
    let componentRef = useRef();
    const [payslipDetails, setPayslipDetails] = useState([]);
    const [loading, setLoading] = useState(false);
    const [clientList, setClientList] = useState([]);
    const [designationList, setDesignationList] = useState([]);
    const [params, setParams] = useState({ client_id: "", category_id: "" });
    const [currentMonth, setCurrentMonth] = useState("");

    const getPayslip = async () => {
        const month = dayjs(currentMonth).format("MM");
        const year = dayjs(currentMonth).format("YYYY");
        setLoading(true);
        try {
            const payroll = await GET_API(`/calculatePayrollClientCatWise/${params?.client_id}/${params?.category_id}?month=${month}&year=${year}`);
            if (payroll?.status === 200) {
                setPayslipDetails(payroll?.data?.data);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            setPayslipDetails([]);
            alertify.error(error?.response?.data?.message);
        }
    };

    const getClientList = async () => {
        setLoading(true);
        try {
            const cliList = await GET_API("/clientLists");
            if (cliList.status === 200) {
                const client = cliList?.data?.data.map((item, i) => {
                    return { value: item?.id, label: item?.name };
                });
                client.unshift({ value: "", label: "Select..." });
                setClientList(client);
                setLoading(false);
            }
        } catch (e) {
            setLoading(false);
            alertify.error("Failed to Get Client List");
        }
    };

    const getDesignationList = async () => {
        setLoading(true);
        try {
            const categList = await GET_API("/CategoryList");
            if (categList.status === 200) {
                const category = categList?.data?.data.map((item, i) => {
                    return { value: item?.id, label: item?.name };
                });
                category.unshift({ value: "", label: "Select..." });
                setDesignationList(category);
                setLoading(false);
            }
        } catch (e) {
            setLoading(false);
            setDesignationList([])
            alertify.error("Failed to Get Designation List");
        }
    };

    useEffect(() => {
        getClientList();
        getDesignationList();
    }, []);

    const intToWords = (rawValue) => {
        var num = rawValue,
            a = [
                "",
                "one ",
                "two ",
                "three ",
                "four ",
                "five ",
                "six ",
                "seven ",
                "eight ",
                "nine ",
                "ten ",
                "eleven ",
                "twelve ",
                "thirteen ",
                "fourteen ",
                "fifteen ",
                "sixteen ",
                "seventeen ",
                "eighteen ",
                "nineteen ",
            ],
            b = [
                "",
                "",
                "twenty",
                "thirty",
                "forty",
                "fifty",
                "sixty",
                "seventy",
                "eighty",
                "ninety",
            ],
            c = ["thousand", "million", ""],
            words = "";

        num = ("000000000" + num.toString()).substr(-9).match(/.{3}/g);

        for (var i = 0; i < c.length; i++) {
            var n = num[i],
                str = "";
            str += words != "" ? " " + c[c.length - 1 - i] + " " : "";
            str += n[0] != 0 ? a[Number(n[0])] + "hundred " : "";
            n = n.substr(1);
            str +=
                n != 0
                    ? (str != "" ? "and " : "") +
                    (a[Number(n)] || b[n[0]] + " " + a[n[1]])
                    : "";
            words += str;
        }
        return words.replace(/ +/g, " ").replace(/ $/, "");
    };

    // const generatePayroll = async () => {
    //     setLoading(true);
    //     try {
    //       const payroll = await POST_API(`/payslipGenerate/${id}`, {
    //         payslipDetails,
    //         createdBy: localStorage.getItem("user_id"),
    //       });
    //       if (payroll.status === 200) {
    //         setLoading(false);
    //         const payslip = getPayslip();
    //         setTimeout(() => print(), 2000);
    //       }
    //     } catch (error) {
    //       setLoading(false);
    //       alertify.error(error?.response?.data?.message);
    //     }
    // };

    const handleSelectChange = (e, name) => {
        const { value } = e;
        setParams({ ...params, [name]: value });
    }

    const handleDateChange = (e) => {
        setCurrentMonth(e.target.value);
    };


    const print = useReactToPrint({
        content: () => componentRef.current,
    });

    if (loading) {
        return <Spinner />;
    }
    console.log("data", payslipDetails);
    return (
        <div className=" bg-[#F7F6F4] grow">
            <div className="sm-container">
                <h1 className="heading-text">Payslips</h1>
                <div className="card-wrapper border-b-[1px] border-b-[#D9D9D9]">
                    <h4 className="card-header">Payslip List</h4>
                    <div className="add-block">
                        <div className="field-block w-1/4 dropdown-select-to-one">
                            <label className="label-name">Select Client</label>
                            <Select
                                options={clientList}
                                value={clientList?.find(
                                    (option) => option?.value === params.client_id
                                )}
                                onChange={(e) => handleSelectChange(e, "client_id", "select")}
                                name="client_id"
                                className=""
                                isSearchable
                            />
                        </div>
                        <div className="field-block w-1/4 dropdown-select-to-one">
                            <label className="label-name">Select Designation</label>
                            <Select
                                options={designationList}
                                value={designationList?.find(
                                    (option) => option?.value === params.category_id
                                )}
                                onChange={(e) => handleSelectChange(e, "category_id", "select")}
                                name="category_id"
                                className=""
                                isSearchable
                            />
                        </div>
                        <div className="field-block w-44">
                            <label className="label-name">Month</label>
                            <input
                                type="month"
                                name="month"
                                className="input-field"
                                placeholder="Enter Month"
                                onChange={handleDateChange}
                                value={currentMonth}
                                max={dayjs().format("YYYY-MM")}
                            />
                        </div>
                        <div className="flex items-center">
                            <div className="add-new-button-block">
                                <button className="add-new-button" onClick={() => getPayslip()}>
                                    <span>Find</span>
                                    {/* <ArrowCircleDownIcon /> */}
                                </button>
                            </div>
                        </div>
                        <div className="flex items-center">
                            <button className="view-list-button" onClick={print}>
                                Print
                                <img src={Print} alt="" />
                            </button>
                        </div>
                    </div>
                </div>
                <div ref={componentRef}>
                    {payslipDetails?.map((item, i) => {
                        return <div className="card-wrapper payslip-container" key={i} >
                            <div >
                                <h4 className="payslip-header">
                                    <div className="flex justify-center">
                                        <div >
                                            <div className="flex justify-center">
                                                <img src={Logo} className="h-[60px] w-[60px]" alt="" />
                                            </div>
                                            <div className="flex justify-center">
                                                <b>Tentacle Secure Squads Private Limited</b>
                                            </div>

                                            <div className="flex justify-center">
                                                PaySlip Month for &nbsp;
                                                <b>{item?.displayMonth}</b>
                                            </div>
                                            <div className="flex justify-center items-center">
                                                <label className="list-label flex">
                                                    {/* <img src={RedUser} className="w-6 h-6" alt="location"/> */}
                                                    {item?.getclientDetails?.name} </label>
                                                <div className="pl-1.5">|</div>
                                                <div
                                                    style={{ wordBreak: "break-word" }}
                                                    className="flex advance-section-list-value"
                                                >
                                                    <img src={Location} className="w-6 h-6" alt="location" />  {item?.getclientDetails?.location}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </h4>
                                <div className="p-1 pl-3 pr-3">
                                    <div className="flex justify-between">
                                        <div>
                                            <div className="flex">
                                                <label className="list-label">Employee Code: </label>
                                                <div
                                                    style={{ wordBreak: "break-word" }}
                                                    className="advance-section-list-value"
                                                >
                                                    {item?.employee?.employee_code}
                                                </div>
                                            </div>
                                            <div className="flex">
                                                <label className="list-label">Employee Name: </label>
                                                <div
                                                    style={{ wordBreak: "break-word" }}
                                                    className="advance-section-list-value"
                                                >
                                                    {item?.employee?.name}
                                                </div>
                                            </div>
                                            <div className="flex">
                                                <label className="list-label">Father Name: </label>
                                                <div
                                                    style={{ wordBreak: "break-word" }}
                                                    className="advance-section-list-value"
                                                >
                                                    {item?.employee?.father_name}
                                                </div>
                                            </div>
                                            <div className="flex">
                                                <label className="list-label">Bank Account No: </label>
                                                <div
                                                    style={{ wordBreak: "break-word" }}
                                                    className="advance-section-list-value"
                                                >
                                                    {item?.bankDetails?.home_bank_account_no || item?.bankDetails?.hdfc_account_no}
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="flex">
                                                <label className="list-label">Designation: </label>
                                                <div
                                                    style={{ wordBreak: "break-word" }}
                                                    className="advance-section-list-value"
                                                >
                                                    {item?.employee?.category_name}
                                                </div>
                                            </div>
                                            <div className="flex">
                                                <label className="list-label">ESI: </label>
                                                <div
                                                    style={{ wordBreak: "break-word" }}
                                                    className="advance-section-list-value"
                                                >
                                                    {item?.employee?.esi_number}
                                                </div>
                                            </div>
                                            <div className="flex">
                                                <label className="list-label">PF No: </label>
                                                <div
                                                    style={{ wordBreak: "break-word" }}
                                                    className="advance-section-list-value"
                                                >
                                                    {item?.employee?.pf_number}
                                                </div>
                                            </div>
                                            <div className="flex">
                                                <label className="list-label">Bank IFSC: </label>
                                                <div
                                                    style={{ wordBreak: "break-word" }}
                                                    className="advance-section-list-value"
                                                >
                                                    {item?.bankDetails?.home_bank_ifsc_code}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-3 pt-1">
                                    <table className="w-[100%] border border-[#d9d9d9] border-collapse">
                                        <tbody>
                                            <tr>
                                                <td className="border border-[#d9d9d9] p-3 pt-3 p w-[30%] align-baseline">
                                                    <table className="w-[100%]">
                                                        <tbody>
                                                            <tr>
                                                                <td>Total Days</td>
                                                                <td className="text-right">
                                                                    <b>
                                                                        {item?.attendance?.totalDays} days
                                                                    </b>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Present Days</td>
                                                                <td className="text-right">
                                                                    <b>
                                                                        {item?.attendance?.presentDays} days
                                                                    </b>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Absent Days</td>
                                                                <td className="text-right">
                                                                    <b>
                                                                        {item?.attendance?.absentDays} days
                                                                    </b>
                                                                </td>
                                                            </tr>
                                                            {item?.attendance?.incentiveDays ?
                                                                <tr>
                                                                    <td>Incentive Days</td>
                                                                    <td className="text-right">
                                                                        <b>
                                                                            {item?.attendance?.incentiveDays} days
                                                                        </b>
                                                                    </td>
                                                                </tr> : null}

                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td className="border border-[#d9d9d9] p-3 pt-3 w-[40%] align-baseline">
                                                    <table className="w-[100%]">
                                                        <tbody>
                                                            <tr>
                                                                <th className="text-left">
                                                                    <b> </b>
                                                                </th>
                                                                <th c className="text-right">
                                                                    <b></b>
                                                                </th>
                                                                <th className="text-right">
                                                                    <b> Fixed</b>
                                                                </th>
                                                                <th className="text-right">
                                                                    <b> Earned</b>
                                                                </th>
                                                            </tr>
                                                            {item?.earnings &&
                                                                item?.earnings.map((list, i) => {
                                                                    return (
                                                                        <tr key={i}>
                                                                            <td>{list?.name}</td>
                                                                            <td>{list?.type}</td>
                                                                            <td className="text-right">
                                                                                {list?.totalAmount}
                                                                            </td>
                                                                            <td className="text-right">
                                                                                <b>{list?.actualAmount}</b>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                            <tr className="border-t border-t-[#d9d9d9]">
                                                                <td><b>Total</b></td>
                                                                <td></td>
                                                                <td className="text-right"><b>{item?.earnings && item?.earnings?.reduce((sum, { totalAmount }) => sum + totalAmount, 0)}</b></td>
                                                                <td className="text-right"><b>{item?.totalEarnings?.total}</b></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td className="border border-[#d9d9d9] p-3 pt-3 w-[30%] align-baseline">
                                                    <table className="w-[100%]">
                                                        <tbody>
                                                            <tr>
                                                                <th colSpan="2" className="text-left">
                                                                    <b>Deductions</b>
                                                                </th>
                                                            </tr>
                                                            {item?.deductions &&
                                                                item?.deductions.map((list, i) => {
                                                                    return (
                                                                        <tr>
                                                                            <td>{list?.name}</td>
                                                                            <td className="text-right">
                                                                                <b>{list?.amount}</b>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                            {item?.otherDeductions &&
                                                                item?.otherDeductions.map((list, i) => {
                                                                    return (
                                                                        <tr>
                                                                            <td>{list?.name}</td>
                                                                            <td className="text-right">
                                                                                <b>{list?.amount}</b>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border border-[#d9d9d9] p-1 pl-2 pr-2 p w-[30%] align-baseline">
                                                    <table className="w-[100%]">
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <b>Total Days</b>
                                                                </td>
                                                                <td className="text-right">
                                                                    {item?.attendance?.totalDays} days
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td className="border border-[#d9d9d9] p-1 pl-2 pr-2 p w-[30%] align-baseline">
                                                    <table className="w-[100%]">
                                                        <tbody>
                                                            <tr>
                                                                <td className="w-[65%]">
                                                                    <b>Total Earnings</b>
                                                                </td>
                                                                <td className="text-right">
                                                                    {/* (-{payslipDetails?.totalEarnings?.deduction}) */}
                                                                </td>
                                                                <td className="text-right">
                                                                    {item?.totalEarnings?.total}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td className="border border-[#d9d9d9] p-1 pl-2 pr-2 p w-[30%] align-baseline">
                                                    <table className="w-[100%]">
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <b>Total Deductions</b>
                                                                </td>
                                                                <td className="text-right">
                                                                    {item?.totalDeductions?.total}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="p-3">
                                    <table>
                                        <tr>
                                            <td className="w-[35%]"><p>Salary to be paid only through bank  : </p></td>
                                            <td className="w-[65%]">
                                                <p>
                                                    <b>
                                                        {" "}
                                                        Rs. {item?.salaryToBePaid}(
                                                        {item?.salaryToBePaid &&
                                                            intToWords(item?.salaryToBePaid)}
                                                        Rupees)
                                                    </b>
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <br />
                                <div className="p-3 pt-4">
                                    <div className="flex justify-between items-center">
                                        <div>
                                            <b>Admit by</b>
                                        </div>
                                        <div>
                                            <b>Checked by</b>
                                        </div>
                                        <div>
                                            <b>Employee Signature</b>
                                        </div>
                                    </div>
                                </div>
                                {/* PF Salary details */}
                                {item?.pfEarnings &&
                                    item?.pfEarnings.length > 0 && (
                                        <>
                                            <div id="scissors">
                                                <div></div>
                                            </div>
                                            <h4 className="payslip-pf-header">
                                                <div className="flex justify-between items-center">
                                                    PF Salary Voucher
                                                </div>
                                            </h4>

                                            <div className="p-1 pl-3 pr-3">
                                                <div className="flex justify-between">
                                                    <div>
                                                        <div className="flex">
                                                            <label className="list-label">Employee Code: </label>
                                                            <div
                                                                style={{ wordBreak: "break-word" }}
                                                                className="advance-section-list-value"
                                                            >
                                                                {item?.employee?.emp_code}
                                                            </div>
                                                        </div>
                                                        <div className="flex">
                                                            <label className="list-label">Employee Name: </label>
                                                            <div
                                                                style={{ wordBreak: "break-word" }}
                                                                className="advance-section-list-value"
                                                            >
                                                                {item?.employee?.first_name +
                                                                    " " +
                                                                    item?.employee?.fathers_name}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="flex">
                                                            <label className="list-label">Designation: </label>
                                                            <div
                                                                style={{ wordBreak: "break-word" }}
                                                                className="advance-section-list-value"
                                                            >
                                                                {item?.employee?.designation_name}
                                                            </div>
                                                        </div>
                                                        <div className="flex">
                                                            <label className="list-label">Date: </label>
                                                            <div
                                                                style={{ wordBreak: "break-word" }}
                                                                className="advance-section-list-value"
                                                            >
                                                                {item?.date}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="p-3 pt-1">
                                                <table className="w-[100%] border border-[#d9d9d9] border-collapse">
                                                    <tbody>
                                                        <tr>
                                                            <td className="border border-[#d9d9d9] p-3 pt-3 p w-[40%] align-baseline">
                                                                <table className="w-[100%]">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>Holiday Scheme</td>
                                                                            <td className="text-right">
                                                                                <b>
                                                                                    {
                                                                                        item?.employee
                                                                                            ?.holiday_scheme_name
                                                                                    }
                                                                                </b>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Present Days</td>
                                                                            <td className="text-right">
                                                                                <b>
                                                                                    {
                                                                                        item?.pfAttendance
                                                                                            ?.presentDays
                                                                                    }
                                                                                </b>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Paid Leave Days</td>
                                                                            <td className="text-right">
                                                                                <b>
                                                                                    {item?.pfAttendance?.paidDays}
                                                                                </b>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Net Present Days</td>
                                                                            <td className="text-right">
                                                                                <b>
                                                                                    {
                                                                                        item?.pfAttendance
                                                                                            ?.netPresentDays
                                                                                    }
                                                                                </b>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                            <td className="border border-[#d9d9d9] p-3 pt-3 w-[40%] align-baseline">
                                                                <table className="w-[100%]">
                                                                    <tbody>
                                                                        <tr>
                                                                            <th colSpan="4" className="text-left">
                                                                                <b> Earnings</b>
                                                                            </th>
                                                                        </tr>
                                                                        {item?.pfEarnings &&
                                                                            item?.pfEarnings.map((list, i) => {
                                                                                return (
                                                                                    <tr>
                                                                                        <td>{list?.name}</td>
                                                                                        <td>{list?.type}</td>
                                                                                        <td className="text-right">
                                                                                            {list?.totalAmount}
                                                                                        </td>
                                                                                        <td className="text-right">
                                                                                            {list?.actualAmount}
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            })}
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                            <td className="border border-[#d9d9d9] p-3 pt-3 w-[20%] align-baseline">
                                                                <table className="w-[100%]">
                                                                    <tbody>
                                                                        <tr>
                                                                            <th colSpan="2" className="text-left">
                                                                                <b>Deductions</b>
                                                                            </th>
                                                                        </tr>
                                                                        {item?.pfDeductions &&
                                                                            item?.pfDeductions.map(
                                                                                (list, i) => {
                                                                                    return (
                                                                                        <tr>
                                                                                            <td>{list?.name}</td>
                                                                                            <td className="text-right">
                                                                                                <b>{list?.amount}</b>
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                }
                                                                            )}
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border border-[#d9d9d9] p-1 pl-2 pr-2 p w-[30%] align-baseline">
                                                                <table className="w-[100%]">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                <b>Total Days</b>
                                                                            </td>
                                                                            <td className="text-right">
                                                                                {item?.attendance?.totalDays} days
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                            <td className="border border-[#d9d9d9] p-1 pl-2 pr-2 p w-[30%] align-baseline">
                                                                <table className="w-[100%]">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td className="w-[65%]">
                                                                                <b>Total</b>
                                                                            </td>
                                                                            <td className="text-right">
                                                                                {item?.pfEarnings &&
                                                                                    item?.pfEarnings[0] &&
                                                                                    item?.pfEarnings[0]
                                                                                        .actualAmount}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                            <td className="border border-[#d9d9d9] p-1 pl-2 pr-2 p w-[30%] align-baseline">
                                                                <table className="w-[100%]">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                <b>Total</b>
                                                                            </td>
                                                                            <td className="text-right">
                                                                                {item?.pfTotalDeductions?.total}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className="p-3 flex">
                                                <p>Salary to be credited in Bank : </p>
                                                <p>
                                                    <b>
                                                        {" "}
                                                        Rs. {item?.pfSalaryInBank}(
                                                        {item?.pfSalaryInBank &&
                                                            intToWords(item?.pfSalaryInBank)}
                                                        Rupees)
                                                    </b>
                                                </p>
                                            </div>
                                            <br />

                                            <div className="p-3 pt-4">
                                                <div className="flex justify-between items-center">
                                                    <div>
                                                        <b>Admit by</b>
                                                    </div>
                                                    <div>
                                                        <b>Checked by</b>
                                                    </div>
                                                    <div>
                                                        <b>Employee Signature</b>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                            </div>
                            {/* {item?.voucherNo == "" && (
                        <div className="search-block ">
                            <div className="export-btn" onClick={generatePayroll}>
                                <span className="export-btn-text">Generate Payroll</span>
                                <img src={Export} alt="" />
                            </div>
                        </div>
                    )} */}

                            <hr className="border-[#d9d9d9] mt-6"></hr>
                            {(item?.ismaual === 0 || item?.ismaual === "0") ?
                                <>
                                    <div className="p-3">
                                        <h3>
                                            <b>Attendance Details: </b>
                                        </h3>
                                    </div>
                                    <div className="grid grid-cols-2 p-6 pt-2 gap-2">
                                        <div>
                                            <p>
                                                Month : <b>{item?.displayMonth}</b>
                                            </p>
                                            <p>
                                                Total days Present :
                                                <b>
                                                    {item?.presentDetails &&
                                                        item?.presentDetails.length}
                                                </b>
                                            </p>
                                        </div>
                                        <div className="flex justify-end items-center gap-3">
                                            <p className="flex jusitify-center items-center gap-2">
                                                <span className="bg-[#11B76B] border rounded border-[#11B76B] h-[14px] w-[14px]"></span>{" "}
                                                <span>Present</span>
                                            </p>
                                            <p className="flex jusitify-center items-center gap-2">
                                                <span className="bg-[#ed452a] border rounded border-[#ed452a]  h-[14px] w-[14px]"></span>
                                                <span>Absent</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="p-1 flex flex-col items-center justify-center">
                                        <table className="overflow-x-scroll  text-center w-2/4">
                                            <tr>
                                                {item?.daysArr &&
                                                    item?.daysArr.map((list, i) => (
                                                        <th className="border attendance-header border-[#d9d9d9] p-3">
                                                            {list}
                                                        </th>
                                                    ))}
                                            </tr>
                                            {item?.attendance_details &&
                                                Object.keys(item?.attendance_details).map((key) => (
                                                    <>
                                                        <tr>
                                                            {item?.attendance_details[key].map(
                                                                (item, index) => {
                                                                    {
                                                                        return (
                                                                            <td className="font-karla border border-[#d9d9d9] p-3">
                                                                                <span
                                                                                    className={`rounded px-2 py-1 ${item.value === "P"
                                                                                        ? "bg-[#11B76B] text-[#FFF]"
                                                                                        : item.value === "A"
                                                                                            ? "bg-[#ed452a] text-[#FFF]"
                                                                                            : ""
                                                                                        }`}
                                                                                >
                                                                                    {item.date}
                                                                                </span>
                                                                            </td>
                                                                        );
                                                                    }
                                                                }
                                                            )}
                                                        </tr>
                                                    </>
                                                ))}
                                        </table>
                                    </div>
                                </> : null}
                        </div>

                    })}
                    {payslipDetails?.length < 1 ?
                        <div className="card-wrapper ">
                            <div className="table-body flex items-center justify-center h-[220px]">
                                Data Not Found..!
                            </div>
                        </div> : null}
                </div>
            </div>
        </div>
    );
}

export default PayslipsList;
