import React, { useState, useEffect } from "react";
import More from "../../../Assets/Icons/More.svg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { DELETE_API, GET_API } from "../../../Services/api";
import DepartmentListTable from "../../DataTable/DepartmentListTable";
import Search from "../../../Assets/Icons/Search.svg";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import Spinner from "../../Spinner";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";

function DepartmentList() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [departmentList, setDepartmentList] = useState([]);
  const [filteredDepartment, setFilteredDepartment] = useState([]);

  const handleDepartmentSearch = (e) => {
    const searchTerm = e.target.value;
    const searchedList = departmentList.filter((item) => {
      for (let key in item) {
        if (
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
    setFilteredDepartment(searchedList);
  };

  const showDepartmentDetails = (listDetail) => {
    navigate("/masters/department/details", {
      state: {
        list: listDetail,
      },
    });
  };

  const navigateEditPage = (listDetail) => {
    navigate("/masters/department/edit", {
      state: {
        list: listDetail,
      },
    });
  };

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "department_name", label: "Department Name" },
    { id: "slug_name", label: "Slug Name" },
    { id: "description", label: "Description" },
    { id: "created_at", label: "Created at" },
    { id: "more_icon", label: "" },
  ];

  useEffect(() => {
    getDepartmentList();
  }, []);
  const getDepartmentList = async () => {
    setLoading(true);
    try {
      const departmentList = await GET_API("/DepartmentList");
      if (departmentList?.status === 200) {
        setDepartmentList(departmentList?.data?.data);
        setFilteredDepartment(departmentList?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      console.log(error?.response?.data?.message);
    }
  };

  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Department..?",
        async function () {
          if (row_id) {
            const deleteRow = await DELETE_API(`/deleteDepartment/${row_id}`);
            if (deleteRow.status === 200) {
              alertify.success(deleteRow?.data?.message);
              getDepartmentList();
              return;
            }
            alertify.error("Failed To Delete Floor");
          }
        },
        function () { }
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">Department</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Department List</h4>
          <div className="search-block">
            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                onChange={(e) => handleDepartmentSearch(e)}
                style={{ outline: "none", border: "none" }}
              />

              <img src={Search} alt="" />
            </div>
            <div className="flex gap-3">
              <div className="add-new-button-block">
                <Link to="/masters/department/create" className="add-new-button">
                  <span>Add New</span>
                  <img src={ColorAdd} alt="add" />
                </Link>
              </div>
            </div>
          </div>

          {/**************** * Table ********************/}
          <DepartmentListTable
            list={filteredDepartment}
            columns={columns}
            icon={More}
            multiple={true}
            editPage={navigateEditPage}
            showDetails={showDepartmentDetails}
            deleteRow={handleDelete}
          />
        </div>
      </div>
    </div>
  );
}

export default DepartmentList;
