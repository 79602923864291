import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import alertify from "alertifyjs";
import { POST_API } from "../../Services/api";
import Spinner from "../Spinner";
import View from "../../Assets/Icons/View.svg";
import ColorAdd from "../../Assets/Icons/ColorAdd.svg";
import "alertifyjs/build/css/alertify.css";

function AllowanceAndDeductions() {
    const { id, emp_id } = useParams();
    const navigate = useNavigate();
    const [allowAndDeductions, setAllowAndDeductions] = useState({});
    const [loading, setLoading] = useState(false);
    const [isBtnDisable, setIsBtnDisable] = useState(false);

    useEffect(() => {
        getAllowanceAndDeduction();
    }, []);

    const getAllowanceAndDeduction = async () => {
        setLoading(true);
        try {
            const allowAndDeduction = await POST_API(`/getClientAllowanceDeductions/${id}`, {employee_id: emp_id});
            if (allowAndDeduction.status === 200) {
                setAllowAndDeductions({
                    allowance: allowAndDeduction?.data?.employeeAllowance,
                    deduction: allowAndDeduction?.data?.employeeDeductions,
                });
                setLoading(false);
            }
        } catch (e) {
            alertify.error("Failed to get List");
            setAllowAndDeductions({});
            setLoading(false);
        }
    };

    const handleAllowanceInput = (index, e) => {
        const { name, value } = e.target;
        const allowanceDetails = [...allowAndDeductions?.allowance]
        allowanceDetails[index][name] = value;
        setAllowAndDeductions({ ...allowAndDeductions, allowance: allowanceDetails })
    }

    const handleDeductionInput = (index, e) => {
        const { name, value } = e.target;
        const deductionDetails = [...allowAndDeductions?.deduction]
        deductionDetails[index][name] = value;
        setAllowAndDeductions({ ...allowAndDeductions, deduction: deductionDetails })
    }

    const handleSubmit = async () => {
        const allowanceData = allowAndDeductions?.allowance?.filter(item => item.automatic !== 1)?.map((item, i) => {
            const { allowance_id, amount } = item
            return { allowance_id: allowance_id, amount: amount, client_id: id }
        })
        const deductionData = allowAndDeductions?.deduction?.filter(item => item.automatic !== 1)?.map((item, i) => {
            const { deductions_id, deduction_id, amount } = item
            return { deductions_id: deductions_id || deduction_id, amount: amount, client_id: id }
        })
        const submitData = {
            employee_id:emp_id,
            allowance: allowanceData,
            deductions: deductionData
        }
        try {
            setIsBtnDisable(true)
            const responce = await POST_API("/employeeAllowanceUpdate", submitData);
            if (responce?.status === 200) {
                navigate("/employee/list")
                setIsBtnDisable(false)
            }
        } catch (error) {
            // alertify.error("")
            setIsBtnDisable(false)
        }
    }

    const textUpperCase = (string) => {
        return string?.charAt(0)?.toUpperCase() + string?.slice(1)
    }

    if (loading) {
        return <Spinner />;
    }

    return (
        <div className=" bg-[#F7F6F4] grow">
            <div className=" sm-container">
                <h1 className="heading-text">Employee</h1>
                <div className="card-wrapper">
                    <div className="search-block">
                        <Link to="/employee/list" className="view-list-button">
                            <span>View List</span>
                            <img src={View} alt="add" />
                        </Link>
                    </div>
                    <div className="list-container">
                        <div className="flex employee-sub-header">
                            <h3 className="text-text-dark text-lg font-semibold w-1/2">Allowance </h3>
                        </div>
                        {allowAndDeductions?.allowance?.map((item, index) => (
                            <div className="p-2 grid grid-cols-4 gap-5" key={index}>
                                <div className="list">
                                    <label className="list-label">Allowance</label>
                                    <div className="list-value">{item?.masterAllowanceName}</div>
                                </div>
                                <div className="list ">
                                    <label className="list-label">Daily/Monthly</label>
                                    <div className="list-value">{textUpperCase(item?.select_type)}</div>
                                </div>
                                <div className="list">
                                    <label className="list-label">Automatic</label>
                                    <div className="list-value">{item?.automatic ? "Yes" : "No"}</div>
                                </div>
                                <div className="list">
                                    {item?.automatic === 1 ?
                                        <>
                                            <label className="list-label">Percentage of</label>
                                            <div className="list-value">{item?.allowanceName?.split(',')?.join(', ')}</div>
                                        </>
                                        :
                                        <>
                                            <label className="list-label">Amount</label>
                                            <input
                                                className="input-field"
                                                type="number"
                                                id={`amount-${index}`}
                                                name="amount"
                                                value={item.amount}
                                                onChange={(e) => handleAllowanceInput(index, e)}
                                            />
                                        </>
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="list-container">
                        <div className="flex employee-sub-header">
                            <h3 className="text-text-dark text-lg font-semibold w-1/2">Deduction</h3>
                        </div>
                        {allowAndDeductions?.deduction?.map((item, index) => (
                            <div className="p-2 grid grid-cols-4 gap-5" key={index}>
                                <div className="list">
                                    <label className="list-label">Deduction</label>
                                    <div className="list-value">{item?.masterDeductionsName}</div>
                                </div>
                                <div className="list">
                                    <label className="list-label">Automatic</label>
                                    <div className="list-value">{item?.automatic ? "Yes" : "No"}</div>
                                </div>
                                <div className="list">

                                </div>
                                <div className="list">
                                    {item?.automatic === 1 ?
                                        <>
                                            <label className="list-label">Percentage of</label>
                                            <div className="list-value">{item?.allowanceName?.split(',')?.join(', ')}</div>
                                        </>
                                        :
                                        <>
                                            <label className="list-label">Amount</label>
                                            <input
                                                className="input-field"
                                                type="number"
                                                id={`amount-${index}`}
                                                name="amount"
                                                value={item.amount}
                                                onChange={(e) => handleDeductionInput(index, e)}
                                            />
                                        </>
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="submit-reset-button">
                        <button
                            disabled={isBtnDisable}
                            type="submit"
                            className="submit-button"
                            onClick={handleSubmit}
                        >
                            <span className="submit-button-label">Save</span>
                        </button>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default AllowanceAndDeductions;
