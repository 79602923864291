import React from "react";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Checkbox from "@mui/material/Checkbox";
import Select from "react-select";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";

function EmployeeBasicInfo({ allowanceData, setAllowanceData, allowanceOptions }) {
   

    const handleAllowanceSelect = (index, e, name) => {
        const { value } = e;
        const newItems = [...allowanceData];
        newItems[index][name] = value;
        setAllowanceData(newItems);
    };

    const handleFormulaSelect = (index, e) => {
        const option = allowanceData.map((item, i) => {
            if (i === index) {
                return { ...item, name: e }
            } else {
                return item
            }
        })
        setAllowanceData(option)
    };

    const handleAllowanceAutomatic = (index, e, name) => {
        const { checked } = e?.target;
        const newItems = [...allowanceData];
        newItems[index][name] = checked ? 1 : 0;
        setAllowanceData(newItems);
    }

    const handlePercentageChange = (index, e) => {
        const { name, value } = e?.target;
        const newItems = [...allowanceData];
        newItems[index][name] = value;
        setAllowanceData(newItems);
    }

    const handleAllowanceAdd = () => {
        setAllowanceData([...allowanceData, { allowance_type_id: '', name: [], select_type: "", percentage: "", automatic: 0, is_incentive:"" }]);
    };

    const handleAllowanceRemove = (index) => {
        if (allowanceData?.length <= 1 && index === 0) {
            alertify.error("Unable to delete");
        } else {
            const newItems = [...allowanceData];
            newItems.splice(index, 1);
            setAllowanceData(newItems);
        }

    };

    const dailyMonthlyOptions = [
        { label: "Please Select", value: "" },
        { label: "Daily", value: "daily" },
        { label: "Monthly", value: "monthly" },
    ]

    return (
        <>
            <form>
                <div className="flex employee-sub-header">
                    <h3 className="flex items-center text-text-dark text-lg font-semibold w-1/2">Allowance </h3>
                    <div className="w-1/2 text-right">
                        <button onClick={handleAllowanceAdd} type="button" className="employee-add-button"><span className="submit-button-label">Add new</span></button>
                    </div>
                </div>

                {allowanceData?.map((item, index) => (

                    <div className="p-2 grid grid-cols-6 gap-5" key={index}>
                        <div className="field-block">
                            <label htmlFor={`name-${index}`}>Allowance</label>
                            <Select
                                id={`name-${index}`}
                                options={allowanceOptions}
                                onChange={(e) => handleAllowanceSelect(index, e, "allowance_type_id")}
                                name="allowance"
                                value={allowanceOptions?.find(
                                    (option) => option?.value === item.allowance_type_id
                                )}
                                isSearchable
                            />
                        </div>
                        <div className="field-block">
                            <label htmlFor={`name-${index}`}>Daily/Monthly</label>
                            <Select
                                id={`name-${index}`}
                                options={dailyMonthlyOptions}
                                onChange={(e) => handleAllowanceSelect(index, e, "select_type")}
                                name="allowance"
                                value={dailyMonthlyOptions?.find(
                                    (option) => option?.value === item.select_type
                                )}
                                isSearchable
                            />
                        </div>
                        <div className="field-block flex justify-center">
                            <div className="">
                            <label htmlFor={`name-${index}`}>Automatic</label>
                            <Checkbox
                                style={{ color: "rgb(193,49,76)", padding: 0 }}
                                className="p-0 bg-primary-color"
                                onChange={e => handleAllowanceAutomatic(index, e, "automatic")}
                                inputProps={{ "aria-label": "controlled" }}
                                checked={(item?.automatic === "1" || item?.automatic === 1) ? true : false}
                            />
                            </div>
                            <div className="">
                            <label htmlFor={`name-${index}`}>Incentive</label>
                            <Checkbox
                                style={{ color: "rgb(193,49,76)", padding: 0 }}
                                className="p-0 bg-primary-color"
                                onChange={e => handleAllowanceAutomatic(index, e, "is_incentive")}
                                inputProps={{ "aria-label": "controlled" }}
                                checked={(item?.is_incentive === "1" || item?.is_incentive === 1) ? true : false}
                            />
                            </div>
                            
                           
                        </div>

                        {(item?.automatic === "1" || item?.automatic === 1 ) ?
                            <div className="field-block">
                                <label htmlFor={`name-${index}`}>Percentage of</label>
                                <input
                                    className="input-field "
                                    type="number"
                                    onChange={(e) => handlePercentageChange(index, e)}
                                    name="percentage"
                                    placeholder="Enter Percentage of"
                                    value={item?.percentage}
                                    autoComplete="off"
                                />
                            </div> :  item?.is_incentive !== 1 ?
                            <div className="field-block">
                                <label htmlFor={`name-${index}`}>Amount</label>
                                <input
                                    className="input-field "
                                    type="number"
                                    onChange={(e) => handlePercentageChange(index, e)}
                                    name="amount"
                                    placeholder="Enter Amount"
                                    value={item?.amount}
                                    autoComplete="off"
                                />
                            </div>:""
                        }
                        {(item?.automatic === "1" || item?.automatic === 1 || item?.is_incentive === 1 || item?.is_incentive === "1") ?
                            <div className="field-block">
                                <label htmlFor={`name-${index}`}>Formula</label>
                                <Select
                                    id={`name-${index}`}
                                    options={allowanceOptions}
                                    onChange={(e) => handleFormulaSelect(index, e)}
                                    name="name"
                                    value={item?.name}
                                    isSearchable
                                    isMulti
                                />
                            </div> : <div className="field-block"> </div>
                        }

                        <div className="field-block">
                            <button onClick={()=>handleAllowanceRemove(index)} type="button" className="employee-add-button mt-6 w-14" >
                                <span className="submit-button-label"> <DeleteOutlineIcon /></span>
                            </button>
                        </div>
                    </div>
                ))}

            </form >
        </>
    );
}

export default EmployeeBasicInfo;



// import React, { useState, useEffect } from "react";
// import { useParams } from "react-router-dom";
// import { css } from "@emotion/react";
// import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
// import Checkbox from "@mui/material/Checkbox";
// import { POST_API } from "../../../../Services/api";
// import { ClipLoader } from "react-spinners";
// import Joi from "joi";
// import Select from "react-select";
// import alertify from "alertifyjs";
// import "alertifyjs/build/css/alertify.css";

// const override = css`
//   display: block;
//   margin: 0 auto;
//   border-color: red;
// `;

// function EmployeeBasicInfo({ isBtnDisable, nextTab, allowanceData, setAllowanceData, allowanceOptions }) {
//     const { id } = useParams();
//     const [isDisable, setIsDisable] = useState(false)
//     const [formData, setFormData] = useState({
//         allowance: [{ allowance: '', name: '' }],
//     });

//     const [errors, setErrors] = useState({});

//     const schema = Joi.object().keys({
//         allowance: Joi.array().items(
//             Joi.object().keys({
//                 name: Joi.string().required().label('Item Name')
//             })
//         ),
//     });

//     const validate = () => {
//         const options = { abortEarly: false };
//         const { error } = schema.validate(allowanceData, options);

//         if (!error) return null;

//         const validationErrors = {};
//         for (let item of error.details) {
//             validationErrors[item.path[0]] = item.message;
//         }

//         return validationErrors;
//     };

//     const handleAllowanceSelect = (index, e, name) => {
//         const { value } = e;
//         const newItems = [...allowanceData];
//         newItems[index][name] = value;
//         setAllowanceData(newItems);
//     };

//     const handleFormulaSelect = (index, e) => {
//         const option =  allowanceData.map((item, i) => {
//             if (i === index) {
//                 return {...item, name:e}
//             } else {
//                 return item
//             }
//         })
//         setAllowanceData(option)
//     };

//     const handleAllowanceAutomatic = (index, e, name) => {
//         const { checked } = e?.target;
//         const newItems = [...allowanceData];
//         newItems[index][name] = checked ? 1 : 0;
//         setAllowanceData(newItems);
//     }

//     const handleAllowanceAdd = () => {
//         setAllowanceData([...allowanceData, { allowance_id: '', name: [], select_type: "", automatic: "" }]);
//     };

//     const handleAllowanceRemove = (index) => {
//         if (allowanceData?.length <= 1 && index === 0) {
//             alertify.error("Unable to delete");
//         } else {
//             const newItems = [...allowanceData];
//             newItems.splice(index, 1);
//             setAllowanceData(newItems);
//         }

//     };

//     useEffect(() => {
//         setErrors(validate());
//     }, [formData]);

//     const handleValidation = async (e) => {
//         e.preventDefault();
//         const submitData = {
//             client_id: id,
//             allowance: allowanceData?.map((item, i)=>{
//                 return {...item, name:item?.name?.length ? item?.name?.map(item=> item?.value).toString(): ""}
//             })
//         }
//         // console.log("submitData", submitData);
//         try {
//             setIsDisable(true)
//             const basicInfo = await POST_API(`/updateAllowance/${id}`, submitData);
//             if (basicInfo?.status === 200) {
//                 setIsDisable(false)
//                 nextTab("deduction_info");
//             }
//         } catch (error) {
//             setIsDisable(false)
//         }
//     };

//     const dailyMonthlyOptions = [
//         { label: "Please Select", value: "" },
//         { label: "Daily", value: "daily" },
//         { label: "Monthly", value: "monthly" },
//     ]

//     return (
//         <>
//             <form>
//                 <div className="flex employee-sub-header">
//                     <h3 className="text-text-dark text-lg font-semibold w-1/2">Allowance </h3>
//                     <div className="w-1/2 text-right">
//                         <button onClick={handleAllowanceAdd} type="button" className="employee-add-button"><span className="submit-button-label">Add new</span></button>
//                     </div>
//                 </div>

//                 {allowanceData?.map((item, index) => (

//                     <div className="p-2 grid grid-cols-5 gap-5" key={index}>
//                         <div className="field-block">
//                             <label htmlFor={`name-${index}`}>Allowance</label>
//                             <Select
//                                 id={`name-${index}`}
//                                 options={allowanceOptions}
//                                 onChange={(e) => handleAllowanceSelect(index, e, "allowance_id")}
//                                 name="allowance"
//                                 value={allowanceOptions?.find(
//                                     (option) => option?.value === item.allowance_id
//                                 )}
//                                 isSearchable
//                             />
//                         </div>
//                         <div className="field-block">
//                             <label htmlFor={`name-${index}`}>Daily/Monthly</label>
//                             <Select
//                                 id={`name-${index}`}
//                                 options={dailyMonthlyOptions}
//                                 onChange={(e) => handleAllowanceSelect(index, e, "select_type")}
//                                 name="allowance"
//                                 value={dailyMonthlyOptions?.find(
//                                     (option) => option?.value === item.select_type
//                                 )}
//                                 isSearchable
//                             />
//                         </div>
//                         <div className="field-block">
//                             <label htmlFor={`name-${index}`}>Automatic</label>
//                             <Checkbox
//                                 style={{ color: "rgb(193,49,76)", padding: 0 }}
//                                 className="p-0 bg-primary-color"
//                                 onChange={e => handleAllowanceAutomatic(index, e, "automatic")}
//                                 inputProps={{ "aria-label": "controlled" }}
//                                 checked={(item?.automatic === "1" || item?.automatic === 1) ? true : false}
//                             />
//                         </div>
//                         {item?.automatic === 1 ?
//                             <div className="field-block">
//                                 <label htmlFor={`name-${index}`}>Formula</label>
//                                 <Select
//                                     id={`name-${index}`}
//                                     options={allowanceOptions}
//                                     onChange={(e) => handleFormulaSelect(index, e)}
//                                     name="name"
//                                     value={item?.name}
//                                     isSearchable
//                                     isMulti
//                                 />
//                             </div> : <div className="field-block"> </div>
//                         }
//                         <div className="field-block">
//                             <button onClick={handleAllowanceRemove} type="button" className="employee-add-button mt-6 w-14" >
//                                 <span className="submit-button-label"> <DeleteOutlineIcon /></span>
//                             </button>
//                         </div>
//                     </div>
//                 ))}

//             </form >
//             <div className="submit-reset-button">
//                 <button
//                     type="button"
//                     className="reset-button"
//                     onClick={() => {
//                         nextTab("basic_info");
//                     }}
//                 >
//                     <span className="reset-button-label">Prev</span>
//                 </button>
//                 <button
//                     type="submit"
//                     className="submit-button"
//                     disabled={isBtnDisable || isDisable}
//                     onClick={(e) => {
//                         handleValidation(e);
//                     }}
//                 >
//                     {(isBtnDisable || isDisable) ? <ClipLoader
//                         css={override}
//                         size={20}
//                         color={"#fff"}
//                         loading={isBtnDisable || isDisable}
//                     /> : ""}
//                     <span className="submit-button-label">{(isDisable || isBtnDisable) ? "Loading..." : "Next"}</span>
//                 </button>
//             </div>
//         </>
//     );
// }

// export default EmployeeBasicInfo;
