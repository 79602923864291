import React, { useState } from "react";

import MenuItems from "../MenuItems";
import { Link, useLocation } from "react-router-dom";
function HomePageMenu() {
  const [selectedMenu, setSelectedMenu] = useState("");
  const user_role = localStorage.getItem("user_role");

  const superAdminMenu = [
    {
      main_title: "Masters",
      links: [
        {
          name: "Employee",
          id: "employee_master",
          list: [
            {
              label: "Role",
              to: "/masters/role",
            },
            {
              label: "User",
              to: "/masters/user",
            },
            {
              label: "Allowance Type",
              to: "/masters/allowancetype",
            },
            {
              label: "Government ID Proof",
              to: "/masters/govtidproof",
            },
            {
              label: "Department",
              to: "/masters/department",
            },
            {
              label: "Shift",
              to: "/masters/shift",
            },
            {
              label: "Break",
              to: "/masters/break",
            },
            {
              label: "Break fine",
              to: "/masters/breakfine",
            },
            {
              label: "Caste",
              to: "/masters/caste",
            },
            {
              label: "Blood Broup",
              to: "/masters/bloodgroup",
            },
            {
              label: "Consultancy Agent",
              to: "/masters/agent/consultancy",
            },
            {
              label: "Security Agent",
              to: "/masters/agent/security",
            },
            {
              label: "Employement Type",
              to: "/masters/employementType",
            },
          ],
        },

        {
          name: "Branch",
          id: "branch_master",
          list: [
            {
              label: "Branch",
              to: "/masters/branch",
            },
            {
              label: "Bio Metrics",
              to: "/masters/biometrics",
            },
            {
              label: "Floor",
              to: "/masters/floor",
            },
          ],
        },

        {
          name: "Attendance",
          id: "attendance_master",
          list: [
            {
              label: "Attendance",
              to: "/masters/manualattendance",
            }
          ],
        },
        {
          name: "Finance",
          id: "finance_master",
          list: [
            {
              label: "Loan",
              to: "/masters/loan",
            },
            {
              label: "Bonus",
              to: "/masters/bonus",
            },
            {
              label: "Expenses",
              to: "/masters/expenses",
            },
            {
              label: "Fine",
              to: "/masters/fine",
            },
            {
              label: "Payment",
              to: "/masters/payment",
            },
            {
              label: "Bank",
              to: "/masters/bank",
            },
            {
              label: "Seasonal salary",
              to: "/masters/seasonalsalary",
            },
          ],
        },
        {
          name: "Holiday",
          id: "holiday_master",
          list: [
            {
              label: "Holiday scheme",
              to: "/masters/holidayscheme",
            },
            {
              label: "Holiday",
              to: "/masters/holiday",
            },
            {
              label: "Leave",
              to: "/masters/leave",
            },
          ],
        },
        {
          name: "Client",
          id: "client_master",
          list: [
            {
              label: "Client",
              to: "/masters/client/list",
            },
            {
              label: "Designation",
              to: "/masters/client/designation",
            },
            {
              label: "Allowances",
              to: "/masters/client/allowance",
            },
            {
              label: "Deductions",
              to: "/masters/client/deductions",
            },
          ],
        },
      ],
    },
    {
      main_title: "Employee Management",
      links: [
        {
          id: "employee_managment",
          list: [
            {
              label: "List",
              to: "/employee/list",
            },
            {
              label: "Add",
              to: "/employee/add",
            },
            {
              label: "Verify Certificate",
              to: "/employee/verify/certificate",
            },
            {
              label: "Terminated Employee",
              to: "/employee/terminated",
            },
          ],
        },
      ],
    },
    {
      main_title: "Loan & Expenses",
      links: [
        {
          id: "loan& expenses",
          list: [
            {
              label: "Loan",
              to: "/expense/loan",
            },
            {
              label: "Agent Commission",
              to: "/expense/agentcommission",
            },
            {
              label: "Bonus",
              to: "/expense/bonus",
            },
            {
              label: "Advance",
              to: "/expense/advance",
            },
            {
              label: "Expenses",
              to: "/expense",
            },
          ],
        },
      ],
    },
    {
      main_title: "Approve",
      links: [
        {
          id: "approve",
          list: [
            {
              label: "Leave",
              to: "/approve/leave",
            },
            {
              label: "Profile",
              to: "/approve/profile",
            },
            {
              label: "Finance",
              to: "/approve/finance",
            },
            {
              label: "Bonus",
              to: "/approve/bonus",
            },
            {
              label: "Expense",
              to: "/approve/expense",
            },
          ],
        },
      ],
    },
    {
      main_title: "Payroll",
      links: [
        {
          id: "payroll",
          list: [
            {
              label: "Payslip",
              to: "/payroll/payslip",
            },
            {
              label: "Download Payslip",
              to: "/payroll/payslip/download",
            },
            {
              label: "Manual Deduction",
              to: "/payroll/manual/deduction",
            },
          ],
        },
      ],
    },

    {
      main_title: "Reports",
      links: [
        {
          id: "report",
          list: [
            {
              label: "Employee Report",
              to: "/report/employee",
            },
            {
              label: "Loan Report",
              to: "/report/loan",
            },
            {
              label: "Leave Report",
              to: "/report/leave",
            }
          ],
        },
      ],
    },
  ];

  const adminMenu = [
    {
      main_title: "Employee Management",
      links: [
        {
          id: "employee_managment",
          list: [
            {
              label: "List",
              to: "/employee/list",
            },
            {
              label: "Add",
              to: "/employee/add",
            },
            {
              label: "Verify Certificate",
              to: "/employee/verify/certificate",
            },
            {
              label: "Terminated Employee",
              to: "/employee/terminated",
            },
          ],
        },
      ],
    },
    {
      main_title: "Loan & Expenses",
      links: [
        {
          id: "loan& expenses",
          list: [
            {
              label: "Loan",
              to: "/expense/loan",
            },
            {
              label: "Agent Commission",
              to: "/expense/agentcommission",
            },
            {
              label: "Bonus",
              to: "/expense/bonus",
            },
            {
              label: "Advance",
              to: "/expense/advance",
            },
            {
              label: "Expenses",
              to: "/expense",
            },
          ],
        },
      ],
    },
    {
      main_title: "Approve",
      links: [
        {
          // name: "report",
          id: "approve",
          list: [
            {
              label: "Leave",
              to: "/approve/leave",
            },
            {
              label: "Profile",
              to: "/approve/profile",
            },
            {
              label: "Finance",
              to: "/approve/finance",
            },
            {
              label: "Bonus",
              to: "/approve/bonus",
            },
            {
              label: "Expense",
              to: "/approve/expense",
            },
          ],
        },
      ],
    },
    {
      main_title: "Payroll",
      links: [
        {
          id: "payroll",
          list: [
            {
              label: "Payslip",
              to: "/payroll/payslip",
            },
            {
              label: "Download Payslip",
              to: "/payroll/payslip/download",
            },
            {
              label: "Manual Deduction",
              to: "/payroll/manual/deduction",
            },
            // {
            //   label: "Manual PF",
            //   to: "/payroll/manualpf",
            // },
          ],
        },
      ],
    },

    {
      main_title: "Reports",
      links: [
        {
          id: "report",
          list: [
            {
              label: "Employee Report",
              to: "/report/employee",
            },
            {
              label: "Loan Report",
              to: "/report/loan",
            },
            {
              label: "Leave Report",
              to: "/report/leave",
            },
          ],
        },
      ],
    },
  ];

  const currentMenu = Number(user_role) === 1 ? superAdminMenu : adminMenu;

  let location = useLocation();
  const currentLocation = location?.pathname.split("/")[1];

  return (
    <div
      className="h-[calc(100vh-102px)] overflow-x-hidden w-[20%] Shadow-lg"
      id="toggle-menu"
    >
      <div className=" pl-[8px] pr-[8px] pt-[12px]">
        {/* <div className="flex justify-end">
          <img
            src={Toggle}
            alt=""
            className="cursor-pointer"
            onClick={handleToggle}
          />
        </div> */}
        <Link
          to="dashboard"
          className={`font-montserrat text-base whitespace-nowrap font-medium ${currentLocation === "dashboard"
              ? "text-text-dark"
              : "text-text-light"
            }`}
        >
          Dashboard
        </Link>
      </div>
      <div className="flex flex-col gap-1">
        {currentMenu.map((nav, i) => {
          return (
            <div key={i}>
              <MenuItems
                selected={selectedMenu === nav.main_title}
                title={nav?.main_title}
                to={nav?.to}
                links={nav?.links}
                handleMenuClick={setSelectedMenu}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default HomePageMenu;
