import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { DELETE_API, GET_API } from "../../../Services/api";
import Validation from "../../../Validation/Validation";
import { POST_API } from "../../../Services/api";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import AllowanceListTable from "../../DataTable/AllowanceListTable";
import Search from "../../../Assets/Icons/Search.svg";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import Edit from "../../../Assets/Icons/Edit.svg";
import More from "../../../Assets/Icons/More.svg";
import Spinner from "../../Spinner";

function Allowance() {
  const [allowanceTypeList, setAllowanceTypeList] = useState([]);
  const [errors, seterrors] = useState({});
  const [buttonLabel, setbuttonLabel] = useState("Add");
  const [allowanceTypeId, setAllowanceTypeId] = useState();
  const [editStatus, setEditStatus] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allowanceTypeDetails, setAllowanceTypeDetails] = useState({
    name: "",
    description: "",
    type: "allowanceType",
  });

  const header = [
    { id: "s.no", label: "S.No" },
    { id: "allowance_name", label: "Allowance Type Name" },
    { id: "description", label: "Description" },
    { id: "created_at", label: "Created at" },
    { id: "more_icon", label: "" },
  ];

  useEffect(() => {
    getAllowanceTypeList();
  }, []);

  const getAllowanceTypeList = async () => {
    setLoading(true);
    try {
      const allowanceTypeList = await GET_API("/AllowanceTypeList");
      console.log(allowanceTypeList.data.data);
      if (allowanceTypeList.status === 200) {
        setLoading(false);
        setAllowanceTypeList(allowanceTypeList?.data?.data);
        setFilteredUser(allowanceTypeList?.data?.data);
      }
    } catch (e) {
      setLoading(false);
      alertify.error(e?.data?.response?.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAllowanceTypeDetails(() => ({ ...allowanceTypeDetails, [name]: value }));
  };
  const [filteredUser, setFilteredUser] = useState([]);

  const handleAllowanceSearch = (e) => {
    const searchTerm = e.target.value;
    const searchedList = allowanceTypeList.filter((item) => {
      for (let key in item) {
        if (
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
    setFilteredUser(searchedList);
  };

  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Allowance..?",
        async function () {
          if (row_id) {
            const deleteRow = await DELETE_API(`/deleteAllowance/${row_id}`);
            if (deleteRow.status === 200) {
              alertify.success(deleteRow?.data?.message);
              getAllowanceTypeList();
              return;
            }
            alertify.error("Failed To Delete Allowance");
          }
        },
        function () {}
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };
  const addAllowance = () => {
    setButtonDisabled(true);
    var allowanceTypeData = {
      ...allowanceTypeDetails,
      id: allowanceTypeId ? allowanceTypeId : null,
    };
    const isValid = Validation.ValidateAllowanceType(allowanceTypeData);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    POST_API("/addAllowance", allowanceTypeDetails)
      .then(async function (data) {
        setButtonDisabled(false);
        if (data.data.status === 403) {
          alertify.error(data?.data?.message);
        }
        alertify.success(data?.data?.message);
        getAllowanceTypeList();
        setAllowanceTypeDetails({
          ...allowanceTypeDetails,
          name: "",
          description: "",
        });
      })
      .catch((error) => {
        setButtonDisabled(false);
        alertify.error(error.message);
      });
  };
  const saveAllowance = () => {
    setButtonDisabled(true);
    var allowanceTypeData = {
      ...allowanceTypeDetails,
      id: allowanceTypeId ? allowanceTypeId : null,
    };
    const isValid = Validation.ValidateAllowanceType(allowanceTypeData);
    if (isValid && isValid.error) {
      setButtonDisabled(false);

      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    POST_API("/updateAllowanceType", allowanceTypeDetails)
      .then(async function (data) {
        setButtonDisabled(false);
        setEditStatus(false);
        if (data.data.status === 403) {
          alertify.error(data?.data?.message);
        }
        alertify.success(data?.data?.message);
        getAllowanceTypeList();
        setAllowanceTypeDetails((prevState) => ({
          ...prevState,
          name: "",
          description: "",
        }));
        setbuttonLabel("Add");
      })
      .catch((error) => {
        setButtonDisabled(false);
        alertify.error(error?.response?.data?.message);
      });
  };
  const resetForm = () => {
    setAllowanceTypeDetails({
      name: "",
      description: "",
      type: "allowanceType",
    });
    setEditStatus(false);
    seterrors({});
  };
  if (loading) {
    return <Spinner />;
  }
  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">Allowance Type</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Add Allowance Type</h4>
          <div className="search-block">
            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                onChange={(e) => handleAllowanceSearch(e)}
                style={{ outline: "none", border: "none" }}
              />

              <img src={Search} alt="" />
            </div>
          </div>
          <div className="add-list-container">
            <div className="input-block">
              <label className="add-new-input-label">
                Allowance Type Name <span className="required-symbol">*</span>
              </label>
              <input
                type="text"
                name="name"
                placeholder="Enter Allowance Type Name"
                autoComplete="off"
                className="input-field"
                onChange={(e) => handleChange(e)}
                value={allowanceTypeDetails.name}
              />
              {errors.name ? (
                <div className="text-[#FF5C42] text-sm font-medium font-karla">
                  {errors.name}
                </div>
              ) : null}
            </div>
            <div className="input-block">
              <label className="add-new-input-label">Description</label>
              <textarea
                type="text"
                name="description"
                placeholder="Enter Description"
                autoComplete="off"
                className="input-field"
                onChange={(e) => handleChange(e)}
                value={allowanceTypeDetails.description}
              />
            </div>

            <div className="flex gap-3">
              <div className="add-new-button-block">
                {editStatus ? (
                  <button
                    className="add-new-button"
                    disabled={buttonDisabled}
                    onClick={() => saveAllowance()}
                  >
                    <span className="button-label">Save</span>
                    <img src={ColorAdd} alt="add" />
                  </button>
                ) : (
                  <button className="add-new-button" disabled={buttonDisabled}>
                    <span
                      className="button-label"
                      onClick={() => addAllowance()}
                    >
                      Add New
                    </span>
                    <img src={ColorAdd} alt="add" />
                  </button>
                )}
              </div>
              <Link className="edit-button" onClick={resetForm}>
                <span className="button-label">Reset</span>
                <img src={Edit} alt="add" />
              </Link>
            </div>
          </div>
          <AllowanceListTable
            columns={header}
            list={filteredUser}
            icon={More}
            multiple={false}
            deleteRow={handleDelete}
            editStatus={setEditStatus}
            editList={setAllowanceTypeDetails}
          />
        </div>
      </div>
    </div>
  );
}

export default Allowance;
