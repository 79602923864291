import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import View from "../../../Assets/Icons/View.svg";
import { GET_API } from "../../../Services/api";
import config from "../../../Services/config.json";

function ManualAttendanceDetails() {
  const { id } = useParams();
  const [empDetail, setEmpDetail] = useState({});
  useEffect(() => {
    getManualAttendanceDetails();
  }, []);
  const getManualAttendanceDetails = async () => {
    GET_API(`/getManualAttendance/${id}`)
      .then(async function (data) {
        setEmpDetail(data?.data?.message);
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className=" sm-container">
        <h1 className="heading-text">Attendance</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Manual Attendance Details</h4>
          <div className="search-block">
            <Link to="/masters/manualattendance" className="view-list-button">
              <span>View List</span>
              <img src={View} alt="add" />
            </Link>
          </div>
          <div className="grid grid-cols-3 font-montserrat">
            <div className="py-6 px-6 gap-8">
              <div className="flex justify-start py-4 px-4 gap-8">
                <label className="text-[#271E0D] text-base font-medium font-montserrat">
                  Employee Code
                </label>
                <div
                  style={{ wordBreak: "break-word" }}
                  className="text-[#7B7B7B] text-base font-karla"
                >
                  {empDetail?.empCode}
                </div>
              </div>
              <div className="flex py-4 px-4 gap-8">
                <label className="text-[#271E0D] text-base font-medium font-montserrat">
                  Employee Name
                </label>
                <div
                  style={{ wordBreak: "break-word" }}
                  className="text-[#7B7B7B] text-base font-karla"
                >
                  {empDetail?.first_name}
                </div>
              </div>
              <div className="flex py-4 px-4 gap-8">
                <label className="text-[#271E0D] text-base font-medium font-montserrat">
                  Branch
                </label>
                <div
                  style={{ wordBreak: "break-word" }}
                  className="text-[#7B7B7B] text-base font-karla"
                >
                  {empDetail?.branchName}
                </div>
              </div>
              <div className="flex py-4 px-4 gap-8">
                <label className="text-[#271E0D] text-base font-medium font-montserrat">
                  Fine Type
                </label>
                <div
                  style={{ wordBreak: "break-word" }}
                  className="text-[#7B7B7B] text-base font-karla"
                >
                  {empDetail?.fine_type_name}
                </div>
              </div>
              <div className="flex py-4 px-4 gap-8">
                <label className="text-[#271E0D] text-base font-medium font-montserrat">
                  Fine Amount
                </label>
                <div
                  style={{ wordBreak: "break-word" }}
                  className="text-[#7B7B7B] text-base font-karla"
                >
                  {empDetail?.fine_amount}
                </div>
              </div>
              <div className="flex py-4 px-4 gap-8">
                <label className="text-[#271E0D] text-base font-medium font-montserrat">
                  Approval Status
                </label>
                <div
                  style={{ wordBreak: "break-word" }}
                  className="text-[#7B7B7B] text-base font-karla"
                >
                  {empDetail?.approval_status === "approved" ? (
                    <span
                      className=" bg-[#3EAB82] p-2"
                      style={{ color: "#FFF", borderRadius: "4px" }}
                    >
                      Approved
                    </span>
                  ) : (
                    <span
                      className=" bg-[#FF5C42] p-2"
                      style={{ color: "#FFF", borderRadius: "4px" }}
                    >
                      Pending
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="py-6 px-6 gap-8">
              <div className="flex py-4 px-4 gap-8">
                <label className="text-[#271E0D] text-base font-medium font-montserrat">
                  Department
                </label>
                <div
                  style={{ wordBreak: "break-word" }}
                  className="text-[#7B7B7B] text-base font-karla"
                >
                  {empDetail?.deptName}
                </div>
              </div>
              <div className="flex justify-start py-4 px-4 gap-8">
                <label className="text-[#271E0D] text-base font-medium font-montserrat">
                  Designation
                </label>
                <div
                  style={{ wordBreak: "break-word" }}
                  className="text-[#7B7B7B] text-base font-karla"
                >
                  {empDetail?.desName}
                </div>
              </div>

              <div className="flex justify-start py-4 px-4 gap-8">
                <label className="text-[#271E0D] text-base font-medium font-montserrat">
                  Shift
                </label>
                <div
                  style={{ wordBreak: "break-word" }}
                  className="text-[#7B7B7B] text-base font-karla"
                >
                  {empDetail?.shiftName}
                </div>
              </div>

              <div className="flex py-4 px-4 gap-8">
                <label className="text-[#271E0D] text-base font-medium font-montserrat">
                  Approved By
                </label>
                <div
                  style={{ wordBreak: "break-word" }}
                  className="text-[#7B7B7B] text-base font-karla"
                >
                  {empDetail?.approver_name} {empDetail?.approver_fathers_name}
                </div>
              </div>
            </div>
            <div className="py-6 px-6 gap-8">
              <div className="flex py-4 px-4 gap-8">
                <div
                  style={{ wordBreak: "break-word" }}
                  className="text-[#7B7B7B] text-base font-karla"
                >
                  <img
                    src={`${config.path}${empDetail?.photo_path}`}
                    alt=""
                    style={{ height: "120px", width: "120px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManualAttendanceDetails;
