import React, { useState, useEffect } from "react";
import More from "../../../Assets/Icons/More.svg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { GET_API, DELETE_API } from "../../../Services/api";
import Search from "../../../Assets/Icons/Search.svg";
import ClientMasterListTable from "../../DataTable/ClientMasterListTable";
import Spinner from "../../Spinner";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";

function HolidayList() {
  const navigate = useNavigate();
  const [holidatyList, setHolidatyList] = useState([]);
  const [filteredHolidayList, setFilteredHolidayList] = useState([]);
  const [loading, setLoading] = useState(false);

  const showDetails = (listDetail) => {
    navigate(`/masters/client/details/${listDetail?.id}`);
  };

  const navigateEditPage = (listDetail) => {
    navigate(`/masters/client/edit/${listDetail?.id}`, {
      state: {
        list: listDetail,
      },
    });
  };

  const allowanceDetails = (listDetail) => {
    navigate(`/masters/client/allowance/deduction/list/${listDetail?.id}`, {
      state: {
        list: listDetail,
      },
    });
  };

  const columns = [
    { id: "s.no", label: "S.No", minWidth: 170 },
    { id: "name", label: "Name", minWidth: 100 },
    { id: "location", label: "Location", minWidth: 100 },
    { id: "contact_person_name", label: "Contact Person Name", minWidth: 100 },
    { id: "phone", label: "Phone", minWidth: 100 },
    { id: "address", label: "Address", minWidth: 100 },
    { id: "created_at", label: "Created at", minWidth: 100 },
    { id: "more_icon", label: "", minWidth: 100 },
  ];

  const getClientsList = async () => {
    setLoading(true);
    try {
      const response = await GET_API("clientLists")
      if (response?.status === 200) {
        setHolidatyList(response?.data?.data);
        setFilteredHolidayList(response?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      setHolidatyList([]);
      setFilteredHolidayList([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    getClientsList();
  }, []);

  const handleBranchSearch = (e) => {
    const searchTerm = e.target.value;
    const searchedList = holidatyList.filter((item) => {
      for (let key in item) {
        if (
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
    setFilteredHolidayList(searchedList);
  };

  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Client",
        async function () {
          if (row_id) {
            try {
              const deleteRow = await DELETE_API(
                `/deleteClient/${row_id}`
              );
              if (deleteRow.status === 200) {
                alertify.success(deleteRow?.data?.message);
                getClientsList();
                return;
              }
            } catch (error) {
              alertify.error("Failed To Delete Client");
            }
          }
        },
        function () { }
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">Clients</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Client List</h4>
          <div className="search-block">
            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                onChange={(e) => handleBranchSearch(e)}
                style={{ outline: "none", border: "none" }}
              />
              <img src={Search} alt="" />
            </div>
            <div className="flex gap-3">
              <div className="add-new-button-block">
                <Link to="/masters/client/add" className="add-new-button">
                  <span>Add New</span>
                  <img src={ColorAdd} alt="add" />
                </Link>
              </div>
            </div>
          </div>
          <ClientMasterListTable
            list={filteredHolidayList}
            columns={columns}
            icon={More}
            multiple={true}
            editPage={navigateEditPage}
            showDetails={showDetails}
            deleteRow={handleDelete}
            allowance={true}
            allowanceDetails={allowanceDetails}
          />
        </div>
      </div>

    </div>
  );
}

export default HolidayList;
