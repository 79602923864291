import React from "react";
import { Link, useLocation } from "react-router-dom";
import View from "../../../Assets/Icons/View.svg";
import Edit from "../../../Assets/Icons/Edit.svg";

function DepartmentDetails() {
  const { state } = useLocation();
  const listDetails = state?.list;
  const Details = [
    {
      label: "Department Name",
      value: listDetails.name ? listDetails.name : "-",
    },
    {
      label: "Slug",
      value: listDetails.slug ? listDetails.slug : "-",
    },
    {
      label: "Description",
      value: listDetails.description ? listDetails.description : "-",
    },
  ];

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className=" sm-container">
        <h1 className="heading-text">Department</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Department Details</h4>
          <div className="add-block">
            <Link to="/masters/department" className="view-list-button">
              <span>View List</span>
              <img src={View} alt="add" />
            </Link>
            <Link
              to="/masters/department/edit"
              className="edit-button"
              state={{ list: listDetails }}
            >
              <span>Edit</span>
              <img src={Edit} alt="add" />
            </Link>
          </div>
          <div className="list-container">
            <div className="list-content-block">
              {Details.map((list) => {
                return (
                  <div className="list ">
                    <label className="list-label">{list.label}</label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="list-value"
                    >
                      {list.value}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DepartmentDetails;
