import React, { useState, useRef } from "react";
import Checkbox from "@mui/material/Checkbox";
import Select from "react-select";
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import { useParams } from "react-router-dom";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import Validation from "../../../Validation/Validation";
import AddressForm from "./Components/Address";
import { POST_API } from "../../../Services/api";
import { css } from "@emotion/react";
import { ClipLoader } from "react-spinners";
import ProfilePicture from "./Components/ProfilePicture";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

function EmployeeBasicInfo({ stateChanger, empDetails, isBtnDisable, handleSameAsPermanentAddress, employeeList, baseurl, submitEmployeeDetails, nextTab }) {
  const { id } = useParams();
  const datePickerRef = useRef(null);
  const [referredByType, setReferredByType] = useState(null)
  const [errors, seterrors] = useState({});
  const [isDisable, setIsDisable] = useState(false);

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    if (empDetails?.same_as_permanent_address) {
      const presentAddressMapping = {
        present_address_door_no: "permanent_address_door_no",
        present_address_street_name: "permanent_address_street_name",
        present_address_street_name_two: "permanent_address_street_name_two",
        present_address_city: "permanent_address_city",
        present_address_state: "permanent_address_state",
        present_address_pincode: "permanent_address_pincode"
      };
      const mappedName = presentAddressMapping[name];
      if (mappedName) {
        stateChanger(() => ({ ...empDetails, [name]: value, [mappedName]: value }));
      }
    } else {
      stateChanger(() => ({ ...empDetails, [name]: value }));
    }
  };

  const handleAddressSelectChange = (e, name) => {
    const { value } = e;
    if (empDetails?.same_as_permanent_address) {
      const presentAddressMapping = {
        present_address_door_no: "permanent_address_door_no",
        present_address_street_name: "permanent_address_street_name",
        present_address_street_name_two: "permanent_address_street_name_two",
        present_address_city: "permanent_address_city",
        present_address_state: "permanent_address_state",
        present_address_pincode: "permanent_address_pincode"
      };
      const mappedName = presentAddressMapping[name];
      if (mappedName) {
        stateChanger(() => ({ ...empDetails, [name]: value, [mappedName]: value }));
      }
    } else {
      stateChanger(() => ({ ...empDetails, [name]: value }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    stateChanger(() => ({ ...empDetails, [name]: value }));
  };

  const handleSelectChange = (e, name) => {
    stateChanger(() => ({ ...empDetails, [name]: e?.value }));
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    const selectedDate = new Date(value);
    const today = new Date();

    let ageYears = today.getFullYear() - selectedDate.getFullYear();
    let ageMonths = today.getMonth() - selectedDate.getMonth();

    if (today.getDate() < selectedDate.getDate()) {
      ageMonths--;
    }

    if (ageMonths < 0) {
      ageYears--;
      ageMonths = 12 + ageMonths;
    }
    const age = (`${ageYears} Years ${ageMonths} ${ageMonths > 2 ? "Months" : "Month"}`);
    stateChanger(() => ({ ...empDetails, [name]: value, age: age }));
  }

  const handleValidation = async (e) => {
    e.preventDefault();
    const isValid = Validation.validateEmployeeBasicInfo(empDetails);
    if (isValid && isValid.error) {
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
      if (id) {
        try {
          setIsDisable(true)
          const basicInfo = await POST_API(`/updateEmployeeTentacles/${id}`, empDetails);
          if (basicInfo?.data?.status === 200) {
            setIsDisable(false)
            nextTab("personal_info");
          }
        } catch (error) {
          console.log("error", error);
          setIsDisable(false)
          // handleBtnDisable(false)
        }
      } else {
        submitEmployeeDetails(e)
      }
    }
  };

  const handleReferredBy = (e) => {
    const { value } = e?.target
    stateChanger({ ...empDetails, refrred_by: value })
  }

  const genderOptions = [
    { value: "", label: "Please Select" },
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "others", label: "Others" },
  ];

  const maritalStatus = [
    { value: "", label: "Please Select" },
    { value: "single", label: "Single" },
    { value: "married", label: "Married" },
  ];

  const handleDatePicker = (e) => {
    const created_date = dayjs(e).format("YYYY-MM-DD");
    stateChanger(() => ({ ...empDetails, joining_date: created_date }));
  };

  const handleProfileImage = (e) => {
    const { name } = e?.target;
    const file = e?.target?.files[0];

    if (file) {
      const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
      const maxSizeKB = 200;

      if (allowedTypes.includes(file.type)) {
        if (file.size / 1024 <= maxSizeKB) {
          const fileReader = new FileReader();

          fileReader.onload = () => {
            if (fileReader.readyState === 2) {
              stateChanger({ ...empDetails, [name]: fileReader?.result });
            }
          };
          if (file.type.includes('image')) {
            fileReader.readAsDataURL(file);
            seterrors({ [name]: "" })
          }
        } else {
          seterrors({ [name]: "File size exceeds 200 KB limit" })
        }
      } else {
        seterrors({ [name]: "Invalid file type. Please choose a valid image (png, jpg, jpeg)" })
      }
    }
  };

  const getMaxDate = () => {
    const today = new Date();
    const maxDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
    return maxDate.toISOString().split("T")[0];
  };

  return (
    <>
      <form>
        <div className="p-2 grid grid-cols-3 gap-5">
          <ProfilePicture
            label="Profile Picture"
            value={empDetails?.profile_picture}
            imgSrc={baseurl}
            name="profile_picture"
            isRequired={true}
            errors={errors?.profile_picture}
            handleFileChange={handleProfileImage}
            accept=".jpeg,.jpg,.png"
            stateChanger={stateChanger}
            empDetails={empDetails}
          />

          <div className="field-block">
            <label className="label-name">
              Joining Date
              <span className="required-symbol">*</span>
            </label>
            <div className="w-full">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                  name="joining_date"
                  className="input-field-date-picker w-full"
                  value={dayjs(empDetails?.joining_date)}
                  ref={datePickerRef}
                  disableCloseOnSelect={true}
                  format="DD-MM-YYYY"
                  onChange={(e) => {
                    handleDatePicker(e);
                  }}
                />
              </LocalizationProvider>
              {errors?.joining_date ? (
                <div className="validation-error-label" key={errors?.joining_date} >
                  {errors?.joining_date}
                </div>
              ) : null}
            </div>
          </div>

          <div className="field-block">
            <label className="label-name">
              Employee Name
              <span className="required-symbol">*</span>
            </label>
            <input
              className="input-field "
              type="text"
              onChange={(e) => handleChange(e)}
              name="name"
              placeholder="Enter Employee Name"
              value={empDetails?.name}
              autoComplete="off"
            />
            {errors?.name ? (
              <div className="validation-error-label" key={errors?.name}>
                {errors?.name}
              </div>
            ) : null}
          </div>
          {id ?
            <div className="field-block">
              <label className="label-name">
                Employee Code
                <span className="required-symbol">*</span>
              </label>
              <input
                className="input-field "
                type="text"
                onChange={(e) => handleChange(e)}
                name="employee_code"
                placeholder="Employee Code"
                value={empDetails?.employee_code}
                readOnly={true}
                disabled={true}
                autoComplete="off"
              />
              {errors?.employee_code ? (
                <div className="validation-error-label" key={errors?.employee_code}  >
                  {errors?.employee_code}
                </div>
              ) : null}
            </div> : null}
          <div className='create-employee'>
            <label className="label-name">
              Referred By
              <span className="required-symbol">*</span>
            </label>
            <RadioGroup value={empDetails?.refrred_by} onChange={handleReferredBy} className='scsadcasqc block'>
              <FormControlLabel
                value="ad"
                control={<Radio style={{ color: '#C1314C' }} />}
                label="Ad"
                name="addd"
              />
              <FormControlLabel
                value="oldEmployee"
                control={<Radio style={{ color: '#C1314C' }} />}
                label="Our Existing Employee"
                name="adddEmp"
              />
              <FormControlLabel
                value="others"
                control={<Radio style={{ color: '#C1314C' }} />}
                label="Others"
                name="adddEmp"
              />
            </RadioGroup>
            {errors?.refrred_by ? (
              <div className="validation-error-label">{referredByType !== "oldEmployee" ? errors?.refrred_by : ""}</div>
            ) : null}
          </div>
          {empDetails?.refrred_by === "oldEmployee" ?
            <div className="field-block">
              <label className="label-name">
                Existing Employee
                <span className="required-symbol">*</span>
              </label>
              <Select
                options={employeeList}
                onChange={(e) => handleSelectChange(e, "ref_by_others")}
                name="ref_by_others"
                value={employeeList?.find(
                  (option) => option?.value === Number(empDetails.ref_by_others)
                )}
                className=""
                isSearchable
              />
              {errors?.ref_by_others ? (
                <div className="validation-error-label">{errors?.ref_by_others}</div>
              ) : null}
            </div> : ""}
          {empDetails?.refrred_by === "others" ?
            <div className="field-block">
              <label className="label-name">
                Others
                <span className="required-symbol">*</span>
              </label>
              <input
                name="ref_by_others"
                className="input-field"
                type="text"
                onChange={(e) => handleChange(e)}
                placeholder="Enter Others"
                autoComplete="off"
                value={empDetails?.ref_by_others}
              />
              {errors?.ref_by_others ? (
                <div className="validation-error-label">{errors?.ref_by_others}</div>
              ) : null}
            </div> : ""}
          <div className="field-block">
            <label className="label-name">Father Name<span className="required-symbol">*</span></label>
            <input
              name="father_name"
              className="input-field"
              type="text"
              onChange={(e) => handleChange(e)}
              placeholder="Enter Father Name"
              autoComplete="off"
              value={empDetails?.father_name}
            />
            {errors.father_name ? (
              <div className="validation-error-label">{errors.father_name}</div>
            ) : null}
          </div>
          <div className="field-block">
            <label className="label-name">Mother Name<span className="required-symbol">*</span></label>
            <input
              name="mother_name"
              className="input-field"
              type="text"
              onChange={(e) => handleChange(e)}
              placeholder="Enter Mother Name"
              autoComplete="off"
              value={empDetails?.mother_name}
            />
            {errors.mother_name ? (
              <div className="validation-error-label">{errors.mother_name}</div>
            ) : null}
          </div>
          <div className="field-block">
            <label className="label-name">Aadhar Number<span className="required-symbol">*</span></label>
            <input
              name="aadhar_no"
              className="input-field"
              type="text"
              onChange={(e) => handleChange(e)}
              placeholder="Enter Aadhar Number"
              autoComplete="off"
              value={empDetails?.aadhar_no}
            />
            {errors.aadhar_no ? (
              <div className="validation-error-label">{errors.aadhar_no}</div>
            ) : null}
          </div>
          <div className="field-block">
            <label className="label-name">Date of Birth<span className="required-symbol">*</span></label>
            <input
              type="date"
              name="date_of_birth"
              className="input-field"
              placeholder="Select Date of Birth"
              autoComplete="off"
              // max={new Date().toISOString().split("T")[0]}
              max={getMaxDate()}
              onChange={(e) => {
                handleDateChange(e);
              }}
              value={empDetails["date_of_birth"]}
            />
            {errors.date_of_birth ? (
              <div className="validation-error-label">{errors.date_of_birth}</div>
            ) : null}
          </div>
          <div className="field-block">
            <label className="label-name">Age<span className="required-symbol">*</span></label>
            <input
              name="age"
              className="input-field"
              type="text"
              onChange={(e) => handleChange(e)}
              placeholder=""
              autoComplete="off"
              value={empDetails?.age}
              disabled
            />
            {errors.age ? (
              <div className="validation-error-label">{errors.age}</div>
            ) : null}
          </div>
          <div className="field-block">
            <label className="label-name">Email Id</label>
            <input
              className="input-field"
              type="text"
              autoComplete="off"
              onChange={(e) => handleChange(e)}
              name="email_id"
              placeholder="Enter Email ID"
              value={empDetails?.email_id}
            />
            {errors.email_id ? (
              <div className="text-[#FF5C42] text-sm font-medium font-karla">
                {errors.email_id}
              </div>
            ) : null}
          </div>
          <div className="field-block">
            <label className="label-name">
              Mobile Number
              <span className="font-bold" style={{ color: "#FF5C42" }}>
                *
              </span>
            </label>
            <input
              className="input-field"
              type="number"
              autoComplete="off"
              onChange={(e) => handleChange(e)}
              name="mobile_number"
              placeholder="Enter Phone Number"
              value={empDetails?.mobile_number}
            />
            {errors.mobile_number ? (
              <div className="text-[#FF5C42] text-sm font-medium font-karla">
                {errors.mobile_number}
              </div>
            ) : null}
          </div>
          <div className="field-block">
            <label className="label-name">Gender<span className="required-symbol">*</span></label>
            <Select
              options={genderOptions}
              value={genderOptions?.find(
                (option) => option?.value === empDetails.gender
              )}
              onChange={(e) => handleSelectChange(e, "gender")}
              name="gender"
              className=""
              isSearchable
            />
            {errors.gender ? (
              <div className="validation-error-label">{errors.gender}</div>
            ) : null}
          </div>
          <div className="field-block">
            <label className="label-name">Marital Status<span className="required-symbol">*</span></label>
            <Select
              options={maritalStatus}
              value={maritalStatus?.find(
                (option) => option?.value === empDetails.marital_status
              )}
              onChange={(e) => handleSelectChange(e, "marital_status")}
              name="marital_status"
              className=""
              isSearchable
            />
            {errors.marital_status ? (
              <div className="validation-error-label">{errors.marital_status}</div>
            ) : null}
          </div>
        </div>
        {/* Permanent Address */}
        <h3 className="card-header">Present Address</h3>
        <AddressForm empDetails={empDetails} handleAddressChange={handleAddressChange} handleAddressSelectChange={handleAddressSelectChange} errors={errors} type='presentAddress' />

        {/* Present Address */}
        <div className="card-header">
          <h3>Permanent Address</h3>
          <div className="flex">
            <h5 className="label-name">Same as Present Address</h5>
            <Checkbox
              style={{ color: "rgb(193,49,76)", padding: 0 }}
              className="p-0 bg-primary-color"
              onChange={handleSameAsPermanentAddress}
              inputProps={{ "aria-label": "controlled" }}
              checked={(Number(empDetails?.same_as_permanent_address) === 1 ||empDetails?.same_as_permanent_address === "yes") ? true : false}
            />
          </div>
        </div>
        <AddressForm empDetails={empDetails} handleAddressChange={handleAddressChange} handleAddressSelectChange={handleAddressSelectChange} errors={errors} type='permanentAddress' />
      </form>
      <div className="submit-reset-button">
        <button
          type="submit"
          className="submit-button"
          disabled={isBtnDisable || isDisable}
          onClick={(e) => {
            handleValidation(e);
          }}
        >
          {(isBtnDisable || isDisable) ? <ClipLoader
            css={override}
            size={20}
            color={"#fff"}
            loading={isBtnDisable || isDisable}
          /> : ""}
          <span className="submit-button-label">{(isBtnDisable || isDisable ) ? "Loading..." : "Save"}</span>
        </button>
        {id ?
          <button className="reset-button" disabled={isBtnDisable || isDisable} onClick={() => nextTab("personal_info")}>
            <span className="reset-button-label">Next</span>
          </button> : null}
      </div>
    </>
  );
}

export default EmployeeBasicInfo;
