import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import MoreOption from "../MoreOption";

export default function StickyHeadTable({
  list,
  columns,
  showDetails,
  icon,
  multiple,
  deleteRow,
  editPage,
}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 320 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, i) => (
                <TableCell
                  className="table-header"
                  key={i}
                  align={column.align}
                >
                  {column?.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan="8"
                  className="table-body td-data-not-found h-[220px]"
                >
                  Data Not Found..!
                </TableCell>
              </TableRow>
            ) : (
              list
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => {
                  const createdDate = new Date(row?.created_at);
                  const year = createdDate.getFullYear();
                  const month = String(createdDate.getMonth() + 1).padStart(
                    2,
                    "0"
                  );
                  const date = String(createdDate.getDate()).padStart(2, "0");
                  const hour = createdDate.getUTCHours();
                  var ampm = hour >= 12 ? "pm" : "am";
                  const minute = createdDate.getUTCMinutes();
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={i}
                      className="table-column cursor-text"
                    >
                      {
                        <TableCell className="table-body !w-[7%]">
                          {page * rowsPerPage + (i + 1)}
                        </TableCell>
                      }
                      {
                        <TableCell className="table-body">
                          {row.employeeCode}
                        </TableCell>
                      }
                      {
                        <TableCell className="table-body">
                          {row?.first_name} {row?.fathers_name}
                        </TableCell>
                      }
                      {
                        <TableCell className="table-body">
                          {row?.from_date}
                        </TableCell>
                      }
                      {
                        <TableCell className="table-body">
                          {row?.to_date}
                        </TableCell>
                      }
                      {
                        <TableCell className="table-body">
                          {row?.no_of_days}
                        </TableCell>
                      }
                      {
                        <TableCell className="table-body">
                          {row?.type === "leave" ? (
                            <span
                              className=" bg-[#03789F] px-2 py-1"
                              style={{
                                color: "#FFF",
                                borderRadius: "4px",
                              }}
                            >
                              Leave
                            </span>
                          ) : (
                            <span
                              className=" bg-[#11B76B] px-2 py-1"
                              style={{
                                color: "#FFF",
                                borderRadius: "4px",
                              }}
                            >
                              OD
                            </span>
                          )}
                        </TableCell>
                      }
                      {
                        <MoreOption
                          icon={icon}
                          row={row}
                          showDetails={showDetails}
                          multiple={multiple}
                          activeEdit={false}
                          activeDelete={false}
                        />
                      }
                    </TableRow>
                  );
                })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={list?.length ? list.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
