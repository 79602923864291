import React, { useState, useEffect } from "react";
import Select from "react-select";
import View from "../../../Assets/Icons/View.svg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { GET_API, POST_API } from "../../../Services/api";
import alertify from "alertifyjs";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import Spinner from "../../Spinner";
import Validation from "../../../Validation/Validation";
import "alertifyjs/build/css/alertify.css";

function CreateDesignation() {
  const navigate = useNavigate();

  const [fineList, setFineList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);

  const [errors, seterrors] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [leaveDetails, setleaveDetails] = useState({
    department_id: "",
    name: "",
    description: "",
  });

  const handleEmployee = (key, value) => {
    setleaveDetails(() => ({ ...leaveDetails, [key]: value }));
  };

  const handleSelectChange = (e, name) => {
    const { value } = e;
    setleaveDetails(() => ({ ...leaveDetails, [name]: value }));
  }

  const createDesignation = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.validateDesingnation(leaveDetails);
    if (isValid && isValid.error) {
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      setButtonDisabled(false);
      return;
    } else {
      seterrors({});
    }
    POST_API("/addDesignation", leaveDetails)
      .then(async function (data) {
        alertify.success("Designation added successfully!");
        navigate("/masters/designation");
        setButtonDisabled(false);
      })
      .catch((error) => alertify.error("Some error occured"));
  };

  useEffect(() => {
    getDepartmentList();
    getFineList();
  }, []);

  const getDepartmentList = async () => {
    setLoading(true);
    try {
      const responce = await GET_API("/DepartmentList");
      if (responce?.status === 200) {
        const depList = responce?.data?.data?.map(item => { return { label: item?.name, value: item?.id } })
        setDepartmentList(depList);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getFineList = async () => {
    setLoading(true);
    GET_API("/FinanceCategoriesList/fine")
      .then((response) => {
        setFineList(response?.data[0]);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const resetForm = () => {
    setleaveDetails({
      department_id: "",
      name: "",
      description: "",
    });
    seterrors({});
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">Designation</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Add Designation</h4>
          <div className="add-block">
            <Link to="/masters/designation" className="view-list-button">
              <span>View List</span>
              <img src={View} alt="add" />
            </Link>
          </div>

          <form onSubmit={(e) => createDesignation(e)}>
            <div className="form">
              <div className="field-block">
                <label className="label-name">Department<span className="required-symbol">*</span></label>
                <Select
                  id="department_id"
                  options={departmentList}
                  onChange={(e) => handleSelectChange(e, "department_id")}
                  name="department_id"
                  value={departmentList?.find(item => item?.value === leaveDetails.department_id)}
                  isSearchable
                />
                {errors.department_id ? (
                  <div className="validation-error-label">{errors.department_id}</div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  Designation Name
                  <span className="font-bold" style={{ color: "#FF5C42" }}>
                    *
                  </span>
                </label>
                <input
                  className="input-field "
                  type="text"
                  autoComplete="off"
                  placeholder="Enter Name"
                  name="name"
                  onChange={(e) => {
                    handleEmployee("name", e.target.value);
                  }}
                  value={leaveDetails?.name}
                />
                {errors.name ? (
                  <div className="validation-error-label">{errors.name}</div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">Description</label>
                <textarea
                  className="input-field"
                  type="text"
                  autoComplete="off"
                  placeholder="Enter Description"
                  name="description"
                  onChange={(e) => {
                    handleEmployee("description", e.target.value);
                  }}
                  value={leaveDetails?.description}
                />
                {errors.description ? (
                  <div className="validation-error-label">
                    {errors.description}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="submit-reset-button">
              <button
                type="button"
                className="reset-button"
                onClick={resetForm}
              >
                <span className="reset-button-label">Reset</span>
              </button>
              <button
                disabled={buttonDisabled}
                type="submit"
                className="submit-button"
              >
                <span className="submit-button-label">Add</span>
                <img src={ColorAdd} alt="" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateDesignation;
