import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import View from "../../Assets/Icons/View.svg";
import { GET_API } from "../../Services/api";
import config from "../../Services/config.json";
import alertify from "alertifyjs";

function LeaveOndutyDetails() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [empDetail, setEmpDetail] = useState({});
  useEffect(() => {
    getLeaveOndutyDetails();
  }, []);
  const getLeaveOndutyDetails = async () => {
    try {
      const leaveDetails = await GET_API(`/getEmployeeLeave/${id}`);
      if (leaveDetails?.status === 200) {
        setEmpDetail(leaveDetails?.data?.data);
      }
    } catch (error) {
      alertify.error(error?.response?.data?.data);
    }
  };

  const listNavigate = () => {
    navigate("/leave-onduty-list");
  };
  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className=" sm-container">
        <h1 className="heading-text">Attendance</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Attendance Details</h4>
          <div className="search-block">
            <Link to="/masters/leaveonduty" className="view-list-button">
              <span>View List</span>
              <img src={View} alt="add" />
            </Link>
          </div>
          <div className="grid grid-cols-3  pt-[10px] pr-[10px] font-montserrat">
            <div className="px-6 gap-8">
              <div className="flex justify-start py-4 px-4 gap-8">
                <label className="text-[#271E0D] text-base font-medium font-montserrat">
                  Employee Code
                </label>
                <div
                  style={{ wordBreak: "break-word" }}
                  className="text-[#7B7B7B] text-base font-karla"
                >
                  {empDetail?.empCode}
                </div>
              </div>
              <div className="flex py-4 px-4 gap-8">
                <label className="text-[#271E0D] text-base font-medium font-montserrat">
                  Employee Name
                </label>
                <div
                  style={{ wordBreak: "break-word" }}
                  className="text-[#7B7B7B] text-base font-karla"
                >
                  {empDetail?.first_name + " " + empDetail?.fathers_name}
                </div>
              </div>
              <div className="flex py-4 px-4 gap-8">
                <label className="text-[#271E0D] text-base font-medium font-montserrat">
                  Branch
                </label>
                <div
                  style={{ wordBreak: "break-word" }}
                  className="text-[#7B7B7B] text-base font-karla"
                >
                  {empDetail?.branchName}
                </div>
              </div>
              <div className="flex py-4 px-4 gap-8">
                <label className="text-[#271E0D] text-base font-medium font-montserrat">
                  Leave Type
                </label>
                <div
                  style={{ wordBreak: "break-word" }}
                  className="text-[#7B7B7B] text-base font-karla"
                >
                  {empDetail?.leave_type_name}
                </div>
              </div>
              <div className="flex py-4 px-4 gap-8">
                <label className="text-[#271E0D] text-base font-medium font-montserrat">
                  From Date
                </label>
                <div
                  style={{ wordBreak: "break-word" }}
                  className="text-[#7B7B7B] text-base font-karla"
                >
                  {empDetail?.from_date}
                </div>
              </div>
              <div className="flex py-4 px-4 gap-8">
                <label className="text-[#271E0D] text-base font-medium font-montserrat">
                  No.Of.Days
                </label>
                <div
                  style={{ wordBreak: "break-word" }}
                  className="text-[#7B7B7B] text-base font-karla"
                >
                  {empDetail?.no_of_days}
                </div>
              </div>
            </div>
            <div className="px-6 gap-8">
              <div className="flex py-4 px-4 gap-8">
                <label className="text-[#271E0D] text-base font-medium font-montserrat">
                  Department
                </label>
                <div
                  style={{ wordBreak: "break-word" }}
                  className="text-[#7B7B7B] text-base font-karla"
                >
                  {empDetail?.deptName}
                </div>
              </div>
              <div className="flex justify-start py-4 px-4 gap-8">
                <label className="text-[#271E0D] text-base font-medium font-montserrat">
                  Designation
                </label>
                <div
                  style={{ wordBreak: "break-word" }}
                  className="text-[#7B7B7B] text-base font-karla"
                >
                  {empDetail?.desName}
                </div>
              </div>

              <div className="flex justify-start py-4 px-4 gap-8">
                <label className="text-[#271E0D] text-base font-medium font-montserrat">
                  Shift
                </label>
                <div
                  style={{ wordBreak: "break-word" }}
                  className="text-[#7B7B7B] text-base font-karla"
                >
                  {empDetail?.shiftName}
                </div>
              </div>
              <div className="flex py-4 px-4 gap-8">
                <label className="text-[#271E0D] text-base font-medium font-montserrat">
                  Type
                </label>
                <div
                  style={{ wordBreak: "break-word" }}
                  className="text-[#7B7B7B] text-base font-karla"
                >
                  {empDetail?.type === "leave" ? (
                    <span
                      className=" bg-[#494C54] p-2"
                      style={{ color: "#FFF", borderRadius: "4px" }}
                    >
                      Leave
                    </span>
                  ) : (
                    <span
                      className=" bg-[#BAC] p-2"
                      style={{ color: "#FFF", borderRadius: "4px" }}
                    >
                      OD
                    </span>
                  )}
                </div>
              </div>
              <div className="flex py-4 px-4 gap-8">
                <label className="text-[#271E0D] text-base font-medium font-montserrat">
                  To Date
                </label>
                <div
                  style={{ wordBreak: "break-word" }}
                  className="text-[#7B7B7B] text-base font-karla"
                >
                  {empDetail?.to_date}
                </div>
              </div>
              <div className="flex py-4 px-4 gap-8">
                <label className="text-[#271E0D] text-base font-medium font-montserrat">
                  Approved By
                </label>
                <div
                  style={{ wordBreak: "break-word" }}
                  className="text-[#7B7B7B] text-base font-karla"
                >
                  {empDetail?.approver_name} {empDetail?.approver_last_name}
                </div>
              </div>
            </div>
            <div className="px-6 gap-8">
              <div className="flex py-4 px-4 gap-8">
                <div
                  style={{ wordBreak: "break-word" }}
                  className="text-[#7B7B7B] text-base font-karla"
                >
                  <img
                    src={`${config.path}${empDetail?.photo_path}`}
                    alt=""
                    style={{ height: "120px", width: "120px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeaveOndutyDetails;
