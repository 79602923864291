import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import View from "../../../Assets/Icons/View.svg";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import Validation from "../../../Validation/Validation";
import { POST_API, GET_API } from "../../../Services/api";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";

function CreateBreakTime() {
  const navigate = useNavigate();
  const [breakTimeDetails, setBreakTimeDetails] = useState({
    name: "",
    from_time: "",
    to_time: "",
    shift_id: "",
  });
  const [shiftOptions, setShiftOptions] = useState([]);
  const [errors, seterrors] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setBreakTimeDetails(() => ({ ...breakTimeDetails, [name]: value }));
  };

  const handleSelectChange = (e, name) => {
    const { value } = e;
    setBreakTimeDetails({ ...breakTimeDetails, [name]: value })
  }

  const getShiftList = async () => {
    try {
      const responce = await GET_API("/shiftsList");
      if (responce?.status === 200) {
        const shiftList = responce?.data?.data?.map(item => { return { label: item?.name, value: item?.id } });
        shiftList.unshift({ label: "Select...", value: "" });
        setShiftOptions(shiftList);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getShiftList();
  }, []);

  const createBreakTime = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.validateBreakTime(breakTimeDetails);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    POST_API("/addBreakTime", breakTimeDetails)
      .then(async function (data) {
        setButtonDisabled(false);
        alertify.success(data?.data?.message);
        navigate("/masters/break");
      })
      .catch((error) => {
        setButtonDisabled(false);
        alertify.error(error?.response?.data?.message);
      });
  };
  const resetForm = () => {
    setBreakTimeDetails({
      name: "",
      from_time: "",
      to_time: "",
      shift_id: "",
    });
    seterrors({});
  };

  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">Break</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Add Break Time</h4>
          <div className="add-block">
            <Link to="/masters/break" className="view-list-button">
              <span>View List</span>
              <img src={View} alt="add" />
            </Link>
          </div>
          {/***************Form************* **/}

          <form onSubmit={(e) => createBreakTime(e)}>
            <div className="form">
              <div className="field-block">
                <label className="label-name">
                  Break Name
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="name"
                  placeholder="Enter Break name"
                  value={breakTimeDetails?.name}
                />
                {errors.name ? (
                  <div className="validation-error-label">{errors.name}</div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  From Time
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="time"
                  autoComplete="off"
                  placeholder="Please select from time"
                  name="from_time"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={breakTimeDetails?.from_time}
                />
                {errors.from_time ? (
                  <div className="validation-error-label">
                    {errors.from_time}
                  </div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  To Time
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="time"
                  autoComplete="off"
                  placeholder="Please select to time"
                  name="to_time"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={breakTimeDetails?.to_time}
                />
                {errors.to_time ? (
                  <div className="validation-error-label">{errors.to_time}</div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">Shift Name<span className="required-symbol">*</span></label>
                <Select
                  id="shift_id"
                  options={shiftOptions}
                  onChange={(e) => handleSelectChange(e, "shift_id")}
                  name="shift_id"
                  value={shiftOptions?.find(item => item?.value === breakTimeDetails?.shift_id)}
                  isSearchable
                />
                {errors.shift_id ? (
                  <div className="validation-error-label">{errors.shift_id}</div>
                ) : null}
              </div>
            </div>
            <div className="submit-reset-button">
              <button
                type="button"
                className="reset-button"
                onClick={resetForm}
              >
                <span className="reset-button-label">Reset</span>
              </button>
              <button
                disabled={buttonDisabled}
                type="submit"
                className="submit-button"
              >
                <span className="submit-button-label">Save</span>
                <img src={ColorAdd} alt="" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateBreakTime;
