import React from "react";
import { Link, useLocation } from "react-router-dom";
import Edit from "../../../Assets/Icons/Edit.svg";
import View from "../../../Assets/Icons/View.svg";

function BranchDetails() {
  const { state } = useLocation();
  const listDetails = state?.list;
  const Details = [
    {
      label: "Branch Name",
      value: listDetails?.name,
    },

    {
      label: "No.of.Floors",
      value: listDetails?.no_of_floors,
    },
    {
      label: "GSTIN",
      value: listDetails?.gst_no,
    },
    {
      label: "Address",
      value: listDetails?.address,
    },

    {
      label: "Town / City",
      value: listDetails?.town,
    },
    {
      label: "State",
      value: listDetails?.state,
    },
    {
      label: "Pin Code",
      value: listDetails?.pincode,
    },
    {
      label: "Contact Person",
      value: listDetails?.contact_person,
    },

    {
      label: "Contact Number",
      value: listDetails?.phone,
    },

    {
      label: "E-mail",
      value: listDetails?.contact_email,
    },
  ];

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="flex flex-col justify-between h-full">
        <div className=" sm-container">
          <h1 className="heading-text">Branch</h1>
          <div className="card-wrapper">
            <h4 className="card-header">Branch Details</h4>
            <div className="add-block">
              <Link to="/masters/branch" className="view-list-button">
                <span>View List</span>
                <img src={View} alt="add" />
              </Link>
              <Link
                to="/masters/branch/edit"
                className="edit-button"
                state={{ list: listDetails }}
              >
                <span>Edit</span>
                <img src={Edit} alt="add" />
              </Link>
            </div>
            <div className="list-container">
              <div className="list-content-block">
                {Details.map((list, i) => {
                  return (
                    <div className="list " key={i}>
                      <label className="list-label">{list.label}</label>
                      <div
                        style={{ wordBreak: "break-word" }}
                        className="list-value"
                      >
                        {list.value}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default BranchDetails;
