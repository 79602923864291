import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import View from "../../Assets/Icons/View.svg";
import alertify from "alertifyjs";
import Validation from "../../Validation/Validation";
import { GET_API, POST_API } from "../../Services/api";
import ColorAdd from "../../Assets/Icons/ColorAdd.svg";
import "alertifyjs/build/css/alertify.css";

function CreateHoliday() {
  const navigate = useNavigate();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [holidayList, setHolidayList] = useState({
    name: "",
    religion_id: "",
    description: "",
    religion_id: "",
    leave_type: "",
    financial_year: "",
  });
  const [religionList, setReligionList] = useState([]);
  const [errors, seterrors] = useState({});

  useEffect(() => {
    getReligionsList();
  }, []);

  const getReligionsList = async () => {
    try {
      const responce = await GET_API("/ReligionList");
      const religion = responce?.data?.data?.map(item => { return { label: item?.name, value: item?.id } });
      religion.unshift({ label: "Select", value: "" })
      if (responce?.status === 200) {
        setReligionList(religion);
      }
    } catch (error) {
      setReligionList([]);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setHolidayList(() => ({ ...holidayList, [name]: value }));
  };

  const handleSelectChange = (e, name) => {
    const { value } = e;
    setHolidayList({ ...holidayList, [name]: value })
  }

  const createHoldayList = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.validateHoliday(holidayList);
    if (isValid && isValid.error) {
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      setButtonDisabled(false);
      return;
    } else {
      seterrors({});
    }
    POST_API("/addHolidays", holidayList)
      .then(async function (data) {
        alertify.success(data?.data?.message);
        navigate("/masters/holiday");
        setButtonDisabled(false);
      })

      .catch((error) => {
        alertify.error(error?.response?.data?.message);
        setButtonDisabled(false);
      });
  };

  const resetForm = () => {
    setHolidayList({
      name: "",
      religion_id: "",
      description: "",
      religion_id: "",
      leave_type: "",
      financial_year: "",
    });
    seterrors({});
  };
  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">Holiday</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Add Holiday</h4>
          <div className="add-block">
            <Link to="/masters/holiday" className="view-list-button">
              <span>View List</span>
              <img src={View} alt="add" />
            </Link>
          </div>
          <form>
            <div className="form">
              <div className="field-block">
                <label className="label-name">
                  Holidays Name
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="text"
                  autoComplete="off"
                  placeholder="Enter Holidays Name"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="name"
                  value={holidayList?.name}
                />
                {errors.name && (
                  <span className="validation-error-label">{errors.name}</span>
                )}
              </div>
              <div className="field-block">
                <label className="label-name">Religion<span className="required-symbol">*</span></label>
                <Select
                  id="religion_id"
                  options={religionList}
                  onChange={(e) => handleSelectChange(e, "religion_id")}
                  name="religion_id"
                  value={religionList?.find(item => item?.value === holidayList?.religion_id)}
                  isSearchable
                />
                {errors.religion_id ? (
                  <div className="validation-error-label">{errors.religion_id}</div>
                ) : null}
              </div>
              {/* <div className="field-block">
                <label className="label-name">
                  Religion
                  <span className="required-symbol">*</span>
                </label>
                <select
                  className="input-field"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="religion_id"
                  value={holidayList?.religion_id}
                >
                  <option value="">Select Religion</option>
                  {religionList?.map((list, i) => {
                    return (
                      <option key={i} value={list.id}>
                        {list.name}
                      </option>
                    );
                  })}
                </select>
                {errors.religion_id && (
                  <span className="validation-error-label">
                    {errors.religion_id}
                  </span>
                )}
              </div> */}
              <div className="field-block">
                <label className="label-name">Description</label>
                <textarea
                  className="input-field "
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="description"
                  placeholder="Enter Description"
                  value={holidayList?.description}
                />
                {errors.description && (
                  <span className="validation-error-label">
                    {errors.description}
                  </span>
                )}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Leave Type
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  placeholder="Enter Leave Type"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="leave_type"
                  autoComplete="off"
                  value={holidayList?.leave_type}
                />
                {errors.leave_type && (
                  <span className="validation-error-label">
                    {errors.leave_type}
                  </span>
                )}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Financial Year
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  placeholder="Enter Financial Year"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="financial_year"
                  autoComplete="off"
                  value={holidayList?.financial_year}
                />
                {errors?.financial_year && (
                  <span className="validation-error-label">
                    {errors.financial_year}
                  </span>
                )}
              </div>
            </div>
          </form>
          <div className="submit-reset-button">
            <button type="button" className="reset-button" onClick={resetForm}>
              <span className="reset-button-label">Reset</span>
            </button>
            <button
              disabled={buttonDisabled}
              type="submit"
              className="submit-button"
              onClick={(e) => createHoldayList(e)}
            >
              <span className="submit-button-label">Save</span>
              <img src={ColorAdd} alt="" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateHoliday;
