import React, { useState } from "react";
import { Link } from "react-router-dom";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import VerifiedIcon from '@mui/icons-material/Verified';
import Edit from "../../../Assets/Icons/Edit.svg";
import View from "../../../Assets/Icons/View.svg";
import config from "../../../Services/config.json";
import ImageView from "../Components/ImageView";
import { downloadEmployee } from "../Components/DownloadPdf";
import ImageIcon from "../../../Assets/Icons/upload.svg";
import PdfIcon from "../../../Assets/Icons/pdfIcon.png";

function BankInfo({ listDetails, nextTab, viewList, handleVerify, isBtnDisable, isEdit, isDownload }) {
  const imgPath = config?.path
  const [isOpen, setIsOpen] = useState(false)
  const [imageSrc, setImageSrc] = useState('')

  const handleModal = (src) => {
    setIsOpen(!isOpen)
    setImageSrc(src)
  }

  const otherDetails = [
    {
      label: "UAN No",
      value: listDetails?.uan_number
        ? listDetails?.uan_number
        : "-",
    },
    {
      label: "ESI No",
      value: listDetails?.esi_number ? listDetails?.esi_number : "-",
    },
  ];

  const Details = [
    {
      label: "Account Name",
      value: listDetails?.home_bank_account_name
        ? listDetails?.home_bank_account_name
        : "-",
    },
    {
      label: "Account No",
      value: listDetails?.home_bank_account_no ? listDetails?.home_bank_account_no : "-",
    },
    {
      label: "IFSC Code",
      value: listDetails?.home_bank_ifsc_code ? listDetails?.home_bank_ifsc_code : "-",
    },
    {
      label: "Branch Name",
      value: listDetails?.home_bank_branch ? listDetails?.home_bank_branch : "-",
    }
  ];

  const hdfcBankDetails = [
    {
      label: "Account Name",
      value: listDetails?.hdfc_account_name
        ? listDetails?.hdfc_account_name
        : "-",
    },
    {
      label: "Account No",
      value: listDetails?.hdfc_account_no ? listDetails?.hdfc_account_no : "-",
    },
  ];

  const hdfcDocument = [
    {
      label: "Bank Passbook cover page",
      value: listDetails?.hdfc_bank_passbook_coverpage_image ? imgPath + listDetails?.hdfc_bank_passbook_coverpage_image : "",
    },
    {
      label: "Cancelled Cheque Leaf",
      value: listDetails?.hdfc_cancelled_cheque_leaf_image ? imgPath + listDetails?.hdfc_cancelled_cheque_leaf_image : "",
    }
  ]

  const otherBankDocument = [
    {
      label: "Bank Passbook cover page",
      value: listDetails?.bank_passbook_coverpage_image ? imgPath + listDetails?.bank_passbook_coverpage_image : "",
    },
    {
      label: "Cancelled Cheque Leaf",
      value: listDetails?.cancelled_cheque_leaf_image ? imgPath + listDetails?.cancelled_cheque_leaf_image : "",
    }
  ]

  const fileType = (value) => {
    return value.endsWith(".pdf") ? PdfIcon : value
  }

  return (
    <>
      <div className="add-block">
        <button className="submit-button" onClick={handleVerify} disabled={isBtnDisable}>
          <span className="submit-button-label">Verify</span>
          <VerifiedIcon style={{ color: "white" }} />
        </button>
        {isEdit ?
          <Link
            to={`/employee/edit/${listDetails?.employee_id}`}
            className="submit-button"
            state={{ list: listDetails }}
          >
            <span className="submit-button-label">Edit</span>
            <img style={{ color: "white" }} className="submit-button-label" src={Edit} alt="add" />
          </Link>
          :
          ""
        }

        {isDownload ?
          <div className="add-new-button-block">
            <button className="add-new-button" onClick={() => downloadEmployee(listDetails?.employee_id)}>
              <span>Download</span>
              <ArrowCircleDownIcon />
            </button>
          </div>
          :
          ""
        }
        <Link to={viewList} className="view-list-button">
          <span>View List</span>
          <img src={View} alt="add" />
        </Link>
      </div>
      <div className="list-container">
        <div className="list-content-block">
          {otherDetails.map((list) => {
            return (
              <div className="list">
                <label className="list-label">{list?.label}</label>
                <div style={{ wordBreak: "break-word" }} className="list-value">
                  {list?.value}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <h4 className="detaile-header">HDFC Bank Account Details</h4>
      <div className="list-container">
        <div className="list-content-block">
          {hdfcBankDetails.map((list) => {
            return (
              <div className="list">
                <label className="list-label">{list?.label}</label>
                <div style={{ wordBreak: "break-word" }} className="list-value">
                  {list?.value}
                </div>
              </div>
            );
          })}
          {hdfcDocument?.map((list, i) => {
            return (
              <div className="" key={i}>
                <label className="list-label">{list?.label}</label>
                <div className="flex" >
                  <img className="h-9 w-9" src={list?.value ? fileType(list?.value) : ImageIcon} />
                  {list?.value ?
                    <>
                      {list?.value?.endsWith(".pdf") ?
                        <a href={list?.value} rel="noopener noreferrer" target="_blank">
                          <ArrowCircleDownIcon
                            className="ml-8 mt-1.5 img-view-icon cursor-pointer" />
                        </a> :
                        <ErrorOutlineIcon
                          className="ml-8 mt-1.5 img-view-icon cursor-pointer"
                          onClick={() => handleModal(list?.value)}
                        />}
                    </>
                    :
                    <ErrorOutlineIcon
                      className="ml-8 mt-1.5 img-view-icon"
                    />}
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <h4 className="detaile-header">Other Banck Account Details</h4>
      <div className="list-container border-bottom">
        <div className="list-content-block">
          {Details.map((list) => {
            return (
              <div className="list">
                <label className="list-label">{list?.label}</label>
                <div style={{ wordBreak: "break-word" }} className="list-value">
                  {list?.value}
                </div>
              </div>
            );
          })}
          {otherBankDocument?.map((list, i) => {
            return (
              <div className="" key={i}>
                <label className="list-label">{list?.label}</label>
                <div className="flex" >
                  <img className="h-9 w-9" src={list?.value ? fileType(list?.value) : ImageIcon} />
                  {list?.value ?
                    <>
                      {list?.value?.endsWith(".pdf") ?
                        <a href={list?.value} rel="noopener noreferrer" target="_blank">
                          <ArrowCircleDownIcon
                            className="ml-8 mt-1.5 img-view-icon cursor-pointer"
                          /></a> :
                        <ErrorOutlineIcon
                          className="ml-8 mt-1.5 img-view-icon cursor-pointer"
                          onClick={() => handleModal(list?.value)}
                        />}
                    </>
                    :
                    <ErrorOutlineIcon
                      className="ml-8 mt-1.5 img-view-icon"
                    />}
                </div>
              </div>
            )
          })}
        </div>
      </div>

      <ImageView
        isOpen={isOpen}
        handleModal={handleModal}
        imgSrc={imageSrc}
      />
      <div className="submit-reset-button">
        <button
          type="button"
          className="reset-button"
          onClick={() => {
            nextTab("education_info");
          }}
        >
          <span className="reset-button-label">Prev</span>
        </button>
      </div>
    </>
  );
}

export default BankInfo;
