import React, { useState } from "react";
import { Link } from "react-router-dom";
import RightArrow from "../Assets/Icons/RightArrow.svg";
import SubMenuItems from "./SubMenuItems";
function MenuItems({
  title,
  links,
  to,
  // subtitle,
  // menuList,
  // path,
  // subMenuItems,
  selected,
  handleMenuClick,
}) {
  const [subtitle, setSubtitle] = useState("");
  // const [selectedSubMenuItem, setSelectedSubMenuItem] = useState(
  //   subMenuItems[0] ? subMenuItems[0]?.label : ""
  // );

  return (
    <>
      <Link
        to={to}
        onClick={() => handleMenuClick((prev) => (prev === title ? "" : title))}
        className="px-2 pt-[16px] pb-[12px] flex cursor-pointer text-theme-text-color font-medium items-center justify-between"
      >
        <span
          className={`font-montserrat text-base whitespace-nowrap ${
            selected ? "text-text-dark font-medium" : "text-text-light"
          }`}
        >
          {title}
        </span>
        {links && (
          <img
            src={RightArrow}
            alt=""
            style={{
              transform: selected ? "rotate(90deg)" : "rotate(0)",
            }}
          />
        )}
      </Link>
      {selected &&
        links?.map((link) => {
          return (
            <>
              {link?.name ? (
                <span className="ml-[30px] flex gap-2 ml-[30px] p-2 cursor-pointer">
                  <span
                    className={`font-montserrat text-base whitespace-nowrap  ${
                      link?.id === subtitle
                        ? "text-text-dark font-medium"
                        : "text-text-light"
                    }`}
                    onClick={() =>
                      setSubtitle((prev) => (prev === link?.id ? "" : link?.id))
                    }
                  >
                    {link?.name}
                  </span>
                  <img
                    src={RightArrow}
                    alt=""
                    style={{
                      transform:
                        link?.id === subtitle ? "rotate(90deg)" : "rotate(0)",
                    }}
                  />
                </span>
              ) : (
                link?.list?.map((list) => {
                  return (
                    <>
                      <SubMenuItems
                        label={list}
                        name={link?.name}
                        setSubtitle={setSubtitle}
                      />
                    </>
                  );
                })
              )}

              {link?.list?.map((list) => {
                return (
                  <>
                    {link?.id === subtitle && (
                      <SubMenuItems
                        label={list}
                        name={link?.name}
                        setSubtitle={setSubtitle}
                      />
                    )}
                  </>
                );
              })}
            </>
          );
        })}
      {/* {links?.map((link) => {
        return (
          <>
            {link?.name && (
              <span className="ml-[30px] flex gap-2 ml-[30px] p-1">
                <span
                  className={`font-montserrat text-base whitespace-nowrap  ${
                    selected ? "text-text-dark font-medium" : "text-text-light"
                  }`}
                  onClick={() => {
                    setSubtitle(link?.id);
                  }}
                >
                  {link?.name}
                </span>
                <img
                  src={RightArrow}
                  alt=""
                  style={{
                    transform:
                      link?.id === subtitle ? "rotate(90deg)" : "rotate(0)",
                  }}
                />
              </span>
            )}

            {link?.list?.map((list) => {
              return (
                <>
                  {link?.id === subtitle && (
                    <SubMenuItems
                      label={list}
                      name={link?.name}
                      setSubtitle={setSubtitle}
                    />
                  )}
                </>
              );
            })}
          </>
        );
      })} */}

      {/************** Child Link ***************************/}
      {/* {selected && */}
      {/* {links.map((list, i) => {
        return (
          <div
            className="ml-[40px]"
            key={i}
            // onClick={() =>
            //   setSelectedSubMenuItem((prev) =>
            //     prev === list.label ? "" : list.label
            //   )
            // }
          >
            <SubMenuItems
              submenu={list}
              // selected={selectedSubMenuItem}
              // title={list.label}
            />
          </div>
        );
      })} */}
    </>
  );
}

export default MenuItems;
