import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import View from "../../Assets/Icons/View.svg";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import Validation from "../../Validation/Validation";
import { POST_API } from "../../Services/api";
import ColorAdd from "../../Assets/Icons/ColorAdd.svg";

function CreateEmploymentType() {
  const navigate = useNavigate();
  const [newEmployment, setNewEmployment] = useState({
    name: "",
    description: "",
    color: "",
  });
  const [errors, seterrors] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setNewEmployment(() => ({ ...newEmployment, [name]: value }));
  };

  const createEmploymentType = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.validateEmploymentType(newEmployment);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    POST_API("/addEmployeetype", newEmployment)
      .then(async function (data) {
        setButtonDisabled(false);
        alertify.success(data?.data?.message);
        navigate("/masters/employementType");
      })

      .catch((error) => {
        setButtonDisabled(false);
        alertify.error(error?.response?.data?.message);
      });
  };

  const resetForm = () => {
    setNewEmployment({
      name: "",
      description: "",
      color: "",
    });
    seterrors({});
  };

  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">Employment Type</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Add Employment Type</h4>
          <div className="add-block">
            <Link to="/masters/employementType" className="view-list-button">
              <span>View List</span>
              <img src={View} alt="add" />
            </Link>
          </div>

          {/***************Form************* **/}

          <form onSubmit={(e) => createEmploymentType(e)}>
            <div className="form">
              <div className="field-block">
                <label className="label-name">
                  Staffing Name
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="name"
                  placeholder="Enter name"
                  value={newEmployment?.name}
                />
                {errors.name ? (
                  <div className="validation-error-label">{errors.name}</div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">Tag Name</label>
                <input
                  className="input-field "
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="description"
                  placeholder="Enter Tag Name"
                  value={newEmployment?.description}
                />
                {errors.description ? (
                  <div className="validation-error-label">
                    {errors.description}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Tag Color
                  <span className="required-symbol">*</span>
                </label>
                <select
                  name="color"
                  onChange={(e) => handleChange(e)}
                  value={newEmployment?.color}
                  className="input-field"
                >
                  <option value="">Select Color...</option>
                  <option
                    value="#93b7ed"
                    style={{ backgroundColor: "#93b7ed" }}
                  >
                    Sky Blue
                  </option>
                  <option
                    value="#f5eca6"
                    style={{ backgroundColor: "#f5eca6" }}
                  >
                    yellow
                  </option>
                  <option
                    value="#6348e8"
                    style={{ backgroundColor: "#6348e8" }}
                  >
                    blue
                  </option>
                  <option
                    value="#f5b66e"
                    style={{ backgroundColor: "#f5b66e" }}
                  >
                    orange
                  </option>
                </select>
                {errors.color ? (
                  <div className="validation-error-label">{errors.color}</div>
                ) : null}
              </div>
            </div>

            <div className="submit-reset-button">
              <button
                type="button"
                className="reset-button"
                onClick={resetForm}
              >
                <span className="reset-button-label">Reset</span>
              </button>
              <button
                disabled={buttonDisabled}
                type="submit"
                className="submit-button"
              >
                <span className="submit-button-label">Add</span>
                <img src={ColorAdd} alt="" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateEmploymentType;
