import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import alertify from "alertifyjs";
import View from "../../../Assets/Icons/View.svg";
import Save from "../../../Assets/Icons/Save.svg";
import Spinner from "../../Spinner";
import { GET_API, POST_API } from "../../../Services/api";

function ViewApproval() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [financeList, setfinanceList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isButtonDisable, setIsButtonDisable] = useState(false);
    // Duration
    const [durationVal, setDurationVal] = useState('');

    useEffect(() => {
        if (id) {
            getApprovalList();
        }
    }, [id]);

    const getApprovalList = async () => {
        try {
            setIsLoading(true);
            const list = await GET_API(`/financeapprovalById/${id}`);
            if (list?.status === 200) {
                setfinanceList(list?.data?.employeeDetails[0]);
                setDurationVal(list?.data?.employeeDetails[0]?.duration);
                setIsLoading(false);
            }
        } catch (error) {
            setfinanceList([]);
            setIsLoading(false);
        }
    };

    const handleApprove = async (type) => {
        const user_id = localStorage.getItem("user_id");
        const data = {
            selectedRows: [financeList?.id],
            appType: type,
            approved_by: user_id,
        };

        try {
            const approveFinance = await POST_API("/updateApprovalFinance", data);
            if (approveFinance?.status === 200) {
                alertify.success(`Finance ${data?.type}d successfully...!`);
                navigate('/approve/finance')
                return;
            }
        } catch (error) {
            alertify.error(`Failed to ${data?.type}d Finance`);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const submitData = {
            "duration": durationVal,
        }
        try {
            const durationRes = await POST_API(`/updateDuration/${financeList?.employee_id}`, submitData);
            console.log("durationRes", durationRes);
            if (durationRes?.status === 200) {
                alertify.success(`Finance duration updated successfully...!`);
                navigate('/approve/finance')
                return;
            }
        } catch (error) {
            alertify.error(error?.response?.data?.message);
        }
    };

    const handleChange = (e) => {
        setDurationVal(e?.target?.value);
    }

    if (isLoading) {
        return <Spinner />;
    }

    return (
        <div className=" bg-[#F7F6F4] grow">
            <div className=" sm-container">
                <h1 className="heading-text">Finance</h1>
                <div className="card-wrapper">
                    <h4 className="card-header">Finance Approval</h4>
                    <div className="add-block">
                        {/* <div className="add-new-button-block" >
                            <button
                                 onClick={() => {
                                    handleApprove("reject");
                                  }}
                                disabled={isButtonDisable}
                                className="add-new-button" >
                                <span>Reject</span>
                            </button>
                        </div>
                        <div className="add-new-button-block" >
                            <button
                                 onClick={() => {
                                    handleApprove("approve");
                                  }}
                                disabled={isButtonDisable} className="add-new-button"  >
                                <span>Approve</span>
                            </button>
                        </div> */}

                        <Link to="/approve/finance" className="view-list-button">
                            <span>View List</span>
                            <img src={View} alt="add" />
                        </Link>
                    </div>

                    <div className="list-container border-bottom">
                        <div className="list-content-block">
                            <div className="list ">
                                <label className="list-label">Employee Code</label>
                                <div
                                    style={{ wordBreak: "break-word" }}
                                    className="list-value"
                                >
                                    <input
                                        className="input-field"
                                        value={financeList?.employee_code}
                                        disabled
                                        readOnly
                                    />
                                </div>
                            </div>
                            <div className="list ">
                                <label className="list-label">Employee Name</label>
                                <div
                                    style={{ wordBreak: "break-word" }}
                                    className="list-value"
                                >
                                    <input
                                        className="input-field"
                                        value={financeList?.name}
                                        disabled
                                        readOnly
                                    />
                                </div>
                            </div>

                            <div className="list ">
                                <label className="list-label">Payment Type</label>
                                <div
                                    style={{ wordBreak: "break-word" }}
                                    className="list-value"
                                >
                                    <input
                                        className="input-field"
                                        value={financeList?.payment_type}
                                        disabled
                                        readOnly
                                    />
                                </div>
                            </div>
                            <div className="list ">
                                <label className="list-label">Type</label>
                                <div
                                    style={{ wordBreak: "break-word" }}
                                    className="list-value"
                                >
                                    <input
                                        className="input-field"
                                        value={financeList?.type}
                                        disabled
                                        readOnly
                                    />
                                </div>
                            </div>
                            <div className="list ">
                                <label className="list-label">Amount</label>
                                <div
                                    style={{ wordBreak: "break-word" }}
                                    className="list-value"
                                >
                                    <input
                                        className="input-field"
                                        value={financeList?.amount}
                                        disabled
                                        readOnly
                                    />
                                </div>
                            </div>
                            <div className="list ">
                                <label className="list-label">Duration</label>
                                <div
                                    style={{ wordBreak: "break-word" }}
                                    className="list-value"
                                >
                                    <input
                                        type="number"
                                        className="input-field"
                                        value={durationVal}
                                        onChange={e => handleChange(e)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mt-3 flex justify-end">
                            <button
                                type="submit"
                                className="submit-button"
                                onClick={e => handleSubmit(e)}
                            // disabled={buttonDisabled}
                            >
                                <span className="submit-button-label">Save</span>
                                <img src={Save} alt="" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ViewApproval;
