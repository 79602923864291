import React, { useState, useEffect } from "react";
import alertify from "alertifyjs";
import Select from "react-select";
import { GET_API, POST_API } from "../../../Services/api";
import Validation from "../../../Validation/Validation";
import Spinner from "../../Spinner";
import "alertifyjs/build/css/alertify.css";
import { useParams } from "react-router-dom";
import { css } from "@emotion/react";
import { ClipLoader } from "react-spinners";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

function EmployeePositionInfo({ stateChanger, empDetails, nextTab }) {
  const { id } = useParams();
  const userId = localStorage?.getItem("user_id");
  const [departmentList, setdepartmentList] = useState([]);
  const [employeeTypeList, setemployeeTypeList] = useState([]);
  const [emoployeeList, setemployeeList] = useState([]);
  const [branchList, setbranchList] = useState([]);
  const [shiftList, setshiftList] = useState([]);
  const [floorList, setfloorList] = useState([]);
  const [holidayList, setholidayList] = useState([]);
  const [errors, seterrors] = useState({});
  const [clientOption, setClientOption] = useState([]);
  const [designationList, setDesignationList] = useState([]);
  const [isCategoryLoading, setIsCategoryLoading] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [empTypeLoading, setEmpTypeLoading] = useState(false)
  const [departmentLoading, setDepartmentLoading] = useState(false)
  const [designationLoading, setDesignationLoading] = useState(false)
  const [branchLoading, setBranchLoading] = useState(false)
  const [floorLoading, setFloorLoading] = useState(false)
  const [shiftLoading, setShiftLoading] = useState(false)
  const [holidayLoading, setHolidayLoading] = useState(false)
  const [reportingLoading, setReportingLoading] = useState(false)
  const [isBtnDisable, setIsBtnDisable] = useState(false)

  const handleSelectChange = (e, name) => {
    const { value } = e;
    stateChanger(() => ({ ...empDetails, [name]: value }));
  };

  // const handleDept = async (e, name) => {
  //   try {
  //     const { value } = e;
  //     const department = await GET_API(`/getDepartment/${value}`);
  //     if (department?.status === 200) {
  //       if (department?.data?.message) {
  //         stateChanger(() => ({
  //           ...empDetails,
  //           ["employee_code"]: department?.data?.message,
  //           [name]: value,
  //         }));
  //       }
  //     }
  //   } catch (error) {
  //     alertify.error(error?.response?.data?.message);
  //   }
  // };

  useEffect(() => {
    getDepartmentList();
    getEmployeeTypeList();
    getEmployeeList();
    getHolidayList();
    getFloorList();
    getShiftList();
    getBranchList();
    getClientsList();
    getDesignation();
  }, []);

  const getDesignation = async () => {
    try {
      setIsCategoryLoading(true);
      const response = await GET_API("/CategoryList")
      if (response?.status === 200) {
        const categ = response?.data?.data?.map((item) => {
          return { label: item?.name, value: item?.id }
        })
        setDesignationList(categ)
        setIsCategoryLoading(false);
      }
    } catch (error) {
      setDesignationList([]);
      setIsCategoryLoading(false);
    }
  };

  const getClientsList = async () => {
    try {
      setIsLoading(true);
      const response = await GET_API("clientLists");
      if (response?.status === 200) {
        const cliRes = response?.data?.data?.map((item) => {
          return { label: item?.name, value: item?.id }
        })
        setClientOption(cliRes)
        setIsLoading(false);
      }
    } catch (error) {
      setClientOption([]);
      setIsLoading(false);
    }
  };

  const getBranchList = async () => {
    try {
      setBranchLoading(true);
      const branchList = await GET_API("/branchList");
      if (branchList?.status === 200) {
        setBranchLoading(false);
        const branch = branchList?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        branch.unshift({ value: "", label: "Please Select" });
        setbranchList(branch);
      }
    } catch (error) {
      setBranchLoading(false);
      alertify?.error(error?.response?.data?.message);
    }
  };

  const getShiftList = async () => {
    try {
      setShiftLoading(true);
      const shiftList = await GET_API("/shiftsList");
      if (shiftList?.status === 200) {
        const shift = shiftList?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        shift.unshift({ value: "", label: "Please Select" });
        setshiftList(shift);
        setShiftLoading(false);
      }
    } catch (error) {
      setShiftLoading(false);
      alertify?.error(error?.response?.data?.message);
    }
  };

  const getFloorList = async () => {
    try {
      setFloorLoading(true);
      const leaveList = await GET_API("/floorList");
      if (leaveList?.status === 200) {
        setFloorLoading(false);
        const floor = leaveList?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        floor.unshift({ value: "", label: "Please Select" });
        setfloorList(floor);
      }
    } catch (error) {
      setFloorLoading(false);
      alertify.error(error?.response?.data?.message);
    }
  };

  const getHolidayList = async () => {
    try {
      setHolidayLoading(true);
      const holidayList = await GET_API("/HolidaySchemeList");
      if (holidayList?.status === 200) {
        const holiDay = holidayList?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        holiDay.unshift({ value: "", label: "Please Select" });
        setholidayList(holiDay);
        setHolidayLoading(false);
      }
    } catch (error) {
      setHolidayLoading(false);
      alertify.error(error?.response?.data?.message);
    }
  };

  const getEmployeeList = async () => {
    setReportingLoading(true)
    try {
      const employeeList = await GET_API(`/ListEmployeeTentacles?login=${userId}`);
      if (employeeList?.status === 200) {
        const emp = employeeList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        emp.unshift({ value: "", label: "Please Select" });
        setemployeeList(emp);
        setReportingLoading(false)
      }
    } catch (error) {
      alertify.error(error?.response?.data?.message);
      setReportingLoading(false)
    }
  };

  const getEmployeeTypeList = async () => {
    try {
      setEmpTypeLoading(true);
      const employeeList = await GET_API("/employeetype");
      if (employeeList?.status === 200) {
        setEmpTypeLoading(false);
        const empType = employeeList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        empType.unshift({ value: "", label: "Please Select" });
        setemployeeTypeList(empType);
      }
    } catch (error) {
      setEmpTypeLoading(false);
      alertify.error(error?.response?.data?.message);
    }
  };

  const getDepartmentList = async () => {
    try {
      setDepartmentLoading(true);
      const departmentList = await GET_API("/DepartmentList");
      if (departmentList?.status === 200) {
        setDepartmentLoading(false);
        const depList = departmentList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        depList.unshift({ value: "", label: "Please Select" });
        setdepartmentList(depList);
      }
    } catch (error) {
      setDepartmentLoading(false);
      alertify.error(error?.response?.data?.message);
    }
  };

  const handleValidation = async (e) => {
    e.preventDefault();
    setIsBtnDisable(true)
    const isValid = Validation.validateEmployeePositionInfo(empDetails);
    if (isValid && isValid.error) {
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      setIsBtnDisable(false)
      return;
    } else {
      seterrors({});
      try {
        const educationInfo = await POST_API(`/updateEmployeeTentacles/${id}`, empDetails);
        console.log("educationInfo", educationInfo);
        if (educationInfo?.data?.status === 200) {
          setIsBtnDisable(false)
          nextTab("education_info");
        }
      } catch (error) {
        console.log("error");
        setIsBtnDisable(false)
      }
    }
  };


  if (isCategoryLoading || isLoading || empTypeLoading || departmentLoading || designationLoading || branchLoading || floorLoading || shiftLoading || reportingLoading || holidayLoading) {
    return <Spinner />;
  }

  return (
    <>
      <form>
        <div className="form">
          <div className="field-block">
            <label className="label-name">Client<span className="required-symbol">*</span></label>
            <Select
              options={clientOption}
              value={clientOption?.find(
                (option) => option?.value === Number(empDetails.client_id)
              )}
              onChange={(e) => handleSelectChange(e, "client_id")}
              name="client_id"
              className=""
              isSearchable
            />
            {errors.client_id ? (
              <div className="validation-error-label">{errors.client_id}</div>
            ) : null}
          </div>
          <div className="field-block">
            <label className="label-name">Designation<span className="required-symbol">*</span></label>
            <Select
              options={designationList}
              value={designationList?.find(
                (option) => option?.value === Number(empDetails.category_id)
              )}
              onChange={(e) => handleSelectChange(e, "category_id")}
              name="category_id"
              className=""
              isSearchable
            />
            {errors.category_id ? (
              <div className="validation-error-label">{errors.category_id}</div>
            ) : null}
          </div>
          <div className="field-block">
            <label className="label-name">
              Employee Type
              <span className="required-symbol">*</span>
            </label>
            <Select
              options={employeeTypeList}
              value={employeeTypeList?.find(
                (option) =>
                  option?.value === Number(empDetails?.employee_type)
              )}
              onChange={(e) =>
                handleSelectChange(e, "employee_type")
              }
              name="employee_type"
              className=""
              isSearchable
            />
            {errors?.employee_type ? (
              <div className="validation-error-label" key={errors?.employee_type} >
                {errors?.employee_type}
              </div>
            ) : null}
          </div>
          <div className="field-block">
            <label className="label-name">
              Department
              <span className="required-symbol">*</span>
            </label>
            <Select
              options={departmentList}
              value={departmentList?.find(
                (option) => option?.value === empDetails.department_id
              )}
              onChange={(e) => handleSelectChange(e, "department_id")}
              name="department_id"
              className=""
              isSearchable
            />
            {errors?.department_id ? (
              <div className="validation-error-label" key={errors?.department_id}  >
                {errors?.department_id}
              </div>
            ) : null}
          </div>
          {/* <div className="field-block">
            <label className="label-name">
              Designation
              <span className="required-symbol">*</span>
            </label>
            <Select
              options={designationList}
              value={designationList?.find(
                (option) => option?.value === Number(empDetails.designation_id)
              )}
              onChange={(e) => handleSelectChange(e, "designation_id")}
              name="designation_id"
              className=""
              isSearchable
            />
            {errors?.designation_id ? (
              <div className="validation-error-label" key={errors?.designation_id} >
                {errors?.designation_id}
              </div>
            ) : null}
          </div> */}
          <div className="field-block">
            <label className="label-name">
              branch
              <span className="required-symbol">*</span>
            </label>
            <Select
              options={branchList}
              value={branchList?.find(
                (option) => option?.value === empDetails.branch_id
              )}
              onChange={(e) => handleSelectChange(e, "branch_id")}
              name="branch_id"
              className=""
              isSearchable
            />
            {errors?.branch_id ? (
              <div className="validation-error-label" key={errors?.branch_id} >
                {errors?.branch_id}
              </div>
            ) : null}
          </div>
          <div className="field-block">
            <label className="label-name">
              Floors
              <span className="required-symbol">*</span>
            </label>
            <Select
              options={floorList}
              value={floorList?.find(
                (option) => option?.value === empDetails?.floors_id
              )}
              onChange={(e) => handleSelectChange(e, "floors_id")}
              name="floors_id"
              className=""
              isSearchable
            />
            {errors?.floors_id ? (
              <div className="validation-error-label" key={errors?.floors_id} >
                {errors?.floors_id}
              </div>
            ) : null}
          </div>
          <div className="field-block">
            <label className="label-name">
              Shift
              <span className="required-symbol">*</span>
            </label>
            <Select
              options={shiftList}
              value={shiftList?.find(
                (option) => option?.value === empDetails?.shift_id
              )}
              onChange={(e) => handleSelectChange(e, "shift_id")}
              name="shift_id"
              className=""
              isSearchable
            />
            {errors?.shift_id ? (
              <div className="validation-error-label" key={errors?.shift_id} >
                {errors?.shift_id}
              </div>
            ) : null}
          </div>
          <div className="field-block">
            <label className="label-name">
              Reporting
              <span className="required-symbol">*</span>
            </label>
            <Select
              options={emoployeeList}
              value={emoployeeList?.find(
                (option) => option?.value === Number(empDetails?.reporting_to)
              )}
              onChange={(e) => handleSelectChange(e, "reporting_to")}
              name="reporting_to"
              className=""
              isSearchable
            />
            {errors?.reporting_to ? (
              <div className="validation-error-label" key={errors?.reporting_to} >
                {errors?.reporting_to}
              </div>
            ) : null}
          </div>
          <div className="field-block">
            <label className="label-name">
              Holiday Scheme
              <span className="required-symbol">*</span>
            </label>
            <Select
              options={holidayList}
              value={holidayList?.find(
                (option) =>
                  option?.value === empDetails?.holiday_scheme_id
              )}
              onChange={(e) =>
                handleSelectChange(e, "holiday_scheme_id")
              }
              name="holiday_scheme_id"
              className=""
              isSearchable
            />
            {errors?.holiday_scheme_id ? (
              <div className="validation-error-label" key={errors?.holiday_scheme_id} >
                {errors?.holiday_scheme_id}
              </div>
            ) : null}
          </div>
        </div>
      </form>
      <div className="submit-reset-button">
        <button
          type="button"
          className="reset-button"
          disabled={isBtnDisable}
          onClick={() => {
            nextTab("personal_info");
          }}
        >
          <span className="reset-button-label">Prev</span>
        </button>
        <button
          disabled={isBtnDisable}
          onClick={(e) => handleValidation(e)}
          type="submit"
          className="submit-button"
        >
          {isBtnDisable ?
            <ClipLoader
              css={override}
              size={20}
              color={"#fff"}
              loading={isBtnDisable}
            /> : ""}
          <span className="submit-button-label">{isBtnDisable ? "Loading..." : "Save"}</span>
        </button>
        <button className="reset-button" disabled={isBtnDisable} onClick={() => nextTab("education_info")}>
          <span className="reset-button-label">Next</span>
        </button>
      </div>
    </>
  );
}


export default EmployeePositionInfo;
