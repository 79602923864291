import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import View from "../../Assets/Icons/View.svg";
import Save from "../../Assets/Icons/Save.svg";
import { useLocation, useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import Validation from "../../Validation/Validation";
import { GET_API, POST_API } from "../../Services/api";

function EditHoliday() {
  const navigate = useNavigate();
  const [religionList, setReligionList] = useState([]);

  const { state } = useLocation();
  const listData = state?.list;
  const [errors, seterrors] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [editHolidayDetails, setEditHolidayDetails] = useState({
    id: listData?.id,
    name: listData?.name,
    religion_name: listData?.religion_name,
    description: listData?.description,
    leave_type: listData?.leave_type,
    financial_year: listData?.financial_year,
    religion_id: listData?.religion_id,
  });

  useEffect(() => {
    getReligionsList();
  }, []);

  const getReligionsList = async () => {
    try {
      const responce = await GET_API("/ReligionList");
      const religion = responce?.data?.data?.map(item => { return { label: item?.name, value: item?.id } })
      if (responce?.status === 200) {
        setReligionList(religion);
      }
    } catch (error) {
      setReligionList([]);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditHolidayDetails(() => ({
      ...editHolidayDetails,
      [name]: value,
    }));
  };

  const handleSelectChange = (e, name) => {
    const { value } = e;
    setEditHolidayDetails({ ...editHolidayDetails, [name]: value })
  }

  const saveHoliday = async (e) => {
    setButtonDisabled(true);
    const isValid = Validation.validateHoliday(editHolidayDetails);
    if (isValid && isValid.error) {
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      setButtonDisabled(false);
      return;
    } else {
      seterrors({});
    }
    POST_API("/updateHolidays", editHolidayDetails)
      .then(async function (data) {
        alertify.success(data?.data?.message);
        navigate("/masters/Holiday");
        setButtonDisabled(false);
      })

      .catch((error) => {
        alertify.error(error.response.data.message);
        setButtonDisabled(false);
      });
  };

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">Holiday</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Edit Holiday</h4>
          <div className="add-block">
            <Link to="/masters/holiday" className="view-list-button">
              <span>View List</span>
              <img src={View} alt="add" />
            </Link>
          </div>

          <form>
            <div className="form">
              <div className="field-block">
                <label className="label-name">
                  Holidays Name
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="name"
                  value={editHolidayDetails.name}
                />
                {errors.name && (
                  <span className="validation-error-label">{errors.name}</span>
                )}
              </div>
              <div className="field-block">
                <label className="label-name">Religion<span className="required-symbol">*</span></label>
                <Select
                  id="religion_id"
                  options={religionList}
                  onChange={(e) => handleSelectChange(e, "religion_id")}
                  name="religion_id"
                  value={religionList?.find(item => item?.value === Number(editHolidayDetails.religion_id))}
                  isSearchable
                />
                {errors.religion_id ? (
                  <div className="validation-error-label">{errors.religion_id}</div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">Description</label>
                <textarea
                  className="input-field"
                  type="text"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="description"
                  value={editHolidayDetails.description}
                />
                {errors.description && (
                  <span className="validation-error-label">
                    {errors.description}
                  </span>
                )}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Leave Type
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="leave_type"
                  value={editHolidayDetails.leave_type}
                />
                {errors.leave_type && (
                  <span className="validation-error-label">
                    {errors.leave_type}
                  </span>
                )}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Financial Year
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="financial_year"
                  value={editHolidayDetails.financial_year}
                />
                {errors.financial_year && (
                  <span className="validation-error-label">
                    {errors.financial_year}
                  </span>
                )}
              </div>
            </div>
          </form>
          <div className="submit-reset-button">
            <button type="button" className="reset-button">
              <span className="reset-button-label">Reset</span>
            </button>
            <button
              type="submit"
              className="submit-button"
              onClick={() => saveHoliday()}
              disabled={buttonDisabled}
            >
              <span className="submit-button-label">Save</span>
              <img src={Save} alt="" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default EditHoliday;
