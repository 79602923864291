import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { InputLabel } from "@mui/material";
import { POST_API } from "../../Services/api";
import alertify from "alertifyjs";

const CustomDialog = ({ isOpen, onClose, terminateEmployeeId, getEmployeeList, filterParams }) => {
  const [terminateEmployee, setTerminateEmployee] = useState({
    employee_id: "",
    terminated_by: "",
    remarks: "",
  });

  useEffect(() => {
    const user_id = localStorage.getItem("user_id");
    setTerminateEmployee({
      ...terminateEmployee,
      terminated_by: user_id,
      employee_id: terminateEmployeeId,
    });
  }, [terminateEmployeeId]);

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setTerminateEmployee({ ...terminateEmployee, [name]: value });
  };

  const handleTerminate = async () => {
    const terminate = await POST_API("/terminateEmployee", terminateEmployee);
    if (terminate.status === 200) {
      alertify.success(terminate?.data?.message);
      getEmployeeList(filterParams);
      onClose();
      return;
    }
    onClose();
    alertify.error("Failed to Terminate Employee");
  };

  return (
    <Dialog open={isOpen} onClose={onClose}
      PaperProps={{
        style: {
          minWidth: '400px',
          minHeight: '300px',
        },
      }}>
      <DialogTitle>Terminate Employee</DialogTitle>
      <DialogContent>
        <InputLabel>Remarks</InputLabel>
        <TextareaAutosize
          minRows={3}
          placeholder="Enter Reason"
          onChange={handleChange}
          name="remarks"
          style={{
            width: "100%",
            resize: "none",
            padding: "8px",
            marginTop: "8px",
            border: "1px solid #ccc",
          }}
        />
      </DialogContent>
      <DialogActions>
        <button
          type="button"
          className="reset-button"
          onClick={onClose}
        >
          <span className="reset-button-label">Close</span>
        </button>
        <button
          type="button"
          className="submit-button"
          onClick={handleTerminate}
        >
          <span className="reset-button-label"><span className="submit-button-label">Terminate</span></span>
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
