import React, { useState, useEffect } from "react";
import More from "../../../Assets/Icons/More.svg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { GET_API, DELETE_API } from "../../../Services/api";
import Spinner from "../../Spinner";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import BankListMasterTable from "../../DataTable/BankListMasterTable";
import Search from "../../../Assets/Icons/Search.svg";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";

function BankList() {
  const navigate = useNavigate();
  const [bankList, setBankList] = useState([]);
  const [filteredBranch, setFilteredBranch] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    { id: "s.no", label: "S.No", minWidth: 170 },
    { id: "branch_name", label: "Branch Name", minWidth: 100 },
    { id: "bank_name", label: "Bank Name", minWidth: 100 },
    { id: "created_at", label: "Created at", minWidth: 100 },
    { id: "more_icon", label: "", minWidth: 100 },
  ];

  useEffect(() => {
    getBankList();
  }, []);

  const getBankList = async () => {
    setLoading(true);
    try {
      const list = await GET_API("/bankList");
      console.log(list?.data?.data);
      if (list.status === 200) {
        setBankList(list?.data?.data);
        setFilteredBranch(list?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alertify.error("Failed to get Bank List...");
    }
  };

  const handleBranchSearch = (e) => {
    const searchTerm = e.target.value;
    const searchedList = bankList.filter((item) => {
      for (let key in item) {
        if (
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
    setFilteredBranch(searchedList);
  };

  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Bank..?",
        async function () {
          if (row_id) {
            try {
              const deleteRow = await DELETE_API(`/deletebank/${row_id}`);
              if (deleteRow.status === 200) {
                alertify.success(deleteRow?.data?.message);
                getBankList();
                return;
              }
            } catch (error) {
              alertify.error("Failed To Delete Bank");
            }
          }
        },
        function () { }
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };

  const showBranchDetails = (listDetail) => {
    navigate("/masters/bank/details", {
      state: {
        list: listDetail,
      },
    });
  };

  const navigateEditPage = (listDetail) => {
    navigate("/masters/bank/edit", {
      state: {
        list: listDetail,
      },
    });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">Bank</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Bank List</h4>
          <div className="search-block">
            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                onChange={(e) => handleBranchSearch(e)}
                style={{ outline: "none", border: "none" }}
              />

              <img src={Search} alt="" />
            </div>
            <div className="flex gap-3">
              <div className="add-new-button-block">
                <Link to="/masters/bank/add" className="add-new-button">
                  <span>Add New</span>
                  <img src={ColorAdd} alt="add" />
                </Link>
              </div>
            </div>
          </div>

          <BankListMasterTable
            list={filteredBranch}
            columns={columns}
            icon={More}
            multiple={true}
            editPage={navigateEditPage}
            showDetails={showBranchDetails}
            deleteRow={handleDelete}
          />
        </div>
      </div>
    </div>
  );
}

export default BankList;
