import React from "react";
import FileUploadImage from "../../../../Assets/Icons/FileUpload.svg";
import FileImage from "../../../../Assets/Icons/upload.svg";
import PdfIcon from "../../../../Assets/Icons/pdfIcon.png";
import { useParams } from "react-router-dom";

const FileUpload = ({ label, value, name, errors, isRequired, handleFileChange, imgSrc, accept }) => {
  const { id } = useParams();
  const src = value?.startsWith("uploads") ? imgSrc + value : value

  const imgType = (value) => {
    const isPdf = value?.startsWith("data:application/pdf") ||  value?.endsWith(".pdf");
    if (!id) {
      return isPdf ? "pdf" : "img";
    } else {
      return isPdf ? "pdf" : "img";
    }
  };
 
  return (
    <div className="field-block">
      <label className="label-name">
        {label}
        {isRequired ? <span className="required-symbol">*</span> : ""}
      </label>
      <div className="">
        <div className="flex gap-2 items-center">
          {!value ? (
            <img src={FileImage} className="employee-profile-picture" />
            // <FilePresentIcon className="employee-profile-picture" />
          ) : imgType(value) === "img" ? (
            <img
              name="file"
              className="h-[35px] w-[35px]"
              src={src}
              alt=""
            />
          ) : <img
            name="file"
            className="h-[35px] w-[35px]"
            src={PdfIcon}
            alt=""
          />}
          <div className="p-1 bg-[#FFF2EC] rounded flex justify-center items-center gap-1 cursor-pointer">
            <label
              htmlFor={name}
              className="label-name cursor-pointer"
            >
              {(value === "" || value === null)
                ? "Choose"
                : "Replace"}
            </label>
            <input
              className="w-0 cursor-pointer"
              id={name}
              style={{ visibility: "hidden" }}
              type="file"
              accept={accept}
              name={name}
              onChange={e => handleFileChange(e)}
            />
            <img src={FileUploadImage} alt={label} />
          </div>
        </div>
        {errors ? (
          <div
            className="validation-error-label"
            key={errors}
          >
            {errors}
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default FileUpload