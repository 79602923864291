import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import View from "../../Assets/Icons/View.svg";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import Validation from "../../Validation/Validation";
import { GET_API, POST_API } from "../../Services/api";
import ColorAdd from "../../Assets/Icons/ColorAdd.svg";
import Spinner from "../Spinner";

function CreateLeaveOnduty() {
  const navigate = useNavigate();
  const [employeeList, setemployeeList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [leaveDetail, setLeaveDetail] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [leaveDetails, setleaveDetails] = useState({
    employee_id: "",
    type: "",
    employee_name: "",
    leave_type_id: "",
    from_date: "",
    to_date: "",
    approved_by: "",
    remarks: "",
    no_of_days: 0,
    created_by: localStorage.getItem("user_id"),
  });
  const [errors, seterrors] = useState({});
  useEffect(() => {
    getEmployeeList();
    getLeaveTypeList();
  }, []);

  const getEmployeeList = async () => {
    setLoading(true);
    try {
      const employeeList = await GET_API("/employeesList");
      if (employeeList?.status === 200) {
        setLoading(false);
        setemployeeList(employeeList?.data?.data);
      }
    } catch (error) {
      setLoading(false);
      alertify.error("Failed to get Employee List");
    }
  };

  const getLeaveTypeList = async () => {
    setLoading(true);
    try {
      const leaveTypeList = await GET_API("/leaveTypeList");
      if (leaveTypeList.status === 200) {
        setLoading(false);
        setLeaveDetail(leaveTypeList?.data.data);
      }
    } catch (error) {
      alertify.error("Failed to get Employee Type List");
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setleaveDetails(() => ({ ...leaveDetails, [name]: value }));
  };

  useEffect(() => {
    if (
      leaveDetails.from_date !== "" &&
      leaveDetails?.to_date !== "" &&
      leaveDetails.from_date <= leaveDetails.to_date
    ) {
      const fromDate = new Date(leaveDetails?.from_date);
      const toDate = new Date(leaveDetails?.to_date);
      const diffTime = Math.abs(fromDate - toDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
      setleaveDetails(() => ({ ...leaveDetails, no_of_days: diffDays }));
    } else {
      setleaveDetails(() => ({ ...leaveDetails, no_of_days: 0 }));
    }
  }, [leaveDetails.to_date]);

  const getEmployeeName = async (key, empCode) => {
    if (empCode) {
      setLoading(true);
      try {
        const getEmployeeName = await GET_API(`/employeeGet/${empCode}`);
        if (getEmployeeName.status === 200) {
          setleaveDetails({
            ...leaveDetails,
            [key]: empCode,
            employee_name:
              getEmployeeName?.data?.data?.first_name +
              " " +
              getEmployeeName?.data?.data?.fathers_name,
          });
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        alertify.error("Failed to load Employee Name");
      }
    }
  };

  const createLeave = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.validateLeave(leaveDetails);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    try {
      const addLeave = await POST_API("/AddemployeeLeave", leaveDetails);
      if (addLeave.status === 200) {
        alertify.success(addLeave?.data?.message);
        setButtonDisabled(false);
        navigate("/masters/leaveonduty");
      }
    } catch (error) {
      setButtonDisabled(false);
      alertify.error(error?.respose?.data?.message);
    }
  };

  const resetForm = () => {
    setleaveDetails({
      employee_id: "",
      type: "",
      leave_type_id: "",
      from_date: "",
      to_date: "",
      approved_by: "",
      remarks: "",
      no_of_days: 0,
    });
  };
  if (loading) {
    return <Spinner />;
  }
  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">Leave & On Duty</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Add Leave & On Duty</h4>
          <div className="search-block">
            <Link to="/masters/leaveonduty" className="view-list-button">
              <span>View List</span>
              <img src={View} alt="add" />
            </Link>
          </div>
          <form onSubmit={(e) => createLeave(e)}>
            <div className="form">
              <div className="field-block">
                <label className="label-name ">
                  Type
                  <span className="required-symbol">*</span>
                </label>
                <div className="flex gap-3">
                  <input
                    type="radio"
                    value="leave"
                    name="type"
                    className="input-field"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    checked={leaveDetails.type === "leave"}
                  />
                  Leave
                  <input
                    type="radio"
                    value="onduty"
                    name="type"
                    className="input-field"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    checked={leaveDetails.type === "onduty"}
                  />
                  Onduty
                </div>
                {errors.type ? (
                  <div className="validation-error-label">{errors.type}</div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Employee Code
                  <span className="required-symbol">*</span>
                </label>
                <select
                  name="employee_id"
                  className="input-field"
                  onChange={(e) => {
                    getEmployeeName("employee_id", e.target?.value);
                  }}
                  value={leaveDetails.employee_id}
                >
                  <option value="">Select Employee</option>
                  {employeeList &&
                    employeeList.map((option) => {
                      return (
                        <option value={option.employee_Id}>
                          {option.emp_code}
                        </option>
                      );
                    })}
                </select>
                {errors.employee_id ? (
                  <div className="validation-error-label">
                    {errors.employee_id}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Employee Name
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  autoComplete="off"
                  placeholder="Employee Name"
                  name="emp_name"
                  value={`${leaveDetails?.employee_name}`}
                  readOnly
                  disabled
                />
                {errors.employee_name ? (
                  <div className="validation-error-label">
                    {errors.employee_name}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Leave Type
                  <span className="required-symbol">*</span>
                </label>
                <select
                  name="leave_type_id"
                  className="input-field "
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={leaveDetails?.leave_type_id}
                >
                  <option value="">Select Leave Type</option>

                  {leaveDetail &&
                    leaveDetail.map((option) => {
                      return <option value={option.id}>{option.name}</option>;
                    })}
                </select>
                {errors?.leave_type_id ? (
                  <div className="validation-error-label">
                    {errors?.leave_type_id}
                  </div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  From
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="date"
                  autoComplete="off"
                  placeholder="Select From Date"
                  name="from_date"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={leaveDetails?.from_date}
                />
                {errors?.from_date ? (
                  <div className="validation-error-label">
                    {errors?.from_date}
                  </div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  To
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="date"
                  autoComplete="off"
                  placeholder="Select To Date"
                  name="to_date"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={leaveDetails?.to_date}
                />
                {errors?.to_date ? (
                  <div className="validation-error-label">
                    {errors?.to_date}
                  </div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  No Of Days
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  autoComplete="off"
                  placeholder="Enter No.Of.Days"
                  name="no_of_days"
                  readOnly
                  disabled
                  value={leaveDetails?.no_of_days}
                />
                {errors?.no_of_days ? (
                  <div className="validation-error-label">
                    {errors?.no_of_days}
                  </div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  Approved by
                  <span className="required-symbol">*</span>
                </label>
                <select
                  name="approved_by"
                  className="input-field"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={leaveDetails?.approved_by}
                >
                  <option value="">Select Employee</option>
                  {employeeList &&
                    employeeList.map((option) => {
                      return (
                        <option value={option.employee_Id}>
                          {option.first_name} {option.fathers_name}
                        </option>
                      );
                    })}
                </select>
                {errors.approved_by ? (
                  <div className="validation-error-label">
                    {errors.approved_by}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="px-6 py-3 grid grid gap-2">
              <label className="label-name">
                Remarks
                <span className="font-bold" style={{ color: "#FF5C42" }}>
                  *
                </span>
              </label>
              <textarea
                className="input-field"
                type="date"
                autoComplete="off"
                placeholder="Remarks"
                name="remarks"
                onChange={(e) => {
                  handleChange(e);
                }}
                value={leaveDetails?.remarks}
              />
              {errors?.remarks ? (
                <div className="validation-error-label">{errors?.remarks}</div>
              ) : null}
            </div>

            <div className="submit-reset-button">
              <button
                type="button"
                className="reset-button"
                onClick={resetForm}
              >
                <span className="reset-button-label">Reset</span>
              </button>
              <button
                disabled={buttonDisabled}
                type="submit"
                className="submit-button"
              >
                <span className="submit-button-label">Add</span>
                <img src={ColorAdd} alt="" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateLeaveOnduty;
