import React from "react";
import { Route } from "react-router-dom";

import Loan from "../FinanceMaster/Loan/index";

import Bonus from "../FinanceMaster/Bonus/index";

import Expense from "../FinanceMaster/Expenses/index";

import Fine from "../FinanceMaster/Fine/index";

import Payment from "../FinanceMaster/payment/PaymentList";

import Bank from "../EnterpriseMaster/Bank/BankList";
import CreateBank from "../EnterpriseMaster/Bank/CreateBank";
import EditBank from "../EnterpriseMaster/Bank/EditBank";
import BankDetails from "../EnterpriseMaster/Bank/BankDetails";

import SeasonalSalary from "../FinanceMaster/SeasonalSalary/SeasonalSalaryList";
import CreateSeasonalSalary from "../FinanceMaster/SeasonalSalary/CreateSeasonalSalary";
import EditSeasonalSalary from "../FinanceMaster/SeasonalSalary/EditSeasonalSalary";
import SeasonalSalaryDetails from "../FinanceMaster/SeasonalSalary/SeasonalSalaryDetails";

function FinanceMastersRoutes() {
  return (
    <>
      {/***loan */}
      <Route path="loan" element={<Loan />}></Route>

      {/***bonus */}
      <Route path="bonus" element={<Bonus />}></Route>

      {/***expenses */}
      <Route path="expenses" element={<Expense />}></Route>

      {/***fine */}
      <Route path="fine" element={<Fine />}></Route>

      {/***payment */}
      <Route path="payment" element={<Payment />}></Route>

      {/***bank */}
      <Route path="bank" element={<Bank />}></Route>
      <Route path="bank/add" element={<CreateBank />}></Route>
      <Route path="bank/edit" element={<EditBank />}></Route>
      <Route path="bank/details" element={<BankDetails />}></Route>

      {/***seasonal salary */}
      <Route path="seasonalsalary" element={<SeasonalSalary />}></Route>
      <Route
        path="seasonalsalary/add"
        element={<CreateSeasonalSalary />}
      ></Route>
      <Route
        path="seasonalsalary/edit"
        element={<EditSeasonalSalary />}
      ></Route>
      <Route
        path="seasonalsalary/details"
        element={<SeasonalSalaryDetails />}
      ></Route>
    </>
  );
}

export default FinanceMastersRoutes;
