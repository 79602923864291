import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import alertify from "alertifyjs";
import { useReactToPrint } from "react-to-print";
import Spinner from "../../Spinner";
import Validation from "../../../Validation/Validation";
import { GET_API, POST_API } from "../../../Services/api";
import ExpensePrintVoucher from "./ExpensePrintVoucher";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import View from "../../../Assets/Icons/View.svg";
import "alertifyjs/build/css/alertify.css";

function CreateExpense() {
  const navigate = useNavigate();
  const componentRef = useRef();
  const userId = localStorage?.getItem("user_id");
  const [expenseDetails, setExpenseDetails] = useState({
    employee_id: "",
    type: "expense",
    employee_name: "",
    designation: "",
    department: "",
    finance_category_id: "",
    amount: "",
    total_paid_amount: "",
    duration: "",
    approved_by: "",
  });
  const [errors, seterrors] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [designationList, setDesignationList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [branchList, setbranchList] = useState([]);
  const [designationName, setdesignationName] = useState("");
  const [departmentName, setdepartmentName] = useState("");
  const [branchName, setbranchName] = useState("");

  const [loanList, setLoanList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isPrintVoucher, setIsPrintVoucher] = useState(false);
  const [printVoucherData, setPrintVoucherData] = useState({});

  useEffect(() => {
    getDesignationList();
    getDepartmentList();
    getBranchList();
    getLoanList();
    getEmployeeList();
  }, []);

  const handleDesignation = (key, value) => {
    GET_API(`/employeeGet/${value}`)
      .then(async function (data) {
        setdesignationName(data?.data?.data.designation_name);
        setdepartmentName(data?.data?.data.department_name);
        setbranchName(data?.data?.data.branch_name);
      })
      .catch((error) => console.log(error));
    setExpenseDetails(() => ({ ...expenseDetails, [key]: value }));
  };

  const getDesignationList = () => {
    GET_API("/designationList")
      .then(async function (data) {
        setDesignationList(data?.data?.data);
      })
      .catch((error) => console.log(error));
  };

  const getDepartmentList = () => {
    GET_API("/DepartmentList")
      .then(async function (data) {
        setDepartmentList(data?.data?.data);
      })
      .catch((error) => console.log(error));
  };

  const getBranchList = async () => {
    try {
      const responce = await GET_API("/branchList");
      if (responce?.status === 200) {
        setbranchList(responce?.data?.data);
      }
    } catch (error) {
      setbranchList([]);
    }
  };

  const getLoanList = async () => {
    try {
      const responce = await GET_API("/FinanceCategoriesList/loan");
      if (responce?.status === 200) {
        const list = responce?.data?.data?.map(item => { return { label: item?.name, value: item?.id } });
        list.unshift({ label: "Select...", value: "" });
        setLoanList(list);
      }
    } catch (error) {
      setLoanList([]);
    }
  };

  const getEmployeeList = async () => {
    setLoading(true);
    try {
      const employeeList = await GET_API(`/ListEmployeeTentacles?login=${userId}`);
      if (employeeList.status === 200) {
        const res = employeeList?.data?.data.map((item, i) => {
          return { value: item?.employee_id, label: `${item?.name}` };
        });
        res.unshift({ label: "Select...", value: "" });
        setEmployeeList(res);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to get Employee List...");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setExpenseDetails(() => ({ ...expenseDetails, [name]: value }));
  };

  const handleSelectChange = (e, name) => {
    const { value } = e;
    setExpenseDetails({ ...expenseDetails, [name]: value })
  }

  const printVoucher = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (isPrintVoucher) {
      printVoucher();
      navigate("/expense");
    }
  }, [isPrintVoucher, printVoucher]);

  const createExpense = async (e) => {
    e.preventDefault();
    console.log("expenseDetails", expenseDetails);
    setButtonDisabled(true);
    const isValid = Validation.validateExpense(expenseDetails);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    POST_API("/addExpenseDetails", expenseDetails)
      .then(async function (data) {
        setButtonDisabled(false);
        alertify.success(data?.data?.message);
        setPrintVoucherData(data?.data?.data[0]);
        setTimeout(() => {
          printVoucher();
          setIsPrintVoucher(true);
        }, 1000);
      })

      .catch((error) => {
        setButtonDisabled(false);
        alertify.error(error?.response?.data?.message);
      });
  };

  const resetForm = () => { };
  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">Expense</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Add Expense</h4>
          <div className="add-block">
            <Link to="/expense" className="view-list-button">
              <span>View List</span>
              <img src={View} alt="add" />
            </Link>
          </div>
          {isPrintVoucher && (
            <ExpensePrintVoucher
              ref={componentRef}
              printVoucherData={printVoucherData}
            />
          )}
          {/***************Form************* **/}

          <form onSubmit={(e) => createExpense(e)}>
            <div className="form">
              <div className="field-block">
                <label className="label-name">
                  Employee Name
                  <span className="required-symbol">*</span>
                </label>
                <Select
                  id="employee_id"
                  options={employeeList}
                  value={employeeList?.find(item => item?.value === expenseDetails.employee_id)}
                  onChange={(e) => handleSelectChange(e, "employee_id")}
                  name="employee_id"
                  isSearchable
                />
                {errors["employee_id"] ? (
                  <div className="text-[#FF5C42] text-sm font-medium font-karla">
                    {errors["employee_id"]}
                  </div>
                ) : null}
              </div>

              {/* <div className="flex flex-col gap-1 min-h-[95px]">
                <label className="label-name">
                  Designation
                  <span className="required-symbol">*</span>
                </label>

                <input
                  className="input-field"
                  name="designation"
                  placeholder="select department"
                  value={designationName}
                  readOnly
                  disabled
                />

                {errors.designation ? (
                  <div className="validation-error-label">
                    {errors.designation}
                  </div>
                ) : null}
              </div>

              <div className="flex flex-col gap-1 min-h-[95px]">
                <label className="label-name">
                  Department Name
                  <span className="required-symbol">*</span>
                </label>

                <input
                  className="input-field"
                  name="department"
                  placeholder="select department"
                  value={departmentName}
                  readOnly
                  disabled
                />

                {errors.department ? (
                  <div className="validation-error-label">
                    {errors.department}
                  </div>
                ) : null}
              </div>

              <div className="flex flex-col gap-1 min-h-[95px]">
                <label className="label-name">
                  Branch Name
                  <span className="required-symbol">*</span>
                </label>

                <input
                  className="input-field"
                  name="branch"
                  placeholder="select department"
                  value={branchName}
                  readOnly
                  disabled
                />

                {errors.branch ? (
                  <div className="validation-error-label">{errors.branch}</div>
                ) : null}
              </div> */}

              <div className="field-block">
                <label className="label-name">Loan Type</label>
                <Select
                  id="finance_category_id"
                  options={loanList}
                  value={loanList?.find(item => item?.value === expenseDetails.finance_category_id)}
                  onChange={(e) => handleSelectChange(e, "finance_category_id")}
                  name="finance_category_id"
                  isSearchable
                />
                {errors.finance_category_id ? (
                  <div className="validation-error-label">
                    {errors.finance_category_id}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Amount
                  <span className="required-symbol">*</span>
                </label>
                <input
                  name="amount"
                  className="input-field"
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter Amount"
                />
                {errors.amount ? (
                  <div className="validation-error-label">{errors.amount}</div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Total Paid Amount
                  <span className="required-symbol">*</span>
                </label>
                <input
                  name="total_paid_amount"
                  className="input-field"
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter Total Paid Amount"
                />
                {errors.total_paid_amount ? (
                  <div className="validation-error-label">
                    {errors.total_paid_amount}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Duration
                  <span className="required-symbol">*</span>
                </label>
                <input
                  type="number"
                  name="duration"
                  className="input-field"
                  placeholder="Enter Duration"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                {errors.duration ? (
                  <div className="validation-error-label">
                    {errors.duration}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">Approval</label>
                <Select
                  id="approved_by"
                  options={employeeList}
                  value={employeeList?.find(item => item?.value === expenseDetails.approved_by)}
                  onChange={(e) => handleSelectChange(e, "approved_by")}
                  name="approved_by"
                  isSearchable
                />
                {errors.approved_by ? (
                  <div className="validation-error-label">
                    {errors.approved_by}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="submit-reset-button">
              <button
                type="button"
                className="reset-button"
                onClick={resetForm}
              >
                <span className="reset-button-label">Reset</span>
              </button>
              <button
                disabled={buttonDisabled}
                type="submit"
                className="submit-button"
              >
                <span className="submit-button-label">Add</span>
                <img src={ColorAdd} alt="" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateExpense;
