import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import View from "../../../Assets/Icons/View.svg";
import Save from "../../../Assets/Icons/Save.svg";
import { useLocation, useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import Validation from "../../../Validation/Validation";
import { GET_API, POST_API } from "../../../Services/api";
import Spinner from "../../Spinner";

function EditBranch() {
  const navigate = useNavigate();

  const { state } = useLocation();
  const listData = state?.list;
  const [errors, seterrors] = useState({});
  const [editBranchDetails, setEditBranchDetails] = useState({
    id: listData?.id,
    name: listData?.name,
    no_of_floors: listData?.no_of_floors.toString(),
    gst_no: listData?.gst_no,
    address: listData?.address,
    phone: listData?.phone,
    town: listData?.town,
    state: listData?.state,
    pincode: listData?.pincode,
    contact_person: listData?.contact_person,
    contact_email: listData?.contact_email,
  });
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [floorList, setFloorList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getFloorList();
  }, []);

  // const getFloorList = async () => {
  //   setLoading(true);
  //   const floorList = await GET_API("/floorList");
  //   if (floorList.status === 200) {
  //     setFloorList(floorList?.data?.data);
  //     setLoading(false);
  //     return;
  //   }
  //   alertify.error(floorList?.response?.message);
  //   setLoading(false);
  // };

  const getFloorList = async () => {
    try {
      setLoading(true);
      const responce = await GET_API("/floorList");
      const floorList = responce?.data?.data?.map(item => { return { label: item?.name, value: item?.id } })
      if (responce.status === 200) {
        setFloorList(floorList);
        setLoading(false);
        return;
      }
    } catch (error) {
      alertify.error(error?.response?.message);
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setEditBranchDetails(() => ({ ...editBranchDetails, [name]: value }));
  };

  const handleSelectChange = (e, name) => {
    const { value } = e;
    setEditBranchDetails({ ...editBranchDetails, [name]: value })
  }

  const saveBranch = async (e) => {
    setButtonDisabled(true);
    const isValid = Validation.validateBranch(editBranchDetails);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    try {
      const updateBranch = await POST_API("/updatebranch", editBranchDetails);
      if (updateBranch?.status === 200) {
        setButtonDisabled(false);
        alertify.success(updateBranch?.data?.message);
        navigate("/masters/branch");
      }
    } catch (e) {
      setButtonDisabled(false);
      alertify.error("Failed to Update Branch List");
    }
  };
  const resetForm = () => {
    setEditBranchDetails({
      id: listData?.id,
      name: listData?.name,
      no_of_floors: listData?.no_of_floors.toString(),
      gst_no: listData?.gst_no,
      address: listData?.address,
      phone: listData?.phone,
      town: listData?.town,
      state: listData?.state,
      pincode: listData?.pincode,
      contact_person: listData?.contact_person,
      contact_email: listData?.contact_email,
    });
  };
  if (loading) {
    return <Spinner />;
  }
  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">Branch</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Edit Branch</h4>
          <div className="add-block">
            <Link to="/masters/branch" className="view-list-button">
              <span>View List</span>
              <img src={View} alt="add" />
            </Link>
          </div>
          {/***************Form************* **/}

          <form className="form">
            <div className="field-block">
              <label className="label-name">
                Branch Name
                <span className="required-symbol">*</span>
              </label>
              <input
                className="input-field"
                type="text"
                placeholder="Please Enter Branch Name"
                name="name"
                onChange={(e) => {
                  handleChange(e);
                }}
                autoComplete="off"
                value={editBranchDetails.name}
              />
              {errors.name ? (
                <div className="validation-error-label">{errors.name}</div>
              ) : null}
            </div>
            <div className="field-block">
              <label className="label-name">No of Floors<span className="required-symbol">*</span></label>
              <Select
                id="no_of_floors"
                options={floorList}
                onChange={(e) => handleSelectChange(e, "no_of_floors")}
                name="no_of_floors"
                value={floorList?.find(item => item?.value === Number(editBranchDetails.no_of_floors))}
                isSearchable
              />
              {errors.no_of_floors ? (
                <div className="validation-error-label">{errors.no_of_floors}</div>
              ) : null}
            </div>
            <div className="flex flex-col gap-1 min-h-[95px]">
              <label className="label-name">
                GSTIN
                <span className="required-symbol">*</span>
              </label>
              <input
                name="gst_no"
                className="input-field"
                type="text"
                placeholder="Please Enter GST Number"
                onChange={(e) => {
                  handleChange(e);
                }}
                autoComplete="off"
                value={editBranchDetails?.gst_no}
              />
              {errors.gst_no ? (
                <div className="validation-error-label">{errors.gst_no}</div>
              ) : null}
            </div>
            <div className="flex flex-col gap-1 min-h-[95px]">
              <label className="label-name">
                Address
                <span className="required-symbol">*</span>
              </label>
              <textarea
                className="input-field"
                type="text"
                placeholder="Please Enter Address"
                name="address"
                onChange={(e) => {
                  handleChange(e);
                }}
                rows={5}
                autoComplete="off"
                value={editBranchDetails?.address}
              />
              {errors.address ? (
                <div className="validation-error-label">{errors.address}</div>
              ) : null}
            </div>
            <div className="flex flex-col gap-1 min-h-[95px]">
              <label className="label-name">
                Town / City
                <span className="required-symbol">*</span>
              </label>
              <input
                className="input-field"
                type="text"
                placeholder="Please Enter Town / City"
                name="town"
                onChange={(e) => {
                  handleChange(e);
                }}
                autoComplete="off"
                value={editBranchDetails?.town}
              />
              {errors.town ? (
                <div className="validation-error-label">{errors.town}</div>
              ) : null}
            </div>
            <div className="flex flex-col gap-1 min-h-[95px]">
              <label className="label-name">
                State
                <span className="required-symbol">*</span>
              </label>
              <input
                className="input-field"
                type="text"
                placeholder="Please Enter State"
                name="state"
                onChange={(e) => {
                  handleChange(e);
                }}
                autoComplete="off"
                value={editBranchDetails.state}
              />
              {errors.state ? (
                <div className="validation-error-label">{errors.state}</div>
              ) : null}
            </div>
            <div className="flex flex-col gap-1 min-h-[95px]">
              <label className="label-name">
                Pincode
                <span className="required-symbol">*</span>
              </label>
              <input
                className="input-field"
                type="text"
                placeholder="Please Enter Pincode"
                name="pincode"
                onChange={(e) => {
                  handleChange(e);
                }}
                autoComplete="off"
                value={editBranchDetails.pincode}
              />
              {errors.pincode ? (
                <div className="validation-error-label">{errors.pincode}</div>
              ) : null}
            </div>

            <div className="flex flex-col gap-1 min-h-[95px]">
              <label className="label-name">
                Contact Person
                <span className="required-symbol">*</span>
              </label>
              <input
                className="input-field"
                type="text"
                placeholder="Please Enter Contact Person"
                name="contact_person"
                onChange={(e) => {
                  handleChange(e);
                }}
                autoComplete="off"
                value={editBranchDetails.contact_person}
              />
              {errors.contact_person ? (
                <div className="validation-error-label">
                  {errors.contact_person}
                </div>
              ) : null}
            </div>
            <div className="flex flex-col gap-1 min-h-[95px]">
              <label className="label-name">
                Contact Number
                <span className="required-symbol">*</span>
              </label>
              <input
                className="input-field"
                type="text"
                placeholder="Please Enter Contact Number"
                name="phone"
                onChange={(e) => {
                  handleChange(e);
                }}
                autoComplete="off"
                value={editBranchDetails.phone}
              />
              {errors.pincode ? (
                <div className="validation-error-label">{errors.pincode}</div>
              ) : null}
            </div>
            <div className="flex flex-col gap-1 min-h-[95px]">
              <label className="label-name">
                Contact Email
                <span className="required-symbol">*</span>
              </label>
              <input
                className="input-field"
                type="text"
                placeholder="Please Enter Pincode"
                name="contact_email"
                onChange={(e) => {
                  handleChange(e);
                }}
                autoComplete="off"
                value={editBranchDetails.contact_email}
              />
              {errors.contact_email ? (
                <div className="validation-error-label">
                  {errors.contact_email}
                </div>
              ) : null}
            </div>
          </form>

          <div className="submit-reset-button">
            <button
              type="button"
              className="reset-button"
              onClick={() => resetForm()}
            >
              <span className="reset-button-label">Cancel</span>
            </button>
            <button
              type="submit"
              className="submit-button"
              onClick={() => saveBranch()}
              disabled={buttonDisabled}
            >
              <span className="submit-button-label">Save</span>
              <img src={Save} alt="" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditBranch;
