import React, { useState, useEffect } from "react";
import alertify from "alertifyjs";
import ApproveTable from "../../DataTable/ApproveTable";
import { GET_API, POST_API } from "../../../Services/api";
import Spinner from "../../Spinner";
import Search from "../../../Assets/Icons/Search.svg";
import "alertifyjs/build/css/alertify.css";

function Approval() {
  const [loading, setLoading] = useState(false);
  const [masterList, setMasterList] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    getMastersList();
  }, []);

  const headCells = [
    { field: "id", headerName: "S.No", minWidth: 70 },
    { field: "masters", headerName: "Masters", minWidth: 70 },
    { field: "functionality", headerName: "Functionality", minWidth: 70 },
    { field: "action", headerName: "Action", minWidth: 70 },
  ];

  const getMastersList = async () => {
    try {
      setLoading(true);
      const masterList = await GET_API("/masterLog");
      if (masterList?.status === 200) {
        setMasterList(masterList?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  const handleApprove = async (type) => {
    const data = {
      selectedRows,
      appType: type,
    };
    if (selectedRows.length <= 0) {
      alertify
        .alert("Please select Row to Approve/Reject")
        .setHeader("<b> Alert </b> ");
      return;
    }
    try {
      setButtonDisabled(true);
      const approveMasters = await POST_API("/approveRejectMasterLog", data);
      if (approveMasters?.status === 200) {
        alertify.success(`Masters ${data?.type}d successfully...!`);
        getMastersList();
        setButtonDisabled(false);
        return;
      }
    } catch (error) {
      alertify.error(`Failed to ${data?.type}d Masters`);
      setButtonDisabled(false);
    }
   
    
  };

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">Masters</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Masters Logs</h4>
          <div className="search-block">
            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                style={{ outline: "none", border: "none" }}
              />

              <img src={Search} alt="" />
            </div>
            <div className="flex gap-3">
              <div
                className="add-new-button-block"
                onClick={() => {
                  handleApprove("reject");
                }}
              >
                <button
                  to=""
                  className="add-new-button"
                  disabled={selectedRows?.length >= 1 ? false : true}
                >
                  <span>Reject</span>
                </button>
              </div>
              <div
                className="add-new-button-block"
                onClick={() => {
                  handleApprove("approve");
                }}
              >
                <button
                  to=""
                  className="add-new-button"
                  disabled={selectedRows?.length >= 1 ? false : true}
                >
                  <span>Approve</span>
                </button>
              </div>
            </div>
          </div>
          {/**************** * Table ********************/}
          <ApproveTable
            columns={headCells}
            list={masterList}
            setSelectedRows={setSelectedRows}
          />
        </div>
      </div>
    </div>
  );
}

export default Approval;
