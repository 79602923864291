import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import alertify from "alertifyjs";
// import { useReactToPrint } from "react-to-print";
import View from "../../../Assets/Icons/View.svg";
import AdvanceDetailsTable from "../../DataTable/AdvanceDetailsTable";
import config from "../../../Services/config.json";
import { GET_API, POST_API } from "../../../Services/api";
import Footer from "../../Footer";
// import LoanPrintVoucher from "./LoanPrintVoucher";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import "alertifyjs/build/css/alertify.css";

function LoanDetails() {
  // const componentRef = useRef();
  const [filteredAdvance, setFilteredAdvance] = useState([]);
  const [filteredEmployee, setFilteredEmployee] = useState([]);

  const { state } = useLocation();
  const listDetails = filteredEmployee;
  const [loading, setLoading] = useState(false);
  const [advanceList, setAdvanceList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);

  const [empDetail, setEmpDetail] = useState({});
  const [editedRows, setEditedRows] = useState({
    actual_due_date: "",
    amount: "",
    id: null,
    paid_amount: null,
    remarks: "",
  });
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const Details = [
    {
      label: "Employee Name",
      value: listDetails?.name ,
    },
    {
      label: "Employee Code",
      value: listDetails?.employee_code ? listDetails?.employee_code : "-",
    },
    {
      label: "Designation",
      value: listDetails?.categoty_name ? listDetails?.categoty_name: "-",
    },
  
    {
      label: "Department",
      value: listDetails?.department_name,
    },
    {
      label: "Mobile Number",
      value: listDetails?.mobile_number,
    },
    {
      label: "Branch",
      value: listDetails?.branch_name,
    },
  ];

  const [Loandetails, setLoandetails] = useState([]);
  // const printVoucher = useReactToPrint({
  //   content: () => componentRef.current,
  // });
  useEffect(() => {
    getAdvanceList();
    getEmployeeDetails();
  }, []);
  const getAdvanceList = async () => {
    setLoading(true);
    GET_API("/FinancePaymentDetailsList/" + state.list.id)
      .then(async function (data) {
        setAdvanceList(data.data.data.financePaymentDetails);
        setFilteredAdvance(data.data.data.financePaymentDetails);
        setLoandetails(data.data.data.FinanceDetails);
        setLoading(false);
      })

      .catch((error) => console.log(error));
  };

  const getEmployeeDetails = async () => {
    setLoading(true);
    GET_API("/FinancePaymentDetailsList/" + state.list.id)
      .then(async function (data) {
        setEmployeeList(data.data.data.employeeDetails);
        setFilteredEmployee(data.data.data.employeeDetails);
        console.log(
          "data?.data?.FinanceDetails",
          data.data.data.employeeDetails
        );
        setLoading(false);
      })

      .catch((error) => console.log(error));
  };

  const advanceDetails = [
    {
      label: "Advance Type",
      value: Loandetails?.finance_category_name,
    },
    {
      label: "Type",
      value: Loandetails?.type,
    },
    {
      label: "Duration",
      value: Loandetails?.duration,
    },
    {
      label: "Approved By",
      value: Loandetails?.approved_by_name,
    },
  ];

  const columns = [
    { id: "s_no", label: "S.No", minWidth: 170 },
    { id: "paid_date", label: "Due Date", minWidth: 100 },
    { id: "paid_amount", label: "Paid Amount", minWidth: 170 },
    { id: "due_amount", label: "Due Amount", minWidth: 170 },
    { id: "Status", label: "Status", minWidth: 100 },
    { id: "Action", label: "" },
  ];

  const handleDueDate = async (rowIndex) => {
    await setEditedRows({
      actual_due_date: "",
      amount: "",
      id: null,
      paid_amount: null,
      remarks: "",
    });
    const nextMonth = new Date(
      Math.max(...filteredAdvance?.map((e) => new Date(e.actual_due_date)))
    );
    nextMonth.setMonth(nextMonth.getMonth() + 1);
    const year = nextMonth.getFullYear();
    const month = String(nextMonth.getMonth() + 1).padStart(2, "0");
    const date = String(nextMonth.getDate()).padStart(2, "0");
    const formatedDates = year + "-" + month + "-" + date;
    const clickedRow = {
      ...filteredAdvance[rowIndex],
      actual_due_date: formatedDates,
      remarks: null,
    };
    await setEditedRows(clickedRow);
  };

  const saveDueDate = (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const data = {
      id: editedRows?.id,
      actual_due_date: editedRows?.actual_due_date,
      remarks: editedRows?.remarks,
    };
    console.log("editedRows", editedRows);
    POST_API(`/updateFinancePaymentDetailsList`, data)
      .then(async function (data) {
        console.log("data", data);
        setButtonDisabled(false);
        alertify.success(data?.data?.message);
        getAdvanceList();
        getEmployeeDetails();
        setEditedRows([]);
      })
      .catch((error) => {
        setButtonDisabled(false);
        alertify.error(error?.response?.data?.message);
      });
  };

  const handleChange = (e, rowIndex) => {
    const { name, value } = e?.target;
    setEditedRows(() => ({ ...editedRows, [name]: value }));
  };

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className=" sm-container">
        <h1 className="heading-text">Loan</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Loan Details</h4>
          <div className="add-block">
            {/* <button
              type="button"
              className="submit-button"
              onClick={() => printVoucher()}
            >
              <span className="submit-button-label">Print</span>
            </button> */}
            <Link to="/expense/loan" className="view-list-button">
              <span>View List</span>
              <img src={View} alt="add" />
            </Link>
          </div>
          <div className="advance-details-image ">
            <div className="advance-details-image-container">
              <div
                style={{ wordBreak: "break-word" }}
                className="text-[#7B7B7B] text-base font-karla"
              >
                <img
                  src={`${config.path}${empDetail?.photo_path}`}
                  alt=""
                  style={{ height: "120px", width: "120px" }}
                />
              </div>
            </div>
          </div>
          <div className="list-container ">
            <div className="advance-section">
              {advanceDetails.map((list, i) => {
                return (
                  <div className="flex " key={i}>
                    <label className="list-label">{list.label}: </label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="advance-section-list-value"
                    >
                      {list.value}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
       
          <div className="list-container border-bottom">
            <div className="list-content-block">
              {Details.map((list, i) => {
                return (
                  <div className="list " key={i}>
                    <label className="list-label">{list.label}</label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="list-value"
                    >
                      {list.value}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

         
          {editedRows?.id ? (
            <div className="add-list-container loandetails-border">
              <div className="loandetails-input-block mt-5">
                <label className="add-new-input-label">
                  Due Date <span className="required-symbol">*</span>
                </label>
                <input
                  type="date"
                  name="actual_due_date"
                  className="input-field"
                  placeholder=""
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  autoComplete="off"
                  value={editedRows.actual_due_date}
                />
              </div>
              <div className="loandetails-input-block mt-5">
                <label className="add-new-input-label">Paid Amount</label>
                <input
                  type="text"
                  name="paid_amount"
                  className="input-field"
                  placeholder=""
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  autoComplete="off"
                  value={editedRows.paid_amount ? editedRows.paid_amount : 0}
                  disabled
                />
              </div>
              <div className="loandetails-input-block mt-5">
                <label className="add-new-input-label">Due Amount</label>
                <input
                  type="text"
                  name="amount"
                  className="input-field"
                  placeholder=""
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  autoComplete="off"
                  value={editedRows.amount}
                  disabled
                />
              </div>
              <div className="loandetails-input-block mt-5">
                <label className="add-new-input-label">Remarks</label>
                <textarea
                  type="text"
                  name="remarks"
                  className="input-field"
                  placeholder=""
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  autoComplete="off"
                  value={editedRows.remarks}
                />
              </div>
              <div className="flex gap-3 mt-5">
                <div className="add-new-button-block">
                  <button
                    className="add-new-button"
                    onClick={(e) => {
                      saveDueDate(e);
                    }}
                    disabled={buttonDisabled}
                  >
                    <span className="button-label">Save</span>
                    <img src={ColorAdd} alt="add" />
                  </button>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          <div>
            <AdvanceDetailsTable
              list={filteredAdvance}
              columns={columns}
              handleDueDate={handleDueDate}
              editedRows={editedRows}
            />
          </div>
          {/* <LoanPrintVoucher ref={componentRef} printVoucherData={listDetails} /> */}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default LoanDetails;
