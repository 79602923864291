// ** Initial State
const initialState = {
    dashboardData: [],
}

const dashboard = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_DASHBOARD_DATA':
            return { ...state, dashboardData: action.data}
        default:
            return { ...state }
    }
}

export default dashboard
