import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import alertify from "alertifyjs";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import dayjs from "dayjs";
import { read, utils } from "xlsx";
import { GET_API, POST_API } from "../../../Services/api";
import ManualAttendanceListTable from "../../DataTable/ManualAttendanceListTable";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import Search from "../../../Assets/Icons/Search.svg";
import Add from "../../../Assets/Icons/Add.svg";
import Spinner from "../../Spinner";
import More from "../../../Assets/Icons/More.svg";
import "alertifyjs/build/css/alertify.css";

function ManualAttendanceList() {
  const inputFile = useRef(null);
  const navigate = useNavigate();
  const [filteredBranch, setFilteredBranch] = useState([]);
  const [loading, setLoading] = useState(false);

  const [attendanceData, setAttendanceData] = useState([])
  const [clientList, setClientList] = useState([]);
  const [clientValue, setClientValue] = useState("");
  const [currentMonth, setCurrentMonth] = useState("");

  const columns = [
    { id: "employee_code", label: "Employee Code" },
    { id: "name", label: "Employee Name" },
    { id: "total_number_of_days", label: "Total Working Days" },
    { id: "present_days", label: "Present Days" },
    { id: "incentive_days", label: "Incentive Days" },
    { id: "other_deductions", label: "Other Deductions" },
    { id: "canteen_deduction", label: "Canteen Deduction" },
    { id: "pf_deductions", label: "PF Deduction" },
    { id: "date", label: "Date" },
  ];

  useEffect(() => {
    setCurrentMonth(dayjs(new Date()).format("YYYY-MM"));
    getClientDetails();
  }, []);

  const getClientDetails = async () => {
    try {
      setLoading(true);
      const response = await GET_API("clientLists")
      if (response?.status === 200) {
        const res = response?.data?.data?.map((item) => {
          return { label: item?.name, value: item?.id }
        })
        await setClientValue(res[0]?.value) // intial loading setting client value to the dropdown
        setClientList(res)
        setLoading(false);
        await handleSearch(res[0]?.value, dayjs(new Date()).format("YYYY-MM"));
      }
    } catch (error) {
      setClientList([])
      setLoading(false);
    }
  }

  const getClientEmployees = async (client, monthYear) => {
    try {
      setLoading(true);
      const response = await GET_API(`employeeManualAttendanceList/${client}?year=${dayjs(monthYear).format("YYYY")}&month=${dayjs(monthYear).format("MM")}`)
      if (response?.status === 200) {
        const res = response?.data?.data?.map((item) => {
          return { ...item, date: dayjs(new Date()).format("DD-MM-YYYY"), total_number_of_days:response?.data?.salary_calc_days }
        })
        setAttendanceData(res)
        setFilteredBranch(res)
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }

  const handleBranchSearch = (e) => {
    const searchTerm = e.target.value;
    const searchedList = attendanceData.filter((item) => {
      for (let key in item) {
        if (
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
    setFilteredBranch(searchedList);
  };

  const showBranchDetails = (listDetail) => {
    navigate(`/masters/manualattendance/details/${listDetail?.empAttdId}`, {
      state: {
        list: listDetail,
      },
    });
  };

  const handleSearch = (client, monthYear) => {
    getClientEmployees(client, monthYear);
  }

  const handleDateChange = (e) => {
    setCurrentMonth(e.target.value);
  };

  const handleClientSelect = (e) => {
    setClientValue(e?.value)
  }


  const onImportClick = () => {
    inputFile.current.click();
  };
  const fileUpload = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (e.target.files.length) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = async(e) => {
        const wb = read(e.target.result);
        const sheets = wb.SheetNames;
        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]], {
            raw: false,
            dateNF: "dd-mm-yyyy hh:mm:ss",
          });
          try {
            const responce = await POST_API(`/importAttendance?year=${dayjs(currentMonth).format("YYYY")}&month=${dayjs(currentMonth).format("MM")}`, { data: rows })
            if(responce?.status === 200){
              alertify.success(responce?.data?.message);
              getClientEmployees(clientValue, currentMonth);
            }
          } catch (error) {
            alertify.error("Some error Occured.Please try again")
          }
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };


  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">Manual Attendance</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Manual Attendance List</h4>
          <div className="search-block">
            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                onChange={(e) => handleBranchSearch(e)}
                style={{ outline: "none", border: "none" }}
              />

              <img src={Search} alt="" />
            </div>
            <div className="field-block dropdown-select-to-one w-44">
              <label className="label-name">Select Client</label>
              <Select
                id={`client_id`}
                options={clientList}
                onChange={(e) => handleClientSelect(e, "client_id")}
                name="client_id"
                value={clientList?.find(
                  (option) => option?.value === clientValue
                )}
                isSearchable
              />
            </div>
            <div className="field-block w-44">
              <label className="label-name">Month</label>
              <input
                type="month"
                name="month"
                className="input-field"
                placeholder="Enter Month"
                onChange={handleDateChange}
                value={currentMonth}
                max={dayjs().format("YYYY-MM")}
              />
            </div>
            <div className="field-block">
              <button
                type="button"
                className="submit-button mt-6"
                onClick={()=>handleSearch(clientValue, currentMonth)}
              >
                <span className="submit-button-label">Find</span>
              </button>
            </div>
            <div className="add-new-button-block">
              <input
                type="file"
                id="file"
                ref={inputFile}
                style={{ display: "none" }}
                onChange={fileUpload}
              />

              <button className="add-new-button" onClick={onImportClick}>
                <span style={{ cursor: "pointer" }}>Import</span>
                <img src={Add} alt="" />
              </button>
            </div>
            <div className="add-new-button-block w-44">
              <Link
                to="/masters/manualattendance/add"
                className="add-new-button"
              >
                <span>Add New</span>
                <img src={ColorAdd} alt="add" />
              </Link>
            </div>

          </div>
          <ManualAttendanceListTable
            list={filteredBranch}
            columns={columns}
            icon={More}
            multiple={true}
            showDetails={showBranchDetails}
          />
        </div>
      </div>
    </div>
  );
}

export default ManualAttendanceList;
