import React, { useState, useEffect } from "react";
import Select from "react-select";
import { DELETE_API, GET_API } from "../../Services/api";
import Validation from "../../Validation/Validation";
import { POST_API } from "../../Services/api";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import GovernemntIdProofListTable from "../DataTable/GovernmentIdProofTable";
import Search from "../../Assets/Icons/Search.svg";
import ColorAdd from "../../Assets/Icons/ColorAdd.svg";
import { Link } from "react-router-dom";
import Edit from "../../Assets/Icons/Edit.svg";
import More from "../../Assets/Icons/More.svg";
import Spinner from "../Spinner";

function IDProof() {
  const [filter, setfilter] = useState([]);
  const [idproofList, setIdproofList] = useState([]);
  const [errors, seterrors] = useState({});
  const [buttonLabel, setbuttonLabel] = useState("Add");
  const [idproofId, setIdproofId] = useState();
  const [editStatus, setEditStatus] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const [idproofDetails, setIdproofDetails] = useState({
    name: "",
    mandatory: "",
    type: "idproof",
  });

  const header = [
    { id: "s.no", label: "S.No", minWidth: 170 },
    { id: "name", label: "Government ID Proof Name", minWidth: 100 },
    { id: "manditory", label: "Mandatory", minWidth: 100 },
    { id: "created_at", label: "Created at", minWidth: 100 },
    { id: "more_icon", label: "", minWidth: 100 },
  ];

  useEffect(() => {
    getIdproofList();
  }, []);

  const getIdproofList = async () => {
    try {
      setLoading(true);
      const proofList = await GET_API("/govermentidproofs");
      if (proofList.status === 200) {
        setLoading(false);
        setIdproofList(proofList?.data?.data);
        setfilter(proofList?.data?.data);
      }
    } catch (e) {
      setLoading(false);
      setButtonDisabled(false);
      alertify.error(e.response.data.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setIdproofDetails(() => ({ ...idproofDetails, [name]: value }));
  };

  const handleSelectChange = (e, name) => {
    const { value } = e;
    setIdproofDetails(() => ({ ...idproofDetails, [name]: value }));
  }

  const createProofList = async (e) => {
    setButtonDisabled(true);
    const isValid = Validation.ValidateGovernmentIdProof(idproofDetails);
    if (isValid && isValid.error) {
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      setButtonDisabled(false);
      return;
    } else {
      seterrors({});
    }

    const addProof = await POST_API("/addGovermentIdProofs", idproofDetails);
    if (addProof.status === 200) {
      alertify.success(addProof?.data?.message);
      getIdproofList();
      setIdproofDetails((prevState) => ({
        ...prevState,
        name: "",
        mandatory: "",
      }));
      setButtonDisabled(false);
      return;
    }
    alertify.error(e?.response?.data?.message);
  };

  const editProofList = async () => {
    setButtonDisabled(true);
    const isValid = Validation.ValidateGovernmentIdProof(idproofDetails);
    if (isValid && isValid.error) {
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      setButtonDisabled(false);
      return;
    } else {
      seterrors({});
    }
    try {
      const editProof = await POST_API(
        "/updateGovermentIdProofs",
        idproofDetails
      );
      if (editProof.status) {
        alertify.success(editProof?.data?.message);
        getIdproofList();
        setIdproofDetails((prevState) => ({
          ...prevState,
          name: "",
          mandatory: "",
        }));
        setButtonDisabled(false);
      }
    } catch (e) {
      alertify.error(e?.response?.data?.message);
    }
  };

  const resetFieldsValue = () => {
    if (buttonLabel === "Update") {
      setbuttonLabel("Add");
    }
    setIdproofDetails((prevState) => ({
      ...prevState,
      name: "",
      mandatory: "",
    }));
    setEditStatus(false);
    seterrors({});
  };
  
  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Proof..?",
        async function () {
          if (row_id) {
            const deleteRow = await DELETE_API(
              `/deleteGovermentIdProofs/${row_id}`
            );
            if (deleteRow.status === 200) {
              alertify.success(deleteRow?.data?.message);
              getIdproofList();
              return;
            }
            alertify.error("Failed To Delete Floor");
          }
        },
        function () { }
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };
  const handleProofSearch = (e) => {
    const searchTerm = e.target.value;
    const searchedList = idproofList.filter((item) => {
      for (let key in item) {
        if (
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
    setfilter(searchedList);
  };

  const mandatoryOptions = [
    { label: "Select...", value: "" },
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
  ]

  if (loading) {
    return <Spinner />;
  }
  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">Government ID Proof</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Government ID Proof List</h4>
          <div className="search-block">
            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                onChange={(e) => handleProofSearch(e)}
                style={{ outline: "none", border: "none" }}
              />
              <img src={Search} alt="" />
            </div>
          </div>
          <div className="add-list-container">
            <div className="input-block">
              <label className="add-new-input-label">
                Proof Name <span className="required-symbol">*</span>
              </label>
              <input
                className="input-field"
                type="text"
                onChange={(e) => handleChange(e)}
                name="name"
                placeholder="Enter Govt ID Proof Name"
                autoComplete="off"
                value={idproofDetails.name}
              />
              {errors.name ? (
                <div className="text-[#FF5C42] text-sm font-medium font-karla">
                  {errors.name}
                </div>
              ) : null}
            </div>
            <div className="input-block dropdown-select-to-one">
              <label className="label-name">Is This Mandatory....?{" "}<span className="required-symbol">*</span></label>
              <Select
                id="mandatory"
                options={mandatoryOptions}
                onChange={(e) => handleSelectChange(e, "mandatory")}
                name="mandatory"
                value={mandatoryOptions?.find(item => item?.value === idproofDetails.mandatory)}
                isSearchable
              />
              {errors.mandatory ? (
                <div className="validation-error-label">
                  {errors.mandatory}
                </div>
              ) : null}
            </div>
            
            <div className="flex gap-3">
              <div className="add-new-button-block">
                {editStatus ? (
                  <button
                    className="add-new-button"
                    disabled={buttonDisabled}
                    onClick={(e) => {
                      editProofList(e);
                    }}
                  >
                    <span className="button-label">Save</span>
                    <img src={ColorAdd} alt="add" />
                  </button>
                ) : (
                  <button
                    className="add-new-button"
                    disabled={buttonDisabled}
                    onClick={(e) => {
                      createProofList(e);
                    }}
                  >
                    <span className="button-label">Add New</span>
                    <img src={ColorAdd} alt="add" />
                  </button>
                )}
              </div>
              <Link className="edit-button" onClick={() => resetFieldsValue()}>
                <span className="button-label">Reset</span>
                <img src={Edit} alt="add" />
              </Link>
            </div>
          </div>

          <GovernemntIdProofListTable
            columns={header}
            list={filter}
            icon={More}
            deleteRow={handleDelete}
            editList={setIdproofDetails}
            editStatus={setEditStatus}
          />
        </div>
      </div>
    </div>
  );
}

export default IDProof;
