import React from "react";
import { Route } from "react-router-dom";

import FoodAllowanceList from "../Allowance/FoodAllowance/FoodAllowanceList";


function ApproveRoutes() {
  return (
    <>
      <Route path="food" element={<FoodAllowanceList />}></Route>
    </>
  );
}

export default ApproveRoutes;