import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Spinner from "../Spinner";
import { GET_API } from "../../Services/api";
import BonusListTable from "../DataTable/BonusDetails";
import ColorAdd from "../../Assets/Icons/ColorAdd.svg";
import Search from "../../Assets/Icons/Search.svg";
import alertify from "alertifyjs";

function BonusList() {
  const userId = localStorage?.getItem("user_id");
  const [bonusList, setBonusList] = useState([]);
  const [filteredBonusList, setFilteredBonusList] = useState([]);
  const [loading, setLoading] = useState(false);

  // const showDetails = (listDetail) => {
  //   navigate("/expense/bonus/details", {
  //     state: {
  //       list: listDetail,
  //     },
  //   });
  // }; 
  
  const columns = [
    { id: "s.no", label: "S.No", minWidth: 170 },
    { id: "employee_name", label: "Employee Name", minWidth: 100 },
    { id: "EmployeeCode", label: "Employee Code", minWidth: 100 },
    {
      id: "financeCategoryName",
      label: "Finance Category Name",
      minWidth: 100,
    },
    { id: "amount", label: "Amount", minWidth: 100 },
    { id: "remarks", label: "Remarks", minWidth: 100 },
    { id: "created_at", label: "Date", minWidth: 100 },
    // { id: "more_icon", label: "", minWidth: 100 },
  ];

  useEffect(() => {
    getBonusList();
  }, []);

  const getBonusList = async () => {
    setLoading(true);
    try {
      const bonusList = await GET_API(`/BonusDetailsList?login=${userId}`);
      if (bonusList.status === 200) {
        setLoading(false);
        setBonusList(bonusList?.data?.data);
        setFilteredBonusList(bonusList?.data?.data);
      }
    } catch (error) {
      setLoading(false);
      alertify.error("Failed to get Bonus List");
    }
  };

  const handleBranchSearch = (e) => {
    const searchTerm = e.target.value;
    const searchedList = bonusList.filter((item) => {
      for (let key in item) {
        if (
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
    setFilteredBonusList(searchedList);
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">Bonus</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Bonus Details List</h4>
          <div className="search-block">
            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                onChange={(e) => handleBranchSearch(e)}
                style={{ outline: "none", border: "none" }}
              />
              <img src={Search} alt="" />
            </div>
            <div className="flex gap-3">
              <div className="add-new-button-block">
                <Link
                  to="/expense/bonus/add"
                  state={{ from: "advance" }}
                  className="add-new-button"
                >
                  <span>Add New</span>
                  <img src={ColorAdd} alt="add" />
                </Link>
              </div>
            </div>
          </div>

          <BonusListTable
            list={filteredBonusList}
            columns={columns}
          // icon={More}
          // showDetails={showDetails}
          // multiple={true}
          />
        </div>
      </div>
    </div>
  );
}
export default BonusList;
