import React, { useState, useEffect } from "react";
import BonusApprovalTable from "../../DataTable/BonusApprovalTable";
import Search from "../../../Assets/Icons/Search.svg";
import Spinner from "../../Spinner";
import alertify from "alertifyjs";
import { GET_API, POST_API } from "../../../Services/api";
import "alertifyjs/build/css/alertify.css";

function BonusApproval() {
  const [loading, setLoading] = useState(false);
  const [bonusList, setBonusList] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    getBonusList();
  }, []);

  const headCells = [
    { field: "EmployeeCode", headerName: "Employee Code", minWidth: 150 },
    { field: "employee_name", headerName: "Employee Name", minWidth: 250 },
    { field: "department_name", headerName: "Department", minWidth: 150 },
    { field: "designation_name", headerName: "Designation", minWidth: 150 },
    { field: "payment_type", headerName: "Payment Type", minWidth: 150 },
    { field: "type", headerName: "Type", minWidth: 150 },
    { field: "amount", headerName: "Amount", minWidth: 150 },
  ];

  const getBonusList = async () => {
    setLoading(true);
    try {
      const bonusList = await GET_API("/bonusApproval");
      if (bonusList?.status === 200) {
        setBonusList(bonusList?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  const handleApprove = async (type) => {
    const data = {
      selectedRows,
      appType: type,
    };
    if (selectedRows.length <= 0) {
      alertify
        .alert("Please select Row to Approve/Reject")
        .setHeader("<b> Alert </b> ");
      return;
    }
    try {
      const approveBonus = await POST_API("/updatebonusApproval", data);
      if (approveBonus?.status === 200) {
        alertify.success(`Bonus ${data?.type}d successfully...!`);
        getBonusList();
        return;
      }
    } catch (error) {
      alertify.error(`Failed to ${data?.type}d Bonus`);
    }
  };

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">Bonus</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Bonus Logs</h4>
          <div className="search-block">
            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                style={{ outline: "none", border: "none" }}
              />

              <img src={Search} alt="" />
            </div>
            <div className="flex gap-3">
              <div
                className="add-new-button-block"
                onClick={() => {
                  handleApprove("reject");
                }}
              >
                <button
                  to=""
                  className="add-new-button"
                // disabled={selectedRows?.length >=1 ? false:true}
                >
                  <span>Reject</span>
                </button>
              </div>
              <div
                className="add-new-button-block"
                onClick={() => {
                  handleApprove("approve");
                }}
              >
                <button
                  to=""
                  className="add-new-button"
                // disabled={selectedRows?.length >=1 ? false:true}
                >
                  <span>Approve</span>
                </button>
              </div>
            </div>
          </div>
          {/**************** * Table ********************/}
          <BonusApprovalTable
            columns={headCells}
            list={bonusList}
            setSelectedRows={setSelectedRows}
          />
        </div>
      </div>
    </div>
  );
}

export default BonusApproval;
