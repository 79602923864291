import React, { useState, useEffect } from "react";
import Search from "../../Assets/Icons/Search.svg";
import ColorAdd from "../../Assets/Icons/ColorAdd.svg";
import { Link } from "react-router-dom";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import { useNavigate } from "react-router-dom";
import { GET_API, DELETE_API } from "../../Services/api";
import LeaveOnDutyListTable from "../DataTable/LeaveOnDutyListTable";
import More from "../../Assets/Icons/More.svg";
import Spinner from "../Spinner";
function LeaveOndutyList() {
  const navigate = useNavigate();
  const [leaveList, setLeaveOndutyList] = useState([]);
  const [filteredBranch, setFilteredBranch] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "employee_code", label: "Employee Code" },
    { id: "employee_name", label: "Employee Name" },
    { id: "from_date", label: "From Date" },
    { id: "to_date", label: "To Date" },
    { id: "no_of_days", label: "No of Days" },
    { id: "type", label: "Type" },
    { id: "more_icon", label: "" },
  ];

  useEffect(() => {
    getLeaveOndutyList();
  }, []);

  const getLeaveOndutyList = async () => {
    setLoading(true);
    GET_API("/employeeLeaveList")
      .then(async function (data) {
        const leaveListArr = [];
        data?.data?.data.map((item) => {
          leaveListArr.push(item);
        });
        setLoading(false);
        setLeaveOndutyList(leaveListArr);
        setFilteredBranch(leaveListArr);
      })

      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const handleBranchSearch = (e) => {
    const searchTerm = e.target.value;
    const searchedList = leaveList.filter((item) => {
      for (let key in item) {
        if (
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
    setFilteredBranch(searchedList);
  };

  const navigateEditPage = (listDetail) => {
    navigate("/branch-edit", {
      state: {
        list: listDetail,
      },
    });
  };

  const showBranchDetails = (listDetail) => {
    navigate(`/masters/leaveonduty/details/${listDetail?.id}`, {
      state: {
        list: listDetail,
      },
    });
  };

  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Branch",
        async function () {
          if (row_id) {
            const deleteRow = await DELETE_API(`/deletebranch/${row_id}`);
            if (deleteRow.status === 200) {
              alertify.success(deleteRow?.data?.message);
              getLeaveOndutyList();
              return;
            }
            alertify.error("Failed To Delete Floor");
          }
        },
        function () {}
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">Leave & Onduty</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Leave & Onduty List</h4>
          <div className="search-block">
            <div className="add-new-button-block">
              <Link to="/masters/leaveonduty/add" className="add-new-button">
                <span>Add New</span>
                <img src={ColorAdd} alt="add" />
              </Link>
            </div>
            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                onChange={(e) => handleBranchSearch(e)}
                style={{ outline: "none", border: "none" }}
              />

              <img src={Search} alt="" />
            </div>
          </div>

          <LeaveOnDutyListTable
            list={filteredBranch}
            columns={columns}
            icon={More}
            multiple={true}
            editPage={navigateEditPage}
            showDetails={showBranchDetails}
            deleteRow={handleDelete}
          />
        </div>
      </div>
    </div>
  );
}
export default LeaveOndutyList;
