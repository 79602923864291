import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { GET_API, POST_API } from "../../Services/api";
import EmployeeBasicInfoComp from "./Tabs/BasicInfo";
import EmployeeBankInfoComp from "./Tabs/Bankinfo";
import EmployeePositionInfoComp from "./Tabs/Positioninfo";
import EmployeePersonalInfoComp from "./Tabs/PersonaInfo";
import EmployeeEducationInfoComp from "./Tabs/EducationInfo";
import EmployeeTypeModal from "./Tabs/Components/EmployeeTypeModal";
import config from "../../Services/config.json";
import "alertifyjs/build/css/alertify.css";

function CreateEmployees() {
  const navigate = useNavigate();
  const userId = localStorage?.getItem("user_id");
  const [value, setValue] = useState("basic_info");
  const [oldEmployee, setOldEmployee] = useState(null);
  const [employeeList, setEmployeeList] = useState([]);
  const [empName, setEmpName] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [isModal, setIsModal] = useState(false)
  const [employeeJoin, setEmployeeJoin] = useState('newJoining')
  const [isBtnDisable, setIsBtnDisable] = useState(false)
  const [inActiveEmpList, setnActiveEmpList] = useState([]);
  const [inActiveEmpData, setnActiveEmpData] = useState([]);

  const [empData, setEmpData] = useState({
    rejoin_employee: "",
    profile_picture: "",
    joining_date: dayjs(new Date()).format("YYYY-MM-DD"),
    client_id:"",
    category_id:"",
    name: "",
    employee_type: "",
    department_id: "",
    employee_code: "",
    designation_id: "",
    branch_id: "",
    floors_id: "",
    shift_id: "",
    reporting_to: "",
    holiday_scheme_id: "",
    refrred_by: "",
    ref_by_others: "",
    father_name: "",
    mother_name: "",
    aadhar_no: "",
    date_of_birth: "",
    age: "",
    mobile_number: "",
    email_id: "",
    gender: "",
    marital_status: "",
    present_address_door_no: "",
    present_address_street_name: "",
    present_address_street_name_two: "",
    present_address_city: "",
    present_address_state: "",
    present_address_pincode: "",
    same_as_permanent_address: false,
    permanent_address_door_no: "",
    permanent_address_street_name: "",
    permanent_address_street_name_two: "",
    permanent_address_city: "",
    permanent_address_state: "",
    permanent_address_pincode: "",
    workExperience: [],
    familyDetails: [],
    height: "",
    weight: "",
    blood_pressure: "",
    diabets: "",
    heart_problem: "",
    skin_disease: "",
    aadhar_image: "",
    school_tc: "",
    sslc_Certificate: "",
    hsc_certificate: "",
    degree_certificate: "",
    additional_course_certificate_one_image: "",
    additional_course_certificate_two_image: "",
    additional_course_certificate_three_image: "",
    additional_course_certificate_four_image: "",
    driving_license: "",
    ex_serviceman_dischare_book_image: "",
    work_Exprience_certificate_one_image: "",
    work_Exprience_certificate_two_image: "",
    work_Exprience_certificate_three_image: "",
    work_Exprience_certificate_four_image: "",
    police_verification_certificate_number: "",
    police_verification_certificate_image: "",
    driving_license_image_upload: "",
    employee_qualification: "",
    employee_passed_out: "",
    qualification_details: [],
    esi_number:"",
    uan_number:"",
    pf_number:"",
    hdfc_account_name: "",
    hdfc_account_no: "",
    hdfc_bank_passbook_coverpage_image: "",
    hdfc_cancelled_cheque_leaf_image: "",
    home_bank_account_name: "",
    home_bank_account_no: "",
    home_bank_ifsc_code: "",
    home_bank_branch: "",
    bank_passbook_coverpage_image: "",
    cancelled_cheque_leaf_image: "",
  })
  console.log("empData", empData);

  const handleModal = () => {
    setIsModal(!isModal);
  };

  const handleTabChanges = (value) => {
    setValue(value);
  };

  useEffect(() => {
    getEmployeeList()
    getInactiveEmpList()
  }, [])

  const handleSameAsPermanentAddress = (event) => {
    event.target.checked
      ? setEmpData(() => ({
        ...empData, same_as_permanent_address: 1,
        ["permanent_address_door_no"]: empData["present_address_door_no"],
        ["permanent_address_street_name"]: empData["present_address_street_name"],
        ["permanent_address_street_name_two"]: empData["present_address_street_name_two"],
        ["permanent_address_city"]: empData["present_address_city"],
        ["permanent_address_state"]: empData["present_address_state"],
        ["permanent_address_pincode"]: empData["present_address_pincode"],
      }))
      : setEmpData(() => ({
        ...empData, same_as_permanent_address: 0,
        ["permanent_address_door_no"]: "",
        ["permanent_address_street_name"]: "",
        ["permanent_address_street_name_two"]: "",
        ["permanent_address_city"]: "",
        ["permanent_address_state"]: "",
        ["permanent_address_pincode"]: "",
      }));
  };

  const getEmployeeList = async () => {
    setIsLoading(true);
    try {
      const employeeList = await GET_API(`/ListEmployeeTentacles?login=${userId}`);
      if (employeeList?.status === 200) {
        const empList = employeeList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: `${dep?.name} ${dep?.employee_code}` };
        });
        empList.unshift({ value: "", label: "Please Select" });
        setEmployeeList(employeeList?.data?.data);
        setEmpName(empList);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      alertify.error("Failed to get Employee List");
    }
  };

  const getInactiveEmpList = async () => {
    setIsLoading(true);
    try {
      const employeeList = await GET_API("/getInactiveEmp");
      if (employeeList?.status === 200) {
        const empList = employeeList?.data?.data?.map((dep, i) => {
          return { value: dep?.employee_id, label: `${dep?.name} / ${dep?.aadhar_no}` };
        });
        empList.unshift({ value: "", label: "Please Select" });
        const inActiveData = employeeList?.data?.data
        const inActive = inActiveData.map((item)=>{
          const {id, ...rest} = item;
          return {...rest, is_active:2};
        })
        setnActiveEmpData(inActive)
        setnActiveEmpList(empList);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      alertify.error("Failed to get Employee List");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsBtnDisable(true)
    try {
      const basicInfo = await POST_API(empData?.rejoin_employee ? "addEmployeeTentacles":"/addEmployeeTentaclespage", empData);
      if (basicInfo?.data?.status === 200) {
        setIsBtnDisable(false)
        navigate(`/employee/edit/${basicInfo?.data?.id}`, {
          state: {
            personalInfo:true
          },
        });
      }
    } catch (error) {
      console.log("error");
      setIsBtnDisable(false)
    }
  };


  const handleEmpName = async (e) => {
    const { value } = e
    setOldEmployee(e)
    const list = await inActiveEmpData?.filter(item => item?.employee_id === value)
    const data = await GET_API(`/getEmployeeagainstDetails/${value}`);
    const listData = data?.data
    await setEmpData({
      ...list[0],
      rejoin_employee: value,
      workExperience: listData?.work_exprience_tentacles,
      familyDetails: listData?.family_details_tentacles,
      qualification_details: listData?.education_details_tentacles,
    })
  }
  useEffect(() => {
    handleModal(!isModal)
  }, [])

  const employeeJoinChange = (e) => {
    setEmployeeJoin(e?.target?.value)
  }

  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="flex flex-col justify-between h-full">
        <div className="sm-container">
          <h1 className="heading-text">Employee</h1>
          <div className="card-wrapper">
            <h4 className="card-header">Add Employee</h4>
            <div className="form mt-3">
              <EmployeeTypeModal
                isModal={isModal}
                handleModal={handleModal}
                employeeJoin={employeeJoin}
                employeeJoinChange={employeeJoinChange}
                empNameOptions={inActiveEmpList}
                handleEmpNameChange={handleEmpName}
                oldEmployee={oldEmployee}
              />
            </div>
            <Box sx={{ width: "100%" }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList aria-label="lab API tabs example">
                    <Tab
                      style={{ fontFamily: "Montserrat", cursor: "text" }}
                      value="basic_info"
                      icon={
                        <div
                          style={{
                            display: "flex",
                            gap: "4px",
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{
                              height: "24px",
                              width: "24px",
                              borderRadius: "14px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              border: "1px solid #C1314C",
                              fontFamily: "Montserrat",
                              backgroundColor:
                                value === "basic_info" ? "#C1314C" : "#fff",
                              color:
                                value === "basic_info" ? "#fff" : "#271E0D",
                            }}
                          >
                            1
                          </span>
                          <span>Basic Info</span>
                        </div>
                      }
                    />
                    <Tab
                      style={{ fontFamily: "Montserrat", cursor: "text" }}
                      value="personal_info"
                      icon={
                        <div
                          style={{
                            display: "flex",
                            gap: "4px",
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{
                              height: "24px",
                              width: "24px",
                              borderRadius: "14px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              border: "1px solid #C1314C",
                              fontFamily: "Montserrat",
                              backgroundColor:
                                value === "personal_info" ? "#C1314C" : "#fff",
                              color:
                                value === "personal_info" ? "#fff" : "#271E0D",
                            }}
                          >
                            2
                          </span>
                          <span>Personal Info</span>
                        </div>
                      }
                    />
                    <Tab
                      style={{
                        display: "flex",
                        fontFamily: "Montserrat",
                        cursor: "text",
                      }}
                      value="position_info"
                      icon={
                        <div
                          style={{
                            display: "flex",
                            gap: "4px",
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{
                              height: "24px",
                              width: "24px",
                              borderRadius: "14px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              border: "1px solid #C1314C",
                              fontFamily: "Montserrat",
                              backgroundColor:
                                value === "position_info" ? "#C1314C" : "#fff",
                              color:
                                value === "position_info" ? "#fff" : "#271E0D",
                            }}
                          >
                            3
                          </span>
                          <span>Position Info</span>
                        </div>
                      }
                    />
                    <Tab
                      style={{ fontFamily: "Montserrat", cursor: "text" }}
                      value="education_info"
                      icon={
                        <div
                          style={{
                            display: "flex",
                            gap: "4px",
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{
                              height: "24px",
                              width: "24px",
                              borderRadius: "14px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              border: "1px solid #C1314C",
                              fontFamily: "Montserrat",
                              backgroundColor:
                                value === "education_info" ? "#C1314C" : "#fff",
                              color:
                                value === "education_info" ? "#fff" : "#271E0D",
                            }}
                          >
                            4
                          </span>
                          <span>Education Info</span>
                        </div>
                      }
                    />
                    <Tab
                      style={{ fontFamily: "Montserrat", cursor: "text" }}
                      value="bank_info"
                      icon={
                        <div
                          style={{
                            display: "flex",
                            gap: "4px",
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{
                              height: "24px",
                              width: "24px",
                              borderRadius: "14px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              border: "1px solid #C1314C",
                              fontFamily: "Montserrat",
                              backgroundColor:
                                value === "bank_info" ? "#C1314C" : "#fff",
                              color: value === "bank_info" ? "#fff" : "#271E0D",
                            }}
                          >
                            5
                          </span>
                          <span>Bank Info</span>
                        </div>
                      }
                    />
                  </TabList>
                </Box>
                <TabPanel value="basic_info" key="basic_info">
                  <EmployeeBasicInfoComp
                    empDetails={empData}
                    stateChanger={setEmpData}
                    nextTab={handleTabChanges}
                    handleSameAsPermanentAddress={handleSameAsPermanentAddress}
                    employeeList={empName}
                    submitEmployeeDetails={(e) => handleSubmit(e)}
                    baseurl={config?.path}
                    isBtnDisable={isBtnDisable}
                  />
                </TabPanel>
                <TabPanel value="personal_info">
                  <EmployeePersonalInfoComp
                    empDetails={empData}
                    stateChanger={setEmpData}
                    nextTab={handleTabChanges}
                    baseurl={config?.path}
                  />
                </TabPanel>
                <TabPanel value="position_info">
                  <EmployeePositionInfoComp
                    empDetails={empData}
                    stateChanger={setEmpData}
                    nextTab={handleTabChanges}
                    baseurl={config?.path}
                  />
                </TabPanel>
                <TabPanel value="education_info">
                  <EmployeeEducationInfoComp
                    empDetails={empData}
                    stateChanger={setEmpData}
                    nextTab={handleTabChanges}
                  />
                </TabPanel>
                <TabPanel value="bank_info">
                  <EmployeeBankInfoComp
                    empDetails={empData}
                    stateChanger={setEmpData}
                    nextTab={handleTabChanges}
                    submitEmployeeDetails={(e) => handleSubmit(e)}
                    baseurl={config?.path}
                    isBtnDisable={isBtnDisable}
                  />
                </TabPanel>
              </TabContext>
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateEmployees;
