import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import More from "../../Assets/Icons/More.svg";
import { useNavigate } from "react-router-dom";

export default function DataTable({ list, setSelectedRows }) {
  const navigate = useNavigate();

  const columns = [
    { field: "module_name", headerName: "Masters", minWidth: 70 },
    { field: "save_type", headerName: "Functionality", minWidth: 70 },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (row) => (
        <img
          src={More}
          alt=""
          onClick={() => navigateDetailsPage(row)}
          className="log-icon"
        />
      ),
    },
  ];
  const navigateDetailsPage = (row) => {
    navigate(`/approve/masters/log/${row?.id}`, {
      state: {
        list: row?.row,
      },
    });
  };
  const handleSelectionModelChange = (selectionModel) => {
    setSelectedRows(selectionModel);
  };
  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={list}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
        onRowSelectionModelChange={handleSelectionModelChange}
      />
    </div>
  );
}
