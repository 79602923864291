import React, { useState, useEffect } from "react";
import alertify from "alertifyjs";
import Select from "react-select";
import { GET_API } from "../../../Services/api";
import TerminatedEmployeeTable from "../../DataTable/TerminatedEmployeeTable";
import Spinner from "../../Spinner";
import Search from "../../../Assets/Icons/Search.svg";
import Filter from "../../../Assets/Icons/Filter.svg";
import "alertifyjs/build/css/alertify.css";

function AllEmployeeList() {
  const userId = localStorage?.getItem("user_id");
  const [employeeList, setEmployeeList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [employeeOption, setEmployeeOption] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [department, setDepartment] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(false);
  const [filterParams, setFilterParams] = useState("");
  const [showFilterResultData, setShowFilterResultData] = useState("");
  const [filterList, setfilterList] = useState({
    employee_id: "",
    client_id: "",
    category_id: "",
    branch_id: "",
    emp_type_id: "",
    gender: "",
    department_id: "",
    from_date: "",
  });

  const header = [
    { label: "S.no", value: "s.no" },
    { label: "Profile Picture", value: "profile_picture" },
    { label: "Employee Name", value: "name" },
    { label: "Employee Code", value: "employee_code" },
    { label: "Department Name", value: "departmentname" },
    { label: "Mobile Number", value: "mobile_number" },
    { label: "Created at", value: "create_at" },
  ];

  useEffect(() => {
    getEmployeeOptionList();
    getBranchList();
    getClientList();
    getDesignationList();
    getDepartmentList();
  }, []);

  useEffect(() => {
    getTerminatedEmployeeList(filterParams);
  }, [showFilterResultData]);

  const getTerminatedEmployeeList = async (filters) => {
    setLoading(true);
    try {
      const employeeList = await GET_API(`/getInactiveEmp?login=${userId}${filters}`);
      if (employeeList.status === 200) {
        setLoading(false);
        setEmployeeList(employeeList?.data?.data);
        setFilteredList(employeeList?.data?.data);
      }
    } catch (e) {
      alertify.error("Failed to get Employee List");
      setLoading(false);
      setEmployeeList([]);
      setFilteredList([]);
    }
  };

  const getEmployeeOptionList = async () => {
    setLoading(true);
    try {
      const employeeList = await GET_API(`/ListEmployeeTentacles?login=${userId}`);
      if (employeeList.status === 200) {
        setLoading(false);
        const empList = employeeList?.data?.data.map((item, i) => {
          return { value: item?.employee_id, label: `${item?.name} - ${item?.employee_code}` };
        });
        empList.unshift({ value: "", label: "Select..." });
        setEmployeeOption(empList);
      }
    } catch (e) {
      alertify.error("Failed to get Employee List");
      setEmployeeOption([])
    }
  };

  const getBranchList = async () => {
    setLoading(true);
    try {
      const branchList = await GET_API("/branchList");
      if (branchList.status === 200) {
        const branch = branchList?.data?.data.map((item, i) => {
          return { value: item?.id, label: item?.name };
        });
        branch.unshift({ value: "", label: "Select..." });
        setBranchList(branch);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Branch List");
    }
  };

  const getClientList = async () => {
    setLoading(true);
    try {
      const cliList = await GET_API("/clientLists");
      if (cliList.status === 200) {
        const client = cliList?.data?.data.map((item, i) => {
          return { value: item?.id, label: item?.name };
        });
        client.unshift({ value: "", label: "Select..." });
        setClientList(client);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Client List");
    }
  };

  const getDesignationList = async () => {
    setLoading(true);
    try {
      const designation = await GET_API("/CategoryList");
      if (designation.status === 200) {
        const category = designation?.data?.data.map((item, i) => {
          return { value: item?.id, label: item?.name };
        });
        category.unshift({ value: "", label: "Select..." });
        setDesignation(category);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      setDesignation([])
      alertify.error("Failed to Get Designation List");
    }
  };

  const getDepartmentList = async () => {
    setLoading(true);
    try {
      const departmentList = await GET_API("/DepartmentList");
      if (departmentList.status === 200) {
        const dep = departmentList?.data?.data.map((item, i) => {
          return { value: item?.id, label: item?.name };
        });
        dep.unshift({ value: "", label: "Select..." });
        setDepartment(dep);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Department List");
    }
  };

  const handleLoanSearch = (e) => {
    const searchTerm = e.target.value;
    const searchedList = employeeList.filter((item) => {
      for (let key in item) {
        if (
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
    setFilteredList(searchedList);
  };

  const genderList = [
    { label: "Select...", value: "" },
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
  ];

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const handleFilter = async (e, name, type) => {
    const value = type === "date" ? e?.target?.value : e?.value;
    filterList[name] = value;
    const list = { ...filterList };
    Object.keys(list).forEach((key) => {
      if (list[key] === "") {
        delete list[key];
      }
    });
    let concateURL = "";
    if (isEmptyObject(list)) {
      setFilterParams(concateURL);
      setfilterList({ ...filterList, [name]: value });
    }

    Object.keys(list).forEach((key) => {
      const newURL = `${key}=${list[key]}`;
      concateURL += "&" + newURL;
      setFilterParams(concateURL);
    });
  };

  const showFilterResult = (e) => {
    e?.preventDefault();
    setShowFilterResultData(filterParams);
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">Employee</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Terminated Employee List</h4>
          <div className="search-block">
            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                onChange={(e) => handleLoanSearch(e)}
                style={{ outline: "none", border: "none" }}
              />

              <img src={Search} alt="" />
            </div>
            <div className="flex gap-3">
              <div className="filter-button" onClick={() => setFilter((prev) => !prev)}>
                <span className="filter-btn-label">Filter</span>
                <img src={Filter} alt="" />
              </div>
            </div>
          </div>
          {filter && (
            <form className="border-t-[1px] border-t-[#D9D9D9]">
              <div className="form mt-4">
                <div className="field-block dropdown-select-to-one">
                  <label className="label-name">Client</label>
                  <Select
                    options={clientList}
                    value={clientList?.find(
                      (option) => option?.value === filterList.client_id
                    )}
                    onChange={(e) => handleFilter(e, "client_id", "select")}
                    name="client_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-one">
                  <label className="label-name">Designation</label>
                  <Select
                    options={designation}
                    value={designation?.find(
                      (option) => option?.value === filterList.category_id
                    )}
                    onChange={(e) => handleFilter(e, "category_id", "select")}
                    name="category_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-two">
                  <label className="label-name">Employee</label>
                  <Select
                    options={employeeOption}
                    value={employeeOption?.find(
                      (option) => option?.value === filterList.employee_id
                    )}
                    onChange={(e) => handleFilter(e, "employee_id", "select")}
                    name="employee_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-two">
                  <label className="label-name">Branch Name</label>
                  <Select
                    options={branchList}
                    value={branchList?.find(
                      (option) => option?.value === filterList.branch_id
                    )}
                    onChange={(e) => handleFilter(e, "branch_id", "select")}
                    name="branch_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-three">
                  <label className="label-name">
                    Gender
                  </label>
                  <Select
                    options={genderList}
                    value={genderList?.find(
                      (option) => option?.value === filterList.gender
                    )}
                    onChange={(e) => handleFilter(e, "gender", "select")}
                    name="gender"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-three">
                  <label className="label-name">
                    Department
                  </label>
                  <Select
                    options={department}
                    value={department?.find(
                      (option) => option?.value === filterList.department_id
                    )}
                    onChange={(e) => handleFilter(e, "department_id", "select")}
                    name="department_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block">
                  <label className="label-name">
                    Joining Date
                  </label>
                  <input
                    className="input-field "
                    type="date"
                    autoComplete="off"
                    name="from_date"
                    value={filterList?.from_date}
                    onChange={(e) => {
                      handleFilter(e, "from_date", "date");
                    }}
                  />
                </div>
                <div className="field-block flex justify-center">
                  <div className="add-new-button-block w-[40%] h-max ">
                    <button
                      className="add-new-button"
                      onClick={(e) => {
                        showFilterResult(e);
                      }}
                    >
                      <span style={{ cursor: "pointer" }}>Show Result</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}
          <TerminatedEmployeeTable
            list={filteredList}
            multiple={true}
            columns={header}
          />
        </div>
      </div>
    </div>
  );
}

export default AllEmployeeList;
