import React, { useState, useEffect, useRef } from "react";
import More from "../../../Assets/Icons/More.svg";
import { useNavigate } from "react-router-dom";
import Add from "../../../Assets/Icons/Add.svg";
import { read, utils } from "xlsx";
import ManualTable from "../../DataTable/ManualpfTable";
import Search from "../../../Assets/Icons/Search.svg";
import { GET_API, POST_API } from "../../../Services/api";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import Spinner from "../../Spinner";
import Filter from "../../../Assets/Icons/Filter.svg";

function ManualPFList() {
  const navigate = useNavigate();
  const inputFile = useRef(null);
  const [loading, setLoading] = useState(false);
  const [manualpfList, setManualPFList] = useState([
    {
      employee_name: "",
      pf_amount: "",
      pf_deduction: "",
    },
  ]);
  const [attendanceFineList, seAtttendanceFineList] = useState([]);
  const [filteredList, setfilteredList] = useState([]);
  const [filteredManualPF, setFilteredManualPF] = useState([]);
  const [employeetype, setEmployeetype] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [department, setDepartment] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [filter, setFilter] = useState(false);
  const [filterList, setfilterList] = useState({
    branch_id: "",
    emp_type_id: "",
    gender: "",
    department_id: "",
    designation_id: "",
    joining_from_date: "",
    joining_to_date: "",
  });

  const showManualPFDetails = (listDetail) => {
    navigate("/manualpf-details", {
      state: {
        list: listDetail,
      },
    });
  };

  const columns = [
    { id: "s_no", label: "S.No" },
    { id: "employee_name", label: "Employee Name" },
    { id: "pf_amount", label: "PF Amount" },
    { id: "pf_deductions", label: "PF Deductions" },
    { id: "created_by", label: "Action" },
    { id: "more_icon", label: "" },
  ];

  const getManualPFList = async () => {
    setLoading(true);
    try {
      const manualPf = await GET_API("/manualPfList");
      if (manualPf.status === 200) {
        setManualPFList(manualPf?.data?.data);
        setFilteredManualPF(manualPf?.data?.data);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to get PF List...");
    }
  };

  useEffect(() => {
    getManualPFList();
  }, []);

  const getBranchList = async () => {
    try {
      setLoading(true);
      const branchList = await GET_API("/branchList");
      if (branchList.status === 200) {
        setBranchList(branchList?.data?.data);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Branch List");
    }
  };

  const getEmployeeType = async () => {
    setLoading(true);
    try {
      const empType = await GET_API("/employeetype");
      if (empType.status === 200) {
        setEmployeetype(empType?.data?.data);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Employee Type List");
    }
  };

  const getDepartmentList = async () => {
    setLoading(true);
    try {
      const departmentList = await GET_API("/DepartmentList");
      if (departmentList.status === 200) {
        setDepartment(departmentList.data?.data);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Department List");
    }
  };

  const getDesignationList = async () => {
    setLoading(true);
    try {
      const designationList = await GET_API("/designationList");
      if (designationList.status === 200) {
        setDesignation(designationList?.data?.data);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Department List");
    }
  };

  const getAttendanceFineList = async () => {
    setLoading(true);
    GET_API("/employeeAttendanceFine")
      .then(async function (data) {
        seAtttendanceFineList(data?.data?.data);
        setfilteredList(data?.data?.data);
        setLoading(false);
      })

      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const genderList = [
    { key: "Male", value: "male" },
    { key: "Female", value: "female" },
  ];


  useEffect(() => {
    getAttendanceFineList();
    getBranchList();
    getEmployeeType();
    getDepartmentList();
    getDesignationList();
  }, []);

  const handleFilter = async (e) => {
    const { name, value } = e.target;
    filterList[name] = value;
    const list = { ...filterList };
    Object.keys(list).forEach((key) => {
      if (list[key] === "") {
        delete list[key];
      }
    });
    let concateURL = "/manualPfList?";
    Object.keys(list).forEach((key) => {
      const newURL = `${key}=${list[key]}`;
      if (concateURL === "") {
        concateURL += newURL;
      } else {
        concateURL += "&" + newURL;
      }
    });
    const filteredList = await GET_API(concateURL);

    if (filteredList.status === 200) {
      setfilteredList(filteredList?.data?.data);
    }
  };

  const onImportClick = () => {
    inputFile.current.click();
  };

  const fileUpload = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (e.target.files.length) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const wb = read(e.target.result);
        const sheets = wb.SheetNames;
        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]], {
            raw: false,
            dateNF: "dd-mm-yyyy hh:mm:ss",
          });
          POST_API("/importManualPf", { data: rows })
            .then(async function (data) {
              if (data?.data?.status == true) {
                alertify.success(data?.data?.message);
                getManualPFList();
              }
              if (data?.data?.status == false) {
                alertify.error(data?.data?.message);
              }
            })
            .catch((error) =>
              alertify.error("Some error Occured.Please try again")
            );
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };
  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      {loading}
      <div className="sm-container">
        <h1 className="heading-text">Payslips</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Manual PF Details</h4>
          <div className="search-block">
            <div
              className="flex gap-2 items-center cursor-pointer border-solid border-[2px] border-[#D0A34C] pt-[6px] pb-[6px] pl-[10px] pr-[10px] rounded bg-[#D0A34C]"
              onClick={onImportClick}
            >
              <span className="text-[#FFF] text-lg font-medium font-montserrat">
                Import
              </span>
              <img src={Add} alt="" />
            </div>
            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                style={{ outline: "none", border: "none" }}
              />

              <img src={Search} alt="" />
            </div>
            <div
              className="filter-button"
              onClick={() => setFilter((prev) => !prev)}
            >
              <span className="filter-btn-label">Filter</span>
              <img src={Filter} alt="" />
            </div>
          </div>
          {filter && (
            <form>
              <div className="form ">
                <div className="field-block">
                  <label className="label-name">
                    Branch Name
                    <span className="required-symbol">*</span>
                  </label>
                  <select
                    name="branch_id"
                    className="input-field"
                    onChange={(e) => {
                      handleFilter(e);
                    }}
                  >
                    <option value="">Select Branch</option>
                    {branchList?.map((branch, i) => {
                      return (
                        <option key={i} value={branch?.id}>
                          {branch?.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="field-block">
                  <label className="label-name">
                    Employee Type
                    <span className="required-symbol">*</span>
                  </label>
                  <select
                    name="emp_type_id"
                    className="input-field"
                    onChange={(e) => {
                      handleFilter(e);
                    }}
                  >
                    <option value="">Select Employee Type</option>
                    {employeetype?.map((employee, i) => {
                      return (
                        <option key={i} value={employee?.id}>
                          {employee?.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="field-block">
                  <label className="label-name">
                    Gender
                    <span className="required-symbol">*</span>
                  </label>
                  <select
                    name="gender"
                    className="input-field"
                    onChange={(e) => {
                      handleFilter(e);
                    }}
                  >
                    <option value="">Select Gender</option>
                    {genderList?.map((item, i) => {
                      return (
                        <option key={i} value={item?.value}>
                          {item?.key}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="field-block">
                  <label className="label-name">
                    Department
                    <span className="required-symbol">*</span>
                  </label>
                  <select
                    name="department_id"
                    className="input-field"
                    onChange={(e) => {
                      handleFilter(e);
                    }}
                  >
                    <option value="">Select Department</option>
                    {department.map((item, i) => {
                      return (
                        <option key={i} value={item?.id}>
                          {item?.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="field-block">
                  <label className="label-name">
                    Designation
                    <span className="required-symbol">*</span>
                  </label>
                  <select
                    name="designation_id"
                    className="input-field"
                    onChange={(e) => {
                      handleFilter(e);
                    }}
                  >
                    <option value="">Select Designation</option>
                    {designation?.map((item, i) => {
                      return (
                        <option key={i} value={item?.id}>
                          {item?.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="field-block">
                  <label className="label-name">
                    Joining Date
                    <span className="required-symbol">*</span>
                  </label>
                  <div className="flex gap-1 justify-center items-center">
                    <input
                      className="input-field "
                      type="date"
                      autoComplete="off"
                      name="joining_from_date"
                      onChange={(e) => {
                        handleFilter(e);
                      }}
                    />
                    <label className="label-name">to</label>
                    <input
                      className="input-field "
                      type="date"
                      autoComplete="off"
                      name="joining_to_date"
                      onChange={(e) => {
                        handleFilter(e);
                      }}
                    />
                  </div>
                </div>
              </div>
            </form>
          )}
          <ManualTable
            list={filteredManualPF}
            columns={columns}
            icon={More}
            activeDelete={false}
            activeEdit={false}
            multiple={true}
            showDetails={showManualPFDetails}
            pfList={manualpfList}
            setManualPFList={setManualPFList}
            getList={getManualPFList}
          />
        </div>
      </div>
    </div>
  );
}

export default ManualPFList;
