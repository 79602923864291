import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import Spinner from "../../Spinner";
import { DELETE_API, GET_API } from "../../../Services/api";
import ExpenseTable from "../../DataTable/ExpenseTable";
import More from "../../../Assets/Icons/More.svg";
import Search from "../../../Assets/Icons/Search.svg";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import "alertifyjs/build/css/alertify.css";

function ExpenseList() {
  const navigate = useNavigate();
  const userId = localStorage?.getItem("user_id");
  const [expenseList, setExpenseList] = useState([]);
  const [filteredExpense, setFilteredExpense] = useState([]);
  const [loading, setLoading] = useState(false);

  const showExpenseDetails = (listDetail) => {
    navigate("/expense/details", {
      state: {
        list: listDetail,
      },
    });
  };

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "employee_name", label: "Employee Name" },
    { id: "remarks", label: "Remarks" },
    { id: "total_paid_amount", label: "Total Amount Paid" },
    { id: "amount", label: "Amount" },
    { id: "created_at", label: "Created at" },
    { id: "more_icon", label: "" },
  ];

  useEffect(() => {
    getExpenseList();
  }, []);
  
  const getExpenseList = async () => {
    setLoading(true);
    try {
      const expenseList = await GET_API(`/ExpenseDetailsList?login=${userId}`);
      console.log(expenseList);
      if (expenseList.status === 200) {
        setExpenseList(expenseList?.data?.data);
        setFilteredExpense(expenseList?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alertify.error(error?.response?.data?.message);
    }
  };

  const handleExpenseSearch = (e) => {
    const searchTerm = e.target.value;
    const searchedList = expenseList.filter((item) => {
      for (let key in item) {
        if (
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
    setFilteredExpense(searchedList);
  };

  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Expense",
        async function () {
          if (row_id) {
            const deleteRow = await DELETE_API(`/deleteexpense/${row_id}`);
            if (deleteRow.status === 200) {
              alertify.success(deleteRow?.data?.message);
              getExpenseList();
              return;
            }
            alertify.error("Failed To Delete Floor");
          }
        },
        function () { }
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };
  
  const navigateEditPage = (listDetail) => {
    navigate("/expense-edit", {
      state: {
        list: listDetail,
      },
    });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">Expense</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Expense List</h4>
          <div className="search-block">
            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                onChange={(e) => handleExpenseSearch(e)}
                style={{ outline: "none", border: "none" }}
              />
              <img src={Search} alt="" />
            </div>
            <div className="flex gap-3">
              <div className="add-new-button-block">
                <Link to="/expense/add" className="add-new-button">
                  <span>Add New</span>
                  <img src={ColorAdd} alt="add" />
                </Link>
              </div>
            </div>
          </div>

          {/**************** * Table ********************/}

          <ExpenseTable
            list={filteredExpense}
            columns={columns}
            icon={More}
            multiple={true}
            editPage={navigateEditPage}
            activeEdit={false}
            activeDelete={false}
            showDetails={showExpenseDetails}
            deleteRow={handleDelete}
          />
        </div>
      </div>
    </div>
  );
}

export default ExpenseList;
