import React from "react";
import { Route } from "react-router-dom";

import CategoryList from "../ClientMaster/Category/CategoryList";
import AllowanceList from "../ClientMaster/Allowance/AllowanceList";
import Deductions from "../ClientMaster/Deductions/Deductions";
import CreateUpdateDeduction from "../ClientMaster/Deductions/CreateUpdateDeduction";
import DeductionDetails from "../ClientMaster/Deductions/DeductionDetails";
import ClientList from "../ClientMaster/Client/ClientList";
// import CreateClient from "../ClientMaster/Client/CreateClient";
// import UpdateClient from "../ClientMaster/Client/UpdateClient";
import CreateUpdateClient from "../ClientMaster/Client/CreateClient";
import ClientDetails from "../ClientMaster/Client/ClientDetails";
import ClientAllowanceDeduction from "../ClientMaster/Client/Client/ClientAllowanceDeduction";
import CreateClientAllowanceDeduction from "../ClientMaster/Client/Client/CreateClientAllowanceDeduction";

function ClientMasterRoute() {
  return (
    <>
      <Route path="client/designation" element={<CategoryList />}></Route>
      <Route path="client/allowance" element={<AllowanceList />}></Route>
      <Route path="client/deductions" element={<Deductions />}></Route>
      <Route path="client/deductions/create" element={<CreateUpdateDeduction />}></Route>
      <Route path="client/deductions/update/:deductionId" element={<CreateUpdateDeduction />}></Route>
      <Route path="client/deductions/details/:deductionId" element={<DeductionDetails />}></Route>
      <Route path="client/list" element={<ClientList />}></Route>
      <Route path="client/add" element={<CreateUpdateClient />}></Route>
      <Route path="client/edit/:id" element={<CreateUpdateClient />}></Route>
      <Route path="client/details/:id" element={<ClientDetails />}></Route>
      <Route path="client/allowance/deduction/list/:id" element={<ClientAllowanceDeduction />}></Route>
      <Route path="client/create/allowance/deduction/:clientId" element={<CreateClientAllowanceDeduction />}></Route>
      <Route path="client/:clientId/update/allowance/deduction/:cateName/:cateId" element={<CreateClientAllowanceDeduction />}></Route>
    </>
  );
}

export default ClientMasterRoute;
