import React, { useState, useEffect } from "react";
import More from "../../Assets/Icons/More.svg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Search from "../../Assets/Icons/Search.svg";
import { GET_API, DELETE_API } from "../../Services/api";
import HolidaySchemeTable from "../DataTable/HolidaySchemeTable";
import ColorAdd from "../../Assets/Icons/ColorAdd.svg";
import alertify from "alertifyjs";
import Spinner from "../Spinner";
import "alertifyjs/build/css/alertify.css";

function HolidayScheme() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [holidaySchemeList, setHolidaySchemeList] = useState([]);
  const [filteredHolidayScheme, setFilteredHolidayScheme] = useState([]);

  const navigateEditPage = (listDetail) => {
    navigate("/masters/holidayscheme/edit", {
      state: {
        list: listDetail,
      },
    });
  };

  const showBranchDetails = (listDetail) => {
    navigate("/masters/holidayscheme/details", {
      state: {
        list: listDetail,
      },
    });
  };

  const columns = [
    { id: "s.no", label: "S.No", minWidth: 170 },
    { id: "holiday_scheme_name", label: "Holiday Scheme Name", minWidth: 100 },
    { id: "description", label: "Description", minWidth: 100 },
    { id: "special_notes", label: "Special Notes", minWidth: 100 },
    { id: "created_at", label: "Created at", minWidth: 100 },
    { id: "more_icon", label: "", minWidth: 100 },
  ];

  useEffect(() => {
    getHolidaySchemeList();
  }, []);

  const getHolidaySchemeList = async () => {
    setLoading(true);
    try {
      const schmemeList = await GET_API("/HolidaySchemeList");
      if (schmemeList.status === 200) {
        setHolidaySchemeList(schmemeList?.data?.data);
        setFilteredHolidayScheme(schmemeList?.data?.data);
        setLoading(false);
      }
    } catch (e) {
      alertify.error(e?.response?.data?.message);
      setLoading(false);
    }
  };

  const handleHolidaySchemeSearch = (e) => {
    const searchTerm = e.target.value;
    const searchedList = holidaySchemeList.filter((item) => {
      for (let key in item) {
        if (
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
    setFilteredHolidayScheme(searchedList);
  };

  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete HolidayScheme",
        async function () {
          if (row_id) {
            try {
              const deleteRow = await DELETE_API(
                `/deleteHolidaysScheme/${row_id}`
              );
              if (deleteRow.status === 200) {
                alertify.success(deleteRow?.data?.message);
                getHolidaySchemeList();
                return;
              }
            } catch (error) {
              alertify.error("Failed To Delete HolidayScheme");
            }
          }
        },
        function () { }
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">Holiday Scheme</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Holiday Scheme List</h4>
          <div className="search-block">
            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                onChange={(e) => handleHolidaySchemeSearch(e)}
                style={{ outline: "none", border: "none" }}
              />
              <img src={Search} alt="" />
            </div>
            <div className="flex gap-3">
              <div className="add-new-button-block">
                <Link to="/masters/holidayscheme/add" className="add-new-button">
                  <span>Add New</span>
                  <img src={ColorAdd} alt="add" />
                </Link>
              </div>
            </div>
          </div>
          {/**************** * Table ********************/}
          <HolidaySchemeTable
            list={filteredHolidayScheme}
            columns={columns}
            icon={More}
            multiple={true}
            editPage={navigateEditPage}
            showDetails={showBranchDetails}
            deleteRow={handleDelete}
          />
        </div>
      </div>
    </div>
  );
}

export default HolidayScheme;
