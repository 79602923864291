import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import { DELETE_API, GET_API, POST_API } from "../../../Services/api";
import ClientCategoryMasterList from "../../DataTable/ClientCategoryMasterList";
import More from "../../../Assets/Icons/More.svg";
import Search from "../../../Assets/Icons/Search.svg";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import Validation from "../../../Validation/Validation";
import Edit from "../../../Assets/Icons/Edit.svg";
import Spinner from "../../Spinner";
import "alertifyjs/build/css/alertify.css";

function CategoryList() {
  const navigate = useNavigate();
  const [categoryceList, setCategoryList] = useState([])
  const [categorySearchList, setCategorySearchList] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [categoryValue, setCategoryValue] = useState({
    name: "", description:""
  });

  const [errors, setErrors] = useState("");
  const [editStatus, setEditStatus] = useState(false);

  const showCategoryDetails = (listDetail) => {
    // navigate("/branch-details", {
    //   state: {
    //     list: listDetail,
    //   },
    // });
  };

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "id", label: "ID" },
    { id: "name", label: "Name" },
    { id: "description", label: "Description" },
    { id: "created_at", label: "Created at" },
    { id: "more_icon", label: "Actions" },
  ];

  useEffect(() => {
    getCategory();
  }, []);

  const getCategory = async () => {
    try {
      setLoading(true);
      const response = await GET_API("/CategoryList")
      if (response?.status === 200) {
        setCategoryList(response?.data?.data);
        setCategorySearchList(response?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setCategoryList([]);
      setCategorySearchList([]);
    }
  };


  const handleCategorySearch = (e) => {
    const searchTerm = e.target.value;
    const searchedList = categorySearchList.filter((item) => {
      for (let key in item) {
        if (
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
    setCategoryList(searchedList);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCategoryValue({ ...categoryValue, [name]: value });
  };

  const addCatgory = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);

    const isValid = Validation.validateClientCategoryList(categoryValue);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      setErrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      setErrors({});
    }
    try {
      const createCategory = await POST_API("addCategory", categoryValue);
      if (createCategory.status === 200) {
        setButtonDisabled(false);
        setCategoryValue({ name: "", description:"" });
        getCategory();
        alertify.success(createCategory?.data?.message);
        return;
      }
    } catch (e) {
      setButtonDisabled(false);
      alertify.error(e?.response?.data?.message);
    }
  };

  const editCatgory = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.validateClientCategoryList(categoryValue);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      setErrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      setErrors({});
    }
    const editCategory = await POST_API(`/updateCategory/${categoryValue?.id}`, categoryValue);
    if (editCategory.status === 200) {
      setButtonDisabled(false);
      setCategoryValue({ name: "", description:"" });
      getCategory();
      setEditStatus(false);
      alertify.success(editCategory?.data?.message);
      return;
    }
    alertify.error(editCategory?.response?.data?.message);
    setButtonDisabled(false);
  };

  const resetForm = () => {
    setEditStatus(false);
    setCategoryValue({
      name: "", description:""
    });
    setErrors({})
  };

  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Category...?",
        async function () {
          if (row_id) {
            const deleteRow = await DELETE_API(`/deleteCategory/${row_id}`);
            if (deleteRow.status === 200) {
              alertify.success(deleteRow?.data?.message);
              getCategory();
              return;
            }
            alertify.error("Failed To Delete Category List");
          }
        },
        function () { }
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">Clients</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Add Designation</h4>
          <div className="search-block">
            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                onChange={(e) => handleCategorySearch(e)}
                style={{ outline: "none", border: "none" }}
              />
              <img src={Search} alt="" />
            </div>
          </div>
          <div className="add-list-container">
            <div className="input-block">
              <label className="add-new-input-label">
                Name <span className="required-symbol">*</span>
              </label>
              <input
                type="text"
                name="name"
                autoComplete="off"
                className="p-2 border-solid border-[1px] border-primary rounded font-karla"
                placeholder="Enter Category Name"
                onChange={(e) => {
                  handleChange(e);
                }}
                value={categoryValue?.name}
              />
              {errors.name ? (
                <div className="text-[#FF5C42] text-sm font-medium font-karla">
                  {errors.name}
                </div>
              ) : null}
            </div>
            <div className="input-block">
              <label className="add-new-input-label">
                Description
              </label>
              <textarea
                type="text"
                name="description"
                autoComplete="off"
                className="p-2 border-solid border-[1px] border-primary rounded font-karla"
                placeholder="Enter Description"
                onChange={(e) => {
                  handleChange(e);
                }}
                value={categoryValue?.description}
              />
              {errors.description ? (
                <div className="text-[#FF5C42] text-sm font-medium font-karla">
                  {errors.description}
                </div>
              ) : null}
            </div>

            <div className="flex gap-3">
              <div className="add-new-button-block">
                {editStatus ? (
                  <button
                    className="add-new-button"
                    disabled={buttonDisabled}
                    onClick={(e) => {
                      editCatgory(e);
                    }}
                  >
                    <span className="button-label">Save</span>
                    <img src={ColorAdd} alt="add" />
                  </button>
                ) : (
                  <button className="add-new-button" disabled={buttonDisabled}>
                    <span
                      className="button-label"
                      onClick={(e) => {
                        addCatgory(e);
                      }}
                    >
                      Add New
                    </span>
                    <img src={ColorAdd} alt="add" />
                  </button>
                )}
              </div>
              <button className="edit-button" onClick={() => resetForm()}>
                <span className="button-label">Reset</span>
                <img src={Edit} alt="add" />
              </button>
            </div>
          </div>
          <ClientCategoryMasterList
            list={categoryceList}
            columns={columns}
            showBranchDetails={showCategoryDetails}
            icon={More}
            multiple={false}
            editStatus={setEditStatus}
            editList={setCategoryValue}
            deleteRow={handleDelete}
          />
        </div>
      </div>
    </div>
  );
}

export default CategoryList;
