import React, { useEffect, useState } from "react";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { GET_API } from "../../Services/api";
import { Link } from "react-router-dom";
import Spinner from "../Spinner";

function Dashboard() {
  const userId = localStorage?.getItem("user_id");
  const [dashboardData, setDashboardData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    getDashboard()
  }, [])

  const getDashboard = async () => {
    try {
      setIsLoading(true);
      const responce = await GET_API(`/webDashboardTentacles?login=${userId}`);
      if (responce?.data?.status === 200) {
        setDashboardData(responce?.data?.data)
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      setDashboardData({});
    }
  }

  if(isLoading){
    return <Spinner/>
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className=" pl-[20px] pr-[20px] pb-[20px]">
        <div className="flex justify-between items-center ">
          <div>
            <span className="text-[#271E0D] text-2xl	font-semibold	font-montserrat">
              Dashboard
            </span>
          </div>
        </div>

        <div className="mx-2 mt-[32px] shadow-sm rounded bg-[#fff] py-4">
          {/* <h4 className="text-[#271E0D] text-xl font-semibold pt-[16px] pb-[16px] pl-[24px] pr-[24px] border-b-[1px] border-b-[#D9D9D9] font-montserrat">
            Dashboard Details
          </h4> */}

          <div className="grid grid-cols-4 gap-4 m-4">
            <Link to="/employee/list">
            <div className="flex items-center shadow-xl hover:shadow-none cursor-pointer rounded border-[1px] border-[#D9D9D9] h-20 border-b-[3px] border-b-[#C1314C]">
              <div className="p-2 ">
                <div className="flex">
                  <div>
                    <p className="text-lg m">Active Employees</p>
                  </div>
                  <div className="flex justify-items-end justify-end items-end">
                    <ArrowForwardIosIcon className="text-[#C1314C]" />
                  </div>
                </div>
                <div>
                  <p className="m-0 text-[#C1314C]"> {dashboardData?.verifiedEmployees?dashboardData?.verifiedEmployees:0}</p>
                </div>
              </div>
            </div>
            </Link>
            <Link to="/employee/verify/certificate">
            <div className="flex items-center shadow-xl hover:shadow-none cursor-pointer rounded border-[1px] border-[#D9D9D9] h-20 border-b-[3px] border-b-[#C1314C]">
              <div className="p-2 ">
                <div className="flex">
                  <div>
                    <p className="text-lg m">Verify Certificate</p>
                  </div>
                  <div className="flex justify-items-end justify-end items-end">
                    <ArrowForwardIosIcon className="text-[#C1314C]" />
                  </div>
                </div>
                <div>
                  <p className="m-0 text-[#C1314C]"> {dashboardData?.unverifiedEmployees? dashboardData?.unverifiedEmployees : 0}</p>
                </div>
              </div>
            </div>
            </Link>
            <Link to="/masters/client/list">
            <div className="flex items-center shadow-xl hover:shadow-none cursor-pointer rounded border-[1px] border-[#D9D9D9] h-20 border-b-[3px] border-b-[#C1314C]">
              <div className="p-2 ">
                <div className="flex">
                  <div>
                    <p className="text-lg m">Total Clients</p>
                  </div>
                  <div className="flex justify-items-end justify-end items-end">
                    <ArrowForwardIosIcon className="text-[#C1314C]" />
                  </div>
                </div>
                <div>
                  <p className="m-0 text-[#C1314C]"> {dashboardData?.clientTentacles?dashboardData?.clientTentacles:0}</p>
                </div>
              </div>
            </div>
            </Link>
            <Link to="/masters/branch">
            <div className="flex items-center shadow-xl hover:shadow-none cursor-pointer rounded border-[1px] border-[#D9D9D9] h-20 border-b-[3px] border-b-[#C1314C]">
              <div className="p-2 ">
                <div className="flex">
                  <div>
                    <p className="text-lg m">Total Branch</p>
                  </div>
                  <div className="flex justify-items-end justify-end items-end">
                    <ArrowForwardIosIcon className="text-[#C1314C]" />
                  </div>
                </div>
                <div>
                  <p className="m-0 text-[#C1314C]"> {dashboardData?.branchcount?dashboardData?.branchcount:0}</p>
                </div>
              </div>
            </div>
            </Link>
        
          </div>
        </div>
      </div>
    </div>
  );
}
export default Dashboard;
