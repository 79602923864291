import React, { useState } from "react";
import { Link } from "react-router-dom";
import View from "../../../Assets/Icons/View.svg";
import Save from "../../../Assets/Icons/Save.svg";
import { useLocation, useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import Validation from "../../../Validation/Validation";
import { POST_API } from "../../../Services/api";

function EditDepartment() {
  const navigate = useNavigate();

  const { state } = useLocation();
  const listData = state?.list;
  console.log(listData);
  const [errors, seterrors] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [editreligionDetails, setEditDepartmentDetails] = useState({
    id: listData?.id,
    name: listData?.name,
    slug: listData?.slug,
    description: listData?.description,
  });

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setEditDepartmentDetails(() => ({ ...editreligionDetails, [name]: value }));
  };

  const saveDepartment = async (e) => {
    setButtonDisabled(true);
    const isValid = Validation.validateDepartment(editreligionDetails);
    if (isValid && isValid.error) {
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    POST_API("/updateDepartment", editreligionDetails)
      .then(async function (data) {
        alertify.success(data?.data?.message);
        setButtonDisabled(false);
        navigate("/masters/department");
      })

      .catch((error) => {
        setButtonDisabled(false);
        alertify.error(error?.response?.data?.message);
      });
  };
  const resetForm = () => {
    setEditDepartmentDetails({
      id: listData?.id,
      name: listData?.name,
      slug: listData?.slug,
      description: listData?.description,
    });
  };

  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">Department</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Edit Department</h4>
          <div className="add-block">
            <Link to="/masters/department" className="view-list-button">
              <span>View List</span>
              <img src={View} alt="add" />
            </Link>
          </div>
          <form>
            <div className="form">
              <div className="field-block">
                <label className="label-name">
                  Department Name
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="name"
                  value={editreligionDetails?.name}
                  placeholder="Enter Department name"
                />
                {errors.name ? (
                  <div className="validation-error-label">{errors.name}</div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  Slug
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="slug"
                  value={editreligionDetails?.slug}
                  placeholder="Enter Slug Name"
                />
                {errors.slug ? (
                  <div className="validation-error-label">{errors.slug}</div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  Description
                </label>
                <textarea
                  className="input-field "
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="description"
                  value={editreligionDetails?.description}
                  placeholder="Enter the Description"
                />
                {errors.description ? (
                  <div className="validation-error-label">
                    {errors.description}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="submit-reset-button">
              <button
                type="button"
                className="reset-button"
                onClick={() => resetForm()}
              >
                <span className="reset-button-label">Cancel</span>
              </button>
              <button
                type="submit"
                className="submit-button"
                onClick={() => saveDepartment()}
                disabled={buttonDisabled}
              >
                <span className="submit-button-label">Save</span>
                <img src={Save} alt="" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditDepartment;
