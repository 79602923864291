import React, { useState, useEffect } from "react";
import More from "../../../Assets/Icons/More.svg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { DELETE_API, GET_API } from "../../../Services/api";
import FoodAllowanceListTable from "../../DataTable/FoodAllowanceListTable";
import Search from "../../../Assets/Icons/Search.svg";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import Spinner from "../../Spinner";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";

function FoodAllowance() {
  const navigate = useNavigate();
  const [foodAllowanceList, setfoodAllowanceList] = useState([]);
  const [filteredBranch, setFilteredBranch] = useState([]);
  const [loading, setLoading] = useState(false);

  const showBranchDetails = (listDetail) => {
    navigate("/branch-details", {
      state: {
        list: listDetail,
      },
    });
  };

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "name", label: "Name" },
    { id: "description", label: "Description" },
    { id: "from_time", label: "From Time" },
    { id: "to_time", label: "To Time" },
    { id: "more_icon", label: "" },
  ];

  useEffect(() => {
    getBranchList();
  }, []);

  const getBranchList = async () => {
    setLoading(true);
    try {
      const foodList = await GET_API("/FoodAllowance");
      if (foodList.status === 200) {
        setfoodAllowanceList(foodList?.data?.data);
        setFilteredBranch(foodList?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alertify.error("Failed to Get Branch List");
    }
  };

  const handleBranchSearch = (e) => {
    const searchTerm = e.target.value;
    const searchedList = foodAllowanceList.filter((item) => {
      for (let key in item) {
        if (
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
    setFilteredBranch(searchedList);
  };

  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Branch..?",
        async function () {
          try {
          } catch (error) {}
          if (row_id) {
            const deleteRow = await DELETE_API(`/deletebranch/${row_id}`);
            if (deleteRow.status === 200) {
              alertify.success(deleteRow?.data?.message);
              getBranchList();
              return;
            }
            alertify.error("Failed To Delete Floor");
          }
        },
        function () {}
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };
  const navigateEditPage = (listDetail) => {
    navigate("/branch-edit", {
      state: {
        list: listDetail,
      },
    });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="flex flex-col justify-between h-full">
        <div className="sm-container">
          <h1 className="heading-text">Allowance</h1>
          <div className="card-wrapper">
            <h4 className="card-header">Food Allowance</h4>
            <div className="search-block">
              <div className="add-new-button-block">
                <Link
                  // to="/branch-create"
                  className="add-new-button"
                >
                  <span>Add New</span>
                  <img src={ColorAdd} alt="add" />
                </Link>
              </div>
              <div className="search">
                <input
                  type="text"
                  className="search-input-box"
                  placeholder="Search..."
                  autoComplete="off"
                  onChange={(e) => handleBranchSearch(e)}
                  style={{ outline: "none", border: "none" }}
                />

                <img src={Search} alt="" />
              </div>
            </div>

            {/**************** * Table ********************/}

            <FoodAllowanceListTable
              list={filteredBranch}
              columns={columns}
              icon={More}
              multiple={true}
              editPage={navigateEditPage}
              showDetails={showBranchDetails}
              deleteRow={handleDelete}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FoodAllowance;
