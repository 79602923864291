import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import View from "../../../Assets/Icons/View.svg";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import Validation from "../../../Validation/Validation";
import { POST_API } from "../../../Services/api";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";

function CreateBank() {
  const navigate = useNavigate();
  const [bankDetails, setbankDetails] = useState({
    branch_name: "",
    bank_name: "",
    ifsc_code: "",
  });
  const [errors, seterrors] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setbankDetails(() => ({ ...bankDetails, [name]: value }));
  };

  const create_Bank = async (e) => {
    console.log(bankDetails);
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.ValidateBank(bankDetails);
    if (isValid && isValid.error) {
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      setButtonDisabled(false);
      return;
    } else {
      console.log("error");
      console.log("error");

      seterrors({});
    }
    POST_API("/addBank", bankDetails)
      .then(async function (data) {
        setButtonDisabled(false);
        if (data.data.status === 403) {
          alertify.error(data?.data?.message);
        }
        alertify.success(data?.data?.message);
        navigate("/masters/bank");
      })

      .catch((error) => {
        setButtonDisabled(false);
        alertify.error(error?.response?.data?.message);
      });
  };

  const resetForm = () => {
    setbankDetails({
      branch_name: "",
      bank_name: "",
      ifsc_code: "",
    });
    seterrors({});
  };

  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">Bank</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Add Bank</h4>
          <div className="add-block">
            <Link to="/masters/bank" className="view-list-button">
              <span>View List</span>
              <img src={View} alt="add" />
            </Link>
          </div>

          <form onSubmit={(e) => create_Bank(e)}>
            <div className="form">
              <div className="field-block">
                <label className="label-name">
                  Bank Name
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="bank_name"
                  placeholder="Enter Bank Name"
                  value={bankDetails.bank_name}
                />
                {errors.bank_name ? (
                  <div className="validation-error-label">
                    {errors.bank_name}
                  </div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  Branch Name
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="branch_name"
                  placeholder="Enter Branch Name"
                  value={bankDetails.branch_name}
                />
                {errors.branch_name ? (
                  <div className="validation-error-label">
                    {errors.branch_name}
                  </div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  IFSC Code
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="ifsc_code"
                  placeholder="Please Enter Your IFSC Code"
                  value={bankDetails.ifsc_code}
                />
                {errors.ifsc_code ? (
                  <div className="validation-error-label">
                    {errors.ifsc_code}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="submit-reset-button">
              <button
                type="button"
                className="reset-button"
                onClick={resetForm}
              >
                <span className="reset-button-label">Reset</span>
              </button>
              <button
                disabled={buttonDisabled}
                type="submit"
                className="submit-button"
              >
                <span className="submit-button-label">Add</span>
                <img src={ColorAdd} alt="" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateBank;
