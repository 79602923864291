import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./Components/Login";

import { HomeSideBar } from "./Components/HomeLayout";
import AllRoutes from "./Components/HomeLayout";

import HomeRoutes from "./Components/Routes/HomeRoutes";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/" element={<Navigate to="/login" replace={true} />} />
          <Route path="/" element={<AllRoutes />}>
            <Route element={<HomeSideBar />}>{HomeRoutes()}</Route>
            <Route path="/hrms"></Route>
            <Route path="/ims"></Route>
            <Route path="/fms"></Route>
            <Route path="/crm"></Route>
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
