import React from "react";
import { Route } from "react-router-dom";

// import MastersApproval from "../Approval/Approval";
import MastersApproval from "../Approval/Masters/Approval";
import BonusApproval from "../Approval/BonusApproval/BonusApproval";
import ExpenseApproval from "../Approval/ExpenseApproval/ExpenseApproval";
import FinanceApproval from "../Approval/FinanceApproval/FinanceApproval";
import FinanceApprovalView from "../Approval/FinanceApproval/ViewApproval";
import LeaveApproval from "../Approval/LeaveApproval/LeaveApproval";
import ViewApproval from "../Approval/LeaveApproval/ViewApproval";
import ProfileApprovalList from "../Approval/ProfileApproval/ProfileApprovalList";
import ViewEmployeeProfile from "../Approval/ProfileApproval/ViewEmployeeProfile";

function ApproveRoutes() {
  return (
    <>
      <Route path="masters" element={<MastersApproval />}></Route>
      <Route path="bonus" element={<BonusApproval />}></Route>
      <Route path="expense" element={<ExpenseApproval />}></Route>
      <Route path="finance" element={<FinanceApproval />}></Route>
      <Route path="finance/:id" element={<FinanceApprovalView />}></Route>
      <Route path="leave" element={<LeaveApproval />}></Route>
      <Route path="leave/:empId/:id" element={<ViewApproval />}></Route>
      <Route path="profile" element={<ProfileApprovalList />}></Route>
      <Route path="profile/:empId" element={<ViewEmployeeProfile />}></Route>
    </>
  );
}

export default ApproveRoutes;
