import React, { useState, useEffect } from "react";
import More from "../../../Assets/Icons/More.svg";
import { useNavigate } from "react-router-dom";
import { DELETE_API, GET_API, POST_API } from "../../../Services/api";
import ClientAllowanceListTable from "../../DataTable/ClientAllowanceListTable";
import Search from "../../../Assets/Icons/Search.svg";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import Validation from "../../../Validation/Validation";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import Edit from "../../../Assets/Icons/Edit.svg";
import Spinner from "../../Spinner";
import { Checkbox } from "@mui/material";

function ClientAllowance() {
  const navigate = useNavigate();
  const [allowanceList, setAllowanceList] = useState([]);
  const [allowanceSearchList, setAllowanceSearchList] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allowancValue, setAllowanceValue] = useState({
    name: "",
    remove_in_deduction: "",
  });

  const [errors, setErrors] = useState("");
  const [editStatus, setEditStatus] = useState(false);

  const showBranchDetails = (listDetail) => {
    navigate("/branch-details", {
      state: {
        list: listDetail,
      },
    });
  };

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "name", label: "Name" },
    { id: "created_at", label: "Created at" },
    { id: "more_icon", label: "Actions" },
  ];

  useEffect(() => {
    getAllowance();
  }, []);

  const getAllowance = async () => {
    try {
      setLoading(true);
      const response = await GET_API("/clientAllowanceList")
      if (response?.status === 200) {
        setAllowanceList(response?.data?.data);
        setAllowanceSearchList(response?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setAllowanceList([]);
      setAllowanceSearchList([]);
    }
  };


  const handleAllowanceSearch = (e) => {
    const searchTerm = e.target.value;
    const searchedList = allowanceSearchList.filter((item) => {
      for (let key in item) {
        if (
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
    setAllowanceList(searchedList);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAllowanceValue({ ...allowancValue, [name]: value });
  };

  const handleCheckbox = (e, name) => {
    const { checked } = e?.target;
    setAllowanceValue({ ...allowancValue, [name]: checked ? 1: 0 });
}

  const addAllowance = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);

    const isValid = Validation.validateClientAllowanceList(allowancValue);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      setErrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      setErrors({});
    }
    try {
      const createAllowance = await POST_API("/addclientAllowanceList", allowancValue);
      if (createAllowance.status === 200) {
        setButtonDisabled(false);
        setAllowanceValue({ name: "" });
        getAllowance();
        alertify.success(createAllowance?.data?.message);
        return;
      }
    } catch (e) {
      setButtonDisabled(false);
      alertify.error(e?.response?.data?.message);
    }
  };

  const editAllowance = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.validateClientAllowanceList(allowancValue);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      setErrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      setErrors({});
    }
    const editAllowance = await POST_API(`/updateClientAllowance/${allowancValue?.id}`, allowancValue);
    if (editAllowance.status === 200) {
      setButtonDisabled(false);
      setAllowanceValue({ name: "" });
      getAllowance();
      setEditStatus(false);
      alertify.success(editAllowance?.data?.message);
      return;
    }
    alertify.error(editAllowance?.response?.data?.message);
    setButtonDisabled(false);
  };

  const resetForm = () => {
    setEditStatus(false);
    setAllowanceValue({
      name: ""
    });
    setErrors({})
  };

  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Allowance...?",
        async function () {
          if (row_id) {
            const deleteRow = await DELETE_API(`/deleteClientAllowance/${row_id}`);
            if (deleteRow.status === 200) {
              alertify.success(deleteRow?.data?.message);
              getAllowance();
              return;
            }
            alertify.error("Failed To Delete Allowance List");
          }
        },
        function () { }
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">Clients</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Add Allowance</h4>
          <div className="search-block">
            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                onChange={(e) => handleAllowanceSearch(e)}
                style={{ outline: "none", border: "none" }}
              />
              <img src={Search} alt="" />
            </div>
          </div>
          <div className="add-list-container">
            <div className="input-block">
              <label className="add-new-input-label">
                Name <span className="required-symbol">*</span>
              </label>
              <input
                type="text"
                name="name"
                autoComplete="off"
                className="p-2 border-solid border-[1px] border-primary rounded font-karla"
                placeholder="Enter Allowance Name"
                onChange={(e) => {
                  handleChange(e);
                }}
                value={allowancValue?.name}
              />
              {errors.name ? (
                <div className="text-[#FF5C42] text-sm font-medium font-karla">
                  {errors.name}
                </div>
              ) : null}
            </div>
            <div className="input-block">
              <label htmlFor={`remove_in_deduction`}>Remove in ESI Calculation</label>
              <Checkbox
                style={{ color: "rgb(193,49,76)", padding: 0 }}
                className="p-0 bg-primary-color"
                onChange={e => handleCheckbox(e, "remove_in_deduction")}
                inputProps={{ "aria-label": "controlled" }}
                checked={(allowancValue?.remove_in_deduction === 1 || allowancValue?.remove_in_deduction === "1") ? true : false}
              />
            </div>

            <div className="flex gap-3">
              <div className="add-new-button-block">
                {editStatus ? (
                  <button
                    className="add-new-button"
                    disabled={buttonDisabled}
                    onClick={(e) => {
                      editAllowance(e);
                    }}
                  >
                    <span className="button-label">Save</span>
                    <img src={ColorAdd} alt="add" />
                  </button>
                ) : (
                  <button className="add-new-button" disabled={buttonDisabled}>
                    <span
                      className="button-label"
                      onClick={(e) => {
                        addAllowance(e);
                      }}
                    >
                      Add New
                    </span>
                    <img src={ColorAdd} alt="add" />
                  </button>
                )}
              </div>
              <button className="edit-button" onClick={() => resetForm()}>
                <span className="button-label">Reset</span>
                <img src={Edit} alt="add" />
              </button>
            </div>
          </div>
          <ClientAllowanceListTable
            list={allowanceList}
            columns={columns}
            showBranchDetails={showBranchDetails}
            icon={More}
            multiple={false}
            editStatus={setEditStatus}
            editList={setAllowanceValue}
            deleteRow={handleDelete}
          />
        </div>
      </div>
    </div>
  );
}

export default ClientAllowance;
