import Joi from "joi";

class ValidationClass {
  /**Login validation */
  validateLoginForm(data) {
    const loginSchema = Joi.object({
      email: Joi.string()
        .required("Please enter branch name")
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "Username" `,
          "any.required": `"Username" is required`,
          "string.pattern.base": `Please enter valid "Username"`,
        }),
      password: Joi.string()
        .required("Please enter Password")
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "Password" `,
          "any.required": `"Password" is required`,
          "string.pattern.base": `Please enter valid "Password"`,
        }),
    }).unknown(true);
    return loginSchema.validate(data);
  }

  validateBranch(data) {
    const branchSchema = Joi.object({
      name: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required("Please enter branch name")
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "Branch name" `,
          "any.required": `"Branch name" is required`,
          "string.pattern.base": `Please enter valid "Branch" name`,
        }),
      no_of_floors: Joi.number().required().messages({
        "number.empty": `Please Select "Floor" `,
        "any.required": `Please Select "Floor" `,
        "number.pattern.base": `Please select valid "Floor"`,
      }),
      gst_no: Joi.string()
        .pattern(/[a-zA-Z0-9]{16}$/)
        .max(16)
        .required()
        .messages({
          "string.empty": `Please Enter "GSTIN" `,
          "any.required": `Please Enter "GSTIN" `,
          "string.pattern.base": `'GSTIN must be an alphanumeric string of exactly 16 characters`,
          "string.max": `'GSTIN must be 16 characters only`,
        }),
      address: Joi.string().required().max(150).messages({
        "string.empty": `Please Enter "Address" `,
        "any.required": `Please Enter "Address" `,
        "string.pattern.base": `Please Enter valid "Address"`,
        "string.max": `Max Length is 150 character`,
      }),
      town: Joi.string()
        .pattern(/^\S.*$/)
        .regex(new RegExp("^[A-Za-z]+$"))
        .required()
        .messages({
          "string.empty": `Please Enter "Town / City" `,
          "any.required": `Please Enter "Town / City" `,
          "string.pattern.base": `Please Enter valid "Town / City"`,
        }),
      state: Joi.string()
        .pattern(/^\S.*$/)
        .regex(new RegExp("^[A-Za-z]+$"))
        .required()
        .messages({
          "string.empty": `Please Enter "State" `,
          "any.required": `Please Enter "State" `,
          "string.pattern.base": `Please Enter valid "State"`,
        }),
      pincode: Joi.string()
        .pattern(/^\S.*$/)
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .min(6)
        .max(6)
        .pattern(/^[0-9]+$/)
        .required()
        .messages({
          "string.empty": `Please Enter "Pincode" `,
          "any.required": `Please Enter "Pincode" `,
          "string.pattern.base": `Please Enter valid "Pincode"`,
          "string.min": "Pincode must be 6 Digit",
          "string.max": "Pincode must be 6 Digit",
        }),
      contact_person: Joi.string()
        .pattern(/^\S.*$/)
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .messages({
          "string.empty": `Please Enter "Contact Person name" `,
          "any.required": `Please Enter "Contact Person name" `,
          "string.pattern.base": `Enter valid "Contact Person Name"`,
        }),
      phone: Joi.string()
        .pattern(/^\S.*$/)
        .min(10)
        .max(10)
        .pattern(/^[0-9]+$/)
        .required()
        .messages({
          "string.empty": `Please Enter "Contact Number" `,
          "any.required": `Please Enter "Contact Number" `,
          "string.pattern.base": `Please Enter valid "Contact Number"`,
          "string.min": "Phone number must be 10 Digit",
          "string.max": "Phone number must be 10 Digit",
        }),
      contact_email: Joi.string()
        .required()
        .email({
          minDomainSegments: 2,
          tlds: { allow: false },
        })
        .messages({
          "string.empty": `Please Enter "Contact Email" `,
          "any.required": `Please Enter "Contact Email" `,
          "string.pattern.base": `Please Enter valid "Contact Email"`,
          "string.email": "Invalid Email format.",
        }),
    }).unknown(true);
    return branchSchema.validate(data);
  }

  /**Agent Validation */
  validateAgent(data) {
    console.log(data);
    const agentSchema = Joi.object({
      company_name: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "Company Name" `,
          "any.required": `"Company Name" is required`,
          "string.pattern.base": `Please enter valid "Company" Name`,
        }),
      contact_person: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "Contact Person Name" `,
          "any.required": `"Contact Person Name" is required`,
          "string.pattern.base": `Please enter valid "Contact Person Name`,
        }),
      phone: Joi.string()
        .pattern(/^\S.*$/)
        .min(10)
        .max(10)
        .pattern(/^[0-9]+$/)
        .required()
        .messages({
          "string.empty": `Please Enter "Contact Number" `,
          "any.required": `Please Enter "Contact Number" `,
          "string.pattern.base": `Please Enter valid "Contact Number"`,
          "string.min": "Phone number must be 10 Digit",
          "string.max": "Phone number must be 10 Digit",
        }),
      contact_email: Joi.string()
        .pattern(/^\S.*$/)
        .required()
        .email({
          minDomainSegments: 2,
          tlds: { allow: ["com", "net"] },
        })
        .messages({
          "string.empty": `Please Enter "Email" `,
          "any.required": `Please Enter "Email" `,
          "string.pattern.base": `Please Enter valid "Email"`,
          "string.email": "Invalid Email format.",
        }),
      address: Joi.string()
        .pattern(/^\S.*$/)
        .required()
        .max(150)
        .messages({
          "string.empty": `Please Enter "Address" `,
          "any.required": `Please Enter "Address" `,
          "string.pattern.base": `Please Enter valid "Address"`,
          "string.max": `Max Length is 150 character`,
        }),
    }).unknown(true);
    return agentSchema.validate(data);
  }

  /***Validate Leave List */
  validateLeaveList(data) {
    const leaveListSchema = Joi.object({
      name: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "Leave Name" `,
          "any.required": `"Leave Name" is required`,
          "string.pattern.base": `Please enter valid "Leave Name"`,
        }),
      religion_id: Joi.number().integer().required().messages({
        "string.empty": `Please select "Religion" `,
        "any.required": `"Religion" is required`,
        "number.base": "Please select Religion",
      }),
    }).unknown(true);
    return leaveListSchema.validate(data);
  }

  validateClientAllowanceList(data) {
    const clientAllowanceList = Joi.object({
      name: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "Allowance Name" `,
          "any.required": `"Allowance Name" is required`,
          "string.pattern.base": `Please enter valid "Allowance Name"`,
        }),
    }).unknown(true);
    return clientAllowanceList.validate(data);
  }

  validateClientDeductionList(data) {
    const clientDeductionList = Joi.object({
      name: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "Deduction Name" `,
          "any.required": `"Deduction Name" is required`,
          "string.pattern.base": `Please enter valid "Deduction Name"`,
        }),
    }).unknown(true);
    return clientDeductionList.validate(data);
  }

  validateClientCategoryList(data) {
    const clientCategoryList = Joi.object({
      name: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "Category Name" `,
          "any.required": `"Category Name" is required`,
          "string.pattern.base": `Please enter valid "Category Name"`,
        }),

    }).unknown(true);
    return clientCategoryList.validate(data);
  }

  /***Validate Leave List */
  validateFine(data) {
    const leaveListSchema = Joi.object({
      employee_id: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "Employee Name" `,
          "any.required": `"Employee Name" is required`,
          "string.pattern.base": `Please enter valid "Employee Name"`,
        }),
      fine_type_id: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "Finance Category" `,
          "any.required": `"Finance Category" is required`,
          "string.pattern.base": `Please select valid "Finance Category"`,
        }),
      remarks: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "Remarks" `,
          "any.required": `"Remarks" is required`,
          "string.pattern.base": `Please enter valid "Remarks"`,
        }),
      fine_amount: Joi.string()
        .pattern(/^\d+(\.\d{1,2})?$/)
        .required()
        .messages({
          "string.empty": `Please select "Fine Amount" `,
          "any.required": `"Fine Amount" is required`,
          "number.base": "Please select Fine Amount",
        }),
    }).unknown(true);
    return leaveListSchema.validate(data);
  }

  /**Leave validate */
  validateLeave(data) {
    console.log(data);
    const leaveSchema = Joi.object({
      type: Joi.string().required().messages({
        "string.empty": `Please Select "Type" `,
        "any.required": `Please  Select "Type"  `,
        "string.pattern.base": `Please Select "Type" `,
      }),
      employee_id: Joi.string()
        .pattern(/^\S.*$/)
        .required()
        .messages({
          "string.empty": `Please Select "Employee Code" `,
          "any.required": `Please  Select "Employee Code"  `,
          "string.pattern.base": `Please Select "Employee Code" `,
        }),
      employee_name: Joi.string()
        .pattern(/^\S.*$/)
        .required()
        .messages({
          "string.empty": `Please Select "Employee Name" `,
          "any.required": `Please  Select "Employee Name"  `,
          "string.pattern.base": `Please Select "Employee Name" `,
        }),
      leave_type_id: Joi.string()
        .pattern(/^\S.*$/)
        .pattern(/^[0-9]+$/)
        .required()
        .messages({
          "string.empty": `Please Select "Leave Type" `,
          "any.required": `Please  Select "Leave Type"  `,
          "string.pattern.base": `Please Select "Leave Type" `,
        }),
      from_date: Joi.date().required().messages({
        "any.required": `Please  Select "From Date" `,
        "string.pattern.base": `Please Select "valid Leave Date" `,
      }),
      to_date: Joi.date()
        .iso()
        .min(Joi.ref("from_date"))
        .messages({
          "any.required": `Please  Select "To Date" `,
          "string.pattern.base": `Please Select "valid Leave Date" `,
        })
        .required(),
      no_of_days: Joi.number().integer().min(1).required().messages({
        "string.empty": `Please Select "valid Leave Date"`,
        "any.required": `Please Select "valid Leave Date"`,
        "string.pattern.base": `Please Select "valid Leave Date" `,
      }),

      approved_by: Joi.string()
        .pattern(/^\S.*$/)
        .required()
        .messages({
          "string.empty": `Please Select "Approved by" `,
          "any.required": `Please  Select "Approved by"  `,
          "string.pattern.base": `Please Select "Approved by" `,
        }),
      remarks: Joi.string().required().messages({
        "string.empty": `Please select "Remarks" `,
        "any.required": `"Remarks" is required`,
        "number.base": "Please select Remarks",
      }),
    }).unknown(true);
    return leaveSchema.validate(data);
  }

  /**Allowance Type Validation */
  ValidateAllowanceType(data) {
    const userSchema = Joi.object({
      name: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "Allowance Type Name" `,
          "any.required": `"Allowance Type Name" is required`,
          "string.pattern.base": `Please enter valid "Allowance Type Name`,
        }),
    }).unknown(true);
    return userSchema.validate(data);
  }

  /*Government ID Proofs Validation */
  ValidateGovernmentIdProof(data) {
    const userSchema = Joi.object({
      name: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "GovernmentId Proof Name" `,
          "any.required": `"Role Name" is required`,
          "string.pattern.base": `Please enter valid "Allowance Name`,
        }),
      mandatory: Joi.string()
        .required()
        .messages({
          "string.empty": `${data?.name} is Mandatoy or Not`,
          "any.required": `${data?.name} is Mandatoy or Not`,
        }),
    }).unknown(true);
    return userSchema.validate(data);
  }

  /**user Validation */
  ValidateRole(data) {
    const userSchema = Joi.object({
      name: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "Role Name" `,
          "any.required": `"Role Name" is required`,
          "string.pattern.base": `Please enter valid "Role Name`,
        }),

      description: Joi.string().allow(null, '').min(0).max(150).messages({
        "string.max": `Max Length is 150 character`,
      }),
    }).unknown(true);
    return userSchema.validate(data);
  }

  // Validate Bank page in Bank masters

  /**user Validation */
  ValidateBank(data) {
    const userSchema = Joi.object({
      bank_name: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "Bank Name" `,
          "any.required": `"Bank Name" is required`,
        }),

      branch_name: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "Branch Name" `,
          "any.required": `"Branch Name" is required`,
        }),

      ifsc_code: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "IFSC Code" `,
          "any.required": `"IFSC Code" is required`,
        }),
    }).unknown(true);
    return userSchema.validate(data);
  }

  // Validate Department page in Department masters

  validateDepartment(data) {
    const departmentSchema = Joi.object({
      name: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "Name" `,
          "any.required": `"Name" is required`,
          "string.pattern.base": "Please enter valid letters",
        }),
      description: Joi.string().min(0).max(150).messages({
        "string.max": `Max Length is 150 character`,
      }),
      slug: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "Slug Name" `,
          "any.required": `"Slug Name" is required`,
          "string.pattern.base": "Please enter valid letters",
        }),
    }).unknown(true);
    return departmentSchema.validate(data);
  }

  validateDesingnation(data) {
    console.log(data);
    const departmentSchema = Joi.object({
      department_id: Joi.number().integer().required().messages({
        "string.empty": `Please select "Department" `,
        "any.required": `"Department" is required`,
        "string.pattern.base": "Please enter valid letters",
      }),
      name: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "Name" `,
          "any.required": `"Name" is required`,
          "string.pattern.base": "Please enter valid letters",
        }),
      description: Joi.string().min(0).max(150).messages({
        "string.max": `Max Length is 150 character`,
      }),
    }).unknown(true);
    return departmentSchema.validate(data);
  }

  // Validate Department page in Department masters
  validateCaste(data) {
    const casteSchema = Joi.object({
      name: Joi.string().required(),
    })
      .messages({
        "string.empty": "Please Enter the Caste Name",
        "string.pattern.base": "Please enter valid letters",
      })
      .unknown(true);
    return casteSchema.validate(data);
  }

  /**Biometrics Validation */
  ValidateBiometrics(data) {
    const userSchema = Joi.object({
      name: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "Biometrics Name" `,
          "any.required": `"Role Name" is required`,
          "string.pattern.base": `Please enter valid "Role Name`,
        }),

      device_id: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "Device Id" `,
          "any.required": `"Device Id" is required`,
        }),
    }).unknown(true);
    return userSchema.validate(data);
  }

  // Validate Religion page in Bank masters

  /**validate employment type**/
  validateReligion(data) {
    const ReligionSchema = Joi.object({
      name: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "Religion Name" `,
          "any.required": `"Religion Name" is required`,
          "string.pattern.base": `Please enter valid "Religion Name" `,
        }),
    }).unknown(true);
    return ReligionSchema.validate(data);
  }

  validateFinance(data) {
    const paymentSchema = Joi.object({
      name: Joi.string().required(),
      description: Joi.string().min(0).max(150).messages({
        "string.max": `Max Length is 150 character`,
      }),
    })
      .messages({
        "string.empty": "Please Enter the Name",
        "string.pattern.base": "Please enter valid letters",
      })
      .unknown(true);
    return paymentSchema.validate(data);
  }

  /**validate attendance */
  // validateManualAttendance(data) {
  //   const attendanceSchema = Joi.object({
  //     employee_id: Joi.string()
  //       .pattern(/^\S.*$/)
  //       .required()
  //       .messages({
  //         "string.empty": `Please Select "Employee Id" `,
  //         "any.required": `Please  Select "Employee Id"  `,
  //         "string.pattern.base": `Please Select "Employee Id" `,
  //       }),
  //     date: Joi.date().required(),
  //     clock_in: Joi.string()
  //       .regex(/^([01]\d|2[0-3]):([0-5]\d)$/)
  //       .required(),
  //     clock_out: Joi.string()
  //       .regex(/^([01]\d|2[0-3]):([0-5]\d)$/)
  //       .required(),
  //     status: Joi.string()
  //       .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
  //       .required()
  //       .pattern(/^\S.*$/)
  //       .messages({
  //         "string.empty": `Please select "Status" `,
  //         "any.required": `"Status" is required`,
  //         "string.pattern.base": "Please enter valid letters",
  //       }),
  //   })
  //     .unknown(true)
  //     .custom((data, helpers) => {
  //       console.log("data", data);
  //       const clockInTime = data.clock_in;
  //       const clockOutTime = data.clock_out;
  //       if (clockInTime <= clockOutTime) {
  //         return helpers.error("any.invalid");
  //       }
  //       return data;
  //     }, "clock_in_greater_than_clock_out");
  //   return attendanceSchema.validate(data);
  // }
  validateManualAttendance(data) {
    const attendanceSchema = Joi.object({
      employee_id: Joi.string()
        .pattern(/^\S.*$/)
        .required()
        .messages({
          "string.empty": `Please Select Employee Id `,
          "any.required": `Please  Select "Employee Id"  `,
          "string.pattern.base": `Please Select "Employee Id" `,
        }),
      date: Joi.date().required(),
      clock_in: Joi.string()
        .regex(/^([01]\d|2[0-3]):([0-5]\d)$/)
        .required()
        .messages({
          "string.empty": `Please enter "Clock In" time`,
          "any.required": `"Clock In" time is required`,
          "string.pattern.base": "Please enter valid 'Clock In' time",
        }),
      clock_out: Joi.string()
        .regex(/^([01]\d|2[0-3]):([0-5]\d)$/)
        .required()
        .messages({
          "string.empty": `Please enter "Clock Out" time`,
          "any.required": `"Clock Out" time is required`,
          "string.pattern.base": "Please enter valid 'Clock Out' time",
        }),
    })
      .custom((data, helpers) => {
        const clockInTime = data.clock_in;
        const clockOutTime = data.clock_out;
        if (clockInTime > clockOutTime) {
          return helpers.error("any.invalid", { key: "clock_out" });
        }
        return data;
      }, "any.invalid")
      .messages({
        "any.invalid": `"Out time" must be greater than "In time"`,
      })
      .unknown(true);
    return attendanceSchema.validate(data);
  }

  /**validate holidays schema */
  validateHolidaysScheme(data) {
    const holidaysScheme = Joi.object({
      name: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "Scheme Name" `,
          "any.required": `"Scheme Name" is required`,
          "string.pattern.base": `Please enter valid "Scheme Name`,
        }),
      description: Joi.string().allow(null, '').min(0).max(150).messages({
        "string.max": `Max Length is 150 character`,
      }),
      special_notes: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "Special notes" `,
          "any.required": `"Special notes" is required`,
          "string.pattern.base": `Please enter valid "Special notes`,
        }),
      no_of_days: Joi.number().required().messages({
        "string.empty": `Please enter "No Of Days" `,
        "any.required": `"No Of Days" is required`,
        "string.pattern.base": `Please enter valid "No Of Days`,
      }),
    }).unknown(true);
    return holidaysScheme.validate(data);
  }

  /**validate holiday */

  validateHoliday(data) {
    const holidaysSchema = Joi.object({
      name: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "Holiday Name" `,
          "any.required": `"Holiday Name" is required`,
          "string.pattern.base": `Please enter valid "Holiday Name`,
        }),
      religion_id: Joi.number().required().integer().messages({
        "string.empty": `Please enter "Religion Name" `,
        "any.required": `"Religion Name" is required`,
        "string.pattern.base": `Please enter valid "Religion Name`,
      }),
      description: Joi.string().allow(null, '').min(0).max(150).messages({
        "string.max": `Max Length is 150 character`,
      }),
      leave_type: Joi.string().required().messages({
        "string.empty": `Please enter "Leave Type" `,
        "any.required": `"Leave Type" is required`,
      }),
      financial_year: Joi.string()
        .required()
        .pattern(/^[0-9]+$/)
        .length(4)
        .messages({
          "string.empty": `Please enter "Financial Year" `,
          "any.required": `"Financial Year" is required`,
          "string.pattern.base": `Financial Year must be an integer eg:(2020)`,
          "string.length": `Year Must be in 4 Digits eg:(2020))`,
        }),
    }).unknown(true);
    return holidaysSchema.validate(data);
  }

  validateShift(data) {
    const shiftSchema = Joi.object({
      name: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required("Please enter shift Name")
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "Shift Name" `,
          "any.required": `"Shift Name" is required`,
          "string.pattern.base": `Please enter valid "Shift" name`,
        }),
      from_time: Joi.string().required().messages({
        "string.empty": `Please Enter "From time" `,
        "any.required": `Please Enter "From time" `,
        "string.pattern.base": `Please Enter valid "From time"`,
      }),
      to_time: Joi.string().required().messages({
        "string.empty": `Please Enter "To time" `,
        "any.required": `Please Enter "To time" `,
        "string.pattern.base": `Please Enter valid "To time"`,
      }),
    }).unknown(true);
    return shiftSchema.validate(data);
  }

  validateBreakTime(data) {
    const breakTimeSchema = Joi.object({
      name: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required("Please enter break time name")
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "Break time name" `,
          "any.required": `"Break time name" is required`,
          "string.pattern.base": `Please enter valid "Break time" name`,
        }),
      from_time: Joi.string().required().messages({
        "string.empty": `Please Enter "From time" `,
        "any.required": `Please Enter "From time" `,
        "string.pattern.base": `Please Enter valid "From time"`,
      }),
      to_time: Joi.string().required().messages({
        "string.empty": `Please Enter "To time" `,
        "any.required": `Please Enter "To time" `,
        "string.pattern.base": `Please Enter valid "To time"`,
      }),
      shift_id: Joi.number().required().messages({
        "string.empty": `Please select "Shift Name" `,
        "any.required": `Please select "Shift Name" `,
        "string.pattern.base": `Please select valid "Shift Name"`,
      }),
    }).unknown(true);
    return breakTimeSchema.validate(data);
  }

  validateBreakFine(data) {
    console.log(data);
    const breakFine = Joi.object({
      break_minute: Joi.number().integer().required().messages({
        "string.empty": `Please Enter Break time`,
        "any.required": `Break time is required`,
        "number.base": "Please Enter Break time",
      }),
      amount: Joi.number().integer().required().messages({
        "string.empty": `Please Enter Fine amount`,
        "any.required": `Fine amount is required`,
        "number.base": "Please Enter Fine amount",
      }),
    }).unknown(true);
    return breakFine.validate(data);
  }

  validateTermination(data) {
    const terminationSchema = Joi.object({
      name: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required("Please enter termination Name")
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "Termination Name" `,
          "any.required": `"Termination Name" is required`,
          "string.pattern.base": `Please enter valid "Termination" name`,
        }),
    }).unknown(true);
    return terminationSchema.validate(data);
  }

  /**validate employment type**/
  validateEmploymentType(data) {
    const employmentTypeSchema = Joi.object({
      name: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "Staff Name" `,
          "any.required": `"Staff Name" is required`,
          "string.pattern.base": `Please enter valid "Staff Name" name`,
        }),
      description: Joi.string().allow(null, '').min(0).max(150).messages({
        "string.max": `Max Length is 150 character`,
      }),
      color: Joi.string().required("Please enter break time name").messages({
        "string.empty": `Please select "Color" `,
        "any.required": `"Color" is required`,
      }),
    }).unknown(true);
    return employmentTypeSchema.validate(data);
  }

  validateFloor(data) {
    const floorSchema = Joi.object({
      name: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please Enter "Floor" Name `,
          "any.required": `"Floor" Name is required`,
          "string.pattern.base": `Please enter valid "Floor" name`,
        }),
      description: Joi.string().min(0).max(150).messages({
        "string.max": `Max Length is 150 character`,
      }),
    }).unknown(true);
    return floorSchema.validate(data);
  }
  validateBloodGroup(data) {
    const floorSchema = Joi.object({
      name: Joi.string()
        .regex(/^(A|B|AB|O)[\+\-]$/)
        .uppercase()
        .trim()
        .required()
        .messages({
          "string.empty": `Please Enter "Blood Group" `,
          "any.required": `"Blood Group" is required`,
          "string.pattern.base": `Please enter valid "Blood Group" name`,
        }),
    }).unknown(true);
    return floorSchema.validate(data);
  }

  validateAdvance(data) {
    const advanceSchema = Joi.object({
      // employee_id: Joi.number().required().messages({
      //   "string.empty": `Please enter "Employee id" `,
      //   "any.required": `"Employee id" is required`,
      //   "string.pattern.base": `Please enter valid "Employee" id`,
      // }),
      amount: Joi.number().required().messages({
        "string.empty": `Please Enter "Amount" `,
        "any.required": `Please Enter "Amount" `,
        "string.pattern.base": `Please Enter valid "Amount"`,
      }),
      // duration: Joi.string()
      //   .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
      //   .pattern(/^\S.*$/)
      //   .required()
      //   .messages({
      //     "string.empty": `Please Enter "Duration" `,
      //     "any.required": `Please Enter "Duration" `,
      //     "string.pattern.base": `Please Enter valid "Duration"`,
      //   }),
    }).unknown(true);
    return advanceSchema.validate(data);
  }

  validateFinanceLoan(data) {
    const advanceSchema = Joi.object({
      amount: Joi.number().required().messages({
        "string.empty": `Please Enter "Amount" `,
        "any.required": `Please Enter "Amount" `,
        "string.pattern.base": `Please Enter valid "Amount"`,
      }),
      duration: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .pattern(/^\S.*$/)
        .required()
        .messages({
          "string.empty": `Please Enter "Duration" `,
          "any.required": `Please Enter "Duration" `,
          "string.pattern.base": `Please Enter valid "Duration"`,
        }),
    }).unknown(true);
    return advanceSchema.validate(data);
  }

  validateAgent(data) {
    const advanceSchema = Joi.object({

      agent_id: Joi.number().required().messages({
        "string.empty": `Please Enter "Agents Type" `,
        "any.required": `Please Enter "Agents Type" `,
        "string.pattern.base": `Please Enter valid "Agents Type"`,
      }),
      amount: Joi.number().required().messages({
        "string.empty": `Please Enter "Amount" `,
        "any.required": `Please Enter "Amount" `,
        "string.pattern.base": `Please Enter valid "Amount"`,
      }),
      percentage: Joi.number().required().messages({
        "string.empty": `Please Enter "Percentage" `,
        "any.required": `Please Enter "Percentage" `,
        "string.pattern.base": `Please Enter valid "Percentage"`,
      }),
      remarks: Joi.string().required().messages({
        "string.empty": `Please Enter "Remarks" `,
        "any.required": `Please Enter "Remarks" `,
      }),
    }).unknown(true);
    return advanceSchema.validate(data);
  }

  validateBonus(data) {
    const advanceSchema = Joi.object({
      employee_id: Joi.number().required().messages({
        "number.empty": `Please Enter "Employee Name" `,
        "any.required": `"Employee Name" is required`,
        "number.pattern.base": `Please select valid "Employee Name"`,
      }),
      finance_category_id: Joi.number().required().messages({
        "number.empty": `Please Enter "Finance Category" `,
        "any.required": `"Finance Category" is required`,
        "number.pattern.base": `Please select valid "Finance Category"`,
      }),
      due_date: Joi.date().required().messages({
        "any.required": `Please  Select Due Date`,
        "string.pattern.base": `Please Select valid Due Date`,
        "date.base": `Please Select Due Date`,
      }),
      remarks: Joi.string().required().messages({
        "string.empty": `Please Enter "Remarks" `,
        "any.required": `Please Enter "Remarks" `,
        "string.pattern.base": `Please Enter valid "Remarks"`,
      }),
      duration: Joi.number().required().messages({
        "string.empty": `Please Enter "Duration" `,
        "any.required": `Please Enter "Duration" `,
        "string.pattern.base": `Please Enter valid "Duration"`,
      }),
      amount: Joi.number().required().messages({
        "string.empty": `Please Enter "Amount" `,
        "any.required": `Please Enter "Amount" `,
        "string.pattern.base": `Please Enter valid "Amount"`,
      }),
      approved_by: Joi.number().required().messages({
        "number.empty": `Please Enter "Approved By" `,
        "any.required": `"Approved By" is required`,
        "number.pattern.base": `Please select valid "Approved By"`,
      }),
    }).unknown(true);
    return advanceSchema.validate(data);
  }

  validateExpense(data) {
    const advanceSchema = Joi.object({
      employee_id: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required("Please enter Employee id")
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please enter "Employee id" `,
          "any.required": `"Employee id" is required`,
          "string.pattern.base": `Please enter valid "Employee" id`,
        }),
      amount: Joi.number().required().messages({
        "string.empty": `Please Enter "Amount" `,
        "any.required": `Please Enter "Amount" `,
        "string.pattern.base": `Please Enter valid "Amount"`,
      }),
      total_paid_amount: Joi.number().required().messages({
        "string.empty": `Please Enter "Total Paid Amount" `,
        "any.required": `Please Enter "Total Paid Amount" `,
        "string.pattern.base": `Please Enter valid "Total Paid Amount"`,
      }),
      duration: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .pattern(/^\S.*$/)
        .required()
        .messages({
          "string.empty": `Please Enter "Duration" `,
          "any.required": `Please Enter "Duration" `,
          "string.pattern.base": `Please Enter valid "Duration"`,
        }),
    }).unknown(true);
    return advanceSchema.validate(data);
  }

  validateSeasonalSalary(data) {
    const advanceSchema = Joi.object({
      employee_type_id: Joi.number().integer().required().messages({
        "string.empty": `Please select "Employee Type" `,
        "any.required": `"Employee Type" is required`,
        "number.base": "Please select Employee Type",
      }),
      from_date: Joi.date()
        .min("now")
        .message('"date" cannot be earlier than today')
        .required(),
      to_date: Joi.date()
        .min(Joi.ref("from_date"))
        .message('"date" cannot be lesser than from date')
        .required(),
      per_day_salary: Joi.number().required().messages({
        "string.empty": `Please enter "No Of Days" `,
        "any.required": `"No Of Days" is required`,
        "string.pattern.base": `Please enter valid "No Of Days`,
      }),
    }).unknown(true);
    return advanceSchema.validate(data);
  }

  validateEmployeePositionInfo(data) {
    const advanceSchema = Joi.object({
      client_id: Joi.number().required().messages({
        "string.empty": `Please Select "Client" `,
        "any.required": `"Client" is required`,
        "number.base": "Please select Client",
      }),
      employee_type: Joi.number().required().messages({
        "string.empty": `Please Enter "Employee Type" `,
        "any.required": `"Employee Type" is required`,
        "string.pattern.base": `Please select valid "Employee Type"`,
        "number.base": "Please select Employee Type",
      }),
      department_id: Joi.number().required().messages({
        "string.empty": `Please Enter "Department" `,
        "any.required": `"Department" is required`,
        "string.pattern.base": `Please select valid "Department"`,
        "number.base": "Please select Department",
      }),
      // designation_id: Joi.number().required().messages({
      //   "string.empty": `Please Enter "Designation" `,
      //   "any.required": `"Designation" is required`,
      //   "string.pattern.base": `Please select valid "Designation"`,
      //   "number.base": "Please select Designation",
      // }),
      branch_id: Joi.number().required().messages({
        "string.empty": `Please Enter "Branch" `,
        "any.required": `"Branch" is required`,
        "string.pattern.base": `Please select valid "Branch"`,
        "number.base": "Please select Branch",
      }),
      floors_id: Joi.number().required().messages({
        "string.empty": `Please Enter "Floor" `,
        "any.required": `"Floor" is required`,
        "string.pattern.base": `Please select valid "Floor"`,
        "number.base": "Please select Floor",
      }),
      shift_id: Joi.number().required().messages({
        "string.empty": `Please Enter "Shift" `,
        "any.required": `"Shift" is required`,
        "string.pattern.base": `Please select valid "Shift"`,
        "number.base": "Please select Shift",
      }),
      reporting_to: Joi.number().required().messages({
        "string.empty": `Please Enter "Reporting" `,
        "any.required": `"Reporting" is required`,
        "string.pattern.base": `Please select valid "Reporting"`,
        "number.base": "Please select Reporting",
      }),
      holiday_scheme_id: Joi.number().required().messages({
        "string.empty": `Please Enter "Holiday Scheme" `,
        "any.required": `"Holiday Scheme" is required`,
        "string.pattern.base": `Please select valid "Holiday Scheme"`,
        "number.base": "Please select Holiday Scheme",
      }),

    }).unknown(true);
    return advanceSchema.validate(data);
  }

  validateEmployeeBasicInfo(data) {
    const employeeBasicSchema = Joi.object({
      profile_picture: Joi.string().required().messages({
        "string.empty": `Please choose "Profile Picture" `,
        "any.required": `"Profile Picture" is required`,
        "string.pattern.base": `Please choose valid "Profile Picture"`,
      }),
      name: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please Enter "Employee Name" `,
          "any.required": `"Employee Name" is required`,
          "string.pattern.base": `Please select valid "Employee Name"`,
        }),
      refrred_by: Joi.alternatives().try(
        Joi.string().required().messages({
          "string.empty": `Please Choose "Referred By" `,
          "any.required": `"Referred By" is required`,
          "string.pattern.base": `Please Choose valid "Referred By"`,
        }),
        Joi.number().required().messages({
          "any.required": `"Referred By" is required`,
          "number.base": "Please select Referred By",
        })
      ),
      father_name: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please Enter "Father Name" `,
          "any.required": `"Father Name" is required`,
          "string.pattern.base": `Please Enter valid "Father Name"`,
        }),
      mother_name: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please Enter "Mother Name" `,
          "any.required": `"Mother Name" is required`,
          "string.pattern.base": `Please Enter valid "Mother Name"`,
        }),
      aadhar_no: Joi.string()
        .required()
        .length(12)
        .pattern(/^[2-9]{1}[0-9]{11}$/)
        //   .pattern(/^[0-9]{4}[ -]?[0-9]{4}[ -]?[0-9]{4}$/)
        .trim()
        .messages({
          "string.empty": `Please Enter "Aadhar Number"`,
          "any.required": `"Aadhar Number" is required`,
          "string.pattern.base": `Please Enter valid "Aadhar Number"`,
          "string.length": "Aadhar Number must be 12 digits",
        }),
      date_of_birth: Joi.date().iso().required().messages({
        "date.base": "Please provide a valid date.",
        "date.format": "Please select Date of Birth",
        "any.required": "DOB is required.",
      }),
      age: Joi.string().required().messages({
        "string.empty": `Please Enter "Age" `,
        "any.required": `"Age" is required`,
        "string.pattern.base": `Please Enter valid "Age"`,
        "string.base": "Please Enter Age",
      }),
      email_id: Joi.string()
        .email({
          minDomainSegments: 2,
          tlds: { allow: false },
        })
        .allow("", null)
        .messages({
          "string.pattern.base": `Please Enter valid "Email ID"`,
          "string.email": "Invalid Email format.",
        }),
      mobile_number: Joi.string()
        .pattern(/^(\+\d{1,3}[- ]?)?\d{10}$|^(\+\d{1,3}[- ]?)?\d{12}$/)
        .required()
        .messages({
          "string.empty": 'Please Enter valid "Mobile Number"',
          "any.required": 'Please Enter valid "Mobile Number"',
          "string.pattern.base": 'Mobile Number must be either 10 or 12 digits',
        }),
      gender: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please Select "Gender" `,
          "any.required": `"Gender" is required`,
          "string.pattern.base": `Please select "Gender"`,
        }),
      marital_status: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please Select "Marital Status" `,
          "any.required": `"Marital Status" is required`,
          "string.pattern.base": `Please select "Marital Status"`,
        }),
      present_address_door_no: Joi.string()
        .required()
        .pattern(/^[a-zA-Z0-9\s',.\/]*$/)
        .messages({
          "string.empty": `Please Enter "Door No"`,
          "any.required": `"Door No" is required`,
          "string.pattern.base": `Please Enter a valid "Door No"`,
        }),
      present_address_street_name: Joi.string()
        .required()
        .pattern(/^[a-zA-Z0-9\s',.\/]*$/)
        .messages({
          "string.empty": `Please Enter "Street Name I" `,
          "any.required": `"Street Name I" is required`,
          "string.pattern.base": `Please Enter valid "Street Name I"`,
        }),
      present_address_city: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please Enter "City" `,
          "any.required": `"City" is required`,
          "string.pattern.base": `Please Enter valid "City"`,
        }),
      present_address_state: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please Enter "State" `,
          "any.required": `"State" is required`,
          "string.pattern.base": `Please Enter valid "State"`,
        }),
      present_address_pincode: Joi.string()
        .pattern(/^\S.*$/)
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .min(6)
        .max(6)
        .pattern(/^[0-9]+$/)
        .required()
        .messages({
          "string.empty": `Please Enter "Pincode" `,
          "any.required": `Please Enter "Pincode" `,
          "string.pattern.base": `Please Enter valid "Pincode"`,
          "string.min": "Pincode must be 6 Digit",
          "string.max": "Pincode must be 6 Digit",
        }),
      permanent_address_door_no: Joi.string()
        .required()
        .pattern(/^[a-zA-Z0-9\s',.\/]*$/)
        .messages({
          "string.empty": `Please Enter "Door No"`,
          "any.required": `"Door No" is required`,
          "string.pattern.base": `Please Enter a valid "Door No"`,
        }),
      permanent_address_street_name: Joi.string()
        .pattern(/^[a-zA-Z0-9\s',.\/]*$/)
        .required()
        .messages({
          "string.empty": `Please Enter "Street Name I" `,
          "any.required": `"Street Name I" is required`,
          "string.pattern.base": `Please Enter valid "Street Name I"`,
        }),
      permanent_address_city: Joi.string()
        .pattern(/^[a-zA-Z0-9\s,\/]*$/)
        .required()
        .messages({
          "string.empty": `Please Enter "City" `,
          "any.required": `"City" is required`,
          "string.pattern.base": `Please Enter valid "City"`,
        }),
      permanent_address_state: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please Enter "State" `,
          "any.required": `"State" is required`,
          "string.pattern.base": `Please Enter valid "State"`,
        }),
      permanent_address_pincode: Joi.string()
        .pattern(/^\S.*$/)
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .min(6)
        .max(6)
        .pattern(/^[0-9]+$/)
        .required()
        .messages({
          "string.empty": `Please Enter "Pincode" `,
          "any.required": `Please Enter "Pincode" `,
          "string.pattern.base": `Please Enter valid "Pincode"`,
          "string.min": "Pincode must be 6 Digit",
          "string.max": "Pincode must be 6 Digit",
        }),
    }).unknown(true);
    return employeeBasicSchema.validate(data);
  }

  validateBankInfo(data) {
    const employeeBasicSchema = Joi.object({
      uan_number: Joi.string()
        .pattern(/^[0-9]{12}$/)
        .label('UAN No')
        .allow("", null)
        .messages({
          "string.pattern.base": `Please enter valid UAN No`,
          "string.empty": `Please enter valid UAN No`,
        }),
      esi_number: Joi.string()
        .pattern(/^[0-9]{10}$/)
        .label('ESI No')
        .allow("", null)
        .messages({
          "string.pattern.base": `Please enter valid ESI No`,
          "string.empty": `Please enter valid ESI No`,
        }),
      hdfc_account_name: Joi.string()
        .regex(/^[a-zA-Z' ']+$/)
        .max(50)
        .allow("", null)
        .messages({
          "string.pattern.base": `Please enter valid Branch name`,
          "string.max": `Max Length is 50`,
        }),
      hdfc_account_no: Joi.string()
        .regex(/^[A-Z0-9]{1,12}$/)
        .min(8)
        .max(12)
        .uppercase()
        .allow("", null)
        .messages({
          "string.pattern.base": `Account No Should have 12 digit alpha numeric value with capital letter`,
          "string.min": `Min Length is 8`,
          "string.max": `Max Length is 12`,
        }),
      hdfc_bank_passbook_coverpage_image: Joi.string().allow("", null).messages({
        "string.empty": `Please choose "Bank Passbook cover page" `,
        "any.required": `"Bank Passbook cover page" is required`,
        "string.pattern.base": `Please choose valid "Bank Passbook cover page"`,
      }),
      hdfc_cancelled_cheque_leaf_image: Joi.string().allow("", null).messages({
        "string.empty": `Please choose "Cancelled Cheque Leaf" `,
        "any.required": `"Cancelled Cheque Leaf" is required`,
        "string.pattern.base": `Please choose valid "Cancelled Cheque Leaf"`,
      }),
      home_bank_account_name: Joi.string()
        .regex(/^[a-zA-Z' ']+$/)
        .max(30)
        .allow("", null)
        .messages({
          "string.pattern.base": `Please enter valid Branch name`,
          "string.max": `Max Length is 30`,
        }),
      home_bank_account_no: Joi.string()
        .regex(/^[A-Z0-9]{1,12}$/)
        .min(8)
        .max(12)
        .uppercase()
        .allow("", null)
        .messages({
          "string.pattern.base": `Account No Should have 12 digit alpha numeric value with capital letter`,
          "string.min": `Min Length is 8`,
          "string.max": `Max Length is 12`,
        }),
      home_bank_ifsc_code: Joi.string()
        .regex(/^[A-Z]{4}0[A-Z0-9]{6}$/)
        .max(11)
        .uppercase()
        .optional()
        .allow("", null)
        .messages({
          "string.pattern.base": `Enter first 4 as alphabetic characters and the last 6 as alpha numeric`,
          "string.max": `Max Length is 11`,
        }),
      home_bank_branch: Joi.string()
        .regex(/^[a-zA-Z' ']+$/)
        .max(50)
        .allow("", null)
        .messages({
          "string.pattern.base": `Please enter valid Branch name`,
          "string.max": `Max Length is 50`,
        }),
      bank_passbook_coverpage_image: Joi.string().allow("", null).messages({
        "string.empty": `Please choose "Bank Passbook cover page" `,
        "any.required": `"Bank Passbook cover page" is required`,
        "string.pattern.base": `Please choose valid "Bank Passbook cover page"`,
      }),
      cancelled_cheque_leaf_image: Joi.string().allow("", null).messages({
        "string.empty": `Please choose "Cancelled Cheque Leaf" `,
        "any.required": `"Cancelled Cheque Leaf" is required`,
        "string.pattern.base": `Please choose valid "Cancelled Cheque Leaf"`,
      }),

    }).unknown(true);
    return employeeBasicSchema.validate(data);
  }

  validateEmployeeSalaryInfo(data) {
    console.log("validation:", data);
    const advanceSchema = Joi.object({
      dummy_basic: Joi.number().required().messages({
        "number.empty": `Please Enter "Account Holder Name" `,
        "any.required": `"Account Holder Name" is required`,
        "string.pattern.base": `Please select valid "Account Holder Name"`,
        "number.base": "Please enter Basic salary",
      }),
      pf_amount: Joi.number().required().messages({
        "string.empty": `Please Enter "PF" `,
        "any.required": `"PF" is required`,
        "string.pattern.base": `Please select valid "PF"`,
        "number.base": "Please enter PF amount",
      }),
      gross_salary: Joi.number().required().messages({
        "string.empty": `Please Enter "Gross Salary" `,
        "any.required": `"Gross Salary" is required`,
        "string.pattern.base": `Please select valid "Gross Salary"`,
        "number.base": "Please enter Gross salary",
      }),
      account_salary: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please Enter "Recipient Account Name" `,
          "any.required": `"To Account" is required`,
          "string.pattern.base": `Please select valid "To Account"`,
        }),
      in_cash: Joi.string()
        .pattern(/^\d+(\.\d{1,2})?$/)
        .required()
        .messages({
          "string.empty": `Please Enter Cash amount`,
          "any.required": `Cash amount is required`,
          "string.pattern.base": `Please select valid Cash amount`,
        }),
      approved_by: Joi.number().required().messages({
        "string.empty": `Please select "Approval name" `,
        "any.required": `"Approval name" is required`,
        "string.pattern.base": `Please select valid "Approval name"`,
      }),
    }).unknown(true);
    return advanceSchema.validate(data);
  }


  validateEmpWorkExperience(data) {
    const advanceSchema = Joi.object({
      company_name: Joi.string().required()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please Enter "Company Name" `,
          "any.required": `"Company Name" is required`,
          "string.pattern.base": `Please Enter valid "Company Name"`,
        }),
      agency_name: Joi.string().required()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please Enter "Agency Name" `,
          "any.required": `"Agency Name" is required`,
          "string.pattern.base": `Please Enter valid "Agency Name"`,
        }),
      period_from: Joi.date().required().messages({
        "any.required": `Please  Select "From Date" `,
        "string.pattern.base": `Please Select "valid Leave Date" `,
      }),
      period_to: Joi.date().required()
        .iso()
        .min(Joi.ref("period_from"))
        .messages({
          "any.required": `Please  Select "To Date" `,
          "string.pattern.base": `Please Select "valid Leave Date" `,
        }),
      rank: Joi.string().required()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please Enter "Rank" `,
          "any.required": `"Rank" is required`,
          "string.pattern.base": `Please Enter valid "Rank"`,
        }),
    }).unknown(true);
    return advanceSchema.validate(data);
  }

  validateEmpFamilyDetails(data) {
    const advanceSchema = Joi.object({
      name: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please Enter "Name" `,
          "any.required": `"Name" is required`,
          "string.pattern.base": `Please Enter valid "Name"`,
        }),
      relationship: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please Enter "Relationship" `,
          "any.required": `"Relationship" is required`,
          "string.pattern.base": `Please Enter valid "Relationship"`,
        }),
      // mobile_number: Joi.string()
      //   .optional()
      //   .pattern(/^(\+\d{1,3}[- ]?)?\d{10}$|^(\+\d{1,3}[- ]?)?\d{12}$/)
      //   .required()
      //   .messages({
      //     "string.empty": 'Please Enter valid "Mobile Number"',
      //     "any.required": 'Please Enter valid "Mobile Number"',
      //     "string.pattern.base": 'Mobile Number must be either 10 or 12 digits',
      //   }),
      door_no: Joi.string()
        .required()
        .pattern(/^[a-zA-Z0-9\s',.\/]*$/)
        .messages({
          "string.empty": `Please Enter "Door No"`,
          "any.required": `"Door No" is required`,
          "string.pattern.base": `Please Enter a valid "Door No"`,
        }),
      street_name_1: Joi.string()
        .pattern(/^[a-zA-Z0-9\s',.\/]*$/)
        .required()
        .messages({
          "string.empty": `Please Enter "Street Name I" `,
          "any.required": `"Street Name I" is required`,
          "string.pattern.base": `Please Enter valid "Street Name I"`,
        }),
      city: Joi.string()
        .pattern(/^[a-zA-Z0-9\s,\/]*$/)
        .required()
        .messages({
          "string.empty": `Please Enter "City" `,
          "any.required": `"City" is required`,
          "string.pattern.base": `Please Enter valid "City"`,
        }),
      state: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .required()
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please Enter "State" `,
          "any.required": `"State" is required`,
          "string.pattern.base": `Please Enter valid "State"`,
        }),
      pincode: Joi.string()
        .pattern(/^\S.*$/)
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .min(6)
        .max(6)
        .pattern(/^[0-9]+$/)
        .required()
        .messages({
          "string.empty": `Please Enter "Pincode" `,
          "any.required": `Please Enter "Pincode" `,
          "string.pattern.base": `Please Enter valid "Pincode"`,
          "string.min": "Pincode must be 6 Digit",
          "string.max": "Pincode must be 6 Digit",
        }),

    }).unknown(true);
    return advanceSchema.validate(data);
  }


  validateQualification(data) {
    const advanceSchema = Joi.object({
      // qualification: Joi.string()
      //   .regex(/^[a-zA-Z0-9\s,'.-]*$/)
      //   .pattern(/^\S.*$/)
      //   .messages({
      //     "string.empty": `Please Enter "Qualification" `,
      //     "any.required": `"Qualification" is required`,
      //     "string.pattern.base": `Please Enter valid "Qualification"`,
      //   }),
      qualification: Joi.string().required().messages({
        "string.empty": `Please choose "Qualification" `,
        "any.required": `"Qualification" is required`,
        "string.pattern.base": `Please choose valid "Qualification"`,
      }),
      institute_name: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please Enter "Name of the Institution" `,
          "any.required": `"Name of the Institution" is required`,
          "string.pattern.base": `Please Enter valid "Name of the Institution"`,
        }),
      percentage: Joi.string()
        .regex(/^[0-9]+%?$/)
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please Enter "Percentage of Marks" `,
          "any.required": `"Percentage of Marks" is required`,
          "string.pattern.base": `Please Enter valid "Percentage of Marks"`,
        }),
    }).unknown(true);
    return advanceSchema.validate(data);
  }


  validateEmployeePersonalInfo(data) {
    const advanceSchema = Joi.object({
      height: Joi.string()
        // .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .regex(/^[0-9]+(cm|CM|Cm)$/)
        .pattern(/^\S.*$/)
        .required().messages({
          "string.empty": `Please provide your "Height" in centimeters`,
          "any.required": `Please enter your "Height"`,
          "string.pattern.base": `Please use the format "200CM or 200cm" for the "Height"`
        }),
      weight: Joi.string()
        // .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .regex(/^[0-9]+(kg|KG|Kg)$/)
        .pattern(/^\S.*$/)
        .required().messages({
          "string.empty": `Please provide your "Weight" in centimeters`,
          "any.required": `Please enter your "Weight"`,
          "string.pattern.base": `Please use the format "70KG or 70kg" for the "Weight"`
        }),
      blood_pressure: Joi.string().required().messages({
        "string.empty": `Please Enter "Blood Pressure" `,
        "any.required": `"Blood Pressure" is required`,
        "string.pattern.base": `Please Enter valid "Blood Pressure"`,
      }),
      diabets: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9\s'/]*$"))
        .pattern(/^\S.*$/)
        .required().messages({
          "string.empty": `Please Enter "Diabetes" `,
          "any.required": `"Diabetes" is required`,
          "string.pattern.base": `Please Enter valid "Diabetes"`,
        }),
      heart_problem: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9\s'/]*$"))
        .pattern(/^\S.*$/)
        .required().messages({
          "string.empty": `Please Enter "Cardiac/ Heart problems" `,
          "any.required": `"Cardiac/ Heart problems" is required`,
          "string.pattern.base": `Please Enter valid "Cardiac/ Heart problems"`,
        }),
      skin_disease: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9\s'/]*$"))
        .pattern(/^\S.*$/)
        .required().messages({
          "string.empty": `Please Enter "Skin Disease" `,
          "any.required": `"Skin Disease" is required`,
          "string.pattern.base": `Please Enter valid "Skin Disease"`,
        }),
      aadhar_image: Joi.string().required().messages({
        "string.empty": `Please choose "Aadhar" `,
        "any.required": `"Aadhar" is required`,
        "string.pattern.base": `Please choose valid "Aadhar"`,
      }),
      // driving_license_image_upload: Joi.string().required().messages({
      //   "string.empty": `Please choose "Driving License" `,
      //   "any.required": `"Driving License" is required`,
      //   "string.base": `Please choose valid "Driving License"`,
      // }),
      police_verification_certificate_image: Joi.string().required().messages({
        "string.empty": `Please choose "Police Verification Certificate" `,
        "any.required": `"Police Verification Certificate" is required`,
        "string.base": `Please choose valid "Police Verification Certificate"`,
      }),
      driving_license: Joi.string()
        .regex(/^[A-Z]{2}\d{2,3}[a-zA-Z]?\d{4}\d{7}$/)
        .pattern(/^\S.*$/)
        .required().messages({
          "string.empty": `Please Enter "Driving License" `,
          "any.required": `"Driving License" is required`,
          "string.pattern.base": `Please Enter valid "Driving License"`,
          "string.base": `Please Enter valid "Driving License"`,
        }),
      police_verification_certificate_number: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9\s'/]*$"))
        .pattern(/^\S.*$/)
        .required().messages({
          "string.empty": `Please Enter "Police Verification Certificate" `,
          "any.required": `"Police Verification Certificate" is required`,
          "string.pattern.base": `Please Enter valid "Police Verification Certificate"`,
          "string.base": `Please Enter valid "Police Verification Certificate"`,
        }),
    }).unknown(true);
    return advanceSchema.validate(data);
  }

  validateEmployeeEducationalInfo(data) {
    const advanceSchema = Joi.object({
      employee_qualification: Joi.string().required().messages({
        "string.empty": `Please Enter "Qualification" `,
        "any.required": `Please Enter "Qualification" `,
        "string.pattern.base": `Please Enter valid "Qualification"`,
      }),
      employee_passed_out: Joi.string()
        .required()
        .pattern(/^[0-9]+$/)
        .length(4)
        .messages({
          "string.empty": `Please enter "Passed Out Year" `,
          "any.required": `"Passed Out Year" is required`,
          "string.pattern.base": `Passed Out Year must be an integer eg:(2020)`,
          "string.length": `Year Must be in 4 Digits eg:(2020))`,
        }),
    }).unknown(true);
    return advanceSchema.validate(data);
  }

  validateEmployeeRemarks(data) {
    const advanceSchema = Joi.object({
      remarks: Joi.string()
        .required()
        .pattern(/^[a-zA-Z0-9\s,\/]*$/)
        .messages({
          "string.empty": `Please enter "Remarks" `,
          "any.required": `"Remarks" is required`,
          "string.pattern.base": `Please enter valid "Remarks"`,
        }),
    }).unknown(true);
    return advanceSchema.validate(data);
  }

  validateEmpBulkUpload(data) {
    const schema = Joi.object({
      client_id: Joi.number().required().messages({
        "number.empty": `Please Enter valid "Client" `,
        "any.required": `Please Enter "Client" `,
        "number.pattern.base": `Please Enter valid "Client"`,
      }),
    }).unknown(true);

    const { error } = schema.validate(data);
    return { error };
  }

  validateClientBasicInfo(data) {
    const advanceSchema = Joi.object({
      name: Joi.string()
        .regex(/^[a-zA-Z0-9\s' _.-@#$&()]*$/)
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please Enter "Name" `,
          "any.required": `"Name" is required`,
          "string.pattern.base": `Please Enter valid "Name"`,
        }),
      phone: Joi.string()
        .min(10)
        .max(12)
        .pattern(/^[0-9]+$/)
        .required()
        .messages({
          "string.empty": `Please Enter "Mobile Number" `,
          "any.required": `Please Enter "Mobile Number" `,
          "string.pattern.base": `Please Enter valid "Mobile Number"`,
          "string.min": "Mobile Number must be 10 Digit",
          "string.max": "Mobile Number must be 12 Digit",
        }),
      email: Joi.string()
        .email({
          minDomainSegments: 2,
          tlds: { allow: false },
        })
        .allow("", null)
        .messages({
          "string.pattern.base": `Please Enter valid "Email ID"`,
          "string.email": "Invalid Email format.",
        }),
      location: Joi.string()
        .regex(/^[a-zA-Z0-9\s' _.-@#$&!,/-_()]*$/)
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please Enter "Location" `,
          "any.required": `"Location" is required`,
          "string.pattern.base": `Please Enter valid "Location"`,
        }),
      contact_person_name: Joi.string()
        .regex(new RegExp("^[a-zA-Z0-9s' ']*$"))
        .pattern(/^\S.*$/)
        .messages({
          "string.empty": `Please Enter "Contact Person Name" `,
          "any.required": `"Contact Person Name" is required`,
          "string.pattern.base": `Please Enter valid "Contact Person Name"`,
        }),
      address: Joi.string().max(150).messages({
        "string.empty": `Please Enter "Address" `,
        "any.required": `Please Enter "Address" `,
        "string.pattern.base": `Please Enter valid "Address"`,
        "string.max": `Max Length is 150 character`,
      }),
    }).unknown(true);
    return advanceSchema.validate(data);
  }
  ValidateUser(data) {
    const userSchema = Joi.object({
      employee_id: Joi.number().required().messages({
        "number.empty": `Please select "Employee Name" `,
        "any.required": `"Employee Name" is required`,
        "number.pattern.base": `Please select valid "Employee Name`,
        "number.base": `Please select "Employee Name`,
      }),

      role_id: Joi.number().required().messages({
        "number.empty": `Please select "Role Name" `,
        "any.required": `"Role Name" is required`,
        "number.pattern.base": `Please select valid "Role Name`,
        "number.base": `Please select "Role Name`,
      }),

      email: Joi.string()
        .email({
          minDomainSegments: 2,
          tlds: { allow: false },
        })
        .required()
        .messages({
          "string.pattern.base": `Please Enter valid "Email ID"`,
          "string.email": "Invalid Email format.",
        }),

      password: Joi.string()
        .min(8)
        .required()
        .pattern(new RegExp('^[a-zA-Z0-9!@#$%^&*"|,.<>/?]{3,30}$'))
        .messages({
          "string.base": "Password must be a string",
          "string.empty": "Password is required",
          "string.min": "Password must be at least {#limit} characters long",
          "any.required": "Password is required",
          "string.pattern.base":
            "Password must contain only letters, numbers, and special characters",
        }),
    }).unknown(true);
    return userSchema.validate(data);
  }
  validatePrivilege(data) {
    const privilegeSchema = Joi.object({
      type: Joi.string()
        .pattern(new RegExp("^[a-zA-Z0-9][a-zA-Z0-9' ']*$"))
        .min(0)
        .max(150)
        .required()
        .messages({
          "string.empty": `Please select Type`,
          "string.base": `Please select Valid Type name`,
          "string.pattern.base": `Please select valid Type`,
        }),

      id: Joi.number().integer().required().messages({
        "number.empty": `Please select Value`,
        "number.base": `Please select Value`,
        "number.pattern.base": `Please Select Value`,
      }),

      module_id: Joi.number().integer().required().messages({
        "number.empty": `Please select Module`,
        "number.base": `Please select Module`,
        "number.pattern.base": `Please Select Module`,
      }),
    }).unknown(true);
    return privilegeSchema.validate(data);
  }
}
const Validation = new ValidationClass();
export default Validation;
