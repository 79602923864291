import React, { useState, useEffect } from "react";
import More from "../../../Assets/Icons/More.svg";
import { Link } from "react-router-dom";
import { GET_API, POST_API, DELETE_API } from "../../../Services/api";
import CasteTable from "../../DataTable/CasteTable";
import Search from "../../../Assets/Icons/Search.svg";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import Validation from "../../../Validation/Validation";
import alertify from "alertifyjs";
import Edit from "../../../Assets/Icons/Edit.svg";
import Spinner from "../../Spinner";

function CasteList() {
  const [filter, setfilter] = useState(false);
  const [casteList, setCasteList] = useState([]);
  const [filteredCaste, setFilteredCaste] = useState([]);
  const [errors, seterrors] = useState({});
  const [editStatus, setEditStatus] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [casteDetails, setcasteDetails] = useState({
    name: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setcasteDetails({ ...casteDetails, [name]: value });
  };

  const header = [
    { id: "s.no", label: "S.No", minWidth: 170 },
    { id: "name", label: "Caste Name", minWidth: 100 },
    { id: "created_at", label: "Created at", minWidth: 100 },
    { id: "more", label: "", minWidth: 100 },
  ];

  useEffect(() => {
    getCasteList();
  }, []);

  const getCasteList = async () => {
    setLoading(true);
    try {
      const casteList = await GET_API("/casteLists");
      if (casteList.status === 200) {
        setLoading(false);
        setCasteList(casteList?.data?.data);
        setFilteredCaste(casteList?.data?.data);
      }
    } catch (error) {
      setLoading(false);
      alertify.error("Faile to get Caste List");
    }
  };

  if (loading) {
    return <Spinner />;
  }

  const handleCasteSearch = (e) => {
    const searchTerm = e.target.value;
    const searchedList = casteList.filter((item) => {
      for (let key in item) {
        if (
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
    setFilteredCaste(searchedList);
  };

  const addCaste = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.validateCaste(casteDetails);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    const createFloor = await POST_API("/addCaste", casteDetails);
    if (createFloor.status === 200) {
      setButtonDisabled(false);
      setcasteDetails({ name: "" });
      getCasteList();
      alertify.success(createFloor?.data?.message);
      return;
    }
    alertify.error(createFloor?.response?.data?.message);
  };

  const editCaste = async (e) => {
    setButtonDisabled(true);
    e.preventDefault();
    const isValid = Validation.validateCaste(casteDetails);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    const editFloor = await POST_API("/updateCaste", casteDetails);
    if (editFloor.status === 200) {
      setButtonDisabled(false);
      setcasteDetails({ name: "" });
      getCasteList();
      setEditStatus(false);
      alertify.success(editFloor?.data?.message);
      return;
    }
    alertify.error(editFloor?.response?.data?.message);
  };
  const resetForm = () => {
    setEditStatus(false);
    setcasteDetails({ ...casteDetails, name: "" });
    seterrors({});
  };

  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Caster..?",
        async function () {
          if (row_id) {
            const deleteRow = await DELETE_API(`/deleteCaste/${row_id}`);
            if (deleteRow.status === 200) {
              alertify.success(deleteRow?.data?.message);
              getCasteList();
              return;
            }
            alertify.error("Failed To Delete Floor");
          }
        },
        function () {}
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">Caste</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Add Caste</h4>
          <div className="search-block">
            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                onChange={(e) => handleCasteSearch(e)}
                style={{ outline: "none", border: "none" }}
              />

              <img src={Search} alt="" />
            </div>
          </div>
          <div className="add-list-container">
            <div className="input-block">
              <label className="add-new-input-label">
                Caste Name <span className="required-symbol">*</span>
              </label>
              <input
                type="text"
                name="name"
                className="input-field"
                placeholder="Enter Caste name"
                onChange={(e) => {
                  handleChange(e);
                }}
                autoComplete="off"
                value={casteDetails.name}
              />
              {errors.name ? (
                <div className="text-[#FF5C42] text-sm font-medium font-karla">
                  {errors.name}
                </div>
              ) : null}
            </div>
            <div className="flex gap-3">
              <div className="add-new-button-block">
                {editStatus ? (
                  <button
                    className="add-new-button"
                    onClick={(e) => {
                      editCaste(e);
                    }}
                    disabled={buttonDisabled}
                  >
                    <span className="button-label">Save</span>
                    <img src={ColorAdd} alt="add" />
                  </button>
                ) : (
                  <button
                    className="add-new-button"
                    onClick={(e) => {
                      addCaste(e);
                    }}
                    disabled={buttonDisabled}
                  >
                    <span className="button-label">Add New</span>
                    <img src={ColorAdd} alt="add" />
                  </button>
                )}
              </div>
              <Link className="edit-button" onClick={() => resetForm()}>
                <span className="button-label">Cancel</span>
                <img src={Edit} alt="add" />
              </Link>
            </div>
          </div>

          {/**************** * Table ********************/}
          <CasteTable
            list={filteredCaste}
            columns={header}
            icon={More}
            editStatus={setEditStatus}
            editList={setcasteDetails}
            multiple={false}
            deleteRow={handleDelete}
          />
        </div>
      </div>
    </div>
  );
}

export default CasteList;
