import React, {useState} from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MoreOption from "../../../MoreOption";
export default function StickyHeadTable({
  list,
  columns,
  showDetails,
  icon,
  multiple,
  deleteRow,
  editList,
  editStatus,
  isAction
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 340 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, i) => (
                <TableCell
                  key={i}
                  align={column.align}
                  className="table-header"
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan="8"
                  className="table-body td-data-not-found h-[220px]"
                >
                  Data Not Found..!
                </TableCell>
              </TableRow>
            ) : (
              list
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => {
                 
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={i}
                      className="table-column"
                    >
                      {
                        <TableCell className="table-body !w-[7%]">
                          {page * rowsPerPage + (i + 1)}
                        </TableCell>
                      }
                      {
                        <TableCell className="table-body">
                          {row?.name}
                        </TableCell>
                      }
                      {
                        <TableCell className="table-body">
                          {row?.relationship}
                        </TableCell>
                      }
                      {
                        <TableCell className="table-body">
                          {row?.contact_no}
                        </TableCell>
                      }
                      {        
                        <TableCell className="table-body">
                          {row?.door_no}, {row?.street_name_1}, {row?.street_name_2? `${row?.street_name_2}, ` : ""} {row?.city}, {row?.state}, {row?.pincode}
                        </TableCell>
                      }
                      { isAction ?
                        <MoreOption
                          icon={icon}
                          row={row}
                          showDetails={showDetails}
                          multiple={multiple}
                          deleteRow={deleteRow}
                          activeEdit={false}
                          editList={editList}
                          editStatus={editStatus}
                        />
                        : null
                      }
                    </TableRow>
                  );
                })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
