import React from "react";
import { Route } from "react-router-dom";

import Payslip from "../PayRoll/PaySlips/payslipsList";
import DownloadPayslip from "../PayRoll/DownloadPayslip/DownloadPayslip";
import PayslipDetails from "../PayRoll/PaySlips/payslipsDetails";
import ManualSlip from "../PayRoll/ManualPf/manualpf";
import ManualDeduction from "../PayRoll/ManualDeduction/ManualDeduction";

function PayslipRoutes() {
  return (
    <>
      <Route path="payslip" element={<Payslip />}></Route>
      <Route path="payslip/download" element={<DownloadPayslip />}></Route>
      <Route path="payslip/details/:id" element={<PayslipDetails />}></Route>
      <Route path="manualpf" element={<ManualSlip />}></Route>
      <Route path="manual/deduction" element={<ManualDeduction />}></Route>
    </>
  );
}

export default PayslipRoutes;
