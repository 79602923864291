import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import alertify from "alertifyjs";
import { POST_API } from "../../../Services/api";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import "alertifyjs/build/css/alertify.css";

export default function StickyHeadTable({ list, columns, setAttendanceData }) {
    const navigate = useNavigate();
    const currentMonthYear = new Date()
    // const [page, setPage] = useState(0);
    // const [rowsPerPage, setRowsPerPage] = useState(10);

    // const handleChangePage = (event, newPage) => {
    //     setPage(newPage);
    // };

    // const handleChangeRowsPerPage = (event) => {
    //     setRowsPerPage(+event.target.value);
    //     setPage(0);
    // };

    const handleInput = (e, index) => {
        const { name, value } = e.target
        const attendance = [...list]
        attendance[index][name] = value
        setAttendanceData(attendance)
        // console.log("attendance", attendance);
    }

    const handleSubmit = async () => {
        const res = list?.map((item) => {
            const { id, total_number_of_days, present_days, incentive_days, other_deductions, canteen_deduction, pf_deductions, date } = item
            return { employee_id: id, total_number_of_days, present_days, incentive_days, other_deductions, canteen_deduction, pf_deductions, date }
        })
        const submitData = {
            year: dayjs(currentMonthYear).format("YYYY"),
            month: dayjs(currentMonthYear).format("MM"),
            attendance: res
        }
        try {
            const response = await POST_API(`AddEmployeeAttendance`, submitData);
            console.log("response", response);
            if (response?.status === 200) {
                alertify.success("Attendance added successfully!");
                navigate('/masters/manualAttendance')
            }
            console.log("response", response);
        } catch (error) {

        }
    }

    return (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 320 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column, i) => (
                                <TableCell
                                    key={i}
                                    align={column.align}
                                    className="table-header border border-[#d9d9d9]"
                                >
                                    {column?.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list?.length === 0 ? (
                            <TableRow>
                                <TableCell
                                    colSpan="8"
                                    className="table-body td-data-not-found h-[220px]"
                                >
                                    Data Not Found..!
                                </TableCell>
                            </TableRow>
                        ) : (
                            list
                                // ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, i) => {
                                    return (
                                        <TableRow
                                            key={i}
                                            className="cursor-pointer table-column cursor-text"
                                        >
                                            <TableCell className="table-body border border-[#d9d9d9]" style={{ width: "8%" }}>
                                                {row?.employee_code}
                                            </TableCell>
                                            <TableCell className="table-body border border-[#d9d9d9]" style={{ width: "10%" }}>
                                                {row?.name}
                                            </TableCell>
                                            <TableCell className="table-body border border-[#d9d9d9]" style={{ width: "12%" }} >
                                                <input
                                                    type="number"
                                                    name="total_number_of_days"
                                                    onChange={e => handleInput(e, i)}
                                                    className="input-field"
                                                    value={row?.total_number_of_days}
                                                    disabled
                                                    readOnly
                                                    style={{ width: "100%" }}
                                                />
                                            </TableCell>
                                            <TableCell className="table-body border border-[#d9d9d9]" style={{ width: "12%" }}>
                                                <input
                                                    type="number"
                                                    name="present_days"
                                                    onChange={e => handleInput(e, i)}
                                                    className="input-field"
                                                    value={row?.present_days}
                                                    style={{ width: "100%" }}
                                                />
                                            </TableCell>
                                            <TableCell className="table-body border border-[#d9d9d9]" style={{ width: "12%" }}>
                                                <input
                                                    type="number"
                                                    name="incentive_days"
                                                    onChange={e => handleInput(e, i)}
                                                    className="input-field"
                                                    value={row?.incentive_days}
                                                    style={{ width: "100%" }}
                                                />
                                            </TableCell>
                                            <TableCell className="table-body border border-[#d9d9d9]" style={{ width: "12%" }}>
                                                <input
                                                    type="number"
                                                    name="other_deductions"
                                                    onChange={e => handleInput(e, i)}
                                                    className="input-field"
                                                    value={row?.other_deductions}
                                                    style={{ width: "100%" }}
                                                />
                                            </TableCell>
                                            <TableCell className="table-body border border-[#d9d9d9]" style={{ width: "12%" }}>
                                                <input
                                                    type="number"
                                                    name="canteen_deduction"
                                                    onChange={e => handleInput(e, i)}
                                                    className="input-field"
                                                    value={row?.canteen_deduction}
                                                    style={{ width: "100%" }}
                                                />
                                            </TableCell>
                                            <TableCell className="table-body border border-[#d9d9d9]" style={{ width: "12%" }}>
                                                <input
                                                    type="number"
                                                    name="pf_deductions"
                                                    onChange={e => handleInput(e, i)}
                                                    className="input-field"
                                                    value={row?.pf_deductions}
                                                    style={{ width: "100%" }}
                                                />
                                            </TableCell>
                                            <TableCell className="table-body border border-[#d9d9d9]" style={{ width: "10%" }}>
                                                {row?.date}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className="flex justify-end mt-4 mr-4">
                <button
                    type="button"
                    className="submit-button"
                    onClick={handleSubmit}
                >
                    <span className="submit-button-label">Save</span>
                </button>
            </div>

            {/* <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={list?.length ? list.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
        </Paper>
    );
}
