import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { GET_API, POST_API } from "../../../Services/api";
import Validation from "../../../Validation/Validation";
import Spinner from "../../Spinner";
import View from "../../../Assets/Icons/View.svg";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import "alertifyjs/build/css/alertify.css";

function CreateClient() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [errors, seterrors] = useState({});
    const [isBtnDisable, setIsBtnDisable] = useState(false)

    const [clientData, setClientData] = useState({
        name: "",
        email: "",
        phone: "",
        location:"",
        address: "",
        contact_person_name: "",
        salary_day: "",
        salary_calculation: "",
    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setClientData(() => ({ ...clientData, [name]: value }));
    };

    const handleSalaryCalculation = (e, name) => {
        const { value } = e
        setClientData({ ...clientData, [name]: value })
    }

    const handleValidation = async (e) => {
        e.preventDefault();
        const isValid = Validation.validateClientBasicInfo(clientData);
        if (isValid && isValid.error) {
            seterrors({
                [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
            });
            return;
        } else {
            seterrors({});
            try {
              console.log("clientData", clientData);
                setIsBtnDisable(true);
                const API_URL = id ? `/updateClient/${id}` : "/addClients"
                const basicInfo = await POST_API(API_URL, clientData);
                if (basicInfo?.data?.status === 200) {
                    setIsBtnDisable(false)
                    navigate("/masters/client/list");
                }
            } catch (error) {
                setIsBtnDisable(true);
            }
        }
    };

    useEffect(() => {
        if (id) {
            getByClient();
        }
    }, [id])

    const getByClient = async () => {
        try {
            setIsLoading(true);
            const response = await GET_API(`getClientListbyId/${id}`);
            if (response?.status === 200) {
                setClientData(response?.data?.clientList[0]);
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    }

    const resetForm = () => {
        setClientData({
            name: "",
            email: "",
            location:"",
            phone: "",
            address: "",
            contact_person_name: "",
            salary_day: "",
            salary_calculation: ""
        });
        seterrors({});
    };

    const salaryOption = [
        { label: "Select...", value: "" },
        { label: "Full Calendar Days", value: "fulldays" },
        { label: "Based on Week Off", value: "weekoff" },
        { label: "26 Days", value: "26days" },
    ]

    const salaryDaysOption = [
        { label: "Select...", value: "" },
        { label: "Full Month (1st to last day)", value: "full_month" },
        { label: "Mid Month (21st to 20th)", value: "21_to_20" },
        { label: "Mid Month (26th to 25th)", value: "26_to_25" },
    ]

    if (isLoading) {
        return <Spinner />;
    }

    return (
        <div className="bg-[#F7F6F4] grow">
            <div className="flex flex-col justify-between h-full">
                <div className="sm-container">
                    <h1 className="heading-text">Client</h1>
                    <div className="card-wrapper">
                        <h4 className="card-header">{id ? "Edit" : "Add"} Client</h4>
                        <div className="add-block">
                            <Link to="/masters/client/list" className="view-list-button">
                                <span>View List</span>
                                <img src={View} alt="add" />
                            </Link>
                        </div>
                        <form>
                            <div className="p-2 grid grid-cols-3 gap-5">
                                <div className="field-block">
                                    <label className="label-name">
                                        Name
                                        <span className="required-symbol">*</span>
                                    </label>
                                    <input
                                        className="input-field "
                                        type="text"
                                        onChange={(e) => handleChange(e)}
                                        name="name"
                                        placeholder="Enter Employee Name"
                                        value={clientData?.name}
                                        autoComplete="off"
                                    />
                                    {errors?.name ? (
                                        <div className="validation-error-label" key={errors?.name}>
                                            {errors?.name}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="field-block">
                                    <label className="label-name">
                                        Mobile Number
                                        <span className="font-bold" style={{ color: "#FF5C42" }}>
                                            *
                                        </span>
                                    </label>
                                    <input
                                        className="input-field"
                                        type="number"
                                        autoComplete="off"
                                        onChange={(e) => handleChange(e)}
                                        name="phone"
                                        placeholder="Enter Mobile Number"
                                        value={clientData?.phone}
                                    />
                                    {errors.phone ? (
                                        <div className="text-[#FF5C42] text-sm font-medium font-karla">
                                            {errors.phone}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="field-block">
                                    <label className="label-name">Email Id</label>
                                    <input
                                        className="input-field"
                                        type="text"
                                        autoComplete="off"
                                        onChange={(e) => handleChange(e)}
                                        name="email"
                                        placeholder="Enter Email ID"
                                        value={clientData?.email}
                                    />
                                    {errors.email ? (
                                        <div className="text-[#FF5C42] text-sm font-medium font-karla">
                                            {errors.email}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="field-block">
                                    <label className="label-name">
                                        Location
                                        <span className="required-symbol">*</span>
                                    </label>
                                    <input
                                        className="input-field "
                                        type="text"
                                        onChange={(e) => handleChange(e)}
                                        name="location"
                                        placeholder="Enter Employee Name"
                                        value={clientData?.location}
                                        autoComplete="off"
                                    />
                                    {errors?.location ? (
                                        <div className="validation-error-label" key={errors?.location}>
                                            {errors?.location}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="field-block">
                                    <label className="label-name">Contact Person Name<span className="required-symbol">*</span></label>
                                    <input
                                        name="contact_person_name"
                                        className="input-field"
                                        type="text"
                                        onChange={(e) => handleChange(e)}
                                        placeholder="Enter Father Name"
                                        autoComplete="off"
                                        value={clientData?.contact_person_name}
                                    />
                                    {errors.contact_person_name ? (
                                        <div className="validation-error-label">{errors.contact_person_name}</div>
                                    ) : null}
                                </div>
                                <div className="field-block">
                                    <label className="label-name">Salary Day<span className="required-symbol">*</span></label>
                                    <Select
                                        id="salary_day"
                                        options={salaryDaysOption}
                                        onChange={(e) => handleSalaryCalculation(e, "salary_day")}
                                        name="salary_day"
                                        value={salaryDaysOption?.find(item => item?.value === clientData?.salary_day)}
                                        isSearchable
                                    />
                                    {errors.salary_day ? (
                                        <div className="validation-error-label">{errors.salary_day}</div>
                                    ) : null}
                                </div>
                                <div className="field-block">
                                    <label className="label-name">Salary Calculation<span className="required-symbol">*</span></label>
                                    <Select
                                        id="salary_calc"
                                        options={salaryOption}
                                        onChange={(e) => handleSalaryCalculation(e, "salary_calc")}
                                        name="salary_calc"
                                        value={salaryOption?.find(item => item?.value === clientData?.salary_calc)}
                                        isSearchable
                                    />
                                    {errors.salary_calc ? (
                                        <div className="validation-error-label">{errors.salary_calc}</div>
                                    ) : null}
                                </div>
                               
                                <div className="field-block">
                                    <label className="label-name">
                                        Address
                                        <span className="required-symbol">*</span>
                                    </label>
                                    <textarea
                                        className="input-field "
                                        type="text"
                                        autoComplete="off"
                                        placeholder="Enter Address"
                                        onChange={(e) => {
                                            handleChange(e);
                                        }}
                                        name="address"
                                        value={clientData?.address}
                                        rows={5}
                                    />
                                    {errors?.address ? (
                                        <div className="validation-error-label" key={errors?.address}>
                                            {errors?.address}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </form>
                        <div className="submit-reset-button">
                            <button type="button" className="reset-button" onClick={resetForm}>
                                <span className="reset-button-label">Reset</span>
                            </button>
                            <button disabled={isBtnDisable} type="submit"
                                onClick={(e) => {
                                    handleValidation(e);
                                }} className="submit-button">
                                <span className="submit-button-label">{id ? "Save" : "Submit"}</span>
                                <img src={ColorAdd} alt="" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateClient;




// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import Box from "@mui/material/Box";
// import Tab from "@mui/material/Tab";
// import TabContext from "@mui/lab/TabContext";
// import TabList from "@mui/lab/TabList";
// import TabPanel from "@mui/lab/TabPanel";
// import { POST_API } from "../../../Services/api";
// import ClientBasicInfoComp from "./Tabs/BasicInfo";
// import AllowanceInfo from "./Tabs/AllowanceInfo";
// import DeductionInfo from "./Tabs/DeductionInfo";
// import "alertifyjs/build/css/alertify.css";

// function CreatyeClient() {
//   const navigate = useNavigate();
//   const [value, setValue] = useState("basic_info");
//   const [isBtnDisable, setIsBtnDisable] = useState(false)

//   const [empData, setEmpData] = useState({
//     name: "",
//     email: "",
//     phone: "",
//     address: "",
//     contact_person_name: ""
//   })

//   const handleTabChanges = (value) => {
//     setValue(value);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setIsBtnDisable(true)
//     try {
//       const basicInfo = await POST_API(empData?.rejoin_employee ? "addEmployeeTentacles" : "/addEmployeeTentaclespage", empData);
//       if (basicInfo?.data?.status === 200) {
//         setIsBtnDisable(false)
//         navigate(`/employee/edit/${basicInfo?.data?.id}`, {
//           state: {
//             personalInfo: true
//           },
//         });
//       }
//     } catch (error) {
//       console.log("error");
//       setIsBtnDisable(false)
//     }
//   };

//   return (
//     <div className="bg-[#F7F6F4] grow">
//       <div className="flex flex-col justify-between h-full">
//         <div className="sm-container">
//           <h1 className="heading-text">Client</h1>
//           <div className="card-wrapper">
//             <h4 className="card-header">Add Client</h4>
           
//             <Box sx={{ width: "100%" }}>
//               <TabContext value={value}>
//                 <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
//                   <TabList aria-label="lab API tabs example">
//                     <Tab
//                       style={{ fontFamily: "Montserrat", cursor: "text" }}
//                       value="basic_info"
//                       icon={
//                         <div
//                           style={{
//                             display: "flex",
//                             gap: "4px",
//                             alignItems: "center",
//                           }}
//                         >
//                           <span
//                             style={{
//                               height: "24px",
//                               width: "24px",
//                               borderRadius: "14px",
//                               display: "flex",
//                               justifyContent: "center",
//                               alignItems: "center",
//                               border: "1px solid #C1314C",
//                               fontFamily: "Montserrat",
//                               backgroundColor:
//                                 value === "basic_info" ? "#C1314C" : "#fff",
//                               color:
//                                 value === "basic_info" ? "#fff" : "#271E0D",
//                             }}
//                           >
//                             1
//                           </span>
//                           <span>Basic Info</span>
//                         </div>
//                       }
//                     />
//                     <Tab
//                       style={{ fontFamily: "Montserrat", cursor: "text" }}
//                       value="salary_info"
//                       icon={
//                         <div
//                           style={{
//                             display: "flex",
//                             gap: "4px",
//                             alignItems: "center",
//                           }}
//                         >
//                           <span
//                             style={{
//                               height: "24px",
//                               width: "24px",
//                               borderRadius: "14px",
//                               display: "flex",
//                               justifyContent: "center",
//                               alignItems: "center",
//                               border: "1px solid #C1314C",
//                               fontFamily: "Montserrat",
//                               backgroundColor:
//                                 value === "salary_info" ? "#C1314C" : "#fff",
//                               color:
//                                 value === "salary_info" ? "#fff" : "#271E0D",
//                             }}
//                           >
//                             2
//                           </span>
//                           <span>Allowance Info</span>
//                         </div>
//                       }
//                     />
//                     <Tab
//                       style={{ fontFamily: "Montserrat", cursor: "text" }}
//                       value="salary_info"
//                       icon={
//                         <div
//                           style={{
//                             display: "flex",
//                             gap: "4px",
//                             alignItems: "center",
//                           }}
//                         >
//                           <span
//                             style={{
//                               height: "24px",
//                               width: "24px",
//                               borderRadius: "14px",
//                               display: "flex",
//                               justifyContent: "center",
//                               alignItems: "center",
//                               border: "1px solid #C1314C",
//                               fontFamily: "Montserrat",
//                               backgroundColor:
//                                 value === "salary_info" ? "#C1314C" : "#fff",
//                               color:
//                                 value === "salary_info" ? "#fff" : "#271E0D",
//                             }}
//                           >
//                             3
//                           </span>
//                           <span>Deduction Info</span>
//                         </div>
//                       }
//                     />
//                   </TabList>
//                 </Box>
//                 <TabPanel value="basic_info" key="basic_info">
//                   <ClientBasicInfoComp
//                     empDetails={empData}
//                     stateChanger={setEmpData}
//                     nextTab={handleTabChanges}
//                     submitEmployeeDetails={(e) => handleSubmit(e)}
//                     isBtnDisable={isBtnDisable}
//                   />
//                 </TabPanel>
//                 <TabPanel value="alloewance_info">
//                   <AllowanceInfo
//                     empDetails={empData}
//                     stateChanger={setEmpData}
//                     nextTab={handleTabChanges}
//                     submitEmployeeDetails={(e) => handleSubmit(e)}
//                     isBtnDisable={isBtnDisable}
//                   />
//                 </TabPanel>
//                 <TabPanel value="deduction_info">
//                   <DeductionInfo
//                     empDetails={empData}
//                     stateChanger={setEmpData}
//                     nextTab={handleTabChanges}
//                     submitEmployeeDetails={(e) => handleSubmit(e)}
//                     isBtnDisable={isBtnDisable}
//                   />
//                 </TabPanel>
//               </TabContext>
//             </Box>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default CreatyeClient;
