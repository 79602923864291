import React from "react";
import { Route } from "react-router-dom";

import Attendance from "../Attendance/AttendanceList";
import CreateAttendance from "../Attendance/AttendanceDetails";

import ManualAttendance from "../Attendance/ManualAttendance/ManualAttendanceList";
import CreateManualAttendance from "../Attendance/ManualAttendance/CreateManualAttendance";
import ManualAttendanceDetails from "../Attendance/ManualAttendance/ManualAttendanceDetails";

import LeaveOnDuty from "../Attendance/LeaveOndutyList";
import CreateLeaveOnDuty from "../Attendance/LeaveOndutyAdd";
import LeaveOnDutyDetails from "../Attendance/LeaveOndutyDetails";

import FineDetails from "../Attendance/FineDetails";
import FineCreate from "../Attendance/FineCreate";

function AttendanceMastersRoutes() {
  return (
    <>
      {/****Attendance Routes */}
      <Route path="attendance" element={<Attendance />}></Route>
      <Route
        path="attendance/details/:id"
        element={<CreateAttendance />}
      ></Route>

      {/****Manual Attendance Routes */}
      <Route path="manualattendance" element={<ManualAttendance />}></Route>
      <Route
        path="manualattendance/add"
        element={<CreateManualAttendance />}
      ></Route>
      <Route
        path="manualattendance/details/:id"
        element={<ManualAttendanceDetails />}
      ></Route>

      {/****Leave & OD Routes */}
      <Route path="leaveonduty" element={<LeaveOnDuty />}></Route>
      <Route path="leaveonduty/add" element={<CreateLeaveOnDuty />}></Route>
      <Route
        path="leaveonduty/details/:id"
        element={<LeaveOnDutyDetails />}
      ></Route>

      {/****Fine */}
      <Route path="finedetails" element={<FineDetails />}></Route>
      <Route path="fine/add" element={<FineCreate />}></Route>
    </>
  );
}

export default AttendanceMastersRoutes;
