import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import alertify from "alertifyjs";
import VerifiedIcon from '@mui/icons-material/Verified';
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { GET_API, POST_API } from "../../../Services/api";
import Spinner from "../../Spinner";
import config from "../../../Services/config.json";
import BasicInfo from "../DetailsPage/BasicInfo";
import PersonalInfo from "../DetailsPage/PersonalInfo";
import PositionInfo from "../DetailsPage/PositionInfo";
import EducationalInfo from "../DetailsPage/EducationalInfo";
import BankInfo from "../DetailsPage/BankInfo";
import View from "../../../Assets/Icons/View.svg";
import { ReactComponent as Checkmark } from "../../../Assets/Icons/Checkmark.svg";
import "alertifyjs/build/css/alertify.css";

function SingleEmployeeCertificate() {
  const { id } = useParams();
  const imgPath = config?.path;
  const navigate = useNavigate();
  const [employeeList, setEmployeeList] = useState({});
  const [listDetails, setListDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState('');
  const [isBtnDisable, setIsBtnDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [value, setValue] = useState("basic_info");

  const handleTabChange = (value) => {
    setValue(value);
  };

  const handleTabHeaderChange = (dummy, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    getEmployeeList();
  }, []);

  const getEmployeeList = async () => {
    setLoading(true);
    try {
      const employeeList = await GET_API(`/documentVerficationList/${id}`);
      if (employeeList.status === 200) {
        setEmployeeList(employeeList?.data?.data[0]);
        setLoading(false);
      }
    } catch (e) {
      alertify.error("Failed to get Employee List");
      setEmployeeList([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getEmployeeData();
    }
  }, [id]);

  const getEmployeeData = async () => {
    try {
      setIsLoading(true)
      const employee = await GET_API(`/getEmployeeTentaclesById/${id}`);
      const list = await GET_API(`/getEmployeeagainstDetails/${id}`);
      const empList = employee?.data?.data[0]
      const listData = list?.data
      await setListDetails({
        ...empList,
        id: id,
        workExperience: listData?.work_exprience_tentacles,
        familyDetails: listData?.family_details_tentacles,
        qualification_details: listData?.education_details_tentacles,
      })
      setIsLoading(false)
    } catch (error) {
      setListDetails([])
      setIsLoading(false)
    }
  };

  const handleVerify = async () => {
    const documents = {
      'school_tc': 1,
      'sslc_certificate': 1,
      'hsc_certificate': 1,
      'degree_certificate': 1,
      'driving_license': 1,
      'police_verification_image': 1,
      'is_active': 1,
    }
    setIsBtnDisable(true)
    try {
      const docVerify = await POST_API(`/updateDocumentsVerification/${id}`, documents);
      if (docVerify?.data?.status === 200) {
        setIsBtnDisable(false)
        alertify.success(docVerify?.data?.message);
        navigate("/employee/verify/certificate");
      }
    } catch (error) {
      alertify.success(error?.response?.data?.message);
      setIsBtnDisable(false)
    }
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className=" sm-container">
        <h1 className="heading-text">Employee</h1>
        <div className="card-wrapper">
          {/* <div className="add-block">
            <button className="submit-button" onClick={handleVerify} disabled={isBtnDisable}>
              <span className="submit-button-label">Verify</span>
              <VerifiedIcon style={{color:"white"}}/>
            </button>
            <Link to="/employee/verify/certificate" className="view-list-button">
              <span>View List</span>
              <img src={View} alt="add" />
            </Link>
          </div> */}
          <div>
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList aria-label="lab API tabs example" onChange={handleTabHeaderChange}>
                    <Tab
                      style={{ fontFamily: "Montserrat" }}
                      value="basic_info"
                      icon={
                        <div
                          style={{
                            display: "flex",
                            gap: "4px",
                            alignItems: "center",
                          }}
                        >
                          <span>
                            <Checkmark />
                          </span>
                          <span>Basic Info</span>
                        </div>
                      }
                    />
                    <Tab
                      style={{ fontFamily: "Montserrat" }}
                      value="personal_info"
                      icon={
                        <div
                          style={{
                            display: "flex",
                            gap: "4px",
                            alignItems: "center",
                          }}
                        >
                          <span>
                            <Checkmark />
                          </span>
                          <span>Personal Info</span>
                        </div>
                      }
                    />
                    <Tab
                      style={{ fontFamily: "Montserrat" }}
                      value="position_info"
                      icon={
                        <div
                          style={{
                            display: "flex",
                            gap: "4px",
                            alignItems: "center",
                          }}
                        >
                          <span>
                            <Checkmark />
                          </span>
                          <span>Position Info</span>
                        </div>
                      }
                    />
                    <Tab
                      style={{ fontFamily: "Montserrat" }}
                      value="education_info"
                      icon={
                        <div
                          style={{
                            display: "flex",
                            gap: "4px",
                            alignItems: "center",
                          }}
                        >
                          <span>
                            <Checkmark />
                          </span>
                          <span>Education Info</span>
                        </div>
                      }
                    />
                    <Tab
                      style={{ fontFamily: "Montserrat" }}
                      value="bank_info"
                      icon={
                        <div
                          style={{
                            display: "flex",
                            gap: "4px",
                            alignItems: "center",
                          }}
                        >
                          <span>
                            <Checkmark />
                          </span>
                          <span>Bank Info</span>
                        </div>
                      }
                    />
                  </TabList>
                </Box>
                <TabPanel value="basic_info" key="basic_info">
                  <BasicInfo
                    listDetails={listDetails}
                    isEditAndView={true}
                    nextTab={handleTabChange}
                    handleVerify={handleVerify}
                    viewList="/employee/verify/certificate"
                    isDownload={false}
                    isEdit={true}
                  />
                </TabPanel>
                <TabPanel value="personal_info">
                  <PersonalInfo
                    listDetails={listDetails}
                    isEditAndView={true}
                    nextTab={handleTabChange}
                    handleVerify={handleVerify}
                    viewList="/employee/verify/certificate"
                    isDownload={false}
                    isEdit={true}
                  />
                </TabPanel>
                <TabPanel value="position_info">
                  <PositionInfo
                    listDetails={listDetails}
                    isEditAndView={true}
                    nextTab={handleTabChange}
                    handleVerify={handleVerify}
                    viewList="/employee/verify/certificate"
                    isDownload={false}
                    isEdit={true}
                  />
                </TabPanel>
                <TabPanel value="education_info">
                  <EducationalInfo
                    listDetails={listDetails}
                    isEditAndView={true}
                    nextTab={handleTabChange}
                    handleVerify={handleVerify}
                    viewList="/employee/verify/certificate"
                    isDownload={false}
                    isEdit={true}
                  />
                </TabPanel>
                <TabPanel value="bank_info">
                  <BankInfo
                    listDetails={listDetails}
                    isEditAndView={true}
                    nextTab={handleTabChange}
                    handleVerify={handleVerify}
                    viewList="/employee/verify/certificate"
                    isDownload={false}
                    isEdit={true}
                  />
                </TabPanel>
              </TabContext>
            </Box>
          </div>

        </div>
      </div>
    </div>
  );
}

export default SingleEmployeeCertificate;




// import React, { useState, useEffect } from "react";
// import { Link, useNavigate, useParams } from "react-router-dom";
// import alertify from "alertifyjs";
// import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
// import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
// import { GET_API, POST_API } from "../../../Services/api";
// import Spinner from "../../Spinner";
// import config from "../../../Services/config.json";
// import ImageIcon from "../../../Assets/Icons/upload.svg";
// import PdfIcon from "../../../Assets/Icons/pdfIcon.png";
// import ImageView from "../Components/ImageView";
// import View from "../../../Assets/Icons/View.svg";
// import "alertifyjs/build/css/alertify.css";

// function SingleEmployeeCertificate() {
//   const { id } = useParams();
//   const imgPath = config?.path;
//   const navigate = useNavigate();
//   const [employeeList, setEmployeeList] = useState({});
//   const [loading, setLoading] = useState(false);
//   const [isOpen, setIsOpen] = useState(false);
//   const [imageSrc, setImageSrc] = useState('');
//   const [isBtnDisable, setIsBtnDisable] = useState(false);

//   const handleModal = (src) => {
//     setIsOpen(!isOpen)
//     setImageSrc(src)
//   }

//   const basicDocument = [
//     {
//       label: "School Tc",
//       value: employeeList?.school_tc ? imgPath + employeeList?.school_tc : "",
//     },
//     {
//       label: "SSLC Certificate",
//       value: employeeList?.sslc_Certificate ? imgPath + employeeList?.sslc_Certificate : "",
//     },
//     {
//       label: "HSC Certificate",
//       value: employeeList?.hsc_certificate ? imgPath + employeeList?.hsc_certificate : "",
//     },
//     {
//       label: "Degree Certificate",
//       value: employeeList?.degree_certificate ? imgPath + employeeList?.degree_certificate : "",
//     },
//     {
//       label: "Driving License",
//       value: employeeList?.driving_license_image_upload ? imgPath + employeeList?.driving_license_image_upload : "",
//     },
//     {
//       label: "Police Verification Certificate",
//       value: employeeList?.police_verification_certificate_image ? imgPath + employeeList?.police_verification_certificate_image : "",
//     },
//   ]

//   useEffect(() => {
//     getEmployeeList();
//   }, []);

//   const getEmployeeList = async () => {
//     setLoading(true);
//     try {
//       const employeeList = await GET_API(`/documentVerficationList/${id}`);
//       if (employeeList.status === 200) {
//         setEmployeeList(employeeList?.data?.data[0]);
//         setLoading(false);
//       }
//     } catch (e) {
//       alertify.error("Failed to get Employee List");
//       setEmployeeList([]);
//       setLoading(false);
//     }
//   };

//   const handleVerify = async () => {
//     const documents = {
//       'school_tc': 1,
//       'sslc_certificate': 1,
//       'hsc_certificate': 1,
//       'degree_certificate': 1,
//       'driving_license': 1,
//       'police_verification_image': 1,
//       'is_active': 1,
//     }
//     setIsBtnDisable(true)
//     try {
//       const docVerify = await POST_API(`/updateDocumentsVerification/${id}`, documents);
//       if (docVerify?.data?.status === 200) {
//         setIsBtnDisable(false)
//         alertify.success(docVerify?.data?.message);
//         navigate("/employee/verify/certificate");
//       }
//     } catch (error) {
//       alertify.success(error?.response?.data?.message);
//       setIsBtnDisable(false)
//     }
//   }

//   const fileType = (value) => {
//     return value.endsWith(".pdf") ? PdfIcon : value
//   }

//   if (loading) {
//     return <Spinner />;
//   }

//   return (
//     <div className=" bg-[#F7F6F4] grow">
//       <div className=" sm-container">
//         <h1 className="heading-text">Employee</h1>
//         <div className="card-wrapper">
//           <div className="add-block">
//             <button className="submit-button" onClick={handleVerify} disabled={isBtnDisable}>
//               <span className="submit-button-label">Verify</span>
//             </button>
//             <Link to="/employee/verify/certificate" className="view-list-button">
//               <span>View List</span>
//               <img src={View} alt="add" />
//             </Link>
//           </div>
//           <div className="list-container border-bottom">
//             <div className="flex w-full">
//               <div className="">
//                 {/* <label className="list-label">Profile Picture</label> */}
//                 <div className="w-40" >
//                   <img style={{ height: "156px", width: "156px" }} src={imgPath + employeeList?.profile_picture} />
//                 </div>
//               </div>
//             </div>
//             <div className="border-bottom py-4">
//               <div className="flex-row gap-x-2.5 flex">
//                 <label className="list-label">Employee Name: <span className="list-value">{employeeList?.name}</span></label>
//                 <label className="list-label">Employee Code: <span className="list-value">{employeeList?.employee_code}</span></label>
//               </div>
//             </div>
//             <h4 className="detaile-header">Documents</h4>
//             <div className="list-container">
//               <div className="list-content-block">
//                 {basicDocument?.map((list, i) => {
//                   return (
//                     <div className="" key={i}>
//                       <label className="list-label">{list?.label}</label>
//                       <div className="flex" >
//                         {/* <img className="h-9 w-9" src={list?.value ? list?.value : ImageIcon} /> */}
//                         <img className="h-9 w-9" src={list?.value ? fileType(list?.value) : ImageIcon} />
//                         {list?.value ?
//                           <>
//                             {list?.value?.endsWith(".pdf") ?
//                               <a href={list?.value} rel="noopener noreferrer" target="_blank">
//                                 <ArrowCircleDownIcon
//                                   className="ml-8 mt-1.5 img-view-icon cursor-pointer"
//                                 /></a> :
//                               <ErrorOutlineIcon
//                                 className="ml-8 mt-1.5 img-view-icon cursor-pointer"
//                                 onClick={() => handleModal(list?.value)}
//                               />}
//                           </>
//                           :
//                           <ErrorOutlineIcon
//                             className="ml-8 mt-1.5 img-view-icon"
//                           />}
//                       </div>
//                     </div>
//                   )
//                 })}
//               </div>
//             </div>
//             <ImageView
//               isOpen={isOpen}
//               handleModal={handleModal}
//               imgSrc={imageSrc}
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default SingleEmployeeCertificate;
