import React, {useState} from "react";
import Support from "../../Assets/Icons/Support.svg";
import Setting from "../../Assets/Icons/Setting.svg";
import Remainder from "../../Assets/Icons/Remainder.svg";
import Logo from "../../Assets/Logo/Logo.png";
import { Link, useNavigate } from "react-router-dom";
import SettingsCard from "./Settings/SettingsCard";
import UseOutsideClick from "../OutsideClick";

function Header() {
  const navigate = useNavigate();
  const [isSetting, setIsSetting] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user_id");
    localStorage.removeItem("user_role");
    navigate("/login");
  };

  const actionModel = UseOutsideClick(() => {
    setIsSetting(false);
  });

  return (
    <div className="pt-[12px] pb-[12px] pl-[24px] pr-[24px] flex justify-between items-center border border-primary h-[66px]">
      <div>
        <div className="flex items-center gap-4">
          <Link to="/dashboard">
            <img
              src={Logo}
              alt=""
              className="h-[40px] w-[40px]"
             
            />
          </Link>
          <span className="text-special-text-color font-bold font-montserrat text-base">
            Tentacle Secure Squads
          </span>
        </div>
      </div>
      <div className="flex gap-6">
        {/* <span
          className="text-text-dark font-karla cursor-pointer"
          onClick={() => handleLogout()}
        >
          Logout
        </span>
        <div className="flex justify-center items-center">
          <label className="switch">
            <input type="checkbox" />
            <span className="slider round"></span>
          </label>
        </div>
        <div className="flex justify-center items-center">
          <img src={Support} alt="" />
        </div> */}
        {/* <div className="flex justify-center items-center">
          <img src={Setting} alt="" />
        </div> */}
        <div
          className={`flex justify-center items-center cursor-pointer relative`}
          onClick={() => {
            setIsSetting((current) => !current);
          }}
        >
          <img
            src={Setting}
            alt=""
            className={`${
              isSetting ? "duration-500 rotate-45 " : "duration-500 rotate-0"
            }`}
          />
          <div ref={actionModel}>{isSetting && <SettingsCard />}</div>
        </div>
        {/* <div className="flex justify-center items-center">
          <img src={Remainder} alt="" />
        </div> */}
      </div>
    </div>
  );
}
export default Header;
