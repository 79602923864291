import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import Select from "react-select";
import { DELETE_API, GET_API, POST_API } from "../../Services/api";
import EmployementList from "../DataTable/EmployementList";
import Spinner from "../Spinner";
import Terminate from "../Employee/Terminate";
import { downloadEmployee } from "./Components/DownloadPdf";
import Search from "../../Assets/Icons/Search.svg";
import ColorAdd from "../../Assets/Icons/ColorAdd.svg";
import More from "../../Assets/Icons/More.svg";
import Filter from "../../Assets/Icons/Filter.svg";
import EmployeeBulkUpload from "./Components/BulkUploadModal";
import "alertifyjs/build/css/alertify.css";

function AllEmployeeList() {
  const userId = localStorage?.getItem("user_id");
  const navigate = useNavigate();
  const [isModal, setIsModal] = useState(false);  // Employee Bulk upload
  const [employeeList, setEmployeeList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [employeeOption, setEmployeeOption] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [categoryList, setcategoryList] = useState([]);
  const [department, setDepartment] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isCustomDialogOpen, setCustomDialogOpen] = useState(false);
  const [terminateEmployeeId, setTerminateEmployeeId] = useState(0);
  const [filter, setFilter] = useState(false);
  const [filterParams, setFilterParams] = useState("");
  const [showFilterResultData, setShowFilterResultData] = useState("");
  const [filterList, setfilterList] = useState({
    employee_id: "",
    client_id: "",
    category_id: "",
    branch_id: "",
    emp_type_id: "",
    gender: "",
    department_id: "",
    // designation_id: "",
    from_date: "",
  });

  const header = [
    { label: "S.no", value: "s.no" },
    { label: "Profile Picture", value: "profile_picture" },
    { label: "Employee Name", value: "name" },
    { label: "Employee Code", value: "employee_code" },
    { label: "Department Name", value: "departmentname" },
    { label: "Mobile Number", value: "mobile_number" },
    { label: "Created at", value: "create_at" },
    { label: "Payslip Enable/Disable", value: "payslip_mode" },
    { label: "Action", value: "" },
  ];

  const handleModal = () => {
    setIsModal(!isModal);
  };

  const viewRemarksModal = (listDetail) => {
    navigate(`/employee/remarks/${listDetail?.employee_id}`);
  };

  useEffect(() => {
    getEmployeeOptionList();
    getBranchList();
    getClientList();
    getDesignationList();
    getDepartmentList();
  }, []);

  useEffect(() => {
    getEmployeeList(filterParams);
  }, [showFilterResultData]);

  const getEmployeeList = async (filters) => {
    setLoading(true);
    try {
      const employeeList = await GET_API(`/ListEmployeeTentacles?login=${userId}${filters}`);
      if (employeeList.status === 200) {
        setLoading(false);
        setEmployeeList(employeeList?.data?.data);
        setFilteredList(employeeList?.data?.data);
      }
    } catch (e) {
      alertify.error("Failed to get Employee List");
      setLoading(false);
      setEmployeeList([]);
      setFilteredList([]);
    }
  };

  const getEmployeeOptionList = async () => {
    setLoading(true);
    try {
      const employeeList = await GET_API(`/ListEmployeeTentacles?login=${userId}`);
      if (employeeList.status === 200) {
        setLoading(false);
        const empList = employeeList?.data?.data.map((item, i) => {
          return { value: item?.employee_id, label: `${item?.name} - ${item?.employee_code}` };
        });
        empList.unshift({ value: "", label: "Select..." });
        setEmployeeOption(empList);
      }
    } catch (e) {
      alertify.error("Failed to get Employee List");
      setEmployeeOption([])
    }
  };

  const getBranchList = async () => {
    setLoading(true);
    try {
      const branchList = await GET_API("/branchList");
      if (branchList.status === 200) {
        const branch = branchList?.data?.data.map((item, i) => {
          return { value: item?.id, label: item?.name };
        });
        branch.unshift({ value: "", label: "Select..." });
        setBranchList(branch);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Branch List");
    }
  };

  const getClientList = async () => {
    setLoading(true);
    try {
      const cliList = await GET_API("/clientLists");
      if (cliList.status === 200) {
        const client = cliList?.data?.data.map((item, i) => {
          return { value: item?.id, label: item?.name };
        });
        client.unshift({ value: "", label: "Select..." });
        setClientList(client);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Client List");
    }
  };

  const getDesignationList = async () => {
    setLoading(true);
    try {
      const designation = await GET_API("/CategoryList");
      if (designation.status === 200) {
        const category = designation?.data?.data.map((item, i) => {
          return { value: item?.id, label: item?.name };
        });
        category.unshift({ value: "", label: "Select..." });
        setDesignation(category);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      setDesignation([])
      alertify.error("Failed to Get Designation List");
    }
  };

  const getDepartmentList = async () => {
    setLoading(true);
    try {
      const departmentList = await GET_API("/DepartmentList");
      if (departmentList.status === 200) {
        const dep = departmentList?.data?.data.map((item, i) => {
          return { value: item?.id, label: item?.name };
        });
        dep.unshift({ value: "", label: "Select..." });
        setDepartment(dep);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Department List");
    }
  };

  const handleLoanSearch = (e) => {
    const searchTerm = e.target.value;
    const searchedList = employeeList.filter((item) => {
      for (let key in item) {
        if (
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
    setFilteredList(searchedList);
  };

  // const getDesignationList = async () => {
  //   setLoading(true);
  //   try {
  //     const designationList = await GET_API("/designationList");
  //     if (designationList.status === 200) {
  //       const design = designationList?.data?.data.map((item, i) => {
  //         return { value: item?.id, label: item?.name };
  //       });
  //       design.unshift({ value: "", label: "Select..." });
  //       setDesignation(design);
  //       setLoading(false);
  //     }
  //   } catch (e) {
  //     setLoading(false);
  //     alertify.error("Failed to Get Department List");
  //   }
  // };

  const showBranchDetails = (listDetail) => {
    navigate(`/employee/details/${listDetail?.employee_id}`, {
      state: {
        list: listDetail,
      },
    });
  };

  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Employee..?",
        async function () {
          if (row_id) {
            try {
              const deleteRow = await DELETE_API(`/DeleteEmployeeTentacles/${row_id}`);
              if (deleteRow.status === 200) {
                alertify.success(deleteRow?.data?.message);
                getEmployeeList(filterParams);
                return;
              }
              alertify.error("Failed To Delete Employee");
            } catch (error) {
              console.log("error", error);
              alertify.error("Failed To Delete Employee");
            }

          }
        },
        function () { }
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };

  const navigateEditPage = (listDetail) => {
    navigate(`/employee/edit/${listDetail?.employee_id}`, {
      state: {
        list: listDetail,
        personalInfo: false
      },
    });
  };

  const handleCloseCustomDialog = () => {
    setCustomDialogOpen(false);
  };

  const terminateEmployee = (employeeId) => {
    setCustomDialogOpen(true);
    setTerminateEmployeeId(employeeId?.employee_id);
  };

  const genderList = [
    { label: "Select...", value: "" },
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
  ];

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const handleFilter = async (e, name, type) => {
    const value = type === "date" ? e?.target?.value : e?.value;
    filterList[name] = value;
    const list = { ...filterList };
    Object.keys(list).forEach((key) => {
      if (list[key] === "") {
        delete list[key];
      }
    });
    let concateURL = "";
    // let isFirst = true;
    if (isEmptyObject(list)) {
      setFilterParams(concateURL);
      setfilterList({ ...filterList, [name]: value });
    }

    Object.keys(list).forEach((key) => {
      const newURL = `${key}=${list[key]}`;
      concateURL += "&" + newURL;
      // if (isFirst) {
      //   concateURL += "?" + newURL;
      //   isFirst = false;
      // } else {
      // concateURL += "&" + newURL;
      // }
      setFilterParams(concateURL);
    });
  };

  const showFilterResult = (e) => {
    e?.preventDefault();
    setShowFilterResultData(filterParams);
  };

  const disablePayslip = async (e, empId) => {
    const { checked } = e?.target;
    alertify
      .confirm(
        "Confirm",
        "Confirm to Disable Payslips..?",
        async function () {
          if (empId) {
            try {
              const response = await POST_API(`/enablePayslip/${empId}`, { payslip_mode: checked });
              if (response?.status === 200) {
                alertify.success(response?.data?.message);
                getEmployeeList(filterParams);
              }
            } catch (error) {
              alertify.error("Failed To Disable Payslips");
            }
          }
        },
        function () { }
      )
      .set("movable", false)
      .set("labels", { ok: "Disable!", cancel: "Cancel!" });
  }

  const handleDownload = async (listDetail) => {
    await downloadEmployee(listDetail?.employee_id)
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">Employee</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Employee List</h4>
          <div className="search-block">
            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                onChange={(e) => handleLoanSearch(e)}
                style={{ outline: "none", border: "none" }}
              />

              <img src={Search} alt="" />
            </div>
            <div className="flex gap-3">
              <div className="filter-button" onClick={() => setFilter((prev) => !prev)}>
                <span className="filter-btn-label">Filter</span>
                <img src={Filter} alt="" />
              </div>

              <div className="add-new-button-block">
                <button className="add-new-button" onClick={handleModal}>
                  <span>Bulk Upload</span>
                  <img src={ColorAdd} alt="add" />
                </button>
              </div>
              <div className="add-new-button-block">
                <Link to="/employee/add" className="add-new-button">
                  <span>Add New</span>
                  <img src={ColorAdd} alt="add" />
                </Link>
              </div>
            </div>
          </div>
          {filter && (
            <form className="border-t-[1px] border-t-[#D9D9D9]">
              <div className="form mt-4">
                <div className="field-block dropdown-select-to-one">
                  <label className="label-name">Client</label>
                  <Select
                    options={clientList}
                    value={clientList?.find(
                      (option) => option?.value === filterList.client_id
                    )}
                    onChange={(e) => handleFilter(e, "client_id", "select")}
                    name="client_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-one">
                  <label className="label-name">Designation</label>
                  <Select
                    options={designation}
                    value={designation?.find(
                      (option) => option?.value === filterList.category_id
                    )}
                    onChange={(e) => handleFilter(e, "category_id", "select")}
                    name="category_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-two">
                  <label className="label-name">Employee</label>
                  <Select
                    options={employeeOption}
                    value={employeeOption?.find(
                      (option) => option?.value === filterList.employee_id
                    )}
                    onChange={(e) => handleFilter(e, "employee_id", "select")}
                    name="employee_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-two">
                  <label className="label-name">Branch Name</label>
                  <Select
                    options={branchList}
                    value={branchList?.find(
                      (option) => option?.value === filterList.branch_id
                    )}
                    onChange={(e) => handleFilter(e, "branch_id", "select")}
                    name="branch_id"
                    className=""
                    isSearchable
                  />
                </div>
                {/* <div className="field-block dropdown-select-to-two">
                  <label className="label-name">Employee Type</label>
                  <Select
                    options={employeetype}
                    value={employeetype?.find(
                      (option) => option?.value === filterList.emp_type_id
                    )}
                    onChange={(e) => handleFilter(e, "emp_type_id", "select")}
                    name="emp_type_id"
                    className=""
                    isSearchable
                  />
                </div> */}
                <div className="field-block dropdown-select-to-three">
                  <label className="label-name">
                    Gender
                  </label>
                  <Select
                    options={genderList}
                    value={genderList?.find(
                      (option) => option?.value === filterList.gender
                    )}
                    onChange={(e) => handleFilter(e, "gender", "select")}
                    name="gender"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-three">
                  <label className="label-name">
                    Department
                  </label>
                  <Select
                    options={department}
                    value={department?.find(
                      (option) => option?.value === filterList.department_id
                    )}
                    onChange={(e) => handleFilter(e, "department_id", "select")}
                    name="department_id"
                    className=""
                    isSearchable
                  />
                </div>
                {/* <div className="field-block dropdown-select-to-three">
                  <label className="label-name">
                    Designation
                  </label>
                  <Select
                    options={designation}
                    value={designation?.find(
                      (option) => option?.value === filterList.designation_id
                    )}
                    onChange={(e) => handleFilter(e, "designation_id", "select")}
                    name="designation_id"
                    className=""
                    isSearchable
                  />
                </div> */}
                <div className="field-block">
                  <label className="label-name">
                    Joining Date
                  </label>
                  <input
                    className="input-field "
                    type="date"
                    autoComplete="off"
                    name="from_date"
                    value={filterList?.from_date}
                    onChange={(e) => {
                      handleFilter(e, "from_date", "date");
                    }}
                  />
                </div>
                <div className="field-block flex justify-center">
                  <div className="add-new-button-block w-[40%] h-max ">
                    <button
                      className="add-new-button"
                      onClick={(e) => {
                        showFilterResult(e);
                      }}
                    >
                      <span style={{ cursor: "pointer" }}>Show Result</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}

          <EmployementList
            list={filteredList}
            multiple={true}
            showDetails={showBranchDetails}
            deleteRow={handleDelete}
            editPage={navigateEditPage}
            icon={More}
            columns={header}
            download={handleDownload}
            allowance={true}
            disablePayslip={disablePayslip}
            ShowRemarks={viewRemarksModal}
            termination={true}
            terminateEmployee={terminateEmployee}
          />
        </div>
      </div>
      {/***Employee Terminate */}
      <Terminate
        isOpen={isCustomDialogOpen}
        terminateEmployeeId={terminateEmployeeId}
        onClose={handleCloseCustomDialog}
        filterParams={filterParams}
        getEmployeeList={getEmployeeList}
      />
      <EmployeeBulkUpload
        isModal={isModal}
        handleModal={handleModal}
        clientList={clientList}
        getEmployeeList={getEmployeeList}
      />
    </div>
  );
}

export default AllEmployeeList;
