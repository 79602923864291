import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import EmployeeProfileListTable from "../../DataTable/EmployeeProfileListTable";
import Search from "../../../Assets/Icons/Search.svg";
import Spinner from "../../Spinner";
import { GET_API } from "../../../Services/api";
import More from "../../../Assets/Icons/More.svg";
import "alertifyjs/build/css/alertify.css";

function LeaveApproval() {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false);
    const [employeeList, setEmployeeList] = useState([]);
    const [filteredList, setFilteredList] = useState([]);

    useEffect(() => {
        getEmployeeList();
    }, []);

    const columns = [
        { field: "id", label: "S.No", minWidth: 75 },
        { field: "employee_code", label: "Employee Code", minWidth: 200 },
        { field: "name", label: "Employee Name", minWidth: 200 },
        { field: "profile_picture", label: "Profile Picture", minWidth: 200 },
        { field: "address", label: "Address", minWidth: 200 },
        { field: "", label: "Action", minWidth: 200 },
    ];

    const getEmployeeList = async () => {
        setIsLoading(true);
        try {
            const empList = await GET_API("/getemployeesReview");
            if (empList?.status === 200) {
                setEmployeeList(empList?.data?.data);
                setFilteredList(empList?.data?.data);
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    };

    const handleUserSearch = (e) => {
        const searchTerm = e.target.value;
        const searchedList = employeeList.filter((item) => {
            for (let key in item) {
                if (
                    typeof item[key] === "string" &&
                    item[key].toLowerCase().includes(searchTerm.toLowerCase())
                ) {
                    return true;
                }
            }
            return false;
        });
        setFilteredList(searchedList);
    };

    const handleApproveProfile = (listDetail) => {
        navigate(`/approve/profile/${listDetail?.id}`,
            {
                state: {
                    list: listDetail,
                },
            });
    }

    if (isLoading) {
        return <Spinner />;
    }

    return (
        <div className=" bg-[#F7F6F4] grow">
            <div className="sm-container">
                <h1 className="heading-text">Profile</h1>
                <div className="card-wrapper">
                    <h4 className="card-header">Employee Profile</h4>
                    <div className="search-block">
                        <div className="search">
                            <input
                                type="text"
                                className="search-input-box"
                                placeholder="Search..."
                                autoComplete="off"
                                onChange={(e) => handleUserSearch(e)}
                                style={{ outline: "none", border: "none" }}
                            />
                            <img src={Search} alt="" />
                        </div>
                    </div>
                    {/**************** * Table ********************/}
                    <EmployeeProfileListTable
                        list={filteredList}
                        columns={columns}
                        icon={More}
                        multiple={false}
                        activeEdit={false}
                        activeDelete={false}
                        approveLeave={handleApproveProfile}
                    />
                </div>
            </div>
        </div>
    );
}

export default LeaveApproval;