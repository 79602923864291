import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import View from "../../../Assets/Icons/View.svg";
import Spinner from "../../Spinner";
import { GET_API, POST_API } from "../../../Services/api";
import alertify from "alertifyjs";

function ViewApproval() {
    const navigate = useNavigate();
    const { empId, id } = useParams();
    const [leaveList, setLeaveList] = useState([]);
    const [isLeaveLoading, setIsLeaveLoading] = useState(false);
    const [isButtonDisable, setIsButtonDisable] = useState(false);

    useEffect(() => {
        if (empId || id) {
            getLeaveApprovalList();
        }
    }, [empId, id]);


    const getLeaveApprovalList = async () => {
        try {
            setIsLeaveLoading(true);
            const leaveList = await GET_API(`/checkApproval/${empId}/${id}`);
            if (leaveList?.status === 200) {
                setLeaveList(leaveList?.data?.data[0])
                setIsLeaveLoading(false);
            }
        } catch (error) {
            setIsLeaveLoading(false);
        }
    };

    const approveLeaveRequest = async (id, statusValue) => {
        try {
            setIsButtonDisable(true);
            const approveReject = await POST_API(`/leaveRequestApproval/${id}`, { status: statusValue });
            if (approveReject?.status === 200) {
                setIsLeaveLoading(false);
                setIsButtonDisable(false);
                alertify.success(approveReject?.data?.message);
                navigate('/approve/leave')
            }
        } catch (error) {
            setIsLeaveLoading(false);
            setIsButtonDisable(false);
        }
    };

    if (isLeaveLoading) {
        return <Spinner />;
    }
    return (
        <div className=" bg-[#F7F6F4] grow">
            <div className=" sm-container">
                <h1 className="heading-text">Leave</h1>
                <div className="card-wrapper">
                    <h4 className="card-header">Leave Approval</h4>
                    <div className="add-block">
                        <div className="add-new-button-block" >
                            <button
                                onClick={() => approveLeaveRequest(id, 2)}
                                disabled={isButtonDisable}
                                className="add-new-button" >
                                <span>Reject</span>
                            </button>
                        </div>
                        <div className="add-new-button-block" >
                            <button
                                onClick={() => { approveLeaveRequest(id, 0) }}
                                disabled={isButtonDisable} className="add-new-button"  >
                                <span>Approve</span>
                            </button>
                        </div>

                        <Link to="/approve/leave" className="view-list-button">
                            <span>View List</span>
                            <img src={View} alt="add" />
                        </Link>
                    </div>

                    <div className="list-container border-bottom">
                        <div className="list-content-block">

                            <div className="list ">
                                <label className="list-label">Employee Name</label>
                                <div
                                    style={{ wordBreak: "break-word" }}
                                    className="list-value"
                                >
                                    {leaveList?.employee_name}
                                </div>
                            </div>
                            <div className="list ">
                                <label className="list-label">Employee Code</label>
                                <div
                                    style={{ wordBreak: "break-word" }}
                                    className="list-value"
                                >
                                    {leaveList?.EmployeeCode}
                                </div>
                            </div>
                            <div className="list ">
                                <label className="list-label">No of Day(s)</label>
                                <div
                                    style={{ wordBreak: "break-word" }}
                                    className="list-value"
                                >
                                    {leaveList?.no_of_days}
                                </div>
                            </div>
                            <div className="list ">
                                <label className="list-label">From Date</label>
                                <div
                                    style={{ wordBreak: "break-word" }}
                                    className="list-value"
                                >
                                    {leaveList?.from_date}
                                </div>
                            </div>
                            <div className="list ">
                                <label className="list-label">To Date</label>
                                <div
                                    style={{ wordBreak: "break-word" }}
                                    className="list-value"
                                >
                                    {leaveList?.to_date}
                                </div>
                            </div>
                            <div className="list ">
                                <label className="list-label">Remarks</label>
                                <div
                                    style={{ wordBreak: "break-word" }}
                                    className="list-value"
                                >
                                    {leaveList?.remarks}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ViewApproval;
