import React, { useState, useEffect } from "react";
import More from "../../../Assets/Icons/More.svg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { GET_API, DELETE_API } from "../../../Services/api";
import BreakTimeTable from "../../DataTable/BreakTimeTable";
import Search from "../../../Assets/Icons/Search.svg";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import Spinner from "../../Spinner";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";

function BreakTimeList() {
  const navigate = useNavigate();
  const [breakTimeList, setBreakTimeList] = useState([]);
  const [filteredBranch, setFilteredBranch] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleBranchSearch = (e) => {
    const searchTerm = e.target.value;
    const searchedList = breakTimeList.filter((item) => {
      for (let key in item) {
        if (
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
    setFilteredBranch(searchedList);
  };

  const showBreakTimeDetails = (listDetail) => {
    navigate("/masters/break/details", {
      state: {
        list: listDetail,
      },
    });
  };

  const navigateEditPage = (listDetail) => {
    navigate("/masters/break/edit", {
      state: {
        list: listDetail,
      },
    });
  };

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "name", label: "Break Name" },
    { id: "time", label: "Time" },
    { id: "shift", label: "For Shift" },
    { id: "created_at", label: "Created at" },
    { id: "more_icon", label: "" },
  ];

  const getBreakTimeList = async () => {
    setLoading(true);
    try {
      const breakTimeList = await GET_API("/breakTimeList");
      if (breakTimeList.status === 200) {
        setBreakTimeList(breakTimeList?.data?.data);
        setFilteredBranch(breakTimeList?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alertify.error("Failed to Get Break Time List");
    }
  };
  useEffect(() => {
    getBreakTimeList();
  }, []);

  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Break Time..?",
        async function () {
          if (row_id) {
            try {
              const deleteRow = await DELETE_API(`/deleteShifts/${row_id}`);
              if (deleteRow.status === 200) {
                alertify.success(deleteRow?.data?.message);
                getBreakTimeList();
                return;
              }
            } catch (error) {
              alertify.error("Failed To Delete Break Time");
            }
          }
        },
        function () { }
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">Break</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Break List</h4>
          <div className="search-block">
            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                onChange={(e) => handleBranchSearch(e)}
                style={{ outline: "none", border: "none" }}
              />
              <img src={Search} alt="" />
            </div>
            <div className="flex gap-3">
              <div className="add-new-button-block">
                <Link to="/masters/break/create" className="add-new-button">
                  <span>Add New</span>
                  <img src={ColorAdd} alt="add" />
                </Link>
              </div>
            </div>
          </div>

          {/**************** * Table ********************/}

          <BreakTimeTable
            list={filteredBranch}
            columns={columns}
            icon={More}
            multiple={true}
            showBranchDetails={showBreakTimeDetails}
            showDetails={showBreakTimeDetails}
            editPage={navigateEditPage}
            deleteRow={handleDelete}
          />
        </div>
      </div>
    </div>
  );
}

export default BreakTimeList;
