import React, { useState, useEffect } from "react";
import More from "../../Assets/Icons/More.svg";
import { GET_API, DELETE_API } from "../../Services/api";
import EmploymentTypeTable from "../DataTable/EmploymentTypeTable";
import ColorAdd from "../../Assets/Icons/ColorAdd.svg";
import alertify from "alertifyjs";
import { Link, useNavigate } from "react-router-dom";
import Search from "../../Assets/Icons/Search.svg";
import Spinner from "../Spinner";
import "alertifyjs/build/css/alertify.css";

function EmploymentType() {
  const navigate = useNavigate();
  const [employmentTypeList, setEmploymentTypeList] = useState([]);
  const [filteredTypeList, setFilteredTypeList] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "staffing_name", label: "Staffing Name" },
    { id: "employment_type", label: "Employment Type" },
    { id: "created_at", label: "Created at" },
    { id: "more_icon", label: "" },
  ];

  useEffect(() => {
    getEmploymentTypeList();
  }, []);

  const getEmploymentTypeList = async () => {
    try {
      setLoading(true);
      const employmentTypeList = await GET_API("/employeetype");
      setFilteredTypeList(employmentTypeList?.data?.data);
      setEmploymentTypeList(employmentTypeList?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error?.response?.data?.message);
    }
  };

  const handleBranchSearch = (e) => {
    const searchTerm = e.target.value;
    const searchedList = employmentTypeList.filter((item) => {
      for (let key in item) {
        if (
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
    setFilteredTypeList(searchedList);
  };

  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Employment Type",
        async function () {
          if (row_id) {
            try {
              const deleteRow = await DELETE_API(
                `/deleteEmployeetype/${row_id}`
              );
              if (deleteRow.status === 200) {
                alertify.success(deleteRow?.data?.message);
                getEmploymentTypeList();
                return;
              }
            } catch (error) {
              console.log("error", error);
              alertify.error("Failed To Delete Employment Type List");
            }
          }
        },
        function () { }
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };

  const navigateEditPage = (listDetail) => {
    navigate("/masters/employementType/edit", {
      state: {
        list: listDetail,
      },
    });
  };

  const showBranchDetails = (listDetail) => {
    navigate("/masters/employementType/details", {
      state: {
        list: listDetail,
      },
    });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">Employment Type</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Employment Type List</h4>
          <div className="search-block">
            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                onChange={(e) => handleBranchSearch(e)}
                style={{ outline: "none", border: "none" }}
              />
              <img src={Search} alt="" />
            </div>
            <div className="flex gap-3">
              <div className="add-new-button-block">
                <Link
                  to="/masters/employementType/add"
                  className="add-new-button"
                >
                  <span>Add New</span>
                  <img src={ColorAdd} alt="add" />
                </Link>
              </div>
            </div>
          </div>

          <EmploymentTypeTable
            list={filteredTypeList}
            columns={columns}
            icon={More}
            multiple={true}
            editPage={navigateEditPage}
            showDetails={showBranchDetails}
            deleteRow={handleDelete}
          />
        </div>
      </div>
    </div>
  );
}

export default EmploymentType;
