import React, { useState } from "react";
import { css } from "@emotion/react";
import { ClipLoader } from "react-spinners";
import alertify from "alertifyjs";
import { GET_API, POST_API } from "../../../Services/api";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../../Spinner";
import Validation from "../../../Validation/Validation";
import FileUpload from "./Components/File";
import "alertifyjs/build/css/alertify.css";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

function EmployeeBankInfo({ stateChanger, empDetails, nextTab, baseurl }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errors, seterrors] = useState({});
  const [isBtnDisable, setIsBtnDisable] = useState(false)

  const handleIfscCode = async (value, sameAs) => {
    try {
      const ifscDetails = await GET_API(`/getIfscCode/${value}`);
      if (ifscDetails?.status === 200 && ifscDetails?.data?.data) {
        if (sameAs) {
          stateChanger((prevEmpDetails) => ({
            ...prevEmpDetails,
            home_bank_branch: ifscDetails?.data?.data?.branch_name,
            hdfc_bank_branch: ifscDetails?.data?.data?.branch_name,
          }));
        } else {
          stateChanger((prevEmpDetails) => ({
            ...prevEmpDetails,
            home_bank_branch: ifscDetails?.data?.data?.branch_name
          }));
        }

      }
    } catch (error) {
      console.error("Error fetching branch details:", error);
    }
  }
  const presentAddressMapping = {
    home_bank_account_name: "hdfc_account_name",
    home_bank_account_no: "hdfc_account_no",
    home_bank_ifsc_code: "hdfc_bank_ifsc_code",
    home_bank_branch: "hdfc_bank_branch",
  };

  const handleBankChange = (e) => {
    const { name, value } = e.target;
    if (empDetails?.same_as_home_bank) {
      const mappedName = presentAddressMapping[name];
      if (mappedName) {
        stateChanger(() => ({ ...empDetails, [name]: value, [mappedName]: value }));
        handleIfscCode(value, true)
      }
    } else {
      stateChanger(() => ({ ...empDetails, [name]: value }));
      handleIfscCode(value, false)
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    stateChanger(() => ({ ...empDetails, [name]: value }));
  };

  const handleValidation = async (e) => {
    e.preventDefault()
    setIsBtnDisable(true)
    const isValid = Validation.validateBankInfo(empDetails);
    if (isValid && isValid.error) {
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      setIsBtnDisable(false)
      return;
    } else {
      seterrors({});
      // console.log("empDetails 123", empDetails);
      try {
        const bankInfo = await POST_API(`/updateEmployeeTentacles/${id}`, empDetails);
        console.log("bankInfo", bankInfo);
        if (bankInfo?.data?.status === 200) {
          setIsBtnDisable(false)
          alertify.success(bankInfo?.data?.message);
          navigate("/employee/list");
        }
      } catch (error) {
        console.log("error");
        setIsBtnDisable(false)
      }
    }
  };

  const handleProfileImage = (e) => {
    const { name } = e?.target;
    const file = e?.target?.files[0];

    if (file) {
      const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];
      const maxSizeKB = 5120;

      if (allowedTypes.includes(file.type)) {
        if (file.size / 1024 <= maxSizeKB) {
          const fileReader = new FileReader();

          fileReader.onload = () => {
            if (fileReader.readyState === 2) {
              stateChanger({ ...empDetails, [name]: fileReader?.result });
              seterrors({})
            }
          };
          if (file.type.includes('image')) {
            fileReader.readAsDataURL(file);
          } else if (file.type === 'application/pdf') {
            fileReader.readAsDataURL(file);
          }
        } else {
          seterrors({ [name]: "File size exceeds 5 MB limit" })
        }
      } else {
        seterrors({ [name]: "Invalid file type. Please choose a valid image (png, jpg, jpeg) or PDF file" })
      }
    }
  };

  if (loading) {
    return <Spinner />;
  }
  return (
    <>
      <form>
        <div className="p-2 grid grid-cols-3 gap-5">
          <div className="field-block">
            <label className="label-name">UAN No</label>
            <input
              name="uan_number"
              value={empDetails?.uan_number}
              className="input-field"
              type="text"
              onChange={(e) => handleChange(e)}
              placeholder="Enter UAN No"
              autoComplete="off"
            />
            {errors.uan_number ? (
              <div className="validation-error-label">{errors.uan_number}</div>
            ) : null}
          </div>
          <div className="field-block">
            <label className="label-name">ESI No</label>
            <input
              name="esi_number"
              value={empDetails?.esi_number}
              className="input-field"
              type="text"
              onChange={(e) => handleChange(e)}
              placeholder="Enter ESI No"
              autoComplete="off"
            />
            {errors.esi_number ? (
              <div className="validation-error-label">{errors.esi_number}</div>
            ) : null}
          </div>
          <div className="field-block">
            <label className="label-name">PF No</label>
            <input
              name="pf_number"
              value={empDetails?.pf_number}
              className="input-field"
              type="text"
              onChange={(e) => handleChange(e)}
              placeholder="Enter PF No"
              autoComplete="off"
            />
            {errors.pf_number ? (
              <div className="validation-error-label">{errors.pf_number}</div>
            ) : null}
          </div>
        </div>
        <h3 className="card-header">HDFC Bank Account Details</h3>
        <div className="p-2 grid grid-cols-3 gap-5">
          <div className="field-block">
            <label className="label-name">Account Holder Name</label>
            <input
              name="hdfc_account_name"
              value={empDetails?.hdfc_account_name}
              className="input-field"
              type="text"
              onChange={(e) => handleChange(e)}
              placeholder="Enter Account Holder Name"
              autoComplete="off"
            />
            {errors.hdfc_account_name ? (
              <div className="validation-error-label">{errors.hdfc_account_name}</div>
            ) : null}
          </div>
          <div className="field-block">
            <label className="label-name">Account No</label>
            <input
              name="hdfc_account_no"
              value={empDetails?.hdfc_account_no}
              className="input-field"
              type="text"
              onChange={(e) => handleChange(e)}
              placeholder="Enter Account No"
              autoComplete="off"
            />
            {errors.hdfc_account_no ? (
              <div className="validation-error-label">{errors.hdfc_account_no}</div>
            ) : null}
          </div>
          <FileUpload
            label="Bank Passbook cover page"
            value={empDetails?.hdfc_bank_passbook_coverpage_image}
            name="hdfc_bank_passbook_coverpage_image"
            isRequired={false}
            errors={errors?.hdfc_bank_passbook_coverpage_image}
            handleFileChange={handleProfileImage}
            imgSrc={baseurl}
            accept=".jpeg,.jpg,.png,.pdf"
          />
          <FileUpload
            label="Cancelled Cheque Leaf"
            value={empDetails?.hdfc_cancelled_cheque_leaf_image}
            name="hdfc_cancelled_cheque_leaf_image"
            isRequired={false}
            errors={errors?.hdfc_cancelled_cheque_leaf_image}
            handleFileChange={handleProfileImage}
            imgSrc={baseurl}
            accept=".jpeg,.jpg,.png,.pdf"
          />
        </div>
        <h3 className="card-header">Other Bank Account Details</h3>
        <div className="p-2 grid grid-cols-3 gap-5">
          <div className="field-block">
            <label className="label-name">Account Holder Name</label>
            <input
              name="home_bank_account_name"
              value={empDetails?.home_bank_account_name}
              className="input-field"
              type="text"
              onChange={(e) => handleChange(e)}
              placeholder="Enter Account Holder name"
              autoComplete="off"
            />
            {errors.home_bank_account_name ? (
              <div className="validation-error-label">{errors.home_bank_account_name}</div>
            ) : null}
          </div>
          <div className="field-block">
            <label className="label-name">Account No</label>
            <input
              name="home_bank_account_no"
              value={empDetails?.home_bank_account_no}
              className="input-field"
              type="text"
              onChange={(e) => handleChange(e)}
              placeholder="Enter Account No"
              autoComplete="off"
            />
            {errors.home_bank_account_no ? (
              <div className="validation-error-label">{errors.home_bank_account_no}</div>
            ) : null}
          </div>
          <div className="field-block">
            <label className="label-name">IFSC Code</label>
            <input
              name="home_bank_ifsc_code"
              value={empDetails?.home_bank_ifsc_code}
              className="input-field"
              type="text"
              onChange={(e) => handleBankChange(e)}
              placeholder="Enter IFSC Code"
              autoComplete="off"
            />
            {errors.home_bank_ifsc_code ? (
              <div className="validation-error-label">{errors.home_bank_ifsc_code}</div>
            ) : null}
          </div>
          <div className="field-block">
            <label className="label-name">Branch name</label>
            <input
              name="home_bank_branch"
              className="input-field"
              type="text"
              onChange={(e) => handleChange(e)}
              placeholder="Enter Branch name"
              autoComplete="off"
              value={empDetails?.home_bank_branch}
            />
            {errors.home_bank_branch ? (
              <div className="validation-error-label">{errors.home_bank_branch}</div>
            ) : null}
          </div>
          <FileUpload
            label="Bank Passbook cover page"
            value={empDetails?.bank_passbook_coverpage_image}
            name="bank_passbook_coverpage_image"
            isRequired={false}
            errors={errors?.bank_passbook_coverpage_image}
            handleFileChange={handleProfileImage}
            imgSrc={baseurl}
            accept=".jpeg,.jpg,.png,.pdf"
          />
          <FileUpload
            label="Cancelled Cheque Leaf"
            value={empDetails?.cancelled_cheque_leaf_image}
            name="cancelled_cheque_leaf_image"
            isRequired={false}
            errors={errors?.cancelled_cheque_leaf_image}
            handleFileChange={handleProfileImage}
            imgSrc={baseurl}
            accept=".jpeg,.jpg,.png,.pdf"
          />
        </div>

      </form>
      <div className="submit-reset-button">
        <button
          type="button"
          className="reset-button"
          onClick={() => {
            nextTab("education_info");
          }}
        >
          <span className="reset-button-label">Prev</span>
        </button>
        <button
          type="submit"
          className="submit-button"
          onClick={(e) => handleValidation(e)}
          disabled={isBtnDisable}
        >
          {isBtnDisable ?
            <ClipLoader
              css={override}
              size={20}
              color={"#fff"}
              loading={isBtnDisable}
            /> : ""}
          <span className="submit-button-label">{isBtnDisable ? "Loading..." : "Save"}</span>
        </button>
      </div>
    </>
  );
}



export default EmployeeBankInfo;
