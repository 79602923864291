import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import { GET_API, POST_API } from "../../Services/api";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import TableRow from "@mui/material/TableRow";
import MoreOption from "../MoreOption";
import ColorAdd from "../../Assets/Icons/ColorAdd.svg";

export default function StickyHeadTable({
  list,
  columns,
  showDetails,
  icon,
  activeDelete,
  activeEdit,
  multiple,
  pfList,
  setManualPFList,
  getList,
}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChange = (e, i) => {
    const { name, value } = e?.target;
    const newArr = [...pfList];
    newArr[i][name] = value;
    setManualPFList(newArr);
  };

  function updateList() {
    POST_API("/manualPfUpdate", pfList)
      .then(async function (data) {
        alertify.success(data?.data?.message);
        getList();
      })
      .catch((error) => {
        alertify.error(error?.response?.data?.message);
      });
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 320 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, i) => (
                <TableCell
                  className="table-header"
                  key={i}
                  align={column.align}
                >
                  {column?.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan="8"
                  className="table-body td-data-not-found h-[220px]"
                >
                  Data Not Found..!
                </TableCell>
              </TableRow>
            ) : (
              list
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={i}
                      className="table-column"
                    >
                      {
                        <TableCell className="table-body !w-[7%]">
                          {page * rowsPerPage + (i + 1)}
                        </TableCell>
                      }
                      {
                        <TableCell className="table-body">
                          {row?.first_name + " " + row?.fathers_name}
                        </TableCell>
                      }
                      {
                        <TableCell className="table-body">
                          {
                            <input
                              type="number"
                              className="input-field"
                              name="pf_amount"
                              onChange={(e) => {
                                handleChange(e, i);
                              }}
                              placeholder="PF Amount"
                              autoComplete="off"
                              value={pfList[i]["pf_amount"]}
                            />
                          }
                        </TableCell>
                      }
                      {
                        <TableCell className="table-body">
                          {
                            <input
                              className="input-field"
                              name="pf_deduction"
                              onChange={(e) => {
                                handleChange(e, i);
                              }}
                              placeholder="PF Deductions"
                              type="number"
                              autoComplete="off"
                              value={pfList[i]["pf_deduction"]}
                            />
                          }
                        </TableCell>
                      }

                      {
                        <TableCell className="table-body">
                          {
                            <button type="submit" className="submit-button">
                              <span
                                className="submit-button-label"
                                onClick={() => updateList()}
                              >
                                Save
                              </span>
                              <img src={ColorAdd} alt="" />
                            </button>
                          }
                        </TableCell>
                      }

                      {
                        <MoreOption
                          icon={icon}
                          row={row}
                          showDetails={showDetails}
                          activeDelete={activeDelete}
                          activeEdit={activeEdit}
                          multiple={multiple}
                        />
                      }
                    </TableRow>
                  );
                })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={list?.length ? list.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
