import React from 'react';
import Select from "react-select";

const districts = [
    { "label": "Ariyalur", "value": "ariyalur" },
    { "label": "Chengalpattu", "value": "chengalpattu" },
    { "label": "Chennai", "value": "chennai" },
    { "label": "Coimbatore", "value": "coimbatore" },
    { "label": "Cuddalore", "value": "cuddalore" },
    { "label": "Dharmapuri", "value": "dharmapuri" },
    { "label": "Dindigul", "value": "dindigul" },
    { "label": "Erode", "value": "erode" },
    { "label": "Kallakurichi", "value": "kallakurichi" },
    { "label": "Kancheepuram", "value": "kancheepuram" },
    { "label": "Karur", "value": "karur" },
    { "label": "Krishnagiri", "value": "krishnagiri" },
    { "label": "Madurai", "value": "madurai" },
    { "label": "Nagapattinam", "value": "nagapattinam" },
    { "label": "Namakkal", "value": "namakkal" },
    { "label": "Nilgiris", "value": "nilgiris" },
    { "label": "Perambalur", "value": "perambalur" },
    { "label": "Pudukkottai", "value": "pudukkottai" },
    { "label": "Ramanathapuram", "value": "ramanathapuram" },
    { "label": "Ranipet", "value": "ranipet" },
    { "label": "Salem", "value": "salem" },
    { "label": "Sivagangai", "value": "sivagangai" },
    { "label": "Tenkasi", "value": "tenkasi" },
    { "label": "Thanjavur", "value": "thanjavur" },
    { "label": "Theni", "value": "theni" },
    { "label": "Thiruvallur", "value": "thiruvallur" },
    { "label": "Thiruvarur", "value": "thiruvarur" },
    { "label": "Thoothukudi", "value": "thoothukudi" },
    { "label": "Tiruchirappalli", "value": "tiruchirappalli" },
    { "label": "Tirunelveli", "value": "tirunelveli" },
    { "label": "Tirupathur", "value": "tirupathur" },
    { "label": "Tiruppur", "value": "tiruppur" },
    { "label": "Tiruvannamalai", "value": "tiruvannamalai" },
    { "label": "Vellore", "value": "vellore" },
    { "label": "Viluppuram", "value": "viluppuram" },
    { "label": "Virudhunagar", "value": "virudhunagar" }
];

const stateOptions = [
    { "label": "Tamilnadu", "value": "tamilnadu" },
]

const AddressForm = ({ empDetails, handleAddressChange, handleAddressSelectChange, errors, type }) => {
    const addType = type === 'presentAddress' ? 'present' : 'permanent'

    return (
        <div className="p-2 grid grid-cols-3 gap-5">
            <div className="field-block">
                <label className="label-name">Door No<span className="required-symbol">*</span></label>
                <input
                    type="text"
                    id={`${addType}_address_door_no`}
                    name={`${addType}_address_door_no`}
                    onChange={e => handleAddressChange(e)}
                    value={empDetails?.[`${addType}_address_door_no`]}
                    className="input-field"
                    placeholder="Enter Door No"
                    autoComplete="off"
                />
                {errors?.[`${addType}_address_door_no`] ?
                    <div className="validation-error-label">{errors?.[`${addType}_address_door_no`]}</div>
                    : null}
            </div>
            <div className="field-block">
                <label className="label-name">Street Name I<span className="required-symbol">*</span></label>
                <input
                    type="text"
                    id={`${addType}_address_street_name`}
                    name={`${addType}_address_street_name`}
                    onChange={e => handleAddressChange(e)}
                    value={empDetails?.[`${addType}_address_street_name`]}
                    className="input-field"
                    placeholder="Enter Street Name I"
                    autoComplete="off"
                />
                {errors?.[`${addType}_address_street_name`] ?
                    <div className="validation-error-label">{errors?.[`${addType}_address_street_name`]}</div>
                    : null}
            </div>
            <div className="field-block">
                <label className="label-name">Street Name II</label>
                <input
                    type="text"
                    id={`${addType}_address_street_name_two`}
                    name={`${addType}_address_street_name_two`}
                    onChange={e => handleAddressChange(e)}
                    value={empDetails?.[`${addType}_address_street_name_two`]}
                    className="input-field"
                    placeholder="Enter Street Name II"
                    autoComplete="off"
                />
                {errors?.[`${addType}_address_street_name_two`] ?
                    <div className="validation-error-label">{errors?.[`${addType}_address_street_name_two`]}</div>
                    : null}
            </div>
            <div className="field-block">
                <label className="label-name">City<span className="required-symbol">*</span></label>
                <Select
                    options={districts}
                    value={empDetails?.[`${addType}_address_city`] ? districts?.find(
                        (option) => option?.value === empDetails?.[`${addType}_address_city`]
                    ):""}
                    onChange={(e) => handleAddressSelectChange(e, `${addType}_address_city`)}
                    name={`${addType}_address_city`}
                    className=""
                    isSearchable
                />
                {errors?.[`${addType}_address_city`] ?
                    <div className="validation-error-label">{errors?.[`${addType}_address_city`]}</div>
                    : null}
            </div>
            <div className="field-block">
                <label className="label-name">State<span className="required-symbol">*</span></label>
                <Select
                    options={stateOptions}
                    value={empDetails?.[`${addType}_address_state`] ? stateOptions?.find(
                        (option) => option?.value === empDetails?.[`${addType}_address_state`]
                    ):""}
                    onChange={(e) => handleAddressSelectChange(e, `${addType}_address_state`)}
                    name={`${addType}_address_state`}
                    className=""
                    isSearchable
                />
                {errors?.[`${addType}_address_state`] ?
                    <div className="validation-error-label">{errors?.[`${addType}_address_state`]}</div>
                    : null}
            </div>
            <div className="field-block">
                <label className="label-name">Pincode<span className="required-symbol">*</span></label>
                <input
                    type="text"
                    id={`${addType}_address_pincode`}
                    name={`${addType}_address_pincode`}
                    onChange={e => handleAddressChange(e)}
                    value={empDetails?.[`${addType}_address_pincode`]}
                    className="input-field"
                    placeholder="Enter Pincode"
                    autoComplete="off"
                />
                {errors?.[`${addType}_address_pincode`] ?
                    <div className="validation-error-label">{errors?.[`${addType}_address_pincode`]}</div>
                    : null}
            </div>
        </div>
    );
};

export default AddressForm;
