import React from "react";

function Footer() {
  return (
    <div className="pt-[6px] pb-[6px] flex justify-center bg-[#fff] shadow">
      <span className="montserrat text-[#BBAACC] font-medium	">
        Created by
        &nbsp;<a
          href="https://m8itsolutions.com/"
          target="_blank"
          className="montserrat text-[#494C54] font-medium"
          rel="noreferrer"
        >
          M8 IT Solutions
        </a>
      </span>
    </div>
  );
}

export default Footer;
