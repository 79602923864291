import React, { useState, useRef } from "react";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FileUploadImage from "../../../../Assets/Icons/FileUpload.svg";
import FileImage from "../../../../Assets/Icons/upload.svg";
import Camera from "../../../../Assets/Icons/Camera.png";

const ProfilePicture = ({ label, value, name, errors, isRequired, handleFileChange, imgSrc, accept, empDetails, stateChanger }) => {
  const videoRef = useRef(null);
  const mediaStreamRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const src = value?.startsWith("uploads") ? imgSrc + value : value

  const startCamera = async (event) => {
    event.preventDefault();
    setIsModalOpen(!isModalOpen);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      videoRef.current.srcObject = stream;
      mediaStreamRef.current = stream;
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };

  const stopCamera = () => {
    if (mediaStreamRef.current) {
      const tracks = mediaStreamRef.current.getTracks();
      tracks.forEach((track) => track.stop());
      videoRef.current.srcObject = null;
    }
  };

  const capture = (event) => {
    event.preventDefault();
    const canvas = document.createElement("canvas");
    const video = videoRef.current;
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const context = canvas.getContext("2d");
    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    // Crop the image to a square
    const size = Math.min(canvas.width, canvas.height);
    const x = (canvas.width - size) / 3;
    const y = (canvas.height - size) / 3;
    const croppedCanvas = document.createElement("canvas");
    croppedCanvas.width = size;
    croppedCanvas.height = size;
    const croppedContext = croppedCanvas.getContext("2d");
    croppedContext.drawImage(canvas, x, y, size, size, 0, 0, size, size);

    // Resize the image to a specific size (passport photo size)
    const targetSize = 200; // Set your desired size
    const resizedCanvas = document.createElement("canvas");
    resizedCanvas.width = targetSize;
    resizedCanvas.height = targetSize;
    const resizedContext = resizedCanvas.getContext("2d");
    resizedContext.drawImage(
      croppedCanvas,
      0,
      0,
      croppedCanvas.width,
      croppedCanvas.height,
      0,
      0,
      targetSize,
      targetSize
    );

    const imageSrc = resizedCanvas.toDataURL("image/jpeg");
    stateChanger({ ...empDetails, profile_picture: imageSrc });
    stopCamera();
    closeModal();
  };

  const closeModal = () => {
    setIsModalOpen(!isModalOpen);
    stopCamera();
  }
  return (
    <div className="field-block">
      <label className="label-name">
        {label}
        {isRequired ? <span className="required-symbol">*</span> : ""}
      </label>
      <div className="">
        <div className="flex gap-2 items-center">
          {!value ? (
            <img src={FileImage} className="employee-profile-picture" />
          ) : (
            <img
              name="file"
              className="h-[35px] w-[35px]"
              src={src}
              alt=""
            />
          )}
          <div className="p-1 bg-[#FFF2EC] rounded flex justify-center items-center gap-1 cursor-pointer">
            <label
              htmlFor={name}
              className="label-name cursor-pointer"
            >
              {value === ""
                ? "Choose"
                : "Replace"}
            </label>
            <input
              className="w-0 cursor-pointer"
              id={name}
              style={{ visibility: "hidden" }}
              type="file"
              accept={accept}
              name={name}
              onChange={e => handleFileChange(e)}
            />
            <img src={FileUploadImage} alt={label} />
          </div>
          <img className="w-7 h-7 cursor-pointer" src={Camera} alt="camera" onClick={(e) => startCamera(e)} />

        </div>
        {errors ? (
          <div
            className="validation-error-label"
            key={errors}
          >
            {errors}
          </div>
        ) : null}
      </div>
      <Dialog className='' open={isModalOpen} onClose={closeModal}
        disableBackdropClick={false}
        PaperProps={{
          style: {
            minWidth: '800px',
            minHeight: '600px',
          },
        }}>
        <DialogContent>
          <video
            ref={videoRef}
            autoPlay
            playsInline
            muted
            style={{ width: "100%" }}
          />
        </DialogContent>
        <DialogActions className="submit-reset-button">
          <button className="submit-button" onClick={(e) => capture(e)}>
            <span className="submit-button-label">Capture</span>
          </button>
          <button type="button" className="reset-button" onClick={closeModal}>
            <span className="reset-button-label">Close</span>
          </button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ProfilePicture