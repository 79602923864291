export const dataList = [
    {
        "menu": {
            "id": 1,
            "module_id": 1,
            "name": "attendance",
            "display_name": "Attendance",
            "description": null,
            "link": null,
            "icon": null,
            "crud_value": null,
            "is_visible": null,
            "is_active": 1,
            "created_at": "2023-11-20 14:35:22",
            "updated_at": "2023-11-20 14:35:22",
            "groupSelect": false
        },
        "submenus": [
            {
                "id": 1,
                "submenu": "attendance",
                "menu_id": 1,
                "display_name": "Attendance",
                "description": null,
                "link": null,
                "icon_crud": null,
                "crud_value": null,
                "is_visible": null,
                "is_active": "1",
                "created_at": "2023-11-20 14:35:55",
                "updated_at": "2023-11-20 14:35:55",
                "menu_name": "attendance",
                "add": true,
                "edit": false,
                "delete": false,
                "view": false
            },
            {
                "id": 2,
                "submenu": "manual_attendance",
                "menu_id": 1,
                "display_name": "Manual Attendance",
                "description": null,
                "link": null,
                "icon_crud": null,
                "crud_value": null,
                "is_visible": null,
                "is_active": "1",
                "created_at": "2023-11-20 14:36:03",
                "updated_at": "2023-11-20 14:37:52",
                "menu_name": "attendance",
                "add": false,
                "edit": false,
                "delete": false,
                "view": false
            },
            {
                "id": 3,
                "submenu": "leave_onduty",
                "menu_id": 1,
                "display_name": "Leave On Duty",
                "description": null,
                "link": null,
                "icon_crud": null,
                "crud_value": null,
                "is_visible": null,
                "is_active": "1",
                "created_at": "2023-11-20 14:36:03",
                "updated_at": "2023-11-20 14:37:44",
                "menu_name": "attendance",
                "add": false,
                "edit": false,
                "delete": false,
                "view": false
            },
            {
                "id": 4,
                "submenu": "employee_fine_details",
                "menu_id": 1,
                "display_name": "Employee Fine Details",
                "description": null,
                "link": null,
                "icon_crud": null,
                "crud_value": null,
                "is_visible": null,
                "is_active": "1",
                "created_at": "2023-11-20 14:37:35",
                "updated_at": "2023-11-20 14:37:35",
                "menu_name": "attendance",
                "add": false,
                "edit": false,
                "delete": false,
                "view": false
            }
        ]
    },
    {
        "menu": {
            "id": 2,
            "module_id": 1,
            "name": "employee_management",
            "display_name": "Employee Managament",
            "description": null,
            "link": null,
            "icon": null,
            "crud_value": null,
            "is_visible": null,
            "is_active": 1,
            "created_at": "2023-11-20 14:41:38",
            "updated_at": "2023-11-21 12:07:48",
            "groupSelect": false
        },
        "submenus": [
            {
                "id": 5,
                "submenu": "list",
                "menu_id": 2,
                "display_name": "List",
                "description": null,
                "link": null,
                "icon_crud": null,
                "crud_value": null,
                "is_visible": null,
                "is_active": "1",
                "created_at": "2023-11-20 14:42:17",
                "updated_at": "2023-11-20 14:42:17",
                "menu_name": "employee_management",
                "add": false,
                "edit": false,
                "delete": false,
                "view": false
            },
            {
                "id": 6,
                "submenu": "add",
                "menu_id": 2,
                "display_name": "Add",
                "description": null,
                "link": null,
                "icon_crud": null,
                "crud_value": null,
                "is_visible": null,
                "is_active": "1",
                "created_at": "2023-11-20 14:42:17",
                "updated_at": "2023-11-20 14:42:17",
                "menu_name": "employee_management",
                "add": true,
                "edit": true,
                "delete": false,
                "view": true
            },
            {
                "id": 7,
                "submenu": "employee_rejoin",
                "menu_id": 2,
                "display_name": "Employee Rejoin",
                "description": null,
                "link": null,
                "icon_crud": null,
                "crud_value": null,
                "is_visible": null,
                "is_active": "1",
                "created_at": "2023-11-20 14:42:41",
                "updated_at": "2023-11-20 14:42:41",
                "menu_name": "employee_management",
                "add": false,
                "edit": false,
                "delete": false,
                "view": false
            }
        ]
    },
    {
        "menu": {
            "id": 3,
            "module_id": 1,
            "name": "loan_and_expenses",
            "display_name": "Loan and Expenses",
            "description": null,
            "link": null,
            "icon": null,
            "crud_value": null,
            "is_visible": null,
            "is_active": 1,
            "created_at": "2023-11-20 14:43:28",
            "updated_at": "2023-11-21 12:07:49",
            "groupSelect": false
        },
        "submenus": [
            {
                "id": 8,
                "submenu": "loan",
                "menu_id": 3,
                "display_name": "Loan",
                "description": null,
                "link": null,
                "icon_crud": null,
                "crud_value": null,
                "is_visible": null,
                "is_active": "1",
                "created_at": "2023-11-20 14:43:51",
                "updated_at": "2023-11-20 14:43:51",
                "menu_name": "loan_and_expenses",
                "add": false,
                "edit": false,
                "delete": false,
                "view": false
            },
            {
                "id": 9,
                "submenu": "agent_commission",
                "menu_id": 3,
                "display_name": "Agent Commission",
                "description": null,
                "link": null,
                "icon_crud": null,
                "crud_value": null,
                "is_visible": null,
                "is_active": "1",
                "created_at": "2023-11-20 14:45:17",
                "updated_at": "2023-11-20 14:45:17",
                "menu_name": "loan_and_expenses",
                "add": false,
                "edit": false,
                "delete": false,
                "view": false
            },
            {
                "id": 10,
                "submenu": "bonus",
                "menu_id": 3,
                "display_name": "Bonus",
                "description": null,
                "link": null,
                "icon_crud": null,
                "crud_value": null,
                "is_visible": null,
                "is_active": "1",
                "created_at": "2023-11-20 14:45:17",
                "updated_at": "2023-11-20 14:45:17",
                "menu_name": "loan_and_expenses",
                "add": false,
                "edit": false,
                "delete": false,
                "view": false
            },
            {
                "id": 11,
                "submenu": "advance",
                "menu_id": 3,
                "display_name": "Advance",
                "description": null,
                "link": null,
                "icon_crud": null,
                "crud_value": null,
                "is_visible": null,
                "is_active": "1",
                "created_at": "2023-11-20 14:45:17",
                "updated_at": "2023-11-20 14:45:17",
                "menu_name": "loan_and_expenses",
                "add": false,
                "edit": false,
                "delete": false,
                "view": false
            },
            {
                "id": 12,
                "submenu": "expenses",
                "menu_id": 3,
                "display_name": "Expenses",
                "description": null,
                "link": null,
                "icon_crud": null,
                "crud_value": null,
                "is_visible": null,
                "is_active": "1",
                "created_at": "2023-11-20 14:45:17",
                "updated_at": "2023-11-20 14:45:17",
                "menu_name": "loan_and_expenses",
                "add": false,
                "edit": false,
                "delete": false,
                "view": false
            }
        ]
    },
    {
        "menu": {
            "id": 4,
            "module_id": 1,
            "name": "payroll",
            "display_name": "Payroll",
            "description": null,
            "link": null,
            "icon": null,
            "crud_value": null,
            "is_visible": null,
            "is_active": 1,
            "created_at": "2023-11-20 14:46:10",
            "updated_at": "2023-11-21 12:07:52",
            "groupSelect": false
        },
        "submenus": [
            {
                "id": 13,
                "submenu": "generate_payslip",
                "menu_id": 4,
                "display_name": "Generate Payslip",
                "description": null,
                "link": null,
                "icon_crud": null,
                "crud_value": null,
                "is_visible": null,
                "is_active": "1",
                "created_at": "2023-11-20 14:47:00",
                "updated_at": "2023-11-20 14:47:00",
                "menu_name": "payroll",
                "add": false,
                "edit": false,
                "delete": false,
                "view": false
            },
            {
                "id": 14,
                "submenu": "manual_PF",
                "menu_id": 4,
                "display_name": "Manual PF",
                "description": null,
                "link": null,
                "icon_crud": null,
                "crud_value": null,
                "is_visible": null,
                "is_active": "1",
                "created_at": "2023-11-20 14:47:55",
                "updated_at": "2023-11-20 14:47:55",
                "menu_name": "payroll",
                "add": false,
                "edit": false,
                "delete": false,
                "view": false
            },
            {
                "id": 15,
                "submenu": "payslip_details",
                "menu_id": 4,
                "display_name": "Payslip Details",
                "description": null,
                "link": null,
                "icon_crud": null,
                "crud_value": null,
                "is_visible": null,
                "is_active": "1",
                "created_at": "2023-11-20 14:47:55",
                "updated_at": "2023-11-20 14:47:55",
                "menu_name": "payroll",
                "add": false,
                "edit": false,
                "delete": false,
                "view": false
            }
        ]
    },
    {
        "menu": {
            "id": 5,
            "module_id": 1,
            "name": "reports",
            "display_name": "Reports",
            "description": null,
            "link": null,
            "icon": null,
            "crud_value": null,
            "is_visible": null,
            "is_active": 1,
            "created_at": "2023-11-20 14:48:42",
            "updated_at": "2023-11-21 12:07:54",
            "groupSelect": false
        },
        "submenus": [
            {
                "id": 16,
                "submenu": "employee_report",
                "menu_id": 5,
                "display_name": "Employee Report",
                "description": null,
                "link": null,
                "icon_crud": null,
                "crud_value": null,
                "is_visible": null,
                "is_active": "1",
                "created_at": "2023-11-20 14:49:27",
                "updated_at": "2023-11-20 14:49:27",
                "menu_name": "reports",
                "add": false,
                "edit": false,
                "delete": false,
                "view": false
            },
            {
                "id": 17,
                "submenu": "employee_master_report",
                "menu_id": 5,
                "display_name": "Employee Master Report",
                "description": null,
                "link": null,
                "icon_crud": null,
                "crud_value": null,
                "is_visible": null,
                "is_active": "1",
                "created_at": "2023-11-20 14:52:43",
                "updated_at": "2023-11-20 14:52:43",
                "menu_name": "reports",
                "add": false,
                "edit": false,
                "delete": false,
                "view": false
            },
            {
                "id": 18,
                "submenu": "termination_report",
                "menu_id": 5,
                "display_name": "Termination Report",
                "description": null,
                "link": null,
                "icon_crud": null,
                "crud_value": null,
                "is_visible": null,
                "is_active": "1",
                "created_at": "2023-11-20 14:52:43",
                "updated_at": "2023-11-20 14:52:43",
                "menu_name": "reports",
                "add": false,
                "edit": false,
                "delete": false,
                "view": false
            },
            {
                "id": 19,
                "submenu": "attendance_list_report",
                "menu_id": 5,
                "display_name": "Attendance List Report",
                "description": null,
                "link": null,
                "icon_crud": null,
                "crud_value": null,
                "is_visible": null,
                "is_active": "1",
                "created_at": "2023-11-20 14:52:43",
                "updated_at": "2023-11-20 14:52:43",
                "menu_name": "reports",
                "add": false,
                "edit": false,
                "delete": false,
                "view": false
            },
            {
                "id": 20,
                "submenu": "leave_report",
                "menu_id": 5,
                "display_name": "Leave Report",
                "description": null,
                "link": null,
                "icon_crud": null,
                "crud_value": null,
                "is_visible": null,
                "is_active": "1",
                "created_at": "2023-11-20 14:52:43",
                "updated_at": "2023-11-20 14:52:43",
                "menu_name": "reports",
                "add": false,
                "edit": false,
                "delete": false,
                "view": false
            },
            {
                "id": 21,
                "submenu": "absent_report",
                "menu_id": 5,
                "display_name": "Absent Report",
                "description": null,
                "link": null,
                "icon_crud": null,
                "crud_value": null,
                "is_visible": null,
                "is_active": "1",
                "created_at": "2023-11-20 14:52:43",
                "updated_at": "2023-11-20 14:52:43",
                "menu_name": "reports",
                "add": false,
                "edit": false,
                "delete": false,
                "view": false
            },
            {
                "id": 22,
                "submenu": "exit_report",
                "menu_id": 5,
                "display_name": "Exit Report",
                "description": null,
                "link": null,
                "icon_crud": null,
                "crud_value": null,
                "is_visible": null,
                "is_active": "1",
                "created_at": "2023-11-20 14:52:43",
                "updated_at": "2023-11-20 14:52:43",
                "menu_name": "reports",
                "add": false,
                "edit": false,
                "delete": false,
                "view": false
            },
            {
                "id": 23,
                "submenu": "on_duty_report",
                "menu_id": 5,
                "display_name": "OnDuty Report",
                "description": null,
                "link": null,
                "icon_crud": null,
                "crud_value": null,
                "is_visible": null,
                "is_active": "1",
                "created_at": "2023-11-20 14:52:43",
                "updated_at": "2023-11-20 14:52:43",
                "menu_name": "reports",
                "add": false,
                "edit": false,
                "delete": false,
                "view": false
            },
            {
                "id": 24,
                "submenu": "late_fine_report",
                "menu_id": 5,
                "display_name": "Late Fine Report",
                "description": null,
                "link": null,
                "icon_crud": null,
                "crud_value": null,
                "is_visible": null,
                "is_active": "1",
                "created_at": "2023-11-20 14:52:43",
                "updated_at": "2023-11-20 14:52:43",
                "menu_name": "reports",
                "add": false,
                "edit": false,
                "delete": false,
                "view": false
            },
            {
                "id": 25,
                "submenu": "advance_report",
                "menu_id": 5,
                "display_name": "Advance Report",
                "description": null,
                "link": null,
                "icon_crud": null,
                "crud_value": null,
                "is_visible": null,
                "is_active": "1",
                "created_at": "2023-11-20 14:52:43",
                "updated_at": "2023-11-20 14:53:35",
                "menu_name": "reports",
                "add": false,
                "edit": false,
                "delete": false,
                "view": false
            },
            {
                "id": 26,
                "submenu": "loan_report",
                "menu_id": 5,
                "display_name": "Loan Report",
                "description": null,
                "link": null,
                "icon_crud": null,
                "crud_value": null,
                "is_visible": null,
                "is_active": "1",
                "created_at": "2023-11-20 14:54:22",
                "updated_at": "2023-11-20 14:54:22",
                "menu_name": "reports",
                "add": false,
                "edit": false,
                "delete": false,
                "view": false
            },
            {
                "id": 27,
                "submenu": "expense_report",
                "menu_id": 5,
                "display_name": "Expense Report",
                "description": null,
                "link": null,
                "icon_crud": null,
                "crud_value": null,
                "is_visible": null,
                "is_active": "1",
                "created_at": "2023-11-20 14:55:16",
                "updated_at": "2023-11-20 14:55:16",
                "menu_name": "reports",
                "add": false,
                "edit": false,
                "delete": false,
                "view": false
            },
            {
                "id": 28,
                "submenu": "cost_report",
                "menu_id": 5,
                "display_name": "Cost Report",
                "description": null,
                "link": null,
                "icon_crud": null,
                "crud_value": null,
                "is_visible": null,
                "is_active": "1",
                "created_at": "2023-11-20 14:55:16",
                "updated_at": "2023-11-20 14:55:50",
                "menu_name": "reports",
                "add": false,
                "edit": false,
                "delete": false,
                "view": false
            },
            {
                "id": 29,
                "submenu": "movement_report",
                "menu_id": 5,
                "display_name": "Movement Report",
                "description": null,
                "link": null,
                "icon_crud": null,
                "crud_value": null,
                "is_visible": null,
                "is_active": "1",
                "created_at": "2023-11-20 14:56:26",
                "updated_at": "2023-11-20 14:58:44",
                "menu_name": "reports",
                "add": false,
                "edit": false,
                "delete": false,
                "view": false
            },
            {
                "id": 30,
                "submenu": "part_time_employee_report",
                "menu_id": 5,
                "display_name": "Part Time Employee Report",
                "description": null,
                "link": null,
                "icon_crud": null,
                "crud_value": null,
                "is_visible": null,
                "is_active": "1",
                "created_at": "2023-11-20 14:58:29",
                "updated_at": "2023-11-20 14:58:48",
                "menu_name": "reports",
                "add": false,
                "edit": false,
                "delete": false,
                "view": false
            },
            {
                "id": 31,
                "submenu": "food_allowance_report",
                "menu_id": 5,
                "display_name": "Food Allowance Report",
                "description": null,
                "link": null,
                "icon_crud": null,
                "crud_value": null,
                "is_visible": null,
                "is_active": "1",
                "created_at": "2023-11-20 14:58:29",
                "updated_at": "2023-11-20 14:58:51",
                "menu_name": "reports",
                "add": false,
                "edit": false,
                "delete": false,
                "view": false
            },
            {
                "id": 32,
                "submenu": "early_exit_report",
                "menu_id": 5,
                "display_name": "Early Exit Report",
                "description": null,
                "link": null,
                "icon_crud": null,
                "crud_value": null,
                "is_visible": null,
                "is_active": "1",
                "created_at": "2023-11-20 14:58:29",
                "updated_at": "2023-11-20 14:58:59",
                "menu_name": "reports",
                "add": false,
                "edit": false,
                "delete": false,
                "view": false
            },
            {
                "id": 33,
                "submenu": "branch_wise_report",
                "menu_id": 5,
                "display_name": "Branch Wise Report",
                "description": null,
                "link": null,
                "icon_crud": null,
                "crud_value": null,
                "is_visible": null,
                "is_active": "1",
                "created_at": "2023-11-20 14:58:29",
                "updated_at": "2023-11-20 14:59:02",
                "menu_name": "reports",
                "add": false,
                "edit": false,
                "delete": false,
                "view": false
            },
            {
                "id": 94,
                "submenu": "floor_wise_report",
                "menu_id": 5,
                "display_name": "Floor Report",
                "description": null,
                "link": null,
                "icon_crud": null,
                "crud_value": null,
                "is_visible": null,
                "is_active": "1",
                "created_at": "2023-12-01 15:17:21",
                "updated_at": "2023-12-01 15:22:03",
                "menu_name": "reports",
                "add": false,
                "edit": false,
                "delete": false,
                "view": false
            },
            {
                "id": 101,
                "submenu": "consolidate_report",
                "menu_id": 5,
                "display_name": "Consolidate Report",
                "description": null,
                "link": null,
                "icon_crud": null,
                "crud_value": null,
                "is_visible": null,
                "is_active": "1",
                "created_at": "2023-12-22 12:08:09",
                "updated_at": "2023-12-22 12:08:09",
                "menu_name": "reports",
                "add": false,
                "edit": false,
                "delete": false,
                "view": false
            },
            {
                "id": 103,
                "submenu": "longleave_report",
                "menu_id": 5,
                "display_name": "Long Leave Report",
                "description": null,
                "link": null,
                "icon_crud": null,
                "crud_value": null,
                "is_visible": null,
                "is_active": "1",
                "created_at": "2023-12-22 12:08:09",
                "updated_at": "2023-12-22 12:08:09",
                "menu_name": "reports",
                "add": false,
                "edit": false,
                "delete": false,
                "view": false
            },
            {
                "id": 104,
                "submenu": "loan_ledger",
                "menu_id": 5,
                "display_name": "Loan Ledger Report",
                "description": null,
                "link": null,
                "icon_crud": null,
                "crud_value": null,
                "is_visible": null,
                "is_active": "1",
                "created_at": "2023-12-22 12:08:09",
                "updated_at": "2023-12-22 12:08:09",
                "menu_name": "reports",
                "add": false,
                "edit": false,
                "delete": false,
                "view": false
            },
            {
                "id": 105,
                "submenu": "agent_commission_creation_report",
                "menu_id": 5,
                "display_name": "Agent comm Creation Report",
                "description": null,
                "link": null,
                "icon_crud": null,
                "crud_value": null,
                "is_visible": null,
                "is_active": "1",
                "created_at": "2023-12-22 12:08:09",
                "updated_at": "2023-12-22 12:08:09",
                "menu_name": "reports",
                "add": false,
                "edit": false,
                "delete": false,
                "view": false
            },
            {
                "id": 106,
                "submenu": "agent_commission_actual_report",
                "menu_id": 5,
                "display_name": "Agent comm Actual Report",
                "description": null,
                "link": null,
                "icon_crud": null,
                "crud_value": null,
                "is_visible": null,
                "is_active": "1",
                "created_at": "2023-12-22 12:08:09",
                "updated_at": "2023-12-22 12:08:09",
                "menu_name": "reports",
                "add": false,
                "edit": false,
                "delete": false,
                "view": false
            }
        ]
    },
    {
        "menu": {
            "id": 6,
            "module_id": 1,
            "name": "approve",
            "display_name": "Approve",
            "description": null,
            "link": null,
            "icon": null,
            "crud_value": null,
            "is_visible": null,
            "is_active": 1,
            "created_at": "2023-11-20 15:02:20",
            "updated_at": "2023-11-21 12:07:56",
            "groupSelect": false
        },
        "submenus": [
            {
                "id": 34,
                "submenu": "approve_master",
                "menu_id": 6,
                "display_name": "masters",
                "description": null,
                "link": null,
                "icon_crud": null,
                "crud_value": null,
                "is_visible": null,
                "is_active": "1",
                "created_at": "2023-11-20 15:02:48",
                "updated_at": "2023-11-23 18:11:35",
                "menu_name": "approve",
                "add": true,
                "edit": true,
                "delete": true,
                "view": true
            },
            {
                "id": 35,
                "submenu": "approve_leave",
                "menu_id": 6,
                "display_name": "Leave",
                "description": null,
                "link": null,
                "icon_crud": null,
                "crud_value": null,
                "is_visible": null,
                "is_active": "1",
                "created_at": "2023-11-20 15:03:20",
                "updated_at": "2023-11-23 18:11:43",
                "menu_name": "approve",
                "add": true,
                "edit": true,
                "delete": true,
                "view": true
            },
            {
                "id": 36,
                "submenu": "approve_late_entry",
                "menu_id": 6,
                "display_name": "Late Entry",
                "description": null,
                "link": null,
                "icon_crud": null,
                "crud_value": null,
                "is_visible": null,
                "is_active": "1",
                "created_at": "2023-11-20 15:05:51",
                "updated_at": "2023-11-23 18:11:45",
                "menu_name": "approve",
                "add": true,
                "edit": true,
                "delete": true,
                "view": true
            },
            {
                "id": 37,
                "submenu": "approve_early_exit",
                "menu_id": 6,
                "display_name": "Early Exit",
                "description": null,
                "link": null,
                "icon_crud": null,
                "crud_value": null,
                "is_visible": null,
                "is_active": "1",
                "created_at": "2023-11-20 15:05:51",
                "updated_at": "2023-11-23 18:11:53",
                "menu_name": "approve",
                "add": true,
                "edit": true,
                "delete": true,
                "view": true
            },
            {
                "id": 38,
                "submenu": "approve_finance",
                "menu_id": 6,
                "display_name": "Finance",
                "description": null,
                "link": null,
                "icon_crud": null,
                "crud_value": null,
                "is_visible": null,
                "is_active": "1",
                "created_at": "2023-11-20 15:05:51",
                "updated_at": "2023-11-23 18:11:58",
                "menu_name": "approve",
                "add": true,
                "edit": true,
                "delete": true,
                "view": true
            },
            {
                "id": 39,
                "submenu": "approve_bonus",
                "menu_id": 6,
                "display_name": "Bonus",
                "description": null,
                "link": null,
                "icon_crud": null,
                "crud_value": null,
                "is_visible": null,
                "is_active": "1",
                "created_at": "2023-11-20 15:05:51",
                "updated_at": "2023-11-23 18:12:06",
                "menu_name": "approve",
                "add": true,
                "edit": true,
                "delete": true,
                "view": true
            },
            {
                "id": 40,
                "submenu": "approve_expense",
                "menu_id": 6,
                "display_name": "Expense",
                "description": null,
                "link": null,
                "icon_crud": null,
                "crud_value": null,
                "is_visible": null,
                "is_active": "1",
                "created_at": "2023-11-20 15:06:09",
                "updated_at": "2023-11-23 18:32:12",
                "menu_name": "approve",
                "add": true,
                "edit": true,
                "delete": true,
                "view": true
            },
            {
                "id": 107,
                "submenu": "approve_employee",
                "menu_id": 6,
                "display_name": "Employees",
                "description": "Employees",
                "link": null,
                "icon_crud": null,
                "crud_value": null,
                "is_visible": null,
                "is_active": "1",
                "created_at": "2024-02-09 11:09:19",
                "updated_at": "2024-02-09 11:09:19",
                "menu_name": "approve",
                "add": false,
                "edit": false,
                "delete": false,
                "view": false
            }
        ]
    },
    {
        "menu": {
            "id": 7,
            "module_id": 1,
            "name": "voucher",
            "display_name": "Voucher",
            "description": null,
            "link": null,
            "icon": null,
            "crud_value": null,
            "is_visible": null,
            "is_active": 1,
            "created_at": "2023-11-20 15:06:45",
            "updated_at": "2023-11-21 12:07:59",
            "groupSelect": false
        },
        "submenus": [
            {
                "id": 41,
                "submenu": "food_allowance",
                "menu_id": 7,
                "display_name": "Food Allowance",
                "description": null,
                "link": null,
                "icon_crud": null,
                "crud_value": null,
                "is_visible": null,
                "is_active": "1",
                "created_at": "2023-11-20 15:07:16",
                "updated_at": "2023-11-20 15:07:44",
                "menu_name": "voucher",
                "add": false,
                "edit": false,
                "delete": false,
                "view": false
            }
        ]
    },
    {
        "menu": {
            "id": 8,
            "module_id": 1,
            "name": "detection",
            "display_name": "Detection",
            "description": null,
            "link": null,
            "icon": null,
            "crud_value": null,
            "is_visible": null,
            "is_active": 1,
            "created_at": "2023-11-20 15:08:16",
            "updated_at": "2023-11-21 12:08:01",
            "groupSelect": true
        },
        "submenus": []
    }
]