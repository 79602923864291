import React, { useEffect, useState } from "react";
import Select from "react-select";
import Arrow from "../../../Assets/Icons/RightArrow.svg";
import Spinner from "../../Spinner";
import { GET_API, POST_API } from "../../../Services/api";
import alertify from "alertifyjs";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import Validation from "../../../Validation/Validation";
import { Checkbox } from "@mui/material";
import { dataList } from "./data";
function UserPrivileges() {
  const [loading, setLoading] = useState(false);
  const [activeModel, setActiveModel] = useState();
  const [modelList, setModelList] = useState([]);
  const [buttonDisabled, setbuttonDisabled] = useState(false);
  const [typeList, setTypeList] = useState([]);
  const [errors, setErrors] = useState({});
  const [showContent, setShowContent] = useState("");
  const [userPrivilege, setUserPrivilege] = useState({
    id: "",
    type: "user",
    module_id: "",
    data: [],
  });

  useEffect(() => {
    getModelList();
    getPrivilegeForUserOrRole();
  }, []);

  const getModelList = async () => {
    setLoading(true);
    try {
      const modelList = await GET_API("/moduleList");
      if (modelList?.data?.status === 200) {
        const floor = modelList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        floor.unshift({ value: "", label: "Select..." });
        setModelList(floor);
        setLoading(false);
        return;
      }
    } catch (error) {
      setLoading(false);
      setModelList([]);
    }
  };

  const getMenuList = async (e) => {
    if (e?.value === "") return;
    setLoading(true);
    setActiveModel(e?.value);
    const type = userPrivilege?.type;
    const id = userPrivilege?.id;
    try {
      const list = await GET_API(`/menuList/${type}/${e?.value}/${id}`);
      if (list?.data?.status === 200) {
        setLoading(false);
        setUserPrivilege({
          ...userPrivilege,
          module_id: e?.value,
          // data: list?.data?.data,
          data: dataList,
        });
      }
    } catch (error) {
      setUserPrivilege({
        id: "",
        type: "",
        module_id: "",
        data: [],
      });
      setLoading(false);
      alertify?.error("Failed to get Menu list...");
    }
  };

  const handleActiveStatus = (type, e, menuID, menuIndex, submenuIndex) => {
    const menuList = userPrivilege?.data?.map((item, i) => {
      const submenuList = item?.submenus?.map((submenu, i) => {
        if (submenu?.menu_id === menuID && submenuIndex === i) {
          return { ...submenu, [type]: e?.target?.checked };
        } else {
          return { ...submenu };
        }
      });
      return { ...item, submenus: submenuList };
    });
    if (!checkGroupSelectIsActiveOrNot(menuList[menuIndex])) {
      const result = menuList?.map((list, i) => {
        if (i === menuIndex) {
          const menu = { ...list?.menu, groupSelect: false };
          return { menu: menu, submenus: list?.submenus };
        }
        return { ...list };
      });
      setUserPrivilege({ ...userPrivilege, data: result });
    } else {
      const result = menuList?.map((list, i) => {
        if (i === menuIndex) {
          const menu = { ...list?.menu, groupSelect: true };
          return { menu: menu, submenus: list?.submenus };
        }
        return { ...list };
      });
      setUserPrivilege({ ...userPrivilege, data: result });
    }
  };

  const checkGroupSelectIsActiveOrNot = (list) => {
    for (const obj of list?.submenus) {
      if (!obj.add || !obj.edit || !obj.view || !obj.delete) {
        return false;
      }
    }
    return true;
  };

  const handleGroupSelect = (e, menuIndex) => {
    const menuList = userPrivilege?.data?.map((item, i) => {
      if (menuIndex === i) {
        const submenuList = item?.submenus?.map((submenu) => {
          return {
            ...submenu,
            add: e?.target?.checked,
            edit: e?.target?.checked,
            delete: e?.target?.checked,
            view: e?.target?.checked,
          };
        });
        return {
          ...item,
          menu: { ...item?.menu, groupSelect: e?.target?.checked },
          submenus: submenuList,
        };
      } else {
        return { ...item };
      }
    });
    setUserPrivilege({ ...userPrivilege, data: menuList });
  };

  const handleSubmit = async () => {
    const isValid = Validation.validatePrivilege(userPrivilege);
    if (isValid && isValid?.error) {
      setbuttonDisabled(false);
      setErrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      setErrors({});
      setbuttonDisabled(false);
    }
    try {
      const addUserRolePrivilege = await POST_API(
        "addUserRolePrivileges",
        userPrivilege
      );
      if (addUserRolePrivilege?.data?.status === 200) {
        setbuttonDisabled(false);
        alertify?.success(addUserRolePrivilege?.data?.message);
      }
    } catch (error) {
      setbuttonDisabled(false);
      alertify?.error("Failed to add privilege");
    }
  };

  const getPrivilegeForUserOrRole = async () => {
    setLoading(true);
    try {
      const getList = await GET_API("userListDropdown");
      if (getList?.data?.status === 200) {
        setLoading(false);
        const typeListData = getList?.data?.data.map((item) => {
          return {
            label: `${item?.username} (${item?.emp_code})`,
            value: item?.id,
          };
        });
        typeListData.unshift({ value: "", label: "Select..." });
        setTypeList(typeListData);
      }
    } catch (error) {
      setLoading(false);
      setTypeList([]);
      alertify?.error("Failed to get Type details...");
    }
  };

  const handleChange = (e) => {
    if (e?.value === "") return;
    setUserPrivilege({ ...userPrivilege, id: e?.value });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="grow bg-[#F7F6F4]">
      <div className="sm-container">
        <h1 className="heading-text">User Privilege</h1>
        <div className="border-[1px] border-primary p-4 bg-white-color shadow-lg rounded">
          <div>
            <div className="w-full flex gap-2">
              <div className="w-6/12 min-h-[80px] flex flex-col justify-end">
                <label className="label-name">User</label>
                <Select
                  isSearchable
                  options={typeList}
                  value={typeList?.find(
                    (option) => option?.value === userPrivilege?.id
                  )}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                {errors.id ? (
                  <div className="validation-error-label">{errors.id}</div>
                ) : null}
              </div>
              <div className="w-6/12 min-h-[80px] flex flex-col justify-end">
                <label className="label-name">Modules</label>
                <Select
                  isSearchable
                  options={modelList}
                  onChange={(e) => getMenuList(e)}
                  value={
                    activeModel
                      ? modelList?.find(
                        (option) => option?.value === activeModel
                      )
                      : ""
                  }
                />
                {errors.module_id ? (
                  <div className="validation-error-label">
                    {errors.module_id}
                  </div>
                ) : null}
              </div>
              <div className="w-6/12 min-h-[80px] flex flex-col justify-end">
                <button
                  disabled={buttonDisabled}
                  type="submit"
                  className="submit-button w-[30%]"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  <span className="submit-button-label">Submit</span>
                  <img src={ColorAdd} alt="" />
                </button>
              </div>
            </div>
          </div>

          {userPrivilege?.data && (
            <div>
              {userPrivilege?.data?.map((list, i) => {
                return (
                  <div key={i}>
                    <MenuItems
                      menuList={list}
                      userPrivilege={userPrivilege}
                      setUserPrivilege={setUserPrivilege}
                      menuIndex={i}
                      handleActiveStatus={handleActiveStatus}
                      showContent={showContent}
                      setShowContent={setShowContent}
                    // enableGroupSelect={isEnableGroupSelect}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );

  function MenuItems({
    menuList,
    menuIndex,
    handleActiveStatus,
    showContent,
    setShowContent,
    enableGroupSelect,
  }) {
    return (
      <div className="border-[1px] border-primary p-4 bg-white-color shadow-lg rounded mt-4">
        <div className="">
          <div className="flex justify-between items-center">
            <div className="flex gap-2">
              <Checkbox
                onChange={(e) => {
                  handleGroupSelect(e, menuIndex);
                }}
                checked={userPrivilege?.data[menuIndex]?.menu?.groupSelect}
                style={{ padding: "unset" }}
              />
              <span className="font-karla text-lg text-text-dark">
                {menuList?.menu?.display_name}
              </span>
            </div>
            <div className="cursor-pointer">
              <img
                src={Arrow}
                alt=""
                style={{
                  transform:
                    showContent === menuList?.menu?.name
                      ? "rotate(90deg)"
                      : "rotate(0)",
                }}
                onClick={() =>
                  setShowContent((prev) =>
                    prev === menuList?.menu?.name ? "" : menuList?.menu?.name
                  )
                }
              />
            </div>
          </div>
          {showContent === menuList?.menu?.name && (
            <div>
              {menuList?.submenus?.map((submenu, i) => {
                return (
                  <React.Fragment key={i}>
                    <SubMenuItems
                      submenu={submenu}
                      menuIndex={menuIndex}
                      submenuIndex={i}
                      handleActiveStatus={handleActiveStatus}
                    />
                  </React.Fragment>
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }

  function SubMenuItems({
    submenu,
    menuIndex,
    submenuIndex,
    handleActiveStatus,
  }) {
    return (
      <div className="pt-5 font-montserrat text-sm font-semibold">
        <span>{submenu?.display_name}</span>
        <div className="py-5 flex justify-between items-center border-b-[1px] border-b-[#DADADA]">
          <div className="flex gap-2">
            <Checkbox
              onChange={(e) => {
                handleActiveStatus(
                  "add",
                  e,
                  submenu?.menu_id,
                  menuIndex,
                  submenuIndex
                );
              }}
              checked={
                userPrivilege?.data[menuIndex]?.submenus[submenuIndex]?.add
                  ? userPrivilege?.data[menuIndex]?.submenus[submenuIndex]?.add
                  : ""
              }
              style={{ padding: "unset", alignItems: "center" }}
            />
            <div className="font-montserrat text-text-light text-sm font-medium	">
              Add
            </div>
          </div>
          <div className="flex gap-2">
            <Checkbox
              onChange={(e) => {
                handleActiveStatus(
                  "edit",
                  e,
                  submenu?.menu_id,
                  menuIndex,
                  submenuIndex
                );
              }}
              checked={
                userPrivilege?.data[menuIndex]?.submenus[submenuIndex]?.edit
                  ? userPrivilege?.data[menuIndex]?.submenus[submenuIndex]?.edit
                  : ""
              }
              style={{ padding: "unset" }}
            />
            <div className="font-montserrat text-text-light text-sm font-medium	">
              Edit
            </div>
          </div>
          <div className="flex gap-2">
            <Checkbox
              checked={
                userPrivilege?.data[menuIndex]?.submenus[submenuIndex]?.view
                  ? userPrivilege?.data[menuIndex]?.submenus[submenuIndex]?.view
                  : ""
              }
              onChange={(e) => {
                handleActiveStatus(
                  "view",
                  e,
                  submenu?.menu_id,
                  menuIndex,
                  submenuIndex
                );
              }}
              style={{ padding: "unset" }}
            />
            <div className="font-montserrat text-text-light text-sm font-medium	">
              View
            </div>
          </div>
          <div className="flex gap-2">
            <Checkbox
              onChange={(e) => {
                handleActiveStatus(
                  "delete",
                  e,
                  submenu?.menu_id,
                  menuIndex,
                  submenuIndex
                );
              }}
              checked={
                userPrivilege?.data[menuIndex]?.submenus[submenuIndex]?.delete
                  ? userPrivilege?.data[menuIndex]?.submenus[submenuIndex]
                    ?.delete
                  : ""
              }
              style={{ padding: "unset" }}
            />
            <div className="font-montserrat text-text-light text-sm font-medium	">
              Delete
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default UserPrivileges;
