import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import More from "../../../Assets/Icons/More.svg";
import { DELETE_API, GET_API, POST_API } from "../../../Services/api";
import ClientDeductionListTable from "../../DataTable/ClientDeductionListTable";
import Search from "../../../Assets/Icons/Search.svg";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import Validation from "../../../Validation/Validation";
import Edit from "../../../Assets/Icons/Edit.svg";
import Spinner from "../../Spinner";
import "alertifyjs/build/css/alertify.css";

function ClientDeductions() {
  const navigate = useNavigate();
  const [deductionList, setDeductionList] = useState([])
  const [deductionSearchList, setDeductionSearchList] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deductionValue, setDeductionValue] = useState({
    name: "",
  });
  const [errors, setErrors] = useState("");
  const [editStatus, setEditStatus] = useState(false);
 
  const showDeductionDetails = (listDetail) => {
    navigate(`/masters/client/deductions/details/${listDetail?.id}`);
  };

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "name", label: "Name" },
    { id: "created_at", label: "Created at" },
    { id: "more_icon", label: "Actions" },
  ];

  useEffect(() => {
    getDeduction();
  }, []);

  const getDeduction = async () => {
    try {
      setLoading(true);
      const response = await GET_API("/clientDeductionsList")
      if (response?.status === 200) {
        setDeductionList(response?.data?.data);
        setDeductionSearchList(response?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setDeductionList([]);
      setDeductionSearchList([]);
    }
  };

  const handleDeductionSearch = (e) => {
    const searchTerm = e.target.value;
    const searchedList = deductionSearchList.filter((item) => {
      for (let key in item) {
        if (
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
    setDeductionList(searchedList);
  };

  const navigateEditPage = (listDetail) => {
    navigate(`/masters/client/deductions/update/${listDetail?.id}`, {
      state: {
        list: listDetail,
      },
    });
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setDeductionValue({ ...deductionValue, [name]: value });
  };

  const addDeduction = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);

    const isValid = Validation.validateClientDeductionList(deductionValue);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      setErrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      setErrors({});
    }
    try {
      const createDeduction = await POST_API("/addclientDeductionsList", deductionValue);
      if (createDeduction.status === 200) {
        setButtonDisabled(false);
        setDeductionValue({ name: "" });
        getDeduction();
        alertify.success(createDeduction?.data?.message);
        return;
      }
    } catch (e) {
      setButtonDisabled(false);
      alertify.error(e?.response?.data?.message);
    }
  };

  const editDeduction = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.validateClientDeductionList(deductionValue);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      setErrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      setErrors({});
    }
    const editDeduction = await POST_API(`/updateClientDeductions/${deductionValue?.id}`, deductionValue);
    if (editDeduction.status === 200) {
      setButtonDisabled(false);
      setDeductionValue({ name: "" });
      getDeduction();
      setEditStatus(false);
      alertify.success(editDeduction?.data?.message);
      return;
    }
    alertify.error(editDeduction?.response?.data?.message);
    setButtonDisabled(false);
  };

  const resetForm = () => {
    setEditStatus(false);
    setDeductionValue({
      name: ""
    });
  };

  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Deduction...?",
        async function () {
          if (row_id) {
            const deleteRow = await DELETE_API(`/deleteClientDeductions/${row_id}`);
            if (deleteRow.status === 200) {
              alertify.success(deleteRow?.data?.message);
              getDeduction();
              return;
            }
            alertify.error("Failed To Delete Deduction List");
          }
        },
        function () { }
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">Clients</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Add Deduction</h4>
          <div className="search-block">
            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                onChange={(e) => handleDeductionSearch(e)}
                style={{ outline: "none", border: "none" }}
              />
              <img src={Search} alt="" />
            </div>
          </div>
          <div className="add-list-container">
            <div className="input-block">
              <label className="add-new-input-label">
                Name <span className="required-symbol">*</span>
              </label>
              <input
                type="text"
                name="name"
                autoComplete="off"
                className="p-2 border-solid border-[1px] border-primary rounded font-karla"
                placeholder="Enter Deduction Name"
                onChange={(e) => {
                  handleChange(e);
                }}
                value={deductionValue?.name}
              />
              {errors.name ? (
                <div className="text-[#FF5C42] text-sm font-medium font-karla">
                  {errors.name}
                </div>
              ) : null}
            </div>
          
            <div className="flex gap-3">
              <div className="add-new-button-block">
                {editStatus ? (
                  <button
                    className="add-new-button"
                    disabled={buttonDisabled}
                    onClick={(e) => {
                      editDeduction(e);
                    }}
                  >
                    <span className="button-label">Save</span>
                    <img src={ColorAdd} alt="add" />
                  </button>
                ) : (
                  <button className="add-new-button" disabled={buttonDisabled}>
                    <span
                      className="button-label"
                      onClick={(e) => {
                        addDeduction(e);
                      }}
                    >
                      Add New
                    </span>
                    <img src={ColorAdd} alt="add" />
                  </button>
                )}
              </div>
              <button className="edit-button" onClick={() => resetForm()}>
                <span className="button-label">Reset</span>
                <img src={Edit} alt="add" />
              </button>
            </div>
          </div>
          <ClientDeductionListTable
            list={deductionList}
            columns={columns}
            icon={More}
            multiple={false}
            editStatus={setEditStatus}
            editList={setDeductionValue}
            deleteRow={handleDelete}
          />
        </div>
      </div>
    </div>
  );
}

export default ClientDeductions;
