import React, { useState, useRef, useEffect } from "react";
import { read, utils } from "xlsx";
import alertify from "alertifyjs";
import dayjs from "dayjs";
import { GET_API, POST_API } from "../../../Services/api";
import ManualDeductionTable from "../../DataTable/ManualDeductionTable";
import Search from "../../../Assets/Icons/Search.svg";
import Add from "../../../Assets/Icons/Add.svg";
import Spinner from "../../Spinner";
import "alertifyjs/build/css/alertify.css";

function ManualDeductionList() {
    const inputFile = useRef(null);
    const [filteredDeduction, setFilteredDeduction] = useState([]);
    const [loading, setLoading] = useState(false);

    const [deductionData, setDeductionData] = useState([])
    const [currentMonth, setCurrentMonth] = useState("");

    const columns = [
        { id: "employee_code", label: "Employee Code" },
        { id: "name", label: "Employee Name" },
        { id: "amount", label: "Amount" },
        { id: "date", label: "Date" },
    ];

    const getManualDeductionList = async () => {
        try {
            setLoading(true);
            const response = await GET_API(`empOtherDeductionList`);
            if (response?.status === 200) {
                setDeductionData(response?.data?.data)
                setFilteredDeduction(response?.data?.data)
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        getManualDeductionList();
        setCurrentMonth(dayjs(new Date()).format("YYYY-MM"));
    }, [])

    const handleManualDeductionSearch = (e) => {
        const searchTerm = e.target.value;
        const searchedList = deductionData.filter((item) => {
            for (let key in item) {
                if (
                    typeof item[key] === "string" &&
                    item[key].toLowerCase().includes(searchTerm.toLowerCase())
                ) {
                    return true;
                }
            }
            return false;
        });
        setFilteredDeduction(searchedList);
    };

    const handleDateChange = (e) => {
        const { value } = e?.target
        setCurrentMonth(value);
    };

    const onImportClick = () => {
        inputFile.current.click();
    };

    const fileUpload = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (e.target.files.length) {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.onload = async (e) => {
                const wb = read(e.target.result);
                const sheets = wb.SheetNames;
                if (sheets.length) {
                    const rows = utils.sheet_to_json(wb.Sheets[sheets[0]], {
                        raw: false,
                        dateNF: "dd-mm-yyyy hh:mm:ss",
                    });
                    try {
                        const responce = await POST_API(`/importManualDeductions?year=${dayjs(currentMonth).format("YYYY")}&month=${dayjs(currentMonth).format("MM")}`, { data: rows })
                        if (responce?.status === 200) {
                            alertify.success(responce?.data?.message);
                            getManualDeductionList();
                        }
                    } catch (error) {
                        alertify.error("Some error Occured.Please try again")
                    }

                }
            };
            reader.readAsArrayBuffer(file);
        }
    };

    if (loading) {
        return <Spinner />;
    }

    return (
        <div className=" bg-[#F7F6F4] grow">
            <div className="sm-container">
                <h1 className="heading-text">Manual Deduction</h1>
                <div className="card-wrapper">
                    <h4 className="card-header">Manual Deduction List</h4>
                    <div className="search-block">
                        <div className="search">
                            <input
                                type="text"
                                className="search-input-box"
                                placeholder="Search..."
                                autoComplete="off"
                                onChange={(e) => handleManualDeductionSearch(e)}
                                style={{ outline: "none", border: "none" }}
                            />

                            <img src={Search} alt="" />
                        </div>
                        <div className="flex gap-3">
                            <div className="field-block w-44">
                                <label className="label-name">Month</label>
                                <input
                                    type="month"
                                    name="month"
                                    className="input-field"
                                    placeholder="Enter Month"
                                    onChange={handleDateChange}
                                    value={currentMonth}
                                    max={dayjs().format("YYYY-MM")}
                                />
                            </div>
                            <div className="flex items-center">
                                <div className="add-new-button-block">
                                    <input
                                        type="file"
                                        id="file"
                                        ref={inputFile}
                                        style={{ display: "none" }}
                                        onChange={fileUpload}
                                    />

                                    <button className="add-new-button" onClick={onImportClick}>
                                        <span style={{ cursor: "pointer" }}>Import</span>
                                        <img src={Add} alt="" />
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                    <ManualDeductionTable
                        list={filteredDeduction}
                        columns={columns}
                    />
                </div>
            </div>
        </div>
    );
}

export default ManualDeductionList;
