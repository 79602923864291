import React from "react";
import { Route } from "react-router-dom";

import HolidayScheme from "../HolidaySchemeMaster/HolidayScheme";
import CreateHolidayScheme from "../HolidaySchemeMaster/CreateHolidayScheme";
import EditHolidayScheme from "../HolidaySchemeMaster/EditHolidaysScheme";
import HolidaySchemeDetails from "../HolidaySchemeMaster/HolidaysSchemeDetails";

import Holiday from "../HolidayMaster/HolidayList";
import CreateHoliday from "../HolidayMaster/CreateHolidays";
import EditHoliday from "../HolidayMaster/EditHolidays";
import HolidayDetails from "../HolidayMaster/HolidayDetails";

import Leave from "../Employee/Leave";

function HolidayMasterRoutes() {
  return (
    <>
      {/**scheme */}
      <Route path="holidayscheme" element={<HolidayScheme />}></Route>
      <Route path="holidayscheme/add" element={<CreateHolidayScheme />}></Route>
      <Route path="holidayscheme/edit" element={<EditHolidayScheme />}></Route>
      <Route
        path="holidayscheme/details"
        element={<HolidaySchemeDetails />}
      ></Route>

      {/**holiday */}
      <Route path="holiday" element={<Holiday />}></Route>
      <Route path="holiday/add" element={<CreateHoliday />}></Route>
      <Route path="holiday/edit" element={<EditHoliday />}></Route>
      <Route path="holiday/details" element={<HolidayDetails />}></Route>

      {/**leave */}
      <Route path="leave" element={<Leave />}></Route>
    </>
  );
}

export default HolidayMasterRoutes;
