import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

const ImageView = ({ isOpen, handleModal, imgSrc }) => {
    return (
        <div>
            <Dialog className='testing' open={isOpen} onClose={handleModal}
                disableBackdropClick={false}
                PaperProps={{
                    style: {
                        minWidth: '600px',
                        minHeight: '400px',
                    },
                }}
                >
                <DialogTitle></DialogTitle>
                <DialogContent>
                    <img
                        src={imgSrc}
                        className='w-full h-96'
                    />
                </DialogContent>
                <DialogActions>
                    <button
                        type="button"
                        className="reset-button"
                        onClick={handleModal}
                    >
                        <span className="reset-button-label">Close</span>
                    </button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ImageView;
