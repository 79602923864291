import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import Export from "../../../Assets/Icons/Export.svg";
import { Link } from "react-router-dom";
import View from "../../../Assets/Icons/View.svg";
import AdvanceDetailsTable from "../../DataTable/AdvanceDetailsTable";
import config from "../../../Services/config.json";
import { GET_API } from "../../../Services/api";
import AdvancePrintVoucher from "./AdvancePrintVoucher";
import { useReactToPrint } from "react-to-print";
import Spinner from "../../Spinner";

function AdvanceDetails() {
  const componentRef = useRef();
  const [filteredAdvance, setFilteredAdvance] = useState([]);
  const [filteredEmployee, setFilteredEmployee] = useState([]);
  const { state } = useLocation();
  const listDetails = filteredEmployee;
  const [loading, setLoading] = useState(false);
  const [advanceList, setAdvanceList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);

  const [empDetail, setEmpDetail] = useState({});

  const [Advancedetails, setAdvancedetails] = useState([]);

  console.log("Advancedetails", Advancedetails);
  const Details = [
    {
      label: "Employee ID",
      value: listDetails?.emp_code ? listDetails?.emp_code : "-",
    },
    {
      label: "Designation",
      value: listDetails?.designation_name,
    },
    {
      label: "Employee Name",
      value: listDetails?.first_name + " " + listDetails?.fathers_name,
    },
    {
      label: "Department",
      value: listDetails?.department_name,
    },
    {
      label: "Alternate Mobile Number",
      value: listDetails?.alternate_phone_no,
    },
    {
      label: "Branch",
      value: listDetails?.branch_name,
    },
  ];

  useEffect(() => {
    getAdvanceList();
    getEmployeeDetails();
  }, []);

  const printVoucher = useReactToPrint({
    content: () => componentRef.current,
  });

  const getAdvanceList = async () => {
    setLoading(true);
    GET_API("/FinancePaymentDetailsList/" + state.list.id)
      .then(async function (data) {
        setAdvanceList(data.data.data.financePaymentDetails);
        setFilteredAdvance(data.data.data.financePaymentDetails);
        setAdvancedetails(data.data.data.FinanceDetails);
        setLoading(false);
      })

      .catch((error) => console.log(error));
  };

  const getEmployeeDetails = async () => {
    setLoading(true);
    GET_API("/FinancePaymentDetailsList/" + state.list.id)
      .then(async function (data) {
        setEmployeeList(data.data.data.employeeDetails);
        setFilteredEmployee(data.data.data.employeeDetails);
        console.log(
          "data?.data?.FinanceDetails",
          data.data.data.employeeDetails
        );
        setLoading(false);
      })

      .catch((error) => console.log(error));
  };

  const advanceDetails = [
    {
      label: "Advance Type",
      value: Advancedetails?.finance_category_name
        ? Advancedetails?.finance_category_name
        : "-",
    },
    {
      label: "Advance Amount",
      value: Advancedetails?.amount ? Advancedetails?.amount : "-",
    },
    {
      label: "Payment Type",
      value: Advancedetails?.payment_type ? Advancedetails?.payment_type : "-",
    },
    {
      label: "Due Date",
      value: Advancedetails?.due_date ? Advancedetails?.due_date : "-",
    },
  ];

  const columns = [
    { id: "s_no", label: "S.No" },
    { id: "paid_date", label: "Due Date" },
    { id: "paid_amount", label: "Paid Amount" },
    { id: "due_amount", label: "Due Amount" },
    { id: "Status", label: "Status" },
    { id: "Status", label: "Status" },
    { id: "icon", label: "" },
  ];

  if (loading) {
    return <Spinner />;
  }
  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className=" sm-container">
        <h1 className="heading-text">Advance</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Advance Details</h4>
          <div className="add-block">
            {/* <Barcode
              value={Advancedetails?.voucher_no}
              height="40"
              displayValue="false"
            /> */}
            <button
              type="button"
              className="submit-button"
              onClick={() => printVoucher()}
            >
              <span className="submit-button-label">Print</span>
            </button>
            <Link to="/expense/advance" className="view-list-button">
              <span>View List</span>
              <img src={View} alt="add" />
            </Link>
          </div>

          <div className="advance-details-image">
            <div className="advance-details-image-container">
              <div
                style={{ wordBreak: "break-word" }}
                className="text-[#7B7B7B] text-base font-karla"
              >
                <img
                  src={`${config.path}${empDetail?.photo_path}`}
                  alt=""
                  style={{ height: "120px", width: "120px" }}
                />
              </div>
            </div>
          </div>
          <div className="list-container">
            <div className="advance-section">
              {advanceDetails.map((list, i) => {
                return (
                  <div className="flex " key={i}>
                    <label className="list-label">{list.label}: </label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="advance-section-list-value"
                    >
                      {list.value}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="list-container">
            <div className="list-content-block">
              {Details.map((list, i) => {
                return (
                  <div className="list " key={i}>
                    <label className="list-label">{list.label}</label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="list-value"
                    >
                      {list.value}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {/* <div className="search-block ">
            <div className="export-btn">
              <span className="export-btn-text">Export</span>
              <img src={Export} alt="" />
            </div>
          </div> */}
          <div>
            <AdvanceDetailsTable list={filteredAdvance} columns={columns} />
          </div>
          <AdvancePrintVoucher
            ref={componentRef}
            printVoucherData={Advancedetails}
          />
        </div>
      </div>
    </div>
  );
}

export default AdvanceDetails;
