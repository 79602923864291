import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import View from "../../Assets/Icons/View.svg";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import Validation from "../../Validation/Validation";
import { POST_API } from "../../Services/api";
import Save from "../../Assets/Icons/Save.svg";

function EditEmploymentType() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const listData = state?.list;
  const [editNewEmployment, setNewEmployment] = useState({
    name: listData?.name,
    description: listData?.description,
    color: listData?.color,
    id: listData?.id,
  });
  const [errors, seterrors] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setNewEmployment(() => ({ ...editNewEmployment, [name]: value }));
  };

  const editEmploymentType = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.validateEmploymentType(editNewEmployment);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    POST_API("/updateEmployeetype", editNewEmployment)
      .then(async function (data) {
        setButtonDisabled(false);
        alertify.success(data?.data?.message);
        navigate("/masters/employementType");
      })

      .catch((error) => {
        setButtonDisabled(false);
        alertify.error(error?.response?.data?.message);
      });
  };

  const resetForm = () => {
    setNewEmployment({
      name: listData?.name,
      description: listData?.description,
      color: listData?.color,
      id: listData?.id,
    });
  };

  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">Employment Type</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Edit Employment Type</h4>
          <div className="add-block">
            <Link to="/masters/employementType" className="view-list-button">
              <span>View List</span>
              <img src={View} alt="add" />
            </Link>
          </div>

          {/***************Form************* **/}

          <form onSubmit={(e) => editEmploymentType(e)}>
            <div className="form">
              <div className="field-block">
                <label className="label-name">
                  Staffing Name
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="name"
                  placeholder="Enter name"
                  value={editNewEmployment.name}
                />
                {errors.name ? (
                  <div className="validation-error-label">{errors.name}</div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">Tag Name</label>
                <input
                  name="description"
                  className="input-field"
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter name"
                  value={editNewEmployment.description}
                />
                {errors.description ? (
                  <div className="validation-error-label">
                    {errors.description}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Tag Color
                  <span className="required-symbol">*</span>
                </label>
                <select
                  name="color"
                  onChange={(e) => handleChange(e)}
                  value={editNewEmployment?.color}
                  className="input-field"
                >
                  <option
                    value="#93b7ed"
                    style={{ backgroundColor: "#93b7ed" }}
                  >
                    Sky Blue
                  </option>
                  <option
                    value="#f5eca6"
                    style={{ backgroundColor: "#f5eca6" }}
                  >
                    yellow
                  </option>
                  <option
                    value="#6348e8"
                    style={{ backgroundColor: "#6348e8" }}
                  >
                    blue
                  </option>
                  <option
                    value="#f5b66e"
                    style={{ backgroundColor: "#f5b66e" }}
                  >
                    orange
                  </option>
                </select>
                {errors.color ? (
                  <div className="validation-error-label">{errors.color}</div>
                ) : null}
              </div>
            </div>

            <div className="submit-reset-button">
              <button
                type="button"
                className="reset-button"
                onClick={resetForm}
              >
                <span className="reset-button-label">Reset</span>
              </button>
              <button
                type="submit"
                className="submit-button"
                disabled={buttonDisabled}
              >
                <span className="submit-button-label">Save</span>
                <img src={Save} alt="" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditEmploymentType;
