import React, { useState, useEffect } from "react";
import More from "../../../Assets/Icons/More.svg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { DELETE_API, GET_API } from "../../../Services/api";
import AgentSecurity from "../../DataTable/AgentSecurity";
import Search from "../../../Assets/Icons/Search.svg";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import Spinner from "../../Spinner";

function SecurityList() {
  const navigate = useNavigate();
  const [securityList, setSecurityList] = useState([]);
  const [filteredSecurity, setFilteredSecurity] = useState([]);
  const [loading, setLoading] = useState(false);

  const showSecurityDetails = (listDetail) => {
    navigate("/masters/agent/security/details", {
      state: {
        list: listDetail,
      },
    });
  };
  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "company_name", label: "Company Name" },
    { id: "contact_person", label: "Contact Person" },
    { id: "contact_number", label: "Contact Number" },
    { id: "created_at", label: "Created at" },
    { id: "more_icon", label: "" },
  ];

  useEffect(() => {
    getSecurityList();
  }, []);
  const getSecurityList = async () => {
    setLoading(true);
    try {
      const securityList = await GET_API("/agentList/security_agent");
      if (securityList?.data?.status === 200) {
        setLoading(false);
        setSecurityList(securityList.data?.data);
        setFilteredSecurity(securityList.data?.data);
        return;
      }
    } catch (error) {
      setLoading(false);
      alertify.error("Failed to Get Security List");
    }
  };

  const handleSecuritySearch = (e) => {
    const searchTerm = e.target.value;
    const searchedList = securityList.filter((item) => {
      for (let key in item) {
        if (
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
    setFilteredSecurity(searchedList);
  };
  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Security..?",
        async function () {
          if (row_id) {
            const deleteRow = await DELETE_API(`/deleteAgents/${row_id}`);
            if (deleteRow.status === 200) {
              alertify.success(deleteRow?.data?.message);
              getSecurityList();
              return;
            }
            alertify.error("Failed To Delete Floor");
          }
        },
        function () { }
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };
  const navigateEditPage = (listDetail) => {
    navigate("/masters/agent/security/edit", {
      state: {
        list: listDetail,
      },
    });
  };
  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">Security</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Security Agent List</h4>
          <div className="search-block">
            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                onChange={(e) => handleSecuritySearch(e)}
                style={{ outline: "none", border: "none" }}
              />
              <img src={Search} alt="" />
            </div>
            <div className="flex gap-3">
              <div className="add-new-button-block">
                <Link to="/masters/agent/security/add" className="add-new-button">
                  <span>Add New</span>
                  <img src={ColorAdd} alt="add" />
                </Link>
              </div>
            </div>
          </div>
          <AgentSecurity
            list={filteredSecurity}
            columns={columns}
            showDetails={showSecurityDetails}
            icon={More}
            multiplePage={true}
            editPage={navigateEditPage}
            deleteRow={handleDelete}
          />
        </div>
      </div>
    </div>
  );
}

export default SecurityList;
