// import React, { useState } from "react";
// import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
// import Select from "react-select";
// import alertify from "alertifyjs";
// import "alertifyjs/build/css/alertify.css";
// import { GET_API } from "../../../../Services/api";
// import Spinner from "../../../Spinner";

// function EmployeeBasicInfo({ deductionData, setDeductionData, deductionsOptions }) {

//     const [isLoading, setIsLoading] = useState(false);

//     const handleDeductionSelect = (index, e, name) => {
//         const { value } = e;
//         const newItems = [...deductionData];
//         newItems[index][name] = value;
//         setDeductionData(newItems);
//         getDeductionById(value)
//     };

//     const handleAllowanceAutomatic = (index, e, name) => {
//         const { checked } = e?.target;
//         const newItems = [...deductionData];
//         newItems[index][name] = checked ? 1 : 0;
//         setDeductionData(newItems);
//     }

//     const handlePercentageChange = (index, e) => {
//         const { name, value } = e?.target;
//         const newItems = [...deductionData];
//         newItems[index][name] = value;
//         setDeductionData(newItems);
//     }

//     const handleFormulaSelect = (index, e) => {
//         const option = deductionData.map((item, i) => {
//             if (i === index) {
//                 return { ...item, name: e }
//             } else {
//                 return item
//             }
//         })
//         setDeductionData(option)
//     };

//     const handleDeductionAdd = () => {
//         setDeductionData([...deductionData, { deduction_type_id: '', is_automatic: "", amount: "" }]);
//     };

//     const getDeductionById = async (deductionId) => {
//         try {
//             setIsLoading(true);
//             const response = await GET_API(`clientDeductionById/${deductionId}`);
//             if (response?.status === 200) {
//                 const res = response?.data?.data
//                 const ded = deductionData?.map((item) => {
//                     if (item?.deduction_type_id === deductionId) {
//                         return {
//                             deduction_type_id: deductionId,
//                             is_automatic: res?.is_automatic,
//                             percentage_value: res?.percentage_value,
//                             formula_value: res?.formula_value,
//                             amount: res?.manual_calc_amount,
//                         }
//                     } else {
//                         return item
//                     }
//                 })
//                 setDeductionData(ded);
//                 setIsLoading(false);
//             }
//         } catch (error) {
//             setIsLoading(false);
//         }
//     }

//     const handleDeductionRemove = (index) => {
//         if (deductionData?.length <= 1 && index === 0) {
//             alertify.error("Unable to delete");
//         } else {
//             const newItems = [...deductionData];
//             newItems.splice(index, 1);
//             setDeductionData(newItems);
//         }

//     };


//     if (isLoading) {
//         return <Spinner />;
//     }

//     return (
//         <>
//             <form>
//                 <div className="flex employee-sub-header border-[1px] border-primary border-top">
//                     <h3 className="flex items-center text-text-dark text-lg font-semibold w-1/2">Deductions </h3>
//                     <div className="w-1/2 text-right">
//                         <button onClick={handleDeductionAdd} type="button" className="employee-add-button"><span className="submit-button-label">Add new</span></button>
//                     </div>
//                 </div>

//                 {deductionData?.map((item, index) => (
//                     <div className="p-2 grid grid-cols-6 gap-5" key={index}>
//                         <div className="field-block">
//                             <label htmlFor={`name-${index}`}>Deduction</label>
//                             <Select
//                                 id={`name-${index}`}
//                                 options={deductionsOptions}
//                                 onChange={(e) => handleDeductionSelect(index, e, "deduction_type_id")}
//                                 name="allowance"
//                                 value={deductionsOptions?.find(
//                                     (option) => option?.value === item.deduction_type_id
//                                 )}
//                                 isSearchable
//                             />
//                         </div>
//                         <div className="list">
//                             <label className="list-label">Automatic</label>
//                             <div style={{ wordBreak: "break-word" }} className="list-value">
//                                 {(item?.is_automatic === "1" || item?.is_automatic === 1) ? "True" : "False"}
//                             </div>
//                         </div>

//                         {(item?.is_automatic === "1" || item?.is_automatic === 1) ? <>
//                             <div className="list">
//                                 <label className="list-label">Percentage of</label>
//                                 <div style={{ wordBreak: "break-word" }} className="list-value">
//                                     {item?.percentage_value}
//                                 </div>
//                             </div>
//                             <div className="list">
//                                 <label className="list-label">Formula</label>
//                                 <div style={{ wordBreak: "break-word" }} className="list-value">
//                                     {item?.formula_value === "total_salary" ? "Total Salary" : item?.formula_value === "manual" ? "Manual" : "-"}
//                                 </div>
//                             </div>
//                         </> : <>
//                             <div className="list">
//                                 <label className="list-label">Percentage of</label>
//                                 <div style={{ wordBreak: "break-word" }} className="list-value">
//                                     --
//                                 </div>
//                             </div>
//                             <div className="list">
//                                 <label className="list-label">Formula</label>
//                                 <div style={{ wordBreak: "break-word" }} className="list-value">
//                                     --
//                                 </div>
//                             </div>
//                         </>
//                         }
//                         {(item?.is_automatic === "0" || item?.is_automatic === 0 || item?.is_automatic === '') ?
//                             < div className="field-block">
//                                 <label htmlFor={`name-${index}`}>Amount</label>
//                                 <input
//                                     className="input-field "
//                                     type="number"
//                                     onChange={(e) => handlePercentageChange(index, e)}
//                                     name="amount"
//                                     placeholder="Enter Amount"
//                                     value={item?.amount}
//                                     autoComplete="off"
//                                 />
//                             </div> : item?.formula_value === "manual" ?
//                                 <div className="list">
//                                     <label className="list-label">Amount</label>
//                                     <div style={{ wordBreak: "break-word" }} className="list-value">
//                                         {item?.amount} / Salary Days
//                                     </div>
//                                 </div> : <div className="list">
//                                     <label className="list-label">Amount</label>
//                                     <div style={{ wordBreak: "break-word" }} className="list-value">
//                                         --
//                                     </div>
//                                 </div>}

//                         <div className="field-block">

//                             <button type="button" className="employee-add-button mt-6 w-14" onClick={() => handleDeductionRemove(index)}>
//                                 <span className="submit-button-label"><DeleteOutlineIcon /></span>
//                             </button>
//                         </div>
//                     </div>
//                 ))}
//             </form >

//         </>
//     );
// }

// export default EmployeeBasicInfo;


import React from "react";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Checkbox from "@mui/material/Checkbox";
import Select from "react-select";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
// import { GET_API } from "../../../../Services/api";
// import Spinner from "../../../Spinner";

function EmployeeBasicInfo({ deductionData, setDeductionData, deductionsOptions, allowanceOptions }) {
    console.log("deductionData", deductionData);
    // const [deductionOption, setDeductionOption] = useState({});
    // const [isLoading, setIsLoading] = useState(false);

    const handleDeductionSelect = (index, e, name) => {
        const { value } = e;
        const newItems = [...deductionData];
        newItems[index][name] = value;
        setDeductionData(newItems);
        // getDeductionById(value)
    };

    const handleAllowanceAutomatic = (index, e, name) => {
        const { checked } = e?.target;
        const newItems = [...deductionData];
        newItems[index][name] = checked ? 1 : 0;
        newItems[index]['manual_calc_amount'] = [];
        newItems[index]['formula_value'] = '';
        setDeductionData(newItems);
    }

    const handlePercentageChange = (index, e) => {
        const { name, value } = e?.target;
        const newItems = [...deductionData];
        newItems[index][name] = value;
        setDeductionData(newItems);
    }

    const handleDeductionAdd = () => {
        setDeductionData([...deductionData, { deduction_type_id: '', is_automatic: 0, percentage: "", formula_value: '', manual_calc_amount: [] }]);
    };

    const handleFormulaSelect = (index, e, name) => {
        const { value } = e;
        const newItems = [...deductionData];
        newItems[index][name] = value;
        newItems[index]['manual_calc_amount'] = [];
        setDeductionData(newItems);
    }

    const handleManualCalcSelect = (index, e, name) => {
        const option = deductionData.map((item, i) => {
            if (i === index) {
                return { ...item, manual_calc_amount: e }
            } else {
                return item
            }
        })
        setDeductionData(option)
    }

    const handleDeductionRemove = (index) => {
        if (deductionData?.length <= 1 && index === 0) {
            alertify.error("Unable to delete");
        } else {
            const newItems = [...deductionData];
            newItems.splice(index, 1);
            setDeductionData(newItems);
        }

    };

    const formulaOptions = [
        { label: "Total Salary", value: "total_salary" },
        { label: "Manual", value: "manual" }
    ]

    return (
        <>
            <form>
                <div className="flex employee-sub-header border-[1px] border-primary border-top">
                    <h3 className="flex items-center text-text-dark text-lg font-semibold w-1/2">Deductions </h3>
                    <div className="w-1/2 text-right">
                        <button onClick={handleDeductionAdd} type="button" className="employee-add-button"><span className="submit-button-label">Add new</span></button>
                    </div>
                </div>

                {deductionData?.map((item, index) => {
                    return <div className="p-2 grid grid-cols-6 gap-5" key={index}>
                        <div className="field-block">
                            <label htmlFor={`name-${index}`}>Deduction</label>
                            <Select
                                id={`name-${index}`}
                                options={deductionsOptions}
                                onChange={(e) => handleDeductionSelect(index, e, "deduction_type_id")}
                                name="allowance"
                                value={deductionsOptions?.find(
                                    (option) => option?.value === item.deduction_type_id
                                )}
                                isSearchable
                            />
                        </div>
                        <div className="field-block">
                            <label htmlFor={`name-${index}`}>Automatic</label>
                            <Checkbox
                                style={{ color: "rgb(193,49,76)", padding: 0 }}
                                className="p-0 bg-primary-color"
                                onChange={e => handleAllowanceAutomatic(index, e, "is_automatic")}
                                inputProps={{ "aria-label": "controlled" }}
                                checked={(item?.is_automatic === "1" || item?.is_automatic === 1) ? true : false}
                            />
                        </div>
                        {(item?.is_automatic === "1" || item?.is_automatic === 1) ?
                            <div className="field-block">
                                <label htmlFor={`name-${index}`}>Percentage of</label>
                                <input
                                    className="input-field "
                                    type="text"
                                    onChange={(e) => handlePercentageChange(index, e)}
                                    name="percentage_value"
                                    placeholder="Enter Percentage of"
                                    value={item?.percentage_value}
                                    autoComplete="off"
                                />
                            </div> :
                            <div className="field-block">
                                <label htmlFor={`name-${index}`}>Amount</label>
                                <input
                                    className="input-field "
                                    type="number"
                                    onChange={(e) => handlePercentageChange(index, e)}
                                    name="amount"
                                    placeholder="Enter Amount"
                                    value={item?.amount}
                                    autoComplete="off"
                                />
                            </div>
                        }
                        {(item?.is_automatic === "1" || item?.is_automatic === 1) ?
                            <div className="field-block">
                                <label htmlFor="formula_value">Formula</label>
                                <Select
                                    id="formula_value"
                                    options={formulaOptions}
                                    onChange={(e) => handleFormulaSelect(index, e, "formula_value")}
                                    name="formula_value"
                                    value={formulaOptions.find((opt) => opt.value === item?.formula_value)}
                                    isSearchable
                                />
                            </div> : null}

                        {item?.formula_value === "manual" ?
                            <div className="field-block">
                                <label htmlFor="manual_calc_amount">Manual Calculation</label>
                                <Select
                                    id="manual_calc_amount"
                                    options={allowanceOptions}
                                    onChange={(e) => handleManualCalcSelect(index, e, "manual_calc_amount")}
                                    name="manual_calc_amount"
                                    value={item?.manual_calc_amount}
                                    isSearchable
                                    isMulti
                                />
                            </div>
                            : null}

                        <div className="field-block">
                            <button type="button" className="employee-add-button mt-6 w-14" onClick={() => handleDeductionRemove(index)}>
                                <span className="submit-button-label"><DeleteOutlineIcon /></span>
                            </button>
                        </div>
                    </div>
                })}
            </form >
        </>
    );
}

export default EmployeeBasicInfo;
