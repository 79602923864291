import React from "react";
import { Link } from "react-router-dom";
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import VerifiedIcon from '@mui/icons-material/Verified';
import Edit from "../../../Assets/Icons/Edit.svg";
import View from "../../../Assets/Icons/View.svg";
import QualificationTable from "../Tabs/Components/QualificationTable";
import { downloadEmployee } from "../Components/DownloadPdf";

function EducationalInfo({ listDetails, nextTab, viewList, handleVerify, isBtnDisable, isEdit, isDownload }) {
  const Details = [
    {
      label: "Qualification",
      value: listDetails?.employee_qualification ? listDetails?.employee_qualification : "-",
    },
    {
      label: "Passed Out",
      value: listDetails?.employee_passed_out ? listDetails?.employee_passed_out : "-",
    }
  ];

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "qualification", label: "Qualification" },
    { id: "institute_name", label: "Name of the Institution" },
    { id: "percentage", label: "Percentage of Marks" }
  ];

  return (
    <>
      <div className="add-block">
        <button className="submit-button" onClick={handleVerify} disabled={isBtnDisable}>
          <span className="submit-button-label">Verify</span>
          <VerifiedIcon style={{ color: "white" }} />
        </button>
        {isEdit ?
          <Link
            to={`/employee/edit/${listDetails?.employee_id}`}
            className="submit-button"
            state={{ list: listDetails }}
          >
            <span className="submit-button-label">Edit</span>
            <img style={{ color: "white" }} className="submit-button-label" src={Edit} alt="add" />
          </Link>
          :
          ""
        }

        {isDownload ?
          <div className="add-new-button-block">
            <button className="add-new-button" onClick={() => downloadEmployee(listDetails?.employee_id)}>
              <span>Download</span>
              <ArrowCircleDownIcon />
            </button>
          </div>
          :
          ""
        }
        <Link to={viewList} className="view-list-button">
          <span>View List</span>
          <img src={View} alt="add" />
        </Link>
      </div>
      <div className="list-container">
        <div className="list-content-block">
          {Details.map((list) => {
            return (
              <div className="list">
                <label className="list-label">{list?.label}</label>
                <div style={{ wordBreak: "break-word" }} className="list-value">
                  {list?.value}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <h4 className="card-header">Qualification Details</h4>
      <div>
        <QualificationTable
          list={listDetails?.qualification_details}
          columns={columns}
          isAction={false}
        />
      </div>
      <div className="submit-reset-button">
        <button
          type="button"
          className="reset-button"
          onClick={() => {
            nextTab("position_info");
          }}
        >
          <span className="reset-button-label">Prev</span>
        </button>
        <button
          type="submit"
          className="submit-button"
          onClick={() => {
            nextTab("bank_info");
          }}
        >
          <span className="submit-button-label">Next</span>
        </button>
      </div>
    </>
  );
}

export default EducationalInfo;
