import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import alertify from "alertifyjs";
import Select from "react-select";
import Grid from '@mui/material/Grid';
import View from "../../../../Assets/Icons/View.svg";
import { GET_API, POST_API } from '../../../../Services/api';
import AllowanceInfo from "../Tabs/AllowanceInfo";
import DeductionInfo from "../Tabs/DeductionInfo";
import Spinner from '../../../Spinner';

const ClientAllowanceDeduction = () => {
  const navigate = useNavigate();
  const { clientId, cateId, cateName } = useParams();
  const [allowanceOptions, setAllowanceOptions] = useState([])
  const [allowanceData, setAllowanceData] = useState([]);
  const [deductionData, setDeductionData] = useState([]);
  const [deductionsOptions, setDeductionsOptions] = useState([]);
  const [isBtnDisable, setIsBtnDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  //Designation dropdown option
  const [designationList, setDesignationList] = useState([]);
  const [categoryValue, setCategoryValue] = useState('');
  const [isCategoryLoading, setIsCategoryLoading] = useState(false);

  const handleValidation = async (e) => {
    e.preventDefault();
    const submitData = {
      category_id: categoryValue?.value,
      allowance: allowanceData?.map((item, i) => {
        return {
          ...item,
          amount: (item?.automatic === 1 || item?.automatic === "1" || item?.is_incentive === 1) ? null : item?.amount,
          name: item?.name?.length ? item?.name?.map(item => item?.value).toString() : "",
          automatic: item?.automatic ? Number(item?.automatic) : 0,
          is_incentive: item?.is_incentive ? Number(item?.is_incentive) : 0
        }
      }),
      deduction: deductionData?.map((item, i) => {
        return {
          amount: (item?.is_automatic === 1 || item?.is_automatic === "1") ? null : item?.amount,
          deduction_type_id: item?.deduction_type_id,
          is_automatic: item?.is_automatic,
          formula_value: item?.formula_value? item?.formula_value: '',
          manual_calc_amount: item?.manual_calc_amount?.length ? item?.manual_calc_amount?.map(item => item?.value).toString() : "",
          percentage_value: (item?.is_automatic === 1 || item?.is_automatic === "1") ? item?.percentage_value : ""
        }
      })
    }

    if (categoryValue) {
      try {
        setIsBtnDisable(true)
        const apiEndpoint = cateId ? `updateDeductionAllowance/${clientId}` : `/createDeductionAllowance/${clientId}`
        const deductions = await POST_API(apiEndpoint, submitData);
        if (deductions?.status === 200) {
          setIsBtnDisable(false);
          navigate(`/masters/client/allowance/deduction/list/${clientId}`)
          alertify.success(deductions?.data?.message)
        }
      } catch (error) {
        console.log("error", error);
        setIsBtnDisable(false)
      }
    } else {
      alertify.error("Please Select Category")
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (clientId) {
        await getByClient();
      }
    };
    fetchData();
  }, [clientId]);

  const handleOption = (value, options) => {
    if (value?.length) {
      const newArr = value.split(',').map(Number);
      const responce = options?.filter((item, i) => {
        return newArr.includes(item?.value)
      })
      return responce
    } else {
      return value
    }

  }

  useEffect(() => {
    getDesignation();
  }, []);

  const getDesignation = async () => {
    try {
      setIsCategoryLoading(true);
      const response = await GET_API("/CategoryList")
      if (response?.status === 200) {
        const categ = response?.data?.data?.map((item) => {
          return { label: item?.name, value: item?.id }
        })
        setDesignationList(categ);
        setIsCategoryLoading(false);
      }
    } catch (error) {
      setIsCategoryLoading(false);
      setDesignationList([]);
    }
  };

  const getByClient = async () => {
    try {
      setIsLoading(true);
      const allowance = await GET_API("clientAllowanceList");
      const deductions = await GET_API("clientDeductionsList");
      const allowDeduction = cateId ? await POST_API(`clientCategoryList/${clientId}/${cateId}`, { category_name: cateName }) : "";

      // allowance options    // 
      const allowanceList = allowance?.data?.data?.map((item, i) => {
        return { label: item?.name, value: item?.id }
      })

      // allowance Tab
      const allowanceRes = cateId ? allowDeduction?.data?.data[0]?.allowance.map((item, i) => {
        return {
          amount: item?.amount,
          allowance_type_id: item?.allowance_type_id, select_type: item?.select_type,
          automatic: item?.automatic, percentage: item?.percentage,
          is_incentive: item?.is_incentive,
          name: handleOption(item?.name, allowanceList)
        }
      }) : ""

      //deduction options
      const deductionsList = deductions?.data?.data?.map((item, i) => {
        return { label: item?.name, value: item?.id }
      })

      //deduction
      const deductionRes = cateId ? allowDeduction?.data?.data[0]?.deduction.map((item, i) => {
        return {
          amount: item?.is_automatic ? item?.manual_calc_amount : item?.amount,
          is_automatic: item?.is_automatic,
          percentage_value: item?.percentage_value,
          deduction_type_id: item?.deduction_type_id,
          formula_value: item?.formula_value,
          manual_calc_amount: handleOption(item?.manual_calc_amount, allowanceList)
        }
      }) : ""

      const allow = cateId ? allowanceRes : [{ allowance_type_id: '', select_type: "", automatic: 0, is_incentive: "", amount: "", percentage: "", name: '' }];
      const ded = cateId ? deductionRes : [{ deduction_type_id: "", is_automatic: 0, amount: "", manual_calc_amount: [], percentage_value: "" }];

      if (allowDeduction?.status === 200) {
        setCategoryValue({ label: allowDeduction?.data?.data[0]?.categoryName, value: Number(allowDeduction?.data?.data[0]?.categoryId) })
        setIsLoading(false);
      }
      if (allowance?.status === 200) {
        setAllowanceData(allow);
        setAllowanceOptions(allowanceList);
        setIsLoading(false);
      }
      if (deductions?.status === 200) {
        setDeductionData(ded);
        setDeductionsOptions(deductionsList);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  }

  if (isLoading || isCategoryLoading) {
    return <Spinner />;
  }

  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="flex flex-col justify-between h-full">
        <div className="sm-container">
          <h1 className="heading-text">Client</h1>
          <div className="card-wrapper p-4">
            <div className="add-block">
              <Link to={`/masters/client/allowance/deduction/list/${clientId}`} className="view-list-button">
                <span>View List</span>
                <img src={View} alt="add" />
              </Link>
            </div>
            <div className='shadow-lg border-[1px] border-primary bg-white-color shadow-lg rounded mb-4'>
              <Grid container spacing={2}>
                <Grid item xs={4} >
                  <div className='py-4 mx-2'>
                    <label className="label-name">Select Designation<span className="required-symbol">*</span></label>
                    <Select
                      options={designationList}
                      onChange={(e) => setCategoryValue(e)}
                      name="category_id"
                      className=""
                      isSearchable
                      value={categoryValue}
                    />
                  </div>

                </Grid>
              </Grid>
            </div>
            <div className='shadow-lg border-[1px] border-primary bg-white-color shadow-lg rounded mb-4'>
              <AllowanceInfo
                allowanceData={allowanceData}
                setAllowanceData={setAllowanceData}
                allowanceOptions={allowanceOptions}
              />
              <DeductionInfo
                deductionData={deductionData}
                setDeductionData={setDeductionData}
                deductionsOptions={deductionsOptions}
                allowanceOptions={allowanceOptions}
              />
              <div className="submit-reset-button">
                <button
                  type="submit"
                  className="submit-button"
                  disabled={isBtnDisable}
                  onClick={(e) => {
                    handleValidation(e);
                  }}
                >
                  <span className="submit-button-label">Save</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientAllowanceDeduction