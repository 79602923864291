import config from "../../../Services/config.json";

const baseURL = config.base_url;

export const downloadEmployee = async (empId) => {
    try {
      const response = await fetch(`${baseURL}generatePdfById/${empId}`);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', "employee.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading PDF:', error);
    }
  };
