import React, { useState, useEffect, useRef } from "react";
import Add from "../../Assets/Icons/Add.svg";
import More from "../../Assets/Icons/More.svg";
import alertify from "alertifyjs";
import { read, utils } from "xlsx";
import { useNavigate } from "react-router-dom";
import { GET_API, POST_API } from "../../Services/api";
import AttendanceListTable from "../DataTable/AttendanceListTable";
import Spinner from "../Spinner";
import Search from "../../Assets/Icons/Search.svg";
import "alertifyjs/build/css/alertify.css";

function AttendanceList() {
  const inputFile = useRef(null);
  const navigate = useNavigate();
  const [filteredBranch, setFilteredBranch] = useState([]);
  const [attendanceList, setAttendanceList] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "employee_code", label: "Employee Code" },
    { id: "employee_name", label: "Employee Name" },
    { id: "designation", label: "Designation" },
    { id: "attendance", label: "Attendance" },
    { id: "date", label: "Date" },
    { id: "in_at", label: "In At" },
    { id: "more_icon", label: "" },
  ];

  const attendanceDetailsPage = (listDetail) => {
    navigate(`/masters/attendance/details/${listDetail?.employeeCode}`, {
      state: {
        list: listDetail,
      },
    });
  };

  useEffect(() => {
    getAttendanceList();
  }, []);

  const getAttendanceList = async () => {
    setLoading(true);
    GET_API("/attendanceList")
      .then(async function (data) {
        const attendanceListArr = [];
        data?.data?.data.map((item) => {
          attendanceListArr.push(item);
        });
        setLoading(false);
        setAttendanceList(attendanceListArr);
        setFilteredBranch(attendanceListArr);
      })

      .catch((error) => {
        setLoading(false);
      });
  };

  const onImportClick = () => {
    inputFile.current.click();
  };
  const fileUpload = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (e.target.files.length) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const wb = read(e.target.result);
        const sheets = wb.SheetNames;
        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]], {
            raw: false,
            dateNF: "dd-mm-yyyy hh:mm:ss",
          });
          POST_API("/importAttendance", { data: rows })
            .then(async function (data) {
              if (data?.data?.status == true) {
                alertify.success(data?.data?.message);
                getAttendanceList();
              }
              if (data?.data?.status == false) {
                alertify.error(data?.data?.message);
              }
            })
            .catch((error) =>
              alertify.error("Some error Occured.Please try again")
            );
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleBranchSearch = (e) => {
    const searchTerm = e.target.value;
    const searchedList = attendanceList.filter((item) => {
      for (let key in item) {
        if (
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
    setFilteredBranch(searchedList);
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">Attendance</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Attendance List</h4>
          <div className="search-block">
            <div className="add-new-button-block">
              <input
                type="file"
                id="file"
                ref={inputFile}
                style={{ display: "none" }}
                onChange={fileUpload}
              />

              <button className="add-new-button" onClick={onImportClick}>
                <span style={{ cursor: "pointer" }}>Import</span>
                <img src={Add} alt="" />
              </button>
            </div>

            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                onChange={(e) => handleBranchSearch(e)}
                style={{ outline: "none", border: "none" }}
              />

              <img src={Search} alt="" />
            </div>
          </div>
          <AttendanceListTable
            list={filteredBranch}
            columns={columns}
            icon={More}
            multiple={true}
            showDetails={attendanceDetailsPage}
          />
        </div>
      </div>
    </div>
  );
}

export default AttendanceList;
