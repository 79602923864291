import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import View from "../../../Assets/Icons/View.svg";
import Save from "../../../Assets/Icons/Save.svg";
import { useLocation, useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import { POST_API, GET_API } from "../../../Services/api";
import Spinner from "../../Spinner";
import Validation from "../../../Validation/Validation";

function EditDesignation() {
  const navigate = useNavigate();

  const { state } = useLocation();
  const listData = state?.list;
  console.log(listData?.department_id);
  const [empName, setEmpName] = useState("");

  const [departmentList, setDepartmentList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [errors, seterrors] = useState({});
  const [editDesignationDetails, setEditDesignationDetails] = useState({
    id: listData?.id,
    name: listData?.name,
    DepartmentName: listData?.DepartmentName,
    department_id: listData?.department_id,
    description: listData?.description,
  });

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setEditDesignationDetails(() => ({
      ...editDesignationDetails,
      [name]: value,
    }));
  };

  const handleSelectChange = (e, name) => {
    const { value } = e;
    setEditDesignationDetails(() => ({ ...editDesignationDetails, [name]: value }));
  }

  useEffect(() => {
    getDepartmentList();
  }, []);

  const getDepartmentList = async () => {
    setLoading(true);
    try {
      const responce = await GET_API("/DepartmentList");
      if (responce?.status === 200) {
        const depList = responce?.data?.data?.map(item => { return { label: item?.name, value: item?.id } })
        setDepartmentList(depList);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleEmployee = (key, value) => {
    GET_API(`/employeeGet/${value}`)
      .then(async function (data) {
        console.log(data?.data);
        setEmpName(
          `${data?.data[0]["first_name"]} ${data?.data[0]["fathers_name"]}`
        );
      })
      .catch((error) => console.log(error));
    setEditDesignationDetails(() => ({
      ...editDesignationDetails,
      [key]: value,
    }));
  };

  const saveDesignation = async (e) => {
    setButtonDisabled(true);
    const isValid = Validation.validateDesingnation(editDesignationDetails);
    if (isValid && isValid.error) {
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      setButtonDisabled(false);
      return;
    } else {
      seterrors({});
    }
    POST_API("/updateDesignation", editDesignationDetails)
      .then(async function (data) {
        console.log("+++++++++Data++++++++");
        console.log(data);
        alertify.success(data?.data?.message);
        navigate("/masters/designation");
        setButtonDisabled(false);
      })

      .catch((error) => {
        alertify.error(error?.response?.data?.message);
        setButtonDisabled(false);
      });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">Designation</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Edit Designation</h4>
          <div className="search-block">
            <Link to="/masters/designation" className="view-list-button">
              <span>View List</span>
              <img src={View} alt="add" />
            </Link>
          </div>
          <form onSubmit={(e) => saveDesignation(e)}>
            <div className="form">
              <div className="field-block">
                <label className="label-name">Department<span className="required-symbol">*</span></label>
                <Select
                  id="department_id"
                  options={departmentList}
                  onChange={(e) => handleSelectChange(e, "department_id")}
                  name="department_id"
                  value={departmentList?.find(item => item?.value === editDesignationDetails.department_id)}
                  isSearchable
                />
                {errors.department_id ? (
                  <div className="validation-error-label">{errors.department_id}</div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  Name
                  <span className="font-bold" style={{ color: "#FF5C42" }}>
                    *
                  </span>
                </label>
                <input
                  className="input-field "
                  type="text"
                  autoComplete="off"
                  placeholder="Enter Name"
                  name="name"
                  value={editDesignationDetails.name}
                  onChange={(e) => {
                    handleEmployee("name", e.target.value);
                  }}
                />
                {errors.name ? (
                  <div className="validation-error-label">{errors.name}</div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  Description
                  <span className="font-bold" style={{ color: "#FF5C42" }}>
                    *
                  </span>
                </label>
                <textarea
                  name="description"
                  className="input-field"
                  placeholder="Enter Description"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={editDesignationDetails.description}
                  autoComplete="off"
                />
                {errors.description ? (
                  <div className="validation-error-label">
                    {errors.description}
                  </div>
                ) : null}
              </div>
            </div>
          </form>
          <div className="submit-reset-button">
            <button type="button" className="reset-button">
              <span className="reset-button-label">Reset</span>
            </button>
            <button
              type="submit"
              className="submit-button"
              onClick={() => saveDesignation()}
              disabled={buttonDisabled}
            >
              <span className="submit-button-label">Save</span>
              <img src={Save} alt="" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditDesignation;
