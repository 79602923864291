import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import MoreOption from "../MoreOption";

export default function StickyHeadTable({ list, columns, icon, deleteRow }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 320 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, i) => (
                <TableCell
                  className="table-header"
                  key={i}
                  align={column.align}
                >
                  {column?.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan="8"
                  className="table-body td-data-not-found h-[220px]"
                >
                  Data Not Found..!
                </TableCell>
              </TableRow>
            ) : (
              list
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => {
                  const createdDate = new Date(row?.created_at);
                  const year = createdDate.getFullYear();
                  const month = String(createdDate.getMonth() + 1).padStart(
                    2,
                    "0"
                  );
                  const date = String(createdDate.getDate()).padStart(2, "0");
                  const hour = createdDate.getUTCHours();
                  var ampm = hour >= 12 ? "pm" : "am";
                  const minute = createdDate.getUTCMinutes();
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={i}
                      className="table-column"
                    >
                      {
                        <TableCell className="table-body !w-[7%]">
                          {page * rowsPerPage + (i + 1)}
                        </TableCell>
                      }
                      <TableCell className="table-body">
                        {row?.first_name} {row?.fathers_name}
                      </TableCell>
                      <TableCell className="table-body">
                        {row?.empCode}
                      </TableCell>
                      <TableCell className="table-body">
                        {row?.fine_type_name}
                      </TableCell>
                      <TableCell className="table-body">
                        {row?.fine_amount}
                      </TableCell>
                      <TableCell className="table-body">
                        {row?.remarks}
                      </TableCell>
                      <TableCell className="table-body">
                        {date +
                          "-" +
                          month +
                          "-" +
                          year +
                          " " +
                          hour +
                          ":" +
                          minute +
                          " " +
                          ampm}
                      </TableCell>
                      {
                        <MoreOption
                          icon={icon}
                          row={row}
                          activeEdit={false}
                          deleteRow={deleteRow}
                        />
                      }
                    </TableRow>
                  );
                })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={list?.length ? list.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
