import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import alertify from "alertifyjs";
import View from "../../../Assets/Icons/View.svg";
import Spinner from "../../Spinner";
import { GET_API, POST_API } from "../../../Services/api";

function ViewProfileApproval() {
    const navigate = useNavigate();
    const { empId } = useParams();
    const [employeeList, setEmployeeList] = useState([]);
    const [isLeaveLoading, setIsLeaveLoading] = useState(false);
    const [isButtonDisable, setIsButtonDisable] = useState(false);

    useEffect(() => {
        if (empId) {
            getEmployeeProfile();
        }
    }, [empId]);


    const getEmployeeProfile = async () => {
        try {
            setIsLeaveLoading(true);
            const leaveList = await GET_API(`/checkstatusProfileupdate/${empId}`);
            if (leaveList?.status === 200) {
                setEmployeeList(leaveList?.data?.data[0])
                setIsLeaveLoading(false);
            }
        } catch (error) {
            setIsLeaveLoading(false);
        }
    };

    const employeeProfileUpdate = async (statusValue) => {
        try {
            setIsButtonDisable(true);
            const approveReject = await POST_API(`/updateDetailsEmployee/${employeeList?.employee_id}/${statusValue }`, );
            if (approveReject?.status === 200) {
                setIsLeaveLoading(false);
                setIsButtonDisable(false);
                alertify.success(approveReject?.data?.message);
                navigate('/approve/profile')
            }
        } catch (error) {
            setIsLeaveLoading(false);
            setIsButtonDisable(false);
        }
    };

    if (isLeaveLoading) {
        return <Spinner />;
    }
    return (
        <div className=" bg-[#F7F6F4] grow">
            <div className=" sm-container">
                <h1 className="heading-text">Profile</h1>
                <div className="card-wrapper">
                    <h4 className="card-header">Employee Profile Approval</h4>
                    <div className="add-block">
                        <div className="add-new-button-block" >
                            <button
                                onClick={() => employeeProfileUpdate(3)}
                                disabled={isButtonDisable}
                                className="add-new-button" >
                                <span>Reject</span>
                            </button>
                        </div>
                        <div className="add-new-button-block" >
                            <button
                                onClick={() => { employeeProfileUpdate(2)}}
                                disabled={isButtonDisable} className="add-new-button"  >
                                <span>Approve</span>
                            </button>
                        </div>

                        <Link to="/approve/profile" className="view-list-button">
                            <span>View List</span>
                            <img src={View} alt="add" />
                        </Link>
                    </div>

                    <div className="list-container border-bottom">
                        <div className="list-content-block">
                            <div className="list ">
                                <label className="list-label">Employee Code</label>
                                <div
                                    style={{ wordBreak: "break-word" }}
                                    className="list-value"
                                >
                                    {employeeList?.employee_code}
                                </div>
                            </div>
                            <div className="list ">
                                <label className="list-label">Employee Name</label>
                                <div
                                    style={{ wordBreak: "break-word" }}
                                    className="list-value"
                                >
                                    {employeeList?.name}
                                </div>
                            </div>
                            <div className="list ">
                                <label className="list-label">Profile Picture</label>
                                <div
                                    style={{ wordBreak: "break-word" }}
                                    className="list-value"
                                >
                                    {employeeList?.profile_picture}
                                </div>
                            </div>
                            <div className="list ">
                                <label className="list-label">Address</label>
                                <div
                                    style={{ wordBreak: "break-word" }}
                                    className="list-value"
                                >
                                    {employeeList?.address}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ViewProfileApproval;
