import React, { useState } from "react";
import TableCell from "@mui/material/TableCell";
import Action from "./Action";
import UseOutsideClick from "./OutsideClick";
function MoreOption({
  icon,
  editStatus,
  editList,
  row,
  multiple,

  deleteRow,
  paySlip,
  showDetails,
  activeDelete,
  activeEdit,
  editPage,
  allowance,
  termination,
  terminateEmployee,
  enableMoreOption,
  verification,
  download,
  allowanceDetails,
  approveLeave,
  ShowRemarks,
}) {
  const [toggle, setToggle] = useState(false);
  const actionModel = UseOutsideClick(() => {
    setToggle(false);
  });
  return (
    <>
      <TableCell
        className="table-body"
        style={{ position: "relative" }}
        onClick={() => setToggle((prev) => !prev)}
      >
        <img src={icon} alt="" style={{ float: "right", cursor: "pointer" }} />
        <div ref={actionModel}>
          {toggle && (
            <Action
              editStatus={editStatus}
              editList={editList}
              row={row}
              multiplePage={multiple}
              deleteRow={deleteRow}
              showDetails={showDetails}
              activeDelete={activeDelete}
              activeEdit={activeEdit}
              paySlip={paySlip}
              editPage={editPage}
              allowance={allowance}
              termination={termination}
              terminateEmployee={terminateEmployee}
              enableMoreOption={enableMoreOption}
              verification={verification}
              download={download}
              allowanceDetails={allowanceDetails}
              approveLeave={approveLeave}
              ShowRemarks={ShowRemarks}
            />
          )}
        </div>
      </TableCell>
    </>
  );
}

export default MoreOption;
