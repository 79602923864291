import React, { useState, useEffect } from "react";
import Select from "react-select";
import View from "../../Assets/Icons/View.svg";
import Save from "../../Assets/Icons/Save.svg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { GET_API } from "../../Services/api";
import Validation from "../../Validation/Validation";
import { POST_API } from "../../Services/api";
import alertify from "alertifyjs";
import Spinner from "../Spinner";
import "alertifyjs/build/css/alertify.css";

function CreateAgent() {
  const navigate = useNavigate();
  const [errors, seterrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [agentDetails, setAgentDetails] = useState({
    agent_id: "",
    amount: "",
    percentage: "",
    remarks: "",
  });
  const [agentType, setagentType] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setAgentDetails(() => ({ ...agentDetails, [name]: value }));
  };

  const handleSelectChange = (e, name) => {
    const { value } = e;
    setAgentDetails({ ...agentDetails, [name]: value })
  }

  const createAgentcommisson = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.validateAgent(agentDetails);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    try {
      const addAgent = await POST_API("/addAgentCommission", agentDetails);
      if (addAgent) {
        alertify.success(addAgent?.data?.data);
        navigate("/expense/agentcommission");
      }
    } catch (error) {
      alertify.error("Failed to add Agent Commision");
    }
  };

  useEffect(() => {
    getAgentType();
  }, []);

  const getAgentType = async () => {
    setLoading(true);
    try {
      const getType = await GET_API("/agentList/consultancy");
      if (getType.status === 200) {
        const list = getType?.data?.data?.map(item => { return { label: item?.company_name, value: item?.id } });
        list.unshift({ label: "Select...", value: "" });
        setagentType(list);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alertify.error("Failed to get Agent Type List...");
    }
  };

  const resetForm = () => {
    seterrors({});
  };

  if (loading) {
    return <Spinner />;
  }
  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">Agent Commission</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Add Agent Commission</h4>
          <div className="add-block">
            <Link to="/expense/agentcommission" className="view-list-button">
              <span>View List</span>
              <img src={View} alt="add" />
            </Link>
          </div>

          <form onSubmit={(e) => createAgentcommisson(e)}>
            <div className="form">
              <div className="field-block">
                <label className="label-name">
                  Agents Type
                  <span className="required-symbol">*</span>
                </label>
                <Select
                  id="agent_id"
                  options={agentType}
                  value={agentType?.find(item => item?.value === agentDetails.agent_id)}
                  onChange={(e) => handleSelectChange(e, "agent_id")}
                  name="agent_id"
                  isSearchable
                />
                {errors.agent_id ? (
                  <div className="validation-error-label">
                    {errors.agent_id}
                  </div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  Amount
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="number"
                  autoComplete="off"
                  placeholder="Enter Amount"
                  name="amount"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                {errors.amount ? (
                  <div className="validation-error-label">{errors.amount}</div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  Percentage
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="number"
                  autoComplete="off"
                  placeholder="Enter Percentage"
                  name="percentage"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                {errors.percentage ? (
                  <div className="validation-error-label">
                    {errors.percentage}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Remarks
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  autoComplete="off"
                  placeholder="Enter Remarks"
                  name="remarks"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                {errors.remarks ? (
                  <div className="validation-error-label">{errors.remarks}</div>
                ) : null}
              </div>
            </div>

            <div className="submit-reset-button">
              <button
                type="button"
                className="reset-button"
                onClick={resetForm}
              >
                <span className="reset-button-label">Reset</span>
              </button>
              <button
                type="submit"
                className="submit-button"
                disabled={buttonDisabled}
              >
                <span className="submit-button-label">Add</span>
                <img src={Save} alt="" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateAgent;
