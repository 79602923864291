import React, { useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import View from "../../../../Assets/Icons/View.svg";
import { GET_API } from '../../../../Services/api';
import Spinner from '../../../Spinner';

const ClientAllowanceDeduction = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [allowanceDeduction, setAllowanceDeduction] = useState([]);
  const [clientDetails, setClientDetails] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [isAllowanceLoading, setIsAllowanceLoading] = useState(false);
  const [isClientLoading, setIsClientLoading] = useState(false);
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getAllowanceDeduction = async () => {
    try {
      setIsAllowanceLoading(true);
      const responce = await GET_API(`/clientallowancedeductionsList/${id}`);
      if (responce?.status === 200) {
        setAllowanceDeduction(responce?.data?.data);
        setIsAllowanceLoading(false);
      }
    } catch (error) {
      setAllowanceDeduction([]);
      setIsAllowanceLoading(false);
    }
  }

  const getClientDetails = async () => {
    try {
      setIsClientLoading(true);
      const response = await GET_API(`getClientListbyId/${id}`);
      if (response?.status === 200) {
        setClientDetails(response?.data?.clientList[0]);
        setIsClientLoading(false);
      }
    } catch (error) {
      setAllowanceDeduction([]);
      setIsClientLoading(false);
    }
  }

  useEffect(() => {
    if (id) {
      getAllowanceDeduction();
      getClientDetails();
    }
  }, [id])

  const clientData = [
    {
      label: "Name",
      value: clientDetails?.name ? clientDetails?.name : "-",
    },
    {
      label: "Phone",
      value: clientDetails?.phone ? clientDetails?.phone : "-",
    },
    {
      label: "Email",
      value: clientDetails?.email ? clientDetails?.email : "-",
    },
    {
      label: "Contact Person",
      value: clientDetails?.contact_person_name ? clientDetails?.contact_person_name : "-",
    },
    {
      label: "Salary Calculation",
      value: clientDetails?.salary_calc === "fulldays" ? "Full Calendar Days" : clientDetails?.salary_calc === "weekoff" ? "Based on Week Off" : clientDetails?.salary_calc === "26days" ? "26 Days":"-",
    },
    {
      label: "Address",
      value: clientDetails?.address ? clientDetails?.address : "-",
    }
  ];

  const handleEdit = (value) => {
    console.log("value", value);
    navigate(`/masters/client/${id}/update/allowance/deduction/${value?.categoryName}/${value?.categoryId}`);
  }

  if (isClientLoading || isAllowanceLoading) {
    return <Spinner />;
  }

  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="flex flex-col justify-between h-full">
        <div className="sm-container">
          <h1 className="heading-text">Client</h1>
          <div className="card-wrapper">
            <div className="add-block border-bottom">
              <Link to={`/masters/client/create/allowance/deduction/${id}`}>
                <button className="submit-button">
                  <span className='submit-button-label'>Add Allowance Deduction</span>
                </button>
              </Link>
              <Link to="/masters/client/list" className="view-list-button">
                <span>View List</span>
                <img src={View} alt="add" />
              </Link>
            </div>
            <div className='p-4'>
              <div className='shadow-lg border-[1px] border-primary bg-white-color shadow-lg rounded mb-4'>
                <Grid container spacing={2}>
                  <Grid item xs={8} >
                    <h4 className="detaile-header">Client Details</h4>
                  </Grid>
                </Grid>

                <div className="list-container">
                  <div className="list-content-block">
                    {clientData?.map((list) => {
                      return (
                        <div className="list">
                          <label className="list-label">{list?.label}</label>
                          <div style={{ wordBreak: "break-word" }} className="list-value">
                            {list?.value}
                          </div>
                        </div>
                      );
                    })}

                  </div>
                </div>
              </div>
              <div className='shadow-lg border-[1px] border-primary bg-white-color shadow-lg rounded mb-4'>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <h4 className="detaile-header">Allowance & Deduction</h4>
                  </Grid>
                </Grid>
                <div className='px-4'>
                  {allowanceDeduction?.map((item, i) => {
                    return <Accordion expanded={expanded === i} onChange={handleAccordionChange(i)} className=' mb-3'>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography sx={{ width: '50%', flexShrink: 0 }}>
                          {item?.categoryName}
                        </Typography>
                        {expanded === i ?
                          <Typography sx={{ width: '50%', flexShrink: 0 }} className='flex justify-end'>

                            {/* <Link
                              to={`/masters/client/update/allowance/deduction/${id}`}
                            > */}
                            <button onClick={() => handleEdit(item)} className="submit-button">
                              <span className='submit-button-label'>Edit</span>
                            </button>
                            {/* </Link> */}
                          </Typography>
                          : null}
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <h4 className="detaile-header">Allowance</h4>
                          <div className="border-bottom">
                            {item?.allowance?.map((list, i) => {
                              return (
                                <div className="p-2 grid grid-cols-5 gap-5" key={i}>
                                  <div className="list " key={i}>
                                    <label className="list-label">Allowance</label>
                                    <div
                                      style={{ wordBreak: "break-word" }}
                                      className="list-value"
                                    >
                                      {list?.masterAllowanceName}
                                    </div>
                                  </div>
                                  <div className="list " key={i}>
                                    <label className="list-label">Daily/Monthly</label>
                                    <div
                                      style={{ wordBreak: "break-word" }}
                                      className="list-value"
                                    >
                                      {list?.select_type}
                                    </div>
                                  </div>
                                  <div className="list " key={i}>
                                    <label className="list-label">Automatic</label>
                                    <div
                                      style={{ wordBreak: "break-word" }}
                                      className="list-value"
                                    >
                                      {(list?.automatic === 1 || list?.automatic === "1") ? "Yes" : "No"}
                                    </div>
                                  </div>
                                  <div className="list " key={i}>
                                    <label className="list-label">Incentive</label>
                                    <div
                                      style={{ wordBreak: "break-word" }}
                                      className="list-value"
                                    >
                                      {(list?.is_incentive === 1 || list?.is_incentive === "1") ? "Yes" : "No"}
                                    </div>
                                  </div>
                                  {(list?.automatic === "1" || list?.automatic === 1) ?
                                    <div className="list " key={i}>
                                      <label className="list-label">Percentage Of</label>
                                      <div
                                        style={{ wordBreak: "break-word" }}
                                        className="list-value"
                                      >
                                        {list?.percentage}
                                      </div>
                                    </div> : ""}

                                  {(list?.automatic === "1" || list?.automatic === 1 || list?.is_incentive === 1 || list?.is_incentive === "1") ?
                                    <>
                                      <div className="list " key={i}>
                                        <label className="list-label">Formula</label>
                                        <div
                                          style={{ wordBreak: "break-word" }}
                                          className="list-value"
                                        >
                                          {list?.allowanceName?.replace(/,/g, ", ")}
                                        </div>
                                      </div>
                                    </> : ""}
                                  {(list?.automatic === "1" || list?.automatic === 1 ) ?
                                    <div className="list " key={i}>
                                      <label className="list-label">Amount</label>
                                      <div
                                        style={{ wordBreak: "break-word" }}
                                        className="list-value"
                                      >
                                        {list?.amount ? list?.amount : "--"}
                                      </div>
                                    </div> : ""}
                                </div>
                              );
                            })}
                          </div>
                          <h4 className="detaile-header">Deduction</h4>
                          <div className="">
                            {item?.deduction?.map((list, i) => {
                              return (
                                <div className="p-2 grid grid-cols-5 gap-5" key={i}>
                                  <div className="list " key={i}>
                                    <label className="list-label">Deduction</label>
                                    <div
                                      style={{ wordBreak: "break-word" }}
                                      className="list-value"
                                    >
                                      {list?.masterdeductionName}
                                    </div>
                                  </div>
                                  <div className="list " key={i}>
                                    <label className="list-label">Automatic</label>
                                    <div
                                      style={{ wordBreak: "break-word" }}
                                      className="list-value"
                                    >
                                      {(list?.is_automatic === "1" || list?.is_automatic === 1) ? "Yes" : "No"}
                                    </div>
                                  </div>
                                  {(list?.is_automatic === "1" || list?.is_automatic === 1) ?
                                    <>
                                      <div className="list " key={i}>
                                        <label className="list-label">Percentage Of</label>
                                        <div style={{ wordBreak: "break-word" }} className="list-value" >
                                          {list?.percentage_value}
                                        </div>
                                      </div>
                                      <div className="list " key={i}>
                                        <label className="list-label">Formula</label>
                                        <div style={{ wordBreak: "break-word" }} className="list-value" >
                                          {list?.formula_value === "total_salary" ? "Total Salary" : list?.formula_value === "manual" ? "Manual" : "-"}
                                        </div>
                                      </div>
                                    </> :
                                    <>
                                      <div className="list " key={i}>
                                        <label className="list-label">Percentage Of</label>
                                        <div style={{ wordBreak: "break-word" }} className="list-value" >
                                          --
                                        </div>
                                      </div>
                                      <div className="list " key={i}>
                                        <label className="list-label">Formula</label>
                                        <div style={{ wordBreak: "break-word" }} className="list-value">
                                          --
                                        </div>
                                      </div>
                                    </>
                                  }
                                  <div className="list " key={i}>
                                    <label className="list-label">Amount</label>
                                    {list?.is_automatic === 1 ?
                                      <div style={{ wordBreak: "break-word" }} className="list-value">
                                        { list?.allowanceName? list?.allowanceName?.replace(/,/g, ", ") : "--"}
                                      </div> :
                                      <div style={{ wordBreak: "break-word" }} className="list-value">
                                        {list?.amount}
                                      </div>}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientAllowanceDeduction