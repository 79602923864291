import React from 'react';
import Select from "react-select";

const districts = [
    { "label": "Ariyalur", "value": "ariyalur" },
    { "label": "Chengalpattu", "value": "chengalpattu" },
    { "label": "Chennai", "value": "chennai" },
    { "label": "Coimbatore", "value": "coimbatore" },
    { "label": "Cuddalore", "value": "cuddalore" },
    { "label": "Dharmapuri", "value": "dharmapuri" },
    { "label": "Dindigul", "value": "dindigul" },
    { "label": "Erode", "value": "erode" },
    { "label": "Kallakurichi", "value": "kallakurichi" },
    { "label": "Kancheepuram", "value": "kancheepuram" },
    { "label": "Karur", "value": "karur" },
    { "label": "Krishnagiri", "value": "krishnagiri" },
    { "label": "Madurai", "value": "madurai" },
    { "label": "Nagapattinam", "value": "nagapattinam" },
    { "label": "Namakkal", "value": "namakkal" },
    { "label": "Nilgiris", "value": "nilgiris" },
    { "label": "Perambalur", "value": "perambalur" },
    { "label": "Pudukkottai", "value": "pudukkottai" },
    { "label": "Ramanathapuram", "value": "ramanathapuram" },
    { "label": "Ranipet", "value": "ranipet" },
    { "label": "Salem", "value": "salem" },
    { "label": "Sivagangai", "value": "sivagangai" },
    { "label": "Tenkasi", "value": "tenkasi" },
    { "label": "Thanjavur", "value": "thanjavur" },
    { "label": "Theni", "value": "theni" },
    { "label": "Thiruvallur", "value": "thiruvallur" },
    { "label": "Thiruvarur", "value": "thiruvarur" },
    { "label": "Thoothukudi", "value": "thoothukudi" },
    { "label": "Tiruchirappalli", "value": "tiruchirappalli" },
    { "label": "Tirunelveli", "value": "tirunelveli" },
    { "label": "Tirupathur", "value": "tirupathur" },
    { "label": "Tiruppur", "value": "tiruppur" },
    { "label": "Tiruvannamalai", "value": "tiruvannamalai" },
    { "label": "Vellore", "value": "vellore" },
    { "label": "Viluppuram", "value": "viluppuram" },
    { "label": "Virudhunagar", "value": "virudhunagar" }
];

const stateOptions = [
    { "label": "Tamilnadu", "value": "tamilnadu" },
]


const FamilyDetailsAddress = ({ handleAddressChange, handleAddressSelectChange, errors, familyDetails }) => {
   

    return (
        <div className="p-2 grid grid-cols-3 gap-5">
            <div className="field-block">
                <label className="label-name">Door No<span className="required-symbol">*</span></label>
                <input
                    type="text"
                    id={'door_no'}
                    name={'door_no'}
                    onChange={e => handleAddressChange(e)}
                    value={familyDetails?.door_no}
                    className="input-field"
                    placeholder="Enter Door No"
                    autoComplete="off"
                />
                {errors?.door_no ?
                    <div className="validation-error-label">{errors?.door_no}</div> : null}
            </div>
            <div className="field-block">
                <label className="label-name">Street Name I<span className="required-symbol">*</span></label>
                <input
                    type="text"
                    id={"street_name_1"}
                    name={"street_name_1"}
                    onChange={e => handleAddressChange(e)}
                    value={familyDetails?.street_name_1}
                    className="input-field"
                    placeholder="Enter Street Name I"
                    autoComplete="off"
                />
                {errors?.street_name_1 ?
                    <div className="validation-error-label">{errors?.street_name_1}</div> : null}
            </div>
            <div className="field-block">
                <label className="label-name">Street Name II</label>
                <input
                    type="text"
                    id={"street_name_2"}
                    name={"street_name_2"}
                    onChange={e => handleAddressChange(e)}
                    value={familyDetails?.street_name_2}
                    className="input-field"
                    placeholder="Enter Street Name II"
                    autoComplete="off"
                />
                {errors?.street_name_2 ?
                    <div className="validation-error-label">{errors?.street_name_2}</div> : null}
            </div>
            <div className="field-block dropdown-select-to-two">
                <label className="label-name">City<span className="required-symbol">*</span></label>
                <Select
                    options={districts}
                    value={districts?.find(
                        (option) => option?.value === familyDetails?.city
                    )}
                    onChange={(e) => handleAddressSelectChange(e, "city")}
                    name={"city"}
                    className=""
                    isSearchable
                />
               
                {errors?.city ?
                    <div className="validation-error-label">{errors?.city}</div> : null}
            </div>
            <div className="field-block">
                <label className="label-name">State<span className="required-symbol">*</span></label>
                <Select
                    options={stateOptions}
                    value={stateOptions?.find(
                        (option) => option?.value === familyDetails?.state
                    )}
                    onChange={(e) => handleAddressSelectChange(e, `state`)}
                    name={`state`}
                    className=""
                    isSearchable
                />
                {errors?.state ?
                    <div className="validation-error-label">{errors?.state}</div> : null}
            </div>
            <div className="field-block">
                <label className="label-name">Pincode<span className="required-symbol">*</span></label>
                <input
                    type="text"
                    id={"pincode"}
                    name={"pincode"}
                    onChange={e => handleAddressChange(e)}
                    value={familyDetails?.pincode}
                    className="input-field"
                    placeholder="Enter Pincode"
                    autoComplete="off"
                />
                {errors?.pincode ?
                    <div className="validation-error-label">{errors?.pincode}</div> : null}
            </div>
        </div>
    );
};

export default FamilyDetailsAddress;
