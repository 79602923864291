import React, { useEffect, useState } from "react";
import PositionInfo from "./DetailsPage/PositionInfo";
import BasicInfo from "./DetailsPage/BasicInfo";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useLocation, useParams } from "react-router-dom";
import BankInfo from "./DetailsPage/BankInfo";
import EducationalInfo from "./DetailsPage/EducationalInfo.js";
import PersonalInfo from "./DetailsPage/PersonalInfo";
import { ReactComponent as Checkmark } from "../../Assets/Icons/Checkmark.svg";
import Spinner from "../Spinner";
import { GET_API } from "../../Services/api";

function EmployeeDetails() {
  const { state } = useLocation();
  const { id } = useParams()
  const [listDetails, setListDetails] = useState([]);
  const [breakDetails, setBreakDetails] = useState([]);
  const [value, setValue] = useState("basic_info");
  const [isLoading, setIsLoading] = useState(false)

  const handleTabChange = (value) => {
    setValue(value);
  };

  const handleTabHeaderChange = (dummy, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (id) {
      getEmployeeData();
    }
  }, [id]);

  const getEmployeeData = async () => {
    try {
      setIsLoading(true)
      const employee = await GET_API(`/getEmployeeTentaclesById/${id}`);
      const list = await GET_API(`/getEmployeeagainstDetails/${id}`);
      const empList = employee?.data?.data[0]
      const listData = list?.data
      await setListDetails({
        ...empList,
        id: id,
        workExperience: listData?.work_exprience_tentacles,
        familyDetails: listData?.family_details_tentacles,
        qualification_details: listData?.education_details_tentacles,
      })
      setIsLoading(false)
    } catch (error) {
      setListDetails([])
      setIsLoading(false)
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className=" sm-container">
        <h1 className="heading-text">Employee</h1>
        <div className="card-wrapper">
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList aria-label="lab API tabs example" onChange={handleTabHeaderChange}>
                  <Tab
                    style={{ fontFamily: "Montserrat" }}
                    value="basic_info"
                    icon={
                      <div
                        style={{
                          display: "flex",
                          gap: "4px",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          <Checkmark />
                        </span>
                        <span>Basic Info</span>
                      </div>
                    }
                  />
                  <Tab
                    style={{ fontFamily: "Montserrat" }}
                    value="personal_info"
                    icon={
                      <div
                        style={{
                          display: "flex",
                          gap: "4px",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          <Checkmark />
                        </span>
                        <span>Personal Info</span>
                      </div>
                    }
                  />
                  <Tab
                    style={{ fontFamily: "Montserrat" }}
                    value="position_info"
                    icon={
                      <div
                        style={{
                          display: "flex",
                          gap: "4px",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          <Checkmark />
                        </span>
                        <span>Position Info</span>
                      </div>
                    }
                  />
                  <Tab
                    style={{ fontFamily: "Montserrat" }}
                    value="education_info"
                    icon={
                      <div
                        style={{
                          display: "flex",
                          gap: "4px",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          <Checkmark />
                        </span>
                        <span>Education Info</span>
                      </div>
                    }
                  />
                  <Tab
                    style={{ fontFamily: "Montserrat" }}
                    value="bank_info"
                    icon={
                      <div
                        style={{
                          display: "flex",
                          gap: "4px",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          <Checkmark />
                        </span>
                        <span>Bank Info</span>
                      </div>
                    }
                  />
                </TabList>
              </Box>

              <TabPanel value="basic_info" key="basic_info">
                <BasicInfo
                  listDetails={listDetails}
                  isEditAndView={true}
                  nextTab={handleTabChange}
                  viewList="/employee/list"
                  isDownload={true}
                  isEdit={true}
                />
              </TabPanel>
              <TabPanel value="personal_info">
                <PersonalInfo
                  listDetails={listDetails}
                  isEditAndView={true}
                  nextTab={handleTabChange}
                  viewList="/employee/list"
                  isDownload={true}
                  isEdit={true}
                />
              </TabPanel>
              <TabPanel value="position_info">
                <PositionInfo
                  listDetails={listDetails}
                  isEditAndView={true}
                  breakDetails={breakDetails}
                  nextTab={handleTabChange}
                  viewList="/employee/list"
                  isDownload={true}
                  isEdit={true}
                />
              </TabPanel>
              <TabPanel value="education_info">
                <EducationalInfo
                  listDetails={listDetails}
                  isEditAndView={true}
                  nextTab={handleTabChange}
                  viewList="/employee/list"
                  isDownload={true}
                  isEdit={true}
                />
              </TabPanel>
              <TabPanel value="bank_info">
                <BankInfo
                  listDetails={listDetails}
                  isEditAndView={true}
                  nextTab={handleTabChange}
                  viewList="/employee/list"
                  isDownload={true}
                  isEdit={true}
                />
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
    </div>
  );
}

export default EmployeeDetails;
