import React, { useState, useEffect } from "react";
import More from "../../../Assets/Icons/More.svg";
import { Link } from "react-router-dom";
import { GET_API, POST_API, DELETE_API } from "../../../Services/api";
import Search from "../../../Assets/Icons/Search.svg";
import Validation from "../../../Validation/Validation";
import alertify from "alertifyjs";
import Edit from "../../../Assets/Icons/Edit.svg";
import Spinner from "../../Spinner";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import FineListMasterTable from "../../DataTable/FineListMasterTable";
import "alertifyjs/build/css/alertify.css";

function Fine() {
  const [fineList, setFineList] = useState([]);
  const [errors, seterrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [filteredList, setFilteredList] = useState([]);
  const [editStatus, setEditStatus] = useState(false);

  const [fineDetails, setFineDetails] = useState({
    name: "",
    description: "",
    type: "fine",
  });

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "fine_name", label: "Fine Name" },
    { id: "description", label: "Description" },
    { id: "created_at", label: "Created at" },
    { id: "more_icon", label: "" },
  ];

  useEffect(() => {
    getFineList();
  }, []);

  const getFineList = async () => {
    setLoading(true);
    GET_API("/FinanceCategoriesList/fine")
      .then((response) => {
        console.log("response", response.data?.data);
        setFineList(response?.data?.data);
        setFilteredList(response?.data?.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        alertify.error(error?.response?.data?.message);
      });
  };

  const handleFloorSearch = (e) => {
    const searchTerm = e.target.value;
    const searchedList = fineList.filter((item) => {
      for (let key in item) {
        if (
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
    setFilteredList(searchedList);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFineDetails({ ...fineDetails, [name]: value });
  };

  const addFine = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.validateFinance(fineDetails);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    const createFloor = await POST_API("/addFinanceCategory", fineDetails);
    if (createFloor.status === 200) {
      setButtonDisabled(false);
      setFineDetails({ name: "", description: "" });
      getFineList();
      alertify.success(createFloor?.data?.message);
      return;
    }
    alertify.error(createFloor?.response?.data?.message);
  };

  const editFine = async (e) => {
    setButtonDisabled(true);
    e.preventDefault();
    const isValid = Validation.validateFinance(fineDetails);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    const editFloor = await POST_API("/updateCategories", fineDetails);
    if (editFloor.status === 200) {
      setButtonDisabled(false);
      setFineDetails({ name: "", description: "" });
      getFineList();
      setEditStatus(false);
      alertify.success(editFloor?.data?.message);
      return;
    }
    alertify.error(editFloor?.response?.data?.message);
  };

  const resetForm = () => {
    setEditStatus(false);
    setFineDetails({ ...fineDetails, name: "", description: "" });
    seterrors({});
  };

  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Fine..?",
        async function () {
          if (row_id) {
            try {
              const deleteRow = await DELETE_API(
                `/deleteFinanceCategories/${row_id}`
              );
              if (deleteRow.status === 200) {
                alertify.success(deleteRow?.data?.message);
                getFineList();
                return;
              }
            } catch (error) {
              alertify.error("Failed To Delete Fine");
            }
          }
        },
        function () {}
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">Fine</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Add Fine</h4>
          <div className="search-block">
            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                onChange={(e) => handleFloorSearch(e)}
                style={{ outline: "none", border: "none" }}
              />

              <img src={Search} alt="" />
            </div>
          </div>
          <div className="add-list-container">
            <div className="input-block">
              <label className="add-new-input-label">
                Fine Name <span className="required-symbol">*</span>
              </label>

              <input
                type="text"
                name="name"
                className="input-field"
                placeholder="Enter Floor Name"
                onChange={(e) => {
                  handleChange(e);
                }}
                autoComplete="off"
                value={fineDetails.name}
              />
              {errors.name ? (
                <div className="text-[#FF5C42] text-sm font-medium font-karla">
                  {errors.name}
                </div>
              ) : null}
            </div>
            <div className="input-block">
              <label className="add-new-input-label">Description</label>
              <textarea
                name="description"
                className="input-field"
                placeholder="Enter Description"
                onChange={(e) => {
                  handleChange(e);
                }}
                value={fineDetails.description}
                autoComplete="off"
              />
              {errors.description ? (
                <div className="text-[#FF5C42] text-sm font-medium font-karla">
                  {errors.description}
                </div>
              ) : null}
            </div>
            <div className="flex gap-3">
              <div className="add-new-button-block">
                {editStatus ? (
                  <button
                    className="add-new-button"
                    onClick={(e) => {
                      editFine(e);
                    }}
                    disabled={buttonDisabled}
                  >
                    <span className="button-label">Save</span>
                    <img src={ColorAdd} alt="add" />
                  </button>
                ) : (
                  <button
                    className="add-new-button"
                    onClick={(e) => {
                      addFine(e);
                    }}
                    disabled={buttonDisabled}
                  >
                    <span className="button-label">Add New</span>
                    <img src={ColorAdd} alt="add" />
                  </button>
                )}
              </div>
              <Link className="edit-button" onClick={() => resetForm()}>
                <span className="button-label">Reset</span>
                <img src={Edit} alt="add" />
              </Link>
            </div>
          </div>

          <FineListMasterTable
            list={filteredList}
            columns={columns}
            icon={More}
            editStatus={setEditStatus}
            editList={setFineDetails}
            multiple={false}
            deleteRow={handleDelete}
          />
        </div>
      </div>
    </div>
  );
}

export default Fine;
