import React, { useEffect, useState } from "react";
import alertify from "alertifyjs";
import { useParams } from "react-router-dom";
import Select from "react-select";
import Validation from "../../../Validation/Validation";
import WorkEexperienceTable from "./Components/WorkExperienceTable";
import FamilyDetailsTable from "./Components/FamilyDetailsTable";
import FamilyDetailsAddress from "./Components/FamilyDetailsAddress";
import FileUpload from "./Components/File";
import More from "../../../Assets/Icons/More.svg";
import { DELETE_API, POST_API } from "../../../Services/api";
import "alertifyjs/build/css/alertify.css";
import { css } from "@emotion/react";
import { ClipLoader } from "react-spinners";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

function EmployeePersonalInfo({ stateChanger, empDetails, nextTab, baseurl, getEmployeeData }) {
  const { id } = useParams()
  const [isBtnDisable, setIsBtnDisable] = useState(false)
  const [workId, setWorkId] = useState(1)
  const [familyId, setFamilyId] = useState(1)
  const [workExp, setWorkExp] = useState({
    company_name: "",
    agency_name: "",
    period_from: "",
    period_to: "",
    experience: "",
    rank: "",
    reason_for_termination: ""
  })
  const [workExpErrors, setWorkExpErrors] = useState({});
  const [familyDetails, setFamilyDetails] = useState(
    {
      name: "",
      relationship: "",
      contact_no: "",
      door_no: "",
      street_name_1: "",
      street_name_2: "",
      city: "",
      state: "",
      pincode: "",
    }
  );

  const [familyDetailsErrors, setFamilyDetailsErrors] = useState({})
  const [errors, seterrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    stateChanger(() => ({ ...empDetails, [name]: value }));
  };

  const handleSelectChange = (e, name) => {
    const { value } = e;
    stateChanger(() => ({ ...empDetails, [name]: value }));
  };

  const handleValidation = async (e) => {
    e.preventDefault();
    setIsBtnDisable(true)
    const isValid = Validation.validateEmployeePersonalInfo(empDetails);
    if (isValid && isValid.error) {
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      setIsBtnDisable(false)
      return;
    } else {
      seterrors({});
      try {
        const personalInfo = await POST_API(`/updateEmployeeTentacles/${id}`, empDetails);
        if (personalInfo?.data?.status === 200) {
          setIsBtnDisable(false)
          nextTab("position_info");
        }
      } catch (error) {
        console.log("error");
        setIsBtnDisable(false)
      }
    }
  };

  const handleExperienceChange = (e) => {
    const { name, value } = e.target;
    setWorkExp(() => ({ ...workExp, [name]: value }));
    setWorkExpErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleFamilyChange = (e) => {
    const { name, value } = e.target;
    setFamilyDetails(() => ({ ...familyDetails, [name]: value }));
    setFamilyDetailsErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleFamilySelectChange = (e, name) => {
    const { value } = e;
    setFamilyDetails(() => ({ ...familyDetails, [name]: value }));
    setFamilyDetailsErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleWorkExperience = async (e) => {
    e.preventDefault();
    setWorkId((prevState) => prevState + 1)
    const isValid = Validation.validateEmpWorkExperience(workExp);
    if (isValid && isValid.error) {
      const errors = {};
      isValid.error.details.forEach((detail) => {
        const key = detail.path[0];
        const message = detail.message;
        errors[key] = message;
      });
      setWorkExpErrors(errors);
      return;
    } else {
      if (!id) {
        setWorkExpErrors({});
        stateChanger((prevEmpDetails) => {
          const newEmpDetails = {
            ...prevEmpDetails,
            workExperience: prevEmpDetails?.workExperience || [],
          };

          return {
            ...newEmpDetails,
            workExperience: [...newEmpDetails.workExperience, { ...workExp, id: workId }],
          };
        });
        setWorkExp({
          company_name: "",
          agency_name: "",
          period_from: "",
          period_to: "",
          rank: "",
          reason_for_termination: ""
        })
      } else {
        try {
          const createWork = await POST_API("/createworkExprienceDetails", { ...workExp, employee_id: Number(id) });
          if (createWork.status === 200) {
            alertify.success(createWork?.data?.message);
            getEmployeeData()
          }
        } catch (error) {
          alertify.error(error?.response?.message);
        }
      }
    }
  }

  const handleProfileImage = (e) => {
    const { name } = e?.target;
    const file = e?.target?.files[0];

    if (file) {
      const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];
      const maxSizeKB = 5120;

      if (allowedTypes.includes(file.type)) {
        if (file.size / 1024 <= maxSizeKB) {
          const fileReader = new FileReader();

          fileReader.onload = () => {
            if (fileReader.readyState === 2) {
              stateChanger({ ...empDetails, [name]: fileReader?.result });
              seterrors({})
            }
          };
          if (file.type.includes('image')) {
            fileReader.readAsDataURL(file);
          } else if (file.type === 'application/pdf') {
            fileReader.readAsDataURL(file);
          }
        } else {
          seterrors({ [name]: "File size exceeds 5 MB limit" })
        }
      } else {
        seterrors({ [name]: "Invalid file type. Please choose a valid image (png, jpg, jpeg) or PDF file" })
      }
    }
  };


  const handleFamilyDetails = async (e) => {
    setFamilyId((prevState) => prevState + 1)
    e.preventDefault();
    const isValid = Validation.validateEmpFamilyDetails(familyDetails);
    if (isValid && isValid.error) {
      const errors = {};
      isValid.error.details.forEach((detail) => {
        const key = detail.path[0];
        const message = detail.message;
        errors[key] = message;
      });
      setFamilyDetailsErrors(errors);
      return;
    } else {
      if (!id) {
        setFamilyDetailsErrors({});
        stateChanger((prevEmpDetails) => {
          const newEmpDetails = {
            ...prevEmpDetails,
            familyDetails: prevEmpDetails?.familyDetails || [],
          };

          return {
            ...newEmpDetails,
            familyDetails: [...newEmpDetails.familyDetails, { ...familyDetails, id: familyId }],
          };
        });
        const familyAddress = empDetails?.same_as_permanent_address ? true : false
        setFamilyDetails({
          name: "",
          relationship: "",
          contact_no: "",
          door_no: familyAddress ? empDetails?.permanent_address_door_no : "",
          street_name_1: familyAddress ? empDetails?.permanent_address_street_name : "",
          street_name_2: familyAddress ? empDetails?.permanent_address_street_name_two : "",
          city: familyAddress ? empDetails?.permanent_address_city : "",
          state: familyAddress ? empDetails?.permanent_address_state : "",
          pincode: familyAddress ? empDetails?.permanent_address_pincode : "",
        })
      } else {
        try {
          const createFamily = await POST_API("/createFamilyDetails", { ...familyDetails, employee_id: Number(id) });
          if (createFamily.status === 200) {
            alertify.success(createFamily?.data?.message);
            getEmployeeData()
          }
        } catch (error) {
          alertify.error(error?.response?.message);
        }
      }
    }
  }

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "company_name", label: "Company Name" },
    { id: "agency_name", label: "Agency Name" },
    { id: "period_from", label: "Period" },
    { id: "experience", label: "Experience" },
    { id: "rank", label: "Rank" },
    { id: "reason", label: "Reason for Termination" },
    { id: "", label: "Action" },
  ];

  const familyColumns = [
    { id: "s.no", label: "S.No" },
    { id: "name", label: "Name" },
    { id: "relationship", label: "Relationship" },
    { id: "contact_no", label: "Contact No" },
    { id: "address", label: "Address" },
    { id: "", label: "Action" },
  ];

  const BloodPressureOptions = [
    { label: "High", value: "high" },
    { label: "Low", value: "low" },
    { label: "Normal", value: "normal" }
  ]
  const dropdownOptions = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" }
  ]

  const familyOptions = [
    { label: "Mother", value: "mother" },
    { label: "Father", value: "father" },
    { label: "Daughter", value: "daughter" },
    { label: "Son", value: "son" },
    { label: "Sister", value: "sister" },
    { label: "Brother", value: "brother" },
    { label: "Aunt", value: "aunt" },
    { label: "Uncle", value: "Uncle" },
    { label: "Niece", value: "niece" },
    { label: "Nephew", value: "nephew" },
    { label: "Cousin", value: "Cousin" },
    { label: "Grandmother", value: "grandmother" },
    { label: "Grandfather", value: "grandfather" },
    { label: "Others", value: "others" },
  ]



  useEffect(() => {
    const familyAddress = empDetails?.same_as_permanent_address ? true : false
    if (familyAddress) {
      setFamilyDetails({
        ...familyDetails,
        door_no: familyAddress ? empDetails?.permanent_address_door_no : "",
        street_name_1: familyAddress ? empDetails?.permanent_address_street_name : "",
        street_name_2: familyAddress ? empDetails?.permanent_address_street_name_two : "",
        city: familyAddress ? empDetails?.permanent_address_city : "",
        state: familyAddress ? empDetails?.permanent_address_state : "",
        pincode: familyAddress ? empDetails?.permanent_address_pincode : "",
      })
    }
  }, [empDetails?.same_as_permanent_address])

  useEffect(() => {
    if (workExp.period_from && workExp.period_to) {

      const fromDate = new Date(workExp.period_from);
      const toDate = new Date(workExp.period_to);

      let years = toDate.getFullYear() - fromDate.getFullYear();
      let monthandYear = toDate.getMonth() - fromDate.getMonth();

      if (toDate.getDate() < fromDate.getDate()) {
        monthandYear--;
      }

      if (monthandYear < 0) {
        years--;
        monthandYear = 12 + monthandYear;
      }
      const exp = (`${years} ${years > 2 ? "Years" : "Year"} ${monthandYear} ${monthandYear > 2 ? "Months" : "Month"}`);
      setWorkExp({ ...workExp, experience: exp })
    }
  }, [workExp.period_from, workExp.period_to])

  const handleWorkDelete = (row_id) => {
    if (!id) {
      const delWork = empDetails?.workExperience.filter(item => item?.id !== row_id);
      stateChanger({ ...empDetails, workExperience: [...delWork] });
      alertify.success("Deleted Successfully!..")
    } else {
      alertify
        .confirm(
          "Confirm",
          "Confirm to Delete Work Experience..?",
          async function () {
            if (row_id) {
              try {
                const deleteRow = await DELETE_API(`/deleteWorkexprience/${row_id}`);
                if (deleteRow.status === 200) {
                  alertify.success(deleteRow?.data?.message);
                  getEmployeeData();
                  return;
                }
              } catch (error) {
                alertify.error("Failed To Delete Work Experience");
              }
            }
          },
          function () { }
        )
        .set("movable", false)
        .set("labels", { ok: "Delete!", cancel: "Cancel!" });
    }
  }

  const handleFamilyDelete = (row_id) => {
    if (!id) {
      const delWork = empDetails?.familyDetails.filter(item => item?.id !== row_id);
      stateChanger({ ...empDetails, familyDetails: [...delWork] });
      alertify.success("Deleted Successfully!..")
    } else {
      alertify
        .confirm(
          "Confirm",
          "Confirm to Delete Family Details..?",
          async function () {
            if (row_id) {
              try {
                const deleteRow = await DELETE_API(`/deletefamilyDetails/${row_id}`);
                if (deleteRow.status === 200) {
                  alertify.success(deleteRow?.data?.message);
                  getEmployeeData();
                  return;
                }
              } catch (error) {
                alertify.error("Failed To Delete Family Details");
              }
            }
          },
          function () { }
        )
        .set("movable", false)
        .set("labels", { ok: "Delete!", cancel: "Cancel!" });
    }
  };

  return (
    <>
      <div>
        <form>
          <div className="flex employee-sub-header">
            <h3 className="text-text-dark text-lg font-semibold w-1/2">Work Experience </h3>
            <div className="w-1/2 text-right">
              <button onClick={e => handleWorkExperience(e)} type="submit" className="employee-add-button"><span className="submit-button-label">Add new</span></button>
            </div>
          </div>
          <div className="p-2 grid grid-cols-3 gap-5">
            <div className="field-block">
              <label className="label-name">Company Name<span className="required-symbol">*</span></label>
              <input
                name="company_name"
                className="input-field"
                type="text"
                onChange={(e) => handleExperienceChange(e)}
                placeholder="Enter Company Name"
                autoComplete="off"
                value={workExp?.company_name}
              />
              {workExpErrors.company_name ? (
                <div className="validation-error-label">{workExpErrors.company_name}</div>
              ) : null}
            </div>
            <div className="field-block">
              <label className="label-name">Agency Name<span className="required-symbol">*</span></label>
              <input
                name="agency_name"
                className="input-field"
                type="text"
                onChange={(e) => handleExperienceChange(e)}
                placeholder="Enter Agency Name"
                autoComplete="off"
                value={workExp?.agency_name}
              />
              {workExpErrors.agency_name ? (
                <div className="validation-error-label">{workExpErrors.agency_name}</div>
              ) : null}
            </div>
            <div className="field-block">
              <label className="label-name">
                Period
                <span className="required-symbol">*</span>
              </label>
              <div className="flex gap-1">
                <input
                  className="input-field "
                  type="date"
                  autoComplete="off"
                  name="period_from"
                  value={workExp?.period_from}
                  onChange={(e) => handleExperienceChange(e)}
                />
                <label className="label-name">to</label>
                <input
                  className="input-field "
                  type="date"
                  autoComplete="off"
                  name="period_to"
                  value={workExp?.period_to}
                  onChange={(e) => handleExperienceChange(e)}
                />
              </div>
              {workExpErrors?.period_from ? (
                <div className="validation-error-label">{workExpErrors?.period_from}</div>
              ) : null}
              {workExpErrors?.period_to ? (
                <div className="validation-error-label">{workExpErrors?.period_to}</div>
              ) : null}
            </div>
            <div className="field-block">
              <label className="label-name">Experience</label>
              <input
                name="experience"
                className="input-field"
                type="text"
                // onChange={(e) => handleExperienceChange(e)}
                placeholder="Enter Rank"
                autoComplete="off"
                value={workExp?.experience}
                disabled
                readOnly
              />
            </div>
            <div className="field-block">
              <label className="label-name">Rank<span className="required-symbol">*</span></label>
              <input
                name="rank"
                className="input-field"
                type="text"
                onChange={(e) => handleExperienceChange(e)}
                placeholder="Enter Rank"
                autoComplete="off"
                value={workExp?.rank}
              />
              {workExpErrors.rank ? (
                <div className="validation-error-label">{workExpErrors.rank}</div>
              ) : null}
            </div>
            <div className="field-block">
              <label className="label-name">Reason for Termination</label>
              <textarea
                name="reason_for_termination"
                className="input-field"
                type="text"
                onChange={(e) => handleExperienceChange(e)}
                placeholder="Enter Reason for Termination"
                autoComplete="off"
                value={workExp?.reason_for_termination}
              />
              {workExpErrors.reason_for_termination ? (
                <div className="validation-error-label">{workExpErrors.reason_for_termination}</div>
              ) : null}
            </div>
          </div>
        </form>
        <div>
          <WorkEexperienceTable
            list={empDetails?.workExperience}
            columns={columns}
            icon={More}
            multiple={false}
            deleteRow={handleWorkDelete}
            isAction={true}
          />
        </div>
        {/* Family details */}
        <form>
          <div className="flex employee-sub-header">
            <h3 className="text-text-dark text-lg font-semibold w-1/2">Family Details</h3>
            <div className="w-1/2 text-right">
              <button onClick={e => handleFamilyDetails(e)} type="submit" className="employee-add-button"><span className="submit-button-label">Add new</span></button>
            </div>
          </div>
          <div className="p-2 grid grid-cols-3 gap-5">
            <div className="field-block dropdown-select-to-one">
              <label className="label-name">RelationShip<span className="required-symbol">*</span></label>
              <Select
                options={familyOptions}
                value={familyOptions?.find(
                  (option) => option?.value === familyDetails.relationship
                )}
                onChange={(e) => handleFamilySelectChange(e, "relationship")}
                name="relationship"
                className=""
                isSearchable
              />
              {familyDetailsErrors.relationship ? (
                <div className="validation-error-label">{familyDetailsErrors.relationship}</div>
              ) : null}
            </div>
            <div className="field-block">
              <label className="label-name">Name<span className="required-symbol">*</span></label>
              <input
                name="name"
                className="input-field"
                type="text"
                onChange={(e) => handleFamilyChange(e)}
                placeholder="Enter Name"
                autoComplete="off"
                value={familyDetails?.name}
              />
              {familyDetailsErrors.name ? (
                <div className="validation-error-label">{familyDetailsErrors.name}</div>
              ) : null}
            </div>
            <div className="field-block">
              <label className="label-name">Contact No</label>
              <input
                name="contact_no"
                className="input-field"
                type="text"
                onChange={(e) => handleFamilyChange(e)}
                placeholder="Enter Contact No"
                autoComplete="off"
                value={familyDetails?.contact_no}
              />
              {familyDetailsErrors.contact_no ? (
                <div className="validation-error-label">{familyDetailsErrors.contact_no}</div>
              ) : null}
            </div>
          </div>
          <FamilyDetailsAddress empDetails={empDetails?.familyDetails} handleAddressChange={handleFamilyChange} errors={familyDetailsErrors} familyDetails={familyDetails} />
        </form>
        <div>
          <FamilyDetailsTable
            list={empDetails?.familyDetails}
            columns={familyColumns}
            icon={More}
            multiple={false}
            deleteRow={handleFamilyDelete}
            isAction={true}
          />
        </div>
        {/* Health Information */}
        <form>
          <h3 className="card-header">Health Information</h3>
          <div className="p-2 grid grid-cols-3 gap-5">
            <div className="field-block">
              <label className="label-name">Height<span className="required-symbol">*</span></label>
              <input
                name="height"
                className="input-field"
                type="text"
                onChange={(e) => handleChange(e)}
                placeholder="Enter Height"
                autoComplete="off"
                value={empDetails?.height}
              />
              {errors.height ? (
                <div className="validation-error-label">{errors.height}</div>
              ) : null}
            </div>
            <div className="field-block">
              <label className="label-name">Weight<span className="required-symbol">*</span></label>
              <input
                name="weight"
                className="input-field"
                type="text"
                onChange={(e) => handleChange(e)}
                placeholder="Enter Weight"
                autoComplete="off"
                value={empDetails?.weight}
              />
              {errors.weight ? (
                <div className="validation-error-label">{errors.weight}</div>
              ) : null}
            </div>
            <div className="field-block">
              <label className="label-name">Blood Pressure<span className="required-symbol">*</span></label>
              <Select
                options={BloodPressureOptions}
                value={BloodPressureOptions?.find(
                  (option) => option?.value === empDetails.blood_pressure
                )}
                onChange={(e) => handleSelectChange(e, "blood_pressure")}
                name="blood_pressure"
                className=""
                isSearchable
              />
              {errors.blood_pressure ? (
                <div className="validation-error-label">{errors.blood_pressure}</div>
              ) : null}
            </div>
            <div className="field-block">
              <label className="label-name">Diabetes<span className="required-symbol">*</span></label>
              <Select
                options={dropdownOptions}
                value={dropdownOptions?.find(
                  (option) => option?.value === empDetails.diabets
                )}
                onChange={(e) => handleSelectChange(e, "diabets")}
                name="diabets"
                className=""
                isSearchable
              />
              {errors.diabets ? (
                <div className="validation-error-label">{errors.diabets}</div>
              ) : null}
            </div>
            <div className="field-block">
              <label className="label-name">Cardiac/ Heart problems<span className="required-symbol">*</span></label>
              <Select
                options={dropdownOptions}
                value={dropdownOptions?.find(
                  (option) => option?.value === empDetails.heart_problem
                )}
                onChange={(e) => handleSelectChange(e, "heart_problem")}
                name="heart_problem"
                className=""
                isSearchable
              />
              {errors.heart_problem ? (
                <div className="validation-error-label">{errors.heart_problem}</div>
              ) : null}
            </div>
            <div className="field-block">
              <label className="label-name">Skin Disease<span className="required-symbol">*</span></label>
              <Select
                options={dropdownOptions}
                value={dropdownOptions?.find(
                  (option) => option?.value === empDetails.skin_disease
                )}
                onChange={(e) => handleSelectChange(e, "skin_disease")}
                name="skin_disease"
                className=""
                isSearchable
              />
              {errors.skin_disease ? (
                <div className="validation-error-label">{errors.skin_disease}</div>
              ) : null}
            </div>
            {/* <div className="field-block">
              <label className="label-name">Diabetes<span className="required-symbol">*</span></label>
              <input
                name="diabets"
                className="input-field"
                type="text"
                onChange={(e) => handleChange(e)}
                placeholder="Enter Diabetes"
                autoComplete="off"
                value={empDetails?.diabets}
              />
              {errors.diabets ? (
                <div className="validation-error-label">{errors.diabets}</div>
              ) : null}
            </div> 
            <div className="field-block">
              <label className="label-name">Cardiac/ Heart problems<span className="required-symbol">*</span></label>
              <input
                name="heart_problem"
                className="input-field"
                type="text"
                onChange={(e) => handleChange(e)}
                placeholder="Enter Cardiac/ Heart problems"
                autoComplete="off"
                value={empDetails?.heart_problem}
              />
              {errors.heart_problem ? (
                <div className="validation-error-label">{errors.heart_problem}</div>
              ) : null}
            </div>
            <div className="field-block">
              <label className="label-name">Skin Disease<span className="required-symbol">*</span></label>
              <input
                name="skin_disease"
                className="input-field"
                type="text"
                onChange={(e) => handleChange(e)}
                placeholder="Enter Skin disease"
                autoComplete="off"
                value={empDetails?.skin_disease}
              />
              {errors.skin_disease ? (
                <div className="validation-error-label">{errors.skin_disease}</div>
              ) : null}
            </div>*/}
          </div>

          {/* Documents */}
          <h3 className="card-header">Basic Documents</h3>
          <div className="p-2 grid grid-cols-3 gap-5">
            <FileUpload
              label="Aadhar"
              value={empDetails?.aadhar_image}
              imgSrc={baseurl}
              name="aadhar_image"
              isRequired={true}
              errors={errors?.aadhar_image}
              handleFileChange={handleProfileImage}
              accept=".jpeg,.jpg,.png,.pdf"
            />
            <FileUpload
              label="School TC"
              value={empDetails?.school_tc}
              imgSrc={baseurl}
              name="school_tc"
              isRequired={false}
              errors={errors?.school_tc}
              handleFileChange={handleProfileImage}
              accept=".jpeg,.jpg,.png,.pdf"
            />
            <FileUpload
              label="SSLC Certificate"
              value={empDetails?.sslc_Certificate}
              imgSrc={baseurl}
              name="sslc_Certificate"
              isRequired={false}
              errors={errors?.sslc_Certificate}
              handleFileChange={handleProfileImage}
              accept=".jpeg,.jpg,.png,.pdf"
            />
            <FileUpload
              label="HSC Certificate"
              value={empDetails?.hsc_certificate}
              imgSrc={baseurl}
              name="hsc_certificate"
              isRequired={false}
              errors={errors?.hsc_certificate}
              handleFileChange={handleProfileImage}
              accept=".jpeg,.jpg,.png,.pdf"
            />
            <FileUpload
              label="Degree Certificate"
              value={empDetails?.degree_certificate}
              imgSrc={baseurl}
              name="degree_certificate"
              isRequired={false}
              errors={errors?.degree_certificate}
              handleFileChange={handleProfileImage}
              accept=".jpeg,.jpg,.png,.pdf"
            />
            <FileUpload
              label="Addtional Course Certificate One"
              value={empDetails?.additional_course_certificate_one_image}
              imgSrc={baseurl}
              name="additional_course_certificate_one_image"
              isRequired={false}
              errors={errors?.additional_course_certificate_one_image}
              handleFileChange={handleProfileImage}
              accept=".jpeg,.jpg,.png,.pdf"
            />
            <FileUpload
              label="Addtional Course Certificate Two"
              value={empDetails?.additional_course_certificate_two_image}
              imgSrc={baseurl}
              name="additional_course_certificate_two_image"
              isRequired={false}
              errors={errors?.additional_course_certificate_two_image}
              handleFileChange={handleProfileImage}
              accept=".jpeg,.jpg,.png,.pdf"
            />
            <FileUpload
              label="Addtional Course Certificate Three"
              value={empDetails?.additional_course_certificate_three_image}
              imgSrc={baseurl}
              name="additional_course_certificate_three_image"
              isRequired={false}
              errors={errors?.additional_course_certificate_three_image}
              handleFileChange={handleProfileImage}
              accept=".jpeg,.jpg,.png,.pdf"
            />
            <FileUpload
              label="Addtional Course Certificate Four"
              value={empDetails?.additional_course_certificate_four_image}
              imgSrc={baseurl}
              name="additional_course_certificate_four_image"
              isRequired={false}
              errors={errors?.additional_course_certificate_four_image}
              handleFileChange={handleProfileImage}
              accept=".jpeg,.jpg,.png,.pdf"
            />

            <FileUpload
              label="Ex-Servicemen Discharge Book"
              value={empDetails?.ex_serviceman_dischare_book_image}
              imgSrc={baseurl}
              name="ex_serviceman_dischare_book_image"
              isRequired={false}
              errors={errors?.ex_serviceman_dischare_book_image}
              handleFileChange={handleProfileImage}
              accept=".jpeg,.jpg,.png,.pdf"
            />
            <FileUpload
              label="Work Experience Certificate One"
              value={empDetails?.work_Exprience_certificate_one_image}
              imgSrc={baseurl}
              name="work_Exprience_certificate_one_image"
              isRequired={false}
              errors={errors?.work_Exprience_certificate_one_image}
              handleFileChange={handleProfileImage}
            />
            <FileUpload
              label="Work Experience Certificate Two"
              value={empDetails?.work_Exprience_certificate_two_image}
              imgSrc={baseurl}
              name="work_Exprience_certificate_two_image"
              isRequired={false}
              errors={errors?.work_Exprience_certificate_two_image}
              handleFileChange={handleProfileImage}
              accept=".jpeg,.jpg,.png,.pdf"
            />
            <FileUpload
              label="Work Experience Certificate Three"
              value={empDetails?.work_Exprience_certificate_three_image}
              imgSrc={baseurl}
              name="work_Exprience_certificate_three_image"
              isRequired={false}
              errors={errors?.work_Exprience_certificate_three_image}
              handleFileChange={handleProfileImage}
              accept=".jpeg,.jpg,.png,.pdf"
            />
            <FileUpload
              label="Work Experience Certificate Four"
              value={empDetails?.work_Exprience_certificate_four_image}
              imgSrc={baseurl}
              name="work_Exprience_certificate_four_image"
              isRequired={false}
              errors={errors?.work_Exprience_certificate_four_image}
              handleFileChange={handleProfileImage}
              accept=".jpeg,.jpg,.png,.pdf"
            />
            <FileUpload
              label="Driving License"
              value={empDetails?.driving_license_image_upload}
              imgSrc={baseurl}
              name="driving_license_image_upload"
              isRequired={false}
              errors={errors?.driving_license_image_upload}
              handleFileChange={handleProfileImage}
              accept=".jpeg,.jpg,.png,.pdf"
            />
            <FileUpload
              label="Police Verification Certificate"
              value={empDetails?.police_verification_certificate_image}
              imgSrc={baseurl}
              name="police_verification_certificate_image"
              isRequired={true}
              errors={errors?.police_verification_certificate_image}
              handleFileChange={handleProfileImage}
              accept=".jpeg,.jpg,.png,.pdf"
            />
            <div className="field-block">
              <label className="label-name">Driving License Number<span className="required-symbol">*</span></label>
              <input
                name="driving_license"
                className="input-field"
                type="text"
                onChange={(e) => handleChange(e)}
                placeholder="Enter Driving License Number"
                autoComplete="off"
                value={empDetails?.driving_license}
              />
              {errors.driving_license ? (
                <div className="validation-error-label">{errors.driving_license}</div>
              ) : null}
            </div>
            <div className="field-block">
              <label className="label-name">Police Verification Certificate Number<span className="required-symbol">*</span></label>
              <input
                name="police_verification_certificate_number"
                className="input-field"
                type="text"
                onChange={(e) => handleChange(e)}
                placeholder="Enter Police Verification Certificate Number"
                autoComplete="off"
                value={empDetails?.police_verification_certificate_number}
              />
              {errors.police_verification_certificate_number ? (
                <div className="validation-error-label">{errors.police_verification_certificate_number}</div>
              ) : null}
            </div>
          </div>
        </form>
      </div>
      <div className="submit-reset-button">
        <button disabled={isBtnDisable} type="button" className="reset-button" onClick={() => nextTab("basic_info")} >
          <span className="reset-button-label">Prev</span>
        </button>
        <button className="submit-button" disabled={isBtnDisable} onClick={(e) => handleValidation(e)}>
          {isBtnDisable ?
            <ClipLoader
              css={override}
              size={20}
              color={"#fff"}
              loading={isBtnDisable}
            /> : ""}
          <span className="submit-button-label">{isBtnDisable ? "Loading..." : "Save"}</span>
        </button>
        <button className="reset-button" disabled={isBtnDisable} onClick={() => nextTab("position_info")}>
          <span className="sreset-button-label">Next</span>
        </button>
      </div>
    </>
  );
}

export default EmployeePersonalInfo;
