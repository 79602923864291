import React from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import VerifiedIcon from '@mui/icons-material/Verified';
import Edit from "../../../Assets/Icons/Edit.svg";
import View from "../../../Assets/Icons/View.svg";
import { downloadEmployee } from "../Components/DownloadPdf";
import config from "../../../Services/config.json";

function BasicInfo({ listDetails, nextTab, viewList, handleVerify, isBtnDisable, isEdit, isDownload }) {
  const imgPath = config?.path
  const Details = [
    {
      label: "Date of Joining",
      value: dayjs(listDetails?.joining_date).format("DD-MM-YYYY"),
    },
    {
      label: "Employee Name",
      value: listDetails?.name,
    },
    {
      label: "Employee Code",
      value: listDetails?.employee_code ? listDetails?.employee_code : "-",
    },
    {
      label: "Referred By",
      value: listDetails?.refrred_by ? listDetails?.refrred_by : "-",
    },
    {
      label: "Father Name",
      value: listDetails?.father_name ? listDetails?.father_name : "-",
    },
    {
      label: "Mother Name",
      value: listDetails?.mother_name ? listDetails?.mother_name : "-",
    },
    {
      label: "Aadhar Number",
      value: listDetails?.aadhar_no ? listDetails?.aadhar_no : "-",
    },
    {
      label: "Date of Birth",
      value: dayjs(listDetails?.date_of_birth).format("DD-MM-YYYY "),
    },
    {
      label: "Age",
      value: listDetails?.age ? listDetails?.age : "-",
    },
    {
      label: "Email Id",
      value: listDetails?.email_id ? listDetails?.email_id : "-",
    },
    {
      label: "Mobile Number",
      value: listDetails?.mobile_number ? listDetails?.mobile_number : "-",
    },
    {
      label: "Gender",
      value: listDetails?.gender ? listDetails?.gender : "-",
    },
    {
      label: "Marital Status",
      value: listDetails?.marital_status ? listDetails?.marital_status : "-",
    }
  ];

  const permanentAddress = [
    {
      label: "Door No",
      value: listDetails?.permanent_address_door_no ? listDetails?.permanent_address_door_no : "-",
    },
    {
      label: "Street Name I",
      value: listDetails?.permanent_address_street_name ? listDetails?.permanent_address_street_name : "-",
    },
    {
      label: "Street Name II",
      value: listDetails?.permanent_address_street_name_two ? listDetails?.permanent_address_street_name_two : "-",
    },
    {
      label: "City",
      value: listDetails?.permanent_address_city ? listDetails?.permanent_address_city : "-",
    },
    {
      label: "State",
      value: listDetails?.permanent_address_state ? listDetails?.permanent_address_state : "-",
    },
    {
      label: "Pincode",
      value: listDetails?.permanent_address_pincode ? listDetails?.permanent_address_pincode : "-",
    },
  ];

  const presentAddress = [
    {
      label: "Door No",
      value: listDetails?.present_address_door_no ? listDetails?.present_address_door_no : "-",
    },
    {
      label: "Street Name I",
      value: listDetails?.present_address_street_name ? listDetails?.present_address_street_name : "-",
    },
    {
      label: "Street Name II",
      value: listDetails?.present_address_street_name_two ? listDetails?.present_address_street_name_two : "-",
    },
    {
      label: "City",
      value: listDetails?.present_address_city ? listDetails?.present_address_city : "-",
    },
    {
      label: "State",
      value: listDetails?.present_address_state ? listDetails?.present_address_state : "-",
    },
    {
      label: "Pincode",
      value: listDetails?.present_address_pincode ? listDetails?.present_address_pincode : "-",
    },
  ];

  return (
    <>
      <div className="add-block">
        <button className="submit-button" onClick={handleVerify} disabled={isBtnDisable}>
          <span className="submit-button-label">Verify</span>
          <VerifiedIcon style={{ color: "white" }} />
        </button>
        {isEdit ?
          <Link
            to={`/employee/edit/${listDetails?.employee_id}`}
            className="submit-button"
            state={{ list: listDetails }}
          >
            <span className="submit-button-label">Edit</span>
            <img style={{ color: "white" }} className="submit-button-label" src={Edit} alt="add" />
          </Link>
          :
          ""
        }
        {isDownload ?
          <div className="add-new-button-block">
            <button className="add-new-button" onClick={() => downloadEmployee(listDetails?.employee_id)}>
              <span>Download</span>
              <ArrowCircleDownIcon />
            </button>
          </div>
          :
          ""
        }
        <Link to={viewList} className="view-list-button">
          <span>View List</span>
          <img src={View} alt="add" />
        </Link>
      </div>

      <div className="list-container border-bottom">
        <div className="flex">
          <div className="list-container">
            <label className="list-label">Profile Picture</label>
            <div className="w-40 p-1 border border-[#D9D9D9]" >
              <img style={{ height: "156px", width: "156px" }} src={imgPath + listDetails?.profile_picture} />
            </div>
          </div>
        </div>
        <div className="list-content-block">
          {Details.map((list) => {
            return (
              <div className="list">
                <label className="list-label">{list?.label}</label>
                <div style={{ wordBreak: "break-word" }} className="list-value">
                  {list?.value}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <h4 className="detaile-header">Present Address</h4>
      <div className="list-container border-bottom">
        <div className="list-content-block">
          {presentAddress?.map((list) => {
            return (
              <div className="list">
                <label className="list-label">{list?.label}</label>
                <div style={{ wordBreak: "break-word" }} className="list-value">
                  {list?.value}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <h4 className="detaile-header">Permanent Address</h4>
      <div className="list-container">
        <div className="list-content-block">
          {permanentAddress?.map((list) => {
            return (
              <div className="list">
                <label className="list-label">{list?.label}</label>
                <div style={{ wordBreak: "break-word" }} className="list-value">
                  {list?.value}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="submit-reset-button">
        <button
          type="submit"
          className="submit-button"
          onClick={() => {
            nextTab("personal_info");
          }}
        >
          <span className="submit-button-label">Next</span>
        </button>
      </div>
    </>
  );
}

export default BasicInfo;
