import React, { useState, useEffect } from "react";
import alertify from "alertifyjs";
import Select from "react-select";
import { GET_API } from "../../../Services/api";
import Search from "../../../Assets/Icons/Search.svg";
import ClientWiseEmployeeTable from "../../DataTable/ClientWiseEmployee";
import Spinner from "../../Spinner";
import Filter from "../../../Assets/Icons/Filter.svg";
import "alertifyjs/build/css/alertify.css";

function ClientWiseEmployee() {
    const [employeeList, setEmployeeList] = useState([]);

    // client
    const [clientId, setClienId] = useState({});
    const [clientList, setClientList] = useState([]);
    const [employeeFilter, setemployeeFilter] = useState([]);
    const [employeetype, setEmployeetype] = useState([]);
    const [branchList, setBranchList] = useState([]);
    const [department, setDepartment] = useState([]);
    const [designation, setDesignation] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState(false);
    const [filterList, setfilterList] = useState({
        branch_id: "",
        emp_type_id: "",
        gender: "",
        department_id: "",
        designation_id: "",
        joining_from_date: "",
        joining_to_date: "",
    });

    const header = [
        { id: "s.no", label: "S.No" },
        { id: "profile_picture", label: "Profile Picture" },
        { id: "name", label: "Employee Name" },
        { id: "emp_code", label: "Employee Code" },
        { id: "mobile_number", label: "Mobile No" },
        { id: "employeejoining_date", label: "Joining Date" },
    ];

    useEffect(() => {
        getBranchList();
        getEmployeeType();
        getDepartmentList();
        getDesignationList();
        getClientsList();
    }, []);

    useEffect(() => {
        if (clientId?.value) {
            getEmployeeList();
        }
    }, [clientId]);

    const getClientsList = async () => {
        setLoading(true);
        try {
            const response = await GET_API("clientLists")
            if (response?.status === 200) {
                const cliRes = response?.data?.data?.map((item) => {
                    return { label: item?.name, value: item?.id }
                })
                setClientList(cliRes)
                setLoading(false);
            }
        } catch (error) {
            setClientList([])
            setLoading(false);
        }
    };

    const getEmployeeList = async () => {
        setLoading(true);
        try {
            const employeeList = await GET_API(`/getClientBasedEmployeeReport/${clientId?.value}`);
            console.log("employeeList", employeeList);
            if (employeeList.status === 200) {
                setLoading(false);
                setEmployeeList(employeeList?.data?.clientList);
            }
        } catch (e) {
            alertify.error("Failed to get Employee List");
            setLoading(false);
        }
    };

    const getBranchList = async () => {
        setLoading(true);
        try {
            const branchList = await GET_API("/branchList");
            if (branchList.status === 200) {
                setBranchList(branchList?.data?.data);
                setLoading(false);
            }
        } catch (e) {
            setLoading(false);
            alertify.error("Failed to Get Branch List");
        }
    };

    const getEmployeeType = async () => {
        setLoading(true);
        try {
            const empType = await GET_API("/employeetype");
            if (empType.status === 200) {
                setEmployeetype(empType?.data?.data);
                setLoading(false);
            }
        } catch (e) {
            setLoading(false);
            alertify.error("Failed to Get Employee Type List");
        }
    };

    const getDepartmentList = async () => {
        setLoading(true);
        try {
            const departmentList = await GET_API("/DepartmentList");
            if (departmentList.status === 200) {
                setDepartment(departmentList.data?.data);
                setLoading(false);
            }
        } catch (e) {
            setLoading(false);
            alertify.error("Failed to Get Department List");
        }
    };

    const getDesignationList = async () => {
        setLoading(true);
        try {
            const designationList = await GET_API("/designationList");
            if (designationList.status === 200) {
                setDesignation(designationList?.data?.data);
                setLoading(false);
            }
        } catch (e) {
            setLoading(false);
            alertify.error("Failed to Get Department List");
        }
    };

    const genderList = [
        { key: "Male", value: "male" },
        { key: "Female", value: "female" },
    ];

    const handleFilter = async (e) => {
        const { name, value } = e.target;
        filterList[name] = value;
        const list = { ...filterList };
        Object.keys(list).forEach((key) => {
            if (list[key] === "") {
                delete list[key];
            }
        });
        let concateURL = "/employeereport?";
        Object.keys(list).forEach((key) => {
            const newURL = `${key}=${list[key]}`;
            if (concateURL === "") {
                concateURL += newURL;
            } else {
                concateURL += "&" + newURL;
            }
        });
        const filteredList = await GET_API(concateURL);

        if (filteredList.status === 200) {
            setEmployeeList(filteredList?.data?.data);
        }
    };

    const handleEmployeeSearch = (e) => {
        const searchTerm = e.target.value;
        const searchedList = employeeFilter.filter((item) => {
            for (let key in item) {
                if (
                    typeof item[key] === "string" &&
                    item[key].toLowerCase().includes(searchTerm.toLowerCase())
                ) {
                    return true;
                }
            }
            return false;
        });
        setEmployeeList(searchedList);
    };

    if (loading) {
        return <Spinner />;
    }

    return (
        <div className="bg-[#F7F6F4] grow">
            <div className="flex flex-col justify-between h-full">
                <div className="sm-container">
                    <h1 className="heading-text">Reports</h1>
                    <div className="card-wrapper">
                        <h4 className="card-header">Client Wise Employee Report List</h4>
                        <div className="search-block">
                            <div className="search">
                                <input
                                    type="text"
                                    className="search-input-box"
                                    placeholder="Search..."
                                    autoComplete="off"
                                    style={{ outline: "none", border: "none" }}
                                    onChange={(e) => handleEmployeeSearch(e)}
                                />
                                <img src={Search} alt="" />
                            </div>
                            <div className="flex gap-3">
                                <div className="w-[230px] field-block dropdown-select-to-one ">
                                   <label className="label-name">Select Client</label>
                                    <Select
                                        options={clientList}
                                        value={clientId}
                                        onChange={(e) => setClienId(e)}
                                        name="client_id"
                                        className=""
                                        isSearchable
                                    />
                                </div>
                                <div
                                    className="filter-button"
                                    onClick={() => setFilter((prev) => !prev)}
                                >
                                    <span className="filter-btn-label">Filter</span>
                                    <img src={Filter} alt="" />
                                </div>
                            </div>
                        </div>
                        {filter && (
                            <form>
                                <div className="form ">
                                    <div className="field-block">
                                        <label className="label-name">
                                            Branch Name
                                        </label>
                                        <select
                                            name="branch_id"
                                            className="input-field"
                                            onChange={(e) => {
                                                handleFilter(e);
                                            }}
                                        >
                                            <option value="">Select Branch</option>
                                            {branchList?.map((branch, i) => {
                                                return (
                                                    <option key={i} value={branch?.id}>
                                                        {branch?.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                    <div className="field-block">
                                        <label className="label-name">
                                            Employee Type
                                        </label>
                                        <select
                                            name="emp_type_id"
                                            className="input-field"
                                            onChange={(e) => {
                                                handleFilter(e);
                                            }}
                                        >
                                            <option value="">Select Employee Type</option>
                                            {employeetype?.map((employee, i) => {
                                                return (
                                                    <option key={i} value={employee?.id}>
                                                        {employee?.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                    <div className="field-block">
                                        <label className="label-name">
                                            Gender
                                        </label>
                                        <select
                                            name="gender"
                                            className="input-field"
                                            onChange={(e) => {
                                                handleFilter(e);
                                            }}
                                        >
                                            <option value="">Select Gender</option>
                                            {genderList?.map((item, i) => {
                                                return (
                                                    <option key={i} value={item?.value}>
                                                        {item?.key}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                    <div className="field-block">
                                        <label className="label-name">
                                            Department
                                        </label>
                                        <select
                                            name="department_id"
                                            className="input-field"
                                            onChange={(e) => {
                                                handleFilter(e);
                                            }}
                                        >
                                            <option value="">Select Department</option>
                                            {department?.map((item, i) => {
                                                return (
                                                    <option key={i} value={item?.id}>
                                                        {item?.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                    <div className="field-block">
                                        <label className="label-name">
                                            Designation
                                        </label>
                                        <select
                                            name="designation_id"
                                            className="input-field"
                                            onChange={(e) => {
                                                handleFilter(e);
                                            }}
                                        >
                                            <option value="">Select Designation</option>
                                            {designation?.map((item, i) => {
                                                return (
                                                    <option key={i} value={item?.id}>
                                                        {item?.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                    <div className="field-block">
                                        <label className="label-name">
                                            Joining Date
                                        </label>
                                        <div className="flex gap-1 justify-center items-center">
                                            <input
                                                className="input-field "
                                                type="date"
                                                autoComplete="off"
                                                name="joining_from_date"
                                                onChange={(e) => {
                                                    handleFilter(e);
                                                }}
                                            />
                                            <label className="label-name">to</label>
                                            <input
                                                className="input-field "
                                                type="date"
                                                autoComplete="off"
                                                name="joining_to_date"
                                                onChange={(e) => {
                                                    handleFilter(e);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )}
                        <ClientWiseEmployeeTable
                            list={employeeList}
                            columns={header}

                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ClientWiseEmployee;
