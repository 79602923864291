import React, { useState, useEffect } from "react";
import More from "../../Assets/Icons/More.svg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { DELETE_API, GET_API } from "../../Services/api";
import UserTable from "../../Components/DataTable/UserTable";
import Search from "../../Assets/Icons/Search.svg";
import ColorAdd from "../../Assets/Icons/ColorAdd.svg";
import Spinner from "../../Components/Spinner";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";

function UserList() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [filteredUser, setFilteredUser] = useState([]);
  const [userList, setuserList] = useState([]);

  const showDepartmentDetails = (listDetail) => {
    navigate("/masters/user/details", {
      state: {
        list: listDetail,
      },
    });
  };

  const navigateEditPage = (listDetail) => {
    navigate("/masters/user/edit", {
      state: {
        list: listDetail,
      },
    });
  };

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "emp_name", label: "Employee Name" },
    { id: "emp_code", label: "Employee Code" },
    { id: "email", label: "Email" },
    { id: "role_name", label: "Role name" },
    { id: "created_at", label: "Created at" },
  ];

  useEffect(() => {
    getUserList();
  }, []);

  const getUserList = async () => {
    setLoading(true);
    try {
      const users = await GET_API(`/userListDropdown`);
      console.log("users", users);
      if (users?.status === 200) {
        setFilteredUser(users?.data?.data);
        setuserList(users?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      setFilteredUser([]);
      setuserList([]);
      setLoading(false);
      alertify?.error("Failed to get User list...");
    }
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    const searchedList = userList.filter((item) => {
      for (let key in item) {
        if (
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
    setFilteredUser(searchedList);
  };

  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete User..?",
        async function () {
          if (row_id) {
            const deleteRow = await DELETE_API(`/user/${row_id}`);
            if (deleteRow.status === 200) {
              alertify.success(deleteRow?.data?.message);
              getUserList();
              return;
            }
            alertify.error("Failed To User Floor");
          }
        },
        function () { }
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="card-wrapper">
          <h4 className="card-header">User List</h4>
          <div className="search-block">
            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                onChange={(e) => handleSearch(e)}
                style={{ outline: "none", border: "none" }}
              />
              <img src={Search} alt="" />
            </div>
            <div className="add-new-button-block">
              <Link to="/masters/user/add" className="add-new-button">
                <span>Add New</span>
                <img src={ColorAdd} alt="add" />
              </Link>
            </div>
          </div>

          {/**************** * Table ********************/}
          <UserTable
            list={filteredUser}
            columns={columns}
            icon={More}
            multiple={true}
            editPage={navigateEditPage}
            showDetails={showDepartmentDetails}
            deleteRow={handleDelete}
          />
        </div>
      </div>
    </div>
  );
}

export default UserList;
