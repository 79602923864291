import React, { useState, useEffect } from "react";
import LeaveApproveTable from "../../DataTable/LeaveApproveTable";
import Search from "../../../Assets/Icons/Search.svg";
import Spinner from "../../Spinner";
import { GET_API } from "../../../Services/api";
import { useNavigate } from "react-router-dom";
import More from "../../../Assets/Icons/More.svg";
import "alertifyjs/build/css/alertify.css";

function LeaveApproval() {
  const navigate = useNavigate();
  const userId = localStorage?.getItem("user_id");
  const [loading, setLoading] = useState(false);
  const [leaveList, setLeaveList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);

  useEffect(() => {
    getLeaveList();
  }, []);

  const columns = [
    { field: "id", label: "S.No", minWidth: 75 },
    { field: "employee_name", label: "Employee Name", minWidth: 200 },
    { field: "EmployeeCode", label: "Employee Code", minWidth: 200 },
    { field: "department_name", label: "Department Name", minWidth: 200 },
    { field: "designation_name", label: "Designation Name", minWidth: 200 },
    { field: "no_of_days", label: "Days", minWidth: 200 },
    { field: "", label: "Action", minWidth: 200 },
  ];

  const getLeaveList = async () => {
    setLoading(true);
    try {
      const leaveList = await GET_API(`/employeeLeaveapprovalList?login=${userId}`);
      if (leaveList?.status === 200) {
        setLeaveList(leaveList?.data?.data);
        setFilteredList(leaveList?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleUserSearch = (e) => {
    const searchTerm = e.target.value;
    const searchedList = leaveList.filter((item) => {
      for (let key in item) {
        if (
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
    setFilteredList(searchedList);
  };

  const handleApproveLeave=(listDetail)=>{
   navigate(`/approve/leave/${listDetail?.employee_id}/${listDetail?.id}`,
    {
      state: {
        list: listDetail,
      },
    });
  }

  if (loading) {
    return <Spinner />;
  }
  
  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">Leave</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Employee Leaves</h4>
          <div className="search-block">
            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                onChange={(e) => handleUserSearch(e)}
                style={{ outline: "none", border: "none" }}
              />
              <img src={Search} alt="" />
            </div>
          </div>
          {/**************** * Table ********************/}
           <LeaveApproveTable
            list={filteredList}
            columns={columns}
            icon={More}
            multiple={false}
            activeEdit={false}
            activeDelete={false}
            approveLeave={handleApproveLeave}
          />
        </div>
      </div>
    </div>
  );
}

export default LeaveApproval;


// import React, { useState, useEffect } from "react";
// import alertify from "alertifyjs";
// import LeaveApproveTable from "../../DataTable/LeaveApproveTable";
// import Search from "../../../Assets/Icons/Search.svg";
// import Spinner from "../../Spinner";
// import { GET_API, POST_API } from "../../../Services/api";
// import "alertifyjs/build/css/alertify.css";

// function LeaveApproval() {
//   const [loading, setLoading] = useState(false);
//   const [leaveList, setLeaveList] = useState([]);
//   const [selectedRows, setSelectedRows] = useState([]);
//   const [buttonDisabled, setButtonDisabled] = useState(false);

//   useEffect(() => {
//     getLeaveList();
//   }, []);

//   const columns = [
//     { field: "id", headerName: "S.No", minWidth: 75 },
//     { field: "emploee_code", headerName: "Employee Code", minWidth: 200 },
//     { field: "employee_name", headerName: "Employee Name", minWidth: 200 },
//     { field: "from", headerName: "From", minWidth: 150 },
//     { field: "to", headerName: "To", minWidth: 150 },
//   ];

//   const getLeaveList = async () => {
//     setLoading(true);
//     try {
//       const leaveList = await GET_API("/masterLog");
//       if (leaveList?.status === 200) {
//         setLeaveList(leaveList?.data[0]);
//         setLoading(false);
//       }
//     } catch (error) {
//       setLoading(false);
//     }
//   };

//   if (loading) {
//     return <Spinner />;
//   }
  
//   const handleApprove = async (type) => {
//     const data = {
//       selectedRows,
//       appType:type,
//     };
//     if (selectedRows.length <= 0) {
//       alertify
//         .alert("Please select Row to Approve/Reject")
//         .setHeader("<b> Alert </b> ");
//       return;
//     }
//     try {
//       const approveMasters = await POST_API("/approveRejectMasterLog", data);
//       if (approveMasters?.status === 200) {
//         alertify.success(`Masters ${data?.type}d successfully...!`);
//         getLeaveList();
//         return;
//       }
//     } catch (error) {
//       alertify.error(`Failed to ${data?.type}d Masters`);
//     }
//   };
  
//   return (
//     <div className=" bg-[#F7F6F4] grow">
//       <div className="sm-container">
//         <h1 className="heading-text">Leave</h1>
//         <div className="card-wrapper">
//           <h4 className="card-header">Leave Logs</h4>
//           <div className="search-block">
//                 <div
//                   className="add-new-button-block"
//                   onClick={() => {
//                     handleApprove("reject");
//                   }}
//                 >
//                   <button
//                     to=""
//                     className="add-new-button"
//                     // disabled={selectedRows?.length >=1 ? false:true}
//                   >
//                     <span>Reject</span>
//                   </button>
//                 </div>
//                 <div
//                   className="add-new-button-block"
//                   onClick={() => {
//                     handleApprove("approve");
//                   }}
//                 >
//                   <button
//                     to=""
//                     className="add-new-button"
//                     // disabled={selectedRows?.length >=1 ? false:true}
//                   >
//                     <span>Approve</span>
//                   </button>
//                 </div>
//             <div className="search">
//               <input
//                 type="text"
//                 className="search-input-box"
//                 placeholder="Search..."
//                 autoComplete="off"
//                 style={{ outline: "none", border: "none" }}
//               />
//               <img src={Search} alt="" />
//             </div>
//           </div>
//           {/**************** * Table ********************/}
//           <LeaveApproveTable
//             columns={columns}
//             list={leaveList}
//             setSelectedRows={setSelectedRows}
//           />
//         </div>
//       </div>
//     </div>
//   );
// }

// export default LeaveApproval;
