import React from "react";
import { Link } from "react-router-dom";
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import VerifiedIcon from '@mui/icons-material/Verified';
import Edit from "../../../Assets/Icons/Edit.svg";
import View from "../../../Assets/Icons/View.svg";
import { downloadEmployee } from "../Components/DownloadPdf";

function PositionInfo({ listDetails, nextTab, viewList, handleVerify, isBtnDisable, isEdit, isDownload }) {

  const Details = [
    {
      label: "Client Name",
      value: listDetails?.client_id ? listDetails?.client_id : "-",
    },
    {
      label: "Designation Name",
      value: listDetails?.category_id ? listDetails?.category_id : "-",
    },
    {
      label: "Employee Type",
      value: listDetails?.employee_type
        ? listDetails?.employee_type
        : "-",
    },
    {
      label: "Department Name",
      value: listDetails?.departmentname ? listDetails?.departmentname : "-",
    },
    // {
    //   label: "Designation",
    //   value: listDetails?.designationName ? listDetails?.designationName : "-",
    // },

    {
      label: "Branch Name",
      value: listDetails?.branch_id ? listDetails?.branch_id : "-",
    },
    {
      label: "Floor",
      value: listDetails?.floorName ? listDetails?.floorName : "-",
    },
    {
      label: "Shift",
      value: listDetails?.shift_id ? listDetails?.shift_id : "-",
    },
    {
      label: "Reporting",
      value: listDetails?.reporting_to ? listDetails?.reporting_to : "-",
    },
    {
      label: "Holiday Scheme",
      value: listDetails?.holiday_scheme_id
        ? listDetails?.holiday_scheme_id
        : "-",
    },

  ];

  return (
    <>
      <div className="add-block">
        <button className="submit-button" onClick={handleVerify} disabled={isBtnDisable}>
          <span className="submit-button-label">Verify</span>
          <VerifiedIcon style={{ color: "white" }} />
        </button>
        {isEdit ?
          <Link
            to={`/employee/edit/${listDetails?.employee_id}`}
            className="submit-button"
            state={{ list: listDetails }}
          >
            <span className="submit-button-label">Edit</span>
            <img style={{ color: "white" }} className="submit-button-label" src={Edit} alt="add" />
          </Link>
          :
          ""
        }
        {isDownload ?
          <div className="add-new-button-block">
            <button className="add-new-button" onClick={() => downloadEmployee(listDetails?.employee_id)}>
              <span>Download</span>
              <ArrowCircleDownIcon />
            </button>
          </div>
          :
          ""
        }
        <Link to={viewList} className="view-list-button">
          <span>View List</span>
          <img src={View} alt="add" />
        </Link>
      </div>

      <div className="list-container">
        <div className="list-content-block">
          {Details.map((list) => {
            return (
              <div className="list">
                <label className="list-label">{list?.label}</label>
                <div style={{ wordBreak: "break-word" }} className="list-value">
                  {list?.value}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="submit-reset-button">
        <button
          type="button"
          className="reset-button"
          onClick={() => {
            nextTab("personal_info");
          }}
        >
          <span className="reset-button-label">Prev</span>
        </button>
        <button
          type="submit"
          className="submit-button"
          onClick={() => {
            nextTab("education_info");
          }}
        >
          <span className="submit-button-label">Next</span>
        </button>
      </div>
    </>
  );
}

export default PositionInfo;
