import React from "react";
import { Route } from "react-router-dom";
import CreateEmployee from "../Employee/createEmployee";
import EmployeeEdit from "../Employee/EmployeeEdit";
import EmployeeList from "../Employee/AllemployeesList";
import EmployeeDetails from "../Employee/EmployeeDetails";
import EmployeeVerificationList from "../Employee/CertificateVerification/CertificateVerification";
import SingleEmployeeCertificate from "../Employee/CertificateVerification/SingleEmployeeCertificate";
import AllowanceAndDeductions from "../Employee/AllowanceAndDeductions";
import EmployeeRemarks from "../Employee/Remarks/Remarks";
import TerminatedEmployeeList from "../Employee/TerminatedEmployeeList/TerminatedEmployeeList";

function EmployeeManagementRoutes() {
  return (
    <>
      <Route path="list" element={<EmployeeList />}></Route>
      <Route path="add" element={<CreateEmployee />}></Route>
      <Route path="edit/:id" element={<EmployeeEdit />}></Route>
      <Route path="details/:id" element={<EmployeeDetails />}></Route>
      <Route path="verify/certificate" element={<EmployeeVerificationList />}></Route>
      <Route path="verify/certificate/:id" element={<SingleEmployeeCertificate />}></Route>
      <Route path=":emp_id/allowance/deduction/:id" element={<AllowanceAndDeductions />}></Route>
      <Route path="remarks/:empID" element={<EmployeeRemarks />}></Route>
      <Route path="terminated" element={<TerminatedEmployeeList />}></Route>
    </>
  );
}

export default EmployeeManagementRoutes;
