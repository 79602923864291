import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

export default function StickyHeadTable({
    list,
    columns,

}) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 320 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns?.map((column, i) => (
                                <TableCell
                                    className="table-header"
                                    key={i}
                                    align={column.align}
                                >
                                    {column?.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list?.length === 0 ? (
                            <TableRow>
                                <TableCell
                                    colSpan="8"
                                    className="table-body td-data-not-found h-[220px]"
                                >
                                    Data Not Found..!
                                </TableCell>
                            </TableRow>
                        ) : (
                            list
                                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, i) => {
                                    const createdDate = new Date(row?.created_at);
                                    const year = createdDate.getFullYear();
                                    const month = String(createdDate.getMonth() + 1).padStart(
                                        2,
                                        "0"
                                    );
                                    const date = String(createdDate.getDate()).padStart(2, "0");
                                    const hour = createdDate.getUTCHours();
                                    const hourBy12 = hour > 12 ? hour - 12 : hour;
                                    var ampm = hour >= 12 ? "pm" : "am";
                                    const minute = createdDate.getUTCMinutes();
                                    const fathers_name =
                                        typeof list?.fathers_name !== "undefined"
                                            ? list?.fathers_name
                                            : "";

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={i}
                                            className="table-column"
                                        >
                                            {
                                                <TableCell className="!w-[7%] table-body ">
                                                    {page * rowsPerPage + (i + 1)}
                                                </TableCell>
                                            }
                                            {/* {
                                                <TableCell className="table-body">
                                                    {row?.name}
                                                </TableCell>
                                            }
                                            {
                                                <TableCell className="table-body">
                                                    {row?.empcode}
                                                </TableCell>
                                            } */}
                                            {
                                                <TableCell className="table-body">
                                                    {row?.remarks}
                                                </TableCell>
                                            }

                                            {
                                                <TableCell className="table-body">
                                                    {date +
                                                        "-" +
                                                        month +
                                                        "-" +
                                                        year +
                                                        " " +
                                                        hourBy12 +
                                                        ":" +
                                                        minute +
                                                        " " +
                                                        ampm}
                                                </TableCell>
                                            }

                                        </TableRow>
                                    );
                                })
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={list?.length ? list.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}
