import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Select from 'react-select';
import { read, utils } from "xlsx";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import dayjs from 'dayjs';
import alertify from "alertifyjs";
import FileUpload from "../../../Assets/Icons/FileUpload.svg";
import ExcelFile from "../../../Assets/Icons/Excel.svg";
import FileImage from "../../../Assets/Icons/upload.svg";
import { POST_API } from '../../../Services/api';

const EmployeeBulkUpload = ({ isModal, handleModal, clientList, getEmployeeList }) => {
    const [clientId, setClienId] = useState("");
    const [empBulkData, setEmpBulkData] = useState([]);
    const [errors, seterrors] = useState({});
    const [isBtnDusable, setIsBtnDisable] = useState(false);

    const closeModal = () => {
        setClienId("");
        seterrors({});
        setEmpBulkData([]);
        handleModal();
    }

    const handleClickAway = () => {

    }

    const handleSelectChange = (e, name) => {
        const { value } = e;
        setClienId(value);
    };

    const handleAgeChange = (value) => {
        // const { name, value } = e.target;
        const selectedDate = new Date(value);
        const today = new Date();

        let ageYears = today.getFullYear() - selectedDate.getFullYear();
        let ageMonths = today.getMonth() - selectedDate.getMonth();

        if (today.getDate() < selectedDate.getDate()) {
            ageMonths--;
        }

        if (ageMonths < 0) {
            ageYears--;
            ageMonths = 12 + ageMonths;
        }
        return (`${ageYears} Years ${ageMonths} ${ageMonths > 2 ? "Months" : "Month"}`);
    }

    const fileUpload = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (e.target.files.length) {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.onload = async (e) => {
                const wb = read(e.target.result);
                const sheets = wb.SheetNames;
                if (sheets.length) {
                    const rows = utils.sheet_to_json(wb.Sheets[sheets[0]], {
                        raw: false,
                        dateNF: "dd-mm-yyyy hh:mm:ss",
                    });
                    const bulkEmp = rows?.map((item) => { return { ...item, status: 2, date_of_birth: dayjs(item?.date_of_birth).format("YYYY-MM-DD"), age: handleAgeChange(item?.date_of_birth) } }); //status 2 for certificate verify 
                    setEmpBulkData(bulkEmp);
                }
            };
            reader.readAsArrayBuffer(file);
        }
    };

    const formSubmit = async (e) => {
        e.preventDefault();
        setIsBtnDisable(true);
        seterrors({});
        if (!clientId) {
            seterrors({ client_id: "Please select a client" });
            setIsBtnDisable(false);
            return;
        }
        if (empBulkData.length === 0) {
            seterrors({ isEmployee: "Please choose an employee file" });
            setIsBtnDisable(false);
            return;
        }
        const submitData = {
            employeesData: empBulkData
        };
        try {
            const response = await POST_API(`/bulkAddEmployee?client_id=${clientId}`, submitData);
            if (response?.status === 200) {
                alertify.success(response?.data?.message);
                handleModal();
                getEmployeeList('');
            }
        } catch (error) {
            alertify.error(error?.data?.message);
        } finally {
            setIsBtnDisable(false);
        }
    };

    return (
        <div>
            <ClickAwayListener onClickAway={handleClickAway}>
                <Dialog className='' open={isModal} onClose={closeModal}
                    disableBackdropClick={false}
                    PaperProps={{
                        style: {
                            minWidth: '400px',
                            minHeight: '200px',
                        },
                    }}>
                    <DialogTitle></DialogTitle>
                    <DialogContent>
                        <div className='create-employee'>

                            <div className="field-block dropdown-select-to-one">
                                <label className="label-name">Select Client</label>
                                <Select
                                    options={clientList}
                                    onChange={(e) => handleSelectChange(e, "client_id")}
                                    name="client_id"
                                    className=""
                                    isSearchable
                                />
                                {errors?.client_id ? (
                                    <p className="validation-error-label m-0">{errors?.client_id}</p>
                                ) : null}
                            </div>
                            <div className="field-block">
                                <label className="label-name">
                                    Choose File <span className="required-symbol">*</span>
                                </label>
                                <div className="">
                                    <div className="flex gap-2 items-center">
                                        {empBulkData.length ?
                                            <img className='w-8 h-8' src={ExcelFile} alt="" />
                                            :
                                            <img className='w-8 h-8' src={FileImage} alt="" />
                                        }
                                        <div className="p-1 bg-[#FFF2EC] rounded flex justify-center items-center gap-1 cursor-pointer">
                                            <label for="files" className="label-name cursor-pointer">
                                                {empBulkData.length ? "Replace" : "Choose"}
                                            </label>
                                            <input
                                                className="w-0 cursor-pointer"
                                                id="files"
                                                style={{ visibility: "hidden" }}
                                                type="file"
                                                onChange={fileUpload}
                                            />
                                            <img src={FileUpload} alt="" />
                                        </div>
                                    </div>
                                </div>
                                {errors?.isEmployee ? (
                                    <div className="validation-error-label">{errors?.isEmployee}</div>
                                ) : null}
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <button
                            type="button"
                            className="reset-button"
                            onClick={closeModal}
                        >
                            <span className="reset-button-label">Close</span>
                        </button>
                        <button
                            type="submit"
                            className="submit-button"
                            onClick={formSubmit}
                            disabled={isBtnDusable}
                        >
                            <span className="reset-button-label"><span className="submit-button-label">Save</span></span>
                        </button>
                    </DialogActions>
                </Dialog>
            </ClickAwayListener>
        </div>
    );
};

export default EmployeeBulkUpload;
