import HomePageMenu from "./Menus/HomePageMenu";
import Layout from "./Layouts/Layout";
import { Outlet } from "react-router-dom";
import UserAuthenticator from "./UserAuthenticator";
function AllRoutes() {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
}

export function HomeSideBar() {
  return (
    <div className="w-full flex grow h-[calc(100vh-102px)]">
      <HomePageMenu />
      <UserAuthenticator>
        <Outlet />
      </UserAuthenticator>
    </div>
  );
}

export default AllRoutes;
