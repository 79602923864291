import React from "react";
import { Route } from "react-router-dom";
import EmployeeMastersRoutes from "../../Components/Routes/EmployeeMastersRoutes";
import BranchMasterRoutes from "../../Components/Routes/BranchMasterRoutes";
import AttendanceMastersRoutes from "../../Components/Routes/AttendanceMasterRoutes";
import FinanceMastersRoutes from "../../Components/Routes/FinanceMasterRoutes";
import HolidayMasterRoutes from "../../Components/Routes/HolidayMasterRoutes";
import EmployeeManagementRoutes from "../../Components/Routes/EmployeeManagementRoutes";
import PayslipRoutes from "../../Components/Routes/PayslipRoutes";
import LoanAndExpenseRoutes from "../../Components/Routes/LoanAndExpenseRoutes";
import ReportRoutes from "../../Components/Routes/ReportRoutes";
import ApproveRoutes from "../../Components/Routes/ApproveRoutes";
import Dashboard from "../Dashboard/dashboard";
import BarcodeDetection from "../BarcodeDetection";
import AllowanceRoutes from "../../Components/Routes/AllowanceRoutes";
import ClientMaster from "../../Components/Routes/ClientMaster";
import UserPrivileges from "../Header/Settings/Userprivileges";
import GeneralSettings from "../Header/Settings/GeneralSettings";

function HomeRoutes() {
  return (
    <>
      <Route path="/dashboard" element={<Dashboard />}></Route>
      <Route path="/userPrivileges" element={<UserPrivileges />}></Route>
      <Route path="/general-settings" element={<GeneralSettings />}></Route>
      <Route path="/masters">{EmployeeMastersRoutes()}</Route>
      <Route path="/masters">{BranchMasterRoutes()}</Route>
      <Route path="/masters">{AttendanceMastersRoutes()}</Route>
      <Route path="/masters">{FinanceMastersRoutes()}</Route>
      <Route path="/masters">{HolidayMasterRoutes()}</Route>
      <Route path="/masters">{ClientMaster()}</Route>

      <Route path="/employee">{EmployeeManagementRoutes()}</Route>

      <Route path="/payroll">{PayslipRoutes()}</Route>

      <Route path="/expense">{LoanAndExpenseRoutes()}</Route>
      <Route path="/report">{ReportRoutes()}</Route>
      <Route path="/approve">{ApproveRoutes()}</Route>
      <Route path="/allowance">{AllowanceRoutes()}</Route>
      <Route path="/detection" element={<BarcodeDetection />}></Route>
    </>
  );
}

export default HomeRoutes;
