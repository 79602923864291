import React from "react";
import { Route } from "react-router-dom";

import EmployeeReport from "../Reports/EmployeeReport/EmployeeReport";
import EmployeeReportDetails from "../Reports/EmployeeReport/EmployeeReportDetails";

import Loan from "../Reports/LoanReport/LoanReport";
import LoanDetails from "../Reports/LoanReport/LoanReportDetails";

import Leave from "../Reports/LeaveReport/LeaveReportList";
import LeaveDetails from "../Reports/LeaveReport/LeaveReportDetails";

import ClientWiseEmployee from "../Reports/ClientWiseEmployee/ClientWiseEmployee";

// import EmployeeMasterReport from "../Reports/EmployeeMasterReport/EmployeeMasterReport";

// import Termination from "../Reports/TerminationReport/TerminationReportList";
// import TerminationDetails from "../Reports/TerminationReport/TerminationReportDetails";

// import Attendance from "../Reports/AttendanceReport/AttendanceReportList";
// import AttendanceReportDetails from "../Reports/AttendanceReport/AttendanceReportDetails";


// import Exit from "../Reports/ExitReport/ExitReport";
// import ExitDetails from "../Reports/ExitReport/ExitReportDetails";

// import Onduty from "../Reports/OnDutyReport/OnDutyReport";

// import LateFine from "../Reports/LateFineReport/LateFineReport";

// import Advance from "../Reports/AdvanceReport/AdvanceReport";
// import AdvanceDetails from "../Reports/AdvanceReport/AdvanceReportDetails";


// import Payment from "../Reports/PaymentReport/PaymentReport";
// import PaymentDetails from "../Reports/PaymentReport/PaymentReportDetails";

// import Expense from "../Reports/ExpenseReport/ExpenseReport";
// import ExpenseDetails from "../Reports/ExpenseReport/ExpenseReportDetails";

// import Movement from "../Reports/MovementReport/MovementReport";
// import MovementDetails from "../Reports/MovementReport/MovementReportsDetails";

// import PartTimeEmployee from "../Reports/PartTimeEmployeeReport/PartTimeEmployeeReport";
// import PartTimeEmployeeDetails from "../Reports/PartTimeEmployeeReport/PartTimeEmployeeReportDetails";

function ReportRoutes() {
  return (
    <>

      <Route path="employee" element={<EmployeeReport />}></Route>
      <Route path="employee/details/:empId" element={<EmployeeReportDetails />}></Route>

      <Route path="loan" element={<Loan />}></Route>
      <Route path="loan/details/:id" element={<LoanDetails />}></Route>

      <Route path="leave" element={<Leave />}></Route>
      <Route path="leave/details/:id" element={<LeaveDetails />}></Route>

      <Route path="client/employee" element={<ClientWiseEmployee />}></Route>

      {/* <Route path="employee" element={<EmployeeReport />}></Route>
      <Route
        path="employee/details/:id"
        element={<EmployeeReportDetails />}
      ></Route>

      <Route path="employeemaster" element={<EmployeeMasterReport />}></Route>

      <Route path="termination" element={<Termination />}></Route>
      <Route
        path="termination/details/:id"
        element={<TerminationDetails />}
      ></Route>

      <Route path="attendance" element={<Attendance />}></Route>
      <Route
        path="attendance/details/:id"
        element={<AttendanceReportDetails />}
      ></Route>

     

      <Route path="exit" element={<Exit />}></Route>
      <Route path="exit/details/:id" element={<ExitDetails />}></Route>

      <Route path="onduty" element={<Onduty />}></Route>

      <Route path="latefine" element={<LateFine />}></Route>

      <Route path="advance" element={<Advance />}></Route>
      <Route path="advance/details/:id" element={<AdvanceDetails />}></Route>

    

      <Route path="payment" element={<Payment />}></Route>
      <Route path="payment/details/:id" element={<PaymentDetails />}></Route>

      <Route path="expense" element={<Expense />}></Route>
      <Route path="expense/details/:id" element={<ExpenseDetails />}></Route>

      <Route path="movement" element={<Movement />}></Route>
      <Route path="movement/details/:id" element={<MovementDetails />}></Route>

      <Route path="parttimeEmployee" element={<PartTimeEmployee />}></Route>
      <Route
        path="parttimeEmployee/details/:id"
        element={<PartTimeEmployeeDetails />}
      ></Route> */}
    </>
  );
}

export default ReportRoutes;
