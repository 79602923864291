import React, { useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import View from "../../../Assets/Icons/View.svg";
import ExpensePrintVoucher from "./ExpensePrintVoucher";
import { useReactToPrint } from "react-to-print";
// import useScanDetection from "use-scan-detection";

function ExpenseDetails() {
  const { state } = useLocation();
  const componentRef = useRef();
  const listDetails = state?.list;
  const Details = [
    {
      label: "Employee Name",
      value: listDetails?.employee_name + " " + listDetails?.fathers_name,
    },

    {
      label: "Total Paid Amount",
      value: listDetails?.total_paid_amount,
    },
    {
      label: "Amount",
      value: listDetails?.amount,
    },

    {
      label: "Remarks",
      value: listDetails?.remarks,
    },
  ];

  const printVoucher = useReactToPrint({
    content: () => componentRef.current,
  });

  const [BarcodeScan, setBarcodeScan] = useState("");
  // useScanDetection({
  //   onComplete: (code) => {
  //     console.log(code);
  //     setBarcodeScan(code);
  //   },
  // });
  console.log(BarcodeScan);

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className=" sm-container">
        <h1 className="heading-text">Expense</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Expense Details</h4>
          <div className="add-block">
            <button
              type="button"
              className="submit-button"
              onClick={() => printVoucher()}
            >
              <span className="submit-button-label">Print</span>
            </button>
            <Link to="/expense" className="view-list-button">
              <span>View List</span>
              <img src={View} alt="add" />
            </Link>
          </div>
          
          <div className="list-container">
            <div className="list-content-block">
              {Details.map((list, i) => {
                return (
                  <div className="list " key={i}>
                    <label className="list-label">{list.label}</label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="list-value"
                    >
                      {list.value}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <ExpensePrintVoucher
            ref={componentRef}
            printVoucherData={listDetails}
          />
        </div>
      </div>
    </div>
  );
}

export default ExpenseDetails;
