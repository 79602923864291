import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import alertify from "alertifyjs";
import Checkbox from "@mui/material/Checkbox";
import Select from "react-select";
import View from "../../../Assets/Icons/View.svg";
import Validation from "../../../Validation/Validation";
import { GET_API, POST_API } from "../../../Services/api";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import Spinner from "../../Spinner";
import "alertifyjs/build/css/alertify.css";

function CreateUpdateDeduction() {
    const navigate = useNavigate();
    const { deductionId } = useParams();
    const [isLoading, setIsLoading] = useState(false);

    // allowance options
    const [allowanceOptions, setAllowanceOptions] = useState([]);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [errors, seterrors] = useState({});
    const [deductionValue, setDeductionValue] = useState({
        name: "",
        is_automatic: 0,
        percentage_value: "",
        formula_value: "total_salary",
        calculation: "",
        manual_calc_amount: []
    });

    const handlePercentageChange = (e) => {
        const { name, value } = e?.target;
        setDeductionValue(() => ({ ...deductionValue, [name]: value }));
    }

    const handleAllowanceAutomatic = (e, name) => {
        const { checked } = e?.target;
        setDeductionValue(() => ({
            ...deductionValue, [name]: checked ? 1 : 0,
            percentage_value: "",
            formula_value: checked ? "total_salary" : "",
            manual_calc_amount: []
        }));
    }

    const handleFormulaSelect = (e, name) => {
        const { value } = e;
        setDeductionValue(() => ({ ...deductionValue, [name]: value, manual_calc_amount: [] }));
    }

    const handleManualCalcSelect = (e, name) => {
        setDeductionValue(prevDeductionValue => ({ ...prevDeductionValue, manual_calc_amount: e }));
    }

    useEffect(() => {
            const getDeductionById = async () => {
                try {
                    setIsLoading(true);
                    const allowOption = await GET_API("/clientAllowanceList");
                    const response = deductionId ? await GET_API(`clientDeductionById/${deductionId}`):"";
                    const allowance = allowOption?.data?.data?.map((item) => {
                        return { label: item?.name, value: item?.id }
                    })
                    if (allowOption?.status === 200) {
                        setAllowanceOptions(allowance);
                        setIsLoading(false);
                    }
                    if (response?.status === 200) {
                        const automatic = response?.data?.data?.is_automatic;
                        const formulas = response?.data?.data?.formula_value;
                       if(automatic === 1 && formulas === "manual"){
                        const manual_calc = response?.data?.data?.manual_calc_amount?.split(',').map(Number);
                        const allow = allowance?.filter((item) => {
                            return manual_calc?.includes(item?.value);
                        });
                        const manual_calc_res = { ...response?.data?.data, manual_calc_amount: allow }
                        setDeductionValue(manual_calc_res);
                       }else{
                        setDeductionValue(response?.data?.data);
                       }
                        setIsLoading(false);
                    }
                } catch (error) {
                    setDeductionValue({
                        name: "",
                        is_automatic: 0,
                        percentage_value: "",
                        formula_value: "",
                        calculation: "",
                        manual_calc_amount: []
                    });
                    setIsLoading(false);
                }
            }
            getDeductionById();
        
    }, [])

    const create_Deduction = async (e) => {
        e.preventDefault();
        setButtonDisabled(true);
        const isValid = Validation.validateClientDeductionList(deductionValue);
        if (isValid && isValid.error) {
            seterrors({
                [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
            });
            setButtonDisabled(false);
            return;
        } else {
            seterrors({});
        }
        try {
            const submitData = {
                ...deductionValue,
                manual_calc_amount: deductionValue?.formula_value === "manual" ? deductionValue?.manual_calc_amount?.map(item => item?.value).toString() : ""
            }
            const API_URL = deductionId ? `/updateClientDeductions/${deductionValue?.id}` : "/addclientDeductionsList"
            const response = await POST_API(API_URL, submitData);
            if (response?.status === 200) {
                alertify.success(response?.data?.message);
                setButtonDisabled(false);
                navigate("/masters/client/deductions");
            }
        } catch (error) {
            alertify.error(error?.response?.message);
            setButtonDisabled(false);
        }
    };

    const resetForm = () => {
        setDeductionValue({
            name: "",
            is_automatic: 0,
            percentage_value: "",
            formula_value: "",
            calculation: "",
            manual_calc_amount: []
        });
        seterrors({});
    };

    const formulaOptions = [
        { label: "Total Salary", value: "total_salary" },
        { label: "Manual", value: "manual" }
    ]

    if (isLoading ) {
        return <Spinner />;
    }

    return (
        <div className="bg-[#F7F6F4] grow">
            <div className="sm-container">
                <h1 className="heading-text">Deduction</h1>

                <div className="card-wrapper">
                    <h4 className="card-header">Add Deduction</h4>

                    <div className="search-block">
                        <Link to="/masters/client/deductions" className="view-list-button">
                            <span>View List</span>
                            <img src={View} alt="add" />
                        </Link>
                    </div>

                    <form onSubmit={(e) => create_Deduction(e)}>
                        <div className="form">
                            <div className="field-block">
                                <label htmlFor="name">Name<span className="required-symbol">*</span></label>
                                <input
                                    className="input-field "
                                    type="text"
                                    onChange={(e) => handlePercentageChange(e)}
                                    name="name"
                                    placeholder="Enter Name"
                                    value={deductionValue?.name}
                                    autoComplete="off"
                                />
                                {errors.name ? (
                                    <div className="validation-error-label">{errors.name}</div>
                                ) : null}
                            </div>
                            <div className="field-block">
                                <label htmlFor="is_automatic">Automatic</label>
                                <Checkbox
                                    className="input-checkbox"
                                    onChange={e => handleAllowanceAutomatic(e, "is_automatic")}
                                    inputProps={{ "aria-label": "controlled" }}
                                    checked={(deductionValue?.is_automatic === "1" || deductionValue?.is_automatic === 1) ? true : false}
                                />
                            </div>

                            {(deductionValue?.is_automatic === "1" || deductionValue?.is_automatic === 1) ?
                                <>
                                    <div className="field-block">
                                        <label htmlFor="percentage">Percentage of</label>
                                        <input
                                            className="input-field "
                                            type="text"
                                            onChange={(e) => handlePercentageChange(e)}
                                            name="percentage_value"
                                            placeholder="Enter Percentage of"
                                            value={deductionValue?.percentage_value}
                                            autoComplete="off"
                                        />
                                    </div>

                                    <div className="field-block">
                                        <label htmlFor="formula_value">Formula</label>
                                        <Select
                                            id="formula_value"
                                            options={formulaOptions}
                                            onChange={(e) => handleFormulaSelect(e, "formula_value")}
                                            name="formula_value"
                                            value={formulaOptions?.find(item => item?.value === deductionValue?.formula_value)}
                                            isSearchable
                                        />
                                    </div>
                                    {deductionValue?.formula_value === "manual" ?
                                        <div className="field-block">
                                            <label htmlFor="manual_calc_amount">Sum of / Salary Days</label>
                                            <Select
                                                id="manual_calc_amount"
                                                options={allowanceOptions}
                                                onChange={(e) => handleManualCalcSelect(e, "manual_calc_amount")}
                                                name="manual_calc_amount"
                                                value={deductionValue?.manual_calc_amount}
                                                isSearchable
                                                isMulti
                                            />
                                        </div>
                                        : null}
                                </> : null}

                        </div>
                        <div className="submit-reset-button">
                            <button type="button" className="reset-button" onClick={resetForm}>
                                <span className="reset-button-label">Reset</span>
                            </button>
                            <button disabled={buttonDisabled} type="submit" className="submit-button">
                                <span className="submit-button-label">{deductionId ? "Save" : "Submit"}</span>
                                <img src={ColorAdd} alt="" />
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default CreateUpdateDeduction;
