import React, { useState, useEffect } from "react";
import More from "../../../Assets/Icons/More.svg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { DELETE_API, GET_API } from "../../../Services/api";
import AgentSecurity from "../../DataTable/AgentSecurity";
import Search from "../../../Assets/Icons/Search.svg";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import Spinner from "../../Spinner";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";

function ConsultancyList() {
  const navigate = useNavigate();
  const [consultancyList, setConsultanyList] = useState([]);
  const [filteredConsultancy, setFilteredConsultancy] = useState([]);
  const [loading, setLoading] = useState(false);
  const showConsultancyDetails = (listDetail) => {
    navigate("/masters/agent/consultancy/details", {
      state: {
        list: listDetail,
      },
    });
  };
  const navigateEditPage = (listDetail) => {
    navigate("/masters/agent/consultancy/edit", {
      state: {
        list: listDetail,
      },
    });
  };
  const columns = [
    { id: "s.no", label: "S.No", minWidth: 170 },
    { id: "company_name", label: "Company Name", minWidth: 100 },
    { id: "contact_person", label: "Contact Person", minWidth: 100 },
    { id: "contact_number", label: "Contact Number", minWidth: 100 },
    { id: "created_at", label: "Created at", minWidth: 100 },
    { id: "more_icon", label: "", minWidth: 100 },
  ];

  useEffect(() => {
    getConsultancyList();
  }, []);
  const getConsultancyList = async () => {
    setLoading(true);
    try {
      const consultancyList = await GET_API("/agentList/consultancy");
      if (consultancyList.status === 200) {
        setLoading(false);
        setConsultanyList(consultancyList.data.data);
        setFilteredConsultancy(consultancyList.data.data);
        return;
      }
    } catch (error) {
      alertify.error("Failed to Get Consultancy List");
      setLoading(false);
    }
  };

  const handleConsultancySearch = (e) => {
    const searchTerm = e.target.value;
    const searchedList = consultancyList.filter((item) => {
      for (let key in item) {
        if (
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
    setFilteredConsultancy(searchedList);
  };

  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Consultancy..?",
        async function () {
          if (row_id) {
            const deleteRow = await DELETE_API(`/deleteAgents/${row_id}`);
            if (deleteRow.status === 200) {
              alertify.success(deleteRow?.data?.message);
              getConsultancyList();
              return;
            }
            alertify.error("Failed To Delete Floor");
          }
        },
        function () { }
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };
  if (loading) {
    return <Spinner />;
  }
  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">Consultancy</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Consultancy Agent List</h4>
          <div className="search-block">
            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                onChange={(e) => handleConsultancySearch(e)}
                style={{ outline: "none", border: "none" }}
              />
              <img src={Search} alt="" />
            </div>
            <div className="flex gap-3">
              <div className="add-new-button-block">
                <Link
                  to="/masters/agent/consultancy/add"
                  className="add-new-button"
                >
                  <span>Add New</span>
                  <img src={ColorAdd} alt="add" />
                </Link>
              </div>
            </div>
          </div>
          <AgentSecurity
            list={filteredConsultancy}
            columns={columns}
            showDetails={showConsultancyDetails}
            icon={More}
            multiplePage={true}
            editPage={navigateEditPage}
            deleteRow={handleDelete}
          />
        </div>
      </div>
    </div>
  );
}

export default ConsultancyList;
