import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import View from "../../Assets/Icons/View.svg";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import Validation from "../../Validation/Validation";
import { GET_API, POST_API } from "../../Services/api";
import ColorAdd from "../../Assets/Icons/ColorAdd.svg";
import Spinner from "../Spinner";

function CreateFine() {
  const navigate = useNavigate();
  useEffect(() => {
    getEmployeeList();
    getBonusList();
  }, []);

  const [fineDetails, setfineDetails] = useState({
    employee_id: "",
    is_manual: 1,
    address: "",
    phone: "",
    town: "",
    state: "",
    pincode: "",
    contact_person: "",
    contact_email: "",
    gst_no: "",
  });
  const [errors, seterrors] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [bonusList, setBonusList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getBonusList = async () => {
    setLoading(true);
    const designationList = await GET_API("/FinanceCategoriesList/fine");
    if (designationList.status === 200) {
      setBonusList(designationList?.data?.data);
      setLoading(false);
      return;
    }
    alertify.error(designationList?.response?.message);
    setLoading(false);
  };

  const getEmployeeList = async () => {
    GET_API("/employeesList")
      .then(async function (data) {
        const employeeListArr = [];
        data.data.data.map((item) => {
          employeeListArr.push(item);
        });
        setEmployeeList(employeeListArr);
      })

      .catch((error) => console.log(error));
  };

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setfineDetails(() => ({ ...fineDetails, [name]: value }));
  };
  const createFine = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.validateFine(fineDetails);
    if (isValid && isValid.error) {
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      setButtonDisabled(false);
      return;
    } else {
      seterrors({});
    }
    try {
      const addFine = await POST_API("/employeeFineAdd", fineDetails);
      if (addFine.status === 200) {
        setButtonDisabled(false);
        alertify.success(addFine?.data?.message);
        navigate("/masters/fine");
      }
    } catch (error) {
      setButtonDisabled(false);
      alertify.error(error?.response?.data?.message);
    }
  };
  const resetForm = () => {};
  if (loading) {
    return <Spinner />;
  }
  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">Fine</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Add Fine</h4>
          <div className="search-block">
            <Link to="/masters/fine" className="view-list-button">
              <span>View List</span>
              <img src={View} alt="add" />
            </Link>
          </div>

          {/***************Form************* **/}

          <form onSubmit={(e) => createFine(e)}>
            <div className="form">
              <div className="field-block">
                <label className="label-name">
                  Employee Name
                  <span className="required-symbol">*</span>
                </label>
                <select
                  onChange={(e) => handleChange(e)}
                  className="input-field"
                  name="employee_id"
                >
                  <option value="">Select Employee</option>
                  {employeeList &&
                    employeeList.map((option) => {
                      return (
                        <option value={option.employee_Id}>
                          {option.first_name} {option.fathers_name}
                        </option>
                      );
                    })}
                </select>
                {errors.employee_id ? (
                  <div className="validation-error-label">
                    {errors.employee_id}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Finance Category
                  <span className="required-symbol">*</span>
                </label>
                <select
                  onChange={(e) => handleChange(e)}
                  className="input-field"
                  name="fine_type_id"
                >
                  <option value="">Select Finance</option>
                  {bonusList?.map((fine) => {
                    return <option value={fine?.id}>{fine?.name}</option>;
                  })}
                </select>
                {errors.fine_type_id ? (
                  <div className="validation-error-label">
                    {errors.fine_type_id}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Remarks
                  <span className="required-symbol">*</span>
                </label>
                <textarea
                  className="input-field"
                  type="text"
                  name="remarks"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter Address"
                  rows={5}
                />
                {errors.remarks ? (
                  <div className="validation-error-label">{errors.remarks}</div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Fine Amount
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  name="fine_amount"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Fine Amount"
                />
                {errors.fine_amount ? (
                  <div className="validation-error-label">
                    {errors.fine_amount}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="submit-reset-button">
              <button
                type="button"
                className="reset-button"
                onClick={resetForm}
              >
                <span className="reset-button-label">Reset</span>
              </button>
              <button
                disabled={buttonDisabled}
                type="submit"
                className="submit-button"
              >
                <span className="submit-button-label">Add</span>
                <img src={ColorAdd} alt="" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateFine;
