import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import View from "../../Assets/Icons/View.svg";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import { GET_API, POST_API } from "../../Services/api";
import ColorAdd from "../../Assets/Icons/ColorAdd.svg";
import Spinner from "../Spinner";
import Validation from "../../Validation/Validation";
// import BonusPrintVoucher from "./BonusPrintVoucher";
import { useReactToPrint } from "react-to-print";

function CreateLoan() {
  const navigate = useNavigate();
  // const componentRef = useRef();
  const userId = localStorage?.getItem("user_id");
  const [loanDetails, setloanDetails] = useState({
    employee_id: "",
    finance_category_id: "",
    due_date: "",
    remarks: "",
    duration: "",
    amount: "",
    approved_by: "",
  });
  const [errors, seterrors] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [bonusList, setBonusList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [loanList, setLoanList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [approvalList, setApprovalList] = useState([]);
  const [isPrintVoucher, setIsPrintVoucher] = useState(false);
  const [printVoucherData, setPrintVoucherData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getDesignationList();
    getDepartmentList();
    getBranchList();
    getLoanList();
    getUserList();
    getApprovalList();
  }, []);

  const getDesignationList = async () => {
    try {
      setLoading(true);
      const designationList = await GET_API("/FinanceCategoriesList/bonus");
      if (designationList.status === 200) {
        const list = designationList?.data?.data?.map(item => { return { label: item?.name, value: item?.id } });
        list.unshift({ label: "Select...", value: "" });
        setBonusList(list);
        setLoading(false);
        return;
      }
    } catch (error) {
      alertify.error(error?.response?.message);
      setBonusList([]);
      setLoading(false);
    }
  };

  const getDepartmentList = async () => {
    try {
      setLoading(true);
      const departmentList = await GET_API("/DepartmentList");
      if (departmentList.status === 200) {
        setDepartmentList(departmentList?.data?.data);
        setLoading(false);
        return;
      }
    } catch (error) {
      alertify.error(error?.response?.message);
      setDepartmentList([]);
      setLoading(false);
    }
  };

  const getUserList = async () => {
    try {
      setLoading(true);
      const userList = await GET_API(`/ListEmployeeTentacles?login=${userId}`);
      if (userList.status === 200) {
        const list = userList?.data?.data?.map(item => { return { label: item?.name, value: item?.id } });
        list.unshift({ label: "Select...", value: "" });
        setUserList(list);
        setLoading(false);
        return;
      }
    } catch (error) {
      alertify.error(error?.response?.message);
      setUserList([]);
      setLoading(false);
    }
  };

  const getApprovalList = async () => {
    try {
      setLoading(true);
      const userList = await GET_API(`/ListEmployeeTentacles?login=${userId}`);
      if (userList.status === 200) {
        const list = userList?.data?.data?.map(item => { return { label: item?.name, value: item?.id } });
        list.unshift({ label: "Select...", value: "" });
        setApprovalList(list);
        setLoading(false);
        return;
      }
    } catch (error) {
      alertify.error(error?.response?.message);
      setApprovalList([]);
      setLoading(false);
    }
  };

  const getBranchList = async () => {
    try {
      setLoading(true);
      const branchList = await GET_API("/branchList");
      if (branchList.status === 200) {
        const list = branchList?.data?.data?.map(item => { return { label: item?.name, value: item?.id } });
        list.unshift({ label: "Select...", value: "" });
        setBranchList(list);
        setLoading(false);
        return;
      }
    } catch (error) {
      alertify.error(error?.response?.message);
      setBranchList([]);
      setLoading(false);
    }
  };

  const getLoanList = async () => {
    try {
      setLoading(true);
      const loanList = await GET_API("/FinanceCategoriesList/loan");
      if (loanList.status === 200) {
        setLoanList(loanList?.data?.data);
        setLoading(false);
        return;
      }
    } catch (error) {
      alertify.error(error?.response?.message);
      setLoanList([]);
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setloanDetails(() => ({ ...loanDetails, [name]: value }));
  };

  const handleSelectChange = (e, name) => {
    const { value } = e;
    setloanDetails({ ...loanDetails, [name]: value })
  }

  // const printVoucher = useReactToPrint({
  //   content: () => componentRef.current,
  // });

  // useEffect(() => {
  //   if (isPrintVoucher) {
  //     printVoucher();
  //     navigate("/expense/bonus");
  //   }
  // }, [isPrintVoucher, printVoucher]);

  const createLoan = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.validateBonus(loanDetails);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
      setButtonDisabled(false);
    }
    POST_API("/addBonusDetails", loanDetails)
      .then(async function (data) {
        setButtonDisabled(false);
        alertify.success(data?.data?.message);
        navigate("/expense/bonus");
        // setPrintVoucherData(data?.data?.data[0]);
        // setTimeout(() => {
        //   printVoucher();
        //   setIsPrintVoucher(true);
        // }, 1000);
      })

      .catch((error) => {
        setButtonDisabled(false);
        alertify.error(error?.response?.data?.message);
      });
  };

  const resetForm = () => { };
  if (loading) {
    return <Spinner />;
  }
  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">Bonus</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Add Bonus</h4>
          <div className="add-block">
            <Link to="/expense/bonus" className="view-list-button">
              <span>View List</span>
              <img src={View} alt="add" />
            </Link>
          </div>

          {/***************Form************* **/}
          {/* {isPrintVoucher && (
            <BonusPrintVoucher
              ref={componentRef}
              printVoucherData={printVoucherData}
            />
          )} */}
          <form onSubmit={(e) => createLoan(e)}>
            <div className="form">
              <div className="field-block">
                <label className="label-name">
                  Employee Name
                  <span className="required-symbol">*</span>
                </label>
                <Select
                  id="employee_id"
                  options={userList}
                  value={userList?.find(item => item?.value === loanDetails.employee_id)}
                  onChange={(e) => handleSelectChange(e, "employee_id")}
                  name="employee_id"
                  isSearchable
                />
                {errors.employee_id ? (
                  <div className="validation-error-label">
                    {errors.employee_id}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Finance Category
                  <span className="required-symbol">*</span>
                </label>
                <Select
                  id="finance_category_id"
                  options={bonusList}
                  value={bonusList?.find(item => item?.value === loanDetails.finance_category_id)}
                  onChange={(e) => handleSelectChange(e, "finance_category_id")}
                  name="finance_category_id"
                  isSearchable
                />
                {errors.finance_category_id ? (
                  <div className="validation-error-label">
                    {errors.finance_category_id}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Due Date
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="date"
                  name="due_date"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Select Date"
                />
                {errors.due_date ? (
                  <div className="validation-error-label">
                    {errors.due_date}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Remarks
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  name="remarks"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter Remarks"
                />
                {errors.remarks ? (
                  <div className="validation-error-label">{errors.remarks}</div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Duration
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  name="duration"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter Duration"
                />
                {errors.duration ? (
                  <div className="validation-error-label">
                    {errors.duration}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Amount
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  name="amount"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter Amount"
                />
                {errors.amount ? (
                  <div className="validation-error-label">{errors.amount}</div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Approved By
                  <span className="required-symbol">*</span>
                </label>
                <Select
                  id="approved_by"
                  options={userList}
                  value={userList?.find(item => item?.value === loanDetails.approved_by)}
                  onChange={(e) => handleSelectChange(e, "approved_by")}
                  name="approved_by"
                  isSearchable
                />
                {errors.approved_by ? (
                  <div className="validation-error-label">
                    {errors.approved_by}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="submit-reset-button">
              <button
                type="button"
                className="reset-button"
                onClick={resetForm}
              >
                <span className="reset-button-label">Reset</span>
              </button>
              <button
                disabled={buttonDisabled}
                type="submit"
                className="submit-button"
              >
                <span className="submit-button-label">Add</span>
                <img src={ColorAdd} alt="" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateLoan;
