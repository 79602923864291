import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Select from 'react-select';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const MyModal = ({ isModal, handleModal, employeeJoin, employeeJoinChange, empNameOptions, handleEmpNameChange, oldEmployee }) => {
  const [isError, setIsError] = useState(false)

  const closeModal = () => {
    if (employeeJoin === "rejoining") {
      if (oldEmployee) {
        setIsError(false)
        handleModal()
      } else {
        setIsError(true)
      }
    } else {
      setIsError(false)
      handleModal()
    }
  }

  return (
    <div>
      <ClickAwayListener onClickAway={false}>
        <Dialog className='' open={isModal} onClose={closeModal}
          disableBackdropClick={false}
          PaperProps={{
            style: {
              minWidth: '500px',
              minHeight: '400px',
            },
          }}>
          <DialogTitle>Select Employee Type</DialogTitle>
          <DialogContent>
            <div className='create-employee'>
              <RadioGroup value={employeeJoin} onChange={employeeJoinChange} className='block'>
                <FormControlLabel
                  value="newJoining"
                  control={<Radio style={{ color: '#C1314C' }} />}
                  label="New Joining"
                />
                <FormControlLabel
                  value="rejoining"
                  control={<Radio style={{ color: '#C1314C' }} />}
                  label="Rejoining"
                />
              </RadioGroup>
              <div className="field-block dropdown-select-to-first mt-2">
                <label className="label-name">
                  Employee Name
                  <span className="required-symbol">*</span>
                </label>
                <Select
                  options={empNameOptions}
                  onChange={(e) => handleEmpNameChange(e)}
                  name="empStatus"
                  className=""
                  isSearchable
                  isDisabled={employeeJoin === 'rejoining' ? false : true}
                />
                {isError ? <div className="validation-error-label">please Select Employee</div> : ""}
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <button
              type="button"
              className="reset-button"
              onClick={closeModal}
            >
              <span className="reset-button-label">Close</span>
            </button>
            <button
              type="button"
              className="submit-button"
              onClick={closeModal}
            >
              <span className="reset-button-label"><span className="submit-button-label">Ok</span></span>
            </button>
          </DialogActions>
        </Dialog>
      </ClickAwayListener>
    </div>
  );
};

export default MyModal;
