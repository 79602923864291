import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import dayjs from "dayjs";
import Spinner from "../../Spinner";
import View from "../../../Assets/Icons/View.svg";
import ManualAttendanceListTable from "./ManualAttendanceListTable";
import { GET_API } from "../../../Services/api";
import "alertifyjs/build/css/alertify.css";

function CreateManualAttendance() {
  const [attendanceData, setAttendanceData] = useState([])
  const [clientList, setClientList] = useState([]);
  const [clientValue, setClientValue] = useState("");
  const [currentMonth, setCurrentMonth] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getClientDetails();
  }, [])

  const getClientDetails = async () => {
    try {
      setLoading(true);
      const response = await GET_API("clientLists")
      if (response?.status === 200) {
        const res = response?.data?.data?.map((item) => {
          return { label: item?.name, value: item?.id }
        })
        setClientList(res)
        setLoading(false);
      }
    } catch (error) {
      setClientList([])
      setLoading(false);
    }
  }

  const getClientEmployees = async () => {
    try {
      setLoading(true);
      const response = await GET_API(`employeeManualAttendanceList/${clientValue}?year=${dayjs(currentMonth).format("YYYY")}&month=${dayjs(currentMonth).format("MM")}`)
      if (response?.status === 200) {
        const res = response?.data?.data?.map((item) => {
          return { ...item, date: dayjs(new Date()).format("DD-MM-YYYY"), total_number_of_days:response?.data?.salary_calc_days}
        })
        setAttendanceData(res)
        setLoading(false);
      }
    } catch (error) {
      setAttendanceData([])
      setLoading(false);
    }
  }

  const handleSearch = () => {
    getClientEmployees();
  }

  const handleClientSelect = (e) => {
    setClientValue(e?.value)
  }

  const handleDateChange = (e) => {
    setCurrentMonth(e.target.value);
  };

  const columns = [
    { id: "employee_code", label: "Employee Code"},
    { id: "name", label: "Employee Name" },
    { id: "total_number_of_days", label: "Total Working Days"},
    { id: "present_days", label: "Present Days"},
    { id: "incentive_days", label: "Incentive Days"},
    { id: "other_deductions", label: "Other Deductions"},
    { id: "canteen_deductions", label: "Canteen Deductions"},
    { id: "pf_deductions", label: "PF Deductions"},
    { id: "date", label: "Date"},
  ];

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">Manual Attendance</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Add Manual Attendance</h4>
          <div className="add-block">
            <div className="field-block dropdown-select-to-one w-52">
              <label className="label-name">Select Client</label>
              <Select
                id={`client_id`}
                options={clientList}
                onChange={(e) => handleClientSelect(e, "client_id")}
                name="client_id"
                value={clientList?.find(
                  (option) => option?.value === clientValue
                )}
                isSearchable
              />
            </div>
            <div className="field-block w-52">
              <label className="label-name">Month</label>
              <input
                type="month"
                name="month"
                className="input-field"
                placeholder="Enter Month"
                onChange={handleDateChange}
                value={currentMonth}
                max={dayjs().format("YYYY-MM")}
              />
            </div>
            <div className="field-block">
              <button
                type="button"
                className="submit-button mt-6"
                onClick={handleSearch}
              >
                <span className="submit-button-label">Find</span>
              </button>
            </div>
            <Link to="/masters/manualAttendance" className="view-list-button">
              <span>View List</span>
              <img src={View} alt="add" />
            </Link>
          </div>

          <ManualAttendanceListTable
            list={attendanceData}
            columns={columns}
            setAttendanceData={setAttendanceData}
            clientList={clientList}
          />
        </div>
      </div>
    </div>
  );
}

export default CreateManualAttendance;


// import React, { useState, useEffect } from "react";
// import View from "../../../Assets/Icons/View.svg";
// import { Link } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
// import { GET_API, POST_API } from "../../../Services/api";
// import Validation from "../../../Validation/Validation";
// import alertify from "alertifyjs";
// import Spinner from "../../Spinner";
// import ManualAttendanceListTable from "./ManualAttendanceListTable";
// import "alertifyjs/build/css/alertify.css";
// import dayjs from "dayjs";

// const attendance = [
//   {employee_code:"TSS-123", employee_name:"Muthu", total_working_days:"", present_days:"", incentive_days:"", date: dayjs(new Date()).format("DD-MM-YYYY")},
//   {employee_code:"TSS-124", employee_name:"Raja", total_working_days:"", present_days:"", incentive_days:"", date: dayjs(new Date()).format("DD-MM-YYYY")}
// ]

// function CreateManualAttendance() {
//   const navigate = useNavigate();
//   const [attendanceData, setAttendanceData] = useState([...attendance])

//   const [errors, seterrors] = useState({});
//   const [fineList, setFineList] = useState([]);
//   const [employeeList, setemployeeList] = useState([]);
//   const [empName, setEmpName] = useState("");
//   const [buttonDisabled, setButtonDisabled] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [leaveDetails, setleaveDetails] = useState({
//     employee_id: "",
//     date: "",
//     clock_in: "",
//     clock_out: "",
//     approval_status: "",
//     fine_type_id: "",
//     fine_amount: "",
//     approved_by: 0,
//   });

//   const handleChange = (key, value) => {
//     setleaveDetails(() => ({ ...leaveDetails, [key]: value }));
//   };

//   const handleEmployee = async (key, value) => {
//     setLoading(true);
//     setEmpName("");
//     try {
//       const getEmployeeName = await GET_API(`/employeeGet/${value}`);
//       if (getEmployeeName.status === 200) {
//         setEmpName(`${getEmployeeName?.data?.dat.first_name}`);
//         setleaveDetails({ ...leaveDetails, [key]: value });
//         setLoading(false);
//       }
//     } catch (error) {
//       setLoading(false);
//       alertify.error("Failed to load Employee Name");
//     }
//   };

//   const createManualAttendance = async (e) => {
//     e.preventDefault();
//     setButtonDisabled(true);
//     const isValid = Validation.validateManualAttendance(leaveDetails);
//     if (isValid && isValid.error) {
//       seterrors({
//         [isValid?.error?.details[0].context?.key]:
//           isValid.error.details[0].message,
//       });
//       setButtonDisabled(false);
//       return;
//     } else {
//       seterrors({});
//     }
//     // return;
//     try {
//       const manualAttendance = await POST_API(
//         "/AddManualAttendance",
//         leaveDetails
//       );
//       if (manualAttendance.status === 200) {
//         alertify.success("Manual Attendance added successfully!");
//         navigate("/masters/manualattendance");
//         setButtonDisabled(false);
//       }
//     } catch (error) {
//       setButtonDisabled(false);
//       alertify.error("Failed to Add Manual Attendance");
//     }
//   };
//   useEffect(() => {
//     getEmployeeList();
//     getFineList();
//   }, []);

//   const getEmployeeList = async () => {
//     try {
//       const employeeList = await GET_API("/employeesList");
//       if (employeeList.status === 200) {
//         setLoading(false);
//         setemployeeList(employeeList?.data?.data);
//       }
//     } catch (error) {
//       alertify.error("Failed To get Employee List");
//     }
//   };

//   const getFineList = async () => {
//     try {
//       const fineList = await GET_API("/FinanceCategoriesList/fine");
//       if (fineList.status === 200) {
//         setFineList(fineList?.data?.data);
//       }
//     } catch (error) {
//       alertify.error("Failed To get Fine List");
//     }
//   };
//   const resetForm = () => {
//     setleaveDetails({
//       employee_id: "",
//       date: "",
//       clock_in: "",
//       clock_out: "",
//       approval_status: "",
//       fine_type_id: "",
//       fine_amount: "",
//       approved_by: 0,
//     });
//     setEmpName("");
//   };

//   if (loading) {
//     return <Spinner />;
//   }

//   const columns = [
//     { id: "employee_code", label: "Employee Code" },
//     { id: "employee_name", label: "Employee Name" },
//     { id: "total_working_days", label: "Total Working Days" },
//     { id: "present_days", label: "Present Days" },
//     { id: "incentive_days", label: "Incentive Days" },
//     { id: "date", label: "Date" },
//   ];



//   return (
//     <div className="bg-[#F7F6F4] grow">
//       <div className="sm-container">
//         <h1 className="heading-text">Manual Attendance</h1>
//         <div className="card-wrapper">
//           <h4 className="card-header">Add Manual Attendance</h4>
//           <div className="search-block">
//             <Link to="/masters/manualAttendance" className="view-list-button">
//               <span>View List</span>
//               <img src={View} alt="add" />
//             </Link>
//           </div>

//           <ManualAttendanceListTable
//             list={attendanceData}
//             columns={columns}
//             setAttendanceData={setAttendanceData}
//           />

//           {/* <form onSubmit={(e) => createManualAttendance(e)}>
//             <div className="form">
//               <div className="field-block">
//                 <label className="label-name">
//                   Employee code
//                   <span className="font-bold" style={{ color: "#FF5C42" }}>
//                     *
//                   </span>
//                 </label>
//                 <select
//                   name="employee_id"
//                   className="input-field"
//                   onChange={(e) => {
//                     handleEmployee("employee_id", e.target.value);
//                   }}
//                   value={leaveDetails?.employee_id}
//                 >
//                   <option value="">Select Employee</option>
//                   {employeeList &&
//                     employeeList.map((option, i) => {
//                       return (
//                         <option key={i} value={option.employee_Id}>
//                           {option.emp_code}
//                         </option>
//                       );
//                     })}
//                 </select>
//                 {errors["employee_id"] ? (
//                   <div className="validation-error-label">
//                     {errors["employee_id"]}
//                   </div>
//                 ) : null}
//               </div>
//               <div className="field-block">
//                 <label className="label-name">
//                   Employee Name
//                   <span className="font-bold" style={{ color: "#FF5C42" }}>
//                     *
//                   </span>
//                 </label>
//                 <input
//                   className="input-field"
//                   type="text"
//                   autoComplete="off"
//                   placeholder="Employee Name"
//                   name="employee_name"
//                   value={empName}
//                   readOnly
//                   disabled
//                 />
//               </div>
//               <div className="field-block">
//                 <label className="label-name">
//                   Date
//                   <span className="font-bold" style={{ color: "#FF5C42" }}>
//                     *
//                   </span>
//                 </label>
//                 <input
//                   className="input-field"
//                   type="date"
//                   autoComplete="off"
//                   placeholder="Date"
//                   name="date"
//                   onChange={(e) => {
//                     handleChange("date", e.target.value);
//                   }}
//                   value={leaveDetails?.date}
//                 />
//                 {errors["date"] ? (
//                   <div className="validation-error-label">{errors["date"]}</div>
//                 ) : null}
//               </div>

//               <div className="field-block">
//                 <label className="label-name">
//                   In Time
//                   <span className="font-bold" style={{ color: "#FF5C42" }}>
//                     *
//                   </span>
//                 </label>
//                 <input
//                   className="input-field"
//                   type="time"
//                   autoComplete="off"
//                   placeholder="In Time"
//                   name="clock_in"
//                   onChange={(e) => {
//                     handleChange("clock_in", e.target.value);
//                   }}
//                   value={leaveDetails?.clock_in}
//                 />
//                 {errors["clock_in"] ? (
//                   <div className="validation-error-label">
//                     {errors["clock_in"]}
//                   </div>
//                 ) : null}
//               </div>
//               <div className="field-block">
//                 <label className="label-name">
//                   Out Time
//                   <span className="font-bold" style={{ color: "#FF5C42" }}>
//                     *
//                   </span>
//                 </label>

//                 <input
//                   className="input-field"
//                   type="time"
//                   autoComplete="off"
//                   placeholder="Out Time"
//                   name="clock_out"
//                   onChange={(e) => {
//                     handleChange("clock_out", e.target.value);
//                   }}
//                   value={leaveDetails?.clock_out}
//                 />
//                 {errors["clock_out"] ? (
//                   <div className="validation-error-label">
//                     {errors["clock_out"]}
//                   </div>
//                 ) : null}
//               </div>

//               <div className="field-block">
//                 <label className="label-name">
//                   Status
//                   <span className="font-bold" style={{ color: "#FF5C42" }}>
//                     *
//                   </span>
//                 </label>
//                 <select
//                   className="cursor-pointer input-field"
//                   onChange={(e) => {
//                     handleChange("approval_status", e.target.value);
//                   }}
//                   name="approval_status"
//                   value={leaveDetails?.status}
//                 >
//                   <option value="">Select Status</option>
//                   <option value="pending">Pending</option>
//                   <option value="approved">Approved</option>
//                 </select>
//                 {errors["status"] ? (
//                   <div className="validation-error-label">
//                     {errors["status"]}
//                   </div>
//                 ) : null}
//               </div>

//               <div className="field-block">
//                 <label className="label-name">Fine Type</label>
//                 <select
//                   name="fine_type_id"
//                   className="input-field"
//                   onChange={(e) => {
//                     handleChange("fine_type_id", e.target.value);
//                   }}
//                   value={leaveDetails?.fine_type_id}
//                 >
//                   <option value="">Select Fine type</option>
//                   {fineList &&
//                     fineList.map((option, i) => {
//                       return (
//                         <option key={i} value={option.id}>
//                           {option.name}
//                         </option>
//                       );
//                     })}
//                 </select>
//                 {errors["fine_type_id"] ? (
//                   <div className="validation-error-label">
//                     {errors["fine_type_id"]}
//                   </div>
//                 ) : null}
//               </div>
//               <div className="field-block">
//                 <label className="label-name">Fine Amount</label>
//                 <input
//                   className=" input-field"
//                   type="number"
//                   autoComplete="off"
//                   placeholder="Fine Amount"
//                   name="fine_amount"
//                   onChange={(e) => {
//                     handleChange("fine_amount", e.target.value);
//                   }}
//                   value={leaveDetails?.fine_amount}
//                 />
//                 {errors["fine_amount"] ? (
//                   <div className="validation-error-label">
//                     {errors["fine_amount"]}
//                   </div>
//                 ) : null}
//               </div>
//               <div className="field-block">
//                 <label className="label-name">Approval</label>
//                 <select
//                   name="approved_by"
//                   className="input-field "
//                   onChange={(e) => {
//                     handleChange("approved_by", e.target.value);
//                   }}
//                   label="Approval"
//                   value={leaveDetails?.approved_by}
//                 >
//                   <option value="">Select Approved by</option>
//                   {employeeList &&
//                     employeeList.map((option, i) => {
//                       return (
//                         <option key={i} value={option.employee_Id}>
//                           {option.first_name} {option?.fathers_name}
//                         </option>
//                       );
//                     })}
//                 </select>
//                 {errors["approved_by"] ? (
//                   <div className="validation-error-label">
//                     {errors["approved_by"]}
//                   </div>
//                 ) : null}
//               </div>
//             </div>
//             <div className="submit-reset-button">
//               <button
//                 type="button"
//                 className="reset-button"
//                 onClick={resetForm}
//               >
//                 <span className="reset-button-label">Reset</span>
//               </button>
//               <button
//                 disabled={buttonDisabled}
//                 type="submit"
//                 className="submit-button"
//               >
//                 <span className="submit-button-label">Add</span>
//                 <img src={ColorAdd} alt="" />
//               </button>
//             </div>
//           </form> */}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default CreateManualAttendance;
