import React, { useState, useEffect } from "react";
import More from "../../Assets/Icons/More.svg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { DELETE_API, GET_API } from "../../Services/api";
import FineDetailsTable from "../DataTable/FineDetailsTable";
import Search from "../../Assets/Icons/Search.svg";
import ColorAdd from "../../Assets/Icons/ColorAdd.svg";
import Spinner from "../Spinner";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";

function FineList() {
  const navigate = useNavigate();
  const [fineDetailsList, setFineDetailsList] = useState([]);
  const [filteredFineDetails, setFilteredFineDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  const showFineDetailsDetails = (listDetail) => {
    navigate("/masters/fine/details", {
      state: {
        list: listDetail,
      },
    });
  };
  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "fineDetails_name", label: "Employee Name" },
    { id: "empCode", label: "Employee Code" },
    { id: "fine_type_name", label: "Fine Type" },
    { id: "fine_amount", label: "Fine Amount" },
    { id: "remarks", label: "Remarks" },
    { id: "created_at", label: "Created At" },
    { id: "more_icon", label: "" },
  ];

  useEffect(() => {
    getFineDetailsList();
  }, []);
  const getFineDetailsList = async () => {
    setLoading(true);
    GET_API("/employeeFineList")
      .then(async function (data) {
        setFineDetailsList(data?.data?.message);
        setFilteredFineDetails(data?.data?.message);
        setLoading(false);
      })

      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const handleFineDetailsSearch = (e) => {
    const searchTerm = e.target.value;
    const searchedList = fineDetailsList.filter((item) => {
      for (let key in item) {
        if (
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
    setFilteredFineDetails(searchedList);
  };

  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete FineList..?",
        async function () {
          try {
            if (row_id) {
              const deleteRow = await DELETE_API(
                `/employeeFineDelete/${row_id}`
              );
              if (deleteRow.status === 200) {
                alertify.success(deleteRow?.data?.message);
                getFineDetailsList();
                return;
              }
            }
          } catch (error) {
            alertify.error("Failed to Delete Fine List..!");
          }
        },
        function () {}
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };
  const navigateEditPage = (listDetail) => {
    navigate("/masters/fine/edit", {
      state: {
        list: listDetail,
      },
    });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">FineDetails</h1>
        <div className="card-wrapper">
          <h4 className="card-header">FineDetails List</h4>
          <div className="search-block">
            <div className="add-new-button-block">
              <Link to="/masters/fine/add" className="add-new-button">
                <span>Add New</span>
                <img src={ColorAdd} alt="add" />
              </Link>
            </div>
            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                onChange={(e) => handleFineDetailsSearch(e)}
                style={{ outline: "none", border: "none" }}
              />

              <img src={Search} alt="" />
            </div>
          </div>

          {/**************** * Table ********************/}

          <FineDetailsTable
            list={filteredFineDetails}
            columns={columns}
            icon={More}
            multiple={true}
            editPage={navigateEditPage}
            showDetails={showFineDetailsDetails}
            deleteRow={handleDelete}
          />
        </div>
      </div>
    </div>
  );
}

export default FineList;
