import React from "react";
import { Route } from "react-router-dom";

import Loan from "../Finance/Loan/LoanList";
import CreateLoan from "../Finance/Loan/CreateLoan";
import LoanDetails from "../Finance/Loan/LoanDetails";

import AgentCommission from "../AgentCommission/agentcommisonList";
import CreateAgentCommission from "../AgentCommission/agentCreate";

import Bonus from "../BonusDetails/bonusdetails";
import CreateBonus from "../BonusDetails/createbonus";

import Advance from "../Finance/Advance/AdvanceList";
import CreateAdvance from "../Finance/Advance/CreateAdvance";
import AdvanceDetails from "../Finance/Advance/AdvanceDetails";

import Expense from "../Finance/Expenses/ExpenseList";
import CreateExpense from "../Finance/Expenses/ExpenseCreate";
import ExpenseDetails from "../Finance/Expenses/ExpenseDetails";

function LoanAndExpenseRoutes() {
  return (
    <>
      {/**loan */}
      <Route path="loan" element={<Loan />}></Route>
      <Route path="loan/add" element={<CreateLoan />}></Route>
      <Route path="loan/details" element={<LoanDetails />}></Route>

      {/**agent */}
      <Route path="agentcommission" element={<AgentCommission />}></Route>
      <Route
        path="agentcommission/add"
        element={<CreateAgentCommission />}
      ></Route>

      {/**bonus */}
      <Route path="bonus" element={<Bonus />}></Route>
      <Route path="bonus/add" element={<CreateBonus />}></Route>

      {/**advance */}
      <Route path="advance" element={<Advance />}></Route>
      <Route path="advance/add" element={<CreateAdvance />}></Route>
      <Route path="advance/details" element={<AdvanceDetails />}></Route>

      {/**expense */}
      <Route path="/expense" element={<Expense />}></Route>
      <Route path="/expense/add" element={<CreateExpense />}></Route>
      <Route path="/expense/details" element={<ExpenseDetails />}></Route>
    </>
  );
}

export default LoanAndExpenseRoutes;
