import React, { useState } from "react";
import alertify from "alertifyjs";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { DELETE_API, GET_API, POST_API } from "../../../Services/api";
import Validation from "../../../Validation/Validation";
import QualificationTable from "./Components/QualificationTable";
import More from "../../../Assets/Icons/More.svg";
import "alertifyjs/build/css/alertify.css";
import { css } from "@emotion/react";
import { ClipLoader } from "react-spinners";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

function EmployeeBasicInfo({ stateChanger, empDetails, nextTab, getEmployeeData }) {
  const { id } = useParams()
  const [isBtnDisable, setIsBtnDisable] = useState(false)
  const [qualId, setQualId] = useState(1)
  const [errors, seterrors] = useState({});
  const [qualificationDetails, setQualificationDetails] = useState({
    qualification: "",
    institute_name: "",
    percentage: ""
  })
  const [qualificationErrors, setQualificationErrors] = useState({})

  const label = [
    {
      label: "Qualification",
      name: "employee_qualification",
      placeholder: "Enter Education Qualification",
      type: "text",
    },

    {
      label: "Passed Out",
      name: "employee_passed_out",
      placeholder: "Enter Passed Out Year",
      type: "number",
    }
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    stateChanger(() => ({ ...empDetails, [name]: value }));
  };

  const handleValidation = async(e) => {
    e.preventDefault();
    setIsBtnDisable(true)
    const isValid = Validation.validateEmployeeEducationalInfo(empDetails);
    if (isValid && isValid.error) {
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      setIsBtnDisable(false)
      return;
    } else {
      seterrors({});
      try {
        const educationInfo = await POST_API(`/updateEmployeeTentacles/${id}`, empDetails);
        console.log("educationInfo", educationInfo);
        if (educationInfo?.data?.status === 200) {
          setIsBtnDisable(false)
          nextTab("bank_info");
        }
      } catch (error) {
        console.log("error");
        setIsBtnDisable(false)
      }
    }
  };

  const handleExperienceChange = (e) => {
    const { name, value } = e.target;
    setQualificationDetails(() => ({ ...qualificationDetails, [name]: value }));
    setQualificationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleQualificationDetails = async (e) => {
    setQualId((prevState) => prevState + 1)
    e.preventDefault();
    const isValid = Validation.validateQualification(qualificationDetails);
    if (isValid && isValid.error) {
      const errors = {};
      isValid.error.details.forEach((detail) => {
        const key = detail.path[0];
        const message = detail.message;
        errors[key] = message;
      });
      setQualificationErrors(errors);
      return;
    } else {
      if (!id) {
        setQualificationErrors({});
        stateChanger((prevEmpDetails) => {
          const newEmpDetails = {
            ...prevEmpDetails,
            qualification_details: prevEmpDetails?.qualification_details || [],
          };

          return {
            ...newEmpDetails,
            qualification_details: [...newEmpDetails.qualification_details, { ...qualificationDetails, id: qualId }],
          };
        });
        setQualificationDetails({
          qualification: "",
          institute_name: "",
          percentage: ""
        })
      } else {
        try {
          const createWork = await POST_API("/createEducationQualificationDetails", { ...qualificationDetails, employee_id: Number(id) });
          if (createWork.status === 200) {
            alertify.success(createWork?.data?.message);
            getEmployeeData()
          }
        } catch (error) {
          alertify.error(error?.response?.message);
        }
      }
    }
  }

  const handleQualificationDelete = (row_id) => {
    if (!id) {
      const delFamily = empDetails?.qualification_details?.filter(item => item?.id !== row_id);
      stateChanger({ ...empDetails, qualification_details: [...delFamily] });
      alertify.success("Deleted Successfully!..")
    } else {
      alertify
        .confirm(
          "Confirm",
          "Confirm to Delete Qualification Details..?",
          async function () {
            if (row_id) {
              try {
                const deleteRow = await DELETE_API(`/deleteeducationDetails/${row_id}`);
                if (deleteRow.status === 200) {
                  alertify.success(deleteRow?.data?.message);
                  getEmployeeData();
                  return;
                }
              } catch (error) {
                alertify.error("Failed To Delete Qualification Details");
              }
            }
          },
          function () { }
        )
        .set("movable", false)
        .set("labels", { ok: "Delete!", cancel: "Cancel!" });
    }
  }

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "qualification", label: "Qualification" },
    { id: "institute_name", label: "Name of the Institution" },
    { id: "percentage", label: "Percentage of Marks" },
    { id: "percentage", label: "Action" }
  ];

  const handleSelectChange = (e, name) => {
    const { value } = e;
    stateChanger(() => ({ ...empDetails, [name]: value }));
  };

  const handleQualificationSelect = (e, name) => {
    const { value } = e;
    setQualificationDetails(() => ({ ...qualificationDetails, [name]: value }));
    setQualificationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const qualificationData = [
    { value: "SSLC", label: "SSLC" },
    { value: "HSC", label: "HSC" },
    { value: "Diploma", label: "Diploma" },
    { value: "Bachelor's Degree", label: "Bachelor's Degree" },
    { value: "Master's Degree", label: "Master's Degree" },
    { value: "Ph.D.", label: "Ph.D." },
    { value: "Medical Degree", label: "Medical Degree" },

  ]

  const qualificationDetail = [
    { label: "Bachelor of Arts in Tamil (BA Tamil)", value: "Bachelor of Arts in Tamil (BA Tamil)" },
    { label: "Bachelor of Arts in English (BA English)", value: "Bachelor of Arts in English (BA English)" },
    { label: "Bachelor of Science in Physics (BSc Physics)", value: "Bachelor of Science in Physics (BSc Physics)" },
    { label: "Bachelor of Science in Chemistry (BSc Chemistry)", value: "Bachelor of Science in Chemistry (BSc Chemistry)" },
    { label: "Bachelor of Commerce (BCom)", value: "Bachelor of Commerce (BCom)" },
    { label: "Bachelor of Engineering in Civil Engineering (BE Civil)", value: "Bachelor of Engineering in Civil Engineering (BE Civil)" },
    { label: "Bachelor of Engineering in Mechanical Engineering (BE Mechanical)", value: "Bachelor of Engineering in Mechanical Engineering (BE Mechanical)" },
    { label: "Bachelor of Engineering in Electrical Engineering (BE Electrical)", value: "Bachelor of Engineering in Electrical Engineering (BE Electrical)" },
    { label: "Bachelor of Technology in Computer Science (B.Tech CS)", value: "Bachelor of Technology in Computer Science (B.Tech CS)" },
    { label: "Bachelor of Business Administration (BBA)", value: "Bachelor of Business Administration (BBA)" },
    { label: "Bachelor of Computer Applications (BCA)", value: "Bachelor of Computer Applications (BCA)" },
    { label: "Bachelor of Fine Arts (BFA)", value: "Bachelor of Fine Arts (BFA)" },
    { label: "Bachelor of Architecture (B.Arch)", value: "Bachelor of Architecture (B.Arch)" },
    { label: "Bachelor of Medicine, Bachelor of Surgery (MBBS)", value: "Bachelor of Medicine, Bachelor of Surgery (MBBS)" },
    { label: "Bachelor of Dental Surgery (BDS)", value: "Bachelor of Dental Surgery (BDS)" },
    { label: "Bachelor of Pharmacy (BPharm)", value: "Bachelor of Pharmacy (BPharm)" },
    { label: "Bachelor of Education (BEd)", value: "Bachelor of Education (BEd)" },
    { label: "Bachelor of Laws (LLB)", value: "LLB" },
    { label: "Bachelor of Social Work (BSW)", value: "Bachelor of Social Work (BSW)" }

  ]
  return (
    <>
      <form>
        <div className="form">
          {label.map((list) => {
            return (
              <div className="field-block dropdown-select-to-one">
                <label className="label-name">
                  {list.label}

                  {list?.name != "remarks" && (
                    <span className="required-symbol">*</span>
                  )}
                </label>

                {list.name === "employee_qualification" ? (
                  <>
                    <Select
                      options={qualificationData}
                      onChange={(e) => handleSelectChange(e, "employee_qualification")}
                      name="employee_qualification"
                      value={qualificationData?.find(
                        (option) => option?.value === empDetails.employee_qualification
                      )}
                      className=""
                      isSearchable
                    />
                    {errors?.employee_qualification ? (
                      <div className="validation-error-label">{errors?.employee_qualification}</div>
                    ) : null}

                  </>
                ) : (
                  <Textfield
                    handleChange={handleChange}
                    name={list.name}
                    placeholder={list.placeholder}
                    errors={errors}
                    value={empDetails[list.name]}
                    type={list?.type}
                  />
                )}
              </div>
            );
          })}
        </div>

        <form>
          <div className="flex employee-sub-header">
            <h3 className="text-text-dark text-lg font-semibold w-1/2">Qualification Details </h3>
            <div className="w-1/2 text-right">
              <button onClick={e => handleQualificationDetails(e)} type="submit" className="employee-add-button"><span className="submit-button-label">Add new</span></button>
            </div>
          </div>
          <div className="p-2 grid grid-cols-3 gap-5">
            <div className="field-block dropdown-select-to-two">
              <label className="label-name">Qualification<span className="required-symbol">*</span></label>
                <Select
                  options={qualificationDetail}
                  onChange={(e) => handleQualificationSelect(e, "qualification")}
                  name="qualification"
                  value={qualificationDetail?.find(
                    (option) => option?.value === qualificationDetails.qualification
                  )}
                  className=""
                  isSearchable
                />
                {qualificationErrors.qualification ? (
                <div className="validation-error-label">{qualificationErrors.qualification}</div>
              ) : null}
              {/* <input
                name="qualification"
                className="input-field"
                type="text"
                onChange={(e) => handleExperienceChange(e)}
                placeholder="Enter Qualification"
                autoComplete="off"
                value={qualificationDetails?.qualification}
              />
              {qualificationErrors.qualification ? (
                <div className="validation-error-label">{qualificationErrors.qualification}</div>
              ) : null} */}
            </div>
            <div className="field-block">
              <label className="label-name">Name of  the Institution<span className="required-symbol">*</span></label>
              <input
                name="institute_name"
                className="input-field"
                type="text"
                onChange={(e) => handleExperienceChange(e)}
                placeholder="Enter Name of the Institution"
                autoComplete="off"
                value={qualificationDetails?.institute_name}
              />
              {qualificationErrors.institute_name ? (
                <div className="validation-error-label">{qualificationErrors.institute_name}</div>
              ) : null}
            </div>

            <div className="field-block">
              <label className="label-name">Percentage of Marks<span className="required-symbol">*</span></label>
              <input
                name="percentage"
                className="input-field"
                type="text"
                onChange={(e) => handleExperienceChange(e)}
                placeholder="Enter Percentage of Marks"
                autoComplete="off"
                value={qualificationDetails?.percentage}
              />
              {qualificationErrors.percentage ? (
                <div className="validation-error-label">{qualificationErrors.percentage}</div>
              ) : null}
            </div>
          </div>
        </form>
        <div>
          <QualificationTable
            list={empDetails?.qualification_details}
            columns={columns}
            icon={More}
            multiple={false}
            deleteRow={handleQualificationDelete}
            isAction={true}
          />
        </div>

        <div className="submit-reset-button">
          <button
            type="button"
            className="reset-button"
            onClick={() => nextTab("position_info")}
          >
            Prev
          </button>
          <button
            className="submit-button"
            onClick={(e) => handleValidation(e)}
          >
            {isBtnDisable ?
        <ClipLoader
            css={override}
            size={20}
            color={"#fff"}
            loading={isBtnDisable}
          /> : ""}
            <span className="submit-button-label">{isBtnDisable ? "Loading..." : "Save"}</span>
          </button>
          <button className="reset-button" disabled={isBtnDisable} onClick={() => nextTab("bank_info")}>
          <span className="reset-button-label">Next</span>
        </button>
        </div>
      </form>
    </>
  );
}

function Textfield({ name, handleChange, placeholder, errors, value, type }) {
  return (
    <>
      <input
        className="input-field"
        type={type}
        onChange={(e) => handleChange(e)}
        name={name}
        placeholder={placeholder}
        autoComplete="off"
        value={value}
      />
      {errors[name] ? (
        <div className="text-[#FF5C42] text-sm font-medium font-karla">
          {errors[name]}
        </div>
      ) : null}
    </>
  );
}


export default EmployeeBasicInfo;
