import React, { useState } from "react";
// import useScanDetection from "use-scan-detection";
import { GET_API } from "../Services/api";
import alertify from "alertifyjs";
import { numberToWords } from "./NumbersToWords/NumbersToWords";
import Logo from "../Assets/Logo/Logo.png";

function BarcodeDetection() {
  const [tableName, setTableName] = useState("");
  const [payslipDetails, setPayslipDetails] = useState({});
  const [nonPayslipObj, setnonPayslipObj] = useState({
    tableName: "",
    voucherId: "",
    empCode: "",
    empName: "",
    designation: "",
    department: "",
    amount: "",
    paymentMode: "",
    date: "",
  });

  // useScanDetection({
  //   onComplete: async (code) => {
  //     try {
  //       if (code !== "") {
  //         const voucherDetails = await GET_API(`voucherdetails/${code}`);

  //         if (
  //           voucherDetails?.status === 200 &&
  //           voucherDetails?.data?.data?.length > 0
  //         ) {
  //           voucherDetails?.data?.data.map((item) => {
  //             setTableName(item?.tableName);
  //             if (item?.tableName === "payslip") {
  //               //for payslip details
  //               item?.voucherDetails?.map((detail) => {
  //                 const parsedData = JSON.parse(detail?.data);
  //                 setPayslipDetails(parsedData);
  //               });
  //             } else {
  //               setnonPayslipObj({
  //                 ...nonPayslipObj,
  //                 tableName: item?.tableName,
  //                 voucherId: item?.voucherId,
  //                 empCode: item?.voucherDetails?.emp_code,
  //                 empName: item?.voucherDetails?.approved_by_name,
  //                 amount: item?.voucherDetails?.amount,
  //                 date: item?.voucherDetails?.created_at,
  //                 department: item?.voucherDetails?.department_name,
  //                 designation: item?.voucherDetails?.designation_name,
  //                 paymentMode: item?.voucherDetails?.payment_type,
  //               });
  //             }
  //           });
  //         } else {
  //           alertify.success(voucherDetails?.data?.message);
  //         }
  //         return;
  //       }
  //       alertify.error("Failed to get Voucher ID");
  //     } catch (error) {
  //       alertify.error(error?.response?.data?.message);
  //     }
  //   },
  // });

  const fathers_name =
    payslipDetails?.employee?.fathers_name !== null
      ? payslipDetails?.employee?.fathers_name
      : "";
  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">Detection</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Detection Details</h4>
          <div className="p-5">
            {(tableName !== "") & (tableName !== "payslip") ? (
              <>
                <h2 className="text-center font-karla text-lg">
                  {nonPayslipObj?.tableName.toUpperCase().replace("_", " ")}
                </h2>
                <div className="flex justify-between items-center">
                  <div className="flex gap-2 w-[30%]">
                    <div className="text-text-dark text-base font-karla">
                      Voucher No
                    </div>
                    <div className="text-text-light text-base font-karla">
                      {nonPayslipObj?.voucherId}
                    </div>
                  </div>

                  <div className="flex gap-2 w-[30%]">
                    <div className="text-text-dark text-base font-karla">
                      Date
                    </div>
                    <div className="text-text-light text-base font-karla">
                      {nonPayslipObj?.date}
                    </div>
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <div className="flex gap-2 w-[30%]">
                    <div className="text-text-dark text-base font-karla">
                      Emp Code
                    </div>
                    <div className="text-text-light text-base font-karla">
                      {nonPayslipObj?.empCode}
                    </div>
                  </div>

                  <div className="flex gap-2 w-[30%]">
                    <div className="text-text-dark text-base font-karla">
                      Designation
                    </div>
                    <div className="text-text-light text-base font-karla">
                      {nonPayslipObj?.designation}
                    </div>
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <div className="flex gap-2 w-[30%]">
                    <div className="text-text-dark text-base font-karla">
                      Emp Name
                    </div>
                    <div className="text-text-light text-base font-karla">
                      {nonPayslipObj?.empName}
                    </div>
                  </div>

                  <div className="flex gap-2 w-[30%]">
                    <div className="text-text-dark text-base font-karla">
                      Department
                    </div>
                    <div className="text-text-light text-base font-karla">
                      {nonPayslipObj?.department}
                    </div>
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <div className="flex gap-2 w-[30%]">
                    <div className="text-text-dark text-base font-karla">
                      Amount
                    </div>
                    <div className="text-text-light text-base font-karla">
                      {nonPayslipObj?.amount}
                    </div>
                  </div>

                  <div className="flex gap-2 w-[30%]">
                    <div className="text-text-dark text-base font-karla">
                      Payment Mode
                    </div>
                    <div className="text-text-light text-base font-karla">
                      {nonPayslipObj?.paymentMode}
                    </div>
                  </div>
                </div>
              </>
            ) : (tableName !== "") & (tableName === "payslip") ? (
              <div className="card-wrapper">
                <div>
                  <h4 className="payslip-header">
                    <div className="flex justify-between items-center">
                      <div>
                        <span className="text-text-dark text-base font-karla">
                          Tentacle Secure Squads
                        </span>
                        <br></br>
                        <span className="text-text-dark text-base font-karla">
                          PaySlip Month for
                        </span>{" "}
                        <b className="text-text-dark text-base font-karla">
                          {payslipDetails?.displayMonth}
                        </b>
                      </div>
                      <div className="flex gap-3 w-[21%]">
                        <img src={Logo} className="h-[60px] w-[60px]" alt="" />
                      </div>
                    </div>
                  </h4>
                  <div className="p-1 pl-3 pr-3">
                    <div className="flex justify-between">
                      <div>
                        <div className="flex">
                          <label className="list-label">Employee Code: </label>
                          <div
                            style={{ wordBreak: "break-word" }}
                            className="advance-section-list-value"
                          >
                            {payslipDetails?.employee?.emp_code}
                          </div>
                        </div>
                        <div className="flex">
                          <label className="list-label">Employee Name: </label>
                          <div
                            style={{ wordBreak: "break-word" }}
                            className="advance-section-list-value"
                          >
                            {payslipDetails?.employee?.first_name +
                              " " +
                              fathers_name}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="flex">
                          <label className="list-label">Designation: </label>
                          <div
                            style={{ wordBreak: "break-word" }}
                            className="advance-section-list-value"
                          >
                            {payslipDetails?.employee?.designation_name}
                          </div>
                        </div>
                        <div className="flex">
                          <label className="list-label">Date: </label>
                          <div
                            style={{ wordBreak: "break-word" }}
                            className="advance-section-list-value"
                          >
                            {payslipDetails?.date}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="p-3 pt-1">
                    <table className=" w-[100%] border border-[#d9d9d9] border-collapse ">
                      <tbody>
                        <tr>
                          <td className="border  border-[#d9d9d9] p-3 pt-3 p w-[40%]  align-baseline">
                            <table className="w-[100%]">
                              <tbody>
                                <tr>
                                  <td className="text-text-dark text-base font-karla">
                                    Holiday Scheme
                                  </td>
                                  <td className="text-right">
                                    <b>
                                      {
                                        payslipDetails?.employee
                                          ?.holiday_scheme_name
                                      }
                                    </b>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-text-dark text-base font-karla">
                                    Present Days
                                  </td>
                                  <td className="text-right">
                                    <b>
                                      {payslipDetails?.attendance?.presentDays}{" "}
                                      days
                                    </b>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-text-dark text-base font-karla">
                                    Absent Days
                                  </td>
                                  <td className="text-right">
                                    <b>
                                      {payslipDetails?.attendance?.absentDays}{" "}
                                      days
                                    </b>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-text-dark text-base font-karla">
                                    Total Absent Days
                                  </td>
                                  <td className="text-right">
                                    <b>
                                      {
                                        payslipDetails?.attendance
                                          ?.totalAbsentDays
                                      }{" "}
                                      days
                                    </b>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td className="border  border-[#d9d9d9]  p-3 pt-3 w-[40%]  align-baseline">
                            <table className="w-[100%]">
                              <tbody>
                                <tr>
                                  <th colSpan="4" className="text-left">
                                    <b> Earnings</b>
                                  </th>
                                </tr>
                                {payslipDetails?.earnings &&
                                  payslipDetails?.earnings.map((list, i) => {
                                    return (
                                      <tr>
                                        <td className="text-text-dark text-base font-karla">
                                          {list?.name}
                                        </td>
                                        <td className="text-text-dark text-base font-karla">
                                          {list?.type}
                                        </td>
                                        <td className="text-right text-text-dark text-base font-karla">
                                          {list?.totalAmount}
                                        </td>
                                        <td className="text-right">
                                          <b>{list?.actualAmount}</b>
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </td>
                          <td className="border  border-[#d9d9d9]  p-3 pt-3 w-[20%] align-baseline">
                            <table className="w-[100%]">
                              <tbody>
                                <tr>
                                  <th colSpan="2" className="text-left">
                                    <b>Deductions</b>
                                  </th>
                                </tr>
                                {payslipDetails?.deductions &&
                                  payslipDetails?.deductions.map((list, i) => {
                                    return (
                                      <tr>
                                        <td className="text-text-dark text-base font-karla">
                                          {list?.name}
                                        </td>
                                        <td className="text-right text-text-dark text-base font-karla">
                                          <b>{list?.amount}</b>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                {payslipDetails?.otherDeductions &&
                                  payslipDetails?.otherDeductions.map(
                                    (list, i) => {
                                      return (
                                        <tr>
                                          <td className="text-text-dark text-base font-karla">
                                            {list?.name}
                                          </td>
                                          <td className="text-right text-text-dark text-base font-karla">
                                            <b>{list?.amount}</b>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td className="border  border-[#d9d9d9]  p-1 pl-2 pr-2 p w-[30%]  align-baseline">
                            <table className="w-[100%]">
                              <tbody>
                                <tr>
                                  <td>
                                    <b className="text-text-dark text-base font-karla">
                                      Total Days
                                    </b>
                                  </td>
                                  <td className="text-right">
                                    {payslipDetails?.attendance?.totalDays} days
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td className="border  border-[#d9d9d9]  p-1 pl-2 pr-2 p w-[30%]  align-baseline">
                            <table className="w-[100%]">
                              <tbody>
                                <tr>
                                  <td className="w-[65%]">
                                    <b className="text-text-dark text-base font-karla">
                                      Total
                                    </b>
                                  </td>
                                  <td className="text-right text-text-dark text-base font-karla">
                                    (-{payslipDetails?.totalEarnings?.deduction}
                                    )
                                  </td>
                                  <td className="text-right text-text-dark text-base font-karla">
                                    {payslipDetails?.totalEarnings?.total}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td className="border  border-[#d9d9d9]  p-1 pl-2 pr-2 p w-[30%]  align-baseline">
                            <table className="w-[100%]">
                              <tbody>
                                <tr>
                                  <td>
                                    <b className="text-text-dark text-base font-karla">
                                      Total
                                    </b>
                                  </td>
                                  <td className="text-right text-text-dark text-base font-karla">
                                    {payslipDetails?.totalDeductions?.total}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="p-3 flex">
                    <p className="text-text-dark text-base font-karla">
                      Salary to be paid in cash :
                    </p>
                    <p className="text-text-dark text-base font-karla">
                      {`${payslipDetails?.salaryToBePaid} (${numberToWords(
                        payslipDetails?.salaryToBePaid
                      )})`}
                    </p>
                  </div>
                  <br />
                  <div className="p-3 pt-4">
                    <div className="flex justify-between items-center">
                      <div>
                        <b className="text-text-dark text-base font-karla">
                          Admit by
                        </b>
                      </div>
                      <div>
                        <b className="text-text-dark text-base font-karla">
                          Checked by
                        </b>
                      </div>
                      <div>
                        <b className="text-text-dark text-base font-karla">
                          Employee Signature
                        </b>
                      </div>
                    </div>
                  </div>
                  {/* PF Salary details */}
                  {payslipDetails?.pfEarnings &&
                    payslipDetails?.pfEarnings.length > 0 && (
                      <>
                        <div id="scissors">
                          <div></div>
                        </div>
                        <h4 className="payslip-pf-header">
                          <div className="flex justify-between items-center text-text-dark text-xl font-karla">
                            PF Salary Voucher
                          </div>
                        </h4>

                        <div className="p-1 pl-3 pr-3">
                          <div className="flex justify-between">
                            <div>
                              <div className="flex">
                                <label className="list-label">
                                  Employee Code:{" "}
                                </label>
                                <div
                                  style={{ wordBreak: "break-word" }}
                                  className="advance-section-list-value"
                                >
                                  {payslipDetails?.employee?.emp_code}
                                </div>
                              </div>
                              <div className="flex">
                                <label className="list-label">
                                  Employee Name:{" "}
                                </label>
                                <div
                                  style={{ wordBreak: "break-word" }}
                                  className="advance-section-list-value"
                                >
                                  {payslipDetails?.employee?.first_name +
                                    " " +
                                    payslipDetails?.employee?.fathers_name}
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="flex">
                                <label className="list-label">
                                  Designation:{" "}
                                </label>
                                <div
                                  style={{ wordBreak: "break-word" }}
                                  className="advance-section-list-value"
                                >
                                  {payslipDetails?.employee?.designation_name}
                                </div>
                              </div>
                              <div className="flex">
                                <label className="list-label">Date: </label>
                                <div
                                  style={{ wordBreak: "break-word" }}
                                  className="advance-section-list-value"
                                >
                                  {payslipDetails?.date}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="p-3 pt-1">
                          <table className=" w-[100%] border border-[#d9d9d9] border-collapse ">
                            <tbody>
                              <tr>
                                <td className="border  border-[#d9d9d9] p-3 pt-3 p w-[40%]  align-baseline">
                                  <table className="w-[100%]">
                                    <tbody>
                                      <tr>
                                        <td className="text-text-dark text-base font-karla">
                                          Holiday Scheme
                                        </td>
                                        <td className="text-right">
                                          <b className="text-text-dark text-base font-karla">
                                            {
                                              payslipDetails?.employee
                                                ?.holiday_scheme_name
                                            }
                                          </b>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="text-text-dark text-base font-karla">
                                          Present Days
                                        </td>
                                        <td className="text-right">
                                          <b className="text-text-dark text-base font-karla">
                                            {
                                              payslipDetails?.pfAttendance
                                                ?.presentDays
                                            }
                                          </b>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="text-text-dark text-base font-karla">
                                          Paid Leave Days
                                        </td>
                                        <td className="text-right text-text-dark text-base font-karla">
                                          <b>
                                            {
                                              payslipDetails?.pfAttendance
                                                ?.paidDays
                                            }
                                          </b>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="text-text-dark text-base font-karla">
                                          Net Present Days
                                        </td>
                                        <td className="text-right text-text-dark text-base font-karla">
                                          <b>
                                            {
                                              payslipDetails?.pfAttendance
                                                ?.netPresentDays
                                            }
                                          </b>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                                <td className="border  border-[#d9d9d9]  p-3 pt-3 w-[40%]  align-baseline">
                                  <table className="w-[100%]">
                                    <tbody>
                                      <tr>
                                        <th colSpan="4" className="text-left">
                                          <b className="text-text-dark text-base font-karla">
                                            Earnings
                                          </b>
                                        </th>
                                      </tr>
                                      {payslipDetails?.pfEarnings &&
                                        payslipDetails?.pfEarnings.map(
                                          (list, i) => {
                                            return (
                                              <tr>
                                                <td className="text-text-dark text-base font-karla">
                                                  {list?.name}
                                                </td>
                                                <td className="text-text-dark text-base font-karla">
                                                  {list?.type}
                                                </td>
                                                <td className="text-right text-text-dark text-base font-karla">
                                                  {list?.totalAmount}
                                                </td>
                                                <td className="text-right text-text-dark text-base font-karla">
                                                  {list?.actualAmount}
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                    </tbody>
                                  </table>
                                </td>
                                <td className="border  border-[#d9d9d9]  p-3 pt-3 w-[20%] align-baseline">
                                  <table className="w-[100%]">
                                    <tbody>
                                      <tr>
                                        <th colSpan="2" className="text-left">
                                          <b className="text-text-dark text-base font-karla">
                                            Deductions
                                          </b>
                                        </th>
                                      </tr>
                                      {payslipDetails?.pfDeductions &&
                                        payslipDetails?.pfDeductions.map(
                                          (list, i) => {
                                            return (
                                              <tr>
                                                <td className="text-text-dark text-base font-karla">
                                                  {list?.name}
                                                </td>
                                                <td className="text-right text-text-dark text-base font-karla">
                                                  <b>{list?.amount}</b>
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td className="border  border-[#d9d9d9]  p-1 pl-2 pr-2 p w-[30%]  align-baseline">
                                  <table className="w-[100%]">
                                    <tbody>
                                      <tr>
                                        <td>
                                          <b className="text-text-dark text-base font-karla">
                                            Total Days
                                          </b>
                                        </td>
                                        <td className="text-right text-text-dark text-base font-karla">
                                          {
                                            payslipDetails?.attendance
                                              ?.totalDays
                                          }
                                          days
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                                <td className="border  border-[#d9d9d9]  p-1 pl-2 pr-2 p w-[30%]  align-baseline">
                                  <table className="w-[100%]">
                                    <tbody>
                                      <tr>
                                        <td className="w-[65%]">
                                          <b className="text-text-dark text-base font-karla">
                                            Total
                                          </b>
                                        </td>
                                        <td className="text-right text-text-dark text-base font-karla">
                                          {payslipDetails?.pfEarnings &&
                                            payslipDetails?.pfEarnings[0] &&
                                            payslipDetails?.pfEarnings[0]
                                              .actualAmount}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                                <td className="border  border-[#d9d9d9]  p-1 pl-2 pr-2 p w-[30%]  align-baseline">
                                  <table className="w-[100%]">
                                    <tbody>
                                      <tr>
                                        <td>
                                          <b className="text-text-dark text-base font-karla">
                                            Total
                                          </b>
                                        </td>
                                        <td className="text-right text-text-dark text-base font-karla">
                                          {
                                            payslipDetails?.pfTotalDeductions
                                              ?.total
                                          }
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div className="p-3 flex text-text-dark text-base font-karla">
                          <p>Salary to be credited in Bank : </p>
                          <p className="text-text-dark text-base font-karla">
                            {`${
                              payslipDetails?.pfSalaryInBank
                            } (${numberToWords(
                              payslipDetails?.pfSalaryInBank
                            )})`}
                          </p>
                        </div>
                        <br />

                        <div className="p-3 pt-4">
                          <div className="flex justify-between items-center">
                            <div>
                              <b className="text-text-dark text-base font-karla">
                                Admit by
                              </b>
                            </div>
                            <div>
                              <b className="text-text-dark text-base font-karla">
                                Checked by
                              </b>
                            </div>
                            <div>
                              <b className="text-text-dark text-base font-karla">
                                Employee Signature
                              </b>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                </div>

                <hr className=" border-[#d9d9d9] mt-6"></hr>
                <div className="p-3">
                  <h3>
                    <b className="text-text-dark text-lg font-karla">
                      Attendance Details:
                    </b>
                  </h3>
                </div>
                <div className="grid grid-cols-2 p-6 pt-2 gap-2">
                  <div>
                    <p className="text-text-dark text-base font-karla">
                      Month :{" "}
                      <b className="text-text-dark text-base font-karla">
                        {payslipDetails?.displayMonth}
                      </b>
                    </p>
                    <p className="text-text-dark text-base font-karla">
                      Total days Present :
                      <b className="text-text-dark text-base font-karla">
                        {payslipDetails?.presentDetails &&
                          payslipDetails?.presentDetails.length}
                      </b>
                    </p>
                  </div>
                  <div className="flex justify-end items-center gap-3">
                    <p className="flex jusitify-center items-center gap-2">
                      <span className="bg-[#11B76B] border rounded border-[#11B76B] h-[14px] w-[14px]"></span>{" "}
                      <span className="text-text-dark text-base font-karla">
                        Present
                      </span>
                    </p>
                    <p className="flex jusitify-center items-center gap-2">
                      <span className="bg-[#ed452a] border rounded border-[#ed452a]  h-[14px] w-[14px]"></span>
                      <span className="text-text-dark text-base font-karla">
                        Absent
                      </span>
                    </p>
                  </div>
                </div>
                <div className="p-1 flex flex-col items-center justify-center">
                  <table className="overflow-x-scroll  text-center w-2/4">
                    <tr>
                      {payslipDetails?.daysArr &&
                        payslipDetails?.daysArr.map((list, i) => (
                          <th className="border attendance-header border-[#d9d9d9] p-3">
                            {list}
                          </th>
                        ))}
                    </tr>
                    {payslipDetails?.attendance_details &&
                      Object.keys(payslipDetails?.attendance_details).map(
                        (key) => (
                          <>
                            <tr>
                              {payslipDetails?.attendance_details[key].map(
                                (item, index) => {
                                  {
                                    return (
                                      <td className="font-karla border border-[#d9d9d9] p-3">
                                        <span
                                          className={`rounded px-2 py-1 ${
                                            item.value === "P"
                                              ? "bg-[#11B76B] text-[#FFF]"
                                              : item.value === "A"
                                              ? "bg-[#ed452a] text-[#FFF]"
                                              : ""
                                          }`}
                                        >
                                          {item.date}
                                        </span>
                                      </td>
                                    );
                                  }
                                }
                              )}
                            </tr>
                          </>
                        )
                      )}
                  </table>
                </div>
              </div>
            ) : (
              <div className="text-center">
                <span className="text-text-dark text-2xl font-karla">
                  Scan to Load Data...
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BarcodeDetection;
