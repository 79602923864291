import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import View from "../../../Assets/Icons/View.svg";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import Validation from "../../../Validation/Validation";
import { POST_API } from "../../../Services/api";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";

function CreateShift() {
  const navigate = useNavigate();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [shiftDetails, setShiftDetails] = useState({
    name: "",
    from_time: "",
    to_time: "",
  });
  const [errors, seterrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setShiftDetails(() => ({ ...shiftDetails, [name]: value }));
  };

  const createShift = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.validateShift(shiftDetails);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    POST_API("/addShifts", shiftDetails)
      .then(async function (data) {
        alertify.success(data?.data?.message);
        navigate("/masters/shift");
        setButtonDisabled(false);
      })
      .catch((error) => {
        setButtonDisabled(false);
        alertify.error(error?.response?.data?.message);
      });
  };
  const resetForm = () => {
    setShiftDetails({
      name: "",
      from_time: "",
      to_time: "",
    });
    seterrors({});
  };
  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">Shift</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Add Shift</h4>
          <div className="add-block">
            <Link to="/masters/shift" className="view-list-button">
              <span>View List</span>
              <img src={View} alt="add" />
            </Link>
          </div>

          {/***************Form************* **/}

          <form onSubmit={(e) => createShift(e)}>
            <div className="form">
              <div className="field-block">
                <label className="label-name">
                  Shift Name
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="name"
                  placeholder="Enter Shift Name"
                  value={shiftDetails.name}
                />
                {errors.name ? (
                  <div className="validation-error-label">{errors.name}</div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  From Time
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="time"
                  autoComplete="off"
                  placeholder="Please select from time"
                  name="from_time"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={shiftDetails?.from_time}
                />
                {errors.from_time ? (
                  <div className="validation-error-label">
                    {errors.from_time}
                  </div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  To Time
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field "
                  type="time"
                  autoComplete="off"
                  placeholder="Please select to time"
                  name="to_time"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={shiftDetails?.to_time}
                />
                {errors.to_time ? (
                  <div className="validation-error-label">{errors.to_time}</div>
                ) : null}
              </div>
            </div>
            <div className="submit-reset-button">
              <button
                type="button"
                className="reset-button"
                onClick={resetForm}
              >
                <span className="reset-button-label">Reset</span>
              </button>
              <button
                disabled={buttonDisabled}
                type="submit"
                className="submit-button"
              >
                <span className="submit-button-label">Add</span>
                <img src={ColorAdd} alt="" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateShift;
