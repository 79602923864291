import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import alertify from "alertifyjs";
import Spinner from "../../Spinner";
import { GET_API } from "../../../Services/api";
import LoanReportTable from "../../DataTable/LoanReportTable";
import Search from "../../../Assets/Icons/Search.svg";
import More from "../../../Assets/Icons/More.svg";
import Filter from "../../../Assets/Icons/Filter.svg";

function LoanReportList() {
  const navigate = useNavigate();
  const userId = localStorage?.getItem("user_id");
  const [loanList, setLoanList] = useState([]);
  const [filteredList, setfilteredList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [employeeOption, setEmployeeOption] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [department, setDepartment] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [filter, setFilter] = useState(false);
  const [showFilterResultData, setShowFilterResultData] = useState("");
  const [filterParams, setFilterParams] = useState("");
  const [filterList, setfilterList] = useState({
    branch_id: "",
    emp_type_id: "",
    gender: "",
    department_id: "",
    designation_id: "",
    joining_from_date: "",
    joining_to_date: "",
  });

  const showDetails = (listDetail) => {
    const id = listDetail?.employeeCode;
    navigate(`/report/loan/details/${id}`, {
      state: {
        list: listDetail,
      },
    });
  };

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "employee_name", label: "Employee Name" },
    { id: "employeeCode", label: "Employee Code" },
    { id: "department_name", label: "Department Name" },
    { id: "designation_name", label: "Designation Name" },
    { id: "amount", label: "Amount" },
    { id: "paid_amount", label: "Paid Amount" },
    { id: "interest", label: "Interest" },
    { id: "more_icon", label: "" },
  ];

  const handleBranchSearch = (e) => {
    const searchTerm = e.target.value;
    const searchedList = loanList.filter((item) => {
      for (let key in item) {
        if (
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
    setfilteredList(searchedList);
  };

  useEffect(() => {
    getEmployeeOptionList();
    getClientList();
    getBranchList();
    getDepartmentList();
    getDesignationList();
  }, []);

  useEffect(() => {
    getLoanReportList(filterParams);
  }, [showFilterResultData]);

  const getLoanReportList = async (filters) => {
    setLoading(true);
    try {
      const list = await GET_API(`/loanReport?login=${userId}${filters}`);
      if (list.status === 200) {
        setLoanList(list?.data?.data);
        setfilteredList(list?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      alertify.error("Failed to get Employee Report List");
      setLoading(false);
    }
  };

  const getEmployeeOptionList = async () => {
    setLoading(true);
    try {
      const employeeList = await GET_API(`/ListEmployeeTentacles?login=${userId}`);
      if (employeeList.status === 200) {
        setLoading(false);
        const empList = employeeList?.data?.data.map((item, i) => {
          return { value: item?.employee_id, label: `${item?.name} - ${item?.employee_code}` };
        });
        empList.unshift({ value: "", label: "Select..." });
        setEmployeeOption(empList);
      }
    } catch (e) {
      alertify.error("Failed to get Employee List");
      setEmployeeOption([])
    }
  };

  const getClientList = async () => {
    setLoading(true);
    try {
      const cliList = await GET_API("/clientLists");
      if (cliList.status === 200) {
        const client = cliList?.data?.data.map((item, i) => {
          return { value: item?.id, label: item?.name };
        });
        client.unshift({ value: "", label: "Select..." });
        setClientList(client);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Client List");
    }
  };

  const getBranchList = async () => {
    try {
      setLoading(true);
      const branchList = await GET_API("/branchList");
      if (branchList.status === 200) {
        const branch = branchList?.data?.data.map((item, i) => {
          return { value: item?.id, label: item?.name };
        });
        branch.unshift({ value: "", label: "Select..." });
        setBranchList(branch);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Branch List");
    }
  };

  const getDepartmentList = async () => {
    setLoading(true);
    try {
      const departmentList = await GET_API("/DepartmentList");
      if (departmentList.status === 200) {
        const dep = departmentList?.data?.data.map((item, i) => {
          return { value: item?.id, label: item?.name };
        });
        dep.unshift({ value: "", label: "Select..." });
        setDepartment(dep);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Department List");
    }
  };

  const getDesignationList = async () => {
    setLoading(true);
    try {
      const designationList = await GET_API("/CategoryList");
      if (designationList.status === 200) {
        const design = designationList?.data?.data.map((item, i) => {
          return { value: item?.id, label: item?.name };
        });
        design.unshift({ value: "", label: "Select..." });
        setDesignation(design);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Designation List");
    }
  };

  const genderList = [
    { label: "Select...", value: "" },
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
  ];

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const handleFilter = async (e, name, type) => {
    const value = type === "date" ? e?.target?.value : e?.value;
    filterList[name] = value;
    const list = { ...filterList };
    Object.keys(list).forEach((key) => {
      if (list[key] === "") {
        delete list[key];
      }
    });
    let concateURL = "";
    // let isFirst = true;
    if (isEmptyObject(list)) {
      setFilterParams(concateURL);
      setfilterList({ ...filterList, [name]: value });
    }

    Object.keys(list).forEach((key) => {
      const newURL = `${key}=${list[key]}`;
      concateURL += "&" + newURL;
      setFilterParams(concateURL);
      // if (isFirst) {
      //   concateURL += "?" + newURL;
      //   isFirst = false;
      // } else {
      //   concateURL += "&" + newURL;
      // }
    });
  };

  const showFilterResult = (e) => {
    e?.preventDefault();
    setShowFilterResultData(filterParams);
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="grow bg-[#F7F6F4]">
      <div className="sm-container">
        <h1 className="heading-text">Report</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Loan Report List</h4>
          <div className="search-block">
            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                style={{ outline: "none", border: "none" }}
                onChange={(e) => handleBranchSearch(e)}
              />
              <img src={Search} alt="" />
            </div>
            {/* <div className="add-new-button-block">
              <CSVLink
                className="view-list-button"
                data={exportList}
                headers={CSVFileHeaders}
              >
                <span>Export</span>
                <img src={Export} alt="export" />
              </CSVLink>
            </div> */}
            <div
              className="filter-button"
              onClick={() => setFilter((prev) => !prev)}
            >
              <span className="filter-btn-label">Filter</span>
              <img src={Filter} alt="" />
            </div>
          </div>
          {filter && (
            <form className="border-t-[1px] border-t-[#D9D9D9]">
              <div className="form mt-4">
                <div className="field-block dropdown-select-to-one">
                  <label className="label-name">Client</label>
                  <Select
                    options={clientList}
                    value={clientList?.find(
                      (option) => option?.value === filterList.client_id
                    )}
                    onChange={(e) => handleFilter(e, "client_id", "select")}
                    name="client_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-one">
                  <label className="label-name">
                    Designation
                  </label>
                  <Select
                    options={designation}
                    value={designation?.find(
                      (option) => option?.value === filterList.category_id
                    )}
                    onChange={(e) => handleFilter(e, "category_id", "select")}
                    name="category_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-two">
                  <label className="label-name">Employee</label>
                  <Select
                    options={employeeOption}
                    value={employeeOption?.find(
                      (option) => option?.value === filterList.employee_id
                    )}
                    onChange={(e) => handleFilter(e, "employee_id", "select")}
                    name="employee_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-two">
                  <label className="label-name">Branch Name</label>
                  <Select
                    options={branchList}
                    value={branchList?.find(
                      (option) => option?.value === filterList.branch_id
                    )}
                    onChange={(e) => handleFilter(e, "branch_id", "select")}
                    name="branch_id"
                    className=""
                    isSearchable
                  />
                </div>

                <div className="field-block dropdown-select-to-three">
                  <label className="label-name">
                    Gender
                  </label>
                  <Select
                    options={genderList}
                    value={genderList?.find(
                      (option) => option?.value === filterList.gender
                    )}
                    onChange={(e) => handleFilter(e, "gender", "select")}
                    name="gender"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-three">
                  <label className="label-name">
                    Department
                  </label>
                  <Select
                    options={department}
                    value={department?.find(
                      (option) => option?.value === filterList.department_id
                    )}
                    onChange={(e) => handleFilter(e, "department_id", "select")}
                    name="department_id"
                    className=""
                    isSearchable
                  />
                </div>

                <div className="field-block">
                  <label className="label-name">
                    Joining Date
                  </label>
                  <input
                    className="input-field "
                    type="date"
                    autoComplete="off"
                    name="from_date"
                    value={filterList?.from_date}
                    onChange={(e) => {
                      handleFilter(e, "from_date", "date");
                    }}
                  />
                </div>
                <div className="field-block flex justify-center">
                  <div className="add-new-button-block w-[40%] h-max ">
                    <button
                      className="add-new-button"
                      onClick={(e) => {
                        showFilterResult(e);
                      }}
                    >
                      <span style={{ cursor: "pointer" }}>Show Result</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}
          <LoanReportTable
            list={filteredList}
            columns={columns}
            icon={More}
            showDetails={showDetails}
            activeEdit={false}
            activeDelete={false}
            multiple={true}
          />
        </div>
      </div>
    </div>
  );
}

export default LoanReportList;
