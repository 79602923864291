import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer";

function HomeLayout({ children }) {
  return (
    <div className="flex max-h-screen">
      {/* <StaticSideBar /> */}
      <div className="grow h-screen flex flex-col justify-between">
        <Header />
        {children}
        <Footer />
      </div>
    </div>
  );
}

export default HomeLayout;
