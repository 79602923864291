import React from "react";
import { Link, useLocation } from "react-router-dom";
import View from "../../Assets/Icons/View.svg";
import Edit from "../../Assets/Icons/Edit.svg";

function HolidaySchemeDetails() {
  const { state } = useLocation();
  const listDetails = state?.list;
  const Details = [
    {
      label: "Holidays Scheme Name",
      value: listDetails?.name,
    },

    {
      label: "Description",
      value: listDetails?.description,
    },
    {
      label: "Special Notes",
      value: listDetails?.special_notes,
    },
    {
      label: "No Of Days",
      value: listDetails?.no_of_days,
    },
    {
      label: "Grace Days",
      value: listDetails?.grace_leave_days,
    },
    {
      label: "Calculationg working Days",
      value: listDetails?.working_days_calculation,
    },
  ];

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className=" sm-container">
        <h1 className="heading-text">Holiday Scheme</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Holiday Scheme Details</h4>
          <div className="add-block">
            <Link to="/masters/Holidayscheme" className="view-list-button">
              <span>View List</span>
              <img src={View} alt="add" />
            </Link>
            <Link
              to="/masters/holidayscheme/edit"
              className="edit-button"
              state={{ list: listDetails }}
            >
              <span>Edit</span>
              <img src={Edit} alt="add" />
            </Link>
          </div>
          <div className="list-container">
            <div className="list-content-block">
              {Details.map((list, i) => {
                return (
                  <div className="list" key={i}>
                    <label className="list-label">{list.label}</label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="list-value"
                    >
                      {list.value}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default HolidaySchemeDetails;
