import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import alertify from "alertifyjs";
import { useReactToPrint } from "react-to-print";
import Spinner from "../../Spinner";
import Validation from "../../../Validation/Validation";
import { GET_API, POST_API } from "../../../Services/api";
import AdvancePrintVoucher from "./AdvancePrintVoucher";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import View from "../../../Assets/Icons/View.svg";
import "alertifyjs/build/css/alertify.css";

function CreateAdvance() {
  const navigate = useNavigate();
  const componentRef = useRef();
  const userId = localStorage?.getItem("user_id");
  const [isPrintVoucher, setIsPrintVoucher] = useState(false);
  const [advanceDetails, setAdvanceDetails] = useState({
    employee_id: [],
    type: "advance",
    employee_name: "",
    designation: "",
    department: "",
    selecttype: "",
    finance_category_id: "",
    amount: "",
    duration: "",
    approved_by: "",
  });
  const [errors, seterrors] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [designationList, setDesignationList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [branchList, setbranchList] = useState([]);
  const [designationName, setdesignationName] = useState("");
  const [departmentName, setdepartmentName] = useState("");
  const [branchName, setbranchName] = useState("");
  const [enableExisting, setEnableExisting] = useState(true);

  const [loanList, setLoanList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [printVoucherData, setPrintVoucherData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isErrors, setIsErrors] = useState({ employee_id: "" });

  useEffect(() => {
    getDesignationList();
    getDepartmentList();
    getBranchList();
    getLoanList();
    getEmployeeList();
  }, []);

  const handleDesignation = (key, value) => {
    value !== "" ? setEnableExisting(false) : setEnableExisting(true);
    GET_API(`/employeeGet/${value}`)
      .then(async function (data) {
        console.log(data?.data);
        setdesignationName(data?.data[0].designation_name);
        setdepartmentName(data?.data[0].department_name);
        setbranchName(data?.data[0].branch_name);
        setAdvanceDetails({
          ...advanceDetails,
          designation: data?.data[0].designation_name,
          department: data?.data[0].department_name,
          branch: data?.data[0].branch_name,
          [key]: value,
        });
      })
      .catch((error) => {
        console.log(error);
      });
    // setAdvanceDetails(() => ({ ...advanceDetails, [key]: value }));
  };

  const getDesignationList = () => {
    setLoading(true);
    GET_API("/designationList")
      .then(async function (data) {
        setLoading(false);
        setDesignationList(data?.data[0]);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const getDepartmentList = () => {
    setLoading(true);
    GET_API("/DepartmentList")
      .then(async function (data) {
        setDepartmentList(data?.data[0]);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const getBranchList = async () => {
    setLoading(true);
    GET_API("/branchList")
      .then(async function (data) {
        setbranchList(data?.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const getLoanList = async () => {
    try {
      setLoading(true);
      const response = await GET_API("/FinanceCategoriesList/loan");
      if (response?.status === 200) {
        const list = response?.data?.data?.map(item => { return { label: item?.name, value: item?.id } });
        list.unshift({ label: "Select...", value: "" });
        setLoanList(list);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getEmployeeList = async () => {
    setLoading(true);
    GET_API(`/ListEmployeeTentacles?login=${userId}`)
      .then(async function (data) {
        const res = data?.data?.data.map((item, i) => {
          return { value: item?.employee_id, label: `${item?.name}` };
        });
        res.unshift({ label: "Select...", value: "" });
        setEmployeeList(res);
        setLoading(false);
      })
      .catch((error) => {
        setEmployeeList([]);
        setLoading(false);
        console.log(error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setAdvanceDetails(() => ({ ...advanceDetails, [name]: value }));
  };

  const handleSelectChange = (e, name) => {
    const { value } = e;
    setAdvanceDetails({ ...advanceDetails, [name]: value })
  }

  const printVoucher = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (isPrintVoucher) {
      printVoucher();
      navigate("/expense/advance");
    }
  }, [isPrintVoucher, printVoucher]);

  const validateForm = () => {
    let valid = true;
    const newErrors = {};
    if (advanceDetails.employee_id.length === 0) {
      newErrors.employee_id = "Employee Name is required";
      valid = false;
      setButtonDisabled(false);
    }
    setIsErrors(newErrors);
    return valid;
  };

  const createAdvance = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.validateAdvance(advanceDetails);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    if (validateForm()) {
      POST_API("/addFinanceDetails", advanceDetails)
        .then(async function (data) {
          setButtonDisabled(false);
          alertify.success(data?.data?.message);
          setPrintVoucherData(data?.data?.data);
          if (selectedOptions?.length <= 1) {
            setTimeout(() => {
              printVoucher();
              setIsPrintVoucher(true);
            }, 500);
          } else {
            navigate("/advance");
          }
        })

        .catch((error) => {
          setButtonDisabled(false);
        });
    }
  };

  const resetForm = () => { };
  if (loading) {
    return <Spinner />;
  }
  
  const checkExisting = async () => {
    try {
      if (advanceDetails.employee_id !== "") {
        const existingAdvanceList = await GET_API(
          `checkExistingFinance/${advanceDetails?.employee_id}/advance`
        );
        if (existingAdvanceList?.status === 200) {
          if (existingAdvanceList?.data?.data.length === 0) {
            alertify
              .alert("Existing advance Details Not Found...!")
              .set("movable", false)
              .setHeader("<b> Existing Advance Details</b> ");
          } else {
            var itemElement = document.createElement("div");

            var itemElement = document.createElement("div");
            itemElement.innerHTML = `
              <table className="log-table mt-[4px]">
                <tr className="bg-[#FFEDC2]">
                  <td className="log-table log-table-data content-label font-extrabold">
                      Amount
                  </td>
                  <td className="log-table log-table-data content-label font-extrabold">
                      Finance Category Name
                  </td>
                  <td className="log-table log-table-data content-label font-extrabold">
                      Payment Type
                  </td>
                  <td className="log-table log-table-data content-label font-extrabold">
                      Paid Amount
                  </td>
                </tr>
               ${existingAdvanceList?.data?.data
                .map((item) => {
                  return `<tr>
                     <td className="log-table log-table-data content-label whitespace-nowrap">
                     &#8377 ${item?.paid_amount}
                     </td>
                     <td className="log-table log-table-data content-label">
                       ${item?.finance_category_name}
                     </td>
                     <td className="log-table log-table-data content-label">
                       ${item?.payment_type}
                     </td>
                     <td className="log-table log-table-data content-label">
                     &#8377 ${item?.paid_amount}
                     </td>
                   </tr>`;
                })
                .join("")}
                </table>`;
            alertify
              .alert(itemElement)
              .set("movable", false)
              .setHeader("<b> Existing Advance Details</b> ");
          }
        }
      }
    } catch (error) {
      alertify.error("Failed To get Existing Advance Details");
    }
  };

  const handleEmployeeName = async (e) => {
    setSelectedOptions(e);
    setIsErrors({ ...isErrors, ["employee_id"]: "" });
    let empId = [...e]?.map((emp, i) => {
      return emp.value;
    });
    setAdvanceDetails(() => ({ ...advanceDetails, employee_id: empId }));
    let value = e[0]?.value;
    if (selectedOptions?.length < 1) {
      value !== "" ? setEnableExisting(false) : setEnableExisting(true);
      try {
        const designation = await GET_API(`/employeeGet/${value}`);
        if (designation.status === 200) {
          setdesignationName(designation?.data?.data?.designation_name);
          setdepartmentName(designation?.data?.data?.department_name);
          setbranchName(designation?.data?.data?.branch_name);
        }
      } catch (e) {
        alertify.error("Failed to get List");
      }
    }
  };

  const paymentType = [
    { label: "Select...", value: "" },
    { label: "Bank", value: "bank" },
    { label: "Cash", value: "cash" },
  ]

  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">Advance</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Add Advance</h4>
          <div className="add-block">
            <button
              disabled={enableExisting}
              onClick={() => {
                checkExisting();
              }}
              className="view-list-button"
            >
              <span>Check Existing</span>
            </button>
            <Link to="/expense/advance" className="view-list-button">
              <span>View List</span>
              <img src={View} alt="add" />
            </Link>
          </div>

          {isPrintVoucher && (
            <AdvancePrintVoucher
              ref={componentRef}
              printVoucherData={printVoucherData}
            />
          )}

          {/***************Form************* **/}
          <form onSubmit={(e) => createAdvance(e)}>
            <div className="p-6">
              <label className="label-name">
                Employee Name
                <span className="required-symbol">*</span>
              </label>
              <Select
                defaultValue={selectedOptions}
                onChange={handleEmployeeName}
                options={employeeList}
                isMulti
              />
              {isErrors["employee_id"] ? (
                <div className="text-[#FF5C42] text-sm font-medium font-karla">
                  {isErrors["employee_id"]}
                </div>
              ) : null}
            </div>
            <div className="form">
              {/* {selectedOptions?.length <= 1 ? (
                <>
                  <div className="flex flex-col gap-1 min-h-[95px]">
                    <label className="label-name">
                      Designation
                      <span className="required-symbol">*</span>
                    </label>

                    <input
                      className="input-field"
                      name="designation"
                      placeholder="select department"
                      value={designationName}
                      readOnly
                      disabled
                    />

                    {errors.designation ? (
                      <div className="validation-error-label">
                        {errors.designation}
                      </div>
                    ) : null}
                  </div>

                  <div className="flex flex-col gap-1 min-h-[95px]">
                    <label className="label-name">
                      Department Name
                      <span className="required-symbol">*</span>
                    </label>

                    <input
                      className="input-field"
                      name="department"
                      placeholder="select department"
                      value={departmentName}
                      readOnly
                      disabled
                    />

                    {errors.department ? (
                      <div className="validation-error-label">
                        {errors.department}
                      </div>
                    ) : null}
                  </div>

                  <div className="flex flex-col gap-1 min-h-[95px]">
                    <label className="label-name">
                      Branch Name
                      <span className="required-symbol">*</span>
                    </label>

                    <input
                      className="input-field"
                      name="branch"
                      placeholder="select department"
                      value={branchName}
                      readOnly
                      disabled
                    />

                    {errors.branch ? (
                      <div className="validation-error-label">
                        {errors.branch}
                      </div>
                    ) : null}
                  </div>
                </>
              ) : (
                ""
              )} */}
              <div className="field-block">
                <label className="label-name">Loan Type</label>
                <Select
                  id="finance_category_id"
                  options={loanList}
                  value={loanList?.find(item => item?.value === advanceDetails.finance_category_id)}
                  onChange={(e) => handleSelectChange(e, "finance_category_id")}
                  name="finance_category_id"
                  isSearchable
                />
                {errors.loan_type ? (
                  <div className="validation-error-label">
                    {errors.loan_type}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Amount
                  <span className="required-symbol">*</span>
                </label>
                <input
                  name="amount"
                  className="input-field"
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter Amount"
                />
                {errors.amount ? (
                  <div className="validation-error-label">{errors.amount}</div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">Approval</label>
                <Select
                  id="approved_by"
                  options={employeeList}
                  value={employeeList?.find(item => item?.value === advanceDetails.approved_by)}
                  onChange={(e) => handleSelectChange(e, "approved_by")}
                  name="approved_by"
                  isSearchable
                />
                {errors.approved_by ? (
                  <div className="validation-error-label">
                    {errors.approved_by}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">Select Type</label>
                <Select
                  id="paymentType"
                  options={paymentType}
                  value={paymentType?.find(item => item?.value === advanceDetails.paymentType)}
                  onChange={(e) => handleSelectChange(e, "paymentType")}
                  name="paymentType"
                  isSearchable
                />
                {errors.selecttype ? (
                  <div className="validation-error-label">
                    {errors.selecttype}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="submit-reset-button">
              <button
                type="button"
                className="reset-button"
                onClick={resetForm}
              >
                <span className="reset-button-label">Reset</span>
              </button>
              <button
                disabled={buttonDisabled}
                type="submit"
                className="submit-button"
              >
                <span className="submit-button-label">Add</span>
                <img src={ColorAdd} alt="" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export default CreateAdvance;
