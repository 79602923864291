import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import Select from "react-select";
import { DELETE_API, GET_API, POST_API } from "../../Services/api";
import LeaveTypeListTable from "../DataTable/LeaveTypeListTable";
import Spinner from "../Spinner";
import Validation from "../../Validation/Validation";
import Search from "../../Assets/Icons/Search.svg";
import ColorAdd from "../../Assets/Icons/ColorAdd.svg";
import Edit from "../../Assets/Icons/Edit.svg";
import More from "../../Assets/Icons/More.svg";
import "alertifyjs/build/css/alertify.css";

function Leave() {
  const navigate = useNavigate();
  const [leaveTypeList, setLeaveTypeList] = useState([]);
  const [FilteredLeaveType, setFilteredLeaveType] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newLeaveList, setNewLeaveList] = useState({
    name: "",
    religion_id: "",
  });
  const [errors, setErrors] = useState("");
  const [editStatus, setEditStatus] = useState(false);
  const [religionList, setReligionList] = useState([]);

  const showBranchDetails = (listDetail) => {
    navigate("/branch-details", {
      state: {
        list: listDetail,
      },
    });
  };
  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "leave_name", label: "Leave Name" },
    { id: "religion_name", label: "Religion Name" },
    { id: "created_at", label: "Created at" },
    { id: "more_icon", label: "" },
  ];

  useEffect(() => {
    getLeaveList();
    getReligionList();
  }, []);

  const getLeaveList = async () => {
    setLoading(true);
    GET_API("/leaveTypeList")
      .then(async function (data) {
        setLoading(false);
        setLeaveTypeList(data?.data?.data);
        setFilteredLeaveType(data?.data?.data);
      })

      .catch((error) => setLoading(false));
  };

  const getReligionList = async () => {
    setLoading(true);
    try {
      const list = await GET_API("/ReligionList");
      if (list.status === 200) {
        const religion = list?.data?.data?.map(item => { return { label: item?.name, value: item?.id } });
        religion.unshift({ label: "Select", value: "" })
        setReligionList(religion);
        setLoading(false);
        return;
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleFloorSearch = (e) => {
    const searchTerm = e.target.value;
    const searchedList = leaveTypeList.filter((item) => {
      for (let key in item) {
        if (
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
    setFilteredLeaveType(searchedList);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewLeaveList({ ...newLeaveList, [name]: value });
  };

  const handleSelectChange = (e, name) => {
    const { value } = e;
    setNewLeaveList({ ...newLeaveList, [name]: value })
  }

  const addLeave = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);

    const isValid = Validation.validateLeaveList(newLeaveList);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      setErrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      setErrors({});
    }
    try {
      const createLeave = await POST_API("/AddLeaveType", newLeaveList);
      if (createLeave.status === 200) {
        setButtonDisabled(false);
        setNewLeaveList({ name: "", religion_id: "" });
        getLeaveList();
        alertify.success(createLeave?.data?.message);
        return;
      }
    } catch (e) {
      setButtonDisabled(false);
      alertify.error(e?.response?.data?.message);
    }
  };

  const editLeave = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.validateLeaveList(newLeaveList);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      setErrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      setErrors({});
    }
    const createLeave = await POST_API("/updateLeaveTypeList", newLeaveList);
    if (createLeave.status === 200) {
      setButtonDisabled(false);
      setNewLeaveList({ name: "", religion_id: "" });
      getLeaveList();
      setEditStatus(false);
      alertify.success(createLeave?.data?.message);
      return;
    }
    alertify.error(createLeave?.response?.data?.message);
    setButtonDisabled(false);
  };

  const resetForm = () => {
    setEditStatus(false);
    setNewLeaveList({
      name: "",
      religion_id: "",
    });
    setErrors({});
  };
  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Leave...?",
        async function () {
          if (row_id) {
            const deleteRow = await DELETE_API(`/deleteLeaveType/${row_id}`);
            if (deleteRow.status === 200) {
              alertify.success(deleteRow?.data?.message);
              getLeaveList();
              return;
            }
            alertify.error("Failed To Delete Leave List");
          }
        },
        function () { }
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };
  if (loading) {
    return <Spinner />;
  }
  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">Leave</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Add Leave</h4>
          <div className="search-block">
            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                onChange={(e) => handleFloorSearch(e)}
                style={{ outline: "none", border: "none" }}
              />
              <img src={Search} alt="" />
            </div>
          </div>
          <div className="add-list-container">
            <div className="input-block">
              <label className="add-new-input-label">
                Leave Name <span className="required-symbol">*</span>
              </label>
              <input
                type="text"
                name="name"
                autoComplete="off"
                className="p-2 border-solid border-[1px] border-primary rounded font-karla"
                placeholder="Enter Leave Name"
                onChange={(e) => {
                  handleChange(e);
                }}
                value={newLeaveList?.name}
              />
              {errors.name ? (
                <div className="text-[#FF5C42] text-sm font-medium font-karla">
                  {errors.name}
                </div>
              ) : null}
            </div>
            <div className="input-block dropdown-select-to-two">
              <label className="add-new-input-label">
                Religion <span className="required-symbol">*</span>
              </label>
              <Select
                id="religion_id"
                options={religionList}
                onChange={(e) => handleSelectChange(e, "religion_id")}
                name="religion_id"
                value={religionList?.find(item => item?.value === newLeaveList?.religion_id)}
                isSearchable
              />
              {errors.religion_id ? (
                <div className="text-[#FF5C42] text-sm font-medium font-karla">
                  {errors.religion_id}
                </div>
              ) : null}
            </div>
            <div className="flex gap-3">
              <div className="add-new-button-block">
                {editStatus ? (
                  <button
                    className="add-new-button"
                    disabled={buttonDisabled}
                    onClick={(e) => {
                      editLeave(e);
                    }}
                  >
                    <span className="button-label">Save</span>
                    <img src={ColorAdd} alt="add" />
                  </button>
                ) : (
                  <button className="add-new-button" disabled={buttonDisabled}>
                    <span
                      className="button-label"
                      onClick={(e) => {
                        addLeave(e);
                      }}
                    >
                      Add New
                    </span>
                    <img src={ColorAdd} alt="add" />
                  </button>
                )}
              </div>
              <button className="edit-button" onClick={() => resetForm()}>
                <span className="button-label">Reset</span>
                <img src={Edit} alt="add" />
              </button>
            </div>
          </div>
          <LeaveTypeListTable
            list={FilteredLeaveType}
            columns={columns}
            showBranchDetails={showBranchDetails}
            icon={More}
            multiple={false}
            editStatus={setEditStatus}
            editList={setNewLeaveList}
            deleteRow={handleDelete}
          />
        </div>
      </div>
    </div>
  );
}

export default Leave;
