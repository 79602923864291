import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import LoanTable from "../../DataTable/LoanTable";
import { GET_API } from "../../../Services/api";
import Spinner from "../../Spinner";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import Search from "../../../Assets/Icons/Search.svg";
import More from "../../../Assets/Icons/More.svg";
import "alertifyjs/build/css/alertify.css";

function LoanList() {
  const navigate = useNavigate();
  const userId = localStorage?.getItem("user_id");
  const [loading, setLoading] = useState(false);
  const [loanList, setLoanList] = useState([]);
  const [filteredLoan, setFilteredLoan] = useState([]);

  const handleLoanSearch = (e) => {
    const searchTerm = e.target.value;
    const searchedList = loanList.filter((item) => {
      for (let key in item) {
        if (
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
    setFilteredLoan(searchedList);
  };

  const showLoanDetails = (listDetail) => {
    navigate("/expense/loan/details", {
      state: {
        list: listDetail,
      },
    });
  };

  const columns = [
    { id: "s_no", label: "S.No" },
    { id: "employee_name", label: "Employee Name" },
    { id: "employee_code", label: "Employee Code" },
    { id: "amount", label: "Amount" },
    { id: "payment_type", label: "Payment Type" },
    { id: "created_by", label: "Date" },
    { id: "more_icon", label: "" },
  ];

  useEffect(() => {
    getLoanList();
  }, []);

  const getLoanList = async () => {
    setLoading(true);
    try {
      const loanList = await GET_API(`/FinanceDetailsList/loan?login=${userId}`);
      console.log(loanList);
      if (loanList.status === 200) {
        setLoanList(loanList?.data?.data);
        setFilteredLoan(loanList?.data?.data);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed To get Loan List");
    }
  };

  if (loading) {
    return <Spinner />;
  }
  
  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">Loan</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Loan List</h4>
          <div className="search-block">

            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                onChange={(e) => handleLoanSearch(e)}
                style={{ outline: "none", border: "none" }}
              />
              <img src={Search} alt="" />
            </div>
            <div className="flex gap-3">
              <div className="add-new-button-block">
                <Link to="/expense/loan/add" className="add-new-button">
                  <span>Add New</span>
                  <img src={ColorAdd} alt="add" />
                </Link>
              </div>
            </div>
          </div>
          <LoanTable
            list={filteredLoan}
            columns={columns}
            icon={More}
            activeDelete={false}
            activeEdit={false}
            multiple={true}
            showDetails={showLoanDetails}
          />
        </div>
      </div>
    </div>
  );
}

export default LoanList;
