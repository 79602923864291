import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { useReactToPrint } from "react-to-print";
import Select from "react-select";
import alertify from "alertifyjs";
import Validation from "../../../Validation/Validation";
import { GET_API, POST_API } from "../../../Services/api";
import View from "../../../Assets/Icons/View.svg";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import Spinner from "../../Spinner";
// import LoanPrintVoucher from "./LoanPrintVoucher";
import "alertifyjs/build/css/alertify.css";

function CreateLoan() {
  const navigate = useNavigate();
  // const componentRef = useRef();
  const userId = localStorage?.getItem("user_id");
  const [loanDetails, setLoanDetails] = useState({
    employee_id: [],
    type: "loan",
    employee_name: "",
    designation: "",
    selecttype: "",
    department: "",
    finance_category_id: "",
    amount: "",
    duration: "",
    approved_by: "",
  });
  const [errors, seterrors] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [designationList, setDesignationList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [branchList, setbranchList] = useState([]);
  const [designationName, setdesignationName] = useState("");
  const [departmentName, setdepartmentName] = useState("");
  const [branchName, setbranchName] = useState("");
  const [enableExisting, setEnableExisting] = useState(true);

  const [loanList, setLoanList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isPrintVoucher, setIsPrintVoucher] = useState(false);
  const [printVoucherData, setPrintVoucherData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isErrors, setIsErrors] = useState({ employee_id: "" });

  useEffect(() => {
    getDesignationList();
    getDepartmentList();
    getBranchList();
    getLoanList();
    getEmployeeList();
  }, []);

  const getDesignationList = async () => {
    setLoading(true);
    try {
      const designationList = await GET_API("/designationList");
      if (designationList.status === 200) {
        setDesignationList(designationList?.data[0]);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alertify.error("Failed to get designation List");
    }
  };

  const getDepartmentList = async () => {
    setLoading(true);
    try {
      const departmentList = await GET_API("/DepartmentList");
      if (departmentList.status === 200) {
        setDepartmentList(departmentList?.data[0]);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alertify.error("Failed to get Department List");
    }
  };

  const getBranchList = async () => {
    setLoading(true);
    try {
      const branchList = await GET_API("/branchList");
      if (branchList.status === 200) {
        setbranchList(branchList?.data.data);
        setLoading(false);
      }
    } catch (error) {
      alertify.error("Failed to get Branch List...");
      setLoading(false);
    }
  };

  const getLoanList = async () => {
    setLoading(true);
    try {
      const loanList = await GET_API("/FinanceCategoriesList/loan");
      if (loanList.status === 200) {
        const loan = loanList?.data?.data?.map(item => { return { label: item?.name, value: item?.id } });
        setLoanList(loan);
      }
    } catch (error) {
      alertify.error("Failed to get Loan llist...");
    }
  };

  const getEmployeeList = async () => {
    setLoading(true);
    GET_API(`/ListEmployeeTentacles?login=${userId}`)
      .then(async function (data) {
        const res = data?.data?.data.map((emp, i) => {
          return {
            label: `${emp?.name}`,
            value: emp?.employee_id,
          };
        });
        setEmployeeList(res);
        setLoading(false);
      })

      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setLoanDetails(() => ({ ...loanDetails, [name]: value }));
  };

  const handleSelectChange = (e, name) => {
    const { value } = e;
    setLoanDetails({ ...loanDetails, [name]: value })
  }

  // const printVoucher = useReactToPrint({
  //   content: () => componentRef.current,
  // });

  // useEffect(() => {
  //   if (isPrintVoucher) {
  //     printVoucher();
  //     navigate("/expense/loan");
  //   }
  // }, [isPrintVoucher, printVoucher]);

  const validateForm = () => {
    let valid = true;
    const newErrors = {};
    if (loanDetails.employee_id.length === 0) {
      newErrors.employee_id = "Employee Name is required";
      valid = false;
      setButtonDisabled(false);
    }
    setIsErrors(newErrors);
    return valid;
  };

  const createLoan = async (e) => {
    e.preventDefault();
    const isValid = Validation.validateFinanceLoan(loanDetails);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    if (validateForm()) {
      POST_API("/addFinanceDetails", loanDetails)
        .then(async function (data) {
          setButtonDisabled(false);
          alertify.success(data?.data?.message);
          navigate("/expense/loan");
          // setPrintVoucherData(data?.data?.data);
          // if (selectedOptions?.length <= 1) {
          //   setTimeout(() => {
          //     printVoucher();
          //     setIsPrintVoucher(true);
          //   }, 500);
          // } else {
          //   navigate("/expense/loan");
          // }
        })

        .catch((error) => {
          setButtonDisabled(false);
          alertify.error(error?.response?.data?.message);
        });
    }
  };

  const checkExisting = async () => {
    try {
      if (loanDetails.employee_id !== "") {
        const existingLoanList = await GET_API(
          `checkExistingFinance/${loanDetails?.employee_id}/loan`
        );
        if (existingLoanList?.status === 200) {
          if (existingLoanList?.data?.data.length === 0) {
            alertify
              .alert("Existing Loan Details Not Found...!")
              .set("movable", false)
              .setHeader("<b> Existing Loan Details</b> ");
          } else {
            var itemElement = document.createElement("div");
            itemElement.innerHTML = `
              <table className="log-table mt-[4px]">
                <tr className="bg-[#FFEDC2]">
                  <td className="log-table log-table-data content-label font-extrabold">
                      Amount
                  </td>
                  <td className="log-table log-table-data content-label font-extrabold">
                      Finance Category Name
                  </td>
                  <td className="log-table log-table-data content-label font-extrabold">
                      Payment Type
                  </td>
                  <td className="log-table log-table-data content-label font-extrabold">
                      Paid Amount
                  </td>
                </tr>
               ${existingLoanList?.data?.data
                .map((item) => {
                  return `<tr>
                     <td className="log-table log-table-data content-label whitespace-nowrap">
                     &#8377 ${item?.amount}
                     </td>
                     <td className="log-table log-table-data content-label">
                       ${item?.finance_category_name}
                     </td>
                     <td className="log-table log-table-data content-label">
                       ${item?.payment_type}
                     </td>
                     <td className="log-table log-table-data content-label">
                     &#8377 ${item?.paid_amount}
                     </td>
                   </tr>`;
                })
                .join("")}
                </table>`;
            alertify
              .alert(itemElement)
              .set("movable", false)
              .setHeader("<b> Existing Loan Details.</b> ");
          }
        }
      }
    } catch (e) {
      alertify.error("Failed To get Existing Loan Details");
    }
  };

  const resetForm = () => {
    seterrors({});
  };
  if (loading) {
    return <Spinner />;
  }

  const handleEmployeeName = async (e) => {
    setSelectedOptions(e);
    setIsErrors({ ...isErrors, ["employee_id"]: "" });
    let empId = [...e]?.map((emp, i) => {
      return emp.value;
    });
    setLoanDetails(() => ({ ...loanDetails, employee_id: empId }));
    let value = e[0]?.value;
    if (selectedOptions?.length < 1) {
      value !== "" ? setEnableExisting(false) : setEnableExisting(true);
      try {
        const empDetails = await GET_API(`/getEmployeeTentaclesById/${value}`);
        if (empDetails.status === 200) {
          setdesignationName(empDetails?.data?.data[0]?.designationName);
          setdepartmentName(empDetails?.data?.data[0]?.departmentname);
          setbranchName(empDetails?.data?.data[0]?.branch_id);
        }
      } catch (e) {
        alertify.error("Failed to get List");
      }
    }
  };

  const paymentOptions = [
    { label: "Select...", value: "" },
    { label: "Bank", value: "bank" },
    { label: "Cash", value: "cash" }
  ]

  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">Loan</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Add Loan</h4>
          <div className="add-block">
            <button
              disabled={enableExisting}
              onClick={() => {
                checkExisting();
              }}
              className="view-list-button"
            >
              <span>Check Existing</span>
            </button>
            <Link to="/expense/loan" className="view-list-button">
              <span>View List</span>
              <img src={View} alt="add" />
            </Link>
          </div>

          {/***************Form************* **/}
          {/* {isPrintVoucher && (
            <LoanPrintVoucher
              ref={componentRef}
              printVoucherData={printVoucherData}
            />
          )} */}
          <form onSubmit={(e) => createLoan(e)}>
            <div className="p-6">
              <label className="label-name">
                Employee Name
                <span className="required-symbol">*</span>
              </label>
              <Select
                defaultValue={selectedOptions}
                onChange={handleEmployeeName}
                options={employeeList}
                isMulti
              />
              {isErrors["employee_id"] ? (
                <div className="text-[#FF5C42] text-sm font-medium font-karla">
                  {isErrors["employee_id"]}
                </div>
              ) : null}
            </div>
            <div className="form">
              {/* {selectedOptions?.length <= 1 ? (
                <>
                  <div className="flex flex-col gap-1 min-h-[95px]">
                    <label className="label-name">
                      Designation
                      <span className="required-symbol">*</span>
                    </label>

                    <input
                      className="input-field"
                      name="designation"
                      placeholder="select department"
                      value={designationName}
                      readOnly
                      disabled
                    />

                    {errors.designation ? (
                      <div className="validation-error-label">
                        {errors.designation}
                      </div>
                    ) : null}
                  </div>

                  <div className="flex flex-col gap-1 min-h-[95px]">
                    <label className="label-name">
                      Department Name
                      <span className="required-symbol">*</span>
                    </label>

                    <input
                      className="input-field"
                      name="department"
                      placeholder="select department"
                      value={departmentName}
                      readOnly
                      disabled
                    />

                    {errors.department ? (
                      <div className="validation-error-label">
                        {errors.department}
                      </div>
                    ) : null}
                  </div>

                  <div className="flex flex-col gap-1 min-h-[95px]">
                    <label className="label-name">
                      Branch Name
                      <span className="required-symbol">*</span>
                    </label>

                    <input
                      className="input-field"
                      name="branch"
                      placeholder="select department"
                      value={branchName}
                      readOnly
                      disabled
                    />

                    {errors.branch ? (
                      <div className="validation-error-label">
                        {errors.branch}
                      </div>
                    ) : null}
                  </div>
                </>
              ) : (
                ""
              )} */}
              <div className="field-block">
                <label className="label-name">Loan Type</label>
                <Select
                  id="loan_type"
                  options={loanList}
                  value={loanList?.find(item => item?.value === loanDetails.loan_type)}
                  onChange={(e) => handleSelectChange(e, "loan_type")}
                  name="loan_type"
                  isSearchable
                />
                {errors.loan_type ? (
                  <div className="validation-error-label">
                    {errors.loan_type}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Amount
                  <span className="required-symbol">*</span>
                </label>
                <input
                  name="amount"
                  className="input-field"
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter Amount"
                />
                {errors.amount ? (
                  <div className="validation-error-label">{errors.amount}</div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  Duration
                  <span className="required-symbol">*</span>
                </label>
                <input
                  type="number"
                  name="duration"
                  className="input-field"
                  placeholder="Enter Duration"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                {errors.duration ? (
                  <div className="validation-error-label">
                    {errors.duration}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">Approval</label>
                <Select
                  id="approved_by"
                  options={employeeList}
                  value={employeeList?.find(item => item?.value === loanDetails.approved_by)}
                  onChange={(e) => handleSelectChange(e, "approved_by")}
                  name="approved_by"
                  isSearchable
                />
                {errors.approval ? (
                  <div className="validation-error-label">
                    {errors.approval}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">Payment Type</label>
                <Select
                  id="selecttype"
                  options={paymentOptions}
                  value={paymentOptions?.find(item => item?.value === loanDetails.selecttype)}
                  onChange={(e) => handleSelectChange(e, "selecttype")}
                  name="selecttype"
                  isSearchable
                />
                {errors.selecttype ? (
                  <div className="validation-error-label">
                    {errors.selecttype}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="submit-reset-button">
              <button
                type="button"
                className="reset-button"
                onClick={resetForm}
              >
                <span className="reset-button-label">Reset</span>
              </button>
              <button
                disabled={buttonDisabled}
                type="submit"
                className="submit-button"
              >
                <span className="submit-button-label">Add</span>
                <img src={ColorAdd} alt="" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateLoan;
