import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Edit from "../../../Assets/Icons/Edit.svg";
import View from "../../../Assets/Icons/View.svg";
import { GET_API } from "../../../Services/api";
import Spinner from "../../Spinner";

function DeductionDetails() {
    const { deductionId } = useParams();
    const [deductionDetails, setDeductionDetails] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    
    const getDeduction = async () => {
        try {
            setIsLoading(true);
            const response = await GET_API(`clientDeductionById/${deductionId}`);
            if (response?.status === 200) {
                setIsLoading(false);
                setDeductionDetails(response?.data?.data)
            }
        } catch (error) {
            setDeductionDetails({})
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (deductionId) {
            getDeduction();
        }
    }, [deductionId])


    if (isLoading) {
        return <Spinner />;
    }

    return (
        <div className=" bg-[#F7F6F4] grow">
            <div className="flex flex-col justify-between h-full">
                <div className=" sm-container">
                    <h1 className="heading-text">Client</h1>
                    <div className="card-wrapper">
                        <h4 className="card-header">Client Details</h4>
                        <div className="search-block">
                            <Link to="/masters/client/deductions" className="view-list-button">
                                <span>View List</span>
                                <img src={View} alt="add" />
                            </Link>
                            <Link
                                to={`/masters/client/deductions/update/${deductionId}`}
                                className="edit-button"
                            >
                                <span>Edit</span>
                                <img src={Edit} alt="add" />
                            </Link>
                        </div>
                        <h4 className="detaile-header"></h4>
                        <div className="list-container ">
                            <div className="list-content-block">
                                <div className="list">
                                    <label className="list-label">Name</label>
                                    <div
                                        style={{ wordBreak: "break-word" }}
                                        className="list-value"
                                    >
                                        {deductionDetails.name}
                                    </div>
                                </div>
                                <div className="list">
                                    <label className="list-label">Automatic</label>
                                    <div
                                        style={{ wordBreak: "break-word" }}
                                        className="list-value"
                                    >
                                        {deductionDetails?.is_automatic === 1 ? "Yes" : "No"}
                                    </div>
                                </div>
                                {deductionDetails?.is_automatic === 1 ? <>
                                    <div className="list">
                                        <label className="list-label">Percentage of</label>
                                        <div
                                            style={{ wordBreak: "break-word" }}
                                            className="list-value"
                                        >
                                            {deductionDetails.percentage_value}
                                        </div>
                                    </div>
                                    <div className="list">
                                        <label className="list-label">Formula</label>
                                        <div
                                            style={{ wordBreak: "break-word" }}
                                            className="list-value"
                                        >
                                            {deductionDetails.formula_value === "total_salary" ? "Total Salary" : "Manual"}
                                        </div>
                                    </div>
                                </> : null}
                                {deductionDetails?.formula_value === "manual" ?
                                    <div className="list">
                                        <label className="list-label">Sum of / Salary Days</label>
                                        <div
                                            style={{ wordBreak: "break-word" }}
                                            className="list-value"
                                        >
                                            {deductionDetails?.manual_calc_amount}
                                        </div>
                                    </div> : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default DeductionDetails;
