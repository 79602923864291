import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";

export default function DataTable({ list, setSelectedRows, columns }) {

  const handleSelectionModelChange = (selectionModel) => {
    setSelectedRows(selectionModel);
  };

  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={list}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
        onRowSelectionModelChange={handleSelectionModelChange}
      />
    </div>
  );
}
