import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import alertify from "alertifyjs";
import Grid from '@mui/material/Grid';
import { GET_API, POST_API } from "../../../Services/api";
import EmployeeRemarksTable from "../Components/EmployeeRemarksTable";
import Search from "../../../Assets/Icons/Search.svg";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import Validation from "../../../Validation/Validation";
import Edit from "../../../Assets/Icons/Edit.svg";
import View from "../../../Assets/Icons/View.svg";
import Spinner from "../../Spinner";
import "alertifyjs/build/css/alertify.css";

function CategoryList() {
    const { empID } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [categoryceList, setCategoryList] = useState([]);
    const [employeeDetails, setEmployeeDetails] = useState({});
    const [remarksList, setRemarksList] = useState([]);
    const [isBtnDisable, setIsBtnDisable] = useState(false);
    const [errors, setErrors] = useState("");
    const [remarksValue, setRemarksValue] = useState({
        remarks: "", employee_id: empID,
    });

    const columns = [
        { label: "S.no", value: "s.no" },
        { label: "Remarks", value: "remarks" },
        { label: "Created at", value: "create_at" },
    ];

    useEffect(() => {
        if (empID) {
            getRemarks(empID);
        }
    }, [empID]);

    const getRemarks = async (id) => {
        try {
            setIsLoading(true);
            const responce = await GET_API(`/getRemarks/${id}`);
            console.log("responce", responce);
            if (responce?.status === 200) {
                setEmployeeDetails(responce?.data?.employdetails[0]);
                setRemarksList(responce?.data?.data);
                setCategoryList(responce?.data?.data);
                setIsLoading(false);
            }
        } catch (error) {
            alertify.error(error?.data?.message)
            setRemarksList([]);
            setCategoryList([]);
            setEmployeeDetails([]);
            setIsLoading(false);
        }
    }

    const handleCategorySearch = (e) => {
        const searchTerm = e.target.value;
        const searchedList = remarksList.filter((item) => {
            for (let key in item) {
                if (
                    typeof item[key] === "string" &&
                    item[key].toLowerCase().includes(searchTerm.toLowerCase())
                ) {
                    return true;
                }
            }
            return false;
        });
        setCategoryList(searchedList);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setRemarksValue({ ...remarksValue, [name]: value });
    };

    const addCatgory = async (e) => {
        e.preventDefault();
        const isValid = Validation.validateEmployeeRemarks(remarksValue);
        if (isValid && isValid.error) {
            setErrors({
                [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
            });
        } else {
            setErrors({});
            try {
                setIsBtnDisable(true);
                const responce = await POST_API(`/addRemarks`, remarksValue);
                if (responce?.data?.status === 200) {
                    setIsBtnDisable(false);
                    getRemarks(empID);
                    setRemarksValue({ remarks: "", employee_id: empID, })
                    alertify.success(responce?.data?.message);
                }
            } catch (error) {
                setIsBtnDisable(false);
                alertify.error("Failed to get Employee List");
            }
        }
    };

    const empData = [
        {
            label: "Employee Name",
            value: employeeDetails?.name ? employeeDetails?.name : "-",
        },
        {
            label: "Employee Code",
            value: employeeDetails?.employee_code ? employeeDetails?.employee_code : "-",
        }
    ];

    const resetForm = () => {
        setRemarksValue({
            remarks: ""
        });
        setErrors({})
    };

    if (isLoading) {
        return <Spinner />;
    }

    return (
        <div className=" bg-[#F7F6F4] grow">
            <div className="sm-container">
                <h1 className="heading-text">Employee Remarks</h1>
                <div className="card-wrapper">
                    <h4 className="card-header">Remarks</h4>
                    <div className="search-block">
                        <div className="search">
                            <input
                                type="text"
                                className="search-input-box"
                                placeholder="Search..."
                                autoComplete="off"
                                onChange={(e) => handleCategorySearch(e)}
                                style={{ outline: "none", border: "none" }}
                            />
                            <img src={Search} alt="" />
                        </div>
                        <div>
                            <Link to="/employee/list" className="view-list-button">
                                <span>View List</span>
                                <img src={View} alt="add" />
                            </Link>
                        </div>
                    </div>
                    <div className="add-list-container mb-3">
                        <div className="input-block">
                            <label className="label-name">Remarks<span className="required-symbol">*</span></label>
                            <textarea
                                name="remarks"
                                className="input-field"
                                type="text"
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                placeholder="Enter Remarks"
                                autoComplete="off"
                                value={remarksValue?.remarks}
                                rows={4}
                            />
                            {errors.remarks ? (
                                <div className="text-[#FF5C42] text-sm font-medium font-karla">
                                    {errors.remarks}
                                </div>
                            ) : null}
                        </div>
                        <div className="flex gap-3">
                            <div className="add-new-button-block">

                                <button className="add-new-button" disabled={isBtnDisable}>
                                    <span
                                        className="button-label"
                                        onClick={(e) => {
                                            addCatgory(e);
                                        }}
                                    >
                                        Add New
                                    </span>
                                    <img src={ColorAdd} alt="add" />
                                </button>
                            </div>
                            <button className="edit-button" onClick={() => resetForm()}>
                                <span className="button-label">Reset</span>
                                <img src={Edit} alt="add" />
                            </button>
                        </div>
                    </div>
                    <div className="p-3">
                        <div className='shadow-lg border-[1px] border-primary bg-white-color shadow-lg rounded mb-4'>
                            <Grid container spacing={2}>
                                <Grid item xs={8} >
                                    <h4 className="detaile-header">Employee Details</h4>
                                </Grid>
                            </Grid>
                            <div className="list-container">
                                <div className="list-content-block">
                                    {empData?.map((list) => {
                                        return (
                                            <div className="list">
                                                <label className="list-label">{list?.label}</label>
                                                <div style={{ wordBreak: "break-word" }} className="list-value">
                                                    {list?.value}
                                                </div>
                                            </div>
                                        );
                                    })}

                                </div>
                            </div>
                        </div>
                    </div>
                    <EmployeeRemarksTable
                        list={categoryceList}
                        columns={columns}
                    />
                </div>
            </div>
        </div>
    );
}

export default CategoryList;
