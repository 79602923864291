import React from "react";
import Barcode from "react-barcode";
import { numberToWords } from "../../NumbersToWords/NumbersToWords";
// import useScanDetection from "use-scan-detection";
// import { Code } from "@mui/icons-material";

const AdvancePrintVoucher = React.forwardRef((props, ref) => {
  const printData = props?.printVoucherData;
  console.log(printData);
  const createdDate = new Date(props?.printVoucherData?.created_at);
  const year = createdDate.getFullYear();
  const month = String(createdDate.getMonth() + 1).padStart(2, "0");
  const date = String(createdDate.getDate()).padStart(2, "0");
  const hour = createdDate.getUTCHours();
  var ampm = hour >= 12 ? "PM" : "AM";
  const minute = createdDate.getUTCMinutes();

  const handleScan = () => {
    console.log("scanned");
  };

  // useScanDetection({
  //   onComplete: (code) => {
  //     console.log(code);
  //     setBarcodeScan(code);
  //   },
  // });
  return (
    <div ref={ref} className="p-5">
      <div className="bank-voucher-border p-5">
        <h2 className="advance-voucher-title text-center">
          Bank Advance Voucher
        </h2>
        <table width={"100%"}>
          <tbody>
            <tr height="50px">
              <td></td>
              <td>
                <Barcode
                  value={printData?.voucher_no}
                  height="40"
                  displayValue="false"
                  onScan={() => handleScan()}
                />
              </td>
            </tr>
            <tr width="100%" className="voucher-emp-code-name">
              <td width="65%">
                Voucher No:
                <span className="advance-voucher-bold">
                  {printData?.voucher_no}
                </span>
              </td>
              <td width="35%">
                <div>
                  Date:
                  <span className="advance-voucher-bold">
                    {date + "/" + month + "/" + year}
                  </span>
                  at
                  <span className="advance-voucher-bold">
                    {hour + ":" + minute} {ampm}
                  </span>
                </div>
              </td>
            </tr>
            <tr width="100%" className="voucher-emp-code-name">
              <td width="65%"></td>
              <td width="35%">
                <span className="advance-voucher-bold"></span>
              </td>
            </tr>
            <tr width="100%">
              <td width="65%" className="pt-3.5">
                Emp Code:
                <span className="advance-voucher-bold">
                  {printData?.emp_code}
                </span>
              </td>
              <td width="35%" className="pt-3.5">
                Desinganation:
                <span className="advance-voucher-bold">
                  {printData?.designation_name}
                </span>
              </td>
            </tr>
            <tr width="100%">
              <td width="65%" className="pb-3.5">
                Emp Name:
                <span className="advance-voucher-bold">
                  {printData?.approved_by_name +
                    " " +
                    printData?.approved_by_fathers_name}
                </span>
              </td>
              <td width="35%" className="pb-3.5">
                Department:
                <span className="advance-voucher-bold">
                  {printData?.department_name}
                </span>
              </td>
            </tr>
            <tr width="100%">
              <td width="65%" className="pb-3.5">
                Amount:
                <span className="voucher-amount bank-voucher-border advance-voucher-bold">
                  RS. {printData?.amount}
                </span>
              </td>
              <td className="pb-3.5" width="35%">
                Payment Mode:
                <span className="advance-voucher-bold">
                  {printData?.payment_type}
                </span>
              </td>
            </tr>
            <tr width="100%">
              <td className="pb-9" width="65%" colspan="2">
                Received towards the Advance by Bank:
                <span className="advance-voucher-bold">
                  ({numberToWords(parseFloat(printData?.amount))} only)
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <table width={"100%"}>
          <tbody>
            <tr width="100%">
              <td width="40%" className="text-center">
                Admit By
              </td>
              <td width="30%" className="text-center">
                Checked By
              </td>
              <td width="30%" className="text-center">
                Employee Signature
              </td>
            </tr>
            <tr width="100%">
              <td width="40%" className="text-center"></td>
              <td width="30%" className="text-center">
                {printData?.created_by_name ? printData?.created_by_name : "-"}
              </td>
              <td width="30%" className="text-center"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
});

export default AdvancePrintVoucher;
