import React, { useState, useEffect } from "react";
import AgentTable from "../DataTable/AgentsTable";
import { Link } from "react-router-dom";
import { GET_API } from "../../Services/api";
import alertify from "alertifyjs";
import Spinner from "../Spinner";
import ColorAdd from "../../Assets/Icons/ColorAdd.svg";
import Search from "../../Assets/Icons/Search.svg";
import Footer from "../../Components/Footer";

function AllCommisionList() {
  const userId = localStorage?.getItem("user_id");
  const [filteredList, setFilteredList] = useState([]);
  const [agentcommisonList, setAgentCommissonList] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    { id: "s.no", label: "S.No", minWidth: 170 },
    { id: "agent_name", label: "Agent Name", minWidth: 100 },
    { id: "amount", label: "Amount", minWidth: 100 },
    { id: "percentage", label: "Percentage", minWidth: 100 },
    { id: "remarks", label: "Remarks", minWidth: 100 },
    { id: "created_at", label: "Date", minWidth: 100 },
    // { id: "more_icon", label: "", minWidth: 100 },
  ];

  useEffect(() => {
    getAgentCommissonList();
  }, []);

  const getAgentCommissonList = async () => {
    setLoading(true);
    try {
      const agentCommissionList = await GET_API(`/AgentCommissionList?login=${userId}`);
      if (agentCommissionList.status === 200) {
        const agentCommissonListArr = [];
        agentCommissionList.data.data.map((item) => {
          agentCommissonListArr.push(item);
        });
        setAgentCommissonList(agentCommissonListArr);
        setFilteredList(agentCommissonListArr);
        setLoading(false);
      }
    } catch (e) {
      alertify.error("Failed to get Agent Commission List");
      setLoading(false);
    }
  };

  const handleAgentCommissionSearch = (e) => {
    const searchTerm = e.target.value;
    const searchedList = filteredList.filter((item) => {
      for (let key in item) {
        if (
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
    setAgentCommissonList(searchedList);
  };

  if (loading) {
    return <Spinner />;
  }
  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">AgentCommission</h1>
        <div className="card-wrapper">
          <h4 className="card-header"> AgentCommission List</h4>
          <div className="search-block">
            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                style={{ outline: "none", border: "none" }}
                onChange={(e) => handleAgentCommissionSearch(e)}
              />
              <img src={Search} alt="" />
            </div>
            <div className="flex gap-3">
              <div className="add-new-button-block">
                <Link
                  to="/expense/agentcommission/add"
                  className="add-new-button"
                >
                  <span>Add New</span>
                  <img src={ColorAdd} alt="add" />
                </Link>
              </div>
            </div>
          </div>
          <AgentTable list={agentcommisonList} columns={columns} />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AllCommisionList;
