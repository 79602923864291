import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Edit from "../../../Assets/Icons/Edit.svg";
import View from "../../../Assets/Icons/View.svg";
import { GET_API } from "../../../Services/api";
import Spinner from "../../Spinner";
import AllowanceTable from "./ClientDetails/AllowanceTable";
import DeductionTable from "./ClientDetails/DeductionTable";

function ClientDetails() {
    const { state } = useLocation();
    const { id } = useParams();
    const [clientDetails, setClientDetails] = useState({})
    const [isLoading, setIsLoading] = useState(false);

    const listDetails = state?.list;

    // const clientDetailss = [
    //     {
    //         label: "Name",
    //         value: clientDetails?.clientList?.name,
    //     },

    //     {
    //         label: "Email",
    //         value: clientDetails?.clientList?.email,
    //     },
    //     {
    //         label: "Phone",
    //         value: clientDetails?.clientList?.phone,
    //     },
    //     {
    //         label: "Contact Person",
    //         value: clientDetails?.clientList?.contact_person_name,
    //     },
    //     {
    //         label: "Address",
    //         value: clientDetails?.clientList?.address,
    //     }
    // ];

    const getClientDetails = async () => {
        try {
            setIsLoading(true);
            const response = await GET_API(`getClientListbyId/${id}`);
            if (response?.status === 200) {
                setIsLoading(false);
                setClientDetails(response?.data)
                console.log("response", response);
            }

        } catch (error) {
            setClientDetails({})
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (id) {
            getClientDetails();
        }
    }, [id])

    const allowanceColumns = [
        { id: "s.no", label: "S.No" },
        { id: "allowance_id", label: "Allowance" },
        { id: "select_type", label: "Daily/Monthly" },
        { id: "automatic", label: "Automatic" },
        { id: "name", label: "Formula" },
      ];

    const deductionColumns = [
        { id: "s.no", label: "S.No" },
        { id: "deductions_id", label: "Deduction" },
        { id: "automatic", label: "Automatic" },
        { id: "name", label: "Formula" },
      ];

    if (isLoading) {
        return <Spinner />;
    }

    return (
        <div className=" bg-[#F7F6F4] grow">
            <div className="flex flex-col justify-between h-full">
                <div className=" sm-container">
                    <h1 className="heading-text">Client</h1>
                    <div className="card-wrapper">
                        <h4 className="card-header">Client Details</h4>
                        <div className="add-block">
                            <Link to="/masters/client/list" className="view-list-button">
                                <span>View List</span>
                                <img src={View} alt="add" />
                            </Link>
                            <Link
                                to={`/masters/client/edit/${id}`}
                                className="edit-button"
                                state={{ list: listDetails }}
                            >
                                <span>Edit</span>
                                <img src={Edit} alt="add" />
                            </Link>
                        </div>
                        <h4 className="detaile-header"></h4>
                        <div className="list-container ">
                            <div className="list-content-block">
                                {clientDetails?.clientList?.map((list, i) => {
                                    return (
                                        <>
                                            <div className="list " key={i}>
                                                <label className="list-label">Name</label>
                                                <div
                                                    style={{ wordBreak: "break-word" }}
                                                    className="list-value"
                                                >
                                                    {list.name}
                                                </div>
                                            </div>
                                            <div className="list " key={i}>
                                                <label className="list-label">Email</label>
                                                <div
                                                    style={{ wordBreak: "break-word" }}
                                                    className="list-value"
                                                >
                                                    {list.email ? list.email : "--"}
                                                </div>
                                            </div>
                                            <div className="list " key={i}>
                                                <label className="list-label">Phone</label>
                                                <div
                                                    style={{ wordBreak: "break-word" }}
                                                    className="list-value"
                                                >
                                                    {list.phone}
                                                </div>
                                            </div>
                                            <div className="list " key={i}>
                                                <label className="list-label">Contact Person</label>
                                                <div
                                                    style={{ wordBreak: "break-word" }}
                                                    className="list-value"
                                                >
                                                    {list.contact_person_name}
                                                </div>
                                            </div>
                                            <div className="list " key={i}>
                                                <label className="list-label">Salary Calculation</label>
                                                <div
                                                    style={{ wordBreak: "break-word" }}
                                                    className="list-value"
                                                >
                                                    {list.salary_calc === "fulldays" ? "Full Calendar Days" :list.salary_calc === "weekoff" ? "Based on Week Off":"--" }
                                                </div>
                                            </div>
                                            <div className="list " key={i}>
                                                <label className="list-label">Address</label>
                                                <div
                                                    style={{ wordBreak: "break-word" }}
                                                    className="list-value"
                                                >
                                                    {list.address}
                                                </div>
                                            </div>
                                        </>
                                    );
                                })}
                            </div>
                        </div>
                        {/* <h4 className="detaile-header">Allowance</h4>
                        <AllowanceTable
                            list={clientDetails?.clientallowance}
                            columns={allowanceColumns}
                        />
                        <h4 className="detaile-header">Deduction</h4>
                        <DeductionTable
                            list={clientDetails?.clientdeductions}
                            columns={deductionColumns}
                        /> */}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ClientDetails;
