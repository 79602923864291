import React, { useState } from "react";
import { Link } from "react-router-dom";
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import VerifiedIcon from '@mui/icons-material/Verified';
import Edit from "../../../Assets/Icons/Edit.svg";
import View from "../../../Assets/Icons/View.svg";
import WorkEexperienceTable from "../Tabs/Components/WorkExperienceTable";
import FamilyDetailsTable from "../Tabs/Components/FamilyDetailsTable";
import config from "../../../Services/config.json";
import ImageView from "../Components/ImageView";
import ImageIcon from "../../../Assets/Icons/upload.svg";
import PdfIcon from "../../../Assets/Icons/pdfIcon.png";
import { downloadEmployee } from "../Components/DownloadPdf";

function PersonalInfo({ listDetails, nextTab, viewList, handleVerify, isBtnDisable, isEdit, isDownload }) {
  const imgPath = config?.path
  const [isOpen, setIsOpen] = useState(false)
  const [imageSrc, setImageSrc] = useState('')

  const handleModal = (src) => {
    setIsOpen(!isOpen)
    setImageSrc(src)
  }
  const healthInformation = [
    {
      label: "Height",
      value: listDetails?.height ? listDetails?.height : "-",
    },
    {
      label: "Weight",
      value: listDetails?.weight ? listDetails?.weight : "-",
    },
    {
      label: "Blood Pressure",
      value: listDetails?.blood_pressure
        ? listDetails?.blood_pressure
        : "-",
    },
    {
      label: "Diabetes",
      value: listDetails?.diabets ? listDetails?.diabets : "-",
    },
    {
      label: "Cardiac/ Heart problems",
      value: listDetails?.heart_problem ? listDetails?.heart_problem : "-",
    },
    {
      label: "Skin disease",
      value: listDetails?.skin_disease ? listDetails?.skin_disease : "-",
    }
  ];

  const basicDocument = [
    {
      label: "Aadhar",
      value: listDetails?.aadhar_image ? imgPath + listDetails?.aadhar_image : "",
    },
    {
      label: "School Tc",
      value: listDetails?.school_tc ? imgPath + listDetails?.school_tc : "",
    },
    {
      label: "SSLC Certificate",
      value: listDetails?.sslc_Certificate ? imgPath + listDetails?.sslc_Certificate : "",
    },
    {
      label: "HSC Certificate",
      value: listDetails?.hsc_certificate ? imgPath + listDetails?.hsc_certificate : "",
    },
    {
      label: "Degree Certificate",
      value: listDetails?.degree_certificate ? imgPath + listDetails?.degree_certificate : "",
    },
    {
      label: "Addtional Course Certificate One",
      value: listDetails?.additional_course_certificate_one_image ? imgPath + listDetails?.additional_course_certificate_one_image : "",
    },
    {
      label: "Addtional Course Certificate Two",
      value: listDetails?.additional_course_certificate_two_image ? imgPath + listDetails?.additional_course_certificate_two_image : "",
    },
    {
      label: "Addtional Course Certificate Three",
      value: listDetails?.additional_course_certificate_three_image ? imgPath + listDetails?.additional_course_certificate_three_image : "",
    },
    {
      label: "Addtional Course Certificate Four",
      value: listDetails?.additional_course_certificate_four_image ? imgPath + listDetails?.additional_course_certificate_four_image : "",
    },
    {
      label: "Ex-Servicemen Discharge Book",
      value: listDetails?.ex_serviceman_dischare_book_image ? imgPath + listDetails?.ex_serviceman_dischare_book_image : "",
    },
    {
      label: "Work Experience Certificate One",
      value: listDetails?.work_Exprience_certificate_one_image ? imgPath + listDetails?.work_Exprience_certificate_one_image : "",
    },
    {
      label: "Work Experience Certificate Two",
      value: listDetails?.work_Exprience_certificate_two_image ? imgPath + listDetails?.work_Exprience_certificate_two_image : "",
    },
    {
      label: "Work Experience Certificate Three",
      value: listDetails?.work_Exprience_certificate_three_image ? imgPath + listDetails?.work_Exprience_certificate_three_image : "",
    },
    {
      label: "Work Experience Certificate Four",
      value: listDetails?.work_Exprience_certificate_four_image ? imgPath + listDetails?.work_Exprience_certificate_four_image : "",
    },
    {
      label: "Driving License",
      value: listDetails?.driving_license_image_upload ? imgPath + listDetails?.driving_license_image_upload : "",
    },
    {
      label: "Police Verification Certificate",
      value: listDetails?.police_verification_certificate_image ? imgPath + listDetails?.police_verification_certificate_image : "",
    },

  ];

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "company_name", label: "Company Name" },
    { id: "agency_name", label: "Agency Name" },
    { id: "period_from", label: "Period" },
    { id: "reason", label: "Reason for Termination" },
    { id: "rank", label: "Rank" },
  ];

  const familyColumns = [
    { id: "s.no", label: "S.No" },
    { id: "name", label: "Name" },
    { id: "relationship", label: "Relationship" },
    { id: "contact_no", label: "Contact No" },
    { id: "address", label: "Address" },
  ];

  const fileType = (value) => {
    return value.endsWith(".pdf") ? PdfIcon : value
  }

  return (
    <>
      <div className="add-block">
        <button className="submit-button" onClick={handleVerify} disabled={isBtnDisable}>
          <span className="submit-button-label">Verify</span>
          <VerifiedIcon style={{ color: "white" }} />
        </button>
        {isEdit ?
          <Link
            to={`/employee/edit/${listDetails?.employee_id}`}
            className="submit-button"
            state={{ list: listDetails }}
          >
            <span className="submit-button-label">Edit</span>
            <img style={{ color: "white" }} className="submit-button-label" src={Edit} alt="add" />
          </Link>
          :
          ""
        }

        {isDownload ?
          <div className="add-new-button-block">
            <button className="add-new-button" onClick={() => downloadEmployee(listDetails?.employee_id)}>
              <span>Download</span>
              <ArrowCircleDownIcon />
            </button>
          </div>
          :
          ""
        }
        <Link to={viewList} className="view-list-button">
          <span>View List</span>
          <img src={View} alt="add" />
        </Link>
      </div>
      <h4 className="card-header">Work Experience</h4>
      <div>
        <WorkEexperienceTable
          list={listDetails?.workExperience}
          columns={columns}
          isAction={false}
        />
      </div>
      <h4 className="card-header">Family Details</h4>
      <div>
        <FamilyDetailsTable
          list={listDetails?.familyDetails}
          columns={familyColumns}
          isAction={false}
        />
      </div>
      <h4 className="detaile-header">Health Information</h4>
      <div className="list-container border-bottom">
        <div className="list-content-block">
          {healthInformation.map((list) => {
            return (
              <div className="list">
                <label className="list-label">{list?.label}</label>
                <div style={{ wordBreak: "break-word" }} className="list-value">
                  {list?.value}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <h4 className="detaile-header">Basic Documents</h4>
      <div className="list-container">
        <div className="list-content-block">
          {basicDocument?.map((list, i) => {
            return (
              <div className="" key={i}>
                <label className="list-label">{list?.label}</label>
                <div className="flex" >
                  <img className="h-9 w-9" src={list?.value ? fileType(list?.value) : ImageIcon} />
                  {list?.value ?
                    <>
                      {list?.value?.endsWith(".pdf") ?
                        <a href={list?.value} rel="noopener noreferrer" target="_blank">
                          <ArrowCircleDownIcon
                            className="ml-8 mt-1.5 img-view-icon cursor-pointer"
                          /></a> :
                        <ErrorOutlineIcon
                          className="ml-8 mt-1.5 img-view-icon cursor-pointer"
                          onClick={() => handleModal(list?.value)}
                        />}
                    </>
                    :
                    <ErrorOutlineIcon
                      className="ml-8 mt-1.5 img-view-icon"
                    />}
                </div>
              </div>
            )
          })}

          <div className="list">
            <label className="list-label">Driving License Number</label>
            <div style={{ wordBreak: "break-word" }} className="list-value">
              {listDetails?.driving_license}
            </div>
          </div>
          <div className="list">
            <label className="list-label">Police Verification Certificate Number</label>
            <div style={{ wordBreak: "break-word" }} className="list-value">
              {listDetails?.police_verification_certificate_number}
            </div>
          </div>

        </div>
      </div>
      <ImageView
        isOpen={isOpen}
        handleModal={handleModal}
        imgSrc={imageSrc}
      />
      <div className="submit-reset-button">
        <button
          type="button"
          className="reset-button"
          onClick={() => {
            nextTab("basic_info");
          }}
        >
          <span className="reset-button-label">Prev</span>
        </button>
        <button
          type="submit"
          className="submit-button"
          onClick={() => {
            nextTab("position_info");
          }}
        >
          <span className="submit-button-label">Next</span>
        </button>
      </div>
    </>
  );
}

export default PersonalInfo;
