import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import { DELETE_API, GET_API } from "../../../Services/api";
import PayslipsTable from "../../DataTable/PayslipsTable";
import Spinner from "../../Spinner";
import Search from "../../../Assets/Icons/Search.svg";
import More from "../../../Assets/Icons/More.svg";
import Filter from "../../../Assets/Icons/Filter.svg";
import "alertifyjs/build/css/alertify.css";

function PayslipsList() {
  const navigate = useNavigate();
  const userId = localStorage?.getItem("user_id");
  const [payslipsList, setPayslipsList] = useState([]);
  const [filteredPayslips, setFilteredPayslips] = useState([]);
  const [loading, setLoading] = useState(false);
  const [employeeOption, setEmployeeOption] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [DesignationList, setDesignationList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [department, setDepartment] = useState([]);
  const [filter, setFilter] = useState(false);
  const [filterParams, setFilterParams] = useState("");
  const [showFilterResultData, setShowFilterResultData] = useState("");
  const [filterList, setfilterList] = useState({
    client_id: "",
    category_id: "",
    branch_id: "",
    emp_type_id: "",
    gender: "",
    department_id: "",
    from_date: "",
  });

  const showPayslipsDetails = (listDetail) => {
    console.log("listDetail", listDetail);
    navigate(`/payroll/payslip/details/${listDetail.employee_id}`, {
      state: {
        list: listDetail,
      },
    });
  };

  const columns = [
    { label: "S.no", value: "s.no" },
    { label: "Employee Name", value: "name" },
    { label: "Employee Code", value: "employee_code" },
    { label: "Department Name", value: "departmentname" },
    { label: "Designation Name", value: "designationName" },
    { label: "Created at", value: "create_at" },
    { label: "", value: "" },
  ];

  const getBranchList = async () => {
    try {
      setLoading(true);
      const branchList = await GET_API("/branchList");
      if (branchList.status === 200) {
        const branch = branchList?.data?.data.map((item, i) => {
          return { value: item?.id, label: item?.name };
        });
        branch.unshift({ value: "", label: "Select..." });
        setBranchList(branch);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Branch List");
    }
  };

  const getDepartmentList = async () => {
    setLoading(true);
    try {
      const departmentList = await GET_API("/DepartmentList");
      if (departmentList.status === 200) {
        const dep = departmentList?.data?.data.map((item, i) => {
          return { value: item?.id, label: item?.name };
        });
        dep.unshift({ value: "", label: "Select..." });
        setDepartment(dep);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Department List");
    }
  };

  const getClientList = async () => {
    setLoading(true);
    try {
      const cliList = await GET_API("/clientLists");
      if (cliList.status === 200) {
        const client = cliList?.data?.data.map((item, i) => {
          return { value: item?.id, label: item?.name };
        });
        client.unshift({ value: "", label: "Select..." });
        setClientList(client);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Client List");
    }
  };

  const getDesignationList = async () => {
    setLoading(true);
    try {
      const response = await GET_API("/CategoryList");
      if (response.status === 200) {
        const designation = response?.data?.data.map((item, i) => {
          return { value: item?.id, label: item?.name };
        });
        designation.unshift({ value: "", label: "Select..." });
        setDesignationList(designation);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      setDesignationList([])
      alertify.error("Failed to Get Designation List");
    }
  };

  const genderList = [
    { label: "Select...", value: "" },
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
  ];

  useEffect(() => {
    getBranchList();
    // getEmployeeType();
    getDepartmentList();
    getDesignationList();
    getClientList();
    getEmployeeOptionList();
  }, []);

  useEffect(() => {
    getPayslipsList(filterParams);
  }, [showFilterResultData]);

  const getPayslipsList = async (filters) => {
    try {
      setLoading(true);
      const responce = await GET_API(`/ListEmployeeTentacles?login=${userId}${filters}`);
      if (responce?.status === 200) {
        setPayslipsList(responce?.data?.data);
        setFilteredPayslips(responce?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setPayslipsList([]);
      setFilteredPayslips([]);
    }
  };

  const handlePayslipsSearch = (e) => {
    const searchTerm = e.target.value;
    const searchedList = payslipsList.filter((item) => {
      for (let key in item) {
        if (
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
    setFilteredPayslips(searchedList);
  };

  const getEmployeeOptionList = async () => {
    setLoading(true);
    try {
      const employeeList = await GET_API(`/ListEmployeeTentacles?login=${userId}`);
      if (employeeList.status === 200) {
        setLoading(false);
        const empList = employeeList?.data?.data.map((item, i) => {
          return { value: item?.employee_id, label: `${item?.name} - ${item?.employee_code}` };
        });
        empList.unshift({ value: "", label: "Select..." });
        setEmployeeOption(empList);
      }
    } catch (e) {
      alertify.error("Failed to get Employee List");
      setEmployeeOption([])
    }
  };

  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Payslips",
        async function () {
          if (row_id) {
            const deleteRow = await DELETE_API(`/deletepayslips/${row_id}`);
            if (deleteRow.status === 200) {
              alertify.success(deleteRow?.data?.message);
              getPayslipsList();
              return;
            }
            alertify.error("Failed To Delete Floor");
          }
        },
        function () { }
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };

  const navigatePaySlipPage = (listDetail) => {
    navigate("/payslip-details", {
      state: {
        list: listDetail,
      },
    });
  };

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const handleFilter = async (e, name, type) => {
    const value = type === "date" ? e?.target?.value : e?.value;
    filterList[name] = value;
    const list = { ...filterList };
    Object.keys(list).forEach((key) => {
      if (list[key] === "") {
        delete list[key];
      }
    });
    let concateURL = "";
    let isFirst = true;
    if (isEmptyObject(list)) {
      setFilterParams(concateURL);
      setfilterList({ ...filterList, [name]: value });
    }

    Object.keys(list).forEach((key) => {
      const newURL = `${key}=${list[key]}`;
      if (isFirst) {
        concateURL += "?" + newURL;
        isFirst = false;
      } else {
        concateURL += "&" + newURL;
      }
      setFilterParams(concateURL);
    });
  };


  const showFilterResult = (e) => {
    e?.preventDefault();
    setShowFilterResultData(filterParams);
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <h1 className="heading-text">Payslips</h1>
        <div className="card-wrapper">
          <h4 className="card-header">Payslip List</h4>
          <div className="search-block">
            <div className="search">
              <input
                type="text"
                className="search-input-box"
                placeholder="Search..."
                autoComplete="off"
                onChange={(e) => handlePayslipsSearch(e)}
                style={{ outline: "none", border: "none" }}
              />
              <img src={Search} alt="" />
            </div>
            <div className="flex gap-3">
              <div
                className="filter-button"
                onClick={() => setFilter((prev) => !prev)}
              >
                <span className="filter-btn-label">Filter</span>
                <img src={Filter} alt="" />
              </div>
            </div>
          </div>
          {filter && (
            <form className="border-t-[1px] border-t-[#D9D9D9]">
              <div className="form mt-4">
                <div className="field-block dropdown-select-to-one">
                  <label className="label-name">Client</label>
                  <Select
                    options={clientList}
                    value={clientList?.find(
                      (option) => option?.value === filterList.client_id
                    )}
                    onChange={(e) => handleFilter(e, "client_id", "select")}
                    name="client_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-one">
                  <label className="label-name">Designation</label>
                  <Select
                    options={DesignationList}
                    value={DesignationList?.find(
                      (option) => option?.value === filterList.category_id
                    )}
                    onChange={(e) => handleFilter(e, "category_id", "select")}
                    name="category_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-two">
                  <label className="label-name">Employee</label>
                  <Select
                    options={employeeOption}
                    value={employeeOption?.find(
                      (option) => option?.value === filterList.employee_id
                    )}
                    onChange={(e) => handleFilter(e, "employee_id", "select")}
                    name="employee_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-two">
                  <label className="label-name">Branch Name</label>
                  <Select
                    options={branchList}
                    value={branchList?.find(
                      (option) => option?.value === filterList.branch_id
                    )}
                    onChange={(e) => handleFilter(e, "branch_id", "select")}
                    name="branch_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-three">
                  <label className="label-name">
                    Gender
                  </label>
                  <Select
                    options={genderList}
                    value={genderList?.find(
                      (option) => option?.value === filterList.gender
                    )}
                    onChange={(e) => handleFilter(e, "gender", "select")}
                    name="gender"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-three">
                  <label className="label-name">
                    Department
                  </label>
                  <Select
                    options={department}
                    value={department?.find(
                      (option) => option?.value === filterList.department_id
                    )}
                    onChange={(e) => handleFilter(e, "department_id", "select")}
                    name="department_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block">
                  <label className="label-name">
                    Joining Date
                  </label>
                  <input
                    className="input-field "
                    type="date"
                    autoComplete="off"
                    name="from_date"
                    value={filterList?.from_date}
                    onChange={(e) => {
                      handleFilter(e, "from_date", "date");
                    }}
                  />
                </div>
                <div className="field-block flex justify-center">
                  <div className="add-new-button-block w-[40%] h-max ">
                    <button
                      className="add-new-button"
                      onClick={(e) => {
                        showFilterResult(e);
                      }}
                    >
                      <span style={{ cursor: "pointer" }}>Show Result</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}
          {/**************** * Table ********************/}

          <PayslipsTable
            list={filteredPayslips}
            columns={columns}
            icon={More}
            multiple={true}
            paySlip={navigatePaySlipPage}
            showDetails={showPayslipsDetails}
            deleteRow={handleDelete}
          />
        </div>
      </div>
    </div>
  );
}

export default PayslipsList;
