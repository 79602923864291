import React, { useState, useEffect } from "react";
import More from "../../../Assets/Icons/More.svg";
import { DELETE_API, GET_API, POST_API } from "../../../Services/api";
import FloorTable from "../../DataTable/FloorTable";
import Search from "../../../Assets/Icons/Search.svg";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import Validation from "../../../Validation/Validation";
import { Link } from "react-router-dom";
import Edit from "../../../Assets/Icons/Edit.svg";
import Spinner from "../../Spinner";

function Floor() {
  const [floorList, setFloorList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [errors, seterrors] = useState({});
  const [editStatus, setEditStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [newFloor, setNewFloor] = useState({
    name: "",
    description: "",
  });

  const columns = [
    { id: "s.no", label: "S.No", minWidth: 170 },
    { id: "floor", label: "Floor", minWidth: 100 },
    { id: "description", label: "Description", minWidth: 100 },
    { id: "created_at", label: "Created at", minWidth: 100 },
    { id: "more_icon", label: "", minWidth: 100 },
  ];

  useEffect(() => {
    getFloorList();
  }, []);
  const getFloorList = async () => {
    setLoading(true);
    try {
      const floorList = await GET_API("/floorList");
      if (floorList.status === 200) {
        setLoading(false);
        setFloorList(floorList?.data?.data);
        setFilteredList(floorList?.data?.data);
        return;
      }
    } catch (error) {
      alertify.error("Failed to Get Floor List");
      setLoading(false);
    }
  };

  const handleFloorSearch = (e) => {
    const searchTerm = e.target.value;
    const searchedList = floorList.filter((item) => {
      for (let key in item) {
        if (
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
    setFilteredList(searchedList);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewFloor({ ...newFloor, [name]: value });
  };
  const addFloor = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.validateFloor(newFloor);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    const createFloor = await POST_API("/addFloors", newFloor);
    if (createFloor.status === 200) {
      setButtonDisabled(false);
      setNewFloor({ name: "", description: "" });
      getFloorList();
      alertify.success(createFloor?.data?.message);
      return;
    }
    alertify.error(createFloor?.response?.data?.message);
  };
  const editFloor = async (e) => {
    setButtonDisabled(true);
    e.preventDefault();
    const isValid = Validation.validateFloor(newFloor);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    const editFloor = await POST_API("/updateFloors", newFloor);
    if (editFloor.status === 200) {
      setButtonDisabled(false);
      setNewFloor({ name: "", description: "" });
      getFloorList();
      setEditStatus(false);
      alertify.success(editFloor?.data?.message);
      return;
    }
    alertify.error(editFloor?.response?.data?.message);
  };
  const resetForm = () => {
    setEditStatus(false);
    setNewFloor({ ...newFloor, name: "", description: "" });
    seterrors({});
  };

  const handleDelete = (row_id) => {
    alertify
      .confirm(
        "Confirm",
        "Confirm to Delete Floor..?",
        async function () {
          if (row_id) {
            const deleteRow = await DELETE_API(`/deleteFloors/${row_id}`);
            if (deleteRow.status === 200) {
              alertify.success(deleteRow?.data?.message);
              getFloorList();
              return;
            }
            alertify.error("Failed To Delete Floor");
          }
        },
        function () {}
      )
      .set("movable", false)
      .set("labels", { ok: "Delete!", cancel: "Cancel!" });
  };
  if (loading) {
    return <Spinner />;
  }
  return (
    <>
      <div className=" bg-[#F7F6F4] grow">
        <div className="sm-container">
          <h1 className="heading-text">Floor</h1>
          <div className="card-wrapper">
            <h4 className="card-header">Add Floor</h4>
            <div className="search-block">
              <div className="search">
                <input
                  type="text"
                  className="search-input-box"
                  placeholder="Search..."
                  autoComplete="off"
                  onChange={(e) => handleFloorSearch(e)}
                  style={{ outline: "none", border: "none" }}
                />

                <img src={Search} alt="" />
              </div>
            </div>
            <div className="add-list-container">
              <div className="input-block">
                <label className="add-new-input-label">
                  Floor <span className="required-symbol">*</span>
                </label>

                <input
                  type="text"
                  name="name"
                  className="input-field"
                  placeholder="Enter Floor Name"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  autoComplete="off"
                  value={newFloor.name}
                />
                {errors.name ? (
                  <div className="text-[#FF5C42] text-sm font-medium font-karla">
                    {errors.name}
                  </div>
                ) : null}
              </div>
              <div className="input-block">
                <label className="add-new-input-label">Description</label>
                <textarea
                  name="description"
                  className="input-field"
                  placeholder="Enter Description"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={newFloor.description}
                  autoComplete="off"
                />
                {errors.description ? (
                  <div className="text-[#FF5C42] text-sm font-medium font-karla">
                    {errors.description}
                  </div>
                ) : null}
              </div>
              <div className="flex gap-3">
                <div className="add-new-button-block">
                  {editStatus ? (
                    <button
                      className="add-new-button"
                      onClick={(e) => {
                        editFloor(e);
                      }}
                      disabled={buttonDisabled}
                    >
                      <span className="button-label">Save</span>
                      <img src={ColorAdd} alt="add" />
                    </button>
                  ) : (
                    <button
                      className="add-new-button"
                      onClick={(e) => {
                        addFloor(e);
                      }}
                      disabled={buttonDisabled}
                    >
                      <span className="button-label">Add New</span>
                      <img src={ColorAdd} alt="add" />
                    </button>
                  )}
                </div>
                <Link className="edit-button" onClick={() => resetForm()}>
                  <span className="button-label">Reset</span>
                  <img src={Edit} alt="add" />
                </Link>
              </div>
            </div>
            <FloorTable
              list={filteredList}
              columns={columns}
              icon={More}
              editStatus={setEditStatus}
              editList={setNewFloor}
              multiple={false}
              deleteRow={handleDelete}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Floor;
