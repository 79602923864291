
import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import alertify from "alertifyjs";
import { useReactToPrint } from "react-to-print";
import { GET_API } from "../../../Services/api";
import Spinner from "../../Spinner";
import Print from "../../../Assets/Icons/Print.svg";
import Logo from "../../../Assets/Logo/Logo.png";
import Location from "../../../Assets/Icons/Location.svg";
import "alertifyjs/build/css/alertify.css";

function PayslipDetails() {
  let componentRef = useRef();
  const [payslipDetails, setPayslipDetails] = useState({});
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getPayslip();
  }, []);

  const getPayslip = async () => {
    setLoading(true);
    try {
      const payroll = await GET_API(`/calculatePayroll/${id}`);
      if (payroll?.status === 200) {
        setPayslipDetails(payroll?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      alertify.error(error?.response?.data?.message);
    }
  };

  const print = useReactToPrint({
    content: () => componentRef.current,
  });

  // const generatePayroll = async () => {
  //   setLoading(true);
  //   try {
  //     const payroll = await POST_API(`/payslipGenerate/${id}`, {
  //       payslipDetails,
  //       createdBy: localStorage.getItem("user_id"),
  //     });
  //     if (payroll.status === 200) {
  //       setLoading(false);
  //       const payslip = getPayslip();
  //       setTimeout(() => print(), 2000);
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     alertify.error(error?.response?.data?.message);
  //   }
  // };

  const intToWords = (rawValue) => {
    var num = rawValue,
      a = [
        "",
        "one ",
        "two ",
        "three ",
        "four ",
        "five ",
        "six ",
        "seven ",
        "eight ",
        "nine ",
        "ten ",
        "eleven ",
        "twelve ",
        "thirteen ",
        "fourteen ",
        "fifteen ",
        "sixteen ",
        "seventeen ",
        "eighteen ",
        "nineteen ",
      ],
      b = [
        "",
        "",
        "twenty",
        "thirty",
        "forty",
        "fifty",
        "sixty",
        "seventy",
        "eighty",
        "ninety",
      ],
      c = ["thousand", "million", ""],
      words = "";

    num = ("000000000" + num.toString()).substr(-9).match(/.{3}/g);

    for (var i = 0; i < c.length; i++) {
      var n = num[i],
        str = "";
      str += words != "" ? " " + c[c.length - 1 - i] + " " : "";
      str += n[0] != 0 ? a[Number(n[0])] + "hundred " : "";
      n = n.substr(1);
      str +=
        n != 0
          ? (str != "" ? "and " : "") +
          (a[Number(n)] || b[n[0]] + " " + a[n[1]])
          : "";
      words += str;
    }
    return words.replace(/ +/g, " ").replace(/ $/, "");
  };

  if (loading) {
    <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className=" sm-container">
        <div className="flex justify-between items-center">
          <div>
            <h1 className="heading-text">PaySlip</h1>
          </div>
          <div>
            <button className="view-list-button" onClick={print}>
              Print
              <img src={Print} alt="" />
            </button>
          </div>
        </div>
        <div className="card-wrapper">
          <div ref={componentRef}>
            <h4 className="payslip-header">
              <div className="flex justify-center">
                <div >
                  <div className="flex justify-center">
                    <img src={Logo} className="h-[60px] w-[60px]" alt="" />
                  </div>
                  <div className="flex justify-center">
                    <b>Tentacle Secure Squads Private Limited</b>
                  </div>

                  <div className="flex justify-center">
                    PaySlip Month for &nbsp;
                    <b>{payslipDetails?.displayMonth}</b>
                  </div>
                  <div className="flex justify-center items-center">
                    <label className="list-label flex">
                      {/* <img src={RedUser} className="w-6 h-6" alt="location"/> */}
                      {payslipDetails?.client?.name} </label>
                    <div className="pl-1.5">|</div>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="flex advance-section-list-value"
                    >
                      <img src={Location} className="w-6 h-6" alt="location" />  {payslipDetails?.client?.location}
                    </div>
                  </div>
                </div>
              </div>
            </h4>
            <div className="p-1 pl-3 pr-3">
              <div className="flex justify-between">
                <div>
                  <div className="flex">
                    <label className="list-label">Employee Code: </label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="advance-section-list-value"
                    >
                      {payslipDetails?.employee?.employee_code}
                    </div>
                  </div>
                  <div className="flex">
                    <label className="list-label">Employee Name: </label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="advance-section-list-value"
                    >
                      {payslipDetails?.employee?.name}
                    </div>
                  </div>
                  <div className="flex">
                    <label className="list-label">Father Name: </label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="advance-section-list-value"
                    >
                      {payslipDetails?.employee?.father_name}
                    </div>
                  </div>
                  <div className="flex">
                    <label className="list-label">Bank Account No: </label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="advance-section-list-value"
                    >
                      {payslipDetails?.bank?.home_bank_account_no || payslipDetails?.bank?.hdfc_account_no}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex">
                    <label className="list-label">Designation: </label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="advance-section-list-value"
                    >
                      {payslipDetails?.employee?.category_name}
                    </div>
                  </div>
                  <div className="flex">
                    <label className="list-label">ESI: </label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="advance-section-list-value"
                    >
                      {payslipDetails?.employee?.esi_number}
                    </div>
                  </div>
                  <div className="flex">
                    <label className="list-label">PF No: </label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="advance-section-list-value"
                    >
                      {payslipDetails?.employee?.pf_number}
                    </div>
                  </div>
                  <div className="flex">
                    <label className="list-label">Bank IFSC: </label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="advance-section-list-value"
                    >
                      {payslipDetails?.bank?.home_bank_ifsc_code}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-3 pt-1">
              <table className=" w-[100%] border border-[#d9d9d9] border-collapse ">
                <tbody>
                  <tr>
                    <td className="border  border-[#d9d9d9] p-3 pt-3 p w-[40%]  align-baseline">
                      <table className="w-[100%]">
                        <tbody>
                          {/* <tr>
                            <td>Holiday Scheme</td>
                            <td className="text-right">
                              <b>
                                {payslipDetails?.employee?.holiday_scheme_name}
                              </b>
                            </td>
                          </tr> */}
                          <tr>
                            <td>Total Days</td>
                            <td className="text-right">
                              <b>
                                {payslipDetails?.attendance?.totalDays} days
                              </b>
                            </td>
                          </tr>
                          <tr>
                            <td>Present Days</td>
                            <td className="text-right">
                              <b>
                                {payslipDetails?.attendance?.presentDays} days
                              </b>
                            </td>
                          </tr>
                          <tr>
                            <td>Absent Days</td>
                            <td className="text-right">
                              <b>
                                {payslipDetails?.attendance?.absentDays} days
                              </b>
                            </td>
                          </tr>
                          {payslipDetails?.attendance?.incentiveDays ?
                            <tr>
                              <td>Incentive Days</td>
                              <td className="text-right">
                                <b>
                                  {payslipDetails?.attendance?.incentiveDays} days
                                </b>
                              </td>
                            </tr> : null}

                        </tbody>
                      </table>
                    </td>
                    <td className="border border-[#d9d9d9] p-3 pt-3 w-[40%] align-baseline">
                      <table className="w-[100%]">
                        <tbody>
                          <tr>
                            <th className="text-left">
                              <b> </b>
                            </th>
                            <th c className="text-right">
                              <b></b>
                            </th>
                            <th className="text-right">
                              <b> Fixed</b>
                            </th>
                            <th className="text-right">
                              <b> Earned</b>
                            </th>
                          </tr>
                          {payslipDetails?.earnings &&
                            payslipDetails?.earnings.map((list, i) => {
                              return (
                                <tr key={i}>
                                  <td>{list?.name}</td>
                                  <td>{list?.type}</td>
                                  <td className="text-right">
                                    {list?.totalAmount}
                                  </td>
                                  <td className="text-right">
                                    <b>{list?.actualAmount}</b>
                                  </td>
                                </tr>
                              );
                            })}
                          <tr className="border-t  border-t-[#d9d9d9]">
                            <td><b>Total</b></td>
                            <td></td>
                            <td className="text-right"><b>{payslipDetails?.earnings && payslipDetails?.earnings?.reduce((sum, { totalAmount }) => sum + totalAmount, 0)}</b></td>
                            <td className="text-right"><b>{payslipDetails?.totalEarnings?.total}</b></td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td className="border  border-[#d9d9d9]  p-3 pt-3 w-[20%] align-baseline">
                      <table className="w-[100%]">
                        <tbody>
                          <tr>
                            <th colSpan="2" className="text-left">
                              <b>Deductions</b>
                            </th>
                          </tr>
                          {payslipDetails?.deductions &&
                            payslipDetails?.deductions.map((list, i) => {
                              return (
                                <tr>
                                  <td>{list?.name}</td>
                                  <td className="text-right">
                                    <b>{list?.amount}</b>
                                  </td>
                                </tr>
                              );
                            })}
                          {payslipDetails?.otherDeductions &&
                            payslipDetails?.otherDeductions.map((list, i) => {
                              return (
                                <tr>
                                  <td>{list?.name}</td>
                                  <td className="text-right">
                                    <b>{list?.amount}</b>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td className="border  border-[#d9d9d9]  p-1 pl-2 pr-2 p w-[30%]  align-baseline">
                      <table className="w-[100%]">
                        <tbody>
                          <tr>
                            <td>
                              <b>Total Days</b>
                            </td>
                            <td className="text-right">
                              {payslipDetails?.attendance?.totalDays} days
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td className="border  border-[#d9d9d9]  p-1 pl-2 pr-2 p w-[30%]  align-baseline">
                      <table className="w-[100%]">
                        <tbody>
                          <tr>
                            <td className="w-[65%]">
                              <b>Total Earnings</b>
                            </td>
                            <td className="text-right">
                              {/* (-{payslipDetails?.totalEarnings?.deduction}) */}
                            </td>
                            <td className="text-right">
                              {payslipDetails?.totalEarnings?.total}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td className="border  border-[#d9d9d9]  p-1 pl-2 pr-2 p w-[30%]  align-baseline">
                      <table className="w-[100%]">
                        <tbody>
                          <tr>
                            <td>
                              <b>Total</b>
                            </td>
                            <td className="text-right">
                              {payslipDetails?.totalDeductions?.total}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="p-3 flex">
              <p>Salary to be paid only through bank  : </p>
              <p>
                <b>
                  {" "}
                  Rs. {payslipDetails?.salaryToBePaid}(
                  {payslipDetails?.salaryToBePaid &&
                    intToWords(payslipDetails?.salaryToBePaid)}
                  Rupees)
                </b>
              </p>
            </div>
            <br />
            <div className="p-3 pt-4">
              <div className="flex justify-between items-center">
                <div>
                  <b>Admit by</b>
                </div>
                <div>
                  <b>Checked by</b>
                </div>
                <div>
                  <b>Employee Signature</b>
                </div>
              </div>
            </div>
            {/* PF Salary details */}
            {payslipDetails?.pfEarnings &&
              payslipDetails?.pfEarnings.length > 0 && (
                <>
                  <div id="scissors">
                    <div></div>
                  </div>
                  <h4 className="payslip-pf-header">
                    <div className="flex justify-between items-center">
                      PF Salary Voucher
                    </div>
                  </h4>

                  <div className="p-1 pl-3 pr-3">
                    <div className="flex justify-between">
                      <div>
                        <div className="flex">
                          <label className="list-label">Employee Code: </label>
                          <div
                            style={{ wordBreak: "break-word" }}
                            className="advance-section-list-value"
                          >
                            {payslipDetails?.employee?.emp_code}
                          </div>
                        </div>
                        <div className="flex">
                          <label className="list-label">Employee Name: </label>
                          <div
                            style={{ wordBreak: "break-word" }}
                            className="advance-section-list-value"
                          >
                            {payslipDetails?.employee?.first_name +
                              " " +
                              payslipDetails?.employee?.fathers_name}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="flex">
                          <label className="list-label">Designation: </label>
                          <div
                            style={{ wordBreak: "break-word" }}
                            className="advance-section-list-value"
                          >
                            {payslipDetails?.employee?.designation_name}
                          </div>
                        </div>
                        <div className="flex">
                          <label className="list-label">Date: </label>
                          <div
                            style={{ wordBreak: "break-word" }}
                            className="advance-section-list-value"
                          >
                            {payslipDetails?.date}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="p-3 pt-1">
                    <table className=" w-[100%] border border-[#d9d9d9] border-collapse ">
                      <tbody>
                        <tr>
                          <td className="border  border-[#d9d9d9] p-3 pt-3 p w-[40%]  align-baseline">
                            <table className="w-[100%]">
                              <tbody>
                                <tr>
                                  <td>Holiday Scheme</td>
                                  <td className="text-right">
                                    <b>
                                      {
                                        payslipDetails?.employee
                                          ?.holiday_scheme_name
                                      }
                                    </b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Present Days</td>
                                  <td className="text-right">
                                    <b>
                                      {
                                        payslipDetails?.pfAttendance
                                          ?.presentDays
                                      }
                                    </b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Paid Leave Days</td>
                                  <td className="text-right">
                                    <b>
                                      {payslipDetails?.pfAttendance?.paidDays}
                                    </b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Net Present Days</td>
                                  <td className="text-right">
                                    <b>
                                      {
                                        payslipDetails?.pfAttendance
                                          ?.netPresentDays
                                      }
                                    </b>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td className="border  border-[#d9d9d9]  p-3 pt-3 w-[40%]  align-baseline">
                            <table className="w-[100%]">
                              <tbody>
                                <tr>
                                  <th colSpan="4" className="text-left">
                                    <b> Earnings</b>
                                  </th>
                                </tr>
                                {payslipDetails?.pfEarnings &&
                                  payslipDetails?.pfEarnings.map((list, i) => {
                                    return (
                                      <tr>
                                        <td>{list?.name}</td>
                                        <td>{list?.type}</td>
                                        <td className="text-right">
                                          {list?.totalAmount}
                                        </td>
                                        <td className="text-right">
                                          {list?.actualAmount}
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </td>
                          <td className="border  border-[#d9d9d9]  p-3 pt-3 w-[20%] align-baseline">
                            <table className="w-[100%]">
                              <tbody>
                                <tr>
                                  <th colSpan="2" className="text-left">
                                    <b>Deductions</b>
                                  </th>
                                </tr>
                                {payslipDetails?.pfDeductions &&
                                  payslipDetails?.pfDeductions.map(
                                    (list, i) => {
                                      return (
                                        <tr>
                                          <td>{list?.name}</td>
                                          <td className="text-right">
                                            <b>{list?.amount}</b>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td className="border  border-[#d9d9d9]  p-1 pl-2 pr-2 p w-[30%]  align-baseline">
                            <table className="w-[100%]">
                              <tbody>
                                <tr>
                                  <td>
                                    <b>Total Days</b>
                                  </td>
                                  <td className="text-right">
                                    {payslipDetails?.attendance?.totalDays} days
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td className="border  border-[#d9d9d9]  p-1 pl-2 pr-2 p w-[30%]  align-baseline">
                            <table className="w-[100%]">
                              <tbody>
                                <tr>
                                  <td className="w-[65%]">
                                    <b>Total</b>
                                  </td>
                                  <td className="text-right">
                                    {payslipDetails?.pfEarnings &&
                                      payslipDetails?.pfEarnings[0] &&
                                      payslipDetails?.pfEarnings[0]
                                        .actualAmount}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td className="border  border-[#d9d9d9]  p-1 pl-2 pr-2 p w-[30%]  align-baseline">
                            <table className="w-[100%]">
                              <tbody>
                                <tr>
                                  <td>
                                    <b>Total</b>
                                  </td>
                                  <td className="text-right">
                                    {payslipDetails?.pfTotalDeductions?.total}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="p-3 flex">
                    <p>Salary to be credited in Bank : </p>
                    <p>
                      <b>
                        {" "}
                        Rs. {payslipDetails?.pfSalaryInBank}(
                        {payslipDetails?.pfSalaryInBank &&
                          intToWords(payslipDetails?.pfSalaryInBank)}
                        Rupees)
                      </b>
                    </p>
                  </div>
                  <br />

                  <div className="p-3 pt-4">
                    <div className="flex justify-between items-center">
                      <div>
                        <b>Admit by</b>
                      </div>
                      <div>
                        <b>Checked by</b>
                      </div>
                      <div>
                        <b>Employee Signature</b>
                      </div>
                    </div>
                  </div>
                </>
              )}
          </div>
          {/* {payslipDetails?.voucherNo == "" && (
            <div className="search-block ">
              <div className="export-btn" onClick={generatePayroll}>
                <span className="export-btn-text">Generate Payroll</span>
                <img src={Export} alt="" />
              </div>
            </div>
          )} */}

          <hr className=" border-[#d9d9d9] mt-6"></hr>
          {(payslipDetails?.ismaual === 0 || payslipDetails?.ismaual === "0") ?
            <>
              <div className="p-3">
                <h3>
                  <b>Attendance Details: </b>
                </h3>
              </div>
              <div className="grid grid-cols-2 p-6 pt-2 gap-2">
                <div>
                  <p>
                    Month : <b>{payslipDetails?.displayMonth}</b>
                  </p>
                  <p>
                    Total days Present :
                    <b>
                      {payslipDetails?.presentDetails &&
                        payslipDetails?.presentDetails.length}
                    </b>
                  </p>
                </div>
                <div className="flex justify-end items-center gap-3">
                  <p className="flex jusitify-center items-center gap-2">
                    <span className="bg-[#11B76B] border rounded border-[#11B76B] h-[14px] w-[14px]"></span>{" "}
                    <span>Present</span>
                  </p>
                  <p className="flex jusitify-center items-center gap-2">
                    <span className="bg-[#ed452a] border rounded border-[#ed452a]  h-[14px] w-[14px]"></span>
                    <span>Absent</span>
                  </p>
                </div>
              </div>
              <div className="p-1 flex flex-col items-center justify-center">
                <table className="overflow-x-scroll  text-center w-2/4">
                  <tr>
                    {payslipDetails?.daysArr &&
                      payslipDetails?.daysArr.map((list, i) => (
                        <th className="border attendance-header border-[#d9d9d9] p-3">
                          {list}
                        </th>
                      ))}
                  </tr>
                  {payslipDetails?.attendance_details &&
                    Object.keys(payslipDetails?.attendance_details).map((key) => (
                      <>
                        <tr>
                          {payslipDetails?.attendance_details[key].map(
                            (item, index) => {
                              {
                                return (
                                  <td className="font-karla border border-[#d9d9d9] p-3">
                                    <span
                                      className={`rounded px-2 py-1 ${item.value === "P"
                                        ? "bg-[#11B76B] text-[#FFF]"
                                        : item.value === "A"
                                          ? "bg-[#ed452a] text-[#FFF]"
                                          : ""
                                        }`}
                                    >
                                      {item.date}
                                    </span>
                                  </td>
                                );
                              }
                            }
                          )}
                        </tr>
                      </>
                    ))}
                </table>
              </div>
            </> : null}
        </div>
      </div>
    </div>
  );
}
export default PayslipDetails;








// import React, { useState, useEffect, useRef } from "react";
// import { useParams } from "react-router-dom";
// import StaticWrapper from "../../StaticWrapper";
// import Export from "../../../Assets/Icons/Export.svg";
// import Print from "../../../Assets/Icons/Print.svg";
// import Logo from "../../../Assets/Logo/Logo.png";
// import { Link, useNavigate } from "react-router-dom";
// import Tick from "../../../Assets/Icons/tick.png";
// import Cross from "../../../Assets/Icons/cross.png";
// import alertify from "alertifyjs";
// import "alertifyjs/build/css/alertify.css";
// import View from "../../../Assets/Icons/View.svg";
// import AdvanceDetailsTable from "../../DataTable/AdvanceDetailsTable";
// import config from "../../../Services/config.json";
// import { GET_API, POST_API } from "../../../Services/api";
// import { Margin } from "@mui/icons-material";
// import { useReactToPrint } from "react-to-print";
// import Barcode from "react-barcode";
// import Spinner from "../../Spinner";

// function PayslipDetails() {
//   let componentRef = useRef();
//   const [payslipDetails, setPayslipDetails] = useState({});
//   const { id } = useParams();
//   const [loading, setLoading] = useState(false);

//   useEffect(() => {
//     getPayslip();
//   }, []);
//   const getPayslip = async () => {
//     setLoading(true);
//     try {
//       const payroll = await GET_API(`/calculatePayroll/${id}`);
//       if (payroll?.status === 200) {
//         setPayslipDetails(payroll?.data?.data);
//         setLoading(false);
//       }
//     } catch (error) {
//       alertify.error(error?.response?.data?.message);
//     }
//   };
//   const print = useReactToPrint({
//     content: () => componentRef.current,
//   });
//   const generatePayroll = async () => {
//     setLoading(true);
//     try {
//       const payroll = await POST_API(`/payslipGenerate/${id}`, {
//         payslipDetails,
//         createdBy: localStorage.getItem("user_id"),
//       });
//       if (payroll.status === 200) {
//         setLoading(false);
//         const payslip = getPayslip();
//         setTimeout(() => print(), 2000);
//       }
//     } catch (error) {
//       setLoading(false);
//       alertify.error(error?.response?.data?.message);
//     }
//   };
//   const intToWords = (rawValue) => {
//     var num = rawValue,
//       a = [
//         "",
//         "one ",
//         "two ",
//         "three ",
//         "four ",
//         "five ",
//         "six ",
//         "seven ",
//         "eight ",
//         "nine ",
//         "ten ",
//         "eleven ",
//         "twelve ",
//         "thirteen ",
//         "fourteen ",
//         "fifteen ",
//         "sixteen ",
//         "seventeen ",
//         "eighteen ",
//         "nineteen ",
//       ],
//       b = [
//         "",
//         "",
//         "twenty",
//         "thirty",
//         "forty",
//         "fifty",
//         "sixty",
//         "seventy",
//         "eighty",
//         "ninety",
//       ],
//       c = ["thousand", "million", ""],
//       words = "";

//     num = ("000000000" + num.toString()).substr(-9).match(/.{3}/g);

//     for (var i = 0; i < c.length; i++) {
//       var n = num[i],
//         str = "";
//       str += words != "" ? " " + c[c.length - 1 - i] + " " : "";
//       str += n[0] != 0 ? a[Number(n[0])] + "hundred " : "";
//       n = n.substr(1);
//       str +=
//         n != 0
//           ? (str != "" ? "and " : "") +
//             (a[Number(n)] || b[n[0]] + " " + a[n[1]])
//           : "";
//       words += str;
//     }
//     return words.replace(/ +/g, " ").replace(/ $/, "");
//   };
//   if (loading) {
//     <Spinner />;
//   }
//   const fathers_name =
//     payslipDetails?.employee?.fathers_name !== null
//       ? payslipDetails?.employee?.fathers_name
//       : "";
//   return (
//     <div className=" bg-[#F7F6F4] grow">
//       <div className=" sm-container">
//         <div className="flex justify-between items-center">
//           <div>
//             <h1 className="heading-text">PaySlip</h1>
//           </div>
//           <div>
//             <button className="view-list-button" onClick={print}>
//               Print
//               <img src={Print} alt="" />
//             </button>
//           </div>
//         </div>
//         <div className="card-wrapper">
//           <div ref={componentRef}>
//             <h4 className="payslip-header">
//               <div className="flex justify-between items-center">
//                 <div>
//                   <b>Tentacle Secure Squads</b>
//                   <br></br>
//                   PaySlip Month for <b>{payslipDetails?.displayMonth}</b>
//                 </div>
//                 <div className="flex gap-3">
//                   {payslipDetails?.voucherNo != "" && (
//                     <Barcode
//                       value={payslipDetails?.voucherNo}
//                       height="40"
//                       displayValue={false}
//                     />
//                   )}
//                   <img src={Logo} className="h-[60px] w-[60px]" alt="" />
//                 </div>
//               </div>
//             </h4>
//             <div className="p-1 pl-3 pr-3">
//               <div className="flex justify-between">
//                 <div>
//                   <div className="flex">
//                     <label className="list-label">Employee Code: </label>
//                     <div
//                       style={{ wordBreak: "break-word" }}
//                       className="advance-section-list-value"
//                     >
//                       {payslipDetails?.employee?.emp_code}
//                     </div>
//                   </div>
//                   <div className="flex">
//                     <label className="list-label">Employee Name: </label>
//                     <div
//                       style={{ wordBreak: "break-word" }}
//                       className="advance-section-list-value"
//                     >
//                       {payslipDetails?.employee?.first_name +
//                         " " +
//                         fathers_name}
//                     </div>
//                   </div>
//                 </div>
//                 <div>
//                   <div className="flex">
//                     <label className="list-label">Designation: </label>
//                     <div
//                       style={{ wordBreak: "break-word" }}
//                       className="advance-section-list-value"
//                     >
//                       {payslipDetails?.employee?.designation_name}
//                     </div>
//                   </div>
//                   <div className="flex">
//                     <label className="list-label">Date: </label>
//                     <div
//                       style={{ wordBreak: "break-word" }}
//                       className="advance-section-list-value"
//                     >
//                       {payslipDetails?.date}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="p-3 pt-1">
//               <table className=" w-[100%] border border-[#d9d9d9] border-collapse ">
//                 <tbody>
//                   <tr>
//                     <td className="border  border-[#d9d9d9] p-3 pt-3 p w-[40%]  align-baseline">
//                       <table className="w-[100%]">
//                         <tbody>
//                           {/* <tr>
//                             <td>Holiday Scheme</td>
//                             <td className="text-right">
//                               <b>
//                                 {payslipDetails?.employee?.holiday_scheme_name}
//                               </b>
//                             </td>
//                           </tr> */}
//                           <tr>
//                             <td>Present Days</td>
//                             <td className="text-right">
//                               <b>
//                                 {payslipDetails?.attendance?.presentDays} days
//                               </b>
//                             </td>
//                           </tr>
//                           <tr>
//                             <td>Absent Days</td>
//                             <td className="text-right">
//                               <b>
//                                 {payslipDetails?.attendance?.absentDays} days
//                               </b>
//                             </td>
//                           </tr>
//                           <tr>
//                             <td>Total Absent Days</td>
//                             <td className="text-right">
//                               <b>
//                                 {payslipDetails?.attendance?.totalAbsentDays}{" "}
//                                 days
//                               </b>
//                             </td>
//                           </tr>
//                         </tbody>
//                       </table>
//                     </td>
//                     <td className="border  border-[#d9d9d9]  p-3 pt-3 w-[40%]  align-baseline">
//                       <table className="w-[100%]">
//                         <tbody>
//                           <tr>
//                             <th colSpan="4" className="text-left">
//                               <b> Earnings</b>
//                             </th>
//                           </tr>
//                           {payslipDetails?.earnings &&
//                             payslipDetails?.earnings.map((list, i) => {
//                               return (
//                                 <tr>
//                                   <td>{list?.name}</td>
//                                   <td>{list?.type}</td>
//                                   <td className="text-right">
//                                     {list?.totalAmount}
//                                   </td>
//                                   <td className="text-right">
//                                     <b>{list?.actualAmount}</b>
//                                   </td>
//                                 </tr>
//                               );
//                             })}
//                         </tbody>
//                       </table>
//                     </td>
//                     <td className="border  border-[#d9d9d9]  p-3 pt-3 w-[20%] align-baseline">
//                       <table className="w-[100%]">
//                         <tbody>
//                           <tr>
//                             <th colSpan="2" className="text-left">
//                               <b>Deductions</b>
//                             </th>
//                           </tr>
//                           {payslipDetails?.deductions &&
//                             payslipDetails?.deductions.map((list, i) => {
//                               return (
//                                 <tr>
//                                   <td>{list?.name}</td>
//                                   <td className="text-right">
//                                     <b>{list?.amount}</b>
//                                   </td>
//                                 </tr>
//                               );
//                             })}
//                           {payslipDetails?.otherDeductions &&
//                             payslipDetails?.otherDeductions.map((list, i) => {
//                               return (
//                                 <tr>
//                                   <td>{list?.name}</td>
//                                   <td className="text-right">
//                                     <b>{list?.amount}</b>
//                                   </td>
//                                 </tr>
//                               );
//                             })}
//                         </tbody>
//                       </table>
//                     </td>
//                   </tr>
//                   <tr>
//                     <td className="border  border-[#d9d9d9]  p-1 pl-2 pr-2 p w-[30%]  align-baseline">
//                       <table className="w-[100%]">
//                         <tbody>
//                           <tr>
//                             <td>
//                               <b>Total Days</b>
//                             </td>
//                             <td className="text-right">
//                               {payslipDetails?.attendance?.totalDays} days
//                             </td>
//                           </tr>
//                         </tbody>
//                       </table>
//                     </td>
//                     <td className="border  border-[#d9d9d9]  p-1 pl-2 pr-2 p w-[30%]  align-baseline">
//                       <table className="w-[100%]">
//                         <tbody>
//                           <tr>
//                             <td className="w-[65%]">
//                               <b>Total</b>
//                             </td>
//                             <td className="text-right">
//                               (-{payslipDetails?.totalEarnings?.deduction})
//                             </td>
//                             <td className="text-right">
//                               {payslipDetails?.totalEarnings?.total}
//                             </td>
//                           </tr>
//                         </tbody>
//                       </table>
//                     </td>
//                     <td className="border  border-[#d9d9d9]  p-1 pl-2 pr-2 p w-[30%]  align-baseline">
//                       <table className="w-[100%]">
//                         <tbody>
//                           <tr>
//                             <td>
//                               <b>Total</b>
//                             </td>
//                             <td className="text-right">
//                               {payslipDetails?.totalDeductions?.total}
//                             </td>
//                           </tr>
//                         </tbody>
//                       </table>
//                     </td>
//                   </tr>
//                 </tbody>
//               </table>
//             </div>
//             <div className="p-3 flex">
//               <p>Salary to be paid in cash : </p>
//               <p>
//                 <b>
//                   {" "}
//                   Rs. {payslipDetails?.salaryToBePaid}(
//                   {payslipDetails?.salaryToBePaid &&
//                     intToWords(payslipDetails?.salaryToBePaid)}
//                   Rupees)
//                 </b>
//               </p>
//             </div>
//             <br />
//             <div className="p-3 pt-4">
//               <div className="flex justify-between items-center">
//                 <div>
//                   <b>Admit by</b>
//                 </div>
//                 <div>
//                   <b>Checked by</b>
//                 </div>
//                 <div>
//                   <b>Employee Signature</b>
//                 </div>
//               </div>
//             </div>
//             {/* PF Salary details */}
//             {payslipDetails?.pfEarnings &&
//               payslipDetails?.pfEarnings.length > 0 && (
//                 <>
//                   <div id="scissors">
//                     <div></div>
//                   </div>
//                   <h4 className="payslip-pf-header">
//                     <div className="flex justify-between items-center">
//                       PF Salary Voucher
//                     </div>
//                   </h4>

//                   <div className="p-1 pl-3 pr-3">
//                     <div className="flex justify-between">
//                       <div>
//                         <div className="flex">
//                           <label className="list-label">Employee Code: </label>
//                           <div
//                             style={{ wordBreak: "break-word" }}
//                             className="advance-section-list-value"
//                           >
//                             {payslipDetails?.employee?.emp_code}
//                           </div>
//                         </div>
//                         <div className="flex">
//                           <label className="list-label">Employee Name: </label>
//                           <div
//                             style={{ wordBreak: "break-word" }}
//                             className="advance-section-list-value"
//                           >
//                             {payslipDetails?.employee?.first_name +
//                               " " +
//                               payslipDetails?.employee?.fathers_name}
//                           </div>
//                         </div>
//                       </div>
//                       <div>
//                         <div className="flex">
//                           <label className="list-label">Designation: </label>
//                           <div
//                             style={{ wordBreak: "break-word" }}
//                             className="advance-section-list-value"
//                           >
//                             {payslipDetails?.employee?.designation_name}
//                           </div>
//                         </div>
//                         <div className="flex">
//                           <label className="list-label">Date: </label>
//                           <div
//                             style={{ wordBreak: "break-word" }}
//                             className="advance-section-list-value"
//                           >
//                             {payslipDetails?.date}
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="p-3 pt-1">
//                     <table className=" w-[100%] border border-[#d9d9d9] border-collapse ">
//                       <tbody>
//                         <tr>
//                           <td className="border  border-[#d9d9d9] p-3 pt-3 p w-[40%]  align-baseline">
//                             <table className="w-[100%]">
//                               <tbody>
//                                 <tr>
//                                   <td>Holiday Scheme</td>
//                                   <td className="text-right">
//                                     <b>
//                                       {
//                                         payslipDetails?.employee
//                                           ?.holiday_scheme_name
//                                       }
//                                     </b>
//                                   </td>
//                                 </tr>
//                                 <tr>
//                                   <td>Present Days</td>
//                                   <td className="text-right">
//                                     <b>
//                                       {
//                                         payslipDetails?.pfAttendance
//                                           ?.presentDays
//                                       }
//                                     </b>
//                                   </td>
//                                 </tr>
//                                 <tr>
//                                   <td>Paid Leave Days</td>
//                                   <td className="text-right">
//                                     <b>
//                                       {payslipDetails?.pfAttendance?.paidDays}
//                                     </b>
//                                   </td>
//                                 </tr>
//                                 <tr>
//                                   <td>Net Present Days</td>
//                                   <td className="text-right">
//                                     <b>
//                                       {
//                                         payslipDetails?.pfAttendance
//                                           ?.netPresentDays
//                                       }
//                                     </b>
//                                   </td>
//                                 </tr>
//                               </tbody>
//                             </table>
//                           </td>
//                           <td className="border  border-[#d9d9d9]  p-3 pt-3 w-[40%]  align-baseline">
//                             <table className="w-[100%]">
//                               <tbody>
//                                 <tr>
//                                   <th colSpan="4" className="text-left">
//                                     <b> Earnings</b>
//                                   </th>
//                                 </tr>
//                                 {payslipDetails?.pfEarnings &&
//                                   payslipDetails?.pfEarnings.map((list, i) => {
//                                     return (
//                                       <tr>
//                                         <td>{list?.name}</td>
//                                         <td>{list?.type}</td>
//                                         <td className="text-right">
//                                           {list?.totalAmount}
//                                         </td>
//                                         <td className="text-right">
//                                           {list?.actualAmount}
//                                         </td>
//                                       </tr>
//                                     );
//                                   })}
//                               </tbody>
//                             </table>
//                           </td>
//                           <td className="border  border-[#d9d9d9]  p-3 pt-3 w-[20%] align-baseline">
//                             <table className="w-[100%]">
//                               <tbody>
//                                 <tr>
//                                   <th colSpan="2" className="text-left">
//                                     <b>Deductions</b>
//                                   </th>
//                                 </tr>
//                                 {payslipDetails?.pfDeductions &&
//                                   payslipDetails?.pfDeductions.map(
//                                     (list, i) => {
//                                       return (
//                                         <tr>
//                                           <td>{list?.name}</td>
//                                           <td className="text-right">
//                                             <b>{list?.amount}</b>
//                                           </td>
//                                         </tr>
//                                       );
//                                     }
//                                   )}
//                               </tbody>
//                             </table>
//                           </td>
//                         </tr>
//                         <tr>
//                           <td className="border  border-[#d9d9d9]  p-1 pl-2 pr-2 p w-[30%]  align-baseline">
//                             <table className="w-[100%]">
//                               <tbody>
//                                 <tr>
//                                   <td>
//                                     <b>Total Days</b>
//                                   </td>
//                                   <td className="text-right">
//                                     {payslipDetails?.attendance?.totalDays} days
//                                   </td>
//                                 </tr>
//                               </tbody>
//                             </table>
//                           </td>
//                           <td className="border  border-[#d9d9d9]  p-1 pl-2 pr-2 p w-[30%]  align-baseline">
//                             <table className="w-[100%]">
//                               <tbody>
//                                 <tr>
//                                   <td className="w-[65%]">
//                                     <b>Total</b>
//                                   </td>
//                                   <td className="text-right">
//                                     {payslipDetails?.pfEarnings &&
//                                       payslipDetails?.pfEarnings[0] &&
//                                       payslipDetails?.pfEarnings[0]
//                                         .actualAmount}
//                                   </td>
//                                 </tr>
//                               </tbody>
//                             </table>
//                           </td>
//                           <td className="border  border-[#d9d9d9]  p-1 pl-2 pr-2 p w-[30%]  align-baseline">
//                             <table className="w-[100%]">
//                               <tbody>
//                                 <tr>
//                                   <td>
//                                     <b>Total</b>
//                                   </td>
//                                   <td className="text-right">
//                                     {payslipDetails?.pfTotalDeductions?.total}
//                                   </td>
//                                 </tr>
//                               </tbody>
//                             </table>
//                           </td>
//                         </tr>
//                       </tbody>
//                     </table>
//                   </div>

//                   <div className="p-3 flex">
//                     <p>Salary to be credited in Bank : </p>
//                     <p>
//                       <b>
//                         {" "}
//                         Rs. {payslipDetails?.pfSalaryInBank}(
//                         {payslipDetails?.pfSalaryInBank &&
//                           intToWords(payslipDetails?.pfSalaryInBank)}
//                         Rupees)
//                       </b>
//                     </p>
//                   </div>
//                   <br />

//                   <div className="p-3 pt-4">
//                     <div className="flex justify-between items-center">
//                       <div>
//                         <b>Admit by</b>
//                       </div>
//                       <div>
//                         <b>Checked by</b>
//                       </div>
//                       <div>
//                         <b>Employee Signature</b>
//                       </div>
//                     </div>
//                   </div>
//                 </>
//               )}
//           </div>
//           {payslipDetails?.voucherNo == "" && (
//             <div className="search-block ">
//               <div className="export-btn" onClick={generatePayroll}>
//                 <span className="export-btn-text">Generate Payroll</span>
//                 <img src={Export} alt="" />
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// }
// export default PayslipDetails;


