import React from "react";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import AddchartIcon from '@mui/icons-material/Addchart';
import Edit from "../Assets/Icons/Edit.svg";
import Show from "../Assets/Icons/View.svg";
import Delete from "../Assets/Icons/Delete.svg";
import TerminateEmployee from "../Assets/Icons/EmployeeTerminate.svg";
import Allowance from "../Assets/Icons/Allowance.svg";
import { useNavigate } from "react-router-dom";

function Action({
  editPage,
  row,
  multiplePage,
  deleteRow,
  showDetails,
  editList,
  editStatus,
  allowance = false,
  activeEdit,
  activeDelete = true,
  paySlip = false,
  termination = false,
  terminateEmployee,
  enableMoreOption = true,
  verification,
  download,
  allowanceDetails,
  approveLeave,
  ShowRemarks,
}) {
 
  const navigate = useNavigate();
  const handleEdit = () => {
    if (multiplePage) {
      editPage(row);
    } else {
      editList(row);
      editStatus(true);
    }
  };
  const handleDelete = () => {
    deleteRow(row?.id);
  };
  const showRowDetails = () => {
    showDetails(row);
  };
  const handleAllownace = () => {
    navigate(`/employee/${row?.employee_id}/allowance/deduction/${row?.client_id}`, {
      state: {
        listId: row?.id,
      },
    });
  };
  const handleAllownaceDetails = () => {
    allowanceDetails(row);
  };

  const handleApprove = () => {
    approveLeave(row);
  };

  const handleShowRemarks = () => {
    ShowRemarks(row);
  };

  return (
    enableMoreOption && (
      <div className="min-w-[75px] bg-[#fff] rounded border-solid border-[1px] border-primary absolute top-[20px] right-[35px] z-50">
        {activeEdit === undefined && (
          <div
            className="px-4 py-2 min-w-[138px] gap-2 border-solid border-b-[1px] border-primary flex justify-between items-center cursor-pointer"
            onClick={() => handleEdit()}
          >
            <span className="font-karla text-base font-normal text-text-light">
              Edit
            </span>
            <img src={Edit} alt="" />
          </div>
        )}
        {allowance && (
          <div
            className="px-4 py-2 min-w-[138px] gap-2 border-solid border-b-[1px] border-primary flex justify-between items-center cursor-pointer"
            onClick={() => handleAllownace()}
          >
            <span className="font-karla text-base font-normal text-text-light">
              Allowance
            </span>
            <img src={Allowance} alt="" />
          </div>
        )}
        {allowanceDetails && (
          <div
            className="px-4 py-2 min-w-[138px] gap-2 border-solid border-b-[1px] border-primary flex justify-between items-center cursor-pointer"
            onClick={() => handleAllownaceDetails()}
          >
            <span className="font-karla text-base font-normal text-text-light">
              Allowance
            </span>
            <img src={Allowance} alt="" />
          </div>
        )}

        {multiplePage && (
          <div
            className="px-4 py-2 min-w-[138px] gap-2 border-solid border-b-[1px] border-primary flex justify-between items-center cursor-pointer"
            onClick={() => showRowDetails()}
          >
            <span className="font-karla text-base font-normal text-text-light">
              Show
            </span>
            <img src={Show} alt="" />
          </div>
        )}
        {paySlip && (
          <div
            className="px-4 py-2 min-w-[138px] gap-2 border-solid border-b-[1px] border-primary flex justify-between items-center cursor-pointer"
            onClick={() => showRowDetails()}
          >
            <span className="font-karla text-base font-normal text-text-light">
              Payslip
            </span>
            <img src={Show} alt="" />
          </div>
        )}
        {activeDelete && (
          <div
            className="px-4 py-2 min-w-[138px] gap-2 border-solid border-b-[1px] border-primary flex justify-between items-center cursor-pointer"
            onClick={() => handleDelete()}
          >
            <span className="font-karla text-base font-normal text-text-light">
              Delete
            </span>
            <img src={Delete} alt="" className="h-5" />
          </div>
        )}

        {termination && (
          <div
            className="px-4 py-2 min-w-[138px] gap-2 border-solid  border-primary flex justify-between items-center cursor-pointer"
            onClick={() => terminateEmployee(row)}
          >
            <span className="font-karla text-base font-normal text-text-light">
              Terminate
            </span>
            <img src={TerminateEmployee} alt="" />
          </div>
        )}

        {verification && (
          <div
            className="px-4 py-2 min-w-[138px] gap-2 border-solid border-b-[1px] border-primary flex justify-between items-center cursor-pointer"
            onClick={() => verification(row)}
          >
            <span className="font-karla text-base font-normal text-text-light">
              Verification
            </span>
            <CheckCircleOutlineIcon/>
          </div>
        )}
        {download && (
          <div
            className="px-4 py-2 min-w-[138px] gap-2 border-solid border-b-[1px] border-primary flex justify-between items-center cursor-pointer"
            onClick={() => download(row)}
          >
            <span className="font-karla text-base font-normal text-text-light">
              Download
            </span>
            <ArrowCircleDownIcon/>
          </div>
        )}
        {approveLeave && (
          <div
            className="px-4 py-2 min-w-[138px] gap-2 border-solid border-b-[1px] border-primary flex justify-between items-center cursor-pointer"
            onClick={() => handleApprove(row)}
          >
            <span className="font-karla text-base font-normal text-text-light">
              Approve
            </span>
            <CheckCircleOutlineIcon/>
          </div>
        )}
         {ShowRemarks && (
          <div
            className="px-4 py-2 min-w-[138px] gap-2 border-solid border-b-[1px] border-primary flex justify-between items-center cursor-pointer"
            onClick={() => handleShowRemarks(row)}
          >
            <span className="font-karla text-base font-normal text-text-light">
              Remarks
            </span>
            <AddchartIcon/>
           
          </div>
        )}
      </div>
    )
  );
}

export default Action;
